import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap'
import { useFetch } from '../../../../helpers/hooks'
import {
  downloadAdminQuote,
  downloadContractPdf,
  getContractAmendmentsAdmin,
  getContractDetailAdmin,
  inviteClientFulltime,
  inviteEmployeeToFtContract,
  requestContractTerminationDetailsAdmin,
  signAsProvider,
  updateAdminFulltimeQuotation,
  updateFTContract,
} from '../../../../services/api'
import { useParams, useHistory } from 'react-router-dom'
import toastr from 'toastr'
import RequestLeftSide from './components/request-left-side'
import RequestStep from './components/request-step'
import FullTimeDetailsForm from '../../../Contract/RequestContract/FullTimeDetailsForm'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import Button from '../../../../components/ui/button'
import { getFullName } from '../../../../utils/get-full-name'
import QuotationForm from '../../../Contract/RequestContract/QuotationForm'
import RequestLoading from './components/request-loading'
import DataCollectionTab from '../../../Contract/ContractPage/data-collection-tab'
import { ContractReviewModal } from './components/contract-review-modal'
import ImmigrationStep from './components/immigration-step'
import EmploymentContractStep from './components/employment-contract-step'
import InviteEmployeeForm from './components/invite-employee-form'
import SignModal from '../../../Contract/ContractDetail/components/SignModal'
import { Signature } from '@phosphor-icons/react'
import BenefitsStep from './components/benefits-step'
import { ShowEmployeeBasicInfo } from './components/show-employee-basic-info'
import AmendmentsTable from '../eor/components/amendments-table'
// Constants
const steps = {
  contract_created: 'contract_created',
  quotation_prepared: 'quotation_prepared',
  client_invited: 'client_invited',
  remotepass_signed: 'remotepass_signed',
  employee_invited: 'employee_invited',
  employee_data_collected: 'employee_data_collected',
  employment_contract_signed: 'employment_contract_signed',
  immigration_document_uploaded: 'immigration_document_uploaded',
  benefit_document_uploaded: 'benefit_document_uploaded',
  deposit_paid: 'deposit_paid',
}

function RequestDetailsNew() {
  // states

  const [partner, setPartner] = useState(null)
  const [terminationDetails, setTerminationDetails] = useState(null)
  const [specialist, setSpecialist] = useState(null)
  const [openStep, setOpenStep] = useState(null)
  const [activities, setActivities] = useState([])
  const [isDownloadOpen, setIsDownloadOpen] = useState(false)
  const [isOpenPreview, setIsOpenPreview] = useState(false)
  const [showSignModal, setShowSignModal] = useState(false)

  // hooks
  const { contractId } = useParams()
  const detailsFormRef = useRef(null)
  const hasEditPermission = useHasPermission(
    ADMIN_PERMISSIONS.MANAGE_EOR_CONTRACTS,
  )
  const history = useHistory()

  // Requests
  const { startFetch: getTerminationDetails } = useFetch({
    action: requestContractTerminationDetailsAdmin,
    withAdminAccess: true,
    onComplete: (data) => {
      setTerminationDetails(data)
    },
  })
  const {
    data: contract,
    isLoading: isContractLoading,
    startFetch: refetchContract,
  } = useFetch({
    action: getContractDetailAdmin,
    withAdminAccess: true,
    autoFetch: true,
    body: { id: contractId },
    onComplete: (data) => {
      setActivities(data.onboarding_activities)
      if (data.partner) {
        setPartner({ value: data?.partner?.id, label: data?.partner?.name })
      }
      if (data.onboarding_specialist) {
        setSpecialist({
          value: data?.onboarding_specialist?.id,
          label: data?.onboarding_specialist?.name,
        })
      }
      if (data.pending_termination_request_id) {
        getTerminationDetails({
          request_id: data.pending_termination_request_id,
          contract_id: contractId,
        })
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const { startFetch: update, isLoading: isUpdating } = useFetch({
    action: updateFTContract,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success('Contract was updated successfully')
      refetchContract({ id: contractId }, false)
    },
  })

  const { startFetch: inviteEmployee, isLoading: isInvitingEmployee } =
    useFetch({
      action: inviteEmployeeToFtContract,
      withAdminAccess: true,
      onComplete: () => {
        refetchContract({ id: contractId }, false)
        toastr.success('Employee invited successfully.')
      },
      checkSuccess: true,
      onError: (err) => {
        toastr.error('There was an error on inviting employee. ' + err)
      },
    })
  const { startFetch: updateQuotation, isLoading: isUpdatingQuotation } =
    useFetch({
      action: updateAdminFulltimeQuotation,
      withAdminAccess: true,
      onComplete: () => {
        refetchContract({ id: contractId }, false)
        toastr.success('Quote updated successfully.')
      },
      checkSuccess: true,
      onError: (err) => {
        toastr.error('There was an error updating quote. ' + err)
      },
    })
  const { startFetch: downloadContract, isLoading: isDownloadingContract } =
    useFetch({
      action: downloadContractPdf,
      withAdminAccess: true,
      onComplete: (data) => {
        downloadFile(data, `SOW-${contract?.ref}.pdf`)
      },
    })

  const { startFetch: downloadQuotation } = useFetch({
    action: downloadAdminQuote,
    withAdminAccess: true,
    onComplete: (data) => {
      downloadFile(data, `Quotation-${contract?.ref}.pdf`)
    },
  })
  const { startFetch: signContract, isLoading: isSigningContract } = useFetch({
    action: signAsProvider,
    withAdminAccess: true,
    onComplete: () => {
      refetchContract({ id: contractId }, false)
      refetchAmendments({ contract_id: contractId }, false)
      toastr.success('Provided signed successfully')
      setShowSignModal(false)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const invite = useFetch({
    action: inviteClientFulltime,
    withAdminAccess: true,
    onComplete: () => {
      setIsOpenPreview(false)
      refetchContract({ id: contractId }, false)
      toastr.success('Client invited')
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const { data: amendments, startFetch: refetchAmendments } = useFetch({
    action: getContractAmendmentsAdmin,
    withAdminAccess: true,
    autoFetch: true,
    body: { contract_id: contractId },
    onComplete: () => {
      setIsOpenPreview(false)
      refetchContract({ id: contractId }, false)
      toastr.success('Contract amendments fetched')
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  // Handlers
  const getActivity = useCallback(
    (step) => {
      return activities.find((activity) => activity.type === step)
    },
    [activities],
  )
  const onUpdateTemplate = () => {
    history.push({
      pathname: '/admin/template-editor',
      state: { contract_id: contract?.id, isAdmin: true },
    })
  }

  const downloadFile = (data, name) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
  }
  const handleSignClick = () => {
    signContract({
      contract_id: contract?.id,
    })
  }
  const onDownloadMAS = () => {
    downloadContract({
      contractToken: contract?.file,
      ref: contract?.ref,
    })
  }

  const onDownloadQuote = () => {
    downloadQuotation({ contract_id: contract?.id })
  }

  const onInviteClient = () => {
    invite.startFetch({
      contract_id: contract?.id,
    })
  }

  // Helpers

  const toggleStep = (step) => {
    if (openStep === step) {
      setOpenStep(null)
      return
    }
    setOpenStep(step)
  }

  useEffect(() => {
    if (getActivity(steps.contract_created) === undefined) {
      setOpenStep(steps.contract_created)
    } else if (getActivity(steps.quotation_prepared) === undefined) {
      setOpenStep(steps.quotation_prepared)
    } else if (getActivity(steps.client_invited) === undefined) {
      setOpenStep(steps.client_invited)
    } else if (getActivity(steps.employee_invited) === undefined) {
      setOpenStep(steps.employee_invited)
    } else if (getActivity(steps.employee_data_collected) === undefined) {
      setOpenStep(steps.employee_data_collected)
    } else if (getActivity(steps.deposit_paid) === undefined) {
      setOpenStep(steps.deposit_paid)
    }
  }, [activities, getActivity])

  if (isContractLoading) {
    return <RequestLoading />
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <Row>
          <h1 className='tw-mb-8 tw-font-medium'>
            Contract
            {!contract?.ref ? null : (
              <span className='tw-text-text-80'> #{contract?.ref}</span>
            )}
          </h1>
        </Row>
        <Row>
          <Col md={4}>
            {contract ? (
              <RequestLeftSide
                partner={partner}
                specialist={specialist}
                contract={contract}
                terminationDetails={terminationDetails}
                refetchContract={refetchContract}
              />
            ) : null}
          </Col>
          <Col md={8}>
            <div className='tw-mb-4 tw-flex tw-flex-col tw-gap-6 tw-bg-white tw-p-6'>
              <div className='tw-text-xl tw-font-medium'>
                Onboarding progress
              </div>
              <RequestStep
                isOpen={openStep === steps.contract_created}
                isDone={!!getActivity(steps.contract_created)}
                userName={getFullName(
                  getActivity(steps.contract_created)?.actor,
                )}
                updatedAt={getActivity(steps.contract_created)?.updated_at}
                title='EOR Request'
                description='View/Edit the client’s EOR request'
                order='1'
                toggle={() => toggleStep(steps.contract_created)}
              >
                <FullTimeDetailsForm
                  ref={detailsFormRef}
                  initialData={contract}
                  readOnly={
                    !hasEditPermission ||
                    contract?.provider_signed ||
                    contract?.client_signed
                  }
                  onComplete={(v) =>
                    update({
                      ...v,
                      contract_id: contract?.id,
                    })
                  }
                  quote={{
                    country_id: contract?.employee?.working_from_country?.id,
                    currency_id: contract?.salary_currency?.id,
                    amount: contract?.amount,
                  }}
                  loading={isUpdating}
                  refetchContract={refetchContract}
                />
                {!hasEditPermission ? null : (
                  <Row className='m-0 p-0 justify-content-end'>
                    <Button
                      onClick={() => {
                        detailsFormRef.current.submit()
                      }}
                      disabled={
                        !hasEditPermission ||
                        isUpdating ||
                        contract?.provider_signed ||
                        contract?.client_signed
                      }
                      loading={isUpdating}
                    >
                      Update
                    </Button>
                  </Row>
                )}
              </RequestStep>
              <RequestStep
                isOpen={openStep === steps.quotation_prepared}
                isDone={!!getActivity(steps.quotation_prepared)}
                userName={getFullName(
                  getActivity(steps.quotation_prepared)?.actor,
                )}
                updatedAt={getActivity(steps.quotation_prepared)?.updated_at}
                title='Quotation'
                description='View/Edit quotation'
                order='2'
                toggle={() => toggleStep(steps.quotation_prepared)}
              >
                <QuotationForm
                  initialData={contract?.quote}
                  readOnly={
                    !hasEditPermission ||
                    contract?.provider_signed ||
                    contract?.client_signed
                  }
                  onSubmit={(v) => {
                    updateQuotation({
                      ...v,
                      contract_id: contract?.id,
                    })
                  }}
                  loading={isUpdatingQuotation}
                  currency={contract?.currency?.code}
                  amount={contract?.amount}
                  salaryCurrency={contract?.salary_currency?.code}
                  countryCode={contract?.employee?.working_from_country?.iso2}
                  contractId={contract?.id}
                  allowances={contract?.allowances}
                />
              </RequestStep>
              <RequestStep
                isOpen={openStep === steps.client_invited}
                isDone={!!getActivity(steps.client_invited)}
                userName={getFullName(getActivity(steps.client_invited)?.actor)}
                updatedAt={getActivity(steps.client_invited)?.updated_at}
                title='Quotation & SOW Sent to Client'
                description='Send/View Statement of Work & Quotation '
                order='3'
                toggle={() => toggleStep(steps.client_invited)}
              >
                <Container>
                  <Row style={{ gap: '1rem' }}>
                    {(!!contract?.template || !!contract?.quote) && (
                      <Col className='px-0'>
                        <Dropdown
                          isOpen={isDownloadOpen}
                          toggle={() => setIsDownloadOpen((t) => !t)}
                        >
                          <DropdownToggle tag='div'>
                            <Button
                              color='secondary'
                              block
                              loading={isDownloadingContract}
                              disabled={isDownloadingContract}
                            >
                              Download
                            </Button>
                          </DropdownToggle>
                          <DropdownMenu className='dropdown-menu' flip={false}>
                            {!!contract?.template && (
                              <DropdownItem onClick={onDownloadMAS}>
                                SOW
                              </DropdownItem>
                            )}
                            {!!contract?.quote && (
                              <DropdownItem onClick={onDownloadQuote}>
                                Quotation
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    )}
                    <Col className='px-0'>
                      <Button
                        block
                        onClick={onUpdateTemplate}
                        disabled={
                          !hasEditPermission ||
                          contract?.provider_signed ||
                          contract?.client_signed
                        }
                      >
                        {contract?.template ? 'Update' : 'Create'} SOW
                      </Button>
                    </Col>
                  </Row>
                  <Row className='pt-2'>
                    <Button
                      block
                      onClick={() => setIsOpenPreview(true)}
                      disabled={
                        !hasEditPermission ||
                        contract?.provider_signed ||
                        contract?.client_signed
                      }
                    >
                      Review & {contract?.client_invited ? 'Resend' : 'Send'}
                    </Button>
                  </Row>
                </Container>
              </RequestStep>
              <RequestStep
                isOpen={false}
                isDone={!!getActivity(steps.deposit_paid)}
                userName={getFullName(getActivity(steps.deposit_paid)?.actor)}
                updatedAt={getActivity(steps.deposit_paid)?.updated_at}
                title='Deposit paid'
                description='Client has signed the SOW & paid the deposit'
                order='4'
                toggle={() => toggleStep(steps.deposit_paid)}
                noArrow={true}
              ></RequestStep>
              <RequestStep
                isOpen={
                  openStep === steps.remotepass_signed &&
                  !getActivity(steps.remotepass_signed)
                }
                isDone={!!getActivity(steps.remotepass_signed)}
                userName={getFullName(
                  getActivity(steps.remotepass_signed)?.actor,
                )}
                updatedAt={getActivity(steps.remotepass_signed)?.updated_at}
                title='Provider signed'
                description='RP to sign the SOW, in order to start the employee onboarding'
                order='5'
                toggle={() => toggleStep(steps.remotepass_signed)}
                noArrow={!!getActivity(steps.remotepass_signed)}
              >
                <Row className='pt-2 px-4'>
                  <Button
                    block
                    id='invite-employee-button'
                    size='lg'
                    textClassName='font-size-14 py-1'
                    onClick={() => setShowSignModal(true)}
                    text='Sign SOW'
                    icon={<Signature size={20} />}
                  />
                </Row>
              </RequestStep>
              <RequestStep
                isOpen={openStep === steps.employee_invited}
                isDone={!!getActivity(steps.employee_invited)}
                userName={getFullName(
                  getActivity(steps.employee_invited)?.actor,
                )}
                updatedAt={getActivity(steps.employee_invited)?.updated_at}
                title='Employee Invited'
                description='Invite your employee'
                order='6'
                toggle={() => toggleStep(steps.employee_invited)}
              >
                <InviteEmployeeForm
                  contract={contract}
                  specialist={specialist}
                  setSpecialist={setSpecialist}
                  refetchContract={refetchContract}
                  onSubmit={(v) =>
                    inviteEmployee({
                      notify_client: v?.notify_client,
                      notify_employee: v?.notify_employee,
                      contract_id: contractId,
                      onboarding_specialist_id: v?.onboarding_specialist_id,
                    })
                  }
                  isLoading={isInvitingEmployee}
                  sendInviteDate={
                    getActivity(steps.employee_invited)?.updated_at
                  }
                />
              </RequestStep>
              <RequestStep
                isOpen={openStep === steps.employee_data_collected}
                isDone={!!getActivity(steps.employee_data_collected)}
                userName={getFullName(
                  getActivity(steps.employee_data_collected)?.actor,
                )}
                updatedAt={
                  getActivity(steps.employee_data_collected)?.updated_at
                }
                title='Employee Data Collected'
                description='Find the documents and info provided by the employee'
                order='7'
                toggle={() => toggleStep(steps.employee_data_collected)}
              >
                <ShowEmployeeBasicInfo employee={contract?.employee} />
                <DataCollectionTab
                  contractId={contract?.id}
                  contractLoading={isContractLoading}
                  refetchContract={refetchContract}
                />
              </RequestStep>
              <RequestStep
                isOpen={openStep === steps.employment_contract_signed}
                isDone={!!getActivity(steps.employment_contract_signed)}
                userName={getFullName(
                  getActivity(steps.employment_contract_signed)?.actor,
                )}
                updatedAt={
                  getActivity(steps.employment_contract_signed)?.updated_at
                }
                title='Employee Contract Signed'
                description='Upload/Download employment agreement'
                order='8'
                toggle={() => toggleStep(steps.employment_contract_signed)}
              >
                <EmploymentContractStep
                  contractSigned={
                    !hasEditPermission ||
                    contract?.provider_signed ||
                    contract?.client_signed
                  }
                  contractStartDate={contract?.start_date}
                  refetch={refetchContract}
                  contractId={contract?.id}
                  documents={contract?.employment_contracts}
                />
              </RequestStep>
              {!contract?.work_visa ? null : (
                <RequestStep
                  isOpen={openStep === steps.immigration_document_uploaded}
                  isDone={!!getActivity(steps.immigration_document_uploaded)}
                  userName={getFullName(
                    getActivity(steps.immigration_document_uploaded)?.actor,
                  )}
                  updatedAt={
                    getActivity(steps.immigration_document_uploaded)?.updated_at
                  }
                  title='Immigration'
                  description='Upload/View the employee’s work permit/visa info'
                  order='9'
                  toggle={() => toggleStep(steps.immigration_document_uploaded)}
                >
                  <ImmigrationStep
                    refetch={refetchContract}
                    contractId={contract?.id}
                    documents={contract?.immigration_documents}
                  />
                </RequestStep>
              )}
              <RequestStep
                isOpen={openStep === steps.benefit_document_uploaded}
                isDone={!!getActivity(steps.benefit_document_uploaded)}
                userName={getFullName(
                  getActivity(steps.benefit_document_uploaded)?.actor,
                )}
                updatedAt={
                  getActivity(steps.benefit_document_uploaded)?.updated_at
                }
                title='Benefits'
                description='Upload/Download benefits'
                order={!contract?.work_visa ? '9' : '10'}
                toggle={() => toggleStep(steps.benefit_document_uploaded)}
              >
                <BenefitsStep
                  contract={contract}
                  refetchContract={refetchContract}
                />
              </RequestStep>
            </div>
            {amendments?.length ? (
              <div className='tw-bg-white tw-p-6'>
                <div className='tw-mb-6 tw-text-xl tw-font-bold'>
                  Amendments
                </div>
                <AmendmentsTable
                  data={amendments.map((a) => ({
                    amendment: { ...a, details: a.changes },
                    contract,
                  }))}
                  canSign={!contract?.contractor_signed}
                  handleOnSign={() => setShowSignModal(true)}
                  refresh={() =>
                    refetchAmendments({ contract_id: contractId }, false)
                  }
                />
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
      {isOpenPreview ? (
        <ContractReviewModal
          contract={contract}
          invite={invite}
          isOpenPreview={isOpenPreview}
          onInviteClient={onInviteClient}
          setIsOpenPreview={setIsOpenPreview}
        />
      ) : null}
      {showSignModal && (
        <SignModal
          data={contract}
          downloading={isDownloadingContract}
          handleDownloadContract={onDownloadMAS}
          handleSignClick={handleSignClick}
          loadingSignature={isSigningContract}
          setShowSignModal={setShowSignModal}
          showSignModal={showSignModal}
          isAdmin={true}
        />
      )}
    </div>
  )
}

export default RequestDetailsNew
