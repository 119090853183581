import { CaretRight } from '@phosphor-icons/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'

import { cn } from 'ui'
import { StyledH2 } from '../../components/Typo'
import Button from '../../components/ui/button'
import { usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { getCurrencyFormatter } from '../../utils/formatters/currency'

export function StyledMiniWidget({ children, className }) {
  return (
    <div
      className={cn('rp-shadow-2 tw-h-full tw-rounded tw-bg-white', className)}
    >
      {children}
    </div>
  )
}

export function StylePart({ children, className }) {
  return (
    <div className={cn('tw-flex tw-items-center tw-p-4 lg:tw-p-6', className)}>
      {children}
    </div>
  )
}

const MiniWidget = ({ simple, withdraw, pay, isAdmin, reports }) => {
  const { t } = useTranslation()
  const { hasAccess } = usePermissions()

  const canManagePayments = useSelector(
    (state) => state.userProfile?.userProfile?.is_payroll_approver,
  )

  return (
    <Row className='mx-n2' style={{ gap: '1rem 0' }}>
      {reports.map((report, key) => {
        const formatter = !report.value?.currency
          ? null
          : getCurrencyFormatter(report.value.currency)

        return (
          <Col key={key} lg={12 / reports?.length} className='px-2'>
            <StyledMiniWidget>
              <StylePart
                className='border-bottom justify-content-between align-items-center d-flex gap-6'
                style={{ minHeight: 83 }}
              >
                <div className='justify-content-between align-items-center d-flex gap-12'>
                  {report?.customIcon ? (
                    <div className='tw-size-6 tw-text-secondary-100'>
                      {report.customIcon}
                    </div>
                  ) : (
                    <img
                      alt='icon'
                      src={report.icon}
                      className='tw-w-6 sm:tw-w-8'
                    />
                  )}

                  <h5 className='tw-mb-0 tw-text-base tw-font-normal'>
                    {t(report.title)}
                    {!!report.info && (
                      <>
                        <i
                          id={`info-repo-${key}`}
                          className='bx bx-info-circle font-size-16 ml-1'
                        />

                        <UncontrolledTooltip
                          placement='top'
                          target={`info-repo-${key}`}
                        >
                          {report.info}
                        </UncontrolledTooltip>
                      </>
                    )}
                  </h5>
                </div>

                {!report?.href ? null : (
                  <span id={`href-tip-${key}`}>
                    <Button
                      tag={Link}
                      to={report.href}
                      color='link'
                      className='py-1.5 tw-border tw-px-0'
                      size='sm'
                      disabled={report.disableHref}
                    >
                      {report?.hrefText}
                    </Button>
                    {!!report.disableHref && (
                      <UncontrolledTooltip
                        placement='bottom'
                        target={`href-tip-${key}`}
                      >
                        {report.hrefTip}
                      </UncontrolledTooltip>
                    )}
                  </span>
                )}

                {!((withdraw && key === 0) || (pay && key === 1)) &&
                  !!report.value?.value && (
                    <>
                      {canManagePayments && pay && key === 2 ? (
                        <Button
                          tag={Link}
                          onClick={report.track}
                          to={report.link}
                          size='sm'
                        >
                          {t('Review')}
                        </Button>
                      ) : (
                        <Link
                          onClick={report.track}
                          to={report.link}
                          className='text-primary ml-2 font-size-14 d-flex align-items-center gap-2'
                        >
                          {t('Details')}

                          <CaretRight weight='bold' size={10} />
                        </Link>
                      )}
                    </>
                  )}

                {withdraw && key === 0 && report.value?.value !== 0 && (
                  <Button
                    tag={Link}
                    onClick={report.track}
                    to='/withdraw'
                    size='sm'
                  >
                    {t('Withdraw')}
                  </Button>
                )}

                {pay &&
                  key === 1 &&
                  report.value?.value !== 0 &&
                  hasAccess(permissions.ViewUnpaidPayments) && (
                    <>
                      {hasAccess(permissions.PrepareTransactions) ? (
                        <Button
                          tag={Link}
                          onClick={report.track}
                          to='/payment'
                          size='sm'
                        >
                          {t('Pay')}
                        </Button>
                      ) : (
                        <Link
                          onClick={report.track}
                          to='/payment'
                          className='text-primary ml-2 font-size-14 d-flex align-items-center gap-2'
                        >
                          {t('Details')}

                          <CaretRight weight='bold' size={10} />
                        </Link>
                      )}
                    </>
                  )}
              </StylePart>

              <StylePart>
                <div>
                  <StyledH2
                    style={{ fontWeight: '600' }}
                    max='20px'
                    min='20px'
                    className='text-gray-h mb-0'
                  >
                    {formatter
                      ? formatter.format(report?.value?.value)
                      : report?.value?.amount}
                  </StyledH2>
                  {!!report?.overDue && (
                    <StyledH2
                      style={{ fontWeight: '600' }}
                      max={isAdmin ? '16px' : '18px'}
                      min={isAdmin ? '16px' : '18px'}
                      className='text-danger mb-0'
                    >
                      {formatter
                        ? formatter.format(report?.overDue)
                        : report?.overDue}{' '}
                      ({t('Overdue')})
                    </StyledH2>
                  )}
                  {!!report?.overDueMonth && (
                    <StyledH2
                      style={{ fontWeight: '600' }}
                      max='16px'
                      min='16px'
                      className='text-secondary mb-0'
                    >
                      {formatter
                        ? formatter.format(report?.overDueMonth)
                        : report?.overDueMonth}{' '}
                      {t('(This month)')}
                    </StyledH2>
                  )}
                </div>
                {!simple && (
                  <div className='d-flex'>
                    <div>
                      <div
                        style={{ color: '#0F1035' }}
                        className={
                          'badge badge-soft-' +
                          report.color +
                          ' font-size-12 ml-2'
                        }
                      >
                        <p className='mb-0 font-size-14'>{report.badgeValue}</p>
                      </div>
                    </div>
                    <span
                      style={{ color: '#0F1035' }}
                      className='ml-2 text-truncate'
                    >
                      {report.desc}
                    </span>
                  </div>
                )}
              </StylePart>
            </StyledMiniWidget>
          </Col>
        )
      })}
    </Row>
  )
}

export default MiniWidget
