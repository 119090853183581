import React, { createContext, useContext } from 'react'
import { useFetch } from '../../../../../helpers/hooks'
import { getPlaceholders } from '../../../../../services/api-automations'

export const PlaceholdersContext = createContext({
  isLoading: true,
  items: [],
})

export function PlaceholdersProvider(props) {
  const { children } = props
  const { isLoading, data: items = [] } = useFetch({
    autoFetch: true,
    isOpenApi: true,
    action: getPlaceholders,
  })

  return (
    <PlaceholdersContext.Provider value={{ isLoading, items }}>
      {children}
    </PlaceholdersContext.Provider>
  )
}

export function usePlaceholders() {
  return useContext(PlaceholdersContext)
}
