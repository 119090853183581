import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useMemo, useState } from 'react'
import { slide as Slide } from 'react-burger-menu'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledInput from '../../../../components/ControlledInput'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import { useFetch } from '../../../../helpers/hooks'
import {
  addBeneficiary as addBeneficiaryAction,
  getAllBeneficiaries,
  getBankDetails as getBankDetailsAction,
} from '../../../../services/api'

export default function Beneficiaries() {
  const [creatingId, setCreatingId] = useState(null)
  const [missingFields, setMissingFields] = useState(null)
  const [bankAccountDetails, setBankAccountDetails] = useState(null)
  const [countryCode, setCountryCode] = useState(null)
  const [currencyCode, setCurrencyCode] = useState(null)

  const {
    data: beneficiaries,
    isLoading,
    startFetch: refreshBeneficiariesList,
  } = useFetch(
    {
      action: getAllBeneficiaries,
      withAdminAccess: true,
      autoFetch: true,
      body: {
        country_code: countryCode?.value,
        currency: currencyCode?.value,
      },
    },
    [currencyCode, countryCode],
  )

  const { startFetch: addBeneficiary } = useFetch({
    action: addBeneficiaryAction,
    withAdminAccess: true,
    checkSuccess: true,
    onComplete: (data, body) => {
      if (data?.missing_info?.length > 0) {
        setMissingFields({ body, fields: data?.missing_info })
      } else {
        refreshBeneficiariesList()
      }
      setCreatingId(null)
    },
    onError: (error) => {
      setCreatingId(null)
      toastr.error(error)
    },
  })

  const handleAddBeneficiary = useCallback(
    function handleAddBeneficiary(data, missingInfos) {
      const id = data?.id
      setCreatingId(id)

      const body = { bank_account_id: id, provider: data?.provider }
      if (missingFields) {
        body.missing_infos = missingInfos
      }

      addBeneficiary(body)
    },
    [addBeneficiary, missingFields],
  )

  function handleAddBeneWithMissingInfo(values) {
    const data = {
      id: missingFields?.body?.bank_account_id,
      provider: missingFields?.body?.provider,
    }

    setMissingFields(null)

    const missingInfo = Object.entries(values).map(([key, value]) => ({
      key,
      value,
    }))
    handleAddBeneficiary(data, missingInfo)
  }

  const {
    startFetch: getBankDetails,
    data: bankDetails,
    isLoading: loadingDetails,
  } = useFetch({
    action: getBankDetailsAction,
    withAdminAccess: true,
  })

  const showBankDetails = useCallback(
    (bankAccountId) => {
      getBankDetails({ id: bankAccountId })
      document.body.classList.add('modal-open')
      setBankAccountDetails([])
    },
    [getBankDetails],
  )
  const data = useSelector((state) => state?.Layout?.staticData)
  const columns = useMemo(() => {
    return [
      {
        Header: 'Bank Account ID',
        accessor: 'id',
        Cell: ({ cellData: bankAccountId }) => {
          return (
            <button
              className='rp-btn-nostyle text-primary'
              onClick={() => showBankDetails(bankAccountId)}
            >
              {bankAccountId}
            </button>
          )
        },
      },
      { Header: 'Holder Name', accessor: 'holder_name' },
      { Header: 'Country', accessor: 'country' },
      { Header: 'Currency', accessor: 'currency' },
      { Header: 'Provider', accessor: 'provider' },
      {
        Header: 'Action',
        style: { width: 200 },
        Cell: ({ rowData }) => {
          return (
            <ActionsColumn
              loading={creatingId === rowData?.id}
              onClickAction={() => handleAddBeneficiary(rowData)}
              rowData={rowData}
            />
          )
        },
      },
    ]
  }, [creatingId, handleAddBeneficiary, showBankDetails])

  return (
    <div className='page-content'>
      <div
        className='d-flex justify-content-between'
        style={{ marginBottom: '2rem' }}
      >
        <h1 className='mb-0'>Beneficiaries</h1>
      </div>

      <Card className='p-3'>
        <Row className='tw-pb-4'>
          <Col md={4} xs={12}>
            <CustomSelect
              name='country_code'
              label='Country'
              isClearable
              handleClear={() => setCountryCode(null)}
              wrapperStyles={{ minWidth: '170px' }}
              value={countryCode}
              onChange={setCountryCode}
              options={
                data?.countries
                  ? data?.countries?.map((c) => ({
                      label: c?.name,
                      value: c?.iso2,
                      flag: `/${c.svg}`,
                    }))
                  : []
              }
            />
          </Col>

          <Col md={4} xs={12}>
            <CustomSelect
              name='currency'
              label='Currency'
              isClearable
              handleClear={() => setCurrencyCode(null)}
              wrapperStyles={{ minWidth: '170px' }}
              value={currencyCode}
              onChange={setCurrencyCode}
              options={
                data?.currencies
                  ? data?.currencies?.map((e) => ({
                      label: e.name,
                      value: e.code,
                      id: e.id,
                    }))
                  : []
              }
            />
          </Col>

          <Col md={3} xs={12} className='tw-flex tw-items-end'>
            <Button
              type='button'
              onClick={() => {
                setCountryCode(null)
                setCountryCode(null)
              }}
            >
              Clear Filter
            </Button>
          </Col>
        </Row>

        {isLoading ? (
          <div
            style={{ minHeight: '50vh' }}
            className='d-flex justify-content-center align-items-center'
          >
            <Spinner type='grow' color='primary' />
          </div>
        ) : (
          <>
            <DataTable responsive columns={columns} data={beneficiaries} />
          </>
        )}

        {!missingFields ? null : (
          <MissingFieldsModal
            isOpen={!!missingFields}
            fields={missingFields?.fields}
            onSubmit={handleAddBeneWithMissingInfo}
            toggle={() => setMissingFields(null)}
          />
        )}
      </Card>

      <BankDetailsSlider
        isOpen={!!bankAccountDetails}
        toggle={() => setBankAccountDetails(null)}
        details={bankDetails}
        isLoading={loadingDetails}
      />
    </div>
  )
}

function getMissingFieldsSchema(fields) {
  return fields.reduce((acc, field) => {
    return {
      ...acc,
      [field.id]: yup
        .string()
        .required(`Missing ${field.id} (${field.description})`),
    }
  }, {})
}

const missingFieldsFormId = 'missingFieldsFormId'
function MissingFieldsModal({ fields = [], isOpen, toggle, onSubmit }) {
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(yup.object().shape(getMissingFieldsSchema(fields))),
  })

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered unmountOnClose>
      <ModalHeader toggle={toggle}>Missing Fields</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)} id={missingFieldsFormId}>
          {fields.map(({ id, description }) => {
            return (
              <ControlledInput
                key={id}
                control={control}
                name={id}
                label={
                  <>
                    {id}{' '}
                    <span className='font-weight-light text-muted'>
                      ({description})
                    </span>
                  </>
                }
              />
            )
          })}
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color='light' outline onClick={toggle}>
          Cancel
        </Button>
        <Button type='submit' formId={missingFieldsFormId}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function BankDetailsSlider({ details = [], isLoading, isOpen, toggle }) {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 1500,
      }}
    >
      <Slide
        onClose={() => {
          toggle?.()
          document.body.classList.remove('modal-open')
        }}
        className='bg-white'
        styles={{
          bmMenu: { padding: '1.5rem' },
          bmItemList: { height: 'auto' },
        }}
        isOpen={isOpen}
        right
      >
        {isLoading ? (
          <div
            style={{ minHeight: '100vh' }}
            className='d-flex justify-content-center align-items-center'
          >
            <Spinner color='primary' />
          </div>
        ) : (
          <>
            <h3 className='mb-3'>Bank account details</h3>
            {details.map(({ name, value }) => {
              return (
                <FormGroup key={name}>
                  <Label tag='div' className='mb-2'>
                    {name}
                  </Label>
                  <Input value={value} readOnly />
                </FormGroup>
              )
            })}
          </>
        )}
      </Slide>
    </div>
  )
}

function ActionsColumn({ loading, onClickAction, rowData }) {
  if (rowData?.kyc_verification === true) {
    return 'KYC In Progress'
  }

  return (
    <Button
      size='sm'
      outline
      loading={loading}
      disabled={loading}
      onClick={onClickAction}
    >
      Add beneficiary
    </Button>
  )
}
