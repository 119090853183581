import React, { useState } from 'react'
import DataTable from '../../../../../components/ui/data-table'
import ContractRef from '../../../components/ContractRef'
import { getFullName } from '../../../../../utils/get-full-name'
import { format } from 'date-fns'
import Button from '../../../../../components/ui/button'
import { Eye, Signature } from '@phosphor-icons/react'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../../../../components/ui/side-menu'
import { ConfirmationModal } from '../../../../remotepass-cards/components/active-credit-card-section'
import { useFetch } from '../../../../../helpers/hooks'
import { approveAmendment, declineAmendment } from '../../../../../services/api'
import toastr from 'toastr'
import { Badge } from 'ui'

const statuses = {
  pending: { id: 'pending', name: 'Pending', color: 'yellow' },
  pending_signature: {
    id: 'pending_signature',
    name: 'Pending Signature',
    color: 'yellow',
  },
  signed: { id: 'signed', name: 'Signed', color: 'green' },
}

function AmendmentItem({ amendment }) {
  return (
    <div className='tw-px-4'>
      <div className='tw-mb-4 tw-text-base tw-font-bold tw-capitalize'>
        {amendment.name}
      </div>
      <div className='tw-mb-4 tw-rounded tw-border tw-border-secondary-30 tw-bg-surface-20 tw-px-4 tw-py-2'>
        <div className='tw-mb-1 tw-text-[10px] tw-text-text-80'>Old Value</div>
        <div className='tw-text-sm tw-text-text-80'>{amendment.oldValue}</div>
      </div>
      <div className='tw-flex tw-items-center tw-justify-between tw-rounded tw-border tw-border-secondary-30 tw-px-4 tw-py-2'>
        <div>
          <div className='tw-mb-1 tw-text-[10px] tw-text-text-80'>
            New Value
          </div>
          <div className='tw-text-sm tw-text-text-80'>{amendment.newValue}</div>
        </div>
        <Badge color='primary' size='sm'>
          Updated
        </Badge>
      </div>
    </div>
  )
}

function AmendmentsTable({
  data,
  refresh,
  canSign,
  handleOnSign,
  showContractInfo,
  clickedAmendmentToSign,
}) {
  const [detailsSideMenu, setDetailsSideMenu] = useState({
    isOpen: false,
    data: null,
  })

  const [confirmationModal, setConfirmationModal] = useState({
    isOpen: false,
    type: '',
    title: null,
    component: null,
    withReason: false,
    content: null,
    onConfirm: null,
    onClose: null,
    confirmText: null,
    isLoading: null,
  })

  const handleClose = () => {
    setDetailsSideMenu((prevState) => ({ ...prevState, isOpen: false }))
  }

  const setLoading = (loading) =>
    setConfirmationModal((prevState) => ({ ...prevState, isLoading: loading }))

  const closeConfirmationModal = () =>
    setConfirmationModal((prevState) => ({ ...prevState, isOpen: false }))

  const { startFetch: approve } = useFetch({
    action: approveAmendment,
    withAdminAccess: true,
    onComplete: () => {
      setLoading(false)
      handleClose()
      closeConfirmationModal()
      refresh?.()
      toastr.success('Amendment successfully approved!')
    },
    onError: (err) => {
      setLoading(false)
      toastr.error(err)
    },
  })

  const { startFetch: decline } = useFetch({
    action: declineAmendment,
    withAdminAccess: true,
    onComplete: () => {
      setLoading(false)
      closeConfirmationModal()
      refresh?.()
      toastr.info('Amendment was declined!')
    },
    onError: (err) => {
      setLoading(false)
      toastr.error(err)
    },
  })

  const handleApprove = ({ id }) => {
    setConfirmationModal({
      isOpen: true,
      title: 'Approve amendment',
      content: (
        <div className='text-center'>
          Are you sure you want to approve this amendment?
        </div>
      ),
      confirmText: 'Approve',
      onConfirm: () => {
        setLoading(true)
        approve({
          id,
        })
      },
      onClose: () => {
        closeConfirmationModal()
      },
    })
  }
  const handleDecline = ({ id }) => {
    setConfirmationModal({
      isOpen: true,
      title: 'Decline amendment',
      content: (
        <>
          <div className='text-center p-3'>
            Are you sure you want to decline this amendment?
          </div>
        </>
      ),
      withReason: { placeholder: 'Reason of decline', isRequired: true },
      confirmText: 'Decline',
      onConfirm: (ev, reason) => {
        setLoading(true)
        decline({
          id,
          reason,
        })
      },
      onClose: () => {
        closeConfirmationModal()
      },
    })
  }

  const columns = [
    showContractInfo && {
      Header: 'Contract ID',
      accessor: 'contract.ref',
      Cell: ({ cellData }) => {
        return (
          <ContractRef contractId={cellData} isAdmin={true} target={null} />
        )
      },
    },
    showContractInfo && {
      Header: 'Role',
      accessor: 'contract.name',
      Cell: ({ cellData }) => {
        return <>{cellData}</>
      },
    },
    showContractInfo && {
      Header: 'Company',
      accessor: 'contract.company_name',
      Cell: ({ cellData }) => {
        return <>{cellData}</>
      },
    },
    showContractInfo && {
      Header: 'Client',
      accessor: 'contract.client',
      Cell: ({ cellData }) => {
        return <>{getFullName(cellData)}</>
      },
    },
    showContractInfo && {
      Header: 'Employee',
      accessor: 'contract.employee',
      Cell: ({ cellData }) => {
        return <>{getFullName(cellData)}</>
      },
    },
    showContractInfo && {
      Header: 'Salary Currency',
      accessor: 'contract.salary_currency.name',
    },
    showContractInfo && {
      Header: 'Frequency',
      accessor: 'contract.frequency.name',
    },
    {
      Header: 'Created On',
      accessor: 'amendment.created_at',
      Cell: ({ cellData }) => {
        return cellData ? format(new Date(cellData), 'yyyy-MM-dd hh:mm') : null
      },
    },
    {
      Header: 'Effective Date',
      accessor: 'amendment.effective_date',
      Cell: ({ cellData }) => {
        return cellData ? format(new Date(cellData), 'yyyy-MM-dd hh:mm') : null
      },
    },
    {
      Header: 'Status',
      accessor: 'amendment.status',
      Cell: ({ cellData }) => {
        return (
          <Badge color={statuses[cellData]?.color ?? 'default'} size='sm'>
            {statuses[cellData]?.name ?? cellData}
          </Badge>
        )
      },
    },
    {
      Header: 'Actions',
      accessor: 'amendment',
      Cell: ({ cellData }) => (
        <div className='d-flex'>
          <Button
            textClassName='tw-flex tw-items-center tw-gap-1 tw-text-primary'
            size='sm'
            color='transparent'
            outline
            onClick={() => {
              setDetailsSideMenu({
                isOpen: true,
                data: cellData,
              })
            }}
          >
            <Eye size={20} /> Details
          </Button>
        </div>
      ),
    },
  ].filter(Boolean)
  function createMarkup(text) {
    return { __html: text }
  }
  return (
    <>
      <DataTable
        data={data}
        responsive
        centered={false}
        expandable={{
          expandedRowRender: (record) => {
            const amendments = record.amendment.details

            const HeaderComp = ({ value }) => (
              <div className='font-size-14 font-weight-bold'>{value}</div>
            )
            return (
              <>
                <tr>
                  <td></td>
                  <td>
                    <HeaderComp value='Field' />
                  </td>
                  <td>
                    <HeaderComp value='Old Value' />
                  </td>
                  <td>
                    <HeaderComp value='New Value' />
                  </td>
                </tr>
                {amendments?.map((amendment) => {
                  return (
                    <tr key={amendment.name} className=''>
                      <td></td>
                      <td className='tw-text-sm'>{amendment.name}</td>
                      <td
                        className='tw-min-w-[300px] tw-overflow-hidden !tw-whitespace-pre-wrap tw-text-sm tw-text-systemRed'
                        dangerouslySetInnerHTML={createMarkup(
                          amendment.oldValue,
                        )}
                      ></td>
                      <td
                        className='tw-min-w-[300px] tw-overflow-hidden !tw-whitespace-pre-wrap tw-text-sm tw-text-systemGreen'
                        dangerouslySetInnerHTML={createMarkup(
                          amendment.newValue,
                        )}
                      ></td>
                    </tr>
                  )
                })}
              </>
            )
          },
          rowExpandable: () => true,
        }}
        columns={columns}
      />
      <SideMenu
        className='tw-max-w-[440px]'
        itemListClassName='tw-grid tw-grid-rows-[77px_1fr_91px] [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain'
        isOpen={detailsSideMenu.isOpen}
        onClose={handleClose}
      >
        <SideMenuHeader toggle={handleClose}>Amendment</SideMenuHeader>
        <SideMenuBody className='!tw-px-0'>
          <div className='tw-px-4'>
            <div className='tw-mb-6 tw-text-base tw-font-bold'>Details</div>
            <div className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-surface-30 tw-py-4'>
              <div className='tw-text-sm tw-text-text-100'>Created on</div>
              <div className='tw-text-sm tw-font-medium'>
                {detailsSideMenu.data?.created_at
                  ? format(
                      new Date(detailsSideMenu.data?.created_at),
                      'dd/MM/yyyy hh:mm',
                    )
                  : null}
              </div>
            </div>
            <div className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-surface-30 tw-py-4'>
              <div className='tw-text-sm tw-text-text-100'>Effective Date</div>
              <div className='tw-text-sm tw-font-medium'>
                {detailsSideMenu.data?.effective_date
                  ? format(
                      new Date(detailsSideMenu.data?.effective_date),
                      'dd/MM/yyyy hh:mm',
                    )
                  : null}
              </div>
            </div>
          </div>
          <hr className='tw-my-8' />
          {detailsSideMenu.data?.details?.map((amendment, index) => (
            <div key={`${amendment}_${index}`}>
              <AmendmentItem amendment={amendment} />
              <hr className='tw-my-8' />
            </div>
          ))}
        </SideMenuBody>
        <SideMenuFooter>
          {detailsSideMenu.data?.status === statuses.pending.id ? (
            <div className='tw-flex tw-w-full tw-items-center tw-justify-between tw-p-4'>
              <Button
                color='danger'
                onClick={() => handleDecline({ id: detailsSideMenu.data?.id })}
              >
                Decline
              </Button>
              <Button
                color='success'
                onClick={() => handleApprove({ id: detailsSideMenu.data?.id })}
              >
                Approve
              </Button>
            </div>
          ) : detailsSideMenu.data?.status === statuses.pending_signature.id &&
            canSign ? (
            <div className='tw-w-full'>
              <Button
                block
                color='primary'
                textClassName='tw-flex tw-items-center tw-gap-1 tw-text-white'
                onClick={() => {
                  handleOnSign?.()
                  clickedAmendmentToSign?.(detailsSideMenu.data.id)
                }}
              >
                <Signature size={20} /> Sign as a provider
              </Button>
            </div>
          ) : null}
        </SideMenuFooter>
      </SideMenu>
      <ConfirmationModal
        isOpen={confirmationModal.isOpen}
        onCancel={confirmationModal.onClose}
        onConfirm={confirmationModal.onConfirm}
        withReason={confirmationModal.withReason}
        title={confirmationModal.title}
        loading={confirmationModal.isLoading}
        content={confirmationModal.content}
        toggle={() => closeConfirmationModal()}
        cancelColor='secondary'
        cancelText='Cancel'
        confirmText={confirmationModal.confirmText}
      />
    </>
  )
}

export default AmendmentsTable
