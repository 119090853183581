import {
  ArrowsLeftRight,
  Bank,
  Briefcase,
  Cardholder,
  CaretDown,
  ClipboardText,
  CreditCard,
  CurrencyCircleDollar,
  File,
  FileDashed,
  FileText,
  Files,
  IdentificationBadge,
  LinkBreak,
  Money,
  Scales,
  ShieldPlus,
  Swap,
  UserList,
  Users,
  Wallet,
} from '@phosphor-icons/react'
import MetisMenu from 'metismenujs'
import React, { useEffect, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import { Link, withRouter } from 'react-router-dom'

import { cn } from 'ui'
import { useAdminPermission } from '../../../../helpers/hooks'
import { adminPanelRoutes } from '../../../../routes/allRoutes'

const links = [
  {
    label: 'All Payments',
    to: '/admin/all-payments',
    customIcon: <Money weight='bold' className='tw-shrink-0' />,
  },
  {
    label: 'Transactions',
    to: '/admin/transactions',
    customIcon: <ArrowsLeftRight weight='bold' className='tw-shrink-0' />,
  },
  {
    label: 'Deposits and refunds',
    to: '/admin/deposits-refunds',
    customIcon: <ArrowsLeftRight weight='bold' className='tw-shrink-0' />,
  },
  {
    to: '/#!',
    customIcon: <Swap weight='bold' className='tw-shrink-0' />,
    label: 'Transfers',
    submenu: [
      { label: 'Pending Transfers', to: '/admin/pending-transfers' },
      { label: 'Confirmed Transfers', to: '/admin/confirmed-transfers' },
      { label: 'Internal Accounts', to: '/admin/internal-accounts' },
    ],
  },
  {
    to: '/#!',
    customIcon: <Scales weight='bold' className='tw-shrink-0' />,
    label: 'Balances',
    submenu: [
      { label: 'Contractors', to: '/admin/balance-contractors' },
      { label: 'Clients', to: '/admin/balance-clients' },
    ],
  },
  {
    to: '/admin/contracts',
    label: 'Contracts',
    customIcon: <FileText weight='bold' className='tw-shrink-0' />,
  },
  {
    to: '/admin/companies',
    customIcon: <Briefcase weight='bold' className='tw-shrink-0' />,
    label: 'Companies',
  },
  {
    to: '/admin/users',
    customIcon: <Users weight='bold' className='tw-shrink-0' />,
    label: 'Users',
  },
  {
    to: '/#!',
    customIcon: <CreditCard weight='bold' className='tw-shrink-0' />,
    label: 'Cards',
    submenu: [
      { to: '/admin/cards-kyc', label: 'KYC Cards' },
      { to: '/admin/cards-unpaid-fees', label: 'Unpaid fees' },
      { to: '/admin/cards-physical', label: 'Physical Requests' },
      { to: '/admin/cards-all', label: 'All Cards' },
      { to: '/admin/cards-transactions', label: 'Transactions Card' },
      { to: '/admin/cards-support', label: 'Supported countries' },
    ],
  },
  {
    to: '/admin/withdraw',
    customIcon: <Wallet weight='bold' className='tw-shrink-0' />,
    label: 'Auto withdraw',
  },
  {
    to: '/admin/templates',
    customIcon: <FileDashed weight='bold' className='tw-shrink-0' />,
    label: 'Templates',
  },
  {
    to: '/#!',
    customIcon: <Files weight='bold' className='tw-shrink-0' />,
    label: 'EOR',
    submenu: [
      { to: '/admin/requests', label: 'Contracts' },
      { to: '/admin/amendments', label: 'Amendments' },
      { to: '/admin/adjustments', label: 'Adjustments' },
      { to: '/admin/payrolls', label: 'Payroll' },
      { to: '/admin/eor-documents', label: 'Document requests' },
      { to: '/admin/partners', label: 'Partners' },
      { to: '/admin/countries', label: 'Regional Configurations' },
      { to: '/admin/contracts-invoices', label: 'Invoices' },
      { to: '/admin/partner-invoices', label: 'Partner Invoices' },
      { to: '/admin/renewal', label: 'Renewal' },
      { to: '/admin/EORCalculator', label: 'Calculator' },
      { to: '/admin/quote-generator', label: 'Quote generator' },
      { to: '/admin/generated-invoices', label: 'Invoice generator' },
    ],
  },
  {
    to: '/#!',
    customIcon: <Files weight='bold' className='tw-shrink-0' />,
    label: 'COR',
    submenu: [
      { to: '/admin/cor/contracts', label: 'Contracts' },
      { to: '/admin/cor/countries', label: 'Supported countries' },
    ],
  },
  {
    to: '/#!',
    customIcon: <IdentificationBadge weight='bold' className='tw-shrink-0' />,
    label: 'Direct Employee',
    submenu: [{ to: '/admin/jurisdictions', label: 'Jurisdictions' }],
  },
  {
    to: '/#!',
    customIcon: <ShieldPlus weight='bold' className='tw-shrink-0' size={24} />,
    label: 'Insurance',
    submenu: [
      { to: '/admin/insurance', label: 'Plans' },
      { to: '/admin/insurance-payments', label: 'Payments' },
      {
        to: '/admin/upcoming-overdue-payments',
        label: 'Upcoming & Overdue Payments',
      },
    ],
  },
  {
    to: '/#!',
    customIcon: <CurrencyCircleDollar weight='bold' className='tw-shrink-0' />,
    label: 'Markups',
    submenu: [
      {
        to: '/admin/currency-markup',
        label: 'Client Currency Markups',
      },
      {
        to: '/admin/wallet-conversion-markups',
        label: 'Wallet Conversion Markups',
      },
    ],
  },
  {
    to: '/admin/withdrawal-methods',
    customIcon: <Cardholder weight='bold' className='tw-shrink-0' size={32} />,
    label: 'Withdrawal Methods',
  },
  {
    to: '/#!',
    customIcon: <UserList weight='bold' className='tw-shrink-0' />,
    label: 'Admin users',
    submenu: [
      { to: '/admin/admin-list', label: 'All Admins' },
      { to: '/admin/admin-roles', label: 'Admin Roles' },
    ],
  },
  {
    to: '/admin/beneficiaries',
    customIcon: <Bank weight='bold' className='tw-shrink-0' />,
    label: 'Bank Beneficiaries',
  },
  {
    to: '/admin/forms',
    customIcon: <File weight='bold' className='tw-shrink-0' />,
    label: 'Manage Forms',
  },
  {
    to: '/admin/provider-rules',
    customIcon: <ClipboardText weight='bold' className='tw-shrink-0' />,
    label: 'Providers Rules',
  },
  {
    to: '/admin/forbidden-domains',
    customIcon: <LinkBreak weight='bold' className='tw-shrink-0' />,
    label: 'Forbidden Domains',
  },
]

function getAdminPermissions(path) {
  return adminPanelRoutes.find((route) => route.path === path)?.permissions
}

const SidebarContent = (props) => {
  const metisInit = useRef(null)
  const { hasAccess } = useAdminPermission()

  const pathName = props.location.pathname

  useEffect(() => {
    function initMenu() {
      // eslint-disable-next-line no-new
      new MetisMenu('#side-menu')
      let matchingMenuItem = null
      const ul = document.getElementById('side-menu')
      const items = ul.getElementsByTagName('a')

      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }

      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
      metisInit.current = true
    }

    if (!metisInit.current) {
      initMenu()
    }
  }, [pathName])

  function activateParentDropdown(item) {
    const parent = item.parentElement

    if (parent) {
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add('mm-show')
      }
    }
  }

  return (
    <div id='sidebar-menu' className='tw-py-6'>
      <ul className='metismenu tw-space-y-1' id='side-menu'>
        {links.map(({ customIcon, icon, label, submenu, to }, index) => {
          const linkIcon = customIcon ?? <i className={icon} />

          const submenuPermsList = submenu
            ?.map((sub) => getAdminPermissions(sub?.to))
            ?.map((permissions) =>
              !permissions ? true : hasAccess(permissions),
            )

          const submenuPerms =
            submenuPermsList?.length > 0
              ? submenuPermsList?.some(Boolean)
              : true

          const permissions = getAdminPermissions(to)

          const hasPerms =
            !to || to === '/#!' || to === '/#'
              ? !!submenu && submenuPerms
              : !permissions
                ? true
                : hasAccess(permissions)

          if (!hasPerms) {
            return null
          }

          const tooltipId = `side-tooltip-${index}`

          const isActive = pathName === to

          return (
            <li key={index}>
              <AdminSideLink
                to={to}
                className='tw-group tw-flex tw-items-center tw-gap-2.5 tw-px-6 tw-py-2.5 [&>*:first-child]:tw-size-4 [&[aria-expanded="true"]>*:last-child]:tw-rotate-180'
                isActive={isActive}
                id={tooltipId}
              >
                {linkIcon}

                <span style={{ whiteSpace: 'nowrap' }}>{label}</span>

                {!submenu ? null : (
                  <CaretDown
                    weight='bold'
                    size={16}
                    className='tw-ml-auto tw-shrink-0 tw-transition-transform [body.vertical-collpsed_&]:tw-hidden [body.vertical-collpsed_&]:group-hover:tw-block'
                  />
                )}
              </AdminSideLink>

              {!submenu ? null : (
                <ul className='sub-menu'>
                  {submenu.map(({ label, to }, subIndex) => {
                    const perms = getAdminPermissions(to)
                    const hasPerms = !perms ? true : hasAccess(perms)

                    if (!hasPerms) {
                      return null
                    }

                    const subTooltipId = `side-sub-tooltip-${index}-${subIndex}`

                    const isActive = pathName === to

                    return (
                      <li key={subIndex}>
                        <AdminSideLink
                          to={to}
                          className='tw-block tw-whitespace-nowrap tw-py-1.5 tw-pl-12 tw-pr-6'
                          isActive={isActive}
                          id={subTooltipId}
                        >
                          {label}
                        </AdminSideLink>
                      </li>
                    )
                  })}
                </ul>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

function AdminSideLink({ className, isActive, ...props }) {
  return (
    <Link
      className={cn(
        'tw-relative tw-text-sm tw-text-current hover:tw-text-text-100',
        isActive
          ? '!tw-cursor-default tw-font-semibold tw-text-secondary-120'
          : 'tw-opacity-70 hover:tw-bg-secondary-40/90 hover:tw-opacity-85',
        className,
      )}
      {...props}
    />
  )
}

export default withRouter(withTranslation()(SidebarContent))
