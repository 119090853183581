import { t } from 'i18next'
import React from 'react'
import { Modal, ModalHeader, Row, Spinner } from 'reactstrap'
import WithdrawDetails from './WithdrawDetail'

const MyComponent = ({ isOpen, toggle, loading, data }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <h5 className='text-primary rp-font-bold'>{t('Withdraw Status')}</h5>
      </ModalHeader>
      <div className='modal-content'>
        <div className='modal-body' style={{ minHeight: '50vh' }}>
          {loading ? (
            <Row
              className='justify-content-center align-items-center'
              style={{ minHeight: '50vh' }}
            >
              <Spinner type='grow' className='mr-2' color='primary' />
            </Row>
          ) : (
            <WithdrawDetails bankInfo={data} />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default MyComponent
