import { ArrowRight } from '@phosphor-icons/react'
import { t } from 'i18next'
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'

import { Avatar } from 'ui'
import { ModalCloseButton } from '../../../../components/Common/modal-close-button'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import Button from '../../../../components/ui/button'
import Shimmer from '../../../../components/ui/shimmer'
import { useFetch } from '../../../../helpers/hooks'
import { putIntegrationVendors } from '../../../../services/api-integrations'
import { MappingItem, useIntegrationAccounts } from '../integration-review-tabs'
import { ContractorCell } from './contractor-cell'

export function UpdateContractorCategories({ itemData, integration }) {
  const [isOpen, setIsOpen] = useState(false)
  function toggle() {
    setIsOpen((open) => !open)
  }

  return (
    <>
      <Button
        color='link'
        className='!tw-px-0'
        type='button'
        onClick={() => setIsOpen(true)}
      >
        {t('Customize Categories')}
      </Button>

      <UpdateModal
        isOpen={isOpen}
        toggle={toggle}
        itemData={itemData}
        integration={integration}
      />
    </>
  )
}

function UpdateModal({ isOpen, toggle, itemData, integration }) {
  const { key: integrationKey, name: integrationName } = integration

  const contractor = itemData.contractor

  const [accounts, setAccounts] = useState(() => {
    if (Object.values(itemData.accountsMap).length > 0) {
      return Object.values(itemData.accountsMap).reduce((acc, value) => {
        acc[value.rpAccountId] = value.integrationAccountId
        return acc
      }, {})
    }

    return {}
  })

  function handleAccountChange({ value }, account) {
    setAccounts((prev) => ({ ...prev, [account.accountId]: value }))
  }

  const { integrationAccounts, accountsLoading } = useIntegrationAccounts({
    integrationKey,
    enabled: isOpen,
  })

  const { startFetch: syncContractors, isLoading: syncingContractors } =
    useFetch({
      action: putIntegrationVendors,
      autoFetch: false,
      onError: (error) => {
        toastr.error(error || 'Something went wrong')
      },
      onComplete: () => {
        toastr.success(t('Workers synced successfully'))
        toggle?.()
      },
    })

  function handleSaveItemMapping() {
    syncContractors({
      integrationKey,
      vendorMappings: [
        {
          contractor: itemData.contractor,
          vendor: itemData.vendor,
          accountsMap: Object.entries(accounts).map(([accountId, value]) => ({
            rpAccountId: Number(accountId),
            integrationAccountId: value,
          })),
        },
      ],
    })
  }

  if (!isOpen) {
    return null
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader close={<ModalCloseButton toggle={toggle} />}>
        {t('Customize Categories')}
      </ModalHeader>
      <ModalBody>
        <div className='tw-flex tw-items-center tw-gap-2'>
          <Avatar size={24} name={contractor.firstName} />
          <div>
            <ContractorCell contractor={contractor} />
          </div>
        </div>

        <h4 className='tw-mb-2 tw-mt-6 tw-text-base tw-font-semibold'>
          {integrationName}
          {t('accounts')}
        </h4>
        <p className='tw-mb-0 tw-text-sm tw-text-disabled'>
          {t(
            'RemotePass categories on the left will be posted to the accounts on the right',
            { integrationName },
          )}
        </p>

        <div className='tw-mt-6 tw-grid tw-items-center tw-gap-2 sm:tw-grid-cols-[1fr_auto_1fr] sm:tw-gap-3'>
          <MappingItem className='tw-hidden sm:tw-contents'>
            <div>{t('Categories')}</div>
            <div />
            <div>{t('Accounts')}</div>
          </MappingItem>

          {accountsLoading ? (
            <MappingItem>
              <Shimmer width='100%' height={42} />
              <div />
              <Shimmer width='100%' height={42} />
            </MappingItem>
          ) : (
            integrationAccounts.internalAccounts.map((account) => {
              const defaultAccountsMap = integrationAccounts.accountsMap.find(
                (map) => map.rpAccountId === account.accountId,
              )

              const defaultLocalAccountsMap = itemData.accountsMap.find(
                (map) => map.rpAccountId === account.accountId,
              )

              const idValue =
                accounts?.[account.accountId] ||
                defaultLocalAccountsMap?.integrationAccountId ||
                defaultAccountsMap?.integrationAccountId

              const selectedValue =
                integrationAccounts.integrationAccounts.find(
                  (account) => String(account.id) === String(idValue),
                )

              return (
                <MappingItem key={account.accountId}>
                  <div className='form-control' readOnly>
                    {account.name}
                  </div>
                  <ArrowRight
                    size={20}
                    weight='bold'
                    className='tw-mx-auto tw-rotate-90 tw-text-primary-100 sm:tw-rotate-0 sm:rtl:tw-rotate-180'
                  />
                  <CustomSelect
                    options={integrationAccounts.integrationAccounts?.map(
                      (account) => {
                        return { label: account.name, value: account.id }
                      },
                    )}
                    isLoading={accountsLoading}
                    isDisabled={accountsLoading}
                    wrapperClassName='tw-mb-6 sm:tw-mb-0'
                    value={
                      !selectedValue
                        ? null
                        : { label: selectedValue.name, value: selectedValue.id }
                    }
                    onChange={(value) => handleAccountChange(value, account)}
                  />
                </MappingItem>
              )
            })
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type='button'
          onClick={toggle}
          color='light'
          outline
          disabled={syncingContractors}
        >
          {t('Cancel')}
        </Button>
        <Button
          type='button'
          onClick={handleSaveItemMapping}
          loading={syncingContractors}
          disabled={syncingContractors}
        >
          {t('Save Changes')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
