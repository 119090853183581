import { t } from 'i18next'
import { X } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'

import Button from '../../../components/ui/button'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  assignContractToSignatory,
  cancelSignatoryInvitation,
  getSignatoryList,
} from '../../../services/api'
import { getFullName } from '../../../utils/get-full-name'

const SelectSignatoryDropdown = ({
  onAssigned,
  onCancel,
  onSign,
  isAdmin,
  isDisabled,
}) => {
  const user = useSelector((state) => state.Account?.user)
  const contract = useSelector((state) => state.Contract?.details)
  const { hasAccess } = usePermissions()
  const [isOpen, setIsOpen] = useState(false)
  const signatories = useFetch({
    action: getSignatoryList,
    autoFetch: true,
  })

  const assign = useFetch({
    action: assignContractToSignatory,
    onComplete: () => {
      onAssigned()
    },
  })
  const cancel = useFetch({
    action: cancelSignatoryInvitation,
    onComplete: () => {
      onCancel()
    },
  })

  const handleSignatorySelection = (signatory) => {
    if (signatory?.email === user?.email) {
      onSign()
    } else {
      assign.startFetch({
        contract_id: contract?.id,
        signatory_id: signatory?.id,
      })
    }
  }
  const handleCancelInvitation = () => {
    cancel.startFetch({ contract_id: contract?.id })
  }

  const disabled =
    !hasAccess([permissions.assignSignatory, permissions.viewSignatories]) ||
    [5, 6, 7, 1].includes(contract?.status?.id) ||
    isAdmin ||
    signatories.isLoading ||
    isDisabled

  const signatoryFullName = getFullName(contract?.signatory)

  const [openJobTitleModal, setOpenJobTitleModal] = useState(false)
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const clientHasJobTitle = !!userProfile?.job_title
  const isDeContract = contract?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE
  const jobTitleRequired = isDeContract

  return contract?.signatory ? (
    <div className='w-100'>
      <Col className='p-0 m-0 w-100 mt-3 d-flex justify-content-end'>
        <Button
          block
          className='btn-danger-cancel'
          onClick={handleCancelInvitation}
          disabled={
            !hasAccess(permissions.cancelPendingSignatory) || cancel.isLoading
          }
          loading={cancel.isLoading}
          color='danger'
        >
          {t('Cancel Invitation')}
        </Button>
      </Col>
      <div className='text-muted font-size-12'>
        {t('Assigned to')} {signatoryFullName}
      </div>
    </div>
  ) : (
    <>
      <Dropdown
        isOpen={isOpen}
        toggle={() => {
          if (jobTitleRequired && !clientHasJobTitle) {
            setOpenJobTitleModal(true)
          } else {
            setIsOpen(!isOpen)
          }
        }}
        disabled={disabled}
      >
        <DropdownToggle
          disabled={disabled}
          tag={Button}
          loading={assign.isLoading}
          block
        >
          {contract?.status?.id === 1
            ? t('Pending Quote')
            : t('Select Signatory')}
        </DropdownToggle>

        {signatories.isLoading ||
        !signatories?.data ||
        signatories?.data?.length <= 0 ? null : (
          <DropdownMenu>
            <DropdownItem header>{t('Select signatory')}</DropdownItem>

            {signatories.data?.map((signatoryItem) => {
              const signatory = formatSignatoryName(signatoryItem, user)
              return (
                <DropdownItem
                  onClick={() => handleSignatorySelection(signatory)}
                  key={signatory.id}
                >
                  {signatory.itemName}
                </DropdownItem>
              )
            }) ?? null}
          </DropdownMenu>
        )}
      </Dropdown>

      <JobTitleModal
        open={openJobTitleModal}
        toggle={() => setOpenJobTitleModal((open) => !open)}
      />
    </>
  )
}

export function formatSignatoryName(signatory, user) {
  const isMe = user?.email === signatory.email

  const fullName = getFullName(signatory)

  const itemName = [fullName || signatory.email || '', isMe ? '(Me)' : null]
    .filter(Boolean)
    .join(' ')

  return { ...signatory, itemName }
}

export function JobTitleModal({ open, toggle }) {
  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader
        close={
          <button
            className='close d-flex'
            onClick={toggle}
            type='button'
            style={{ height: 34, width: 34 }}
          >
            <X />
          </button>
        }
        className='border-0'
      >
        {t('Job title required')}
      </ModalHeader>
      <ModalBody
        className='font-size-16 text-text-90 d-flex justify-content-center align-items-center flex-column'
        style={{ minHeight: 200 }}
      >
        <p className='mb-0'>
          {t('The job title is required to sign this contract.')}
        </p>
        <p className='mb-0'>
          {t('You can set it in the profile settings page.')}
        </p>
      </ModalBody>
      <ModalFooter className='border-0 justify-content-center'>
        <Button color='light' outline onClick={toggle}>
          {t('Cancel')}
        </Button>
        <Button tag={Link} to='/profile-settings'>
          {t('Go to profile settings')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SelectSignatoryDropdown
