export const FiltersTypes = {
  DEFAULT: { title: 'Show on Calendar', key: 'event_types' },
  CONTRACT_TYPE: { title: 'Contract type', key: 'contract_types' },
  CONTRACT_STATUS: { title: 'Contract status', key: 'contract_statuses' },
  CONTRACT_TAGS: { title: 'Contract tags', key: 'contract_tags' },
  COUNTRY_OF_RESIDENCE: {
    title: 'Country of residence',
    key: 'contract_countries',
  },
}

export const defaultFilters = [
  {
    label: 'Approved time off',
    value: 'time_off',
  },
  { label: 'Requested Time off', value: 'time_off_request' },
  { label: 'Birthday', value: 'birthday' },
  { label: 'Work anniversaries', value: 'work_anniversary' },
  { label: 'New Joiners', value: 'new_joiner' },
]

export const showOnCalendarTypeFilters = [
  {
    label: 'Approved time off',
    value: 'time_off',
  },
  { label: 'Requested Time off', value: 'time_off_request' },
  { label: 'Birthday', value: 'birthday' },
  { label: 'Work anniversaries', value: 'work_anniversary' },
  { label: 'New Joiners', value: 'new_joiner' },
  {
    label: 'Company National holidays',
    value: 'company_national_holiday',
  },
  {
    label: 'Team National holidays',
    value: 'worker_national_holiday',
  },
]

export const contractTypeFilters = {
  title: FiltersTypes.CONTRACT_TYPE.title,
  key: FiltersTypes.CONTRACT_TYPE.key,
  filters: [
    {
      label: 'Direct Employee',
      value: 'direct_employee',
    },
    {
      label: 'Milestones',
      value: 'milestone',
    },
    {
      label: 'Fixed',
      value: 'fixed',
    },

    {
      label: 'EOR',
      value: 'full_time',
    },
    {
      label: 'Pay As You Go',
      value: 'payg',
    },
  ],
}
