import { searchParamsFromObject } from '../utils/search-params-from-object'
import { apiV2, getConfig } from './api'

export function authenticateIntegration(token, _, extra) {
  return apiV2.post(
    '/integrations/api/v1/authentication',
    undefined,
    getConfig(token, extra),
  )
}

export function getIntegrationVendors(token, { integrationKey }, extra) {
  return apiV2.get(
    `/integrations/api/v1/integrations/${integrationKey}/vendors`,
    getConfig(token, extra),
  )
}

export function putIntegrationVendors(token, data, extra) {
  const { integrationKey, ...body } = data
  return apiV2.put(
    `/integrations/api/v1/integrations/${integrationKey}/vendors`,
    body,
    getConfig(token, extra),
  )
}

export function getIntegrationAccounts(token, { integrationKey }, extra) {
  return apiV2.get(
    `/integrations/api/v1/integrations/${integrationKey}/accounts`,
    getConfig(token, extra),
  )
}

export function putIntegrationAccounts(token, data, extra) {
  const { integrationKey, ...body } = data

  return apiV2.put(
    `/integrations/api/v1/integrations/${integrationKey}/accounts`,
    body,
    getConfig(token, extra),
  )
}

export function getIntegrationInvoices(token, { integrationKey }, extra) {
  return apiV2.get(
    `/integrations/api/v1/integrations/${integrationKey}/invoices`,
    getConfig(token, extra),
  )
}

export function putIntegrationInvoices(token, data, extra) {
  const { integrationKey, ...body } = data

  const cleanBody = { ...body }
  delete cleanBody.successMessage

  return apiV2.put(
    `/integrations/api/v1/integrations/${integrationKey}/invoices`,
    cleanBody,
    getConfig(token, extra),
  )
}

export function getIntegrationCostCenters(token, { integrationKey }, extra) {
  return apiV2.get(
    `/integrations/api/v1/integrations/${integrationKey}/cost-center`,
    getConfig(token, extra),
  )
}

export function putIntegrationCostCenters(token, data, extra) {
  const { integrationKey, ...body } = data
  return apiV2.put(
    `/integrations/api/v1/integrations/${integrationKey}/cost-center`,
    body,
    getConfig(token, extra),
  )
}

export function getIntegrations(token, _, extra) {
  return apiV2.get('/integrations/api/v1/integrations', getConfig(token, extra))
}

export function getIntegration(token, data, extra) {
  const { integrationKey } = data
  return apiV2.get(
    `/integrations/api/v1/integrations/${integrationKey}`,
    getConfig(token, extra),
  )
}

export function getConnectIntegration(token, data, extra) {
  const { integrationKey, ...body } = data
  const sp = searchParamsFromObject(body)

  return apiV2.get(
    `/integrations/api/v1/authentication/connect/oauth/${integrationKey}${sp}`,
    getConfig(token, extra),
  )
}

export function getDisconnectIntegration(token, data, extra) {
  return apiV2.post(
    `/integrations/api/v1/authentication/disconnect/oauth/${data.integrationKey}`,
    undefined,
    getConfig(token, extra),
  )
}

export function getIntegrationSyncStatus(token, data, extra) {
  const { integrationKey } = data
  return apiV2.get(
    `/integrations/api/v1/integrations/${integrationKey}/invoices/sync-status`,
    getConfig(token, extra),
  )
}
