import { t } from 'i18next'
import { Plus } from '@phosphor-icons/react'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'

import Loader from '../../../components/ui/loader'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { CONTRACT_STATUS, userTypes } from '../../../helpers/enum'
import { useFetch } from '../../../helpers/hooks'
import {
  getContractPaidAndUnPaidDays,
  getContractPaidAndUnPaidDaysAdmin,
  getContractTimeOff,
  getContractTimeOffAdmin,
  getTimeOffPolicies,
} from '../../../services/api-time-off-policies'
import { AddTimeOff } from '../../time-off/add-time-off'
import { getBodyFromFilters, useTimeOffFetch } from '../../time-off/helpers'
import TimeOffEmpty from '../../time-off/time-off-empty'
import TimeOffList, { timeOffFiltersKeys } from '../../time-off/time-off-list'
import TabCardHeader from '../components/tab/tab-card-header'
import { AssignPolicyAction } from './assign-time-off-policy'
import { TimeOffStats } from './time-off-stats'

export function TimesOffTab({ contract, updateContract }) {
  const location = useLocation()
  const isAdmin = location.pathname?.startsWith('/admin/')

  const isEmployee = contract.type === CONTRACT_TYPES.FULL_TIME

  const user = useSelector((state) => state.Account?.user)
  const isClient = user?.type === userTypes.COMPANY

  const contractId = contract?.id

  const {
    timeOffs,
    gettingTimeOffList,
    updateTimeOff,
    paginator,
    filters,
    handleFiltersChange,
    willAutoFetch,
    doneFetching,
  } = useTimeOffFetch({
    isAdmin,
    isEmployee,
    isContractDetails: true,
    contractId,
    autoFetch: !!contractId,
  })

  // @todo: remove this after refactor from BE to unify time-off response for both admin and non-admin endpoints
  const _timeOffs = isAdmin ? timeOffs : timeOffs?.list

  const {
    data: policies,
    isLoading: isLoadingPolicies,
    startFetch: refreshContractPolicies,
  } = useFetch(
    {
      action: !isAdmin ? getContractTimeOff : getContractTimeOffAdmin,
      body: { contract_id: contractId },
      autoFetch: !!contractId,
      withAdminAccess: isAdmin,
    },
    [contractId],
  )

  const { data: companyPolicies, isLoading: fetchingCompanyPolicies } =
    useFetch({ action: getTimeOffPolicies, autoFetch: isClient }, [isClient])

  const {
    data: paidAndUnPaidDays,
    isLoading: gettingPaidDays,
    startFetch: getPaidAndUnpaidDays,
  } = useFetch(
    {
      action: !isAdmin
        ? getContractPaidAndUnPaidDays
        : getContractPaidAndUnPaidDaysAdmin,
      autoFetch: contractId,
      body: getBodyFromFilters(filters, contractId),
      withAdminAccess: isAdmin,
    },
    [contractId, filters],
  )

  const timeOffFilters = Object.entries(filters).filter(([key]) => {
    return timeOffFiltersKeys.includes(key)
  })
  const hasFilters =
    timeOffFilters.length > 0 &&
    timeOffFilters.filter(([, value]) => !!value).length > 0
  const isListEmpty = !timeOffs || _timeOffs?.length <= 0

  const isPageEmpty = !hasFilters && isListEmpty
  const timeOffsList = _timeOffs || []
  const isLoadingTimeOff = willAutoFetch ? !doneFetching : gettingTimeOffList
  const isPoliciesEmpty = policies?.length <= 0

  return (
    <Card className='rp-shadow-2'>
      <TabCardHeader
        title={t('Time off')}
        extra={
          gettingTimeOffList ? null : (
            <div className='tw-flex tw-flex-wrap tw-gap-2 md:tw-flex-nowrap'>
              {!isClient || isPoliciesEmpty ? null : (
                <AssignPolicyAction
                  contract={contract}
                  onSuccess={() => {
                    updateTimeOff()
                    refreshContractPolicies()
                  }}
                />
              )}

              {(contract &&
                contract.status.id === CONTRACT_STATUS.TERMINATED.value) ||
              isPageEmpty ? null : (
                <AddTimeOff
                  contract={contract}
                  timeOffs={timeOffsList}
                  onSubmit={() => {
                    updateTimeOff()
                    refreshContractPolicies()
                    getPaidAndUnpaidDays()
                    updateContract?.()
                  }}
                  btnProps={{ icon: <Plus size={20} />, className: '!tw-px-6' }}
                />
              )}
            </div>
          )
        }
      />

      <TimeOffStats
        contract={contract}
        updateContract={updateContract}
        policies={policies}
        isLoadingPolicies={isLoadingPolicies}
        refreshPolicies={() => {
          refreshContractPolicies()
          getPaidAndUnpaidDays()
        }}
        className={{
          'tw-border-b-0': !contract?.contractor,
          'tw-mb-0 tw-border-b-0': isPoliciesEmpty,
        }}
        companyPolicies={companyPolicies}
        fetchingCompanyPolicies={fetchingCompanyPolicies}
        updateTimeOff={updateTimeOff}
      />

      {contract?.contractor ? (
        isLoadingTimeOff ? (
          <Loader minHeight='30rem' />
        ) : isPoliciesEmpty ? null : isPageEmpty &&
          !gettingTimeOffList &&
          !hasFilters ? (
          <TimeOffEmpty
            contract={contract}
            timeOffs={timeOffsList}
            onSubmit={updateTimeOff}
          />
        ) : (
          <CardBody className='px-md-0'>
            <TimeOffList
              timeOffsList={timeOffsList}
              paginator={paginator}
              filters={filters}
              handleFiltersChange={handleFiltersChange}
              update={() => {
                updateTimeOff()
                refreshContractPolicies()
                getPaidAndUnpaidDays()
              }}
              contract={contract}
              gettingTimeOffList={gettingTimeOffList || gettingPaidDays}
              paidDays={paidAndUnPaidDays?.days_paid}
              unPaidDays={paidAndUnPaidDays?.days_unpaid}
            />
          </CardBody>
        )
      ) : null}
    </Card>
  )
}
