import { t } from 'i18next'
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'

import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import Button from '../../../components/ui/button'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { useFetch } from '../../../helpers/hooks'
import { declineMilestone, declineWork } from '../../../services/api'
import { getErrorMessage } from '../../../utils/get-errors'

export function WorkDeclineModal({ isOpen, toggle, item, onDeclineSuccess }) {
  const [declineReason, setDeclineReason] = useState('')

  function getDeclineAction(contractType) {
    switch (contractType) {
      case CONTRACT_TYPES.PAY_AS_YOU_GO: {
        return declineWork
      }
      case CONTRACT_TYPES.MILESTONES: {
        return declineMilestone
      }
      default: {
        return () => {}
      }
    }
  }

  const { startFetch: decline, isLoading: decliningWork } = useFetch({
    action: getDeclineAction(item?.contract_type),
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Something went wrong while declining work')
      } else {
        toastr.success('Work declined successfully')
        onDeclineSuccess?.()
      }
    },
    onError: (error) => {
      toastr.error(getErrorMessage(error))
    },
  })

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader
        className='!tw-border-none !tw-pb-0'
        close={<ModalCloseButton toggle={toggle} />}
      >
        {t('Decline Reason')}
      </ModalHeader>
      <ModalBody>
        <textarea
          name='raison'
          className='form-control'
          value={declineReason}
          onChange={(e) => setDeclineReason(e.target.value)}
        />
      </ModalBody>
      <ModalFooter>
        <Button color='light' outline onClick={toggle} disabled={decliningWork}>
          {t('Cancel')}
        </Button>

        <Button
          color='danger'
          onClick={() => {
            if (!declineReason) {
              toastr.error('Please provide a reason for declining the work')
              return
            }
            decline({ work_id: item?.id, comment: declineReason })
          }}
          disabled={decliningWork}
          loading={decliningWork}
        >
          {t('Decline')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
