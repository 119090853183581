import { searchParamsFromObject } from '../utils/search-params-from-object'
import { api, getConfig } from './api'

export const getCalendarData = (token, data) => {
  const sp = searchParamsFromObject(data, true, false, true)
  return api.get('calendar/events' + sp, getConfig(token))
}

export const getUpcomingEvents = (token, data, extra) => {
  return api.get('widgets/upcoming_events', getConfig(token, extra))
}

export const getTimelineEvents = (token, data, extra) => {
  const sp = searchParamsFromObject(data, true, false, true)
  return api.get('calendar/timeline/events' + sp, getConfig(token, extra))
}

export const getCalendarLinks = (token) => {
  return api.get('calendar/ical_links', getConfig(token))
}

export const getInsights = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get('/calendar/insights' + sp, getConfig(token))
}

export const ignoreInsights = (token, data) => {
  return api.post('/calendar/insights/ignore', data, getConfig(token))
}

export const createCalendarLink = (token, data) => {
  return api.post('calendar/ical_links', data, getConfig(token))
}

export const deleteCalendarLink = (token, data) => {
  return api.delete('calendar/ical_links/' + data?.id, getConfig(token))
}

export const getWorkersInCalendarView = (token, data) => {
  const sp = searchParamsFromObject(data, true, false, true)
  return api.get('calendar/workers' + sp, getConfig(token))
}
