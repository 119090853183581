import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Container, Modal, ModalBody, ModalFooter } from 'reactstrap'

import KycVerification from '../../assets/images/kyc-verification.svg'
import ModalHeader from '../../components/ModalHeader'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import { userTypes } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import {
  createIdwiseSession,
  getClientInfo,
  getContractorInfo,
} from '../../services/api'
import { t } from 'i18next'

const idWiseMountElementId = 'idwise-verification'

const idwiseScriptLink =
  'https://releases.idwise.com/websdk/latest/idwise.min.js'
const idwiseCssLink = 'https://releases.idwise.com/websdk/latest/idwise.min.css'

function removeIdwiseScripts() {
  const head = document.head
  const linkStyleQuery = `link[href="${idwiseCssLink}"]`
  const linkStyle = document.querySelector(linkStyleQuery)
  const scriptQuery = `script[src="${idwiseScriptLink}"]`
  const script = document.querySelector(scriptQuery)

  head.removeChild(linkStyle)
  head.removeChild(script)
}

function initIDWise(IDWise) {
  const config = {
    locale: 'en', // todo: local should depend on user preference
    clientKey: process.env.REACT_APP_IDWISE_API_SECRET,
  }

  return IDWise.initialize(config)
}

function startIDWiseJourney(idwise, { mountId, referenceNo = '', onFinished }) {
  idwise.cleanup()

  idwise.startJourney({
    mount: `#${mountId}`,
    journeyDefinitionId: process.env.REACT_APP_IDWISE_BUSSINESS_ID,
    referenceNo,
    eventHandlers: {
      onJourneyFinished: onFinished,
    },
  })
}

function startIdwiseVerification({
  onFinished = () => {},
  referenceNo = '',
  onScriptLoaded = () => {},
}) {
  const head = document.head
  const linkStyle = document.createElement('link')
  linkStyle.setAttribute('href', idwiseCssLink)
  linkStyle.setAttribute('rel', 'stylesheet')

  const script = document.createElement('script')
  script.setAttribute('src', idwiseScriptLink)
  script.onload = async () => {
    const IDWise = window.IDWise
    const idwise = await initIDWise(IDWise)

    if (typeof onScriptLoaded === 'function') {
      onScriptLoaded()
    }

    startIDWiseJourney(idwise, {
      mountId: idWiseMountElementId,
      referenceNo,
      onFinished,
    })
  }

  head.append(linkStyle)
  head.append(script)
}

async function cleanupIdWise() {
  const IDWise = window.IDWise
  const idwise = await initIDWise(IDWise)

  idwise.cleanup()
}

export default function IdwiseVerification() {
  const [failureModal, showFailureModal] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const history = useHistory()

  const user = useSelector((state) => state.Account?.user)
  const isClient = user?.type === userTypes.COMPANY

  const { startFetch: createSession } = useFetch({
    action: createIdwiseSession,
    onComplete: () => {
      setTimeout(() => {
        setPageLoading(false)

        backToSetting()
      }, 2000)
    },
  })

  function onFinishedVerification({ journeyId }) {
    setPageLoading(true)
    createSession({ session_id: journeyId })
  }

  const verifyAgain = async () => {
    window.location.reload(false)
    showFailureModal(false)
  }

  const contractorInfo = useFetch({
    action: isClient ? getClientInfo : getContractorInfo,
    autoFetch: true,
    onComplete: (data) => {
      if (['submitted', 'verified'].includes(data.kyc_status)) {
        backToSetting()
      } else {
        const kycId = data?.kyc_id
        setPageLoading(true)
        startIdwiseVerification({
          referenceNo: kycId,
          onFinished: onFinishedVerification,
          onScriptLoaded: () => setPageLoading(false),
        })
      }
    },
    onError: () => {
      setPageLoading(false)
    },
  })

  const isLoading =
    contractorInfo.loading || !contractorInfo.completed || pageLoading

  function backToSetting() {
    const url = isClient
      ? '/profile-settings?tab=verification'
      : '/settings#kyc'
    history.push(url)

    removeIdwiseScripts()

    cleanupIdWise()
  }

  return (
    <div>
      <Container fluid className='px-0'>
        <ModalHeader action={() => backToSetting()} />
      </Container>

      {isLoading ? (
        <Loader minHeight='calc(100vh - 73px)' />
      ) : (
        <div
          id={idWiseMountElementId}
          style={{ height: 'calc(100vh - 73px)' }}
        />
      )}

      {!failureModal || isLoading ? null : (
        <FailureModal
          isOpen={failureModal}
          toggle={() => showFailureModal((o) => !o)}
          onClickVerify={verifyAgain}
          goBackToSettings={() => backToSetting()}
        />
      )}
    </div>
  )
}

function FailureModal({ isOpen, toggle, onClickVerify, goBackToSettings }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalBody className='text-center'>
        <img src={KycVerification} alt='' />
        <p className='font-size-17' style={{ color: '#4e5359' }}>
          {t('Verification Failed, please try again')}
        </p>
      </ModalBody>
      <ModalFooter>
        <button
          className='rp-btn-nostyle'
          type='button'
          onClick={goBackToSettings}
        >
          {t('Verify later')}
        </button>

        <Button type='button' onClick={onClickVerify}>
          {t('Try verification again')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
