import { yupResolver } from '@hookform/resolvers/yup'
import { Check, Info } from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import ControlledDatePicker from '../../../components/ControlledDatePicker'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import Alert from '../../../components/ui/alert'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import { useFetch } from '../../../helpers/hooks'
import { requestContractTermination } from '../../../services/api'
import LabelContent from '../CreateContract/components/label-content'

const reasons = [
  {
    value: 'employer termination',
    label: 'Employer termination',
  },
  {
    value: 'employee resignation',
    label: 'Employee resignation',
  },
]
const RequestTerminationModal = ({ show, hide, contract, updated }) => {
  const [requestCompleted, setRequestCompleted] = useState(false)

  const today = new Date()
  const tomorrowDate = today.setDate(today.getDate() + 1)

  const requestTermination = useFetch({
    action: requestContractTermination,
    onComplete: () => {
      setRequestCompleted(true)
    },
    onError: (e) => {
      toastr.error(e)
    },
  })

  const { control, handleSubmit } = useForm({
    defaultValues: { note: '' },
    resolver: yupResolver(
      yup.object({
        reason: yup
          .string()
          .oneOf(reasons.map((r) => r.value))
          .required()
          .label('Reason'),
        tentative_last_day: yup.date().nullable(),
        note: yup.string().required().label('Note'),
      }),
    ),
  })

  function onSubmit(data) {
    setRequestCompleted(false)
    const tentativeLastDay = data.tentative_last_day

    requestTermination.startFetch({
      contract_id: contract?.id,
      ...data,
      ...(tentativeLastDay
        ? { tentative_last_day: format(tentativeLastDay, 'yyyy-MM-dd') }
        : {}),
    })
  }

  return (
    <Modal isOpen={show} toggle={hide} unmountOnClose>
      <ModalHeader close={<ModalCloseButton toggle={hide} />}>
        {!requestCompleted && !requestTermination?.isLoading
          ? 'Request Termination'
          : ''}
      </ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        {requestCompleted ? (
          <div className='tw-flex tw-flex-col tw-items-center tw-p-6 tw-text-center'>
            <Check size={138} className='tw-text-primary-100' />

            <p className='tw-text-2xl tw-font-medium'>
              Termination request sent
            </p>
            <p className='tw-pb-4 tw-text-sm tw-font-semibold tw-text-text-60'>
              Our team will review it and reach out to you shortly.
            </p>

            <Button onClick={() => updated(false)}>OK, Thanks</Button>
          </div>
        ) : (
          <>
            <ModalBody className='tw-flex tw-min-h-[414px] tw-flex-col tw-gap-4'>
              {requestTermination?.isLoading ? (
                <Loader className='tw-flex-grow' />
              ) : (
                <>
                  <ControlledSelect
                    control={control}
                    name='reason'
                    label={<LabelContent required>Reason</LabelContent>}
                    options={reasons}
                    isSearchable={false}
                    placeholder='Select reason'
                  />

                  <ControlledDatePicker
                    control={control}
                    name='tentative_last_day'
                    label='Tentative last day (optional)'
                    placeholder='Select date'
                    dateFormat='dd-MM-yyyy'
                    minDate={tomorrowDate}
                  />

                  <ControlledInput
                    control={control}
                    name='note'
                    type='textarea'
                    label={<LabelContent required>Note</LabelContent>}
                    placeholder='Insert reason for termination'
                  />

                  <Alert
                    color='danger'
                    customIcon={
                      <Info size={48} className='tw-text-systemRed-100' />
                    }
                    className='!tw-mb-0'
                    innerClassName='tw-font-medium tw-text-black'
                  >
                    Please refrain from discussing the termination with the
                    employee until you have received feedback from the
                    RemotePass team.
                  </Alert>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                color='light'
                outline
                onClick={hide}
                disabled={requestTermination?.isLoading}
              >
                Cancel
              </Button>
              <Button
                color='danger'
                type='submit'
                loading={requestTermination?.isLoading}
                disabled={requestTermination?.isLoading}
              >
                Request Termination
              </Button>
            </ModalFooter>
          </>
        )}
      </form>
    </Modal>
  )
}

export default RequestTerminationModal
