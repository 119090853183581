import { t } from 'i18next'
import React from 'react'
import { Col, Container, Label, Row, Spinner } from 'reactstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import toastr from 'toastr'
import { CopySimple } from '@phosphor-icons/react'

const AccountInfo = ({ loading, data, withCopy = true }) => {
  return loading ? (
    <Container style={{ minHeight: '30rem' }}>
      <Col style={{ minHeight: '30rem' }}>
        <Row
          style={{ minHeight: '30rem' }}
          className='justify-content-center align-items-center'
        >
          <Spinner type='grow' className='mr-2' color='primary' />
        </Row>
      </Col>
    </Container>
  ) : (
    <div>
      <Col className='pt-2'>
        {!!data &&
          data?.data?.map((e, i) => (
            <Col key={`bankInfo-${i}`} className='p-0'>
              <Label className='col-form-label pt-0 font-weight-light'>
                {e.name}
              </Label>
              <div
                name='end-date'
                className='form-control tw-mb-2 !tw-flex tw-justify-between'
                id='bankInfo_Beneficiary'
              >
                {e.value}
                {withCopy && (
                  <CopyToClipboard
                    text={e.value}
                    onCopy={() =>
                      toastr.success(`${e.name} ${t('Copied').toLowerCase()}`)
                    }
                  >
                    <CopySimple
                      weight='fill'
                      className='tw-cursor-pointer tw-fill-primary'
                    />
                  </CopyToClipboard>
                )}
              </div>
            </Col>
          ))}
      </Col>
    </div>
  )
}

export default AccountInfo
