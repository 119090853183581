import { PencilSimple, Warning, WarningOctagon } from '@phosphor-icons/react'
import React, { useState } from 'react'
import toastr from 'toastr'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import { deleteCustomField, updateCustomField } from '../../../services/api'
import { getErrorMessage } from '../../../utils/get-errors'
import { ManageCustomField } from './manage-custom-field'
import { t } from 'i18next'

const CONFIRM_MODAL = {
  UPDATE: 'update',
  DELETE: 'delete',
}

export function EditCustomField({ item, refetchFields }) {
  const [isOpen, setIsOpen] = useState(false)
  const [confirmModal, setConfirmModal] = useState({ type: null, item: null })

  function toggleConfirmModal({ type: modalType, item: modalItem } = {}) {
    setConfirmModal((prevModal) => {
      const { type } = prevModal
      if (Object.values(CONFIRM_MODAL).includes(type)) {
        return { type: null, item: null }
      } else if (Object.values(CONFIRM_MODAL).includes(modalType)) {
        return { type: modalType, item: modalItem }
      } else {
        return { type: null, item: null }
      }
    })
  }

  function toggle() {
    return setIsOpen((open) => !open)
  }

  const { startFetch: updateField, isLoading: updatingField } = useFetch({
    action: updateCustomField,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(
          data.message ||
            t('An error occurred while updating the custom field'),
        )
      } else {
        toastr.success(t('Custom field updated successfully'))
        toggle()
        refetchFields?.()
        toggleConfirmModal()
      }
    },
    onError: (error) => {
      toastr.error(getErrorMessage(error))
    },
  })

  const { startFetch: deleteField, isLoading: deletingField } = useFetch({
    action: deleteCustomField,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(
          data.message ||
            t('An error occurred while deleting the custom field'),
        )
      } else {
        toastr.success(t('Custom field deleted successfully'))
        toggle()
        refetchFields?.()
        toggleConfirmModal()
      }
    },
    onError: (error) => {
      toastr.error(getErrorMessage(error))
    },
  })

  return (
    <>
      <Button
        color='link'
        className='!tw-px-0'
        icon={<PencilSimple size={20} />}
        onClick={toggle}
      >
        {t('Edit')}
      </Button>

      {!isOpen ? null : (
        <ManageCustomField
          title={item.name}
          subtitle={t('Edit this custom field')}
          formId='edit-custom-field-form'
          state={{
            show: isOpen,
            data: {
              name: item.name,
              type_id: item.attribute_type.id,
              required: Number(item.required) === 1,
              active: Number(item.active) === 1,
              options: item.options.map((option) => ({ text: option.name })),
              area: item.area,
              contract_types: item.contract_types.reduce((acc, type) => {
                acc[type] = true
                return acc
              }, {}),
            },
          }}
          toggle={toggle}
          onSubmit={(values) => {
            const itemWithId = { id: item?.id, ...values }
            toggleConfirmModal({ type: CONFIRM_MODAL.UPDATE, item: itemWithId })
          }}
          onDelete={() => {
            toggleConfirmModal({
              type: CONFIRM_MODAL.DELETE,
              item: { id: item?.id },
            })
          }}
          actionsLoading={updatingField || deletingField}
        />
      )}

      <Confirm
        isOpen={confirmModal.type === CONFIRM_MODAL.DELETE}
        toggle={toggleConfirmModal}
        title={`${t('Are you sure you want to delete')} ${item.name}?`}
        subTitle={t('Once this custom field is deleted it can’t be recovered')}
        icon={<Warning size={24} className='tw-text-red-100' />}
        submit={{
          action: () => {
            deleteField(confirmModal.item)
          },
          text: t('Delete'),
          color: 'danger',
        }}
        cancel={{ text: t('Cancel') }}
        actionsLoading={updatingField || deletingField}
      />

      <Confirm
        isOpen={confirmModal.type === CONFIRM_MODAL.UPDATE}
        toggle={toggleConfirmModal}
        title={t('Are you sure you want to save these changes?')}
        subTitle={t('These changes will be applied to future contracts')}
        icon={<WarningOctagon size={24} className='tw-text-systemGold-100' />}
        submit={{
          action: () => {
            updateField(confirmModal.item)
          },
          text: t('Yes, Save'),
        }}
        cancel={{ text: t('No, Go back') }}
        actionsLoading={updatingField || deletingField}
      />
    </>
  )
}

function Confirm({
  icon,
  isOpen,
  toggle,
  title,
  subTitle,
  submit,
  cancel,
  actionsLoading,
}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader
        close={<ModalCloseButton toggle={toggle} />}
        className='!tw-border-none !tw-pb-0'
      >
        {!icon ? null : icon}
      </ModalHeader>
      <ModalBody>
        {!title ? null : <h4>{title}</h4>}
        {!subTitle ? null : <p>{subTitle}</p>}
      </ModalBody>
      <ModalFooter>
        <Button
          color={cancel?.color ?? 'light'}
          outline={cancel?.outline ?? true}
          onClick={cancel?.action ?? toggle}
          disabled={actionsLoading}
        >
          {cancel?.text ?? t('No')}
        </Button>
        <Button
          color={submit?.color}
          outline={submit?.outline ?? false}
          onClick={submit?.action ?? toggle}
          disabled={actionsLoading}
          loading={actionsLoading}
        >
          {submit?.text ?? t('Yes')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
