import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { cn } from 'ui'

export default function NavTabsV2({
  className,
  itemLinkClassName,
  options,
  activeOption,
  onClickOption,
}) {
  return (
    <Nav tabs className={cn('tw-gap-x-6 !tw-border-b-surface-30', className)}>
      {options?.map((option) => (
        <NavItem key={option?.value}>
          <NavLink
            className={cn(
              'tw-mt-1 !tw-border-b-2 !tw-border-transparent !tw-px-0 !tw-py-4 md:!tw-py-6',
              { 'cursor-default text-gray-400': option.disabled },
              activeOption === option?.value
                ? 'tw-pointer-events-none tw-cursor-default !tw-border-b-primary-100 tw-font-semibold !tw-text-primary-100'
                : 'tw-text-black hover:!tw-border-b-secondary-60',
              itemLinkClassName,
              option?.className,
            )}
            style={{ whiteSpace: 'nowrap' }}
            onClick={() => onClickOption(option)}
            disabled={option.disabled}
            tag='button'
            type='button'
          >
            {option?.label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}
