import { t } from 'i18next'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from 'reactstrap'

import PageHeading from '../../components/ui/page-heading'
import { BE_CONTRACT_CATEGORY } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import { getContractDetail, getContractList } from '../../services/api'
import { updateContract } from '../../store/contract/actions'
import DeDocumentsList from '../Contract/ContractPage/de-documents-list'
import { DocumentActions } from '../Contract/ContractPage/de-documents-tab'

export default function DocumentsDeEmployee() {
  const dispatch = useDispatch()

  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const contractorType = userProfile?.contractor_type
  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE

  const { data: contractList, isLoading: gettingContractsList } = useFetch({
    action: getContractList,
    autoFetch: true,
    body: { status: [4] },
    onComplete: (data) => {
      const contract = data?.[0]

      dispatch(updateContract(contract))
    },
  })

  const {
    data: contract,
    isLoading: gettingContractDetails,
    startFetch: refetchContractDetails,
  } = useFetch(
    {
      action: getContractDetail,
      autoFetch: !!contractList?.[0]?.id,
      body: { id: contractList?.[0]?.id },
    },
    [contractList?.[0]?.id],
  )

  const isLoading = gettingContractsList || gettingContractDetails

  const documents = contract?.documents
  const isEmpty = !documents || documents?.length === 0

  return (
    <div className='page-content'>
      <PageHeading>
        <PageHeading.Title>{t('Documents')}</PageHeading.Title>

        {isEmpty ? null : (
          <DocumentActions
            isDirectEmployee={isDeEmployee}
            refreshDocs={refetchContractDetails}
            contract={contract}
          />
        )}
      </PageHeading>

      <Card>
        <DeDocumentsList
          loading={isLoading}
          isEmpty={isEmpty}
          documents={documents}
          contract={contract}
          refreshDocs={refetchContractDetails}
        />
      </Card>
    </div>
  )
}
