import { t } from 'i18next'
import { WarningOctagon } from '@phosphor-icons/react'
import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import Button from '../../../components/ui/button'

const CancelAmendmentModal = ({ isOpen, toggle, onAgree }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader close={<ModalCloseButton toggle={toggle} />}>
        {t('You have unsaved changes')}
      </ModalHeader>

      <ModalBody className='tw-flex tw-flex-col tw-gap-4 !tw-p-6'>
        <WarningOctagon size={40} weight='duotone' color='var(--gold-100)' />

        <p className='tw-mb-0 tw-text-sm'>
          {t('If you close this dialog, your changes will not be saved.')}
        </p>
      </ModalBody>

      <ModalFooter>
        <Button type='button' color='light' outline onClick={toggle}>
          {t('Cancel')}
        </Button>
        <Button type='button' onClick={onAgree}>
          {t('Okay')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CancelAmendmentModal
