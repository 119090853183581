import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'

import { CardItem } from '../../components/CardSelector'
import {
  ACH,
  BANK_TRANSFER,
  BREX,
  COINBASE_CLIENT,
  CREDIT_CARD,
  SEPA_DEBIT,
} from '../../core/config/payment-methods'
import { userTypes } from '../../helpers/enum'
import { usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import FEATURE_FLAGS from '../../config/feature-flags'

export const initialMethods = [
  {
    label: 'Credit / Debit Card',
    description: 'Fee depends on the cash out currency',
    id: CREDIT_CARD.id,
    type: 'card',
  },
  {
    label: BANK_TRANSFER.name,
    description: '2.5% Fee',
    id: BANK_TRANSFER.id,
    type: 'bank_transfer',
  },
  {
    label: SEPA_DEBIT.name,
    description: '2.5% Fee',
    id: SEPA_DEBIT.id,
    type: 'sepa_debit',
  },
  {
    label: ACH.name,
    description: '2.5% Fee',
    id: ACH.id,
  },
  {
    label: COINBASE_CLIENT.name,
    description: null,
    id: COINBASE_CLIENT.id,
  },
  !FEATURE_FLAGS.PAYMENT_METHOD_BREX
    ? null
    : { label: BREX.name, description: '', id: BREX.id },
].filter(Boolean)

function formatFee(formatter, amount) {
  if (amount === 0) {
    return 'No fees'
  } else if (amount) {
    return `${formatter.format(amount)} Fee`.trim()
  }

  return ''
}

const PaymentMethods = ({
  onSelected,
  data,
  bankMethods,
  currency,
  isEditing,
  noACH,
  noCoinbase,
  isInsurance,
  requestAccess,
  isItemSelected,
}) => {
  const [options, setOptions] = useState(isInsurance ? data : initialMethods)

  const formatter = useMemo(() => {
    return getCurrencyFormatter(currency?.code)
  }, [currency?.code])

  const user = useSelector((state) => state.Account?.user)

  const isClient = user?.type === userTypes.COMPANY

  const { hasAccess } = usePermissions()

  const hasPermission =
    !isClient || hasAccess(permissions.ManageCompanyPayment) || !isEditing
  useEffect(() => {
    if (data && !isInsurance) {
      const defaultOptions = isInsurance ? data : initialMethods

      setOptions(() => {
        function formatItem(item) {
          const dataItem = data?.find((f) => f?.id === item?.id)

          const element = {
            ...dataItem,
            ...item,
            description: formatFee(formatter, dataItem?.fee),
          }
          return element
        }

        const formattedOptions = defaultOptions.map((item) => formatItem(item))

        return formattedOptions.filter(
          (t) =>
            !!t?.id &&
            (!noACH || t?.id !== ACH.id) &&
            !(noCoinbase && t?.id === COINBASE_CLIENT.id) &&
            (!isEditing || t?.id !== BANK_TRANSFER.id),
        )
      })
    }
  }, [data, formatter, isEditing, isInsurance, noACH, noCoinbase])

  return (
    <Row className='mx-n2'>
      {options
        .reduce(function (result, _, index, array) {
          if (index % 2 === 0) {
            result.push(array.slice(index, index + 2))
          }
          return result
        }, [])
        .map((e, i) => (
          <React.Fragment key={i}>
            {e?.map((item, index) => (
              <Col key={`${item.id}-${index}`} md={6} className='px-2'>
                <CardItem
                  selected={isItemSelected(item)}
                  key={item.value}
                  isEditing={isEditing}
                  item={item}
                  hasPermission={hasPermission}
                  onSelect={() => onSelected(item)}
                  requestAccess={() => requestAccess(item)}
                />
              </Col>
            ))}
          </React.Fragment>
        ))}

      {bankMethods?.map((item) => {
        const formattedItem = {
          ...item,
          description: formatFee(formatter, item?.fee),
        }

        return (
          <Col key={item.id} md={6} className='px-2'>
            <CardItem
              key={item.value}
              isEditing={isEditing}
              item={formattedItem}
              hasPermission={hasPermission}
              onSelect={() => onSelected(item)}
              requestAccess={() => requestAccess(item)}
            />
          </Col>
        )
      })}
    </Row>
  )
}

export default PaymentMethods
