import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import AsyncSelect from 'react-select/async'
import AsyncCreatableSelect from 'react-select/async-creatable'
import toastr from 'toastr'

import customStyles from '../../../components/Common/react-select-custom-styles'
import { DropdownIndicator } from '../../../components/Forms/CustomSelect/select-components'
import { getInputErrorMessage } from '../../../components/Forms/get-input-error-message'
import InputFeedback from '../../../components/ui/input-feedback'
import { getVendors } from '../../../services/api-bill-payments'
import { mapListToOption } from '../../../utils/map-to-option'

function createNewOption({ t }) {
  return {
    value: null,
    label: t('Start typing to create a new vendor'),
    isDisabled: true,
  }
}

export function ControlledVendorSelect({
  control,
  inputId,
  label,
  placeholder = `${t('Select')} ...`,
  name,
  transform,
  disabled,
  canManageVendors,
  error: externalError,
}) {
  const userToken = useSelector((state) => state.Account?.user?.token)

  const { mutate } = useMutation({
    mutationFn: (data) => {
      return getVendors(userToken, data)
    },
  })

  function loadOptions(inputValue, callback) {
    mutate(
      { search_key: inputValue },
      {
        onSettled: (data) => {
          const options = data?.data?.data?.map((vendor) => {
            return { ...mapListToOption(vendor), raw: vendor }
          })
          const allOptions = canManageVendors
            ? [...options, createNewOption({ t })]
            : [options]
          callback(allOptions)
        },
        onError: (error) => {
          const emptyData = canManageVendors ? [createNewOption({ t })] : []
          callback(emptyData)
          toastr.error(error)
        },
      },
    )
  }
  const SelectComponent = canManageVendors ? AsyncCreatableSelect : AsyncSelect
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onBlur, onChange, ref },
        fieldState: { error },
      }) => {
        function handleChange(newValue) {
          typeof transform?.output === 'function'
            ? onChange(transform.output(newValue))
            : onChange(newValue ?? '')
        }

        const theError = getInputErrorMessage(externalError || error)

        return (
          <div>
            {!label ? null : <label htmlFor={inputId}>{label}</label>}

            <SelectComponent
              cacheOptions
              loadOptions={loadOptions}
              components={{ IndicatorSeparator: null, DropdownIndicator }}
              placeholder={placeholder}
              defaultOptions
              name={name}
              onChange={handleChange}
              value={value}
              styles={customStyles}
              inputId={inputId}
              onBlur={onBlur}
              selectRef={ref}
              isDisabled={disabled}
              aria-invalid={!!theError}
              aria-errormessage={name + '-error-msg'}
            />

            {!error ? null : (
              <InputFeedback className='tw-mt-1'>
                {getInputErrorMessage(error)}
              </InputFeedback>
            )}
          </div>
        )
      }}
    />
  )
}
