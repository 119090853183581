import { useFormContext } from 'react-hook-form'
import { useFilters } from '../contexts/filters-context'
import { useMemo } from 'react'
import { usePlaceholders } from '../contexts/placeholders-context'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import some from 'lodash/some'
import get from 'lodash/get'
import includes from 'lodash/includes'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'

export function useIncompatibleTrigger({ trigger }) {
  const { watch } = useFormContext()
  const { filters } = useFilters()
  const { items } = usePlaceholders()
  const currentSteps = watch('steps')
  const currentFilters = watch('filters')

  const { compatibleSteps, isStepsIncompatible } = useMemo(() => {
    if (!trigger || isEmpty(items)) {
      return { compatibleSteps: {}, isStepsIncompatible: false }
    }

    let isIncompatible = false

    const compatibleSteps = map(currentSteps || [], (step) => {
      if (step?.type !== 'action' || !step?.body) return step

      const placeholderKeys = [
        ...step.body.matchAll(
          /<span[^>]*data-placeholder="true"[^>]*\sid="([^"]+)"/g,
        ),
      ].map((match) => match[1])

      const filteredPlaceholders = placeholderKeys.filter((key) => {
        const placeholder = find(items, { key })

        if (!placeholder || !placeholder.available_categories) return false

        const isCompatible = includes(
          placeholder.available_categories,
          trigger.category,
        )

        if (!isCompatible) isIncompatible = true
        return isCompatible
      })

      const updatedBody = step.body.replace(
        /<span[^>]*data-placeholder="true"[^>]*\sid="([^"]+)".*?<\/span>/g,
        (match, key) => (filteredPlaceholders.includes(key) ? match : ''),
      )

      return { ...step, body: updatedBody }
    })

    return { isStepsIncompatible: isIncompatible, compatibleSteps }
  }, [currentSteps, items, trigger])

  const { compatibleFilters, isFiltersIncompatible } = useMemo(() => {
    if (!trigger || isEmpty(currentFilters) || isEmpty(filters?.all)) {
      return { compatibleFilters: {}, isFiltersIncompatible: false }
    }

    const category = trigger.category

    const compatibleFilters = reduce(
      currentFilters,
      (acc, value, filterKey) => {
        const filterCategories = get(
          find(filters.all, { filter: filterKey }),
          'categories',
          [],
        )

        if (includes(filterCategories, category)) {
          acc[filterKey] = value
        }
        return acc
      },
      {},
    )

    const isIncompatible = some(currentFilters, (value, filterKey) => {
      const filterCategories = get(
        find(filters.all, { filter: filterKey }),
        'categories',
        [],
      )
      return !includes(filterCategories, category)
    })

    return { compatibleFilters, isFiltersIncompatible: isIncompatible }
  }, [currentFilters, filters.all, trigger])

  return {
    compatibleFilters,
    isFiltersIncompatible,
    compatibleSteps,
    isStepsIncompatible,
  }
}
