import { t } from 'i18next'
import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import Button from '../../components/ui/button'
import { storage } from '../../helpers/config'
import { toBase64 } from '../../helpers/helper'
import { useFetch } from '../../helpers/hooks'
import { customizeCompany } from '../../services/api'
import logoPlaceholder from './../../assets/images/logo_placeholder2.png'

const Customization = ({ data }) => {
  const [color, setColor] = useState(data?.main_color)
  const [domain, setDomain] = useState(data?.domain)
  const [logo, setLogo] = useState(null)

  const update = useFetch({
    action: customizeCompany,
    onComplete: () => {
      document.getElementById('root').style.setProperty('--primary', color)
    },
  })

  return (
    <Card className='m-0 p-0'>
      <CardBody className='m-0 p-0'>
        <Col>
          <Row className='p-3 w-100 pb-0 mb-0'>
            <div>
              <div className='upload-btn' style={{}}>
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    toBase64(acceptedFiles[0]).then((r) => {
                      setLogo({ base64: r, file: acceptedFiles[0] })
                    })
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      style={{
                        zIndex: 99999999999,
                      }}
                    >
                      <div {...getRootProps()}>
                        <input
                          {...getInputProps()}
                          onChange={async (e) => {
                            if (
                              e.target.files[0] &&
                              [
                                'image/png',
                                'image/jpg',
                                'image/jpeg',
                                'image/gif',
                              ].includes(e.target.files[0].type)
                            ) {
                              const FileSize =
                                e.target.files[0].size / 1024 / 1024
                              if (FileSize > 25) {
                                toastr.error(t('File size exceeds 25 MB'))
                                return
                              }
                              toBase64(e.target.files[0]).then((r) => {
                                setLogo({
                                  base64: r,
                                  file: e.target.files[0],
                                })
                              })
                            } else {
                              toastr.error(t('File not supported'))
                            }
                          }}
                        />

                        <Row className='align-items-center m-0 p-0'>
                          <img
                            width={120}
                            src={
                              logo?.base64 ||
                              (!!data?.logo && `${storage}${data.logo}`) ||
                              logoPlaceholder
                            }
                            alt=''
                            className='bg-soft-primary'
                          />

                          <a className='mx-3 text-primary'>
                            {t('Upload Logo')}
                          </a>
                        </Row>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
          </Row>
          <Row className='p-3 w-100 pb-0 mb-0'>
            <FormGroup className='mb-0'>
              <Label>{t('Custom Domain')}</Label>
              <InputGroup>
                <Input
                  value={domain}
                  onChange={(e) => {
                    setDomain(e.target.value)
                  }}
                />

                <InputGroupAddon addonType='append'>
                  <InputGroupText>remotepass.com</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Row>
          <Row className='p-3 w-100 pb-0 mb-0'>
            <FormGroup>
              <Label>{t('Primary color')}</Label>
              <Input placeholder='#000000' value={color} />
            </FormGroup>
            <input
              type='color'
              value={color}
              onChange={(e) => setColor(e.target.value)}
            />
          </Row>
          <div className='w-100 divider border-top my-md-4 my-3' />
        </Col>
        <Row className='px-md-4 pb-3 pb-md-4 px-3 m-0 justify-content-end w-100'>
          <Button
            type='submit'
            onClick={() => {
              update.startFetch({
                domain,
                main_color: color,
                logo: logo?.file,
              })
            }}
            loading={update.isLoading}
            text={t('Save')}
            style={{ height: 40 }}
            className='save-info-button font-size-14'
          />
        </Row>
      </CardBody>
    </Card>
  )
}

export default Customization
