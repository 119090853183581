import { api, getConfig, getFormData } from './api'

/**
 * @param {*} data - { type: 'fixed' | 'payg' | 'milestone' }
 */
export function getContractImportTemplate(token, data, extra) {
  const type = data?.type

  return api.get(`contract/import/${type}/template`, getConfig(token, extra))
}

/**
 * @param {*} data - { template: File, contract_type: 'fixed' | 'payg' | 'milestone' }
 */
export function validateContractImportTemplate(token, data) {
  const { progressFuncs, ...restOfData } = data
  const formData = getFormData(restOfData)

  return api.post(
    'contract/import/validate',
    formData,
    getConfig(
      token,
      { ...progressFuncs },
      { 'Content-Type': 'multipart/form-data' },
    ),
  )
}

export function processContractImport(token, data) {
  return api.post('contract/import/process', data, getConfig(token))
}

/**
 * @param {*} data - { task_id: number, custom_message: string, save_custom_message: boolean }
 */
export function sendContractImportInvites(token, data) {
  return api.post(`contract/import/${data?.id}/invite`, data, getConfig(token))
}

export function invitePendingContracts(token) {
  return api.get('contract/import/invite/pending', getConfig(token))
}

export function bulkUpdateContracts(token, data, extra) {
  return api.get(
    `contract/bulk_update/${data?.adjustment_type}/contracts/counts`,
    getConfig(token, extra),
  )
}

export function bulkUpdateContractsList(token, data, extra) {
  return api.get(
    `contract/bulk_update/${data?.contract_type}/${data?.adjustment_type}/contracts`,
    getConfig(token, extra),
  )
}

export function getRecurringExpensesContractList(token, _, extra) {
  return api.get(
    'contract/bulk_recurring_expenses/contracts',
    getConfig(token, extra),
  )
}

export function validateContractOperationTemplate(token, data) {
  const { progressFuncs, contractType, updateType, ...restOfData } = data
  const formData = getFormData(restOfData)

  return api.post(
    `contract/bulk_update/${contractType}/${updateType}/csv/validate`,
    formData,
    getConfig(
      token,
      { ...progressFuncs },
      { 'Content-Type': 'multipart/form-data' },
    ),
  )
}

export function validateContractBulkOperation(token, data) {
  const { contractType, updateType, ...restOfData } = data
  return api.post(
    `contract/bulk_update/${contractType}/${updateType}/validate`,
    restOfData,
    getConfig(token),
  )
}

export function adjustContractBulkOperation(token, data) {
  const { contractType, updateType, ...restOfData } = data
  return api.post(
    `contract/bulk_update/${contractType}/${updateType}/adjust`,
    restOfData,
    getConfig(token),
  )
}
