import React from 'react'
import { useFormContext } from 'react-hook-form'
import { ACTION_TYPE } from '../tools/constants'

export function ActionTitle({ index }) {
  const { watch } = useFormContext()
  const { actionType, time, timeZone } = watch(`steps.${index}`)
  if (!actionType) return <span className='tw-text-text-80'>Select action</span>
  return (
    <span className='tw-font-light tw-text-text-80'>
      {actionType?.value === ACTION_TYPE.EMAIL ? 'Send email' : null}
      {actionType?.value === ACTION_TYPE.SLACK ? 'Send slack message' : null}
      {time ? ` at ${time}` : null}
      {timeZone ? ` ( ${timeZone.label} ${timeZone.description} )` : null}
    </span>
  )
}
