import {
  ArrowsIn,
  ArrowsOut,
  CaretUp,
  DotsThreeOutlineVertical,
  IdentificationBadge,
  Minus,
  PencilSimple,
  Person,
  Plus,
} from '@phosphor-icons/react'
import {
  Background,
  ControlButton,
  Handle,
  MiniMap,
  Panel,
  Position,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useViewport,
} from '@xyflow/react'
import { t } from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { UncontrolledTooltip } from 'reactstrap'

import { Avatar, cn } from 'ui'
import { VIEW } from '.'
import Button from '../../components/ui/button'
import Flag from '../../components/ui/flag'
import {
  DEFAULT_FIT_VIEW_OPTIONS,
  DEPARTMENT_ROOT_ID,
  MANAGER_ROOT_ID,
  NODE_TYPES,
  ZOOM_DURATION,
  ZOOM_STEP,
} from './constants'
import { d3HierarchyLayout } from './d3-auto-layout'
import { departmentColors } from './department-colors'
import { useUtil, UtilProvider } from './util-context'
import {
  getAllChildren,
  getAllDepartmentChildren,
  getChildrenLevel,
  getDepartmentRootHeads,
  getDirectChildren,
} from './utils'

const handleClassName =
  '!tw-w-px !tw-min-w-px !tw-bg-secondary-50 -tw-z-[1] data-[handle-invisible="true"]:!tw-bg-transparent'

export function CompanyProfile({
  data: { name = '', logo = '', handless = false },
}) {
  return (
    <>
      <div className='tw-h-[70px] !tw-w-[220px] tw-overflow-hidden tw-rounded tw-border tw-border-surface-30 tw-bg-white'>
        <div className='tw-flex tw-flex-nowrap tw-items-center tw-gap-2 tw-border-t-2 tw-border-t-primary-100 tw-p-3'>
          <Avatar name={name} photo={logo} />
          <div className='tw-ml-2 tw-min-w-0' translate='no'>
            <p className='mb-0 tw-truncate tw-font-bold tw-text-text-80'>
              {name}
            </p>
            <span className='tw-text-secondary-80'>Entity</span>
          </div>
        </div>
      </div>

      <Handle
        type='source'
        position={Position.Bottom}
        className={handleClassName}
        isConnectable={false}
        data-handle-invisible={handless}
      />
    </>
  )
}

export function UserProfile({
  data: {
    name = '',
    role = '',
    flag = '',
    id = '',
    photo = null,
    isLastLevel = false,
    handless = false,
    className = '',
    linkToContract = false,
    linkToContractHash,
  },
  selected,
  onUserNameClick,
}) {
  const { pathname } = useLocation()
  const inTimeOff = pathname.includes('time-off-policies')
  return (
    <>
      {!handless && (
        <Handle
          type='target'
          position={Position.Top}
          className={handleClassName}
          isConnectable={false}
        />
      )}
      <div
        className={cn(
          'tw-w-52 tw-rounded tw-border tw-border-surface-30 tw-bg-white hover:tw-bg-primary-10',
          selected &&
            'tw-ring-4 tw-ring-primary-60 tw-ring-offset-2 tw-ring-offset-surface-20',
          'tw-w-[220px]',
          className,
        )}
      >
        <div className='tw-flex tw-flex-nowrap tw-items-center tw-gap-3 tw-p-3'>
          <Avatar
            name={name}
            photo={photo}
            size='md'
            flag={inTimeOff ? flag : undefined}
            className={cn({ 'tw-bg-systemGold-20': !name && inTimeOff })}
            customFlag={
              !flag || inTimeOff ? null : (
                <Flag
                  url={flag}
                  className='tw-absolute -tw-bottom-1 -tw-end-1 tw-block !tw-size-4 tw-rounded-full tw-border-2 tw-border-solid tw-border-white tw-object-cover'
                />
              )
            }
            icon={
              !name &&
              inTimeOff && (
                <DotsThreeOutlineVertical
                  size={20}
                  className='tw-fill-systemGold-110'
                />
              )
            }
          />
          <div translate='no' className='tw-min-w-0 tw-leading-none'>
            {!inTimeOff ? (
              <p className='tw-mb-0 tw-truncate tw-text-xs/5 tw-font-semibold'>
                {linkToContract ? (
                  <Link
                    className='!tw-text-black'
                    to={`/contract/detail?id=${id}${linkToContractHash ?? ''}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {name}
                  </Link>
                ) : (
                  name
                )}
              </p>
            ) : (
              <span className='tw-flex tw-items-center'>
                <Button
                  size='xs'
                  className='tw-truncate !tw-p-0 !tw-text-black hover:!tw-text-primary-120'
                  color='link'
                  iconRight={
                    <IdentificationBadge
                      size={16}
                      className='tw-fill-secondary'
                    />
                  }
                  onClick={onUserNameClick}
                  id={`to-contract-${id}`}
                >
                  {name || 'Pending onboarding'}
                </Button>
                <UncontrolledTooltip target={`to-contract-${id}`}>
                  {t('View details')}
                </UncontrolledTooltip>
              </span>
            )}
            <div className='tw-overflow-hidden tw-truncate tw-text-ellipsis !tw-whitespace-nowrap tw-text-wrap tw-text-xs tw-font-medium tw-text-text-80'>
              {role}
            </div>
          </div>
        </div>
      </div>
      {handless ? null : (
        <Handle
          type='source'
          position={Position.Bottom}
          className={handleClassName}
          isConnectable={false}
          data-handle-invisible={isLastLevel}
        />
      )}
    </>
  )
}

export function DepartmentInfo({
  data: { employee_count: employeeCount, name, color },
}) {
  return (
    <>
      <Handle
        type='target'
        position={Position.Top}
        className={handleClassName}
        isConnectable={false}
      />
      <div className='tw-m-auto tw-flex tw-w-[230px] tw-flex-nowrap tw-items-center tw-justify-between tw-gap-2 tw-rounded-full tw-border tw-border-surface-30 tw-bg-white tw-p-2 tw-transition-colors hover:tw-bg-primary-10'>
        <div
          className={cn(
            'tw-flex tw-items-center tw-gap-0.5 tw-rounded-full tw-border tw-border-surface-30 tw-bg-white tw-py-1 tw-pl-2 tw-pr-2',
            departmentColors[color]?.className,
          )}
        >
          <div className='tw-text-[10px]'>{employeeCount}</div>
          <Person size={12} />
        </div>

        <span className='tw-min-w-28 tw-text-center tw-font-bold tw-uppercase'>
          {name}
        </span>

        <PencilSimple size={16} className='tw-mx-2 tw-flex-shrink-0' />
      </div>
      <Handle
        type='source'
        position={Position.Bottom}
        className={handleClassName}
        isConnectable={false}
      />
    </>
  )
}

export function WorkerCount(node) {
  const { noOfEmployees, fromContractId, isTopLevel = false } = node?.data || {}

  const { isNodeHidden } = useUtil()
  const { isHidden: hidden } = isNodeHidden({ nodeId: fromContractId, node })

  return (
    <>
      {isTopLevel ? null : (
        <Handle
          type='target'
          position={Position.Top}
          className={handleClassName}
          isConnectable={false}
        />
      )}
      <div
        className='tw-group tw-peer tw-flex tw-h-7 tw-min-w-[94px] tw-items-center tw-justify-center tw-gap-0.5 tw-rounded-full tw-border tw-border-surface-30 tw-bg-white tw-py-1 tw-pe-2 tw-ps-2 tw-text-[10px] tw-transition-colors hover:tw-bg-primary-10'
        data-content-hidden={hidden}
      >
        <Person size={12} />
        <div>
          {noOfEmployees} {t('People')}
        </div>
        <CaretUp
          size={12}
          className='group-data-[content-hidden="true"]:tw-rotate-180'
        />
      </div>
      <Handle
        type='source'
        position={Position.Bottom}
        className={cn(
          handleClassName,
          'peer-data-[content-hidden="true"]:!tw-bg-transparent',
        )}
        isConnectable={false}
      />
    </>
  )
}

const nodeTypes = {
  [NODE_TYPES.WORKER]: UserProfile,
  [NODE_TYPES.COMPANY]: CompanyProfile,
  [NODE_TYPES.DEPARTMENT]: DepartmentInfo,
  [NODE_TYPES.WORKER_COUNT]: WorkerCount,
}

function LayoutFlow({
  children,
  onNodeClick,
  nodes: initialNodes,
  edges: initialEdges,
  contractsTree,
  view,
}) {
  /* Workaround to hide flashing layout */
  const [show, setShow] = useState(false)

  const [hiddenNodes, setHiddenNodes] = useState([])
  const [clickedNode, setClickedNode] = useState(null)
  const [preventFitView, setPreventFitView] = useState(false)

  function onBackgroundClick(event) {
    const nodeElement = event.target.parentNode.closest('.react-flow__node')
    const isNode = !!nodeElement
    const isRootNode = [MANAGER_ROOT_ID, DEPARTMENT_ROOT_ID].includes(
      nodeElement?.dataset?.id,
    )

    if (!isNode || isRootNode) {
      setClickedNode(null)
      setPreventFitView(true)
    }
  }

  const [myNodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [myEdges, setEdges, onEdgesChange] = useEdgesState(initialEdges)

  const isManagerView = useMemo(() => view === VIEW.MANAGERS, [view])

  const { fitView, zoomTo } = useReactFlow()
  const { zoom } = useViewport()

  function isNodeHidden({ nodeId, node }) {
    let directChildren = []

    if (isManagerView) {
      directChildren = getDirectChildren({
        tree: contractsTree,
        id: nodeId,
      })
    } else {
      directChildren = getAllDepartmentChildren({
        tree: contractsTree,
        id: nodeId,
        node,
      })
    }

    const isHidden = hiddenNodes.includes(directChildren[0])

    return { isHidden, directChildren }
  }

  function handleInit() {
    if (contractsTree) {
      if (isManagerView) {
        const topLevelContracts = contractsTree.filter((c) => !c.parent_id)

        const defaultVisibleNodes = []
        topLevelContracts.forEach((contract) => {
          defaultVisibleNodes.push(contract.id)

          if (contract.children_contracts_ids?.length > 0) {
            contract.children_contracts_ids.forEach((childId) => {
              defaultVisibleNodes.push(childId)
            })
          }
        })

        const allRootNodes = getAllChildren({
          id: MANAGER_ROOT_ID,
          tree: contractsTree,
        })

        const defaultHiddenNodes = allRootNodes.filter(
          (id) => !defaultVisibleNodes.includes(id),
        )

        setHiddenNodes(defaultHiddenNodes)
      } else {
        const rootHeadsNodes = getDepartmentRootHeads({ tree: contractsTree })
        const allRootChildrenNodes = getDepartmentRootHeads({
          tree: contractsTree,
          all: true,
        })

        const defaultHiddenNodes = allRootChildrenNodes.filter(
          (id) => !rootHeadsNodes.includes(id),
        )

        setHiddenNodes(defaultHiddenNodes)
      }
    }

    setShow(true)
  }

  function handleCollapse({ nodeId, node }) {
    const { directChildren, isHidden } = isNodeHidden({ nodeId, node })

    let allChildren = []

    if (isManagerView) {
      allChildren = getChildrenLevel({
        tree: contractsTree,
        id: nodeId,
      })
    } else {
      allChildren = getAllDepartmentChildren({
        tree: contractsTree,
        id: nodeId,
        node,
        all: true,
      })
    }

    let newHiddenNodes = [...hiddenNodes]

    if (isHidden) {
      newHiddenNodes = new Set(
        newHiddenNodes.filter((id) => !directChildren.includes(id)),
      )
    } else {
      newHiddenNodes = new Set([...newHiddenNodes, ...allChildren])
    }

    newHiddenNodes = [...newHiddenNodes.values()]
    setHiddenNodes(newHiddenNodes)
  }

  function handleNodeClick(event, node) {
    if (node.type === NODE_TYPES.WORKER_COUNT) {
      setClickedNode(node)
      handleCollapse({ nodeId: node.data.fromContractId, node })
    } else {
      setClickedNode([node])
      onNodeClick(event, node)
    }
  }

  function handleExpandAll() {
    setClickedNode(null)
    setHiddenNodes([])
    fitView(DEFAULT_FIT_VIEW_OPTIONS)
  }
  function handleCollapseAll() {
    const isManagerView = view === VIEW.MANAGERS

    // Number of employees in the departments + the number of departments
    const depsNodeCount = isManagerView
      ? 0
      : contractsTree.departments.reduce(
          (acc, dep) => acc + dep.children.length,
          0,
        ) + contractsTree.departments.length

    const isAllManagerHidden = hiddenNodes.length === contractsTree.length
    const isAllDepartmentsHidden = hiddenNodes.length === depsNodeCount
    const isAllHidden = isManagerView
      ? isAllManagerHidden
      : isAllDepartmentsHidden

    if (isAllHidden) {
      return
    }
    setClickedNode(null)

    const rootId = isManagerView ? MANAGER_ROOT_ID : DEPARTMENT_ROOT_ID

    handleCollapse({ nodeId: rootId })

    fitView(DEFAULT_FIT_VIEW_OPTIONS)
  }

  useEffect(() => {
    const hiddenNextNodes = initialNodes.map((node) => {
      const shouldHide =
        // User profile card
        hiddenNodes.includes(node.data.id) ||
        hiddenNodes.includes(Number(node.data.id)) ||
        // Or, the employees count badge
        hiddenNodes.includes(node.data.fromContractId) ||
        // Or, the department employees count (department view)
        (node.type === NODE_TYPES.WORKER_COUNT &&
          hiddenNodes.includes(node.data.departmentId))

      return { ...node, hidden: shouldHide }
    })

    const { nodes: nextNodes, edges: nextEdges } = d3HierarchyLayout({
      nodes: hiddenNextNodes.filter((n) => !n.hidden),
      edges: initialEdges,
      options: { view },
    })

    setNodes(nextNodes)
    setEdges(nextEdges)
  }, [hiddenNodes, initialEdges, initialNodes, setEdges, setNodes, view])

  useEffect(() => {
    const clickedNodeId = clickedNode?.data?.fromContractId
    let clickedNodes

    if (Array.isArray(clickedNode)) {
      clickedNodes = clickedNode
    } else if (
      clickedNode?.type === NODE_TYPES.WORKER_COUNT &&
      !isManagerView
    ) {
      if (clickedNode.data.employeeIds) {
        const employeeIds = clickedNode.data.employeeIds.split(',')
        clickedNodes = myNodes.filter(
          (n) =>
            employeeIds.includes(n.id) ||
            n.id === String(clickedNode.data.departmentId) ||
            n.id === String(clickedNode.id),
        )
      } else if (clickedNode.id === DEPARTMENT_ROOT_ID) {
        clickedNodes = myNodes.filter(
          (n) =>
            n.id === clickedNode.id ||
            n.id === String(clickedNode.data.fromContractId),
        )
      }
    } else if (clickedNodeId) {
      const clickedNodeChildren = getDirectChildren({
        tree: contractsTree,
        id: clickedNodeId,
      })

      clickedNodes = myNodes.filter(
        (n) =>
          clickedNodeChildren.includes(n.data.id) ||
          n.data.id === clickedNodeId,
      )
    }

    if (preventFitView && !clickedNode) {
      setPreventFitView(false)
      return
    }

    fitView({
      ...DEFAULT_FIT_VIEW_OPTIONS,
      nodes: clickedNodes,
      padding: clickedNodeId > 0 ? 0.4 : 0.1,
    })
    // We want to prevent the fitView from being called again when `preventFitView` has changed
    // That's why we removed the dependency on `preventFitView`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myNodes, myEdges, fitView, contractsTree, isManagerView, clickedNode])

  return (
    <UtilProvider value={{ isNodeHidden }}>
      <ReactFlow
        nodes={myNodes}
        edges={myEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        panOnScroll
        fitView
        nodesDraggable={false}
        nodesConnectable={false}
        onNodeClick={handleNodeClick}
        onInit={handleInit}
        zoomOnDoubleClick={false}
        onClick={onBackgroundClick}
      >
        <Panel
          position='top-right'
          className='tw-flex tw-rounded [&>button:disabled]:tw-opacity-50 [&>button:hover:not(:disabled)]:tw-bg-surface-20 [&>button>svg]:!tw-max-h-4 [&>button>svg]:!tw-max-w-4 [&>button]:tw-box-border [&>button]:tw-size-10 [&>button]:tw-rounded [&>button]:tw-border [&>button]:tw-border-surface-30 [&>button]:tw-bg-white [&>button]:tw-transition-colors'
        >
          <ControlButton
            onClick={handleCollapseAll}
            aria-label='Collapse all'
            className='-tw-me-px !tw-rounded-e-none'
            id='collapse-all'
          >
            <ArrowsIn />
          </ControlButton>
          <UncontrolledTooltip target='collapse-all'>
            Collapse all
          </UncontrolledTooltip>

          <ControlButton
            onClick={handleExpandAll}
            aria-label='Expand all'
            className='tw-me-3 !tw-rounded-s-none'
            id='expand-all'
          >
            <ArrowsOut />
          </ControlButton>
          <UncontrolledTooltip target='expand-all'>
            Expand all
          </UncontrolledTooltip>

          <ControlButton
            className='-tw-me-px !tw-rounded-e-none'
            onClick={() =>
              zoomTo(zoom - ZOOM_STEP, { duration: ZOOM_DURATION })
            }
          >
            <Minus weight='bold' />
          </ControlButton>
          <ControlButton
            className='-tw-me-px !tw-w-14 !tw-rounded-e-none !tw-rounded-s-none tw-text-text-80'
            onClick={() => zoomTo(1, { duration: ZOOM_DURATION })}
          >
            {(100 * zoom).toFixed(0)}%
          </ControlButton>
          <ControlButton
            className='tw-me-3 !tw-rounded-s-none'
            onClick={() =>
              zoomTo(zoom + ZOOM_STEP, { duration: ZOOM_DURATION })
            }
          >
            <Plus weight='bold' />
          </ControlButton>
        </Panel>

        <MiniMap />
        <Background variant='dots' gap={12} size={1} />

        {children}
        {/* Workaround to hide flashing layout */}
        <div
          className={cn(
            'tw-pointer-events-none tw-absolute tw-inset-0 tw-z-10 tw-bg-surface-20 tw-transition-opacity tw-duration-200',
            show && 'tw-opacity-0',
          )}
        ></div>
      </ReactFlow>
    </UtilProvider>
  )
}

export function OrganizationChart(props) {
  return (
    <ReactFlowProvider>
      <LayoutFlow {...props} />
    </ReactFlowProvider>
  )
}
