import React from 'react'
import { Button, Col, Row } from 'reactstrap'

import withdrawProcessing from '../../assets/images/withdraw_processing.svg'
import { TOP_UP_CARD } from '../../core/config/payment-methods'
import { useResize } from '../../helpers/hooks'
import { t } from 'i18next'

const ConfirmationPage = ({ onComplete, data, paymentMethod }) => {
  const isMobile = useResize()
  return (
    <Row
      className='justify-content-center align-items-center'
      style={{ minHeight: '80vh' }}
    >
      <Col className='p-0'>
        <Row className='justify-content-center' style={{ fontSize: '10rem' }}>
          <img
            style={{ height: isMobile ? 200 : 400 }}
            src={withdrawProcessing}
            alt=''
          />
        </Row>
        <Row className='justify-content-center p-3'>
          <h2>
            {paymentMethod?.id === TOP_UP_CARD.id
              ? t('Withdrawal processed')
              : t('Withdrawal processing')}
          </h2>
        </Row>
        <Row className='justify-content-center text-center text-secondary'>
          <Col md={8}>
            {paymentMethod?.id === 4 ? (
              <p>
                {t(
                  'Your request is processing, you will receive an email confirmation with your Reference ID. You’ll need to present the',
                )}{' '}
                <span className='rp-font-bold text-dark'>
                  {t('Reference ID')}
                </span>{' '}
                {t('along with your')}{' '}
                <span className='rp-font-bold text-dark'>
                  {t('identification')}
                </span>{' '}
                {t(
                  '(passport or government id) to collect your funds from any of the',
                )}{' '}
                <span className='rp-font-bold text-dark'>
                  {t('Sroor Exchange cash points')}
                </span>
                {t('.')}
              </p>
            ) : paymentMethod?.id === 1 ? (
              <p>
                {t(
                  'Your withdrawal request has been received, and we will notify you once it’s confirmed.',
                )}
                <br />
                {t('Your Transaction ID:')}
                {data?.ref}{' '}
              </p>
            ) : paymentMethod?.id === TOP_UP_CARD.id ? (
              <p>
                {t(
                  'Your withdrawal request has been processed successfully, and the corresponding amount has been credited to your card balance.',
                )}
                <br />
                {t('Your Transaction ID:')}
                {data?.ref}{' '}
              </p>
            ) : (
              <p>
                {t(
                  'Your withdrawal request has been received, and we will notify you once it’s confirmed.',
                )}
                <br />
                {t('Your Transaction ID:')}
                {data?.ref}{' '}
              </p>
            )}
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Button
            onClick={onComplete}
            className='my-2 rp-font-bold'
            color='primary'
          >
            {t('Back to Dashboard')}
          </Button>
        </Row>
      </Col>
    </Row>
  )
}

export default ConfirmationPage
