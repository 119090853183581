import React, { useEffect, useMemo, useState } from 'react'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../components/ui/side-menu'
import Button from '../../components/ui/button'
import { CheckItem } from '../../components/ui/check-item'
import SearchBar from '../../components/SearchBar'
import { CircleHalf, UsersFour } from '@phosphor-icons/react'
import { cn, ProfileCard } from 'ui'
import CardButtons from '../../components/ui/card-button/card-buttons'
import { useFetch } from '../../helpers/hooks'
import { getPreferences, savePreference } from '../../services/api-preference'
import toastr from 'toastr'
import { getWorkersInCalendarView } from '../../services/api-events'
import Loader from '../../components/ui/loader'

const LAST_FILTER_KEY = 'calendar.last_selected_view'
export const CALENDAR_SELECTED_WORKERS_FILTER_KEY = 'calendar.selected_workers'
export const CALENDAR_WORKERS_VIEW = {
  MY_CIRCLE: 'MY_CIRCLE',
  ALL_WORKERS: 'ALL_WORKERS',
}
export default function SelectWorkersFilter({
  toggle,
  show,
  selectedWorkers,
  setSelectedWorkers,
  workersView,
  setWorkersView,
  getWorkersInCalendarViewBody,
}) {
  const [search, setSearch] = useState('')
  const [newSelectedWorkers, setNewSelectedWorkers] = useState(selectedWorkers)
  const [allWorkers, setAllWorkers] = useState(null)
  const [myCircleWorkers, setMyCircleWorkers] = useState(null)
  const [mockLoading, setMockLoading] = useState(false)

  const workers =
    workersView === CALENDAR_WORKERS_VIEW.MY_CIRCLE
      ? (myCircleWorkers ?? [])
      : (allWorkers ?? [])
  const allContractIds = workers?.map((w) => w.contract_id).filter(Boolean)
  const selectedWorkersInCalendarView =
    workersView === CALENDAR_WORKERS_VIEW.MY_CIRCLE
      ? new Set(
          Array.from(newSelectedWorkers).filter((cId) =>
            allContractIds.includes(cId),
          ),
        )
      : newSelectedWorkers
  const isAllSelected =
    allContractIds.length > 0 &&
    allContractIds.length === selectedWorkersInCalendarView.size

  const selectAllWorkers = () => {
    setNewSelectedWorkers(new Set(allContractIds))
  }
  const toggleSelectAll = () => {
    if (selectedWorkersInCalendarView.size === allContractIds.length) {
      setNewSelectedWorkers(new Set())
    } else {
      selectAllWorkers()
    }
  }

  const autoFetchWorkers =
    workersView !== null && (allWorkers === null || myCircleWorkers === null)
  const { isLoading: isFetchingWorkers } = useFetch(
    {
      action: getWorkersInCalendarView,
      body: getWorkersInCalendarViewBody,
      autoFetch: autoFetchWorkers,
      onComplete: (data) => {
        if (workersView === CALENDAR_WORKERS_VIEW.ALL_WORKERS) {
          setAllWorkers(data)
        } else {
          setMyCircleWorkers(data)
        }
      },
      onError: (error) => {
        toastr.error(error)
      },
    },
    [workersView],
  )

  const workersGroup = useMemo(() => {
    const group = {}
    workers
      .filter((w) => !!w.name) // confirm they're workers (with names)
      .forEach((worker) => {
        const isChecked = selectedWorkersInCalendarView.has(worker?.contract_id)
        const key = isChecked
          ? 'Selected workers'
          : worker.name[0]?.toLowerCase()
        if (!group[key]) {
          group[key] = []
        }
        group[key].push(worker)
      })

    return Object.keys(group)
      .sort() // Sort the keys alphabetically
      .reduce((acc, key) => {
        acc[key] = group[key] // Reconstruct the object in sorted order
        return acc
      }, {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    myCircleWorkers,
    allWorkers,
    workersView,
    newSelectedWorkers,
    selectedWorkersInCalendarView,
  ])

  const { startFetch: createPreference } = useFetch({
    action: savePreference,
    onError: (error) => {
      toastr.error(error)
    },
  })

  const { isLoading: isFetchingPreferences, data: preferencesData } = useFetch(
    {
      action: getPreferences,
      autoFetch: true,
      onComplete: (data) => {
        // todo: uncomment after calendar issue fix from BE
        // const foundCalendarViewFilter = data?.find(
        //   (obj) => obj.key === LAST_FILTER_KEY,
        // )?.value
        // if (foundCalendarViewFilter) {
        //   setWorkersView(foundCalendarViewFilter)
        // } else {
        //   setWorkersView(CALENDAR_WORKERS_VIEW.MY_CIRCLE) // default
        // }
      },
      onError: (error) => {
        toastr.error(error)
      },
    },
    [],
  )

  const selectPresavedWorkers = () => {
    const foundCalendarSelectedWorkersFilter = preferencesData?.find(
      (obj) => obj.key === CALENDAR_SELECTED_WORKERS_FILTER_KEY,
    )?.value

    let currentWorkersInSavedPreference = []

    // select pre-saved workers
    if (foundCalendarSelectedWorkersFilter) {
      currentWorkersInSavedPreference = allContractIds.filter((id) =>
        Array.from(JSON.parse(foundCalendarSelectedWorkersFilter)).includes(id),
      )
      if (currentWorkersInSavedPreference.length) {
        setNewSelectedWorkers(new Set(currentWorkersInSavedPreference))
      }
    }
    // default, select all workers
    else if (
      currentWorkersInSavedPreference.length === 0 &&
      !foundCalendarSelectedWorkersFilter
    ) {
      selectAllWorkers()
    }
  }

  useEffect(() => {
    if (isFetchingWorkers === false && isFetchingPreferences === false) {
      selectPresavedWorkers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingWorkers, isFetchingPreferences])

  useEffect(() => {
    // mock 500 ms loader (for better UX)
    if (allWorkers !== null && myCircleWorkers !== null) {
      setMockLoading(true)
    }
    const timeoutId = setTimeout(() => {
      setMockLoading(false)
    }, 500)

    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workersView])

  const workersViewOptions = [
    {
      label: 'My circle',
      description: 'Assigned to you',
      icon: () => <CircleHalf size={32} />,
      value: CALENDAR_WORKERS_VIEW.MY_CIRCLE,
    },
    {
      label: 'All workers',
      description: 'Company members',
      icon: () => <UsersFour size={32} />,
      value: CALENDAR_WORKERS_VIEW.ALL_WORKERS,
    },
  ]

  const handleWorkerSelect = (checked, _worker) => {
    if (checked) {
      setNewSelectedWorkers(
        new Set([...newSelectedWorkers, _worker?.contract_id]),
      )
    } else {
      const sW = new Set([...newSelectedWorkers])
      sW.delete(_worker?.contract_id)
      setNewSelectedWorkers(sW)
    }
  }

  const isLoading = isFetchingPreferences || isFetchingWorkers || mockLoading

  return (
    <SideMenu
      onClose={toggle}
      isOpen={show}
      right={false}
      className='!tw-w-full tw-max-w-[480px]'
      itemListClassName='tw-grid tw-grid-rows-[77px_1fr_91px] [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain'
    >
      <SideMenuHeader toggle={toggle}>Select workers to show</SideMenuHeader>
      <SideMenuBody className='tw-flex tw-flex-col tw-overflow-hidden'>
        <div>
          <div className='tw-flex tw-flex-col tw-gap-4'>
            {/* todo: uncomment after calendar issue fix from BE */}
            {/* <CardButtons
              value={workersView}
              onChange={({ value }) => {
                setWorkersView(value)
              }}
              options={workersViewOptions}
              className='tw-flex tw-justify-between'
              buttonNoPadding
              buttonClassName='tw-py-4 tw-px-5'
              labelClassName='tw-font-bold tw-text-sm'
              descriptionClassName='tw-mt-0'
            /> */}

            <SearchBar
              placeholder='Find'
              query={search}
              onQueryChanged={setSearch}
            />
          </div>
          <hr className='-tw-mx-4 !tw-mb-0 md:-tw-mx-6' />
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className='tw-overflow-scroll'>
            {Object.entries(workersGroup)
              .filter(
                ([_, ws]) =>
                  !search ||
                  ws.filter((_w) =>
                    _w.name?.toLowerCase?.().includes?.(search?.toLowerCase()),
                  ).length > 0,
              )
              .map(([labelHeader, _workers], i) => {
                return (
                  <div key={i} className='tw-flex tw-flex-col tw-gap-3'>
                    <div className='tw-sticky tw-top-0 tw-z-10 tw-bg-white tw-py-2 tw-text-sm tw-capitalize tw-text-text-80'>
                      {labelHeader}
                    </div>
                    <div className='tw-flex tw-flex-col'>
                      {_workers
                        .filter(
                          (w) =>
                            !search ||
                            w.name
                              ?.toLowerCase?.()
                              ?.includes?.(search?.toLowerCase()),
                        )
                        .map((_worker) => {
                          const isChecked = selectedWorkersInCalendarView.has(
                            _worker?.contract_id,
                          )
                          return (
                            <div
                              key={_worker?.contract_id}
                              className={cn(
                                'tw-flex tw-cursor-pointer tw-border-b tw-border-b-surface-30 tw-px-4 hover:tw-bg-surface-10',
                                isChecked ? 'tw-bg-surface-20' : 'tw-bg-white',
                              )}
                              onClick={() =>
                                handleWorkerSelect(!isChecked, _worker)
                              }
                            >
                              <CheckItem
                                checked={isChecked}
                                onChange={(e) =>
                                  handleWorkerSelect(e.target.checked, _worker)
                                }
                                disabled={isLoading}
                              />
                              <div className='tw-flex tw-flex-nowrap tw-items-center tw-gap-2 tw-p-3'>
                                <ProfileCard
                                  name={_worker.name}
                                  title={_worker.name}
                                  description={_worker.title}
                                  photo={_worker.avatar}
                                  size='lg'
                                  flag={_worker.country_flag}
                                />
                              </div>
                            </div>
                          )
                        })}
                    </div>
                    <hr className='-tw-mx-4 !tw-mb-0 md:-tw-mx-6' />
                  </div>
                )
              })}
          </div>
        )}
      </SideMenuBody>

      <SideMenuFooter>
        <div className='tw-flex tw-w-full tw-items-center tw-justify-between'>
          <CheckItem
            name='select-all-workers-toggle'
            labelId='select-all-workers-toggle'
            label='Select all'
            checked={isAllSelected}
            onChange={toggleSelectAll}
            disabled={isLoading}
          />
          <Button
            type='submit'
            color='primary'
            onClick={() => {
              toggle()
              setSelectedWorkers(selectedWorkersInCalendarView)
              createPreference({
                key: CALENDAR_SELECTED_WORKERS_FILTER_KEY,
                value: JSON.stringify(Array.from(newSelectedWorkers)),
              })
              createPreference({
                key: LAST_FILTER_KEY,
                value: workersView,
              })
            }}
            disabled={selectedWorkersInCalendarView.size === 0 || isLoading}
          >
            Show Worker
            {selectedWorkersInCalendarView.size > 1 && (
              <>s ({selectedWorkersInCalendarView.size})</>
            )}
          </Button>
        </div>
      </SideMenuFooter>
    </SideMenu>
  )
}
