import {
  Asterisk,
  Bank,
  Coin,
  EnvelopeSimple,
  Flag,
  GlobeSimple,
  IdentificationBadge,
  IdentificationCard,
  MapPin,
  MapPinLine,
  MapTrifold,
  Textbox,
  WarningOctagon,
} from '@phosphor-icons/react'
import { t } from 'i18next'
import React, { useState } from 'react'

import { cn } from 'ui'
import ConfirmationModal from '../../components/Common/ConfirmationModal'
import Alert from '../../components/ui/alert'
import BadgeV2 from '../../components/ui/badge-v2'
import Button from '../../components/ui/button'
import { useSideMenuState } from '../../components/ui/side-menu'
import { usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { DetailsInfoList } from '../review-center/review-layout-details-components'
import { EditBankSideMenu } from './vendors/list/edit-bank-side-menu'

export function DetailSectionTitle({
  title,
  icon,
  className,
  rightItem,
  containerClassName,
}) {
  return (
    <span className={cn('tw-flex tw-justify-between', containerClassName)}>
      <span
        className={cn(
          'tw-flex tw-items-center tw-text-base tw-font-bold tw-text-black',
          className,
        )}
      >
        {icon}
        {title}
      </span>

      {rightItem}
    </span>
  )
}

function BankDetailValue({ value }) {
  return <span className='tw-font-semibold tw-text-black'>{value}</span>
}

export const VENDOR_BANK_ACCOUNT_STATUS = {
  MISSING_DETAILS: 'missing bank details',
  IN_REVIEW: 'bank details in review',
  FAILED: 'bank details verification failed',
  WAITING_FOR_FUNDS: 'waiting for funds',
  REQUEST_APPROVAL: 'request approval',
}

export const VENDOR_ADDRESS_STATUS = {
  MISSING_DETAILS: 'Missing address details',
  COMPLETED: 'Completed',
}

const BANK_BADGE_CLASSNAME = {
  pending: '!tw-bg-systemGold-20 !tw-text-systemGold-110',
  onhold: '!tw-bg-systemGold-20 !tw-text-systemGold-110',
  approved: '!tw-bg-systemGreen-20 !tw-text-systemGreen-110',
  rejected: '!tw-bg-systemRed-20 !tw-text-systemRed-110',
}

export const getBankDetailsStatus = (bankAccount) => {
  const values = { label: '', className: '' }
  if (bankAccount?.details) {
    values.label =
      bankAccount.screening_status ?? VENDOR_BANK_ACCOUNT_STATUS.FAILED
    values.className = bankAccount.screening_status
      ? BANK_BADGE_CLASSNAME[values.label]
      : BANK_BADGE_CLASSNAME.rejected
  } else if (!bankAccount?.details) {
    values.label = VENDOR_BANK_ACCOUNT_STATUS.MISSING_DETAILS
    values.className = BANK_BADGE_CLASSNAME.rejected
  }

  return values
}
export const getAddressDetailsStatus = ({ details }) => {
  const values = { label: '', className: '' }
  if (details?.address && details?.city && details?.country_id) {
    values.label = VENDOR_ADDRESS_STATUS.COMPLETED
    values.className = BANK_BADGE_CLASSNAME.approved
  } else {
    values.label = VENDOR_ADDRESS_STATUS.MISSING_DETAILS
    values.className = BANK_BADGE_CLASSNAME.rejected
  }
  return values
}

export function BankBadge({ status, details }) {
  return (
    <BadgeV2
      name={t(
        getBankDetailsStatus({ screening_status: status, details }).label,
      )}
      className={
        getBankDetailsStatus({ screening_status: status, details }).className
      }
    />
  )
}

export function AddressBadge({ details }) {
  return (
    <BadgeV2
      name={t(getAddressDetailsStatus({ details }).label)}
      className={getAddressDetailsStatus({ details }).className}
    />
  )
}

export function VendorBankDetails({
  bankInfo,
  className,
  handleAddBankAccount,
  canManageBills,
  vendor,
  updateVendors,
}) {
  const [showConfirm, setShowConfirm] = useState()
  const [isAddBankOpen, toggleAddBank] = useSideMenuState()
  const { details, screening_status: status } = bankInfo ?? {}
  const { hasAccess } = usePermissions()
  function confirmEditBankDetails() {
    setShowConfirm(false)
    toggleAddBank()
  }
  const canEditVendorBankAccount = hasAccess(permissions.ManageVendors)

  return (
    <>
      <DetailSectionTitle
        title={
          <>
            {t('Bank details')}

            <BankBadge status={status} details={details} />
          </>
        }
        icon={<Bank size={20} className='tw-mr-0 tw-fill-primary' />}
        rightItem={
          details && canEditVendorBankAccount ? (
            <Button
              color='link'
              className='!tw-px-0 !tw-text-primary'
              onClick={() => setShowConfirm(true)}
            >
              {t('Edit')}
            </Button>
          ) : null
        }
        containerClassName='tw-justify-between tw-gap-2 tw-mb-4'
        className='tw-justify-between tw-gap-2'
      />

      {details ? (
        <DetailsInfoList
          className={cn('tw-p-0', className)}
          items={(bankInfo?.details ?? [])?.map(({ name: label, value }) => {
            return {
              label,
              icon: <BankDetailIcon label={label} size={24} />,
              value: <BankDetailValue value={value} />,
              className: 'tw-flex-nowrap',
              valueClassName: 'tw-text-wrap tw-text-right',
            }
          })}
        />
      ) : (
        <Alert
          className='tw-mt-5 !tw-text-wrap !tw-border-systemRed-20 !tw-bg-systemRed-20 !tw-p-4 !tw-text-xs !tw-text-text-80'
          customIcon={
            <WarningOctagon size={20} className='tw-fill-systemRed' />
          }
          iconClassName='[--icon-size:1.25rem]'
          innerClassName='tw-mt-0'
          innerTag='div'
        >
          <p className='tw-mb-0'>
            {t(
              'Bank details are missing for this vendor, make sure to add them in order to pay bills.',
            )}
          </p>

          {!handleAddBankAccount ||
          typeof handleAddBankAccount !== 'function' ||
          !canManageBills ||
          !canEditVendorBankAccount ? null : (
            <Button
              color='link'
              className='tw-mt-2 !tw-px-0 !tw-py-1 !tw-text-primary'
              onClick={handleAddBankAccount}
            >
              {t('Add Bank Details')}
            </Button>
          )}
        </Alert>
      )}
      <ConfirmationModal
        toggle={() => setShowConfirm(false)}
        isOpen={showConfirm}
        title={t('Edit Bank Details')}
        message='New bank details you enter will replace the previous ones. Only new bills you create and the bills that are in draft status will have the new bank details. Bills details that are already sent for approval will not be changed.'
        onConfirm={confirmEditBankDetails}
      />

      <EditBankSideMenu
        isOpen={isAddBankOpen}
        toggle={toggleAddBank}
        vendor={vendor}
        updateVendors={updateVendors}
        source='bill_list'
      />
    </>
  )
}

function BankDetailIcon({ label, className = 'tw-fill-text-60', size = 24 }) {
  switch (label.toLowerCase()) {
    case 'beneficiary name': {
      return <Textbox size={size} className={className} />
    }
    case 'beneficiary type': {
      return <IdentificationBadge size={size} className={className} />
    }
    case 'account number':
    case 'bank name': {
      return <Bank size={size} className={className} />
    }
    case 'bic code':
    case 'swift code': {
      return <GlobeSimple size={size} className={className} />
    }
    case 'account holder':
    case 'iban': {
      return <IdentificationCard size={size} className={className} />
    }
    case 'country': {
      return <Flag size={size} className={className} />
    }
    case 'state': {
      return <MapPinLine size={size} className={className} />
    }
    case 'city': {
      return <MapTrifold size={size} className={className} />
    }
    case 'currency': {
      return <Coin size={size} className={className} />
    }
    case 'post code': {
      return <EnvelopeSimple size={size} className={className} />
    }
    case 'first line':
    case 'second line': {
      return <MapPin size={size} className={className} />
    }
    default: {
      return <Asterisk size={size} className={className} />
    }
  }
}
