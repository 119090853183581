import { CalendarBlank } from '@phosphor-icons/react'
import { format } from 'date-fns'
import { t } from 'i18next'
import React, { useState } from 'react'
import { Button, TooltipTrigger } from 'react-aria-components'
import { useHistory } from 'react-router'

import { useFetch } from '../../../helpers/hooks'
import { getTimelineEvents } from '../../../services/api-events'
import { getPreferences } from '../../../services/api-preference'
import { TooltipV2 } from '../../VerticalLayout/tooltip-v2'

const CalendarIcon = () => {
  const history = useHistory()
  const [todayHasEvents, setTodayHasEvents] = useState(false)
  const [persistedFilters, setPersistedFilters] = useState({})

  useFetch(
    {
      action: getPreferences,
      autoFetch: true,
      onComplete: (data) => {
        const foundCalendarFilters = data?.find(
          (obj) => obj.key === 'calendar.filters',
        )?.value
        foundCalendarFilters &&
          setPersistedFilters(JSON.parse(foundCalendarFilters))
      },
    },
    [],
  )

  const { isLoading } = useFetch(
    {
      action: getTimelineEvents,
      autoFetch: true,
      initResult: [],
      body: {
        ...persistedFilters,
        from: format(new Date(), 'yyyy-MM-dd'),
        to: format(new Date(), 'yyyy-MM-dd'),
      },
      onComplete: (data) => {
        setTodayHasEvents(data.length > 0)
      },
    },
    [persistedFilters],
  )

  return (
    <TooltipTrigger delay={0}>
      <Button
        className='tw-relative tw-hidden tw-p-4 tw-text-secondary-100 hover:tw-bg-surface-30 hover:tw-text-secondary-100 lg:tw-block'
        onPress={() => history.push('/new-calendar')}
        type='button'
        aria-label='Open Calendar'
      >
        <CalendarBlank size={24} />
        {todayHasEvents && !isLoading && (
          <div className='tw-absolute tw-right-4 tw-top-5 tw-flex tw-h-3 tw-w-3 tw-items-center tw-justify-center tw-rounded-lg tw-bg-white'>
            <div className='tw-h-2 tw-w-2 tw-rounded-lg tw-bg-systemRed-100' />
          </div>
        )}
      </Button>

      <TooltipV2 className='tw-text-xs' placement='bottom'>
        {t('Calendar')}
      </TooltipV2>
    </TooltipTrigger>
  )
}

export default CalendarIcon
