import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { STEP_TYPE, steps } from './consts'
import { Intro } from './intro'
import { Question } from './question'
import { Result } from './result'

const StepsContext = createContext({})

export function StepsProvider(props) {
  const [stepIndex, setStepIndex] = useState(0)
  const [answers, setAnswers] = useState({})
  const nextStep = () => setStepIndex((prev) => prev + 1)
  const retest = () => {
    setStepIndex(0)
    setAnswers({})
  }
  const previousStep = () => setStepIndex((prev) => prev - 1)
  const step = useMemo(() => steps[stepIndex], [stepIndex])
  const questions = useMemo(
    () => steps.filter(({ type }) => type === STEP_TYPE.QUESTION),
    [],
  )
  const renderStep = useCallback(() => {
    if (step.type === STEP_TYPE.INTRO) return <Intro />
    if (step.type === STEP_TYPE.QUESTION) return <Question />
    if (step.type === STEP_TYPE.RESULT) return <Result />
  }, [step])
  return (
    <StepsContext.Provider
      value={{
        answers,
        setAnswers,
        nextStep,
        previousStep,
        renderStep,
        retest,
        questions,
        isOpen: props.isOpen,
        toggle: props.toggle,
        step,
      }}
    >
      {props.children}
    </StepsContext.Provider>
  )
}

export function useSteps() {
  return useContext(StepsContext)
}
