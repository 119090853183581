import { Plugs } from '@phosphor-icons/react'
import { format } from 'date-fns'
import { t } from 'i18next'
import React from 'react'
import { mergeProps, useTooltip, useTooltipTrigger } from 'react-aria'
import { useTooltipTriggerState } from 'react-stately'

import { PermissionTooltip } from '../../../components/permission-tooltip'
import Button from '../../../components/ui/button'
import { LinkOut } from '../../../components/ui/link-out'
import { usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import { PERMISSION_GROUP } from '../manage-role'
import { getHelpLink } from './get-integration-help-link'

export function IntegrationsListItem({
  integration,
  onConnect,
  connectLoading,
  onReview,
  onDisable,
  disabling,
}) {
  const isEnabled = !!integration?.connection

  const { hasAccess } = usePermissions()
  const canManageIntegrations = hasAccess(permissions.ManageCompanyIntegration)

  const helpLink = getHelpLink(integration.key)

  const lasSyncDate =
    !isEnabled || !integration?.connection?.lastActiveAt
      ? null
      : format(
          new Date(integration.connection.lastActiveAt),
          'dd/MM/yy, hh:mm a',
        )

  return (
    <div className='tw-flex tw-min-h-[214px] tw-flex-col tw-gap-4 tw-rounded tw-border tw-border-surface-30 tw-p-4'>
      <div className='tw-flex tw-justify-between'>
        <div className='tw-relative tw-size-14'>
          {!isEnabled ? null : (
            <div className='tw-absolute tw-size-3 tw-rounded-full tw-bg-green-100 tw-ring-2 tw-ring-white'></div>
          )}
          <img
            height={56}
            width={56}
            src={integration?.logoUri}
            className='tw-size-full tw-overflow-clip tw-rounded-full'
          />
        </div>

        {!lasSyncDate ? null : (
          <div className='tw-text-end tw-text-xs'>
            <div className='tw-text-text-80'>{t('Last synced')}</div>
            <div>{lasSyncDate}</div>
          </div>
        )}
      </div>

      <div>
        <div className='tw-mb-2 tw-flex tw-items-center tw-gap-2'>
          <h4 className='tw-mb-0 tw-text-xl tw-font-bold'>
            {integration.name}
          </h4>

          {!helpLink ? null : (
            <LinkOut href={helpLink.href} title={helpLink.title} />
          )}
        </div>
        {!integration?.subtitle ? null : (
          <p className='tw-mb-1 tw-line-clamp-2 tw-text-xs'>
            {integration.subtitle}
          </p>
        )}
        {!integration?.description ? null : (
          <p className='tw-mb-0 tw-line-clamp-4 tw-text-xs tw-text-text-80'>
            {integration.description}
          </p>
        )}
      </div>

      <div className='tw-mt-auto tw-flex tw-flex-wrap tw-gap-2 *:tw-flex-1'>
        <PermissionTooltip
          showing={!canManageIntegrations}
          id={`btn-new-integration-${integration?.id}`}
          area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
        >
          {!isEnabled ? (
            <Button
              type='button'
              icon={<Plugs size={20} />}
              onClick={onConnect}
              loading={connectLoading}
              disabled={!canManageIntegrations || connectLoading}
            >
              {t('Connect')}
            </Button>
          ) : (
            <>
              {integration?.connection?.disconnected ? (
                <TooltipButton
                  outline
                  icon={<Plugs size={20} />}
                  onClick={onConnect}
                  tooltip='The integration is disconnected. Click to reconnect.'
                >
                  {t('Re-connect')}
                </TooltipButton>
              ) : (
                <Button
                  type='button'
                  color='light'
                  outline
                  onClick={onReview}
                  disabled={!canManageIntegrations || disabling}
                >
                  {t('Review')}
                </Button>
              )}
              <Button
                type='button'
                color='danger'
                outline
                onClick={onDisable}
                disabled={!canManageIntegrations || disabling}
                loading={disabling}
              >
                {t('Disconnect')}
              </Button>
            </>
          )}
        </PermissionTooltip>
      </div>
    </div>
  )
}

function Tooltip({ state, ...props }) {
  const { tooltipProps } = useTooltip(props, state)

  return (
    <span
      className='tw-absolute tw-left-1 tw-top-full tw-z-50 tw-mt-3 tw-max-w-40 tw-rounded tw-bg-black tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-will-change-transform'
      {...mergeProps(props, tooltipProps)}
    >
      {props.children}
    </span>
  )
}

function TooltipButton({ icon, outline, color, onClick, ...props }) {
  const state = useTooltipTriggerState({ delay: 50, closeDelay: 50, ...props })
  const ref = React.useRef(null)

  const { triggerProps, tooltipProps } = useTooltipTrigger(props, state, ref)

  return (
    <span style={{ position: 'relative' }}>
      <Button
        ref={ref}
        {...triggerProps}
        icon={icon}
        outline={outline}
        color={color}
        onClick={onClick}
      >
        {props.children}
      </Button>
      {state.isOpen && (
        <Tooltip state={state} {...tooltipProps}>
          {props.tooltip}
        </Tooltip>
      )}
    </span>
  )
}
