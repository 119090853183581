import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import toastr from 'toastr'

import { cn } from 'ui'
import ActionCenter from '../../components/ActionCenter'
import NoContent from '../../components/NoContent'
import { StyledH5, StyledH6 } from '../../components/Typo'
import Loader from '../../components/ui/loader'
import { useFetch } from '../../helpers/hooks'
import { getFullTimeContractDetails, getToDoList } from '../../services/api'
import { updateContract } from '../../store/contract/actions'
import { PayslipList } from '../Fulltime/Payslips'
import emptyDocs from './../../assets/images/empty_docs.svg'
import { tagSession } from './center-overview-client'
import { t } from 'i18next'

function StyledCol({ children, className }) {
  return (
    <Col
      className={cn('tw-rounded tw-bg-white', className)}
      style={{ boxShadow: '0 1px 0 #dfe1e6' }}
    >
      {children}
    </Col>
  )
}

function EmployeeOverview() {
  const user = useSelector((state) => state.Account?.user)

  const dispatch = useDispatch()
  const employeeActivity = useFetch({
    action: getFullTimeContractDetails,
    autoFetch: true,
    onComplete: (data) => {
      dispatch(updateContract(data))
    },
  })
  const todo = useFetch(
    {
      action: getToDoList,
      initResult: [],
      autoFetch: true,
      onError: (err) => toastr.error(err),
      onComplete: () => {
        tagSession(user)
      },
    },
    [],
  )
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: employeeActivity?.data?.salary_currency?.code || 'USD',
  })

  return (
    <div className='page-content'>
      {employeeActivity.isLoading ? (
        <Loader minHeight='30rem' />
      ) : (
        <Container fluid className='p-0 m-0'>
          <h1 className='tw-mb-1 tw-text-2xl sm:tw-text-3xl lg:tw-text-4xl'>
            {t('Hi,')}
            <span translate='no'>{user?.first_name}</span>
          </h1>
          <h2 className='tw-mb-6 tw-text-base tw-font-normal tw-text-text-80'>
            {t('Here is a recap of your employment')}
          </h2>

          {todo?.data?.length > 0 && <ActionCenter data={todo.data} />}

          <StyledCol className='!tw-p-4 md:!tw-p-6'>
            <h3 className='tw-mb-4 md:tw-mb-6'>{t('Employment details')}</h3>

            <Row className='mb-3'>
              <Col className='col-md-4 col-12'>
                <StyledH5 className='font-size-14 text-secondary font-weight-light'>
                  {t('Job title')}
                </StyledH5>
                <StyledH6 className='font-size-14'>
                  {employeeActivity?.data?.name}
                </StyledH6>
              </Col>
              <Col className='col-md-4 col-12'>
                <StyledH5 className='font-size-14 text-secondary font-weight-light'>
                  {t('Company')}
                </StyledH5>
                <StyledH6 className='font-size-14'>
                  {employeeActivity?.data?.company_name}
                </StyledH6>
              </Col>
              <Col className='col-md-4 col-12'>
                <StyledH5 className='font-size-14 text-secondary font-weight-light'>
                  {t('Status')}
                </StyledH5>
                <StyledH6 className='font-size-14'>
                  {t('Full-time employee')}
                </StyledH6>
              </Col>
            </Row>
            <Row>
              <Col className='col-md-4 col-12'>
                <StyledH5 className='font-size-14 text-secondary font-weight-light'>
                  {t('Start date')}
                </StyledH5>
                <StyledH6 className='font-size-14'>
                  {employeeActivity?.data?.start_date}
                </StyledH6>
              </Col>
              <Col className='col-md-4 col-12'>
                <StyledH5 className='font-size-14 text-secondary font-weight-light'>
                  {t('Gross Salary')}
                </StyledH5>
                <StyledH6 className='font-size-14'>
                  {!employeeActivity?.data?.amount
                    ? null
                    : formatter.format(employeeActivity?.data?.amount)}
                </StyledH6>
              </Col>
              <Col className='col-md-4 col-12' />
            </Row>
          </StyledCol>

          <StyledCol className='tw-mt-6 !tw-p-4 md:!tw-p-6'>
            <h3 className='tw-mb-4 md:tw-mb-6'>{t('Recent Payslips')}</h3>

            {!employeeActivity.data?.payslips?.length ? (
              <NoContent
                headline={t('No payslips added')}
                subtitle={t(
                  'Your payslips will be shown here when added by the employer',
                )}
                image={emptyDocs}
              />
            ) : (
              <PayslipList data={employeeActivity.data?.payslips} isShorted />
            )}
          </StyledCol>

          <div className='bg-white'></div>
        </Container>
      )}
    </div>
  )
}

export default EmployeeOverview
