import React from 'react'
import { Controller } from 'react-hook-form'
import Toggle from '../../../../../components/Forms/Toggle/Toggle'
import CustomSelect from '../../../../../components/Forms/CustomSelect/CustomSelect'
import { getTimeZones } from '../tools'
import { allTimezones } from '../tools/constants'
import ControlledInput from '../../../../../components/ControlledInput'

export function ActionSchedule(props) {
  const { index, type } = props
  return (
    <Controller
      name={`steps.${index}.scheduled`}
      render={({ field: { value, onChange } }) => (
        <div className='tw-rounded tw-border tw-border-surface-130'>
          <div className='tw-flex tw-flex-row tw-items-center tw-justify-between tw-p-4'>
            <div className='tw-flex tw-flex-col tw-gap-0.5'>
              <span className='tw-font-bold tw-text-text'>
                Schedule {type === 'email' ? 'email' : 'message'}
              </span>
              <span className='tw-text-text-60'>
                Do you want to send this{' '}
                {type === 'email' ? 'email' : 'message'} at a specific time?
              </span>
            </div>
            <Toggle check={value} change={onChange} />
          </div>

          {value ? (
            <div className='tw-flex tw-flex-row tw-gap-3.5 tw-bg-surface-30 tw-p-4'>
              <ControlledInput
                wrapperClassName='tw-flex-1'
                label='What time?'
                type='time'
                name={`steps.${index}.time`}
              />
              <Controller
                render={({ field }) => (
                  <CustomSelect
                    wrapperClassName='tw-flex-1'
                    label='Which timezone?'
                    options={getTimeZones(allTimezones)}
                    {...field}
                  />
                )}
                name={`steps.${index}.timeZone`}
              />
            </div>
          ) : null}
        </div>
      )}
    />
  )
}
