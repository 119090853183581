import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useMemo, useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { useForm } from 'react-hook-form'
import {
  Alert,
  Card,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledDatePicker from '../../../../components/ControlledDatePicker'
import ControlledInput from '../../../../components/ControlledInput'
import ControlledSelect from '../../../../components/ControlledSelect'
import BadgeX from '../../../../components/Table/BadgeX'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import Loader from '../../../../components/ui/loader'
import NoContent from '../../../../components/ui/no-content'
import Pagination from '../../../../components/ui/pagination'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import { useFetch, useResize } from '../../../../helpers/hooks'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import {
  adminAcceptCardKyc,
  adminDownloadEddForm,
  adminRejectCardKyc,
  adminSubmitCardKyc,
  cardsKycList,
  getCardEnumerations,
  getCardKycDetails,
  screenContractor,
} from '../../../../services/api'
import openFileV2 from '../../../../utils/file/open-v2'
import { isProduction } from '../../../../utils/isProduction'
import unSlugify from '../../../../utils/un-slugify'
import { KYC_STATUSES } from '../../../remotepass-cards/components/cards-kyc-banner'
import { BurgerDetails } from '../Users/users'
import './av-field-arrow-style.css'
import { dateCell } from './cell'
import { FilterField } from './filter-field'
import { useFilters } from './use-filters'

const statuses = {
  [KYC_STATUSES.PENDING]: { label: 'Pending', color: 'warning' },
  [KYC_STATUSES.ACCEPTED]: { label: 'Accepted', color: 'success' },
  [KYC_STATUSES.SUBMITTED]: {
    label: 'Submitted',
    color: 'info',
  },
  [KYC_STATUSES.REJECTED]: { label: 'Rejected', color: 'danger' },
  [KYC_STATUSES.APPROVED]: { label: 'Approved', color: 'success' },
  [KYC_STATUSES.DONE]: { label: 'Done', color: 'success' },
}

export const statusesOptions = Object.entries(statuses).reduce(
  (prev, [value, { label, color }]) => {
    return [...prev, { value, label, color }]
  },
  [],
)

function KycStatus({ status }) {
  return (
    <BadgeX status={statuses[status].color} className='rp-capitalize'>
      {status}
    </BadgeX>
  )
}

function isValidEmail(email) {
  if (!email) return false

  return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
}

function getBodyFromFilters(filters) {
  return {
    ...filters,
    email: isValidEmail(filters?.email) ? filters?.email : undefined,
    kyc_request_status: filters?.kyc_request_status?.value,
    page: (filters?.page - 1).toString(),
  }
}

function ApproverCell({ cellData }) {
  if (!cellData) return 'N/A'
  if (typeof cellData === 'string') return cellData
  return (
    <>
      {cellData?.first_name} {cellData?.last_name} ({cellData?.email})
    </>
  )
}

const screeningStates = {
  idle: { label: 'No Screening', color: 'secondary', id: 'idle' },
  loading: { label: 'Loading', color: 'default', id: 'loading' },
  approved: { label: 'Approved', color: 'success', id: 'approved' },
  onhold: { label: 'On Hold', color: 'warning', id: 'onHold' },
  rejected: { label: 'Rejected', color: 'danger', id: 'rejected' },
  error: { label: 'Error', color: 'danger', id: 'error' },
  unknown: { label: 'Unknown', color: 'danger', id: 'unknown' },
}

function ScreeningCell({ rowData }) {
  const [screeningState, setScreeningState] = useState(screeningStates.idle)

  const { startFetch } = useFetch({
    action: screenContractor,
    withAdminAccess: true,
    onComplete: (data) => {
      const status = screeningStates[data?.screening_status]
      setScreeningState(status || screeningStates.unknown)
    },
    onError: () => {
      toastr.error('Error screening contractor')
      setScreeningState(screeningStates.error)
    },
  })

  function handleScreeningStateChange() {
    setScreeningState(screeningStates.loading)

    startFetch({ user_id: rowData?.rp_user_id })
  }

  switch (screeningState.id) {
    case screeningStates.idle.id: {
      return (
        <Button size='sm' outline onClick={handleScreeningStateChange}>
          Screen
        </Button>
      )
    }
    case screeningStates.loading.id: {
      return <Spinner size='sm' color='info' />
    }
  }

  const { label, color } = screeningState

  return <div className={`text-${color}`}>{label}</div>
}

export default function AdminCardsKyc() {
  const [filters, handleFiltersChange] = useFilters({
    page: 1,
    kyc_request_status: statusesOptions[0],
  })

  const [cardDocuments, setCardDocuments] = useState(null)
  const [selectedContractor, setSelectedContractor] = useState(null)

  const {
    data: kycList,
    isLoading: loadingKycList,
    startFetch: refetchKycList,
  } = useFetch(
    {
      action: cardsKycList,
      autoFetch: true,
      withAdminAccess: true,
      body: getBodyFromFilters(filters),
      onError: (resp) => {
        toastr.clear()
        toastr.error(resp?.error?.messages?.[0] ?? 'Something went wrong')
      },
    },
    [filters],
  )

  const handleRefetchData = useCallback(() => {
    refetchKycList(getBodyFromFilters(filters))
  }, [filters, refetchKycList])

  function handleCustomerClick(card) {
    setCardDocuments(card)
    document.body.classList.add('modal-open')
  }

  function handleCloseMenu() {
    setCardDocuments(null)
    document.body.classList.remove('modal-open')
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'User Id',
        accessor: 'rp_user_id',
        Cell: ({ rowData, cellData }) => (
          <button
            className='rp-btn-nostyle text-primary'
            onClick={() => setSelectedContractor(rowData?.rp_user_id)}
          >
            {cellData}
          </button>
        ),
      },
      { Header: 'Email', accessor: 'user.email' },
      {
        Header: 'Name',
        accessor: 'user.name',
        Cell: ({ cellData }) => {
          if (!cellData) return 'N/A'

          const fullName = [cellData?.first, cellData?.middle, cellData?.last]
            .filter(Boolean)
            .join(' ')

          return (
            <>
              <DisplayInfo title='Full name:' value={fullName} />
              <DisplayInfo title='Preferred:' value={cellData?.preferred} />
            </>
          )
        },
      },
      {
        Header: 'Identification',
        accessor: 'id_number',
        Cell: ({ rowData }) => {
          const user = rowData?.user
          const document = user?.id_document

          if (!user) return 'N/A'

          return (
            <>
              <DisplayInfo title='Type:' value={document?.type} />
              <DisplayInfo title='Number:' value={document?.number} />
            </>
          )
        },
      },
      { Header: 'Requested on', accessor: 'created_at', Cell: dateCell },
      filters.kyc_request_status?.value === KYC_STATUSES.REJECTED && {
        Header: 'Reason for rejection',
        accessor: 'rejection_reason',
      },
      ...([
        KYC_STATUSES.SUBMITTED,
        KYC_STATUSES.ACCEPTED,
        KYC_STATUSES.APPROVED,
        KYC_STATUSES.DONE,
      ].includes(filters.kyc_request_status?.value)
        ? [
            {
              Header: 'Accepted by',
              accessor: 'accepted_by',
              Cell: (props) => <ApproverCell {...props} />,
            },
            { Header: 'Accepted on', accessor: 'accepted_at', Cell: dateCell },
          ]
        : []),
      { Header: 'Screening', accessor: 'screening', Cell: ScreeningCell },
      {
        Header: 'Documents',
        Cell: ({ rowData }) => (
          <button
            className='rp-btn-nostyle text-primary'
            onClick={() => handleCustomerClick(rowData)}
          >
            Show documents
          </button>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ cellData }) => {
          return <KycStatus status={cellData} />
        },
      },
    ].filter((r) => Boolean(r) || !Array.isArray(r))
  }, [filters.kyc_request_status])

  return (
    <div className='page-content'>
      <DocumentsSlider
        hide={handleCloseMenu}
        isOpen={!!cardDocuments}
        cardData={cardDocuments}
        refetchData={handleRefetchData}
      />

      <BurgerDetails
        id={selectedContractor}
        hide={() => setSelectedContractor(null)}
        show={!!selectedContractor}
      />

      <div style={{ marginBottom: '2rem' }}>
        <h2 className='h1 mb-0'>KYC Cards</h2>
      </div>

      <Card>
        <div className='py-3'>
          <Filters filters={filters} onChange={handleFiltersChange} />
        </div>

        {loadingKycList ? (
          <Loader minHeight='max(50vh, 550px)' />
        ) : kycList?.items?.length <= 0 ? (
          <NoContent
            className='text-muted font-size-16'
            minHeight='max(50vh, 550px)'
          >
            No items found
          </NoContent>
        ) : (
          <>
            <DataTable
              responsive
              data={kycList?.items ?? []}
              columns={columns}
            />

            <div className='d-flex justify-content-end px-3'>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={kycList?.limit ?? 10}
                totalItemsCount={kycList?.total_items_count ?? 0}
                onChange={(v) => handleFiltersChange('page', v)}
              />
            </div>
          </>
        )}
      </Card>
    </div>
  )
}

const filtersFields = [
  {
    name: 'kyc_request_status',
    label: 'Status',
    options: statusesOptions,
    type: 'select',
  },
  { name: 'email', label: 'Email', type: 'email' },
  { name: 'name', label: 'Name', type: 'text' },
]

function Filters({ filters, onChange }) {
  return (
    <Container fluid className='px-3'>
      <Row className='mx-n2'>
        {filtersFields.map((field) => {
          return (
            <FilterField
              {...field}
              key={field.name}
              value={filters[field.name]}
              onChange={onChange}
            />
          )
        })}
      </Row>
    </Container>
  )
}

function DisplayInfo({ title, value, labelMinWidth = 60 }) {
  return (
    <div className='d-flex gap-4'>
      <div className='text-muted' style={{ minWidth: labelMinWidth }}>
        {title}
      </div>
      <div className='text-dark rp-capitalize'>{value ?? 'N/A'}</div>
    </div>
  )
}

export const riskAssessmentEnum = {
  CLEARED: 'cleared',
  REJECTED: 'rejected',
  NOT_SPECIFIED: 'not_specified',
  UNKNOWN: 'unknown',
  PENDING: 'pending',
  IN_PROGRESS: 'in_progress',
}

const riskAssessmentStatuses = {
  [riskAssessmentEnum.CLEARED]: {
    color: 'success',
    text: 'User risk assessment has passed the test',
  },
  [riskAssessmentEnum.REJECTED]: {
    color: 'danger',
    text: 'User flagged as risky',
  },
  [riskAssessmentEnum.NOT_SPECIFIED]: {
    color: 'warning',
    text: 'Something wrong in the assessment',
  },
  [riskAssessmentEnum.UNKNOWN]: {
    color: 'warning',
    text: 'Something wrong in the assessment',
  },
  [riskAssessmentEnum.PENDING]: {
    color: 'warning',
    text: 'Risk assessment evaluation will start asap',
  },
  [riskAssessmentEnum.IN_PROGRESS]: {
    color: 'warning',
    text: 'Evaluation in progress',
  },
}

function DocumentsSlider({ hide, isOpen, cardData, refetchData }) {
  const isMobile = useResize()

  function handleRefreshData() {
    refetchData()
  }

  return (
    <div
      style={{ position: 'absolute', top: 0, bottom: 0, left: 0, zIndex: 1050 }}
    >
      <Menu
        onClose={hide}
        isOpen={isOpen}
        className='bg-white'
        width={isMobile ? '100%' : '40%'}
        menuClassName='p-4'
        tag='div'
        right
      >
        <DocumentsSliderContent
          cardData={cardData}
          refetchData={handleRefreshData}
        />
      </Menu>
    </div>
  )
}

function DocumentsSliderContent({ cardData, refetchData }) {
  const hasEditPermission = useHasPermission(
    ADMIN_PERMISSIONS.MANAGE_ADMIN_CARDS,
  )

  const {
    data,
    isLoading,
    startFetch: getDetails,
  } = useFetch(
    {
      action: getCardKycDetails,
      withAdminAccess: true,
      autoFetch: !!cardData?.rp_user_id,
      body: { rp_user_id: cardData?.rp_user_id },
    },
    [cardData?.rp_user_id],
  )

  const documents = data?.documents?.map(
    ({ document_type: documentType, document_url: documentUrl }) => ({
      title: documentType,
      value: documentUrl,
    }),
  )

  function handleRefreshData() {
    refetchData()
    getDetails({ rp_user_id: cardData?.rp_user_id })
  }

  if (isLoading) {
    return <Loader />
  }

  if (!data) {
    return null
  }

  return (
    <>
      <h4>User info</h4>
      <div className='d-flex flex-column gap-6 mb-3'>
        {[
          { label: 'Preferred', value: data?.user?.name?.preferred },
          { label: 'Full name', value: data?.user?.name?.preferred },
          {
            label: 'Email',
            value: <span className='rp-lowercase'>{data?.user?.email}</span>,
          },
          { label: 'Status', value: <KycStatus status={data?.status} /> },
        ].map(({ label, value }) => (
          <DisplayInfo
            key={label}
            title={label}
            value={value}
            labelMinWidth={80}
          />
        ))}
      </div>
      {!data?.user?.residential_address ? null : <h4>Residential address</h4>}
      <div className='d-flex flex-column gap-6 mb-3'>
        {Object.entries(data?.user?.residential_address ?? {}).map(
          ([label, value]) => (
            <DisplayInfo
              key={label}
              title={unSlugify(label)}
              value={value}
              labelMinWidth={100}
            />
          ),
        )}
      </div>

      <h4>KYC Documents</h4>

      {documents?.map(({ title, value }) => {
        const regex = /\/([^/][0-9]*)\/.*\.(.*)\?/gm
        const typeMatches = regex.exec(value)
        const docMimeType = typeMatches?.[2]

        return (
          <div key={title} className='mb-3'>
            <h5 className='col-form-label p-0 m-0 mb-2 font-size-14'>
              {unSlugify(title)}
            </h5>

            <ShowDocument type={docMimeType ?? 'jpeg'} url={value} />
          </div>
        )
      })}

      <EddFormAnswers data={data?.edd_form} user={data?.user} />

      <div className='bg-slate-50 border p-3 rounded-lg mb-4'>
        <h4>Risk Assessment</h4>

        <BadgeX
          status={riskAssessmentStatuses[data?.risk_assessment?.status]?.color}
          className='rp-capitalize'
        >
          {data?.risk_assessment?.status}
        </BadgeX>
        <small className='ml-1 text-slate-700'>
          ({riskAssessmentStatuses[data?.risk_assessment?.status]?.text})
        </small>
      </div>

      {!hasEditPermission ? null : (
        <div className='d-flex flex-column gap-12 pb-4'>
          <ActionsSection data={data} refetchData={handleRefreshData} />
        </div>
      )}
    </>
  )
}

const ID_TYPE_OPTIONS = [
  { label: 'Passport', value: 'passport' },
  { label: 'ID Card', value: 'id_card' },
]

function ActionsSection({ data, refetchData }) {
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [itemToAccept, setItemToAccept] = useState(null)

  const [rejectModal, setRejectModal] = useState(false)
  const [itemToReject, setItemToReject] = useState(null)

  const kycStatus = data?.status

  const { data: enumerations } = useFetch({
    action: getCardEnumerations,
    autoFetch: true,
    withAdminAccess: true,
  })

  function toggleAcceptModal() {
    setShowAcceptModal((open) => !open)
  }

  function toggleRejectModal() {
    setRejectModal((open) => !open)
  }

  function handleCompleteRequest(data) {
    if (data?.success === true) {
      refetchData()

      setRejectModal(false)
      setShowAcceptModal(false)
      setItemToReject(null)
    }
  }

  function handleErrorRequest(resp) {
    toastr.error(
      resp?.error?.messages?.[0] ??
        'Something went wrong while updating the KYC status',
    )
  }

  const { startFetch: acceptKycStatus, isLoading: acceptingKycStatus } =
    useFetch({
      action: adminAcceptCardKyc,
      withAdminAccess: true,
      onComplete: handleCompleteRequest,
      onError: handleErrorRequest,
    })
  const { startFetch: rejectKycStatus, isLoading: rejectingKycStatus } =
    useFetch({
      action: adminRejectCardKyc,
      withAdminAccess: true,
      onComplete: handleCompleteRequest,
      onError: handleErrorRequest,
    })

  const { startFetch: submitKycStatus, isLoading: submittingKycStatus } =
    useFetch({
      action: adminSubmitCardKyc,
      withAdminAccess: true,
      onComplete: handleCompleteRequest,
      onError: handleErrorRequest,
    })

  const kycUpdating =
    acceptingKycStatus || rejectingKycStatus || submittingKycStatus

  function triggerAccept(values) {
    toggleAcceptModal()
    setItemToAccept({ data, values })
  }

  function handleAccept() {
    const body = {
      ...itemToAccept?.values,
      rp_user_id: itemToAccept?.data?.rp_user_id,
      kyc_request_status: KYC_STATUSES.ACCEPTED,
    }

    if (body?.id_date_expiry === null || body?.id_date_expiry === '') {
      delete body.id_date_expiry
    }

    acceptKycStatus(body)
  }

  function triggerReject() {
    toggleRejectModal()
    setItemToReject(data)
  }

  function handleReject(values) {
    if (!itemToReject?.rp_user_id) {
      toastr.error('Something went wrong')
      return
    }

    const body = {
      rp_user_id: itemToReject.rp_user_id,
      kyc_request_status: KYC_STATUSES.REJECTED,
      country_of_issue: '',
      ...values,
    }

    rejectKycStatus(body)
  }

  function handleSubmitKyc() {
    const body = {
      rp_user_id: data?.rp_user_id,
      kyc_request_status: KYC_STATUSES.SUBMITTED,
    }

    submitKycStatus(body)
  }

  const defaultValues = useMemo(() => {
    const idDocument = data?.user?.id_document

    return {
      id_type: idDocument?.type,
      id_number: idDocument?.number,
      id_date_expiry: null,
      country_of_issue: '',
    }
  }, [data?.user?.id_document])

  const { handleSubmit, control } = useForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        country_of_issue: yup.string().required().label('Country of issue'),
        id_type: yup.string().required().label('ID Type'),
        id_number: yup.string().required().label('ID Number'),
        id_date_expiry: yup.string().nullable().label('ID Date Expiry'),
      }),
    ),
  })

  if (![KYC_STATUSES.PENDING, KYC_STATUSES.ACCEPTED].includes(kycStatus)) {
    return null
  }

  return (
    <>
      {kycStatus !== KYC_STATUSES.PENDING ? null : (
        <>
          <form onSubmit={handleSubmit(triggerAccept)} className='tw-space-y-4'>
            <ControlledSelect
              control={control}
              name='country_of_issue'
              inputId='country_of_issue'
              label='Country of issue'
              options={[
                { label: 'Select a country', value: '', isDisabled: true },
                ...(enumerations?.countries?.map(({ description, code }) => ({
                  label: description,
                  value: code,
                })) ?? []),
              ]}
            />

            <ControlledSelect
              control={control}
              name='id_type'
              inputId='id_type'
              label='ID Type'
              options={ID_TYPE_OPTIONS}
            />

            <ControlledInput
              control={control}
              name='id_number'
              id='id_number'
              label='ID Number'
            />

            <ControlledDatePicker
              control={control}
              name='id_date_expiry'
              id='id_date_expiry'
              label='Id Date Expiry'
              placeholder='Pick a date'
              // the min is today's date in the format YYYY-MM-DD
              minDate={new Date()}
              dateFormat='yyyy-MM-dd'
            />

            <Button size='sm' color='success' type='submit'>
              Accept
            </Button>
          </form>

          <AcceptModal
            isOpen={showAcceptModal}
            toggle={toggleAcceptModal}
            onSubmit={handleAccept}
            enumerations={enumerations}
            loading={kycUpdating}
          />
        </>
      )}

      {kycStatus !== KYC_STATUSES.ACCEPTED ? null : (
        <Button
          size='sm'
          color='success'
          style={{ alignSelf: 'flex-start' }}
          loading={kycUpdating}
          disabled={kycUpdating}
          onClick={handleSubmitKyc}
        >
          Submit
        </Button>
      )}

      {![KYC_STATUSES.PENDING, KYC_STATUSES.ACCEPTED].includes(
        kycStatus,
      ) ? null : (
        <>
          <div className='d-flex gap-4'>
            <Button
              size='sm'
              color='danger'
              outline
              onClick={() => triggerReject()}
            >
              Reject
            </Button>
          </div>

          <RejectModal
            isOpen={rejectModal}
            toggle={toggleRejectModal}
            onSubmit={handleReject}
            loading={kycUpdating}
          />
        </>
      )}
    </>
  )
}

function ShowDocument({ type, url }) {
  const classList = 'border border-light rounded bg-light'

  switch (type) {
    case 'pdf': {
      return (
        <iframe
          src={url + '#toolbar=0&navpanes=0&scrollbar=0'}
          className={classList}
          style={{ border: 'none' }}
          width='auto'
          height='450px'
        />
      )
    }

    case 'png':
    case 'jpg':
    case 'jpeg': {
      return (
        <img
          src={url}
          style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
          className={classList}
        />
      )
    }

    default: {
      return isProduction()
        ? null
        : `We don't support the "${type}" type of document`
    }
  }
}

export const kycRejectionReasons = {
  INVALID_ID: 'invalid_id',
  INVALID_POA: 'invalid_poa',
  OTHER: 'other',
}

const kycRejectionReasonsOptions = [
  { label: 'Invalid ID', value: kycRejectionReasons.INVALID_ID },
  { label: 'Invalid Proof of Address', value: kycRejectionReasons.INVALID_POA },
  { label: 'Other', value: kycRejectionReasons.OTHER },
]

const rejectModalFormId = 'rejectModalFormId'
function RejectModal({ isOpen, toggle, onSubmit, loading }) {
  const { handleSubmit, control, reset } = useForm({
    defaultValues: { rejection_reason: '', note: '' },
    resolver: yupResolver(
      yup.object().shape({
        rejection_reason: yup.string().required().label('Rejection reason'),
        note: yup.string().required().label('Note'),
      }),
    ),
  })

  function handleOnSubmit(values) {
    onSubmit(values)

    reset()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered unmountOnClose>
      <ModalHeader toggle={toggle}>Reject Card KYC</ModalHeader>
      <ModalBody>
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          id={rejectModalFormId}
          className='tw-space-y-4'
        >
          <ControlledSelect
            control={control}
            name='rejection_reason'
            inputId='rejection_reason'
            label='Rejection reason:'
            options={[
              { label: 'Select a reason', value: '', isDisabled: true },
              ...kycRejectionReasonsOptions,
            ]}
          />

          <ControlledInput
            control={control}
            name='note'
            id='note'
            label='Note for the rejection reason:'
          />
        </form>
      </ModalBody>
      <ModalFooter>
        <Button outline color='light' onClick={toggle} disabled={loading}>
          Cancel
        </Button>
        <Button
          color='danger'
          formId={rejectModalFormId}
          type='submit'
          loading={loading}
          disabled={loading}
        >
          Reject
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const documentTypes = {
  id_card_front: 'id_card_front',
  id_card_back: 'id_card_back',
  passport_front: 'passport_front',
  proof_of_address: 'proof_of_address',
  selfie: 'selfie',
}

// const documentOptions = [
//   { label: 'ID Card back', value: documentTypes.id_card_front },
//   { label: 'ID Card front', value: documentTypes.id_card_back },
//   { label: 'Passport front', value: documentTypes.passport_front },
//   { label: 'Proof of address', value: documentTypes.proof_of_address },
//   { label: 'Selfie', value: documentTypes.selfie },
// ]

function AcceptModal({ isOpen, toggle, onSubmit, loading }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size='lg'
      centered
      unmountOnClose
      autoFocus
    >
      <ModalHeader toggle={toggle}>Accept</ModalHeader>
      <ModalBody>
        <Alert color='light' className='alert-orange py-3'>
          <h6 className='text-current'>Do you confirm that?</h6>
          <ul className='pl-4 mb-0'>
            <li>
              The ID is <strong>valid</strong> and shows the{' '}
              <strong>provided name</strong>?
            </li>
            <li>
              The <strong>PoA</strong> (Proof of Address) show the provided
              name, address and <strong>dated within the last 3 month</strong>?
            </li>
            <li>
              The person is <strong>NOT</strong> a Politically Exposed Person?
            </li>
          </ul>
        </Alert>
      </ModalBody>
      <ModalFooter>
        <Button outline color='light' onClick={toggle} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={onSubmit} loading={loading} disabled={loading}>
          Accept
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function EddFormAnswers({ data, user }) {
  const { startFetch: downloadForm, isLoading: gettingForm } = useFetch({
    action: adminDownloadEddForm,
    withAdminAccess: true,
    onComplete: (data, body) => {
      openFileV2(data, {
        download: true,
        name: `edd_form_${body?.rp_user_id}.pdf`,
      })
    },
  })

  if (!data) return null

  function handleDownload() {
    if (!user?.rp_user_id) return

    downloadForm({ rp_user_id: user?.rp_user_id })
  }

  return (
    <div className='pb-4 font-size-16'>
      <h4>EDD Form Answers</h4>

      <Button
        size='sm'
        loading={gettingForm}
        disabled={gettingForm}
        outline
        className='mb-3'
        onClick={handleDownload}
      >
        Download Answers
      </Button>

      <div className='d-flex flex-column gap-8'>
        {data.map(({ question, answers }) => {
          return (
            <div
              key={question}
              className='d-flex flex-column gap-6 border border-light rounded-lg p-2'
            >
              <div>
                <small className='rp-font-bold'>Question:</small>
                <div>{question}</div>
              </div>
              <div>
                <small className='rp-font-bold'>Answers:</small>
                <div className='d-flex flex-wrap gap-6'>
                  {answers.map((answer) => {
                    return (
                      <div key={answer} className='py-1 px-2 rounded bg-light'>
                        {answer}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
