import { XCircle } from '@phosphor-icons/react'
import React from 'react'
import Flag from '../../../../components/ui/flag'

const CountryBox = ({ country, remove }) => {
  return (
    <div className='tw-flex tw-grow-0 tw-cursor-pointer tw-items-center tw-justify-start tw-gap-2 tw-rounded-full tw-border-[1px] tw-border-surface-30 tw-bg-white tw-px-4 tw-py-2'>
      <Flag size='16px' className='tw-inline-block' url={country.svg} />

      <span className='tw-text-xs tw-font-medium tw-text-text-80'>
        {country.name}
      </span>

      <XCircle size={16} weight='fill' onClick={remove} />
    </div>
  )
}

export default CountryBox
