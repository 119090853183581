import React, { useState } from 'react'
import toastr from 'toastr'
import { DownloadSimple, Trash, UploadSimple, X } from '@phosphor-icons/react'
import { Spinner, UncontrolledTooltip } from 'reactstrap'
import { DeleteDocumentModal } from '../delete-document-modal'
import { useFetch } from '../../../../helpers/hooks'
import {
  deleteContractorDocument,
  downloadFile,
  removeRequestDoc,
} from '../../../../services/api'
import openFileV2 from '../../../../utils/file/open-v2'
import UploadModal from '../../components/UploadModal'
import { useSelector } from 'react-redux'
import { userTypes } from '../../../../helpers/enum'
import { CancelRequestModal } from '../cancel-request-modal'

export function DocumentTableActions({ rowData, refetch }) {
  const user = useSelector((state) => state?.Account?.user)
  const [deleteDocumentModal, setDeleteDocumentModal] = useState(false)
  const [cancelDocumentModal, setCancelDocumentModal] = useState(false)
  const [uploadDocumentModal, setUploadDocumentModal] = useState(false)
  const { startFetch: downloadDocument, isLoading: isDownloading } = useFetch({
    action: downloadFile,
    onComplete: (data, body) => {
      openFileV2(data, {
        name: `${body?.name}.${data?.type?.split('/')[1]}`,
        download: true,
      })
    },
  })
  const { startFetch: deleteDocument, isLoading: isDeleting } = useFetch({
    action: deleteContractorDocument,
    onComplete: () => refetch(),
    onError: (err) => toastr.error(err),
  })
  const { startFetch: deleteDocumentRequest, isLoading: isDeletingRequest } =
    useFetch({
      action: removeRequestDoc,
      onComplete: refetch,
      onError: (err) => toastr.error(err),
    })

  if (isDeleting || isDownloading || isDeletingRequest)
    return <Spinner size='sm' />

  return (
    <div className='tw-flex tw-items-center tw-justify-end tw-gap-1'>
      {rowData?.link ? (
        <>
          {user.type === userTypes.CONTRACTOR && rowData?.can_delete ? (
            <>
              <button
                onClick={() => setDeleteDocumentModal(true)}
                id='delete-button'
                className='tw-rounded tw-bg-secondary-30 tw-p-2 tw-text-red'
              >
                <Trash size={17} />
              </button>
              <UncontrolledTooltip target='delete-button'>
                Delete document
              </UncontrolledTooltip>
            </>
          ) : null}
          <button
            onClick={() => downloadDocument(rowData)}
            id='download-document'
            className='tw-rounded tw-bg-secondary-30 tw-p-2 tw-text-secondary'
          >
            <DownloadSimple size={17} />
          </button>

          <UncontrolledTooltip target='download-document'>
            Download document
          </UncontrolledTooltip>
        </>
      ) : (
        <>
          {user.type === userTypes.CONTRACTOR ? (
            <>
              <button
                id='upload-document'
                onClick={() => setUploadDocumentModal(true)}
                className='tw-rounded tw-bg-primary-30 tw-p-2 tw-text-primary'
              >
                <UploadSimple size={17} />
              </button>
              <UncontrolledTooltip target='upload-document'>
                Upload document
              </UncontrolledTooltip>
            </>
          ) : null}

          {user.type === userTypes.COMPANY && rowData?.can_delete ? (
            <>
              <button
                id='remove-request-button'
                onClick={() => setCancelDocumentModal(true)}
                className='tw-rounded tw-bg-secondary-30 tw-p-2 tw-text-secondary'
              >
                <X size={17} />
              </button>
              <UncontrolledTooltip target='remove-request-button'>
                Cancel request
              </UncontrolledTooltip>
            </>
          ) : null}
        </>
      )}

      <DeleteDocumentModal
        onDelete={() => deleteDocument({ token: rowData.token })}
        toggle={() => setDeleteDocumentModal((prev) => !prev)}
        isOpen={deleteDocumentModal}
      />

      <UploadModal
        update={refetch}
        isOpen={uploadDocumentModal}
        toggle={() => setUploadDocumentModal((prev) => !prev)}
        doc={rowData}
      />

      <CancelRequestModal
        onCancel={() => deleteDocumentRequest({ request_id: rowData?.id })}
        isOpen={cancelDocumentModal}
        toggle={() => setCancelDocumentModal((prev) => !prev)}
      />
    </div>
  )
}
