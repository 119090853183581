import { t } from 'i18next'
import React from 'react'

import { Avatar } from 'ui'
import { PrimaryAlert } from '../../components/ui/alert'
import Loader from '../../components/ui/loader'
import {
  SideMenu,
  SideMenuBody,
  SideMenuHeader,
} from '../../components/ui/side-menu'
import { useFetch } from '../../helpers/hooks'
import { getUsers } from '../../services/api'
import { getFullName } from '../../utils/get-full-name'

export function ViewUsersSlider({ toggle, isOpen, title, roleId }) {
  const { data: usersList, isLoading: usersLoading } = useFetch(
    { action: getUsers, autoFetch: isOpen },
    [isOpen, roleId],
  )

  const users =
    usersList?.list
      ?.filter((user) => user?.role?.id === roleId)
      ?.map((user) => {
        return {
          id: user.id,
          name: getFullName(user),
          email: user.email,
          photo: user.photo,
        }
      }) || []

  return (
    <SideMenu
      onClose={toggle}
      isOpen={isOpen}
      className='!tw-w-full tw-max-w-[480px]'
      itemListClassName='tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain tw-grid-rows-[auto_1fr]'
    >
      <SideMenuHeader toggle={toggle}>
        <div className='tw-flex tw-flex-col'>
          <div className='tw-font-semibold'>{title || 'Role'}</div>
          <span className='tw-text-sm tw-text-text-60'>
            {t('Assigned users')}
          </span>
        </div>
      </SideMenuHeader>
      <SideMenuBody>
        {usersLoading ? (
          <Loader className='tw-h-full' />
        ) : (
          <>
            <PrimaryAlert>
              {t(
                'You can manage the users roles from the table on the previous page.',
              )}
            </PrimaryAlert>

            <div className='tw-mt-6'>
              {users.map((user) => {
                return (
                  <div
                    key={user?.id}
                    className='tw-flex tw-items-center tw-gap-2 tw-border-b tw-border-surface-30 tw-py-4 tw-pr-4'
                  >
                    <Avatar name={user.name} photo={user.photo} size='lg' />

                    <div className='tw-text-secondary-100'>
                      <div className='tw-text-sm tw-font-semibold'>
                        {user.name}
                      </div>
                      <div className='tw-mt-1 tw-text-xs'>{user.email}</div>
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </SideMenuBody>
    </SideMenu>
  )
}
