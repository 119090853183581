import React from 'react'

import Flag, { getFlagUrlFromIso2 } from '../components/ui/flag'
import { FlagIcon } from '../components/ui/flag-icon'

export function getCurrencyLabel({ code, name, symbol }) {
  const label = [code, name ? `- ${name}` : null, symbol ? `(${symbol})` : null]
    .filter(Boolean)
    .join(' ')

  return label
}

export function mapCurrencyToOption(
  currency = {},
  key = 'code',
  { stringifyValues = false } = {},
) {
  const { code } = currency

  const label = getCurrencyLabel(currency)

  return {
    ...currency,
    label,
    value: stringifyValues ? currency[key]?.toString() : currency[key],
    icon: !code ? null : (
      <FlagIcon code={code} className='flex-shrink-0 tw-me-2' />
    ),
  }
}

export function getOptionFromList(list, optionValue, valueKey = 'value') {
  if (!Array.isArray(list)) {
    return {}
  }

  return list.find((item) => optionValue === item[valueKey])
}

export function mapCountryToOption(
  country = {},
  key = 'id',
  { stringifyValues = false } = {},
) {
  return {
    ...country,
    label: country.name,
    value: stringifyValues ? country[key]?.toString() : country[key],
    icon: country.svg ? (
      <Flag
        url={country.svg ?? getFlagUrlFromIso2(country.iso2)}
        size='1rem'
        style={{ marginInlineEnd: 10, flexShrink: 0 }}
      />
    ) : null,
  }
}

/**
 * A generic map function to convert a list item to an option
 */
export function mapListToOption(
  item,
  { stringifyValues = false, valueKey = 'id' } = {},
) {
  return {
    value: stringifyValues ? item?.[valueKey]?.toString() : item?.[valueKey],
    label: item?.name,
  }
}
