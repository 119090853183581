import axios from 'axios'
import { t } from 'i18next'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Card, Row, TabPane } from 'reactstrap'
import toastr from 'toastr'

import Head from '../../../../components/head'
import NavTabsV2 from '../../../../components/ui/nav-tabs/nav-tabs-v2'
import TabContent from '../../../../components/ui/tabs'
import { useFetch } from '../../../../helpers/hooks'
import {
  getSaplingEditData,
  saveSaplingContractors,
  updateDataToSapling,
} from '../../../../services/api'
import {
  SaplingRequest,
  SaplingUpdateContractors,
} from '../../../../store/sapling/actions'
import { HrIntegrationSettings } from '../HibobIntegration/Tab1'
import Tab4 from '../HibobIntegration/Tab4'
import TabX from '../HibobIntegration/TabX'
import { IntegrationReviewHeader } from '../integration-review-header'
import { LoadingTab } from '../UpdateHibobIntegration'

const UpdateSaplingIntegration = () => {
  const [activeTab, setActiveTab] = useState(0)
  const tab1Ref = useRef()

  const dispatch = useDispatch()
  const saplingRequest = useSelector((state) => state.sapling.saplingRequest)
  const history = useHistory()

  useEffect(() => {
    if (!saplingRequest?.session_id) {
      history.goBack()
    }
  }, [])

  const UpdateData = useFetch({
    action: updateDataToSapling,
    onComplete: (data) => {
      if (data?.user_id) {
        toastr.success(t('Settings updated successfully.'))
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const callGetSaplingContractors = useFetch({
    action: getSaplingEditData,
    onComplete: (data) => {
      if (data?.settings) {
        const newContractType = saplingRequest?.contract_types?.map((type) =>
          data?.settings?.settings?.contract_types.includes(type.name)
            ? type
            : {
                ...type,
                status: false,
              },
        )
        const newFields = saplingRequest?.fields?.map((type) =>
          data?.settings?.settings?.fields.includes(type.name)
            ? {
                ...type,
                status: true,
              }
            : type,
        )
        dispatch(
          SaplingRequest({
            ...saplingRequest,
            ...data?.settings?.auth,
            contract_types: newContractType,
            fields: newFields,
          }),
        )
      }
      if (data?.contractors) {
        dispatch(SaplingUpdateContractors(data?.contractors))
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const refresh = useCallback(
    (cancel) => {
      if (cancel) cancel()
      if (callGetSaplingContractors.data?.contractors_queued > 0) {
        callGetSaplingContractors.startFetch(
          null,
          false,
          new axios.CancelToken((c) => (cancel = c)),
        )
      }
    },
    [callGetSaplingContractors.data],
  )

  useEffect(() => {
    if (activeTab === 1) {
      let cancel
      const interval = setInterval(() => refresh(cancel), 800)
      return () => {
        clearInterval(interval)
      }
    }
  }, [callGetSaplingContractors.data, activeTab])

  const saveContractors = useFetch({
    action: saveSaplingContractors,
    onComplete: () => {
      toastr.success(t('Settings updated successfully.'))
      callGetSaplingContractors.startFetch()
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  useEffect(() => {
    callGetSaplingContractors.startFetch()
  }, [])

  const tabs = [
    {
      label: t('Settings'),
      component: (
        <Card className='p-3'>
          {(saveContractors.isLoading ||
            callGetSaplingContractors.isLoading) && <LoadingTab />}
          <HrIntegrationSettings
            platform='sapling'
            mode='edit'
            ref={tab1Ref}
            onComplete={(data) => {
              dispatch(SaplingRequest({ ...saplingRequest, ...data }))
              const contractTypes = saplingRequest?.contract_types
                ?.filter((type) => type.status === true)
                .map((type) => type.name)
              const fields = saplingRequest?.fields
                ?.filter((type) => type.status === true)
                .map((type) => type.name)
              UpdateData.startFetch({
                ...saplingRequest,
                ...data,
                contract_types: contractTypes,
                fields,
              })
            }}
          />
          <TabX
            platform='sapling'
            mode='edit'
            context='tab3'
            onComplete={(data) => {
              dispatch(SaplingRequest({ ...saplingRequest, fields: data }))
            }}
          />
          <TabX
            platform='sapling'
            mode='edit'
            context='tab2'
            onComplete={(data) => {
              dispatch(
                SaplingRequest({ ...saplingRequest, contract_types: data }),
              )
            }}
          />
          <Row className='justify-content-end p-0 m-0 mt-4'>
            <button
              onClick={() => {
                tab1Ref.current.submit()
              }}
              className='btn btn-primary'
            >
              {UpdateData.isLoading && <i className='bx bx-loader bx-spin' />}{' '}
              Update
            </button>
          </Row>
        </Card>
      ),
    },
    {
      label: t('Team'),
      component: (
        <Card className='p-3'>
          {(saveContractors.isLoading ||
            callGetSaplingContractors.isLoading) && <LoadingTab />}
          <Tab4
            platform='sapling'
            mode='edit'
            createContractor={(ContractorsToSubmit) => {
              saveContractors.startFetch({
                contractors: [ContractorsToSubmit[0]?.contractor_id],
              })
            }}
          />
        </Card>
      ),
    },
  ]
  return (
    <div className='page-content'>
      <Head title='Sapling HR | Integration Review' />

      <IntegrationReviewHeader integrationName='Sapling HR' />

      <NavTabsV2
        options={tabs.map((tab, index) => ({ ...tab, value: index }))}
        activeOption={activeTab}
        onClickOption={(tab) => {
          setActiveTab(tab.value)
        }}
        className='tw-mt-4 !tw-border-b-transparent'
        itemLinkClassName='!tw-py-3 md:!tw-py-3'
      />
      <TabContent activeTab={activeTab} className='mt-3'>
        {tabs.map((t, i) => (
          <TabPane key={`tab:${i}`} tabId={i}>
            {t.component}
          </TabPane>
        ))}
      </TabContent>
    </div>
  )
}

export default UpdateSaplingIntegration
