import React from 'react'
import Shimmer from '../../../components/ui/shimmer'
function CalendarLoading() {
  return (
    <div className='tw-bg-white tw-p-4'>
      <div className='tw-grid tw-grid-cols-7 tw-gap-2 tw-overflow-hidden'>
        {[...Array(7)].map((_, index) => (
          <Shimmer key={index} className='!tw-h-16 !tw-w-full tw-rounded-lg' />
        ))}
      </div>

      <div className='tw-mt-4 tw-grid tw-grid-cols-7 tw-gap-2 tw-overflow-hidden'>
        {[...Array(35)].map((_, index) => (
          <Shimmer
            key={index}
            className='tw-aspect-square !tw-h-full !tw-w-full tw-rounded-lg'
          />
        ))}
      </div>
    </div>
  )
}

export default CalendarLoading
