'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MagnifyingGlass } from '@phosphor-icons/react';
import { useSearchField } from '@react-aria/searchfield';
import { useRef, useState } from 'react';
import { Label } from 'react-aria-components';
import { useSearchFieldState } from 'react-stately';
import { cn } from '../lib/utils/cn';
export function Search(props) {
    const { className, onQueryChanged, wrapperClassName } = props;
    const ref = useRef(null);
    const [timeoutId, setTimeoutId] = useState();
    function handleInputChange(newValue) {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        const newTimeoutId = setTimeout(() => {
            onQueryChanged(newValue);
        }, 500);
        setTimeoutId(newTimeoutId);
    }
    let state = useSearchFieldState(Object.assign(Object.assign({}, props), { onChange: handleInputChange }));
    let { inputProps } = useSearchField(props, state, ref);
    return (_jsxs("div", { className: cn('tw-flex tw-flex-col', wrapperClassName), children: [props.label && (_jsx(Label, { className: cn('tw-mb-2'), htmlFor: props === null || props === void 0 ? void 0 : props.id, children: props.label })), _jsxs("div", { className: cn('tw-relative', className), children: [_jsx(MagnifyingGlass, { className: 'tw-pointer-events-none tw-absolute tw-top-1/2 tw-mx-2 -tw-translate-y-1/2 tw-text-text-80', "aria-hidden": 'true', weight: 'bold' }), _jsx("input", Object.assign({ className: 'tw-min-h-11 tw-w-full tw-flex-1 tw-rounded tw-border tw-border-surface-130 tw-py-3 tw-pe-2 tw-ps-7 tw-text-xs tw-outline-none tw-transition-colors tw-duration-100 focus:tw-border-primary-100 focus-visible:tw-ring-2 focus-visible:tw-ring-primary-30', ref: ref }, inputProps))] })] }));
}
