import { Node, mergeAttributes } from '@tiptap/core'

export const PlaceholderExtension = Node.create({
  name: 'placeholder',
  group: 'inline',
  inline: true,
  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },
      label: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-placeholder]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(
        { 'data-placeholder': true, class: 'tw-text-primary tw-font-bold' },
        HTMLAttributes,
      ),
      `{${HTMLAttributes.label}}`,
    ]
  },

  addCommands() {
    return {
      insertPlaceholder:
        (id, label) =>
        ({ chain }) => {
          return chain()
            .insertContent({
              type: this.name,
              attrs: { id, label },
            })
            .run()
        },
    }
  },
})
