import {
  addDays,
  addMonths,
  differenceInDays,
  endOfMonth,
  format,
  getDate,
  getDaysInMonth,
  isPast,
  isSameDay,
  isToday,
  isValid,
  isWithinInterval,
  startOfMonth,
  subMonths,
} from 'date-fns'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import { getTimeOffName } from '../Activity'
import { contractTypeFilters, defaultFilters } from './filters/filtersTypes'
import { CALENDAR_VIEWS } from '.'

export const weekDays = [
  { text: 'Sun', index: 0 },
  { text: 'Mon', index: 1 },
  { text: 'Tue', index: 2 },
  { text: 'Wed', index: 3 },
  { text: 'Thu', index: 4 },
  { text: 'Fri', index: 5 },
  { text: 'Sat', index: 6 },
]

export const timeOffEventColorClassNames = {
  'Public Holiday':
    'tw-text-secondary-110 tw-bg-secondary-30 hover:tw-bg-secondary-40',
  Vacation:
    'tw-text-systemGold-120 tw-bg-systemGold-40 hover:tw-bg-systemGold-50',
  'Sick leave':
    'tw-text-systemRed-110 tw-bg-systemRed-20 hover:tw-bg-systemRed-30',
  'Parental leave': 'tw-text-green-120 tw-bg-green-20 hover:tw-bg-green-30',
  Religious:
    'tw-text-systemBlue-120 tw-bg-systemBlue-20 hover:tw-bg-systemBlue-30',
  Bereavement: 'tw-text-cyan-120 tw-bg-cyan-30 hover:tw-bg-cyan-40',
  'Default Time Off':
    'tw-text-primary-120 tw-bg-primary-30 hover:tw-bg-primary-40',
  Other: 'tw-text-purple-110 tw-bg-purple-20 hover:tw-bg-purple-30',
}

export function getEventColorsClasses(event) {
  switch (event.type) {
    case 'birthday':
      return 'tw-text-systemGreen-130 tw-bg-systemGreen-30 hover:tw-bg-systemGreen-40'
    case 'time_off':
      return timeOffEventColorClassNames[event.metadata?.timeOffType]
    case 'work_anniversary':
      return 'tw-text-violet-110 tw-bg-violet-10 hover:tw-bg-violet-20'
    case 'new_joiner':
      return 'tw-text-orange-110 tw-bg-orange-10 hover:tw-bg-orange-20'
    case 'company_national_holiday':
    case 'worker_national_holiday':
      return 'tw-text-black tw-bg-surface-30 hover:tw-bg-surface-40'
    case 'paydays':
      return 'tw-text-primary-100 tw-bg-primary-20 hover:tw-bg-primary-30'
    case 'time_off_request':
      return 'tw-text-primary-100 tw-border tw-border-primary tw-bg-white hover:tw-bg-primary hover:tw-text-white hover:tw-border-primary'
    default:
      return 'tw-bg-surface-70 tw-text-secondary hover:tw-bg-surface-80'
  }
}

export function getEventTooltipText(event) {
  const eventDateText = format(new Date(event.date), 'dd/MM/yyyy')
  switch (event.type) {
    case 'birthday':
      return {
        title: 'Birthday',
        text: eventDateText,
      }
    case 'company_national_holiday':
    case 'worker_national_holiday':
      return {
        title: event.metadata?.title,
        text: event.metadata?.location,
        extra: event.worker?.name,
      }
    case 'time_off':
    case 'time_off_request': {
      const isRequest = event.type === 'time_off_request'
      const fromDate = format(new Date(event.startDate), 'dd/MM/yyyy')
      const toDate = format(new Date(event.endDate), 'dd/MM/yyyy')
      const timeOffLength = Number(event.metadata?.days)
      const isOneDay = fromDate === toDate
      return {
        title: `${isRequest ? 'Time off request' : getTimeOffName(event.metadata?.timeOffType)} (${timeOffLength} Day${!isOneDay ? 's' : ''})`,
        text: isOneDay ? fromDate : `From ${fromDate} to ${toDate}`,
      }
    }
    case 'work_anniversary':
      return {
        title: `${event.metadata.ordinalYear} year work anniversary`,
        text: eventDateText,
      }
    case 'new_joiner':
      return {
        title: isPast(new Date(event.date))
          ? `Joined on ${eventDateText}`
          : `Joining on ${eventDateText}`,
        text: eventDateText,
      }
    default:
      return {
        title: `Event`,
        text: format(new Date(event.date), 'dd/MM/yyyy'),
      }
  }
}

export const generateCalendarSlots = (year, month) => {
  // Get the first day of the current month
  const firstDayOfMonth = startOfMonth(new Date(year, month))
  const firstDayOfWeek = firstDayOfMonth.getDay() // 0 = Sunday, 1 = Monday, ...

  // Get the number of days in the current, previous, and next months
  const daysInCurrentMonth = getDaysInMonth(new Date(year, month))
  const prevMonth = subMonths(new Date(year, month), 1)
  const nextMonth = addMonths(new Date(year, month), 1)
  const daysInPrevMonth = getDaysInMonth(prevMonth)

  // Fill days from the previous month
  const slots = []
  for (let i = firstDayOfWeek - 1; i >= 0; i--) {
    const prevDate = daysInPrevMonth - i
    slots.push({
      day: prevDate,
      type: 'prev',
      month: prevMonth.getMonth(),
      year: prevMonth.getFullYear(),
    })
  }

  // Fill days of the current month
  for (let i = 1; i <= daysInCurrentMonth; i++) {
    slots.push({ day: i, type: 'current', month, year })
  }

  // Fill days from the next month
  const totalSlots = 35
  for (let i = 1; slots.length < totalSlots; i++) {
    slots.push({
      day: i,
      type: 'next',
      month: nextMonth.getMonth(),
      year: nextMonth.getFullYear(),
    })
  }

  return slots.map((slot, index) => ({
    ...slot,
    index,
    label: slot.day,
  }))
}

export const mergeSlotsWithEvents = (slots, events) => {
  // Flatten the events into a single array

  let rank = 0
  const rankPlacements = {}

  const finalSlots = slots.map((slot) => {
    const slotDate = new Date(slot.year, slot.month, slot.day)

    // sort events by length
    events = events.sort((a, b) => {
      const aLen = differenceInDays(new Date(a.endDate), new Date(a.startDate))
      const bLen = differenceInDays(new Date(b.endDate), new Date(b.startDate))
      return bLen - aLen
    })

    // Filter events matching the slot date
    let matchingEvents = events
      .map((event) => {
        const eventStartDate = new Date(event.startDate)
        const eventEndDate = new Date(event.endDate)
        // Skip invalid dates
        if (!isValid(eventStartDate) || !isValid(eventEndDate)) return null

        // Check if the slot date falls within the event's date range
        if (
          isWithinInterval(slotDate, {
            start: eventStartDate,
            end: eventEndDate,
          }) ||
          isSameDay(slotDate, eventStartDate) ||
          isSameDay(slotDate, eventEndDate)
        ) {
          if (event.rank === undefined) {
            event.rank = rank++
          }
          const isStartDay = isSameDay(slotDate, eventStartDate)
          const isEndDay = isSameDay(slotDate, eventEndDate)
          return {
            ...event,
            eventLength: differenceInDays(eventEndDate, eventStartDate) + 1,
            isStartOfEvent: isStartDay,
            isEndOfEvent: isEndDay,
          }
        }

        return null
      })
      .filter(Boolean)

    // Reorder ranks within the slot
    matchingEvents = matchingEvents
      .sort((a, b) => a.rank - b.rank) // Sort by rank
      .map((event, index) => {
        let placement
        if (event.rank in rankPlacements) {
          placement = rankPlacements[event.rank]
        } else {
          // check the first two events to see if they have the same placement change the placement of the one with rank not assigned to a placement yet
          const hasConflict = matchingEvents.some(
            (ev) => rankPlacements[ev.rank] === index + 1,
          )
          if (hasConflict) {
            let placementCounter = 1
            while (
              matchingEvents.some(
                (ev) => rankPlacements[ev.rank] === placementCounter,
              )
            ) {
              placementCounter++
            }
            placement = placementCounter
            rankPlacements[event.rank] = placement
          } else {
            placement = index + 1
            rankPlacements[event.rank] = placement
          }
        }
        return {
          ...event,
          placement,
        }
      })

    // Attach matching events to the slot
    return { ...slot, events: matchingEvents }
  })

  return finalSlots
}

export const isWeekend = (day) => {
  return day === 0 || day === 6
}

export const getRemainingLength = (event, slot) => {
  let remainingLength = 0
  if (!event.isStartOfEvent && slot) {
    remainingLength =
      (Math.abs(
        differenceInDays(
          new Date(event.endDate),
          new Date(slot.year, slot.month, slot.day),
        ),
      ) +
        1) *
        100 +
      8
  } else {
    remainingLength = event.eventLength * 100
  }
  // if remaining length is greater than the remaining slots in the week * 100 then subtract the difference
  if (slot && remainingLength > (7 - (slot.index % 7)) * 100) {
    remainingLength = (7 - (slot.index % 7)) * 100 + 100
  }
  return remainingLength
}
export const getTimelineRemainingLength = (event, startDate) => {
  const start =
    new Date(event.startDate) < startDate
      ? startDate
      : new Date(event.startDate)
  return (
    (differenceInCalendarDays(new Date(event.endDate), start) + 1 || 1) * 100
  )
}

export const getDurationInDays = ({ date, duration }) => {
  const monthLength = getDaysInMonth(new Date(date))
  return duration.value === 'w' ? 7 : duration.value === 'm' ? monthLength : 14
}

export const generateTimeLineColumns = ({ startDate, endDate }) => {
  const columns = []
  let currentDate = new Date(startDate)
  while (currentDate <= endDate) {
    const currentDay = getDate(currentDate)
    columns.push({
      date: new Date(currentDate),
      day: currentDay,
      isWeekend: isWeekend(currentDate.getDay()),
      isToday: isToday(currentDate),
      weekDay: weekDays[currentDate.getDay()].text,
      accessor: format(currentDate, 'yyyy-MM-dd'),
    })
    currentDate = addDays(currentDate, 1)
  }
  return { columns, startDate, endDate }
}

export const generateTimeLineData = ({ data }) => {
  const timelineData = data.map((user) => {
    const transformedEvents = user.events.reduce((acc, event) => {
      const startDate = event.startDate
      const endDate = event.endDate
      let currentDate = new Date(startDate)
      while (currentDate <= new Date(endDate)) {
        const newEvent = { ...event, name: user.name }
        const currentKey = format(currentDate, 'yyyy-MM-dd')
        newEvent.cellDate = currentKey
        newEvent.isStartOfEvent = currentKey === startDate
        newEvent.isEndOfEvent = currentKey === endDate

        if (acc[currentKey]) {
          acc[currentKey].push(newEvent)
        } else {
          acc[currentKey] = [newEvent]
        }
        currentDate = addDays(currentDate, 1)
      }
      return acc
    }, {})
    return {
      ...user,
      events: undefined,
      ...transformedEvents,
    }
  })

  return timelineData
}

export const groupCountriesByAlphabet = (countries) => {
  const groupedCountries = {}
  countries?.forEach((country) => {
    const found = groupedCountries[country.name.charAt(0)]
    if (found) {
      groupedCountries[country.name.charAt(0)] = [...found, country]
    } else {
      groupedCountries[country.name.charAt(0)] = [country]
    }
  })
  const formattedGroupedCountries = Object.keys(groupedCountries).map(
    (letter) => {
      return { letter, countries: groupedCountries[letter] }
    },
  )

  return formattedGroupedCountries
}

export const returnTodaysEvents = (workersEvents) => {
  const today = format(new Date(), 'yyy-MM-dd')
  return workersEvents
    ?.map((wev) => {
      return {
        ...wev,
        events: wev?.events.filter(
          (obj) => obj.startDate === today && obj.endDate === today,
        ),
      }
    })
    .filter((wev) => wev.events.length > 0)
}

// return only events with type `birthday` or `work_anniversary` from todays events
// used for the celebration popup
export const returnTodaysCelebrationEvents = (todaysEvents) => {
  return todaysEvents
    ?.map((tev) => {
      const celebrationEvents = []
      const filteredEvents = tev.events.reduce((acc, ev) => {
        if (ev.type === 'birthday') {
          celebrationEvents.push('Birthday')
          acc.push(ev)
        } else if (ev.type === 'work_anniversary') {
          celebrationEvents.push(
            `${ev.metadata?.ordinalYear} year work anniversary`,
          )
          acc.push(ev)
        }

        return acc
      }, [])

      return celebrationEvents.length > 0
        ? { ...tev, events: filteredEvents.flat(), celebrationEvents }
        : undefined
    })
    .filter((ob) => ob)
}

export const getInitialFilters = (initialFilters = {}, contractStatuses) => {
  const filters = {
    event_types:
      initialFilters.event_types ?? defaultFilters.map((f) => f.value),
    contract_types:
      initialFilters.contract_types ??
      contractTypeFilters.filters.map((f) => f.value),
    contract_statuses:
      initialFilters.contract_statuses ??
      contractStatuses
        ?.filter((st) => st.label !== 'Ended')
        .map((f) => f.value),
    contract_tags: initialFilters.contract_tags ?? [],
    contract_countries: initialFilters.contract_countries ?? [],
  }
  return filters
}

export const getFromToDates = ({ date, duration, view }) => {
  let startDate = new Date(date)
  let endDate = new Date(date)
  if (view === CALENDAR_VIEWS.CALENDAR) {
    startDate = startOfMonth(date)
    endDate = endOfMonth(date)
  } else {
    const durationInDays = getDurationInDays({ date, duration })
    endDate = addDays(startDate, durationInDays - 1)
  }
  return {
    from: format(startDate, 'yyyy-MM-dd'),
    to: format(endDate, 'yyyy-MM-dd'),
  }
}

export const getInsightEndDate = (insight) => {
  const duration =
    insight.insightLength <= 7
      ? 7
      : insight.insightLength > 7 && insight.insightLength <= 14
        ? 14
        : 30
  return addDays(new Date(insight.startDate), duration - 1)
}
