import { t } from 'i18next'
import {
  Gear,
  IdentificationBadge,
  Megaphone,
  Money,
  UsersThree,
} from '@phosphor-icons/react'
import cx from 'classnames'
import React, { useState } from 'react'

import NavTabsV2 from '../../components/ui/nav-tabs/nav-tabs-v2'
import { useFetch, usePermissions } from '../../helpers/hooks'
import { useUrlState } from '../../helpers/hooks/use-url-state'
import { getRoles } from '../../services/api'
import { LoadingItems } from '../Contract/ContractPage/payment-approvals'
import CompanyUsers from './Users'
import AddUserModal from './add-user-modal'
import {
  SettingSectionHeading,
  SettingSectionSubHeading,
} from './components/settings-section-heading'
import { AddRole, ManageRoleActions } from './manage-role'
import permissions from '../../helpers/permissions'

export function UsersRoles() {
  const {
    data: rolesData,
    isLoading: loadingRolesData,
    startFetch: refetchRoles,
  } = useFetch({
    action: getRoles,
    autoFetch: true,
  })

  const { hasAccess } = usePermissions()
  const canManageRoles = hasAccess(permissions.ManageCompanyUserAndRole)

  return (
    <div className='tw-p-6'>
      <Roles
        rolesData={rolesData}
        loadingRolesData={loadingRolesData}
        refetchRoles={refetchRoles}
        canManageRoles={canManageRoles}
      />

      <hr className='-tw-mx-6 tw-my-6' />

      <Users refetchRoles={refetchRoles} />
    </div>
  )
}

const ROLE_COLOR = {
  primary: {
    border: 'tw-border-l-2 tw-border-l-primary-100',
    icon: 'tw-text-primary-100 tw-bg-primary-10',
  },
  yellow: {
    border: 'tw-border-l-2 tw-border-l-yellow-100',
    icon: 'tw-text-yellow-100 tw-bg-yellow-10',
  },
  green: {
    border: 'tw-border-l-2 tw-border-l-green-100',
    icon: 'tw-text-green-100 tw-bg-green-10',
  },
  red: {
    border: 'tw-border-l-2 tw-border-l-systemRed-100',
    icon: 'tw-text-systemRed-100 tw-bg-systemRed-10',
  },
  cyan: {
    border: 'tw-border-l-2 tw-border-l-cyan-100',
    icon: 'tw-text-cyan-100 tw-bg-cyan-10',
  },
}
const roleColors = [
  ROLE_COLOR.red,
  ROLE_COLOR.cyan,
  ROLE_COLOR.green,
  ROLE_COLOR.yellow,
]

function Roles({ rolesData, loadingRolesData, refetchRoles, canManageRoles }) {
  const formattedRoles = rolesData?.map((role, index) => {
    // 4 is the number of default roles
    const isDefaultRole = index < 4

    return {
      id: role.id,
      name: role.name,
      description: role.description,
      assigned_users: role.assigned_users,
      usersCount: t('AssignedUsers', { count: role.assigned_users }),
      info: '',
      groups: role.groups,
      color: isDefaultRole ? roleColors[index] : ROLE_COLOR.primary,
      isEditable: role.is_editable,
      is_assigned_contracts_enabled: String(role.is_assigned_contracts_enabled),
      parent_id: role.parent_id,
    }
  })

  return (
    <>
      <div className='tw-mb-4 tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-3 md:tw-flex-nowrap'>
        <div>
          <SettingSectionHeading
            learnMoreLink='https://help.remotepass.com/en/articles/5123137-what-are-the-different-roles-types'
            learnMoreTitle='Learn more about role types'
          >
            {t('Roles')}
          </SettingSectionHeading>

          <SettingSectionSubHeading className='tw-mb-0'>
            {t('Manage roles and access permissions')}
          </SettingSectionSubHeading>
        </div>

        <AddRole
          hasPermission={canManageRoles}
          onUpdated={() => refetchRoles()}
        />
      </div>

      <div className='tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 lg:tw-grid-cols-4'>
        {loadingRolesData ? (
          <LoadingItems
            length={4}
            noParent
            itemHeight='160px'
            item={({ className, style }) => {
              return (
                <div
                  className={cx(
                    'tw-flex tw-animate-pulse tw-flex-col tw-justify-center tw-gap-3 tw-rounded tw-border tw-border-surface-30 tw-p-4',
                    className,
                  )}
                  style={style}
                >
                  <div className='tw-h-10 tw-w-10 tw-rounded tw-bg-surface-30' />
                  <div>
                    <div className='tw-h-3 tw-w-48 tw-rounded tw-bg-surface-30' />
                    <div className='tw-mt-2 tw-h-3 tw-w-32 tw-rounded tw-bg-surface-30' />
                    <div className='tw-mt-2 tw-h-3 tw-w-20 tw-rounded tw-bg-surface-30' />
                  </div>
                </div>
              )
            }}
          />
        ) : (
          formattedRoles?.map((role, index) => {
            return (
              <div
                className={cx(
                  'tw-flex tw-flex-col tw-rounded tw-border tw-border-solid tw-border-surface-30 tw-p-3',
                  role.color.border,
                )}
                key={index}
              >
                <div className='tw-flex tw-items-center tw-justify-between tw-gap-3'>
                  <div
                    className={cx(
                      'tw-flex tw-h-10 tw-w-10 tw-shrink-0 tw-items-center tw-justify-center tw-rounded',
                      role.color.icon,
                    )}
                  >
                    <RoleIcon size={24} roleId={role.id} />
                  </div>

                  {!canManageRoles ? null : (
                    <ManageRoleActions
                      role={role}
                      canManageRoles={canManageRoles}
                      onUpdated={() => refetchRoles()}
                    />
                  )}
                </div>

                <div className='tw-mt-4 tw-flex tw-grow tw-flex-col tw-text-start'>
                  {!role.name ? null : (
                    <h4
                      className='tw-mb-0.5 tw-text-sm tw-font-bold'
                      style={{ overflowWrap: 'anywhere' }}
                    >
                      {role.name}
                    </h4>
                  )}

                  <p className='tw-mb-2 tw-break-words tw-text-xs tw-text-text-80'>
                    {role.description}
                  </p>

                  <p className='tw-mb-0 tw-mt-auto tw-text-xs tw-text-text-80'>
                    {role.usersCount}
                  </p>
                </div>
              </div>
            )
          })
        )}
      </div>
    </>
  )
}

function Users({ refetchRoles }) {
  const [activeTab, setActiveTab] = useUrlState({
    name: 'tab',
    defaultValue: 'active',
  })

  const [userKey, setUserKey] = useState(0)

  return (
    <>
      <div className='tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-3 md:tw-flex-nowrap'>
        <div>
          <SettingSectionHeading
            learnMoreLink='https://help.remotepass.com/en/articles/5123137-what-are-the-different-available-user-role-types'
            learnMoreTitle='Learn more about user role types'
          >
            {t('Users')}
          </SettingSectionHeading>
          <SettingSectionSubHeading className='tw-mb-0'>
            {t('Manage users and assign roles')}
          </SettingSectionSubHeading>
        </div>

        <AddUserModal onComplete={() => setUserKey((prev) => prev + 1)} />
      </div>

      <NavTabsV2
        options={[
          { label: t('Active users'), value: 'active' },
          { label: t('Archived users'), value: 'archived' },
        ]}
        activeOption={activeTab}
        className='!tw-mb-6'
        onClickOption={(option) => setActiveTab(option?.value)}
      />

      <CompanyUsers
        archived={activeTab === 'archived'}
        key={userKey}
        refetchRoles={refetchRoles}
      />
    </>
  )
}

function RoleIcon({ roleId, size = 24 }) {
  switch (roleId) {
    // Admin
    case 1: {
      return <Gear size={size} />
    }
    // Accountant
    case 2: {
      return <Money size={size} />
    }
    // HR
    case 3: {
      return <Megaphone size={size} />
    }
    // Manager
    case 4: {
      return <UsersThree size={size} />
    }
    // Custom roles
    default: {
      return <IdentificationBadge size={size} />
    }
  }
}
