import { t } from 'i18next'
import { CaretDown } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Table } from 'reactstrap'
import toastr from 'toastr'
import { Avatar } from 'ui'

import Toggle from '../../components/Forms/Toggle/Toggle'
import RolesModal from '../../components/RolesModal'
import Loader from '../../components/ui/loader'
import { useFetch, usePermissions, useResize } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import {
  changeUserRole,
  getUsers,
  updateUserPermissions,
} from '../../services/api'
import { getFullName } from '../../utils/get-full-name'
import {
  ArchiveToggleUser,
  EditStatus,
  USER_ARCHIVE_STATUS,
} from './user-tab-actions'
import { PermissionTooltip } from '../../components/permission-tooltip'
import { PERMISSION_GROUP } from './manage-role'

function UserCard({ myUser, index, handleCanSign, archived, updateUsersList }) {
  return (
    <div className='mb-3 rounded border'>
      <div className='border-bottom p-3'>
        <CompanyUser myUser={myUser} archived={archived} />
      </div>

      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='text-dark font-size-14 mb-0'>{t('Role')}</p>
        {archived ? (
          myUser?.role?.name
        ) : (
          <EditUserRole user={myUser} onUpdate={updateUsersList} />
        )}
      </div>

      {archived ? null : (
        <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
          <p className='text-dark font-size-14 mb-0'>{t('Can Sign')}</p>
          <Toggle
            id={`SwitchCanSign${index}`}
            check={myUser?.can_sign}
            change={() => {
              handleCanSign(myUser?.id, myUser?.can_sign)
            }}
            marginRight=''
          />
        </div>
      )}

      {archived ? null : (
        <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
          <p className='text-dark font-size-14 mb-0'>{t('Status')}</p>
          <EditStatus user={myUser} onUpdate={updateUsersList} />
        </div>
      )}

      <div className='d-flex justify-content-between align-items-center px-3 pb-3'>
        <ArchiveToggleUser user={myUser} onUpdate={updateUsersList} />
      </div>
    </div>
  )
}

export function CompanyUsers({ archived, refetchRoles }) {
  const isMobile = useResize()
  const user = useSelector((state) => state?.Account?.user)
  const { hasAccess } = usePermissions()

  const {
    data: users,
    isLoading: usersLoading,
    startFetch: updateUsers,
  } = useFetch(
    {
      action: getUsers,
      autoFetch: true,
      body: archived ? { status: USER_ARCHIVE_STATUS.archived } : null,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(
            data?.message || 'Something went wrong while fetching users',
          )
        } else {
          window.analytics.track('Viewed Users', { 'email-id': user?.email })
        }
      },
    },
    [archived],
  )

  function updateUsersList(isLoading = true) {
    const body = archived ? { status: USER_ARCHIVE_STATUS.archived } : null

    updateUsers(body, isLoading)
    refetchRoles?.()
  }

  const changeCanSign = useFetch({
    action: updateUserPermissions,
    onComplete: () => updateUsersList(false),
  })

  const handleCanSign = (id, status) => {
    changeCanSign.startFetch({
      user_id: id,
      can_sign: status ? 0 : 1,
    })
  }

  return (
    <>
      {usersLoading ? (
        <Loader minHeight='50vh' />
      ) : users?.list?.length <= 0 ? (
        <div
          className='tw-flex tw-flex-col tw-items-center tw-justify-center'
          style={{ minHeight: '50vh' }}
        >
          <h4 className='tw-mt-4'>{t('No users found')}</h4>
          <p className='tw-mb-0 tw-text-secondary-90'>
            {archived
              ? t('Archived users will appear here')
              : t('Add users to your company')}
          </p>
        </div>
      ) : isMobile ? (
        <Col className='px-0 pt-0 p-md-3' style={{ minHeight: '60vh' }}>
          {users?.list?.map((myUser, key) => (
            <UserCard
              archived={archived}
              handleCanSign={handleCanSign}
              index={key}
              key={'_order_' + myUser?.id}
              myUser={myUser}
              updateUsersList={updateUsersList}
            />
          ))}
        </Col>
      ) : (
        <div className='table-responsive'>
          <Table className='table table-centered table-nowrap'>
            <thead className='thead-light'>
              <tr
                className='rp-border font-size-14 !tw-text-start'
                style={{ borderTopStyle: 'hidden', borderWidth: '0 0 1px 0' }}
              >
                <th>{t('Name')}</th>

                {archived ? (
                  <th>{t('Email')}</th>
                ) : (
                  <>
                    <th>{t('Role')}</th>
                    <th>{t('Can Sign')}</th>
                    <th>{t('Status')}</th>
                  </>
                )}
                <th>{t('Action')}</th>
              </tr>
            </thead>
            <tbody className='!tw-text-start'>
              {users?.list?.map((myUser, key) => {
                return (
                  <tr key={'_order_' + myUser?.id}>
                    <td>
                      <CompanyUser myUser={myUser} archived={archived} />
                    </td>

                    {archived ? (
                      <td>{myUser.email}</td>
                    ) : (
                      <>
                        <td>
                          <EditUserRole
                            user={myUser}
                            onUpdate={updateUsersList}
                          />
                        </td>

                        <td>
                          <PermissionTooltip
                            showing={
                              !hasAccess(permissions.ManageCompanyUserAndRole)
                            }
                            area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
                            placement='left'
                            id='can-sign-tooltip'
                          >
                            <Toggle
                              id={`SwitchCanSign${key}`}
                              check={myUser?.can_sign}
                              change={() => {
                                handleCanSign(myUser?.id, myUser?.can_sign)
                              }}
                              disabled={
                                !hasAccess(permissions.ManageCompanyUserAndRole)
                              }
                              marginRight={null}
                            />
                          </PermissionTooltip>
                        </td>

                        <td>
                          <PermissionTooltip
                            showing={
                              !hasAccess(permissions.ManageCompanyUserAndRole)
                            }
                            placement='left'
                            area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
                            id='status-tooltip'
                          >
                            <EditStatus
                              user={myUser}
                              onUpdate={updateUsersList}
                            />
                          </PermissionTooltip>
                        </td>
                      </>
                    )}
                    <td>
                      {hasAccess(permissions.ManageCompanyUserAndRole) && (
                        <ArchiveToggleUser
                          user={myUser}
                          onUpdate={updateUsersList}
                        />
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      )}
    </>
  )
}

function EditUserRole({ user, onUpdate }) {
  const [showEditModal, setShowEditModal] = useState(false)

  const loggedUser = useSelector((state) => state?.Account?.user)

  const changeRole = useFetch({
    action: changeUserRole,
    onComplete: (data) => {
      setShowEditModal(null)

      if (data?.success === false) {
        toastr.error(data?.message || 'Something went wrong while updating')
      } else {
        toastr.success(t('Role updated successfully'))
        onUpdate?.(false)
      }
    },
    onError: (err) => {
      setShowEditModal(null)
      toastr.error(err)
      onUpdate?.(false)
    },
  })

  const { hasAccess } = usePermissions()

  if (
    user.id === loggedUser?.id ||
    user?.role === 'Owner' ||
    !hasAccess(permissions.ManageCompanyUserAndRole)
  ) {
    return <div className='tw-px-2 tw-py-1'>{user?.role?.name}</div>
  }

  return (
    <>
      <button
        className='tw-flex tw-gap-2 tw-rounded tw-px-2 tw-py-1 tw-transition-colors tw-duration-75 hover:tw-bg-surface-10'
        onClick={() => setShowEditModal(true)}
      >
        {user?.role?.name}

        <CaretDown size={20} className='tw-fill-primary-100' />
      </button>

      <RolesModal
        hide={() => setShowEditModal(false)}
        show={showEditModal}
        onChange={(roleId) => {
          changeRole.startFetch({
            user_id: user?.id,
            role_id: roleId?.value,
          })
        }}
        user={user}
        loading={changeRole.isLoading}
      />
    </>
  )
}

function CompanyUser({ myUser, archived }) {
  const fullName = getFullName(myUser)

  return (
    <div className='align-items-center d-flex flex-nowrap gap-12 tw-text-start'>
      <Avatar name={fullName || 'Invitation pending'} photo={myUser.photo} />

      <div className='tw-text-secondary-100'>
        <h4
          className='tw-mb-0 tw-text-sm tw-font-semibold tw-text-current'
          translate='no'
        >
          {fullName || 'Invitation pending'}
        </h4>
        {archived ? null : (
          <p className='tw-mb-0 tw-mt-1 tw-text-xs'>{myUser.email}</p>
        )}
      </div>
    </div>
  )
}

export default CompanyUsers
