import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { WarningOctagon } from '@phosphor-icons/react'
import Button from '../../../components/ui/button'
import React from 'react'

export function CancelRequestModal({ isOpen, toggle, onCancel }) {
  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <WarningOctagon size={25} className='tw-text-systemRed-100' />
      </ModalHeader>
      <ModalBody className='d-flex flex-column'>
        <span className='tw-text-base tw-font-bold'>
          Are you sure you want to cancel this request?
        </span>
        <span>
          This document request will no longer be visible to the worker
        </span>
      </ModalBody>
      <ModalFooter className='!tw-flex-nowrap'>
        <Button
          className='tw-mb-2 !tw-bg-white'
          textClassName='!tw-text-black'
          color='surface-30'
          outline={true}
          onClick={toggle}
        >
          No, close
        </Button>
        <Button
          color='danger'
          onClick={() => {
            onCancel()
            toggle()
          }}
        >
          Yes, Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
