import { t } from 'i18next'
import {
  CalendarBlank,
  CalendarCheck,
  Envelope,
  Flag as FlagIcon,
  GlobeHemisphereEast,
  IdentificationBadge,
  IdentificationCard,
  Medal,
  Paperclip,
  Phone,
  SuitcaseSimple,
  Tag,
  Textbox,
  TreeStructure,
  UserRectangle,
} from '@phosphor-icons/react'
import {
  differenceInMonths,
  differenceInYears,
  format,
  formatDistanceToNowStrict,
} from 'date-fns'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Avatar, cn, ListItem } from 'ui'

import Button from '../../../components/ui/button'
import Flag, { getFlagUrlFromIso2 } from '../../../components/ui/flag'
import { SideMenu } from '../../../components/ui/side-menu'
import { userTypes } from '../../../helpers/enum'
import { usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import { getFullName } from '../../../utils/get-full-name'
import ContractStatus from '../ContractList/contract-status'
import { getContractStatusColor } from '../ContractList/ContractList'

export function WorkerDetailsSideMenu({ contract, label, user }) {
  const userType = useSelector((state) => state.Account?.user?.type)
  const isClient = userType === userTypes.COMPANY

  const { hasAccess } = usePermissions()

  const [isOpen, setIsOpen] = useState(false)
  function toggleMenu() {
    setIsOpen((open) => !open)
  }

  // Return early if the user is not a client
  if (!isClient) return null

  const fullName = getFullName(user)

  const formattedBirthDate = !user?.birth_date
    ? null
    : format(new Date(user?.birth_date), 'd MMMM yyyy')
  const formattedBirthDateDistance = !user?.birth_date
    ? null
    : formatDistanceToNowStrict(new Date(user?.birth_date), { unit: 'year' })
  const prettyBirthDate = !user?.birth_date
    ? null
    : `${formattedBirthDate} (${formattedBirthDateDistance})`

  const formattedTenure = getTenure({ startDate: contract?.start_date })

  const iconStyles = { className: 'tw-size-6 tw-text-text-60' }
  const detailSections = [
    {
      title: t('General information'),
      items: [
        {
          icon: <Textbox {...iconStyles} />,
          label: t('Title'),
          value: user?.title,
        },
        {
          icon: <UserRectangle {...iconStyles} />,
          label: t('Full name'),
          value: fullName,
        },
        {
          icon: <Envelope {...iconStyles} />,
          label: t('Email'),
          value: user?.email,
        },
        {
          icon: <Phone {...iconStyles} />,
          label: t('Phone'),
          value: user?.phone,
        },
        {
          icon: <CalendarBlank {...iconStyles} />,
          label: t('Date of birth'),
          value: prettyBirthDate,
        },
        user?.citizen && {
          icon: <FlagIcon {...iconStyles} />,
          label: t('Nationality'),
          // TODO: Nationality is not available in the API
          value: <CountryLabel country={user?.citizen} />,
        },
        {
          icon: <GlobeHemisphereEast {...iconStyles} />,
          label: t('Country of residence'),
          value: <CountryLabel country={user?.country} />,
        },
        // {
        //   icon: <MapPinLine {...iconStyles} />,
        //   label: 'Address',
        //   // TODO: address is not available in the API
        //   // value: user?.address,
        // },
        // {
        //   icon: <Clock {...iconStyles} />,
        //   label: 'Timezone',
        //   // TODO: timezone is not available in the API
        //   // value: user?.timezone,
        // },
      ],
    },
    {
      title: t('Contract details'),
      items: [
        {
          icon: <IdentificationBadge {...iconStyles} />,
          label: t('Contract ID'),
          value: `#${contract?.ref}`,
        },
        {
          icon: <Tag {...iconStyles} />,
          label: t('Status'),
          value: (
            <ContractStatus
              contract={contract}
              badgeStatus={getContractStatusColor(
                contract?.status,
                contract?.timeline?.[contract?.timeline?.length - 1],
              )}
            />
          ),
        },
        {
          icon: <SuitcaseSimple {...iconStyles} />,
          label: t('Role'),
          value: contract?.name,
        },
        {
          icon: <CalendarCheck {...iconStyles} />,
          label: t('Start date'),
          value: contract?.start_date ?? '--',
        },
        contract?.start_date && {
          icon: <Medal {...iconStyles} />,
          label: t('Tenure'),
          value: formattedTenure,
        },
        {
          icon: <IdentificationCard {...iconStyles} />,
          label: t('Hire type'),
          value: contract?.type,
        },
      ],
    },
    // @todo: add org chart info once available in the API
    // {
    //   title: 'Organizational information',
    //   items: [
    //     {
    //       icon: <GridFour {...iconStyles} />,
    //       label: 'Department',
    //       value: !contract?.department_id
    //         ? 'No department assigned'
    //         : `${contract?.department || ''} (#${contract?.department_id})`,
    //     },
    //     ...(!contract?.department_id
    //       ? []
    //       : [
    //           {
    //             icon: <TreeStructure {...iconStyles} />,
    //             label: 'Head of department',
    //             // Todo: Head of department is not available in the API
    //             value: null,
    //           },
    //           {
    //             icon: <Users {...iconStyles} />,
    //             label: 'Reporting to',
    //             // Todo: Reporting to is not available in the API
    //             value: null,
    //           },
    //           {
    //             icon: <UserList {...iconStyles} />,
    //             label: 'Direct reports',
    //             // Todo: Direct reports is not available in the API
    //             value: null,
    //           },
    //         ]),
    //   ],
    // },
    // @todo: add default payment method info once available in the API
    // {
    //   title: 'Default payment method',
    //   items: [
    //     {
    //       icon: <HashStraight {...iconStyles} />,
    //       label: 'Method',
    //       // Todo: Default payment method is not available in the API
    //       value: null,
    //     },
    //     {
    //       icon: <Money {...iconStyles} />,
    //       label: 'Currency',
    //       // Todo: Default payment method is not available in the API
    //       value: null,
    //     },
    //   ],
    // },
  ]

  const documentsTabLink = `/contract/detail?id=${contract?.ref}&tab=documents`

  return (
    <>
      <button
        className='tw-flex tw-size-8 tw-items-center tw-justify-center tw-rounded tw-bg-secondary-20 tw-p-2 tw-text-secondary-100 tw-transition-colors hover:tw-bg-secondary-30'
        type='button'
        onClick={toggleMenu}
      >
        <IdentificationBadge />
      </button>

      <SideMenu
        className='tw-max-w-[480px]'
        itemListClassName='tw-grid tw-grid-rows-[auto_1fr_auto] [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain'
        isOpen={isOpen}
        onClose={toggleMenu}
      >
        <SideMenu.Header toggle={toggleMenu}>
          <div className='tw-flex tw-items-center tw-gap-4'>
            <Avatar
              size='xl'
              name={fullName || user?.email}
              photo={user?.photo}
              label={label}
            />

            <div>
              <div className='tw-flex tw-items-center tw-gap-2'>
                <div className='tw-font-semibold'>{fullName}</div>
                {/* @todo: add vacation info tooltip */}
                {/* <TreePalm
                   id='vacation-info'
                   className='tw-size-6 tw-rounded-full tw-bg-primary-30 tw-p-1 tw-text-primary-100'
                  />
                  <UncontrolledTooltip target='vacation-info'>
                   Vacation info
                  </UncontrolledTooltip> */}
              </div>
              <div className='tw-text-sm tw-font-medium tw-text-text-80'>
                #{contract.ref}
              </div>
            </div>
          </div>
        </SideMenu.Header>
        <SideMenu.Body className='!tw-p-0'>
          {detailSections.map((section, index) => {
            return (
              <DetailsSection
                key={index}
                title={section.title}
                items={section.items}
                className={cn(
                  'tw-px-4 tw-py-8 md:tw-px-6',
                  index === 0 ? '' : 'tw-border-t tw-border-surface-30',
                )}
              />
            )
          })}
        </SideMenu.Body>
        <SideMenu.Footer className='tw-grid tw-grid-cols-2'>
          <Button
            color='light'
            outline
            icon={<Paperclip size={20} />}
            tag={Link}
            to={documentsTabLink}
          >
            {t('View documents')}
          </Button>
          {hasAccess([
            permissions.ViewOrgChart,
            permissions.ManageOrgChart,
          ]) && (
            <Button
              color='light'
              outline
              icon={<TreeStructure size={20} />}
              tag={Link}
              to='/org-chart'
            >
              {t('View OrgChart')}
            </Button>
          )}
        </SideMenu.Footer>
      </SideMenu>
    </>
  )
}

function DetailsSection({ className, title, items }) {
  return (
    <div className={className}>
      <h6 className='tw-mb-0 tw-text-base tw-font-bold'>{title}</h6>

      <div>
        {items.filter(Boolean).map((item, index) => {
          return (
            <ListItem
              key={index}
              left={<ItemLabel label={item?.label} icon={item?.icon} />}
              right={item?.value ?? t('N/A')}
            />
          )
        })}
      </div>
    </div>
  )
}

function ItemLabel({ label, icon }) {
  if (!icon) return label

  return (
    <div className='tw-flex tw-items-center tw-gap-4'>
      {icon}
      {label}
    </div>
  )
}

function CountryLabel({ country }) {
  if (!country) return null

  return (
    <div className='tw-flex tw-items-center tw-gap-2'>
      {country?.name}
      <Flag url={getFlagUrlFromIso2(country?.iso2)} />
    </div>
  )
}

function plural({ number, singular, plural }) {
  return number === 1 ? singular : plural
}

function getTenure({ startDate }) {
  if (!startDate) return null

  const startDateObject = new Date(startDate)
  const diffYears = differenceInYears(new Date(), startDateObject)
  const diffMonths = differenceInMonths(new Date(), startDateObject)

  const tenureYears = diffYears > 0 ? diffYears : null
  const tenureMonths = diffMonths - diffYears * 12

  const formattedTenure = [
    tenureYears > 0
      ? `${tenureYears} ${plural({
          number: tenureYears,
          singular: 'year',
          plural: 'years',
        })}`
      : null,
    tenureMonths > 0
      ? `${tenureMonths} ${plural({
          number: tenureMonths,
          singular: 'month',
          plural: 'months',
        })}`
      : null,
    !tenureYears && !tenureMonths ? t('Less than a month') : null,
  ]
    .filter(Boolean)
    .join(' and ')

  return formattedTenure
}
