import { CaretDown } from '@phosphor-icons/react'
import React, { useState, type ReactNode } from 'react'
import { Collapse } from 'reactstrap'
import { cn } from 'ui'

interface Props {
  className: string
  label: ReactNode
  value: ReactNode
  contentWrapperClassName?: string
  iconSize?: number
  iconClassName?: string
  isOpenByDefault?: boolean
}
export default function Accordion({
  className,
  label,
  value,
  contentWrapperClassName,
  iconSize,
  iconClassName,
  isOpenByDefault,
}: Props) {
  const [isOpen, setIsOpen] = useState(isOpenByDefault)
  return (
    <>
      <button
        type='button'
        className={cn(
          'tw-flex tw-w-full tw-items-center tw-justify-between tw-p-6',
          { '!tw-rounded-b-none': isOpen },
          className,
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        {label}
        <CaretDown
          size={iconSize ?? 20}
          className={cn(
            'tw-fill-secondary tw-transition-transform tw-duration-200',
            { 'tw-rotate-180': isOpen },
            iconClassName,
          )}
        />
      </button>
      <Collapse
        isOpen={isOpen}
        className={cn('tw-px-6 tw-py-4', contentWrapperClassName)}
      >
        {value}
      </Collapse>
    </>
  )
}
