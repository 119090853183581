import { setDefaultOptions } from 'date-fns'
import { ar, enUS } from 'date-fns/locale'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { useLocalStorage } from 'usehooks-ts'

import { assetsStorage } from '../../../helpers/config'
import i18n from '../../../i18n'
import { addLocaleToInstancesRequestInterceptor } from '../../../services/interceptors'
import { getFlagUrlFromIso2 } from '../../ui/flag'

const languages = {
  en: {
    label: { en: 'English', ar: 'الانجليزية' },
    flag: `${assetsStorage}/${getFlagUrlFromIso2('us')}`,
  },
  ar: {
    label: { en: 'Arabic', ar: 'العربية' },
    flag: `${assetsStorage}/${getFlagUrlFromIso2('sa')}`,
  },
}

function LanguageDropdown() {
  const [menu, setMenu] = useState(false)
  const [savedLang, setSavedLang] = useLocalStorage(
    'language',
    i18n.language.split('-')[0],
  )

  const selectedLanguage = languages[i18n.language]

  useEffect(() => {
    i18n.changeLanguage(savedLang)
    addLocaleToInstancesRequestInterceptor(savedLang)

    window.document.dir = i18n.dir()
    document.querySelector('html').lang = savedLang

    setDefaultOptions({ locale: savedLang === 'ar' ? ar : enUS })
  }, [])

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className='tw-inline-block'
    >
      <DropdownToggle
        className='tw-flex tw-items-center tw-gap-2 tw-p-4 tw-text-secondary-100 hover:tw-bg-surface-30 hover:tw-text-secondary-100'
        tag='button'
        type='button'
      >
        <img src={selectedLanguage?.flag} alt='' className='tw-h-4' />
        <span>{selectedLanguage?.label?.[i18n.language]}</span>
      </DropdownToggle>

      <DropdownMenu right={i18n.dir() === 'rtl'} className='!tw-mt-2'>
        {Object.entries(languages).map(([key, language]) => {
          return (
            <DropdownItem
              key={key}
              type='button'
              tag='button'
              onClick={() => {
                window.location.reload()
                setSavedLang(key)
              }}
              className='!tw-flex tw-items-center tw-gap-2'
            >
              <img
                src={language.flag}
                alt=''
                style={{ height: 12, width: 16 }}
              />
              <span>{language.label[key]}</span>
            </DropdownItem>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}

export default withTranslation()(LanguageDropdown)
