import { useState, useEffect } from 'react'
import { SHOW_AUTOMATION_ONBOARDING_STORAGE_KEY } from '../tools/constants'

export function useOnboardingDisplay({ automations = [] } = {}) {
  const [isDisplayed, setDisplayed] = useState(
    JSON.parse(localStorage.getItem(SHOW_AUTOMATION_ONBOARDING_STORAGE_KEY)),
  )

  useEffect(() => {
    if (!isDisplayed && automations?.length > 0) setDisplayed(true)
  }, [automations, isDisplayed])

  useEffect(() => {
    localStorage.setItem(
      SHOW_AUTOMATION_ONBOARDING_STORAGE_KEY,
      JSON.stringify(isDisplayed),
    )
  }, [isDisplayed])

  return { isDisplayed, setDisplayed }
}
