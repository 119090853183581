import { HandHeart, Sparkle } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { Alert, Modal, ModalBody } from 'reactstrap'
import { Button, CloseButton, ProfileCard } from 'ui'

import Lottie from 'lottie-react'
import confettiAnimation from './confetti_animation.json'
import { useHistory } from 'react-router'
import EventType from './EventType'
import { usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'

const CelebrationPopup = ({ open, onClose, data }) => {
  const [alertVisible, setAlertVisible] = useState(true)
  const { hasAccess } = usePermissions()

  const history = useHistory()

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      className='tw-relative !tw-overflow-hidden'
      style={{ overFlow: 'hidden' }}
    >
      <div className='tw-absolute tw-right-4 tw-top-4 tw-z-10 tw-flex'>
        <CloseButton onClick={onClose} />
      </div>
      <ModalBody className='tw-relative tw-flex tw-flex-col tw-items-center tw-gap-8 !tw-overflow-hidden !tw-px-0 !tw-pb-10'>
        <Lottie
          loop={false}
          animationData={confettiAnimation}
          style={{ position: 'absolute', top: '-134px', zIndex: 0, left: 0 }}
        />
        <div className='tw-relative tw-flex tw-flex-col tw-items-center tw-gap-8 !tw-px-6'>
          <div className='tw-flex tw-flex-col tw-items-center'>
            <HandHeart weight='duotone' color='blue' size={88} />
            <div className='tw-flex tw-flex-col tw-items-center tw-gap-2 tw-text-center'>
              <span className='tw-text-2xl tw-font-normal'>
                Today is special
              </span>
              <span className='tw-text-sm tw-font-normal tw-text-text-60'>
                Celebrate your team’s birthdays and work anniversaries—make it
                memorable!
              </span>
            </div>
          </div>
          {hasAccess(permissions.ManageCompanyAutomation) && (
            <Alert
              className='!tw-m-0 !tw-w-full !tw-border-none !tw-bg-[#F7FCF9]'
              isOpen={alertVisible}
              toggle={() => setAlertVisible(false)}
            >
              <div className='tw-flex tw-gap-2'>
                <Sparkle size={20} className='!tw-text-green-110' />
                <div className='tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-1'>
                  <span className='tw-text-xs tw-font-medium tw-text-black'>
                    Never miss an important date!
                  </span>
                  <span className='tw-w-[190px] tw-text-xs tw-font-normal tw-text-text-80 md:tw-w-[330px]'>
                    Use our ready-made templates to automate quick reminders.
                  </span>
                  <Button
                    text='Set Up Now'
                    size='small'
                    className='tw-p-0 !tw-text-green-110'
                    ghost
                    onPress={() =>
                      history.push('/settings/automations?openTemplates=true')
                    }
                  />
                </div>
              </div>
            </Alert>
          )}
        </div>

        <div className='!tw-scrollbar tw-max-h-44 tw-w-full tw-overflow-y-scroll !tw-px-6 md:tw-max-h-72'>
          {data.map((wev, i) => {
            return (
              <>
                <div key={wev.name + i} className='tw-flex tw-flex-col tw-py-4'>
                  <ProfileCard
                    key={i}
                    photo={wev.avatar}
                    title={wev.name}
                    name={wev.name}
                    flag={wev.country_flag}
                    description={wev.celebrationEvents?.join(' and ')}
                    withBorder
                    suffix={wev.events?.map((ev, i) => {
                      return (
                        <EventType key={ev.type + i} type={ev.type} index={i} />
                      )
                    })}
                  />
                </div>
                {i < data.length - 1 && <hr className='!tw-m-0' />}
              </>
            )
          })}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CelebrationPopup
