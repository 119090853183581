import React, { useEffect, useState } from 'react'
import { ArrowCounterClockwise, CaretDown } from '@phosphor-icons/react'
import FilterBox from './FilterBox'
import { Button, TooltipTrigger } from 'react-aria-components'
import { TooltipV2 } from '../../../components/VerticalLayout/tooltip-v2'
import { cn } from 'ui'

const FiltersBoxContainer = ({
  data,
  isExpandable,
  icon,
  title,
  id,
  children,
  setFilters,
  initialFilters,
  defaultFilters,
  showReset,
  handleReset,
}) => {
  useEffect(() => {
    showReset && handleReset(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showReset])

  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <div className='tw-w-full tw-bg-white'>
      <div className='tw-flex tw-w-full tw-items-center tw-justify-between tw-border-b-[1px] tw-border-surface-30 tw-px-6 tw-py-4'>
        <div className='tw-flex tw-gap-2'>
          {icon}
          <span className='tw-text-sm tw-font-normal'>{title}</span>
        </div>
        <div className='tw-flex tw-gap-2'>
          {showReset && (
            <TooltipTrigger delay={0}>
              <Button
                aria-label='Reset Filters'
                onClick={() => handleReset(true)}
                className='tw-cursor-pointer tw-rounded-[4px] tw-border tw-border-surface-30 tw-p-2'
              >
                <ArrowCounterClockwise size={16} />
              </Button>
              <TooltipV2 className='tw-text-xs' placement='bottom'>
                Reset filters
              </TooltipV2>
            </TooltipTrigger>
          )}

          {isExpandable && (
            <TooltipTrigger delay={0}>
              <Button
                className='tw-rounded-[4px] tw-border tw-border-surface-30 tw-p-2'
                onPress={() => setIsExpanded((prev) => !prev)}
                aria-label='Expand Filters'
              >
                <CaretDown
                  className={cn('tw-transition-transform', {
                    'tw-rotate-180': isExpanded,
                  })}
                  size={16}
                />
              </Button>

              <TooltipV2 className='tw-text-xs' placement='bottom'>
                {isExpanded ? 'Collapse Filters' : 'Expand Filters'}
              </TooltipV2>
            </TooltipTrigger>
          )}
        </div>
      </div>
      {data && (
        <FilterBox
          data={data}
          setFilters={setFilters}
          id={id}
          initialFilters={initialFilters}
          defaultFilters={defaultFilters}
        />
      )}

      <div className={`${isExpanded ? 'tw-visible' : 'tw-hidden'}`}>
        {children}
      </div>
    </div>
  )
}

export default FiltersBoxContainer
