import { t } from 'i18next'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Toggle from '../../../components/Forms/Toggle/Toggle'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import { updateCompanyInfo } from '../../../services/api'
import { updateProfileCompany } from '../../../store/profile/actions'
import { PERMISSION_GROUP } from '../manage-role'
import PayrollApprovalModal from './payroll-approval-modal'

export default function PayrollApprovalToggle() {
  const dispatch = useDispatch()
  const [payrollModalOpen, setPayrollModalOpen] = useState(false)

  const payrollApprovalEnabled = useSelector(
    (state) =>
      state.userProfile?.userProfile?.company?.is_payroll_approval_enabled?.toString() ===
      '1',
  )

  const { startFetch: updateCompany, isLoading } = useFetch({
    action: updateCompanyInfo,
    onComplete: (data) => {
      dispatch(updateProfileCompany(data))
      setPayrollModalOpen(false)
    },
  })

  const { hasAccess } = usePermissions()
  const canManageApprovals = hasAccess(permissions.ManageCompanyApproval)

  return (
    <>
      <PermissionTooltip
        showing={!canManageApprovals}
        id='enable-payroll-approval'
        area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
      >
        <Toggle
          change={() => setPayrollModalOpen(true)}
          check={payrollApprovalEnabled}
          name='enablePayrollApproval'
          disabled={isLoading || !canManageApprovals}
          marginRight=''
        />
      </PermissionTooltip>
      {!payrollModalOpen ? null : (
        <PayrollApprovalModal
          isOpen={payrollModalOpen}
          toggle={() => setPayrollModalOpen((o) => !o)}
          newValue={!payrollApprovalEnabled}
          isLoading={isLoading}
          onConfirm={({ sendEmail }) => {
            const body = {
              is_payroll_approval_enabled: !payrollApprovalEnabled ? 1 : 0,
            }
            if (sendEmail) {
              body.send_payroll_approval_enabled_notification = true
            }
            updateCompany(body)
          }}
        />
      )}
    </>
  )
}
