import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import Button from '../../../../components/ui/button'
import { PlusCircle, XCircle } from '@phosphor-icons/react'
import { CheckItem } from '../../../../components/ui/check-item'
import SearchBar from '../../../../components/SearchBar'
import CountryBox from './CountryBox'
import Flag from '../../../../components/ui/flag'
import { groupCountriesByAlphabet } from '../../helpers'

const CountriesMultiSelect = ({ setFilters, initialFilters, reset }) => {
  const { countries } = useSelector((state) => state?.Layout?.staticData ?? {})
  const groupedCountries = groupCountriesByAlphabet(countries) ?? null

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [searchCountries, setSearchCountries] = useState('')
  const [innerSelectedCountries, setInnerSelectedCountries] = useState([])

  const [shownSelectedCountries, setShownSelectedCountries] = useState([])

  const toggle = () => setDropdownOpen((o) => !o)

  const handleCountriesSearch = (query) => {
    setSearchCountries(query)
  }

  const countriesSearch = (obj) => {
    return (
      !searchCountries ||
      obj.name.toLowerCase().includes(searchCountries?.toLowerCase())
    )
  }

  const alphabetSearch = (obj) => {
    return (
      !searchCountries ||
      obj.letter.toLowerCase() === searchCountries.charAt(0).toLowerCase()
    )
  }

  const clear = () => {
    setShownSelectedCountries([])
    setInnerSelectedCountries([])
    setFilters({ contract_countries: [] })
  }

  useEffect(() => {
    if (reset) {
      clear()
    }
  }, [reset])

  useEffect(() => {
    if (countries && initialFilters.length > 0) {
      setInnerSelectedCountries(
        initialFilters?.map((sc) => countries?.find((obj) => obj.id === sc)),
      )
      setShownSelectedCountries(
        initialFilters?.map((sc) => countries?.find((obj) => obj.id === sc)),
      )
    }
  }, [countries, initialFilters])

  return (
    <div className='tw-flex tw-grow-0 tw-flex-col tw-items-start tw-gap-2'>
      <div
        onClick={clear}
        className={`tw-flex tw-grow-0 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full ${innerSelectedCountries.length > 0 ? 'tw-rounded-full tw-border-[1px] tw-border-surface-30 tw-bg-white' : 'tw-bg-primary-100'} !tw-px-4 !tw-py-2`}
      >
        <span
          className={`tw-text-xs tw-font-semibold ${innerSelectedCountries.length > 0 ? 'tw-text-80' : 'tw-text-white'}`}
        >
          All Countries
        </span>
      </div>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction='up'>
        <DropdownToggle data-toggle='dropdown' tag='div'>
          <div
            className={`tw-flex tw-w-[140px] tw-cursor-pointer tw-items-center !tw-justify-between tw-gap-1 tw-rounded-full ${dropdownOpen ? 'tw-bg-primary-100' : 'tw-border-[1px] tw-border-surface-30 tw-bg-white'} tw-px-4 tw-py-2`}
          >
            <span
              className={`tw-text-xs tw-font-semibold ${dropdownOpen ? 'tw-text-white' : 'tw-text-primary-100'} `}
            >
              Select Country
            </span>

            {dropdownOpen ? (
              <XCircle size={16} weight='fill' className='!tw-text-white' />
            ) : (
              <PlusCircle
                size={16}
                weight='fill'
                className='!tw-text-primary-100'
              />
            )}
          </div>
        </DropdownToggle>

        <DropdownMenu
          className='!tw-mt-[-100px] !tw-w-[240px] !tw-translate-y-[-480px] tw-overflow-hidden'
          container='div'
          direction='up'
        >
          <DropdownItem toggle={false}>
            <SearchBar
              className='tw-basis-full'
              query={searchCountries}
              placeholder='Find'
              onQueryChanged={handleCountriesSearch}
              isClearable
              inputClassName='!tw-h-auto'
            />
          </DropdownItem>
          <DropdownItem divider />
          <div className='tw-max-h-96 tw-overflow-scroll'>
            {groupedCountries.length > 0 &&
              groupedCountries?.filter(alphabetSearch).map((obj) => (
                <div key={obj.id}>
                  <DropdownItem header>{obj.letter}</DropdownItem>
                  {obj.countries?.filter(countriesSearch).map((country, i) => {
                    const isSelected = Boolean(
                      innerSelectedCountries?.find(
                        (obj) => obj.name === country.name,
                      ),
                    )
                    return (
                      <DropdownItem key={i} toggle={false}>
                        <CheckItem
                          label={
                            <div className='tw-flex tw-items-center tw-gap-2'>
                              <Flag
                                size='20px'
                                className='tw-inline-block'
                                url={country.svg}
                              />
                              <span
                                className={`tw-text-sm ${isSelected ? 'tw-font-bold tw-text-primary-100' : 'tw-text-black'}`}
                              >
                                {country.name}
                              </span>
                            </div>
                          }
                          name={country.name}
                          labelId={country.name}
                          value={country.id}
                          checked={isSelected}
                          onChange={(event) => {
                            const { name, checked } = event.target
                            const c = countries?.find(
                              (obj) => obj.name === name,
                            )
                            setInnerSelectedCountries((p) =>
                              checked
                                ? [...p, c]
                                : p.filter((obj) => obj.name !== name),
                            )
                          }}
                        />
                      </DropdownItem>
                    )
                  })}
                </div>
              ))}
          </div>
          <DropdownItem divider />
          <DropdownItem toggle={false}>
            <div className='tw-flex tw-justify-between'>
              <Button
                className='!tw-px-0'
                color='link'
                type='button'
                onClick={() => {
                  clear()
                  toggle()
                  setSearchCountries('')
                }}
                disabled={!innerSelectedCountries.length > 0}
                textClassName='tw-text-black'
              >
                Clear all
              </Button>

              <Button
                className='!tw-px-0'
                color='link'
                type='button'
                onClick={() => {
                  setShownSelectedCountries(innerSelectedCountries)
                  setFilters({
                    contract_countries: innerSelectedCountries.map((c) => c.id),
                  })
                  setSearchCountries('')
                  toggle()
                }}
                disabled={!innerSelectedCountries.length > 0}
              >
                Add
                {innerSelectedCountries.length > 0 &&
                  ` (${innerSelectedCountries.length})`}
              </Button>
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {countries &&
        shownSelectedCountries?.map((c) => {
          return (
            <CountryBox
              key={c.id}
              country={c}
              remove={() => {
                setInnerSelectedCountries((prev) =>
                  prev.filter((pc) => pc !== c),
                )
                setShownSelectedCountries((prev) =>
                  prev.filter((pc) => pc !== c),
                )
                setFilters({
                  contract_countries: innerSelectedCountries
                    .filter((pc) => pc !== c)
                    .map((o) => o.id),
                })
              }}
            />
          )
        })}
    </div>
  )
}

export default CountriesMultiSelect
