import { t } from 'i18next'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'

import { ModalCloseButton } from '../../../../components/Common/modal-close-button'
import Button from '../../../../components/ui/button'
import Loader from '../../../../components/ui/loader'
import { useFetch, usePermissions } from '../../../../helpers/hooks'
import permissions from '../../../../helpers/permissions'
import {
  getContractPDF,
  signContract,
  userDownloadContract,
} from '../../../../services/api'
import openFileV2 from '../../../../utils/file/open-v2'
import { getErrorMessage } from '../../../../utils/get-errors'
import { getFullName } from '../../../../utils/get-full-name'

export function ContractSignatureModal({
  isOpen,
  toggle,
  contract,
  onSignSuccess,
}) {
  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  const [pdfUrl, setPdfUrl] = useState()

  const contractData = { file: contract?.file, ref: contract?.ref }

  const { isLoading: gettingContractPdf } = useFetch(
    {
      action: getContractPDF,
      autoFetch: isOpen && contract?.file && contract?.ref,
      body: contractData,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(t('Something went wrong while getting the contract.'))
        } else {
          const objectUrl = URL.createObjectURL(data)
          setPdfUrl(objectUrl)
        }
      },
      onError: (error) => {
        toastr.error(getErrorMessage(error))
      },
    },
    [isOpen, contract?.file, contract?.ref],
  )

  const { hasAccess } = usePermissions()

  const { startFetch: downloadContract, isLoading: downloadingContract } =
    useFetch({
      action: userDownloadContract,
      onComplete: (data) => {
        if (data?.status === false) {
          toastr.error(
            t('Something went wrong while downloading the contract.'),
          )
        } else {
          openFileV2(data, {
            name: `contract-${contract?.ref}.pdf`,
            type: 'application/pdf',
            download: true,
          })
        }
      },
      onError: (error) => {
        toastr.error(getErrorMessage(error))
      },
    })

  const { startFetch: signContractFetch, isLoading: signingContract } =
    useFetch({
      action: signContract,
      onComplete: (data) => {
        if (data?.status === false) {
          toastr.error(t('Something went wrong while signing the contract.'))
        } else {
          toastr.success(t('Contract signed successfully'))
          toggle()
          onSignSuccess?.()
        }
      },
      onError: (error) => {
        toastr.error(getErrorMessage(error))
      },
    })

  const userFullName = getFullName(userProfile)

  function handleSignContract() {
    const contractId = contract?.id

    signContractFetch({ name: userFullName, contract_id: contractId })
  }

  const contractPreviewClasses = 'tw-h-[calc(100vh-12.5rem)]'

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg' centered>
      <ModalHeader
        close={
          <div className='tw-flex tw-items-center tw-gap-4'>
            <Button
              color='light'
              size='sm'
              outline
              onClick={() => downloadContract(contractData)}
              disabled={
                !hasAccess(permissions.DownloadContracts) || downloadingContract
              }
              loading={downloadingContract}
            >
              {t('Download')}
            </Button>

            <ModalCloseButton toggle={toggle} />
          </div>
        }
      >
        {t('Sign Contract')}
      </ModalHeader>

      <ModalBody className='!tw-p-0'>
        {gettingContractPdf ? (
          <Loader minHeight='100%' className={contractPreviewClasses} />
        ) : (
          <iframe
            title={`${t('Contract')} #${contract?.ref}`}
            src={pdfUrl}
            width='100%'
            className={contractPreviewClasses}
          />
        )}
      </ModalBody>

      <ModalFooter className='justify-content-between'>
        <div className='tw-flex tw-flex-col'>
          <div className='tw-text-xs tw-text-text-80'>{t('Signatory')}</div>
          <div className='tw-text-sm'>{userFullName}</div>
        </div>

        <Button
          type='button'
          onClick={handleSignContract}
          disabled={signingContract}
          loading={signingContract}
        >
          {t('Sign contract')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
