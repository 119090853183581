import { t } from 'i18next'
import React from 'react'
import { useDispatch } from 'react-redux'

import { SimpleCard } from '../../../../components/simple-card'
import Button from '../../../../components/ui/button'
import { submitAccountMappingAndUpdateRequest } from '../../../../store/xero/actions'
import { IntegrationAccountMapping } from '../XeroAccountMapping'

export function XeroAccountMappingTab({ loading, onSubmitted }) {
  const dispatch = useDispatch()

  const integrationName = 'Xero'

  return (
    <SimpleCard className='tw-mt-6 tw-p-6'>
      <h4 className='tw-text-base tw-font-bold'>
        {t('Select the account to use for each bill', { integrationName })}
      </h4>
      <p className='tw-mb-0 tw-text-sm tw-text-disabled'>
        {t(
          'RemotePass categories on the left will be posted to the accounts on the right',
          { integrationName },
        )}
      </p>

      <IntegrationAccountMapping
        isUpdate
        context='tab'
        onSubmitted={(values) => {
          dispatch(submitAccountMappingAndUpdateRequest(values))
          onSubmitted()
        }}
      />

      <div className='tw-mt-4 tw-flex tw-justify-end'>
        <Button
          type='button'
          onClick={() => {
            document.getElementById('submit-xero-account-mapping-tab').click()
          }}
          loading={loading}
          disabled={loading}
        >
          {t('Save')}
        </Button>
      </div>
    </SimpleCard>
  )
}
