import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Container, Table, UncontrolledTooltip } from 'reactstrap'
import SearchBar from '../../components/SearchBar'
import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import { mapCountryToOption } from '../../utils/map-to-option'
import welcomeImage from '../../assets/images/welcome.svg'
import Shimmer from '../../components/ui/shimmer'
import TabEmpty from '../Contract/components/tab/tab-empty'
import { Eye, FolderOpen } from '@phosphor-icons/react'
import { Button, cn, Pagination } from 'ui'
import Flag, { getFlagUrlFromIso2 } from '../../components/ui/flag'
import { format, isValid } from 'date-fns'
import CustomDatePicker from '../../components/Forms/CustomDatePicker/CustomDatePicker'
import { DATE_FORMAT } from '../../utils/formatters/date-picker-date-format'
import BadgeV2 from '../../components/ui/badge-v2'
import NoContent from '../../components/NoContent'

export default function ClientPayroll({
  onQuery,
  onEntityChange,
  payrollData,
  ...props
}) {
  const { paginator, page, setPage, filterDate, setFilterDate } = props
  const [query, setQuery] = useState(null)

  const isListEmpty =
    payrollData?.length === 0 && !props?.loading && query !== ''

  const { contract_statuses: status, countries } = useSelector(
    (state) => state?.Layout?.staticData ?? {},
  )
  const profile = useSelector((state) => state?.userProfile?.userProfile)

  const isPastPayrollReport = Boolean(setFilterDate)

  function handleSearch(query) {
    setQuery(query)
    onQuery(query)
  }

  const filtersBlock = (
    <Container fluid className='tw-p-4 md:tw-px-6'>
      <div className='tw-flex tw-flex-col tw-flex-wrap tw-gap-2 sm:tw-flex-row'>
        <SearchBar
          className='tw-w-full tw-min-w-[11rem] tw-flex-1'
          query={query}
          placeholder='Search'
          onQueryChanged={handleSearch}
          isClearable
          inputClassName='!tw-h-auto'
        />
        {isPastPayrollReport && (
          <CustomDatePicker
            wrapperClassName='tw-w-full tw-min-w-[11rem] tw-flex-1'
            placeholder='Month'
            clearable
            showMonthYearPicker
            showFullMonthYearPicker
            handleClear={() => {
              setFilterDate('')
            }}
            selected={filterDate ? new Date(filterDate) : null}
            handleOnChange={setFilterDate}
          />
        )}

        <CustomSelect
          options={profile.entities.map((entity) => ({
            value: entity.entity_id,
            label: entity.name,
          }))}
          wrapperClassName='tw-flex-1 tw-min-w-44'
          placeholder='Entity'
          onChange={(val) => onEntityChange?.(val?.value)}
          isClearable
        />

        <CustomSelect
          wrapperClassName='tw-flex-1 tw-min-w-44'
          isClearable
          placeholder='Country'
          options={countries?.map((c) => mapCountryToOption(c))}
          onChange={(country) => {
            props.filterByCountry?.(country?.id)
          }}
        />
      </div>
    </Container>
  )

  return !props?.loading && profile.entities?.length > 1 ? (
    <div
      className='tw-rounded tw-bg-white'
      style={{ boxShadow: '0px 1px 0px #dfe1e6' }}
    >
      {filtersBlock}

      <>
        <div className='table-responsive'>
          {isListEmpty ? (
            <TabEmpty
              title='No data available'
              icon={
                <FolderOpen
                  size={240}
                  color='var(--primary)'
                  weight='duotone'
                />
              }
            />
          ) : props?.loading || props?.filtering ? (
            <div
              className={cn('w-100 px-3 pb-3')}
              style={{ '--s-height': '57px' }}
            >
              <Shimmer width='100%' height='var(--s-height)' className='mb-1' />
              <Shimmer width='100%' height='var(--s-height)' className='mb-1' />
              <Shimmer width='100%' height='var(--s-height)' />
            </div>
          ) : (
            <Table className='table-centered table-nowrap text-muted mb-0'>
              <thead>
                <tr
                  style={{
                    borderTop: 'hidden',
                    borderBottom: '1px solid #E7E8F2',
                    width: '100px',
                    maxWidth: '100px',
                  }}
                >
                  <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                    Country
                  </th>

                  <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                    Entity
                  </th>

                  <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                    Employees
                  </th>

                  <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                    Cycle
                  </th>

                  <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                    Cut off date
                  </th>

                  {isPastPayrollReport && (
                    <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                      Total Payroll
                    </th>
                  )}

                  <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                    Status
                  </th>

                  <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                    Actions
                  </th>
                </tr>
              </thead>
              {!props.filtering && (
                <tbody>
                  {payrollData?.map((payroll) => {
                    return (
                      <PayrollLine
                        key={`payroll-${payroll?.id}`}
                        payroll={payroll}
                        isPastPayrollReport={isPastPayrollReport}
                      />
                    )
                  })}
                </tbody>
              )}
            </Table>
          )}
        </div>

        {!paginator || isListEmpty || props?.loading ? null : (
          <div className='mt-2 mt-md-0 d-flex justify-content-md-end p-4.5'>
            <Pagination
              innerClass='pagination mb-0'
              activePage={page}
              onChange={setPage}
              itemsCountPerPage={paginator?.per_page ?? 50}
              totalItemsCount={paginator?.total ?? 0}
            />
          </div>
        )}
      </>
    </div>
  ) : (
    <div
      className='tw-rounded tw-bg-white'
      style={{ boxShadow: '0px 1px 0px #dfe1e6' }}
    >
      {filtersBlock}
      <NoContent
        image={welcomeImage}
        subtitle='Manage and run payroll for direct employees.'
        headline='Direct Employee Payroll'
      />
    </div>
  )
}

const PayrollLine = ({ payroll, isPastPayrollReport }) => {
  const payrollCountryFlag = getFlagUrlFromIso2(payroll?.country?.iso2) ?? null
  const getColor = () => {
    switch (payroll.status) {
      case 'approved':
      case 'paid':
        return 'success'
      case 'pending':
        return 'warning'
      default:
        return 'primary'
    }
  }

  return (
    <tr
      className={cn('tw-border-b tw-border-b-surface-30 odd:tw-bg-surface-10')}
    >
      <td className='tw-p-4'>
        <span className='tw-flex tw-items-center tw-gap-2'>
          <Flag size='24px' url={payrollCountryFlag} />
          <span className='tw-text-sm tw-text-text'>
            {payroll.country?.name || 'N/A'}
          </span>
        </span>
      </td>

      <td className='tw-p-4'>
        <span className='tw-text-sm tw-font-medium tw-text-text'>
          {payroll.entity_name}
        </span>
      </td>

      <td className='tw-p-4'>
        <span className='tw-text-sm tw-font-medium tw-text-text'>
          {payroll.employees}
        </span>
      </td>

      <td className='tw-p-4'>
        <span className='tw-text-sm tw-font-medium tw-text-text'>
          {payroll.cycle && isValid(new Date(payroll.cycle))
            ? format(new Date(payroll.cycle), 'MMMM yyyy')
            : 'N/A'}
        </span>
      </td>

      <td className='tw-p-4'>
        <span className='tw-text-sm tw-font-medium tw-text-text'>
          {payroll.cut_off_date && isValid(new Date(payroll.cut_off_date))
            ? format(new Date(payroll.cut_off_date), DATE_FORMAT.FORMAT_1)
            : 'N/A'}
        </span>
      </td>

      {isPastPayrollReport && (
        <th className='tw-text-sm tw-font-medium tw-text-text'>
          {payroll.total_payroll || 'N/A'}
        </th>
      )}

      <td className='tw-p-4'>
        <BadgeV2 name={payroll?.status} status={getColor()} />
      </td>

      <td className='tw-p-4'>
        <Button
          // @todo: replace with link/action to details page.
          // onClick={toggle}
          onlyIcon
          type='button'
          className='tw-rounded-lg tw-bg-secondary-20 hover:tw-bg-secondary-40'
          icon={<Eye size={20} className='tw-text-secondary-100' />}
          id='actionBtn'
        />
        <UncontrolledTooltip target='actionBtn'>
          See details
        </UncontrolledTooltip>
      </td>
    </tr>
  )
}
