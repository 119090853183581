import React from 'react'
import * as Yup from 'yup'
import {
  At,
  BellSimpleRinging,
  Clock,
  ClockCountdown,
  CreditCard,
  Envelope,
  FileDoc,
  Funnel,
  Hammer,
  Hash,
  IdentificationCard,
  Money,
  Person,
  Robot,
  Signature,
  SlackLogo,
  UsersThree,
} from '@phosphor-icons/react'
import isNill from '../../../../../utils/is-nill'
import { getRemovableStorageKey } from '../../../../../helpers/removable-storage'

export const TRIGGER_OCCURRENCE = {
  PENDING_DAYS: 'pending_days',
  AFTER_DAYS: 'after_days',
  BEFORE_DAYS: 'before_days',
  RANGE: 'range',
}

export const TRIGGER_FILTER = {
  EXPENSE_AMOUNT: 'expense_amount',
  PAYMENT_ADJUSTMENT_AMOUNT: 'payment_adjustment_amount',
  WORK_AMOUNT: 'work_rate_hours',
  CONTRACT_TENURE: 'contract_tenures.tenure',
  DOCUMENT_NAME: 'document_name',
  DOCUMENT_REQUEST_DATE: 'document_requested_date',
}

export const ACTION_TYPE = {
  EMAIL: 'EMAIL',
  SLACK: 'SLACK',
}

export const SHOW_AUTOMATION_ONBOARDING_STORAGE_KEY = getRemovableStorageKey(
  'SHOW_AUTOMATION_ONBOARDING',
)

const TRIGGER_CATEGORY = {
  CONTRACT: 'Contract',
  TIME_OFF: 'Timeoff',
  PAYMENT: 'Payments',
  PEOPLE: 'People',
  EXPENSES: 'Expenses',
  WORK: 'Works',
  DOCUMENTS_SUBMISSIONS: 'Documents',
}

export const allTimezones = {
  'Pacific/Midway': 'Midway Island, Samoa',
  'Pacific/Honolulu': 'Hawaii',
  'America/Juneau': 'Alaska',
  'America/Boise': 'Mountain Time',
  'America/Dawson': 'Dawson, Yukon',
  'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
  'America/Phoenix': 'Arizona',
  'America/Chicago': 'Central Time',
  'America/Regina': 'Saskatchewan',
  'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
  'America/Belize': 'Central America',
  'America/Detroit': 'Eastern Time',
  'America/Bogota': 'Bogota, Lima, Quito',
  'America/Caracas': 'Caracas, La Paz',
  'America/Santiago': 'Santiago',
  'America/St_Johns': 'Newfoundland and Labrador',
  'America/Sao_Paulo': 'Brasilia',
  'America/Tijuana': 'Tijuana',
  'America/Montevideo': 'Montevideo',
  'America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown',
  'America/Godthab': 'Greenland',
  'America/Los_Angeles': 'Pacific Time',
  'Atlantic/Azores': 'Azores',
  'Atlantic/Cape_Verde': 'Cape Verde Islands',
  GMT: 'UTC',
  'Europe/London': 'Edinburgh, London',
  'Europe/Dublin': 'Dublin',
  'Europe/Lisbon': 'Lisbon',
  'Africa/Casablanca': 'Casablanca, Monrovia',
  'Atlantic/Canary': 'Canary Islands',
  'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Sarajevo': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Africa/Algiers': 'West Central Africa',
  'Europe/Bucharest': 'Bucharest',
  'Africa/Cairo': 'Cairo',
  'Europe/Helsinki': 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Athens': 'Athens',
  'Asia/Jerusalem': 'Jerusalem',
  'Africa/Harare': 'Harare, Pretoria',
  'Europe/Moscow': 'Istanbul, Minsk, Moscow, St. Petersburg, Volgograd',
  'Asia/Kuwait': 'Kuwait, Riyadh',
  'Africa/Nairobi': 'Nairobi',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Tehran': 'Tehran',
  'Asia/Dubai': 'Abu Dhabi, Muscat',
  'Asia/Baku': 'Baku, Tbilisi, Yerevan',
  'Asia/Kabul': 'Kabul',
  'Asia/Yekaterinburg': 'Ekaterinburg',
  'Asia/Karachi': 'Islamabad, Karachi, Tashkent',
  'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Dhaka': 'Astana, Dhaka',
  'Asia/Colombo': 'Sri Jayawardenepura',
  'Asia/Almaty': 'Almaty, Novosibirsk',
  'Asia/Rangoon': 'Yangon Rangoon',
  'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
  'Asia/Taipei': 'Taipei',
  'Australia/Perth': 'Perth',
  'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
  'Asia/Seoul': 'Seoul',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
  'Asia/Yakutsk': 'Yakutsk',
  'Australia/Darwin': 'Darwin',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Sydney': 'Canberra, Melbourne, Sydney',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Hobart': 'Hobart',
  'Asia/Vladivostok': 'Vladivostok',
  'Pacific/Guam': 'Guam, Port Moresby',
  'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia',
  'Asia/Kamchatka': 'Kamchatka, Marshall Islands',
  'Pacific/Fiji': 'Fiji Islands',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Tongatapu': "Nuku'alofa",
}

export const triggerFilterConditions = [
  {
    label: 'Equal to',
    value: '==',
  },
  {
    label: 'More than',
    value: '>',
  },
  {
    label: 'Less than',
    value: '<',
  },
]

export const occurrenceDaysLabel = {
  [TRIGGER_OCCURRENCE.BEFORE_DAYS]: 'Days before',
  [TRIGGER_OCCURRENCE.AFTER_DAYS]: 'Days After',
  [TRIGGER_OCCURRENCE.PENDING_DAYS]: 'For how many days',
  [TRIGGER_OCCURRENCE.RANGE]: 'How many days?',
}

export const rangeOccurrences = [
  {
    label: 'Before',
    value: 'before',
    key: 'minutesBefore',
  },
  {
    label: 'After',
    value: 'after',
    key: 'minutesLong',
  },
]

export const triggers = [
  {
    label: 'Contract',
    icon: (props) => <IdentificationCard {...props} />,
    options: [
      {
        label: 'Pending worker signature',
        value: 'CONTRACTOR_INVITED',
        description: 'When the worker has not signed the contract after X days',
        category: TRIGGER_CATEGORY.CONTRACT,
        occurrence: {
          type: TRIGGER_OCCURRENCE.PENDING_DAYS,
          key: 'minutesLong',
        },
      },
      {
        label: 'Pending company signature',
        value: 'CLIENT_INVITED',
        description:
          'When the company has not signed the contract after X days',
        category: TRIGGER_CATEGORY.CONTRACT,
        occurrence: {
          type: TRIGGER_OCCURRENCE.PENDING_DAYS,
          key: 'minutesLong',
        },
      },
      {
        label: 'Contract activated',
        category: TRIGGER_CATEGORY.CONTRACT,
        description: 'When the contract status changes to Ongoing',
        value: 'CONTRACT_ACTIVATED',
      },
      {
        label: 'Contract terminated',
        category: TRIGGER_CATEGORY.CONTRACT,
        description: 'When the contract status changes to Terminated',
        value: 'CONTRACT_TERMINATED',
      },
      {
        label: 'Contract amended',
        category: TRIGGER_CATEGORY.CONTRACT,
        description: 'When the contract status changes to Pending Amendment',
        value: 'CONTRACT_AMENDED',
      },
    ],
  },
  {
    label: 'People',
    icon: (props) => <Person {...props} />,
    options: [
      {
        label: 'Direct manager changed',
        category: TRIGGER_CATEGORY.PEOPLE,
        description: 'When a direct manager is assigned to a contract',
        value: 'DIRECT_MANAGER_ASSIGNED',
      },
      {
        label: 'Job role changed',
        category: TRIGGER_CATEGORY.PEOPLE,
        description: "When a worker's title or role is updated in a contract",
        value: 'CONTRACT_TITLE_CHANGED',
      },
      {
        label: 'Work anniversary',
        category: TRIGGER_CATEGORY.PEOPLE,
        description: 'Triggered X days before or after the worker anniversary',
        value: 'WORK_ANNIVERSARY',
        occurrence: { type: TRIGGER_OCCURRENCE.RANGE },
      },
      {
        label: 'Contract start',
        value: 'CONTRACT_START_DATE',
        description:
          'Triggered X days before or after the contract’s start date',
        category: TRIGGER_CATEGORY.PEOPLE,
        occurrence: { type: TRIGGER_OCCURRENCE.RANGE },
      },
      {
        label: 'Birthday',
        value: 'WORKER_BIRTHDAY',
        description: 'Triggered X days before or after the worker’s birthday',
        category: TRIGGER_CATEGORY.PEOPLE,
        occurrence: { type: TRIGGER_OCCURRENCE.RANGE },
      },
    ],
  },
  {
    label: 'Time off',
    icon: (props) => <Clock {...props} />,
    options: [
      {
        label: 'Time off approved',
        value: 'TIME_OFF_APPROVED',
        description: 'When a time off request is approved',
        category: TRIGGER_CATEGORY.TIME_OFF,
      },
      {
        label: 'Upcoming time off',
        value: 'TIME_OFF_START_DATE',
        description:
          'Triggered X days before or after the start date of an approved time off request',
        category: TRIGGER_CATEGORY.TIME_OFF,
        occurrence: { type: TRIGGER_OCCURRENCE.RANGE },
      },
      {
        label: 'Ending time off',
        value: 'TIME_OFF_END_DATE',
        description:
          'Triggered X days before or after the end date of an approved time off request',
        category: TRIGGER_CATEGORY.TIME_OFF,
        occurrence: { type: TRIGGER_OCCURRENCE.RANGE },
      },
      {
        label: 'Pending time off approval',
        category: TRIGGER_CATEGORY.TIME_OFF,
        value: 'TIME_OFF_PENDING',
        description:
          'When a time off request remains in pending status for more than X days',
        occurrence: {
          type: TRIGGER_OCCURRENCE.AFTER_DAYS,
          key: 'minutesLong',
        },
      },
      {
        label: 'Negative time off balance',
        category: TRIGGER_CATEGORY.TIME_OFF,
        value: 'TIME_OFF_NEGATIVE_BALANCE',
        description:
          'When the time off balance remains negative for more than X days',
        occurrence: {
          type: TRIGGER_OCCURRENCE.AFTER_DAYS,
          key: 'minutesLong',
        },
      },
    ],
  },
  {
    label: 'Payment',
    icon: (props) => <CreditCard {...props} />,
    options: [
      {
        label: 'Pending due payment approval',
        value: 'PAYMENT_PENDING_APPROVAL',
        category: TRIGGER_CATEGORY.PAYMENT,
        description:
          'When one or more due payments remain pending approval for more than X days',
        occurrence: {
          type: TRIGGER_OCCURRENCE.PENDING_DAYS,
          key: 'minutesLong',
        },
      },
      {
        label: 'Paid payment',
        category: TRIGGER_CATEGORY.PAYMENT,
        value: 'PAYMENT_PAID',
        description: 'When a company transaction status changes to paid',
      },
      {
        label: 'Pending overdue payment',
        category: TRIGGER_CATEGORY.PAYMENT,
        value: 'PAYMENT_OVERDUE',
        description:
          'When one or more payments remain overdue for more than X days',
        occurrence: {
          type: TRIGGER_OCCURRENCE.PENDING_DAYS,
          key: 'minutesLong',
        },
      },
      {
        label: 'New adjustment',
        category: TRIGGER_CATEGORY.PAYMENT,
        value: 'PAYMENT_ADJUSTMENT',
        apiValue: 'PAYMENT_ADJUSTMENT',
        description:
          'When an adjustment is submitted with an amount greater than or equal to a specified value',
        filter: {
          key: TRIGGER_FILTER.PAYMENT_ADJUSTMENT_AMOUNT,
        },
      },
    ],
  },
  {
    label: 'Expenses',
    icon: (props) => <Money {...props} />,
    options: [
      {
        label: 'Expense approved',
        value: 'EXPENSE_APPROVED',
        description: 'When an expense status changes to approved',
        category: TRIGGER_CATEGORY.EXPENSES,
      },
      {
        label: 'New Expense',
        value: 'NEW_EXPENSE',
        apiValue: 'EXPENSE_PENDING_APPROVAL',
        description:
          'When an expense is submitted with an amount greater than or equal to a specified value',
        category: TRIGGER_CATEGORY.EXPENSES,
        filter: {
          key: TRIGGER_FILTER.EXPENSE_AMOUNT,
        },
      },
      {
        label: 'Pending expense approval',
        value: 'EXPENSE_PENDING_APPROVAL',
        description:
          'When an expense remains in pending status for more than X days',
        category: TRIGGER_CATEGORY.EXPENSES,
        occurrence: {
          type: TRIGGER_OCCURRENCE.PENDING_DAYS,
          key: 'minutesLong',
        },
      },
    ],
  },
  {
    label: 'Submitted works',
    icon: (props) => <Hammer {...props} />,
    options: [
      {
        label: 'New work',
        value: 'NEW_WORK_SUBMITTED',
        category: TRIGGER_CATEGORY.WORK,
        description:
          'When a worker submits work with an amount greater than or equal to a specified value',
        apiValue: 'WORK_SUBMITTED',
        filter: {
          key: TRIGGER_FILTER.WORK_AMOUNT,
        },
      },
      {
        label: 'Pending work approval',
        value: 'WORK_SUBMITTED',
        category: TRIGGER_CATEGORY.WORK,
        description:
          'When a submitted work remains in pending status for more than X days.',
        occurrence: {
          type: TRIGGER_OCCURRENCE.PENDING_DAYS,
          key: 'minutesLong',
        },
      },
    ],
  },
  {
    label: 'Documents',
    icon: (props) => <FileDoc {...props} />,
    options: [
      {
        label: 'Pending worker document',
        value: 'PENDING_WORKER_DOCUMENT',
        description:
          'When the document requested from the worker remains in pending status for more than X days',
        category: TRIGGER_CATEGORY.DOCUMENTS_SUBMISSIONS,
        occurrence: {
          type: TRIGGER_OCCURRENCE.PENDING_DAYS,
          key: 'minutesLong',
        },
      },
      {
        label: 'Pending client document',
        value: 'PENDING_CLIENT_DOCUMENT',
        description:
          'When the document requested from the Client remains in pending status for more than X days',
        category: TRIGGER_CATEGORY.DOCUMENTS_SUBMISSIONS,
        occurrence: {
          type: TRIGGER_OCCURRENCE.PENDING_DAYS,
          key: 'minutesLong',
        },
      },
    ],
  },
]

export const actionTypes = [
  { label: 'Email', value: ACTION_TYPE.EMAIL },
  { label: 'Slack', value: ACTION_TYPE.SLACK },
]

export const triggerActionType = [
  {
    label: 'Send an email',
    value: ACTION_TYPE.EMAIL,
    description: 'Send a custom email',
    icon: <Envelope size={25} className='tw-mr-3' />,
  },
  {
    label: 'Send on Slack',
    value: ACTION_TYPE.SLACK,
    description: 'Send a custom message through Slack',
    icon: <SlackLogo size={25} className='tw-mr-3' />,
  },
]

export const emailRecipientUserType = [
  { label: 'Contract Worker', value: 'CONTRACT_WORKER' },
  { label: 'Contract Direct Manager', value: 'CONTRACT_DIRECT_MANAGER' },
  { label: 'Contract Creator', value: 'CONTRACT_CREATOR' },
  { label: 'Contract Approvers', value: 'CONTRACT_APPROVERS' },
]

export const statuses = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
  { label: 'Draft', value: 'draft' },
]

export const suggestedTemplate = {
  name: 'Custom automation',
  buttonText: 'Create your own',
  category: 'Featured',
  custom: true,
  _id: 'custom-automation',
}

export const schema = Yup.object().shape({
  steps: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string().required('Type is required'),
        actionType: Yup.mixed().when(['type', '$shouldValidate'], {
          is: (type, shouldValidate) => type === 'action' && shouldValidate,
          then: () => Yup.object().required('This field is required'),
          otherwise: () => Yup.mixed().notRequired(),
        }),
        event: Yup.mixed().when(['type', '$shouldValidate'], {
          is: (type, shouldValidate) => type === 'trigger' && shouldValidate,
          then: () => Yup.object().required('This field is required'),
          otherwise: () => Yup.mixed().notRequired(),
        }),

        filters: Yup.object().when('event', ([event], schema) => {
          const key = event?.filter?.key
          if (key) {
            return schema.required().concat(
              Yup.object().shape({
                [key]: Yup.number()
                  .transform((value) => (Number.isNaN(value) ? null : value))
                  .required(`This field is required`),
              }),
            )
          }
          return schema
        }),
        recipients: Yup.array()
          .of(Yup.string().required())
          .when(['actionType.value', '$shouldValidate'], {
            is: (value, shouldValidate) =>
              [ACTION_TYPE.EMAIL, ACTION_TYPE.SLACK].includes(value) &&
              shouldValidate,
            then: () =>
              Yup.array()
                .min(
                  1,
                  'Recipients must have more than one element for action type',
                )
                .required('This field is required'),
            otherwise: () => Yup.array().notRequired(),
          }),
        subject: Yup.string().when(['actionType.value', '$shouldValidate'], {
          is: (value, shouldValidate) =>
            value === ACTION_TYPE.EMAIL && shouldValidate,
          then: () =>
            Yup.string()
              .required('This field is required')
              .min(1, 'Must be more than 1 character'),
          otherwise: () => Yup.string().notRequired(),
        }),
        connection: Yup.mixed().when(['actionType.value', '$shouldValidate'], {
          is: (value, shouldValidate) =>
            value === ACTION_TYPE.SLACK && shouldValidate,
          then: () => Yup.object().required('This field is required'),
          otherwise: () => Yup.mixed().notRequired(),
        }),
        body: Yup.string().when(['actionType.value', '$shouldValidate'], {
          is: (value, shouldValidate) =>
            [ACTION_TYPE.EMAIL, ACTION_TYPE.SLACK].includes(value) &&
            shouldValidate,
          then: () =>
            Yup.string()
              .required('This field is required')
              .min(1, 'Must be more than 1 character'),
          otherwise: () => Yup.string().notRequired(),
        }),
        numberOfDays: Yup.string().when('type', {
          is: 'delay',
          then: () =>
            Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .min(1, 'The number of days should be more than 0 days')
              .max(30, 'The number of days should be less than 30 days')
              .nullable()
              .when('$shouldValidate', {
                is: true,
                then: (s) =>
                  s.required('Number of days is required for delay type'),
              }),
          otherwise: () => Yup.string().notRequired(),
        }),
        occurrenceDays: Yup.string().when('type', {
          is: 'trigger',
          then: () =>
            Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .max(30, 'The number of days should be less than 30 days')
              .when('event', {
                is: (event) => {
                  const value = event?.value
                  const filter = event?.filter
                  return (
                    (value === 'EXPENSE_PENDING_APPROVAL' ||
                      value === 'WORK_PENDING_APPROVAL') &&
                    isNill(filter)
                  )
                },
                then: (schema) => schema.required('This field is required'),
              }),
          otherwise: () => Yup.string().notRequired(),
        }),
      }),
    )
    .test(
      'last-step-delay',
      'Number of days is invalid for the last step',
      function (steps) {
        if (!steps || !Array.isArray(steps) || steps.length === 0) {
          return true
        }
        const lastStep = steps[steps.length - 1]
        if (lastStep.type === 'delay') {
          return this.createError({
            path: `steps[${steps.length - 1}].numberOfDays`,
            message: 'Delay can not be at the end',
          })
        }

        return true
      },
    ),
})

export const defaultValue = {
  steps: [{ type: 'trigger' }, { type: 'action', actionType: null }],
  name: 'Untitled Workflow',
  isPublished: false,
  shouldValidate: false,
  description: '',
}

export const slackRecipients = {
  directs: {
    label: 'Direct message',
    icon: (props) => <At {...props} />,
    options: [],
  },
  channels: {
    label: 'Channels',
    icon: (props) => <Hash {...props} />,
    options: [],
  },
}

export const slackConnections = {
  label: 'Slack Connections',
  icon: (props) => <SlackLogo {...props} />,
  options: [],
}

export const automationOnboardingCards = [
  {
    title: 'Trigger',
    description: 'Contract signed',
    leftIcon: Signature,
    rightIcon: Robot,
    className: '!tw-w-[60%]',
    scale: 1,
  },
  {
    title: 'Delay',
    description: 'Wait for 2 days',
    leftIcon: ClockCountdown,
    rightIcon: ClockCountdown,
    className: '!tw-w-[65%]',
    scale: 1.1,
  },
  {
    title: 'Trigger',
    description: 'Contract signed',
    leftIcon: SlackLogo,
    rightIcon: BellSimpleRinging,
    className: '!tw-w-[75%] !tw-border-2 !tw-border-green !tw-mb-1',
    hasBorder: false,
    scale: 1.2,
  },
]

export const automationOnboardingFeatures = [
  {
    title: 'Based on rules',
    icon: Funnel,
  },
  {
    title: 'For the whole team',
    icon: UsersThree,
  },
  {
    title: 'On the right time',
    icon: Clock,
  },
]

export const automationLearnMoreLink =
  'https://help.remotepass.com/en/articles/10021964-Automations'

export const minutesInDay = 60 * 24

export const maximumDelaysCount = 3

export const maximumActionsCount = 4
