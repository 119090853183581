import React, { createContext, useContext, useMemo } from 'react'
import { useFetch } from '../../../../../helpers/hooks'
import { getOwners } from '../../../../../services/api-automations'
import { keyBy } from 'lodash'

export const OwnersContext = createContext({
  isLoading: true,
  owners: {},
})

export function OwnersProvider(props) {
  const { children } = props
  const { data = [], isLoading } = useFetch({
    autoFetch: true,
    action: getOwners,
  })

  const owners = useMemo(() => (data?.length ? keyBy(data, 'id') : {}), [data])

  return (
    <OwnersContext.Provider value={{ isLoading, owners }}>
      {children}
    </OwnersContext.Provider>
  )
}

export function useOwners() {
  return useContext(OwnersContext)
}
