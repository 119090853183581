import { ArrowLeft } from '@phosphor-icons/react'
import { t } from 'i18next'
import React from 'react'
import { Link, Route, Switch, useHistory, useParams } from 'react-router-dom'

import {
  integrationDataMap,
  integrationLoaderElement,
  RpIntegrationProvider,
} from '.'
import Head from '../../../components/head'
import { LinkOut } from '../../../components/ui/link-out'
import NavTabsV2 from '../../../components/ui/nav-tabs/nav-tabs-v2'
import { useFetch } from '../../../helpers/hooks'
import { getIntegration } from '../../../services/api-integrations'
import { IntegrationSyncStatus } from './components/integration-sync-status'
import { getHelpLink } from './get-integration-help-link'
import {
  IntegrationReviewAccounts,
  IntegrationReviewContractors,
  IntegrationReviewCostCenters,
  IntegrationReviewInvoices,
} from './integration-review-tabs'

export function IntegrationReview() {
  const history = useHistory()
  const params = useParams()

  if (!params?.integrationKey) {
    history.push('/settings/integrations')
  }

  return (
    <div className='page-content tw-min-h-[calc(100svh-58px)]'>
      <Head title={t('Integration Review')} />

      <RpIntegrationProvider>
        <IntegrationReviewPage />
      </RpIntegrationProvider>
    </div>
  )
}

const integrationTabs = (t) => [
  {
    key: 'accounts',
    label: t('Accounts'),
    Component: IntegrationReviewAccounts,
  },
  {
    key: 'contractors',
    label: t('Contractors'),
    Component: IntegrationReviewContractors,
  },
  {
    key: 'invoices',
    label: t('Invoices'),
    Component: IntegrationReviewInvoices,
  },
  {
    key: 'cost_centers',
    label: t('Cost Centers'),
    Component: IntegrationReviewCostCenters,
    show: ({ integrationKey }) => integrationKey === 'netsuite',
  },
]

function IntegrationReviewPage() {
  const history = useHistory()

  const params = useParams()

  const { data: customIntegrationData, isLoading: customIntegrationLoading } =
    useFetch(
      {
        action: getIntegration,
        autoFetch: !!params.integrationKey,
        body: { integrationKey: params.integrationKey },
      },
      [params.integrationKey],
    )

  const loading = customIntegrationLoading

  const integration =
    customIntegrationData?.code === params.integrationKey
      ? integrationDataMap(customIntegrationData)
      : customIntegrationData

  const helpLink = getHelpLink(integration?.key)

  if (loading) {
    return integrationLoaderElement
  }

  const tabs = integrationTabs(t).filter(
    (tab) => !tab.show || tab.show?.({ integrationKey: params.integrationKey }),
  )

  const entityNameAndId = [
    integration?.organizationName,
    integration?.organizationId,
  ]
    .filter(Boolean)
    .join(' - ')

  return (
    <>
      <Head
        title={[
          !integration.connection?.name
            ? null
            : `${integration.connection?.name} |`,
          'Integration Review',
        ]
          .filter(Boolean)
          .join(' ')}
      />

      <div className='tw-flex tw-items-center tw-gap-3'>
        <Link
          to='/settings/integrations'
          className='tw-text-current rtl:tw-rotate-180'
        >
          <ArrowLeft size={24} />
        </Link>
        <h2 className='tw-mb-0'>{integration.connection?.name}</h2>
        {!helpLink ? null : (
          <LinkOut href={helpLink.href} title={helpLink.title} />
        )}

        <div className='tw-ms-auto tw-flex tw-flex-col tw-items-end'>
          <IntegrationSyncStatus integration={integration} />
          <p className='tw-mb-0 tw-mt-1'>{entityNameAndId}</p>
        </div>
      </div>

      <NavTabsV2
        options={tabs.map((tab) => ({ ...tab, value: tab.key }))}
        activeOption={params.tab}
        onClickOption={(tab) => {
          history.push(
            `/settings/integrations/${params.integrationKey}/${tab.value}`,
          )
        }}
        className='tw-mt-4 !tw-border-b-transparent'
        itemLinkClassName='!tw-py-3 md:!tw-py-3'
      />

      <Switch>
        {tabs.map((tab) => (
          <Route
            key={tab.key}
            path={`/settings/integrations/${params.integrationKey}/${tab.key}`}
            component={() => (
              <tab.Component
                integrationKey={params.integrationKey}
                integration={integration}
              />
            )}
          />
        ))}
      </Switch>
    </>
  )
}
