import { t } from 'i18next'
import { CaretDown, Coins, FileArrowDown, Swap } from '@phosphor-icons/react'
import cx from 'classnames'
import { format } from 'date-fns'
import React, { useEffect } from 'react'
import Dropzone, { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'
import { Col, Row, Table } from 'reactstrap'
import toastr from 'toastr'

import { Avatar, cn } from 'ui'
import Button from '../../components/ui/button'
import Shimmer from '../../components/ui/shimmer'
import { userTypes } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import { uploadYourInvoice } from '../../services/api'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import { getFullName } from '../../utils/get-full-name'
import TabEmpty from '../Contract/components/tab/tab-empty'
import { MilestoneSideMenu } from '../../components/invoices-tab'

export function InvoicesTable({
  orders,
  dataLoading,
  isAdmin = false,
  expanded,
  setExpanded,
  handleDownloadInvoice,
}) {
  const user = useSelector((state) => state?.Account?.user)

  return (
    <div className='table-responsive'>
      {dataLoading ? (
        <div className='tw-flex tw-w-full tw-flex-col tw-gap-2 [--s-height:197px] md:[--s-height:57px]'>
          <Shimmer width='100%' height='var(--s-height)' />
          <Shimmer width='100%' height='var(--s-height)' />
          <Shimmer width='100%' height='var(--s-height)' />
        </div>
      ) : orders?.length > 0 ? (
        <>
          <div className='tw-flex tw-flex-col tw-gap-4 md:tw-hidden'>
            {orders?.map((order) => (
              <InvoiceCard
                key={order.id}
                handleDownloadInvoice={handleDownloadInvoice}
                invoice={order}
                isAdmin={isAdmin}
              />
            ))}
          </div>

          <Table
            className={cn(
              'table-centered table-nowrap !tw-mb-0 tw-hidden tw-text-start md:tw-table',
            )}
          >
            <thead>
              <tr style={{ borderTop: 'hidden' }}>
                <th />

                <th style={{ width: '200px' }} className='!tw-px-6'>
                  {t('Invoice', { count: 1 })}
                </th>

                {isAdmin ? null : (
                  <th className='!tw-px-6'>
                    {t(
                      user?.type === userTypes.COMPANY
                        ? 'Contractor'
                        : 'Client',
                    )}
                  </th>
                )}

                <th className='!tw-px-6'>{t('Total')}</th>
                <th className='!tw-px-6'>{t('Created on')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {orders?.map((invoice, key) => (
                <InvoiceLine
                  invoice={invoice}
                  key={'_order_' + key}
                  isAdmin={isAdmin}
                  onExpand={() => {
                    if (expanded === key) {
                      setExpanded(-1)
                    } else {
                      setExpanded(key)
                    }
                  }}
                  expanded={expanded === key}
                />
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <TabEmpty
          subtitle='All your invoices will be shown here after each payment'
          title={t('No Invoices')}
          icon={<Coins size={240} color='var(--primary)' weight='duotone' />}
        />
      )}
    </div>
  )
}

function InvoiceLine({ invoice, onExpand, expanded, isAdmin }) {
  const user = useSelector((state) => state?.Account?.user)
  const formatter = getCurrencyFormatter(invoice?.currency?.code)

  const partner =
    user?.type === userTypes.COMPANY ? invoice.contractor : invoice.client

  const upload = useFetch({
    action: uploadYourInvoice,
    onError: (err) => toastr.error(err),
    onComplete: () => {
      toastr.success(t('Invoice replaced successfully'))
    },
  })

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  })

  useEffect(() => {
    if (acceptedFiles[0]) {
      upload.startFetch({
        invoice_id: invoice?.id,
        file: acceptedFiles[0],
      })
    }
  }, [acceptedFiles])

  const invoicePartner =
    user?.type === userTypes.CONTRACTOR
      ? invoice.company_name
      : [partner?.first_name, partner?.last_name].filter(Boolean).join(' ')

  return (
    <>
      <tr>
        <th className='!tw-px-6 tw-text-center'>
          {/* @todo: Use the expand icon component */}
          {invoice?.works?.length !== 0 ? (
            <button
              className={cn(
                'tw-relative tw-flex tw-items-center tw-justify-center',
                { expanded },
              )}
              onClick={onExpand}
              style={{
                '--size': '20px',
                width: 'var(--size)',
                height: 'var(--size)',
              }}
            >
              <CaretDown size={16} />
            </button>
          ) : null}
        </th>
        <td className='!tw-px-4'>{invoice.invoice_ref}</td>
        {isAdmin ? null : (
          <td className='!tw-px-4'>
            <div className='tw-flex tw-flex-nowrap tw-items-center'>
              <Avatar
                name={
                  invoice?.type === 'fee'
                    ? 'RemotePass'
                    : user?.type === userTypes.CONTRACTOR
                      ? invoice?.company_name
                      : partner?.first_name
                }
                photo={
                  user?.type === userTypes.CONTRACTOR
                    ? invoice?.company_logo
                    : partner?.photo
                }
                flag={user?.type === userTypes.COMPANY && partner?.flag}
              />

              <div
                className='tw-ms-2 tw-text-sm tw-font-bold tw-text-text-80'
                translate='no'
              >
                {invoice?.type === 'fee'
                  ? 'RemotePass'
                  : invoicePartner?.length > 17
                    ? invoicePartner?.slice(0, 17) + '...'
                    : invoicePartner || ''}
              </div>
            </div>
          </td>
        )}
        <td className='!tw-px-4'>{formatter.format(invoice?.total)}</td>
        <td className='!tw-px-4'>
          {format(new Date(invoice.invoice_date * 1000), 'MM/dd/yyyy HH:mm')}
        </td>
        <td className='!tw-px-6'>
          <MilestoneSideMenu
            invoice={invoice}
            onReplace={open}
            isContractor={user?.type === userTypes.CONTRACTOR}
            isReplacing={upload.isLoading}
            isAdmin={isAdmin}
          />
        </td>
      </tr>

      <div className='container d-none'>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
        </div>
      </div>

      {expanded && (
        <>
          {invoice?.works?.map((work) => {
            return (
              <tr className='tw-bg-surface-20' key={'_trans' + work?.id}>
                <td className='tw-h-11 !tw-p-0'>
                  <div className='tw-h-full tw-border-s-4 tw-border-primary tw-px-4' />
                </td>

                <td colSpan={2} className='!tw-px-4'>
                  <p className='tw-mb-2 tw-font-bold'>{work?.name}</p>

                  <p className='tw-mb-0'>{work?.details}</p>

                  {work.attributes?.length <= 0 ? null : (
                    <div className='tw-mt-2 tw-flex tw-flex-col tw-gap-3 tw-border-l-2 tw-border-surface-30 tw-py-1 tw-pl-2'>
                      {work.attributes.map((work) => {
                        return (
                          <div key={`attr-${work?.name}`}>
                            <span className='tw-text-text-60'>
                              {work?.name}
                              {':'}{' '}
                            </span>
                            <span>{work?.value}</span>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </td>

                <td className='!tw-px-4'>{formatter.format(work?.amount)}</td>

                <td />
                {isAdmin ? null : <td />}
              </tr>
            )
          })}
        </>
      )}
    </>
  )
}

function InvoiceCard({ invoice, isAdmin, handleDownloadInvoice }) {
  const user = useSelector((state) => state?.Account?.user)
  const partner =
    user?.type === userTypes.COMPANY ? invoice.contractor : invoice.client

  const partnerName = getFullName(partner)

  const formatter = getCurrencyFormatter(invoice?.currency?.code)

  const upload = useFetch({
    action: uploadYourInvoice,
    onError: (err) => toastr.error(err),
  })

  return (
    <div className='pb-3 bg-white border border-gray-b rounded'>
      <div className='d-flex justify-content-between align-items-center border-bottom p-3'>
        <p
          className='text-dark mb-0 rp-font-bold'
          style={{ fontWeight: 'bold' }}
        >
          {t('Invoice', { count: 1 })}

          {invoice.invoice_ref}
        </p>
      </div>

      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='tw-mb-0'>
          {isAdmin || !partnerName ? 'Created on' : partnerName}
        </p>
        <p className='tw-mb-0 tw-font-semibold'>
          {format(new Date(invoice.invoice_date * 1000), 'MM/dd/yyyy HH:mm')}
        </p>
      </div>

      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='tw-mb-0'>{t('Total')}</p>
        <p className='tw-mb-0 tw-font-semibold'>
          {formatter.format(invoice?.total)}
        </p>
      </div>

      {isAdmin ? null : (
        <Row className='m-0 px-3 pt-3'>
          <Col
            className={cx('m-0 p-0', {
              'pr-1': user?.type === userTypes.CONTRACTOR,
            })}
          >
            <Button
              type='button'
              onClick={() => handleDownloadInvoice?.(invoice)}
              color='light'
              outline
              className='!tw-border-surface-30'
              icon={<FileArrowDown weight='bold' />}
              block
            >
              {t('Invoice', { count: 1 })}
            </Button>
          </Col>

          {user?.type === userTypes.CONTRACTOR && (
            <Col className='m-0 p-0 pl-1'>
              <UploadArea
                onFileChanges={async (files) => {
                  if (files[0]) {
                    if (files[0].size > 2000000) {
                      toastr.error(t('The file may not be greater than 2MB'))
                    } else {
                      upload.startFetch({
                        invoice_id: invoice?.id,
                        file: files[0],
                      })
                    }
                  }
                }}
              >
                <Button
                  as='div'
                  type='button'
                  loading={upload.isLoading}
                  color='light'
                  outline
                  className='!tw-border-surface-30'
                  icon={<Swap weight='bold' />}
                  block
                >
                  {t('Replace')}
                </Button>
              </UploadArea>
            </Col>
          )}
        </Row>
      )}
    </div>
  )
}

function UploadArea({ onFilesDropped, onFileChanges, children }) {
  return (
    <Dropzone onDrop={onFilesDropped}>
      {({ getRootProps, getInputProps }) => (
        <div style={{ zIndex: 99999999999 }}>
          <div {...getRootProps()} className='no-outline'>
            <input
              className='no-outline'
              {...getInputProps()}
              onChange={async (e) => {
                onFileChanges(e.target.files)
              }}
            />

            {children}
          </div>
        </div>
      )}
    </Dropzone>
  )
}
