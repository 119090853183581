import { yupResolver } from '@hookform/resolvers/yup'
import React, { forwardRef, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledInput from '../../../../components/ControlledInput'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import { contractTypes } from '../../../../helpers/enum'
import { useFetch } from '../../../../helpers/hooks'
import { getTemplateList } from '../../../../services/api'
import LabelContent from '../../CreateContract/components/label-content'
import ContractSelect, {
  CONTRACT_TYPE_KEY,
  CONTRACT_TYPE_MAP,
} from '../../CreateContract/ContractSelect'
import { ContractorLabel } from '../../CreateContract/create-contract-v3'

function getContractMedia({ amendData } = {}) {
  if (amendData?.template) {
    return CONTRACT_TYPE_KEY.YOUR_TEMPLATE
  }
  if (amendData?.rw_file) {
    return CONTRACT_TYPE_KEY.RP_TEMPLATE
  }
  if (amendData?.file) {
    return CONTRACT_TYPE_KEY.UPLOADED
  }

  return null
}

const ComplianceForm = forwardRef(function ComplianceForm(
  { onSubmit, data, isAdmin, amendData, onUploadStatusChanged, isAmend },
  ref,
) {
  const contractorTypes = useSelector(
    (state) => state.Layout.staticData?.contractor_types,
  )

  const type = data?.type

  const { data: templates } = useFetch({
    action: getTemplateList,
    autoFetch: !isAdmin,
  })

  const [file, setFile] = useState(null)
  const [contractMedia, setContractMedia] = useState(() => {
    if (amendData?.template_type) {
      return CONTRACT_TYPE_MAP[amendData?.template_type]
    }

    if (amendData?.template || amendData?.rw_file || amendData?.file) {
      const amendTemplateValue = getContractMedia({ amendData })

      if (amendTemplateValue) {
        return amendTemplateValue
      }
    }

    return data?.template_type
      ? CONTRACT_TYPE_MAP[data?.template_type]
      : CONTRACT_TYPE_KEY.RP_TEMPLATE
  })
  const [template, setTemplate] = useState(null)

  const labelOptions = contractorTypes?.map((e) => ({
    value: e.id,
    label: e.name,
  }))
  const [label, setLabel] = useState(labelOptions[0])

  const dataTemplate = templates?.find((e) => e?.id === data?.template_id)

  useEffect(() => {
    setTemplate(
      dataTemplate
        ? { value: dataTemplate?.id, label: dataTemplate?.name }
        : amendData?.template,
    )
  }, [amendData?.template, dataTemplate])

  useEffect(() => {
    return () => {
      setFile(null)
      setTemplate(null)
    }
  }, [contractMedia])

  useEffect(() => {
    if (contractorTypes) {
      const initLabel = contractorTypes?.find(
        (e) =>
          e.name === amendData?.contractor_name ||
          e.name === data?.contractor_name,
      )
      if (initLabel) {
        setLabel({ value: initLabel?.id, label: initLabel?.name })
      }
    }
  }, [amendData?.contractor_name, data?.contractor_name, contractorTypes])

  const { handleSubmit, control } = useForm({
    defaultValues: {
      notice_period: amendData?.notice_period || data?.notice_period || 15,
    },
    resolver: yupResolver(
      yup.object().shape({
        notice_period: yup
          .number()
          .min(0)
          .required('Required')
          .label('Notice period')
          .typeError('Notice period must be a number'),
      }),
    ),
  })

  function handleOnSubmit(values) {
    if (
      contractMedia === CONTRACT_TYPE_KEY.UPLOADED &&
      file === null &&
      amendData?.file === null
    ) {
      toastr.error('Please upload the contract file')
      return
    }

    if (
      contractMedia === CONTRACT_TYPE_KEY.YOUR_TEMPLATE &&
      template === null
    ) {
      toastr.error('Please select a template')
      return
    }

    onSubmit(
      { ...values, contractor_name: label?.label },
      file,
      contractMedia,
      contractMedia ? template : null,
    )
  }

  return (
    <form ref={ref} onSubmit={handleSubmit(handleOnSubmit)}>
      {!isAmend && (
        <ContractSelect
          onContractChanged={(key) => {
            setContractMedia(key)
            if (key === CONTRACT_TYPE_KEY.RP_TEMPLATE) {
              setFile(null)
            }
          }}
          value={contractMedia}
          onFileUploaded={setFile}
          templates={templates}
          template={template}
          onTemplateSelected={setTemplate}
          onUploadStatusChanged={onUploadStatusChanged}
          uploaded={data?.uploaded}
          amendData={amendData}
        />
      )}

      <div className='tw-mt-4 tw-grid tw-gap-4 md:tw-grid-cols-2'>
        {type !== contractTypes.FULL_TIME && (
          <div>
            <label>
              <LabelContent required>
                Notice period (days) to terminate the contract
              </LabelContent>
            </label>
            <InputGroup>
              <ControlledInput
                control={control}
                name='notice_period'
                id='notice_period'
                type='number'
                min={0}
                className='!tw-rounded-r-none'
                wrapperClassName='tw-flex-1'
              />
              <InputGroupAddon addonType='append'>
                <InputGroupText className='bg-transparent text-muted'>
                  Days
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </div>
        )}
        {type !== contractTypes.FULL_TIME && !isAmend && (
          <CustomSelect
            label={<ContractorLabel />}
            onChange={setLabel}
            value={label}
            options={labelOptions}
          />
        )}
      </div>
    </form>
  )
})

export default ComplianceForm
