import { Info, PencilSimple } from '@phosphor-icons/react'
import { t } from 'i18next'
import React, { useState } from 'react'
import { UncontrolledTooltip } from 'reactstrap'

import { cn } from 'ui'
import Toggle from '../../../../../components/Forms/Toggle/Toggle'
import IconButton from '../../../../../components/ui/icon-button'
import InputFeedback from '../../../../../components/ui/input-feedback'
import Shimmer from '../../../../../components/ui/shimmer'
import { getCurrencyFormatter } from '../../../../../utils/formatters/currency'
import { fieldKeys } from '../../create-contract-v3'
import {
  PRORATA_CALCULATION_TYPE,
  PRORATA_TYPE,
  WORKING_DAYS,
} from './constants'
import { useProrataState } from './prorata-context'
import ProrataEdit from './prorata-edit'

export function ProrataSettings({
  currency,
  dates,
  calculationType,
  workDays,
  onSave,
  onEditClick,
  onEditClose,
  showEdit,
  error,
}) {
  const { firstPaymentProrata } = useProrataState()

  return (
    <>
      <ProrataToggle />

      {!firstPaymentProrata ? null : (
        <BottomSection
          onEditClick={onEditClick}
          onEditClose={onEditClose}
          currency={currency}
          dates={dates}
          calculationType={calculationType}
          workDays={workDays}
          onSave={onSave}
          showEdit={showEdit}
        />
      )}

      {error && <InputFeedback className='tw-mt-1'>{error}</InputFeedback>}
    </>
  )
}

function ProrataToggle() {
  const { firstPaymentProrata, setFirstPaymentProrata } = useProrataState()

  return (
    <div
      className={cn(
        'tw-flex tw-items-center tw-gap-2 tw-border tw-border-surface-30 tw-p-4',
        firstPaymentProrata ? 'tw-rounded-t' : 'tw-rounded',
      )}
    >
      <span className='tw-text-sm tw-font-semibold'>
        {t('Pro-rata on first payment')}
      </span>

      <Info size={20} className='tw-fill-secondary' id='prorataInfo' />
      <UncontrolledTooltip target='prorataInfo'>
        {t('prorataTooltipText')}
      </UncontrolledTooltip>

      <Toggle
        change={setFirstPaymentProrata}
        check={firstPaymentProrata}
        marginRight=''
        className='tw-ms-auto'
      />
    </div>
  )
}

function BottomSection({
  onEditClick,
  onEditClose,
  currency,
  dates,
  calculationType,
  workDays,
  onSave,
  showEdit,
}) {
  const [customAmount, setCustomAmount] = useState()

  const {
    gettingProrataData,
    prorataData,
    [fieldKeys.proRataAmount]: proRataAmount,
    prorataTab,
    prorataDays,
  } = useProrataState()

  const formatter = getCurrencyFormatter(currency?.code)

  if (gettingProrataData && !showEdit) {
    return <Shimmer className='!tw-w-full tw-rounded-t-none' height={54} />
  }

  const prorataDaysLabel =
    prorataDays === PRORATA_CALCULATION_TYPE.CALENDAR_DAYS
      ? 'Calendar day(s)'
      : 'Working day(s)'

  const workDaysLabel =
    prorataDays !== PRORATA_CALCULATION_TYPE.WORKING_DAYS
      ? null
      : workDays.value === WORKING_DAYS.MONDAY_FRIDAY
        ? 'Monday to Friday'
        : workDays.value === WORKING_DAYS.SUNDAY_THURSDAY
          ? 'Sunday to Thursday'
          : null

  return (
    <div className='tw-flex tw-rounded-bl tw-rounded-br tw-border tw-border-t-0 tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
      <div className='text-sm tw-flex-1 tw-text-secondary-100'>
        {prorataTab === PRORATA_TYPE.CALCULATED ? (
          <>
            <div>
              {prorataData?.days_worked ?? 0} {prorataDaysLabel}
            </div>
            {!workDaysLabel ? null : (
              <div className='tw-text-xs tw-text-text-60'>{workDaysLabel}</div>
            )}
          </>
        ) : prorataTab === PRORATA_TYPE.FULL_AMOUNT ? (
          'Full cycle amount'
        ) : (
          'Custom amount'
        )}
      </div>

      <span className='tw-me-2 tw-text-sm tw-font-bold'>
        {formatter.format(proRataAmount)}
      </span>
      <IconButton
        icon={<PencilSimple className='tw-fill-primary' size={20} />}
        className='!tw-size-5 !tw-border-0 !tw-bg-transparent'
        type='button'
        onClick={onEditClick}
        loading={gettingProrataData}
        disabled={gettingProrataData || !prorataData}
      />

      {showEdit && (
        <ProrataEdit
          onClose={onEditClose}
          currency={currency}
          dates={dates}
          calculationType={calculationType}
          workDays={workDays}
          onSave={({ amount }) => {
            setCustomAmount(amount)
            onSave(amount)
          }}
          customAmount={customAmount}
        />
      )}
    </div>
  )
}
