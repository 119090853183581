import { useInfiniteQuery } from '@tanstack/react-query'
import cx from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import toastr from 'toastr'

import Head from '../../components/head'
import NavTabsV2 from '../../components/ui/nav-tabs/nav-tabs-v2'
import PageHeading from '../../components/ui/page-heading'
import Shimmer from '../../components/ui/shimmer'
import { assetsStorage } from '../../helpers/config'
import { useFetch } from '../../helpers/hooks'
import { useUrlStateV2 } from '../../helpers/hooks/use-url-state'
import {
  getBillsReviewItems,
  getClientToDoList,
  getContractsReviewItems,
  getDocumentsReviewItems,
  getExpensesReviewItems,
  getInvoicesReviewItems,
  getPaymentsReviewItems,
  getTimeOffsReviewItems,
  getWorksReviewItems,
} from '../../services/api'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import { getErrorMessage } from '../../utils/get-errors'
import { getFullName } from '../../utils/get-full-name'
import { useHasChanged } from '../Activity'
import {
  TODO_TYPE,
  getReviewTypeIcon,
} from '../Activity/center-overview-client'
import { BaseAmount } from '../expenses/components/base-amount'
import { formatDate } from '../time-off/time-off-list'
import { ReviewCenterColumns } from './review-columns'
import { ReviewLayout } from './review-list-layout'
import { DismissWrapper } from '../../components/dismiss-wrapper'
import { BellSimpleRinging } from '@phosphor-icons/react'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import permissions from '../../helpers/permissions'
import { PermissionGuard } from '../../components/permission-guard'

export function getExpenseIcon({ category }) {
  if (!category) {
    return getReviewTypeIcon(TODO_TYPE.expenses, { size: 24 })
  }

  const src = assetsStorage + '/' + category.icon_s3_key

  return (
    <img
      src={src}
      alt={category.name}
      className='tw-size-6'
      style={{
        color: 'black',
        // Primary color to filter css prop
        // Using this tool: https://codepen.io/sosuke/pen/Pjoqqp
        filter:
          'invert(26%) sepia(69%) saturate(6842%) hue-rotate(226deg) brightness(97%) contrast(101%)',
      }}
    />
  )
}
// Backend endpoints to translate
function getAction({ type }) {
  switch (type) {
    case TODO_TYPE.timeOff: {
      return getTimeOffsReviewItems
    }
    case TODO_TYPE.expenses: {
      return getExpensesReviewItems
    }
    case TODO_TYPE.contracts: {
      return getContractsReviewItems
    }
    case TODO_TYPE.works: {
      return getWorksReviewItems
    }
    case TODO_TYPE.documents: {
      return getDocumentsReviewItems
    }
    case TODO_TYPE.payments: {
      return getPaymentsReviewItems
    }
    case TODO_TYPE.invoices: {
      return getInvoicesReviewItems
    }
    case TODO_TYPE.bills: {
      return getBillsReviewItems
    }
    default: {
      return undefined
    }
  }
}

export function getSelectedItemId({ rawItem, type }) {
  switch (type) {
    case TODO_TYPE.bills: {
      return rawItem?.name
    }
    default: {
      return rawItem?.id
    }
  }
}

// Item name should be translated from the backend
function getRenderableItems({ item, type }) {
  const raw = {
    ...item,
    selectedId: getSelectedItemId({ rawItem: item, type }),
  }

  switch (type) {
    case TODO_TYPE.timeOff: {
      return {
        id: item.id,
        left: {
          top: getFullName(item.contractor),
          bottom: t('TodoType.timeOffFromDateToDate', {
            fromDate: item.from_date,
            toDate: item.to_date,
          }),
        },
        right: {
          top: t('TodoType.timeOffDays', { count: parseFloat(item.days) }),
          bottom: '',
        },
        icon: getReviewTypeIcon(type, { size: 24 }),
        raw,
      }
    }

    case TODO_TYPE.expenses: {
      const formatter = getCurrencyFormatter(item?.contract_currency?.code)

      return {
        id: item.id,
        left: { top: item.name, bottom: getFullName(item.contractor) },
        right: {
          top: (
            <>
              {formatter.format(item.amount)}

              <BaseAmount
                baseAmount={item?.base_amount}
                baseCurrency={item?.base_currency}
                contract={{ currency: item?.contract_currency }}
              />
            </>
          ),

          bottom: formatDate(item.date),
        },
        icon: getExpenseIcon({ category: item.category }),
        raw,
      }
    }

    case TODO_TYPE.contracts: {
      return {
        id: item.id,
        left: { top: getFullName(item.contractor), bottom: item?.ref },
        right: { top: item.name, bottom: item.type },
        icon: getReviewTypeIcon(type, { size: 24 }),
        raw,
      }
    }

    case TODO_TYPE.works: {
      return {
        id: item.id,
        left: {
          top: item.name,
          bottom: t('TodoType.workSubmittedBy', {
            submittedBy: item.submitted_by,
          }),
        },
        right: {
          top: item.details,
          bottom: formatDate(item.submitted_at),
        },
        icon: getReviewTypeIcon(type, { size: 24 }),
        raw,
      }
    }

    case TODO_TYPE.documents: {
      return {
        id: item.id,
        left: {
          top: getFullName(item.contractor),
          bottom: '#' + item.reference,
        },
        right: {
          top: item.title,
          bottom: formatDate(item.created_at),
        },
        icon: getReviewTypeIcon(type, { size: 24 }),
        raw,
      }
    }

    case TODO_TYPE.payments: {
      const formatter = getCurrencyFormatter(item?.currency?.code)

      return {
        id: item.id,
        left: { top: getFullName(item.contractor), bottom: item.contract.ref },
        right: {
          top: formatter.format(item.amount),
          bottom: `Due ${formatDate(item.due_date * 1000)}`,
        },
        icon: getReviewTypeIcon(type, { size: 24 }),
        raw,
      }
    }

    case TODO_TYPE.invoices: {
      const formatter = getCurrencyFormatter(item?.currency?.code)

      return {
        id: item.id,
        left: { top: getFullName(item.contractor), bottom: item.ref },
        right: {
          top: formatter.format(item.amount),
          bottom: formatDate(item.submitted_at),
        },
        icon: getReviewTypeIcon(type, { size: 24 }),
        raw,
      }
    }

    case TODO_TYPE.bills: {
      const formatter = getCurrencyFormatter(item.currency.code)
      return {
        id: item.id,
        left: { top: item.name, bottom: item.vendor.name },
        right: {
          top: formatter.format(item.amount),
          bottom: `Due ${formatDate(item.due_date)}`,
        },
        icon: getReviewTypeIcon(type, { size: 24 }),
        raw,
      }
    }

    default: {
      return {
        id: item.id,
        left: { top: '', bottom: '' },
        right: { top: '', bottom: '' },
        icon: getReviewTypeIcon(type, { size: 24 }),
        raw,
      }
    }
  }
}

export function ReviewCenter() {
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )

  const hasCompanyChanged = useHasChanged(company)

  const {
    data: todoList,
    isLoading: gettingTodoList,
    startFetch: refetchTodoItems,
  } = useFetch(
    {
      action: getClientToDoList,
      initResult: { items: [] },
      autoFetch: true,
      onError: (err) => toastr.error(getErrorMessage(err)),
    },
    [hasCompanyChanged],
  )

  const tabItemsToReview = todoList?.items?.map(({ type, count, title }) => ({
    key: type,
    label: title,
    count,
  }))

  const { value: urlStateValue, updater: setUrlState } = useUrlStateV2({
    tab: tabItemsToReview[0]?.key || TODO_TYPE.contracts,
    selectedItem: undefined,
    search_key: '',
    contract_id: '',
    page: 1,
    selecting: undefined,
  })

  const { tab: activeTab, selectedItem, search_key: searchKey } = urlStateValue

  const user = useSelector((state) => state.Account?.user)

  const {
    fetchNextPage,
    isFetchingNextPage,
    data,
    refetch: refetchReviewList,
    status,
  } = useInfiniteQuery({
    queryKey: [user?.token, 'review_items', activeTab, searchKey],
    queryFn: ({ pageParam, signal }) => {
      const actionFunc = getAction({ type: activeTab })
      const userToken = user?.token

      return actionFunc(
        userToken,
        { page: pageParam, search_key: searchKey },
        { signal },
      )
    },
    select: (data) => {
      return {
        pages: data?.pages.map((page) => page.data),
        pageParams: data?.pageParams,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const currentPage = lastPage.data.paginator.current_page
      const hasMorePages = lastPage.data.paginator.has_more_pages

      const itemToSelect = getSelectedItemId({
        rawItem: lastPage.data.data[0],
        type: activeTab,
      })

      if (currentPage === 1 && !selectedItem && !!itemToSelect) {
        setUrlState({ selectedItem: itemToSelect })
      }

      const nextPage = hasMorePages ? currentPage + 1 : undefined

      return nextPage
    },
  })

  const reviewFlatItems = data?.pages?.map((item) => item.data)?.flat()

  const reviewItems = reviewFlatItems?.map((item) => {
    return getRenderableItems({ item, type: activeTab })
  })

  const options = gettingTodoList
    ? [
        { label: <Shimmer height={20} />, value: 1 },
        { label: <Shimmer height={20} />, value: 2 },
        { label: <Shimmer height={20} />, value: 3 },
        { label: <Shimmer height={20} />, value: 4 },
        { label: <Shimmer height={20} />, value: 5 },
      ]
    : tabItemsToReview.map(({ key: value, label, count }) => ({
        value,
        label: (
          <TodoNavItem
            label={label}
            count={count}
            value={value}
            activeTab={activeTab}
          />
        ),
      }))

  return (
    <ReviewLayout.Page className='page-content'>
      <Head title={t('Items to review')} />

      <PageHeading.Title>{t('Items to review')}</PageHeading.Title>

      <PermissionGuard
        permission={[
          permissions.ViewCompanyAutomation,
          permissions.ManageCompanyAutomation,
        ]}
      >
        <DismissWrapper storageKey='review-center-automation'>
          {({ handleDismiss }) => (
            <div className='tw-flex tw-flex-row tw-gap-2 tw-bg-green-10 tw-p-3'>
              <BellSimpleRinging className='tw-text-green' size={20} />
              <span className='tw-flex-1 tw-text-black'>
                {t(
                  'Never miss a beat - Automated reminders keep you informed about contract updates, time off, expenses and document requests',
                )}
              </span>
              <Link
                to='/settings/automations?openTemplates=true'
                className='tw-font-bold tw-text-green'
              >
                {t('Browse templates')}
              </Link>
              <button onClick={handleDismiss}>{t('Dismiss')}</button>
            </div>
          )}
        </DismissWrapper>
      </PermissionGuard>

      <NavTabsV2
        options={options}
        activeOption={activeTab}
        onClickOption={
          gettingTodoList
            ? () => {}
            : (option) => {
                setUrlState({
                  tab: option?.value,
                  selectedItem: undefined,
                  page: 1,
                  search_key: undefined,
                  selecting: undefined,
                })
              }
        }
        className='tw-flex-shrink-0 !tw-flex-nowrap !tw-gap-x-0 tw-overflow-auto tw-rounded tw-bg-white tw-p-px max-lg:tw-pb-1'
        itemLinkClassName='!tw-px-4 tw-text-sm tw-text-text-60 !tw-font-normal tw-transition-colors !tw-flex tw-gap-2'
      />

      <ReviewCenterColumns
        isLoading={status === 'pending'}
        reviewItems={reviewItems}
        refreshReviewList={() => {
          refetchReviewList()
          refetchTodoItems(undefined, false)
        }}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        urlState={urlStateValue}
        setUrlState={setUrlState}
      />
    </ReviewLayout.Page>
  )
}

function TodoNavItem({ label, count, value, activeTab }) {
  return (
    <>
      <span className='tw-h-5'>{label}</span>
      {!count || count === 0 ? null : (
        <span
          className={cx(
            'tw-text-gray-400 tw-inline-flex tw-h-5 tw-min-w-5 tw-items-center tw-justify-center tw-rounded tw-border tw-px-1 tw-text-xs',
            value === activeTab
              ? 'tw-border-primary-100 tw-bg-primary-100 tw-text-white'
              : 'tw-border-surface-30',
          )}
        >
          {count}
        </span>
      )}
    </>
  )
}
