'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { cn } from '../lib/utils/cn';
import { formatOrdinals } from '../lib/utils/format-ordinals';
export function Pagination({ activePage, itemsCountPerPage, totalItemsCount, onChange, pageRangeDisplayed = Math.ceil(totalItemsCount / itemsCountPerPage) < 5
    ? Math.ceil(totalItemsCount / itemsCountPerPage)
    : 5, href, LinkComp = 'a', className, }) {
    const totalPages = Math.ceil(totalItemsCount / itemsCountPerPage);
    const pageNumbers = getPageNumbers({
        activePage,
        pageRangeDisplayed,
        totalPages,
    });
    const items = ['«', '⟨', ...pageNumbers.map(String), '⟩', '»'];
    function handleOnChange(page) {
        const nextPage = getPageNumber({ activePage, page, totalPages });
        onChange === null || onChange === void 0 ? void 0 : onChange(nextPage);
    }
    return (_jsx("nav", { role: 'navigation', "aria-label": 'Pagination Navigation', className: className, children: _jsx("ul", { className: 'tw-flex', children: items.map((item) => {
                const isCurrentPageLast = String(activePage) === String(totalPages);
                const isCurrentPageFirst = String(activePage) === String(1);
                const isPrevNavigationItems = ['«', '⟨'].includes(item);
                const isNextNavigationItems = ['⟩', '»'].includes(item);
                const disabled = (isPrevNavigationItems && isCurrentPageFirst) ||
                    (isNextNavigationItems && isCurrentPageLast);
                const active = item === String(activePage);
                return (_jsx("li", { className: '[&:first-child>*]:tw-ms-0 [&:first-child>*]:tw-rounded-s [&:last-child>*]:tw-rounded-e', children: _jsx(LinkComp, { className: cn('-tw-ms-px tw-block tw-border  tw-px-3 tw-py-2 tw-text-sm tw-tabular-nums tw-leading-tight  tw-transition-colors tw-duration-75', disabled &&
                            'tw-pointer-events-none tw-cursor-auto tw-border tw-border-secondary-40 tw-text-text-30', active
                            ? 'tw-border-primary-100 tw-bg-primary-100 tw-text-white'
                            : 'tw-border-secondary-40 tw-bg-white tw-text-text-60 hover:tw-bg-secondary-20 hover:tw-text-text-90'), "aria-label": getAriaLabel(item), onClick: active || LinkComp || LinkComp === 'a'
                            ? undefined
                            : (event) => {
                                event.preventDefault();
                                handleOnChange(item);
                            }, href: href ? href(item) : '#!', children: item }) }, item));
            }) }) }));
}
export function getPageNumber({ activePage, page, totalPages, }) {
    let nextPage = activePage;
    switch (page) {
        case '«':
            nextPage = 1;
            break;
        case '⟨':
            nextPage = Math.max(1, activePage - 1);
            break;
        case '⟩':
            nextPage = Math.min(totalPages, activePage + 1);
            break;
        case '»':
            nextPage = totalPages;
            break;
        default:
            nextPage = Number(page);
            break;
    }
    return Number(nextPage) <= 1 ? null : nextPage;
}
function getPageNumbers({ activePage = 10, pageRangeDisplayed = 5, totalPages, }) {
    const pageRange = Math.min(pageRangeDisplayed, totalPages);
    const pageRangeStart = Math.max(0, Math.min(totalPages - pageRange, activePage - Math.floor(pageRange / 2) - 1));
    const pageRangeEnd = Math.min(totalPages, pageRangeStart + pageRange);
    const pageNumbers = [];
    for (let i = pageRangeStart; i < pageRangeEnd; i++) {
        pageNumbers.push(i + 1);
    }
    return pageNumbers;
}
function getAriaLabel(page) {
    switch (page) {
        case '«':
            return 'Go to first page';
        case '⟨':
            return 'Go to previous page';
        case '⟩':
            return 'Go to next page';
        case '»':
            return 'Go to last page';
        default: {
            const ordinal = formatOrdinals(Number(page));
            return `Go to the ${ordinal} page`;
        }
    }
}
