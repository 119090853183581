import { t } from 'i18next'
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { CalendarX, WarningOctagon, XCircle } from '@phosphor-icons/react'
import { add, format, isValid } from 'date-fns'

import { ModalCloseButton } from '../../../../components/Common/modal-close-button'
import Alert, { PrimaryAlert } from '../../../../components/ui/alert'
import CustomRadio from '../../../../components/custom-radio'
import Toggle from '../../../../components/Forms/Toggle/Toggle'
import Button from '../../../../components/ui/button'
import { CustomCurrencyInput } from '../../../../components/ControlledCurrencyInput'
import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import BadgeV2 from '../../../../components/ui/badge-v2'
import { useFetch } from '../../../../helpers/hooks'
import { getTerminationProrata } from '../../../../services/api'
import { TERMINATE_CONTRACT_TYPE } from '../../../../helpers/enum'
import Shimmer from '../../../../components/ui/shimmer'
import { getCurrencyFormatter } from '../../../../utils/formatters/currency'
import { WORKING_DAYS } from '../../CreateContract/components/prorata/constants'

const terminationTypes = [
  {
    icon: <XCircle size={24} className='tw-me-2' />,
    value: TERMINATE_CONTRACT_TYPE.NOW,
    label: 'Terminate now',
  },
  {
    icon: <CalendarX size={24} className='tw-me-2' />,
    value: TERMINATE_CONTRACT_TYPE.LATER,
    label: 'Schedule Termination',
  },
]

const PayNoticePeriod = ({ onToggle, noticePeriod, checked }) => {
  return (
    <div className='tw-flex tw-items-center tw-justify-between tw-rounded tw-border tw-border-surface-30 tw-p-4'>
      <span className='tw-me-4 tw-flex-1'>
        <span className='tw-font-bold'>{t('Pay notice period')}</span>
        <div className='tw-text-text-60'>
          {t('The notice period for this contract is days', {
            count: noticePeriod,
          })}
        </div>
      </span>
      <Toggle
        marginRight=''
        change={(event) => onToggle(event.target.checked)}
        check={checked}
      />
    </div>
  )
}

const PaymentRadioItem = ({
  item,
  isSelected,
  customAmount,
  onCustomAmountChange,
  currencyCode,
}) => {
  return (
    <span>
      <span className='tw-flex tw-items-center tw-justify-between'>
        <span className='tw-flex-1'>
          <div>{item.title}</div>
          <span>{item.subtitle}</span>
        </span>
        {item.title !== t('Custom amount') && (
          <BadgeV2
            className={isSelected ? '!tw-bg-primary' : '!tw-bg-secondary-20'}
            textClassName={isSelected ? '!tw-text-white' : ''}
          >
            {item.amount}
          </BadgeV2>
        )}
      </span>

      {isSelected && item.title === t('Custom amount') && (
        <span className='-tw-mx-6 tw-mt-6 tw-block tw-border-t tw-border-t-surface-30 tw-bg-surface-10 tw-px-6 tw-pt-6'>
          <div className='tw-flex'>
            <CustomCurrencyInput
              name='amount'
              className='!tw-rounded-br-none !tw-rounded-tr-none !tw-border-r-0'
              placeholder={t('Amount')}
              value={customAmount}
              onChange={onCustomAmountChange}
            />

            <span className='tw-flex tw-items-center tw-rounded-b tw-rounded-r tw-rounded-bl-none tw-border tw-border-l-0 tw-border-surface-30 tw-px-4 tw-text-text-80'>
              {currencyCode}
            </span>
          </div>
        </span>
      )}
    </span>
  )
}

const ProrataPayments = ({
  isNow,
  paymentType,
  onPaymentTypeChange,
  isLoading,
  prorataData,
  currencyCode,
  customAmount,
  onCustomAmountChange,
}) => {
  const formatter = getCurrencyFormatter(currencyCode)
  const {
    working_days_monday_friday: monToFri,
    working_days_sunday_thursday: sunToThur,
    calendar_days: calendarDays,
  } = prorataData || {}

  const paymentTypes = [
    {
      title: t('Working days Pay', { count: monToFri?.days_worked }),
      subtitle: t('Based on a Monday–Friday Workweek'),
      amount: formatter.format(monToFri?.pro_rata_amount),
      value: WORKING_DAYS.MONDAY_FRIDAY,
    },
    {
      title: t('Working days Pay', { count: sunToThur?.days_worked }),
      subtitle: t('Based on a Sunday-Thursday Workweek'),
      amount: formatter.format(sunToThur?.pro_rata_amount),
      value: WORKING_DAYS.SUNDAY_THURSDAY,
    },
    {
      title: t('Calendar days Pay', { count: calendarDays?.days_worked }),
      subtitle: t('Based on a Full Calendar Week'),
      amount: formatter.format(calendarDays?.pro_rata_amount),
      value: 'calendar_days',
    },
    {
      title: t('Custom amount'),
      subtitle: t('Set a Custom Amount'),
      value: 'custom',
    },
  ]

  const startDate = new Date(prorataData?.start_date)
  const terminationDate = new Date(prorataData?.termination_date)

  if (isLoading) {
    return <Shimmer className='tw-mt-4 !tw-w-full' height={68} />
  }

  return (
    <>
      <hr className='-tw-mx-4 tw-my-6' />
      {!isNow && isValid(startDate) && isValid(terminationDate) && (
        <PrimaryAlert className='!tw-mb-6 !tw-text-xs !tw-text-text-80'>
          {t('the termination payment cycle alert', {
            startDate: format(startDate, 'MMM dd, yyyy'),
            terminationDate: format(terminationDate, 'MMM dd, yyyy'),
          }) + '.'}
        </PrimaryAlert>
      )}

      <div className='tw-text-base tw-font-semibold'>
        {isNow ? t('Final payment') : t('Prorated amount')}
      </div>
      {isValid(startDate) && isValid(terminationDate) && (
        <span className='tw-text-xs tw-font-medium tw-text-text-60'>
          {t('Calculated termination days between dates', {
            startDate: format(startDate, 'MMM dd, yyyy'),
            terminationDate: format(terminationDate, 'MMM dd, yyyy'),
          })}
        </span>
      )}

      <span className='tw-mt-6 tw-flex tw-flex-col'>
        <CustomRadio
          items={paymentTypes}
          renderItem={(item) => (
            <PaymentRadioItem
              item={item}
              isSelected={item.value === paymentType}
              customAmount={customAmount}
              onCustomAmountChange={onCustomAmountChange}
              currencyCode={currencyCode}
            />
          )}
          className='tw-mb-4 last:tw-mb-0 [&>span>span>span>span]:tw-text-xs [&>span>span>span>span]:tw-text-text-60 [&>span>span>span>span]:has-[:checked]:tw-text-primary'
          name='paymentType'
          checkedItem={paymentType}
          onSelect={(event) => onPaymentTypeChange(event.target.value)}
        />
      </span>
    </>
  )
}

export default function ContractTerminationModal({
  toggle,
  isOpen,
  terminationType,
  onTypeChange,
  paymentType,
  onPaymentTypeChange,
  contract,
  onTerminationConfirmation,
}) {
  const isNow = terminationType === TERMINATE_CONTRACT_TYPE.NOW
  const [shouldPayNoticePeriod, setShouldPayNoticePeriod] = useState(false)
  const [customAmount, setCustomAmount] = useState()
  const [scheduledDate, setScheduledDate] = useState()
  const [showWarning, setShowWarning] = useState(contract.amended)
  const showPayments =
    (isNow && shouldPayNoticePeriod) || (!isNow && scheduledDate)
  const disabled = !showWarning
    ? (isNow && !showPayments) ||
      (isNow && showPayments && paymentType === 'custom' && !customAmount)
    : false

  const { data: prorataData, isLoading: fetchingProrata } = useFetch(
    {
      action: getTerminationProrata,
      body: {
        contractId: contract.id,
        termination_date:
          !isNow && isValid(scheduledDate)
            ? format(new Date(scheduledDate), 'yyyy-MM-dd')
            : format(new Date(), 'yyyy-MM-dd'),
      },
      autoFetch: isNow ? shouldPayNoticePeriod : Boolean(scheduledDate),
    },
    [scheduledDate, shouldPayNoticePeriod, isNow],
  )

  const getAmount = () => {
    switch (paymentType) {
      case WORKING_DAYS.MONDAY_FRIDAY:
        return prorataData.working_days_monday_friday.pro_rata_amount
      case WORKING_DAYS.SUNDAY_THURSDAY:
        return prorataData.working_days_sunday_thursday.pro_rata_amount
      case WORKING_DAYS.CALENDAR_DAYS:
        return prorataData.calendar_days.pro_rata_amount
      default:
        return customAmount
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className='tw-min-w-[520px] [&>div]:tw-max-h-[calc(100vh-96px)]'
      unmountOnClose
      onClosed={() => setShowWarning(contract.amended)}
    >
      <ModalHeader
        close={<ModalCloseButton toggle={toggle} size={24} />}
        className='!tw-px-6'
      >
        <div>{t('Terminate contract')}</div>
        {!showWarning && (
          <span className='tw-text-sm tw-text-text'>
            {t('You can terminate the contract now or pick a date') + '.'}
          </span>
        )}
      </ModalHeader>

      <Alert
        className='tw-mx-6 tw-mt-6 !tw-border-none !tw-bg-systemRed-20 !tw-text-xs !tw-text-text-80'
        customIcon={<WarningOctagon size={20} className='tw-fill-systemRed' />}
      >
        {!showWarning
          ? t('contract notice period days message', {
              noticePeriod: contract.notice_period,
            }) + '.'
          : t(
              'By proceeding, the amendment in progress will be canceled and contract will be terminated',
            ) + '.'}
      </Alert>

      {!showWarning && (
        <ModalBody className='tw-min-h-96 tw-overflow-auto !tw-px-6'>
          <div className='tw-mb-6 tw-flex tw-gap-2'>
            <CustomRadio
              items={terminationTypes}
              name='terminationType'
              renderItem={(item) => (
                <>
                  {item.icon}
                  {t(item.label)}
                </>
              )}
              className='tw-flex tw-flex-1 tw-items-center tw-text-[13px]'
              onSelect={(event) => onTypeChange(event.target.value)}
              checkedItem={terminationType}
            />
          </div>
          {isNow ? (
            <PayNoticePeriod
              onToggle={(checked) => setShouldPayNoticePeriod(checked)}
              noticePeriod={contract.notice_period}
              checked={shouldPayNoticePeriod}
            />
          ) : (
            <CustomDatePicker
              value={scheduledDate}
              handleOnChange={(date) => setScheduledDate(date)}
              minDate={add(new Date(), { days: 1 })}
            />
          )}
          {showPayments && (
            <ProrataPayments
              isNow={isNow}
              paymentType={paymentType}
              onPaymentTypeChange={onPaymentTypeChange}
              prorataData={prorataData}
              isLoading={fetchingProrata}
              currencyCode={contract.currency.code}
              customAmount={customAmount}
              onCustomAmountChange={(val) => {
                setCustomAmount(val)
              }}
            />
          )}
        </ModalBody>
      )}
      <ModalFooter className='tw-sticky tw-bottom-0 tw-bg-white !tw-px-6'>
        <Button
          color='transparent'
          className='!tw-border !tw-border-surface-30'
          onClick={toggle}
        >
          {showWarning ? t('Go Back') : t('Cancel')}
        </Button>
        <Button
          color='danger'
          onClick={() =>
            showWarning
              ? setShowWarning(false)
              : onTerminationConfirmation(
                  getAmount(),
                  // No need to send termination date for terminate now as that is handled at the point of termination
                  scheduledDate,
                  terminationType,
                )
          }
          disabled={disabled}
        >
          {showWarning ? t('Terminate') : t('Confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
