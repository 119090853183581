import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import toastr from 'toastr'

import Loader from '../../components/ui/loader'
import { useFetch } from '../../helpers/hooks'
import {
  cardActivity,
  cardsPreConditions,
  getCardDetails,
} from '../../services/api'
import { getErrorMessage } from '../../utils/get-errors'
import { riskAssessmentEnum } from '../AdminPanel/pages/cards/cards-kyc'
import BalanceDisplay from './components/balance-display'
import CardsLatestTransactions from './components/card-latest-trx'
import CardsKycBanner, { KYC_STATUSES } from './components/cards-kyc-banner'
import CashBack from './components/cashback'
import CreditCardComponent from './components/cc-component/cc-component'
import EmptyContent from './components/empty-content'
import ExtraCashBack from './components/extra-cash-back'
import PendingCard from './components/pending-card'
import PhysicalCardBanner from './components/physical-card-banner'
import RequestNewCard from './components/request-new-card'
import RewardUnlockedModal from './components/reward-unlocked-modal'
import { CardsSection } from './components/section-heading'
import UnderReviewCard from './components/under-review-card'
import { isPhysicalCardPending } from './organisms/physical-card-states'
import './style.scss'
import { CARD_STATUSES, CARD_TYPE_ENUM } from './utils/card-types-status'
import { formatCard, getActiveCard } from './utils/cards-data-formatting'

export const REWARD_UNLOCKED = 'reward_unlocked_showed'

const SelectedCardContext = createContext({})
export const useSelectedCard = () => useContext(SelectedCardContext)

export default function RemotePassCards() {
  const [selectedCard, setSelectedCard] = useState(CARD_TYPE_ENUM.VIRTUAL)

  return (
    <div className='page-content'>
      <h1 className='font-size-32 text-gray-h mb-3 mb-md-4'>Cards</h1>
      <SelectedCardContext.Provider value={{ selectedCard, setSelectedCard }}>
        <CardStates />
      </SelectedCardContext.Provider>
    </div>
  )
}

function CardStates() {
  const user = useSelector((state) => state.Account?.user)
  const [openRewardUnlocked, setOpenRewardUnlocked] = useState(false)

  const { data: preConditions, isLoading: preConditionsLoading } = useFetch({
    action: cardsPreConditions,
    autoFetch: true,
  })

  const {
    data: cards,
    isLoading: cardsLoading,
    startFetch: refetchCards,
    error: cardsError,
  } = useFetch({
    action: cardActivity,
    autoFetch: true,
    body: { rp_user_id: user?.id },
    onComplete: (data) => {
      const showRewardModal = !localStorage.getItem(REWARD_UNLOCKED)
      if (data?.cashback_details?.cashback_unlocked && showRewardModal) {
        setOpenRewardUnlocked(true)
      }
    },
    onError: (err) => {
      const errorMessage = getErrorMessage(err)

      toastr.error(errorMessage || 'Something went wrong')
    },
  })

  function handleRefetchCards() {
    refetchCards({ rp_user_id: user?.id })
  }

  const hasCardOrder = !!cards?.card_order || cards?.cards?.length > 0

  const isUnderReview =
    hasCardOrder &&
    cards?.risk_assessment?.status !== riskAssessmentEnum.CLEARED

  const isPagePending =
    cards?.cards?.length === 1 &&
    cards?.card_order &&
    cards?.card_order?.status !== 'issued' &&
    cards?.card_order?.card_type !== CARD_TYPE_ENUM.PHYSICAL

  if (cardsError) {
    return (
      <EmptyContent>
        <h3 className='h2 mb-0' style={{ color: 'var(--gray-h)' }}>
          Whoops!
        </h3>
        <p className='mb-0'>There was an error</p>
      </EmptyContent>
    )
  }

  if (cardsLoading || preConditionsLoading) {
    return <Loader minHeight={566} />
  }

  if (isPagePending) {
    return <PendingCard />
  }

  if (isUnderReview) {
    return <UnderReviewCard />
  }

  if (hasCardOrder) {
    return (
      <>
        <CardsDetails
          cards={cards}
          refetchCards={handleRefetchCards}
          preConditions={preConditions}
        />
        {openRewardUnlocked && (
          <RewardUnlockedModal
            userName={user?.first_name}
            date={cards?.cashback_details?.cashback_rewarded_at}
            isOpen={openRewardUnlocked}
            toggle={() => setOpenRewardUnlocked(false)}
          />
        )}
      </>
    )
  }

  return (
    <RequestNewCard
      afterRequestNewCard={refetchCards}
      preConditions={preConditions}
    />
  )
}

function CardsDetails({ cards, preConditions, refetchCards }) {
  const { selectedCard } = useSelectedCard()
  const [allCardData, setAllCardData] = useState({})
  const history = useHistory()

  const acknowledgedAt = cards?.kyc?.acknowledged_at
  const showKycBanner = !acknowledgedAt

  const allCardsTerminated =
    allCardData?.[CARD_TYPE_ENUM.PHYSICAL]?.cardStatus ===
      CARD_STATUSES.TERMINATED &&
    allCardData?.[CARD_TYPE_ENUM.VIRTUAL]?.cardStatus ===
      CARD_STATUSES.TERMINATED

  const alreadyHasPhysicalCard = cards?.cards?.some(
    (card) => card?.card_type === CARD_TYPE_ENUM.PHYSICAL,
  )
  const physicalCardPending = isPhysicalCardPending({
    status: cards?.card_order?.status,
    type: cards?.card_order?.card_type,
  })
  const hideCardBanner = alreadyHasPhysicalCard || physicalCardPending

  useEffect(() => {
    const physicalCards = []
    const virtualCards = []

    cards?.cards?.forEach((card) => {
      if (card.card_type === CARD_TYPE_ENUM.VIRTUAL) {
        virtualCards.push(formatCard(card))
      } else {
        physicalCards.push(formatCard(card))
      }
    })

    setAllCardData({
      [CARD_TYPE_ENUM.PHYSICAL]: getActiveCard(physicalCards),
      [CARD_TYPE_ENUM.VIRTUAL]: getActiveCard(virtualCards),
    })

    return () => {}
  }, [cards?.cards])

  const isPhysicalCardSupported =
    preConditions?.card_configurations?.is_physical_card_supported &&
    preConditions?.nationality_card_ordering_enabled

  function handleRequestCard() {
    const isKycApproved = [KYC_STATUSES.APPROVED, KYC_STATUSES.DONE].includes(
      cards?.kyc?.status,
    )

    if (!isPhysicalCardSupported) {
      toastr.error(
        'Physical cards are not supported <strong>in your country</strong>',
        null,
        { allowHtml: true },
      )
      return
    }

    if (!isKycApproved) {
      toastr.error(
        'You need to complete your KYC before you can request a physical card.',
      )
      return
    }

    history.push('/cards/request-physical')
    window.scrollTo(0, 0)
  }

  return (
    <div className='d-flex flex-column gap-24'>
      {showKycBanner ? (
        <CardsKycBanner
          kycStatus={cards?.kyc}
          refetchCards={refetchCards}
          preConditions={preConditions}
        />
      ) : null}

      <CreditCardAndBalance
        allCardsTerminated={allCardsTerminated}
        handleRequestCard={handleRequestCard}
        showKycBanner={showKycBanner}
        cards={cards}
        activeCardData={allCardData[selectedCard]}
        alreadyHasPhysicalCard={alreadyHasPhysicalCard}
        cardOrder={cards?.card_order}
        refetchCards={refetchCards}
        setAllCardData={setAllCardData}
      />

      <ExtraCashBack />

      <PhysicalCardBanner
        handleRequestCard={handleRequestCard}
        isPhysicalCardSupported={isPhysicalCardSupported}
        hide={hideCardBanner}
        preConditions={preConditions}
      />

      <CardsLatestTransactions />
    </div>
  )
}

function CreditCardAndBalance({
  allCardsTerminated,
  showKycBanner,
  activeCardData,
  alreadyHasPhysicalCard,
  handleRequestCard,
  cardOrder,
  refetchCards,
  setAllCardData,
  cards,
}) {
  const rpUserId = useSelector((state) => state.Account?.user?.id)

  const cardId = useMemo(() => activeCardData?.id ?? null, [activeCardData?.id])

  const { data: cardDetails, isLoading: loadingCardDetails } = useFetch(
    {
      action: getCardDetails,
      body: { cardId, rpUserId },
      autoFetch: !!cardId,
      onError: (err) => {
        // eslint-disable-next-line no-console
        console.log('err', err)
      },
    },
    [cardId],
  )
  return (
    <>
      <div className='tw-flex tw-min-h-[347px] tw-flex-col tw-flex-wrap max-md:tw-gap-4 md:tw-flex-row'>
        <CardsSection className='tw-p-4 md:!tw-rounded-r-none md:!tw-border-r md:!tw-border-surface-30 md:tw-p-8'>
          <BalanceDisplay
            className='tw-mb-6 tw-flex tw-w-full md:tw-hidden'
            loadingCardDetails={loadingCardDetails}
            balance={cardDetails?.funds?.available}
          />
          <div className='tw-relative'>
            <CreditCardComponent
              handleRequestCard={handleRequestCard}
              activeCardData={activeCardData}
              alreadyHasPhysicalCard={alreadyHasPhysicalCard}
              cardDetails={cardDetails}
              cardOrder={cardOrder}
              refetchCards={refetchCards}
              setAllCardData={setAllCardData}
            />
          </div>
        </CardsSection>
        <CardsSection className='tw-flex-grow md:!tw-rounded-l-none'>
          <BalanceContent
            cardDetails={cardDetails}
            cards={cards}
            showKycBanner={showKycBanner}
            loadingCardDetails={loadingCardDetails}
            activeCardData={activeCardData}
            allCardsTerminated={allCardsTerminated}
          />
        </CardsSection>
      </div>
    </>
  )
}

function BalanceContent({
  loadingCardDetails,
  showKycBanner,
  cardDetails,
  cards,
}) {
  return (
    <>
      <div
        className='d-none d-md-flex flex-column w-100 p-4.5'
        style={{ borderBottom: '1px solid var(--surface-30)' }}
      >
        <BalanceDisplay
          loadingCardDetails={loadingCardDetails}
          className='d-flex'
          balance={cardDetails?.funds?.available}
        />
      </div>
      <CashBack data={cards} showKycBanner={showKycBanner} />
    </>
  )
}
