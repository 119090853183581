import React, { useState } from 'react'
import ReactInputVerificationCode from 'react-input-verification-code'
import { useSelector } from 'react-redux'
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap'
import toastr from 'toastr'
import { t } from 'i18next'
import { useFetch } from '../../../helpers/hooks'
import {
  disable2fa,
  enable2fa,
  request2fa,
  sendTfaEmailCode,
  verifyTfaEmail,
} from '../../../services/api'
import { isOtpCodeValid } from '../../../utils/is-otp-code-valid'
import OptionRadioButtons from '../../option-radio-buttons'
import Button from '../../ui/button'
import Loader from '../../ui/loader'

const STATES = {
  LOADING: 'LOADING',
  PASSWORD: 'PASSWORD',
  TFA_CODE: 'TFA_CODE',
  G_CODE_SCAN: 'G_CODE_SCAN',
}

function TwoFactorAuthentication({
  isOpen,
  toggle,
  is2FAEnabled,
  defaultTfaType,
  onEnabled,
  onDisabled,
}) {
  const TFA_TYPES = {
    email: { label: t('Email'), value: 'email' },
    google: { label: t('Google Authenticator'), value: 'google' },
  }

  const [uiState, setUiState] = useState(STATES.PASSWORD)
  const [tfaType, setTfaType] = useState(null)

  const usingGSignOn = useSelector(
    (state) => state?.userProfile?.userProfile?.gsi,
  )

  const request = useFetch({
    action: request2fa,
    initResult: null,
    onComplete: () => {
      setUiState(STATES.G_CODE_SCAN)
    },
    onError: (err) => {
      setUiState(STATES.PASSWORD)
      toastr.error(err)
    },
  })
  const { startFetch: sendEmailCode } = useFetch({
    action: sendTfaEmailCode,
    initResult: null,
    onComplete: () => {
      setUiState(STATES.TFA_CODE)
    },
    onError: (err) => {
      setUiState(STATES.PASSWORD)
      toastr.error(err)
    },
  })

  const enable = useFetch({
    action: enable2fa,
    initResult: null,
    onComplete: onEnabled,
    onError: (err) => {
      toastr.error(
        typeof err === 'string'
          ? err
          : (err?.message ?? t('Something went wrong')),
      )
      setUiState(STATES.TFA_CODE)
    },
  })
  const { startFetch: enableEmail } = useFetch({
    action: verifyTfaEmail,
    initResult: null,
    onComplete: onEnabled,
    onError: (err) => {
      toastr.error(
        typeof err === 'string'
          ? err
          : (err?.message ?? t('Something went wrong')),
      )
      setUiState(STATES.TFA_CODE)
    },
  })

  const disable = useFetch({
    action: disable2fa,
    initResult: null,
    onComplete: onDisabled,
    onError: (err) => {
      setUiState(STATES.PASSWORD)
      toastr.error(err)
    },
  })

  const handleRequest = (values) => {
    if (is2FAEnabled) {
      setUiState(STATES.LOADING)
      disable.startFetch(values)
    } else {
      const { password, type } = values
      setTfaType(type)
      setUiState(STATES.LOADING)

      if (type === TFA_TYPES.google.value) {
        request.startFetch({ password })
      } else {
        sendEmailCode({ password })
      }
    }
  }

  function handleNextStep() {
    setUiState(STATES.TFA_CODE)
  }

  function handleBack() {
    if (tfaType === TFA_TYPES.google.value) {
      setUiState(STATES.G_CODE_SCAN)
    } else if (tfaType === TFA_TYPES.email.value) {
      setUiState(STATES.PASSWORD)
    }
  }

  function handleVerifyCode({ code }) {
    setUiState(STATES.LOADING)
    if (tfaType === TFA_TYPES.google.value) {
      enable.startFetch({ code })
    } else {
      enableEmail({ code })
    }
  }

  return (
    <Modal isOpen={isOpen} size='md' centered toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {is2FAEnabled
          ? t('Disable Two-Factor Authentication')
          : t('Enable Two-Factor Authentication')}
      </ModalHeader>

      <ModalBody
        className='p-4 d-flex flex-column'
        style={{ minHeight: '30vh' }}
      >
        {uiState === STATES.LOADING ? (
          <Loader />
        ) : uiState === STATES.PASSWORD ? (
          <TypeAndPassword
            onToggleTfa={handleRequest}
            tfaType={defaultTfaType}
            is2FAEnabled={is2FAEnabled}
            usingGSignOn={usingGSignOn}
          />
        ) : uiState === STATES.G_CODE_SCAN ? (
          <GoogleQRScan
            tfaSecret={request?.data?.['2fa_secret']}
            qrCodeImgSrc={request?.data?.qr_image}
            onNextStep={handleNextStep}
          />
        ) : uiState === STATES.TFA_CODE ? (
          <TFACode
            onBack={handleBack}
            onNextStep={handleVerifyCode}
            tfaType={tfaType}
          />
        ) : null}
      </ModalBody>
    </Modal>
  )
}

const OS = {
  WINDOWS_PHONE: 'Windows Phone',
  IOS: 'iOS',
  ANDROID: 'Android',
  UNKNOWN: 'unknown',
}

function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return OS.WINDOWS_PHONE
  }

  if (/android/i.test(userAgent)) {
    return OS.ANDROID
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return OS.IOS
  }

  return OS.UNKNOWN
}

function GAInstallLink() {
  const os = getMobileOperatingSystem()

  switch (os) {
    case OS.ANDROID: {
      return (
        <a
          className='text-primary'
          target='_blank'
          rel='noreferrer'
          href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
        >
          {t('Google Authenticator app')}
        </a>
      )
    }
    case OS.IOS: {
      return (
        <a
          className='text-primary'
          target='_blank'
          rel='noreferrer'
          href='https://apps.apple.com/us/app/google-authenticator/id388497605'
        >
          {t('Google Authenticator app')}
        </a>
      )
    }
    case OS.WINDOWS_PHONE:
    case OS.UNKNOWN: {
      return (
        <span>
          {t('Google Authenticator app')} (
          <a
            className='text-primary'
            target='_blank'
            rel='noreferrer'
            href='https://apps.apple.com/us/app/google-authenticator/id388497605'
          >
            {t('For iOS')}
          </a>
          , or{' '}
          <a
            className='text-primary'
            target='_blank'
            rel='noreferrer'
            href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
          >
            {t('Android')}
          </a>
          )
        </span>
      )
    }
  }
}

function TypeAndPassword({ tfaType, is2FAEnabled, onToggleTfa, usingGSignOn }) {
  const TFA_TYPES = {
    email: { label: t('Email'), value: 'email' },
    google: { label: t('Google Authenticator'), value: 'google' },
  }
  const TFP_OPTIONS = [
    {
      label: TFA_TYPES.email.label,
      value: TFA_TYPES.email.value,
      description: t('Receive a code via email'),
      icon: <i className='bx bxs-envelope font-size-16' />,
    },
    {
      label: TFA_TYPES.google.label,
      value: TFA_TYPES.google.value,
      description: t('Via a mobile app'),
      icon: <i className='bx bxl-google font-size-16' />,
    },
  ]
  const [type, setType] = useState(null)
  const [password, setPassword] = useState('')

  function handleContinue() {
    const typeIsOk = tfaType || type

    if ((!usingGSignOn && !password) || !typeIsOk) {
      toastr.error(t('Please fill in all fields'))
      return
    }

    const dummyGSignInPassword = '7DLvCasVfPc7AUp'

    onToggleTfa({
      type: tfaType ?? type,
      password: usingGSignOn ? dummyGSignInPassword : password,
    })
  }

  return (
    <>
      {!is2FAEnabled ? (
        <FormGroup className='tw-text-start'>
          <Label>{t('Select type:')}</Label>
          <OptionRadioButtons
            options={TFP_OPTIONS}
            value={type}
            onChange={setType}
            size='sm'
          />
        </FormGroup>
      ) : (
        <p>
          {t('You want to disable')} {TFA_TYPES[tfaType].label}{' '}
          {t('Two-Factor authentication.')}.
        </p>
      )}

      {usingGSignOn ? (
        <p>{t('You are using Google to log in.')}</p>
      ) : (
        <FormGroup className='tw-text-start'>
          <Label htmlFor='password'>
            {t('Verify your password to make changes:')}
          </Label>
          <Input
            type='password'
            name='password'
            id='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormGroup>
      )}

      <Button onClick={handleContinue} className='ml-auto mt-auto'>
        {t('Continue')}
      </Button>
    </>
  )
}

function GoogleQRScan({ tfaSecret, qrCodeImgSrc, onNextStep }) {
  return (
    <>
      <ol className='pl-3'>
        <li className='mb-2'>
          Download and install <GAInstallLink /> on your phone.
        </li>
        <li className='mb-2'>
          Using the app, scan the QR code or manually enter the following code:{' '}
          <code className='rp-font-bold'>{tfaSecret}</code>
          <span className='d-flex justify-content-center mt-2'>
            <img
              src={qrCodeImgSrc}
              className='border rounded'
              style={{ height: 200 }}
            />
          </span>
        </li>
        <li>
          Once you have scanned the QR code or entered the code, click on
          “Continue”
        </li>
      </ol>

      <Button onClick={onNextStep} className='ml-auto'>
        Continue
      </Button>
    </>
  )
}

function TFACode({ onBack, onNextStep, tfaType }) {
  const TFA_TYPES = {
    email: { label: t('Email'), value: 'email' },
    google: { label: t('Google Authenticator'), value: 'google' },
  }
  const [code, setCode] = useState('')

  function handleCompleted(code) {
    if (isOtpCodeValid(code)) {
      onNextStep({ code })
    }
  }

  return (
    <>
      {tfaType === TFA_TYPES.google.value ? (
        <p>
          Open the <strong>Google Authenticator</strong> app on your phone and
          enter the generated code.
        </p>
      ) : (
        <p>
          Enter the code you received via <strong>email</strong>.
          {/* If you didn’t receive the code,
          you can{' '}
          <a
            className='text-primary'
            href='https://www.google.com/accounts/recovery/'
            target='_blank'
            rel='noreferrer'
          >
            request a new one
          </a>
          . */}
        </p>
      )}

      <div className='verificationCode mb-3' style={{ margin: '0 2px' }}>
        <ReactInputVerificationCode
          type='number'
          length={6}
          onChange={setCode}
          inputProps={{ id: 'tfa-code' }}
          onCompleted={handleCompleted}
        />
      </div>

      <div className='mt-auto d-flex justify-content-between'>
        <Button color='light' outline onClick={onBack}>
          Back
        </Button>
        <Button
          onClick={() => onNextStep({ code })}
          disabled={code?.includes('·')}
        >
          {t('Continue')}
        </Button>
      </div>
    </>
  )
}

export default TwoFactorAuthentication
