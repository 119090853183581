import { t } from 'i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Avatar } from 'ui'
import * as yup from 'yup'

import ControlledInput from '../../../components/ControlledInput'
import { PrimaryAlert } from '../../../components/ui/alert'
import { getFullName } from '../../../utils/get-full-name'
import { ControlledApprovers } from './approval-tab'

export default function ApprovalFlowForm({
  formId,
  usersList,
  onSubmit,
  defaultValues,
}) {
  function _onSubmit(data) {
    const formattedApprovers = data.approvers.map((approver, index) => {
      const isLast = index === data.approvers.length - 1
      // Mark all approvers as canOverride = false & then leave the last one as is
      const canOverride = isLast ? !!approver.canOverride : false

      return { id: approver.userId, can_override: canOverride }
    })

    onSubmit({ name: data.flowName, approvers: formattedApprovers })
  }

  const { control, handleSubmit } = useForm({
    defaultValues: defaultValues ?? {
      flowName: '',
      approvers: [{ userId: null }],
    },
    resolver: yupResolver(
      yup.object().shape({
        flowName: yup.string().required(t('Flow name is required')),
        approvers: yup.array().of(
          yup.object().shape({
            userId: yup.number().required(t('User is required')),
          }),
        ),
      }),
    ),
  })

  const someUserCantViewContracts = usersList?.some((user) => user?.isDisabled)

  return (
    <>
      <PrimaryAlert className='!tw-mb-4 !tw-p-4'>
        {t(
          'By default, users with the Admin role can override approval flows.',
        )}
      </PrimaryAlert>

      {!someUserCantViewContracts ? null : (
        <PrimaryAlert className='!tw-mb-4 !tw-mt-4 !tw-p-4'>
          {t(
            'Only active users with View or Manage Contract permissions can be assigned a flow.',
          )}{' '}
          <a
            href='https://help.remotepass.com/en/articles/5123137-what-are-the-different-available-user-role-types'
            target='_blank'
            rel='noreferrer'
          >
            {t('Learn more')}
          </a>
        </PrimaryAlert>
      )}

      <form
        id={formId}
        onSubmit={handleSubmit(_onSubmit)}
        className='!tw-text-start'
      >
        <ControlledInput
          control={control}
          name='flowName'
          label={t('Flow Title')}
          placeholder={t('Name your flow')}
          wrapperClassName='tw-mb-4'
        />

        <ControlledApprovers
          control={control}
          name='approvers'
          label={t('Approvers')}
          usersList={usersList}
        />
      </form>
    </>
  )
}

export function getUsersList(users) {
  return users?.list
    ?.filter((user) => user?.status === 'active')
    ?.map((user) => {
      const fullName = getFullName(user)

      return {
        label: fullName,
        fullName,
        description: user?.email,
        email: user?.email,
        value: user.id,
        photo: user?.photo,
        isDisabled: !user?.is_contract_viewer,
        icon: (
          <Avatar
            photo={user?.photo}
            name={fullName || user?.email}
            size='sm'
            className='tw-mr-2'
          />
        ),
      }
    })
}
