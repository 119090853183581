import { HourglassLow, Invoice } from '@phosphor-icons/react'
import { t } from 'i18next'
import React from 'react'
import { Card } from 'reactstrap'
import Heading from '../../remotepass-perks/components/heading'

export default function PendingStatusPage() {
  return (
    <Card className='tw-mb-0 tw-flex tw-min-h-[75vh] tw-flex-col tw-items-center tw-justify-center tw-px-4 tw-pb-5 tw-pt-5'>
      <div className='tw-flex tw-h-auto tw-max-w-[500px] tw-items-center tw-justify-between'>
        <div>
          <Invoice
            size={410}
            weight='duotone'
            className='tw-text-primary-100'
          />
        </div>
        <div>
          <HourglassLow size={90} className='tw-text-primary-100' />
        </div>
      </div>
      <Heading>
        {t('Your access request to Bill Pay has been received')}
      </Heading>

      <p className='tw-mb-0 tw-max-w-[480px] tw-text-center tw-text-base tw-text-disabled'>
        {t('You will be notified by email once your request is approved')}
      </p>
    </Card>
  )
}
