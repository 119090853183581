export function getHelpLink(integrationKey) {
  switch (integrationKey) {
    case 'wafeq': {
      return {
        href: 'https://help.remotepass.com/en/articles/10741148-how-to-connect-wafeq-to-remotepass',
        title: 'How to connect Wafeq to RemotePass',
      }
    }
    default: {
      return null
    }
  }
}
