import React from 'react'
import { cn } from 'ui'
import { ShieldCheck, ShieldWarning } from '@phosphor-icons/react'
import { UncontrolledTooltip } from 'reactstrap'

export function KycStatusRow({ status, verifiedAt, id }) {
  const isVerified = status === 'verified'
  const containerId = `key-status-container-${id}`
  return (
    <div
      id={containerId}
      className={cn(
        'tw-flex tw-w-fit tw-items-center tw-gap-1 tw-rounded-full tw-bg-primary-20 tw-px-2.5 tw-py-1 tw-font-bold tw-text-primary',
        !isVerified && 'tw-bg-red-20 tw-text-red',
      )}
    >
      {isVerified ? (
        <>
          VERIFIED
          <ShieldCheck weight='fill' />
          {verifiedAt ? (
            <UncontrolledTooltip target={containerId}>
              Verified on {verifiedAt}
            </UncontrolledTooltip>
          ) : null}
        </>
      ) : (
        <>
          UNVERIFIED
          <ShieldWarning weight='fill' />
        </>
      )}
    </div>
  )
}
