import { t } from 'i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  CheckCircle,
  CheckSquare,
  Circle,
  PlusCircle,
  Square,
  TrashSimple,
} from '@phosphor-icons/react'
import cx from 'classnames'
import React from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import * as yup from 'yup'

import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import { ControlledToggle } from '../../../components/Forms/Toggle/controlled-toggle'
import { getInputErrorMessage } from '../../../components/Forms/get-input-error-message'
import { ControlledCheckboxList } from '../../../components/controlled-checkbox/controlled-checkbox-list'
import ControlledRadioList from '../../../components/controlled-radio-list'
import Button from '../../../components/ui/button'
import InputFeedback from '../../../components/ui/input-feedback'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../../components/ui/side-menu'

export const FIELD_CONTRACT_TYPES = {
  fixed: 'fixed',
  payg: 'payg',
  milestone: 'milestone',
  eor: 'eor',
  direct_employee: 'direct_employee',
}

const AREA_TYPES = {
  contract: 'contract',
  work: 'work',
}

const SELECT_TYPE_ID = 3

const schema = yup.object().shape({
  name: yup.string().required('Custom field name is required'),
  type_id: yup.string().required('Field type is required'),
  required: yup.boolean(),
  area: yup.string().required('Area is required'),
  contract_types: yup
    .object()
    .shape({
      fixed: yup.boolean(),
      payg: yup.boolean(),
      milestone: yup.boolean(),
      eor: yup.boolean(),
      direct_employee: yup.boolean(),
    })
    // at least one option should be true
    .test({
      name: 'at-least-one-option',
      message: 'At least one contract type should be selected',
      test: (value) => {
        return (
          value.fixed ||
          value.payg ||
          value.milestone ||
          value.eor ||
          value.direct_employee
        )
      },
    }),
  options: yup.array().when('type_id', {
    is: (typeId) => {
      return Number(typeId) === SELECT_TYPE_ID
    },
    then: (schema) =>
      schema
        .of(
          yup.object().shape({
            text: yup
              .string()
              .required('Option text is required')
              .min(1, 'Option text is required'),
          }),
        )
        .min(2, 'At least two options are required')
        .required('Options are required'),
  }),
})

export function ManageCustomField({
  title,
  subtitle,
  state,
  toggle,
  onSubmit,
  onDelete,
  actionsLoading,
  formId = 'manage-custom-field-form',
}) {
  const areaOptions = [
    {
      label: ({ isActive }) => (
        <RadioOptionLabel
          label={t('Apply to Contract details')}
          description={t(
            'Will be added to the contract creation flow and to the contract details page',
          )}
          isActive={isActive}
        />
      ),
      value: AREA_TYPES.contract,
    },
    {
      label: ({ isActive }) => (
        <RadioOptionLabel
          label={t('Apply to Timesheet / Work Submission')}
          description={t('Access to all company contracts')}
          isActive={isActive}
        />
      ),
      value: AREA_TYPES.work,
    },
  ]
  const fieldContractTypesOptions = [
    {
      label: ({ isActive }) => (
        <CheckBoxOptionLabel label={t('Fixed contracts')} isActive={isActive} />
      ),
      value: FIELD_CONTRACT_TYPES.fixed,
      name: FIELD_CONTRACT_TYPES.fixed,
    },
    {
      label: ({ isActive }) => (
        <CheckBoxOptionLabel
          label={t('Pay As You Go contracts')}
          isActive={isActive}
        />
      ),
      value: FIELD_CONTRACT_TYPES.payg,
      name: FIELD_CONTRACT_TYPES.payg,
    },
    {
      label: ({ isActive }) => (
        <CheckBoxOptionLabel
          label={t('Milestone contracts')}
          isActive={isActive}
        />
      ),
      value: FIELD_CONTRACT_TYPES.milestone,
      name: FIELD_CONTRACT_TYPES.milestone,
    },
    {
      label: ({ isActive }) => (
        <CheckBoxOptionLabel label={t('EOR contracts')} isActive={isActive} />
      ),
      value: FIELD_CONTRACT_TYPES.eor,
      name: FIELD_CONTRACT_TYPES.eor,
    },
    {
      label: ({ isActive }) => (
        <CheckBoxOptionLabel
          label={t('Direct Employee contracts')}
          isActive={isActive}
        />
      ),
      value: FIELD_CONTRACT_TYPES.direct_employee,
      name: FIELD_CONTRACT_TYPES.direct_employee,
    },
  ]
  const types = useSelector(
    (state) => state?.Layout?.staticData?.attribute_types,
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: state.data ?? {
      name: '',
      type_id: '',
      required: false,
      active: false,
      area: '',
      contract_types: {
        fixed: false,
        payg: false,
        milestone: false,
        eor: false,
        direct_employee: false,
      },
      // If the type is select, the default is two options (and the min number)
      options: [{ text: '' }, { text: '' }],
    },
    resolver: yupResolver(schema),
  })

  const isSelectType = watch('type_id') === SELECT_TYPE_ID
  const contractTypes = watch('contract_types')
  const area = watch('area')

  function _onSubmit(values) {
    const data = {
      ...values,
      contract_types: Object.keys(values.contract_types).filter(
        (key) => values.contract_types[key],
      ),
    }

    if (
      Array.isArray(values.options) &&
      Number(values.type_id) === SELECT_TYPE_ID
    ) {
      data.options = values.options.map((option) => option.text)
    } else {
      delete data.options
    }

    onSubmit(data)
  }

  return (
    <>
      <SideMenu
        onClose={toggle}
        isOpen={state.show}
        className='!tw-w-full tw-max-w-[612px] tw-text-start'
        itemListClassName={cx(
          'tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain',
          'tw-grid-rows-[auto_1fr_91px]',
        )}
      >
        <SideMenuHeader toggle={toggle}>
          <div className='tw-flex tw-flex-col'>
            <div className='tw-font-semibold'>
              {title || t('New custom field')}
            </div>
            <span className='tw-text-sm tw-text-text-60'>
              {subtitle || t('Create a custom field')}
            </span>
          </div>
        </SideMenuHeader>

        <SideMenuBody>
          <form
            onSubmit={handleSubmit(_onSubmit)}
            id={formId}
            className='tw-flex tw-flex-col tw-gap-6'
          >
            <ControlledInput
              control={control}
              name='name'
              id='name'
              label={t('Custom field name')}
              placeholder={t('Custom field name')}
            />

            <ControlledSelect
              control={control}
              name='type_id'
              id='type_id'
              label={t('Field type')}
              options={types?.map((type) => ({
                label: type.name,
                value: type.id,
              }))}
            />

            <FieldOptions
              control={control}
              name='options'
              isSelectType={isSelectType}
            />

            <ControlledToggle
              control={control}
              name='required'
              marginRight={false}
              inputClassName='tw-order-2'
              labelClassName='tw-text-sm'
              wrapperClassName='tw-mb-0 tw-flex tw-items-center tw-p-4 tw-rounded tw-border tw-border-surface-30 tw-justify-between'
              label={<div className='tw-font-bold'>{t('Required')}</div>}
            />

            <ControlledToggle
              control={control}
              name='active'
              marginRight={false}
              inputClassName='tw-order-2'
              labelClassName='tw-text-sm'
              wrapperClassName='tw-mb-0 tw-flex tw-items-start tw-p-4 tw-rounded tw-border tw-border-surface-30 tw-justify-between'
              label={
                <>
                  <div className='tw-font-bold'>{t('Visibility')}</div>
                  <div className='tw-text-text-80'>
                    {t('Enable or disable this custom field')}
                  </div>
                </>
              }
            />

            <ListFieldWithDescription
              label={t('Area')}
              description={t(
                'Choose the area which you want this field to be added to',
              )}
              error={errors.area}
            >
              <ControlledRadioList
                name='area'
                control={control}
                options={areaOptions}
                fancyLabelClassName='tw-flex tw-flex-col tw-justify-center tw-w-full tw-relative'
                isFancy
                transform={{
                  output: (event) => {
                    if (event.target.value === AREA_TYPES.work) {
                      // Work is selected, unselect all unrelated contract types
                      setValue('contract_types', {
                        ...contractTypes,
                        fixed: false,
                        eor: false,
                        direct_employee: false,
                      })
                    }

                    return event
                  },
                }}
              />
            </ListFieldWithDescription>

            <ListFieldWithDescription
              label={t('Contracts')}
              description={t(
                'Choose the type of contracts which you want this field to be added to',
              )}
              error={errors.contract_types?.root}
            >
              <ControlledCheckboxList
                control={control}
                name='contract_types'
                isFancy
                horizontal
                options={fieldContractTypesOptions.map((option) => ({
                  ...option,
                  disabled:
                    area === AREA_TYPES.work &&
                    !['payg', 'milestone'].includes(option.value),
                }))}
              />
            </ListFieldWithDescription>
          </form>
        </SideMenuBody>

        <SideMenuFooter>
          {onDelete && typeof onDelete === 'function' ? (
            <Button
              color='link'
              className='tw-me-auto !tw-px-0 !tw-text-red-100'
              outline
              onClick={onDelete}
              disabled={actionsLoading}
            >
              {t('Delete')}
            </Button>
          ) : null}

          <Button
            color='light'
            outline
            onClick={toggle}
            disabled={actionsLoading}
          >
            {t('Cancel')}
          </Button>
          <Button
            formId={formId}
            disabled={actionsLoading}
            loading={actionsLoading}
          >
            {t('Save')}
          </Button>
        </SideMenuFooter>
      </SideMenu>
    </>
  )
}

function ListFieldWithDescription({ label, description, error, children }) {
  return (
    <div>
      <legend className='tw-mb-0 tw-text-wrap tw-text-base tw-font-bold'>
        {label}
      </legend>
      <p className='tw-text-wrap tw-text-sm tw-text-text-80'>{description}</p>

      {!error ? null : (
        <InputFeedback className='-tw-mt-3 tw-mb-4'>
          {getInputErrorMessage(error)}
        </InputFeedback>
      )}

      {children}
    </div>
  )
}

function CheckBoxOptionLabel({ label, description, isActive }) {
  return (
    <>
      <span
        className={cx(
          'tw-text-wrap tw-font-semibold',
          !isActive && 'tw-text-black',
        )}
      >
        {label}
      </span>
      {!description ? null : (
        <span
          className={cx(
            'tw-text-wrap tw-font-normal',
            !isActive && 'tw-text-text-80',
          )}
        >
          {description}
        </span>
      )}

      <div className='tw-absolute tw-top-4 ltr:tw-right-4 rtl:tw-left-4'>
        {isActive ? (
          <CheckSquare size={20} weight='fill' />
        ) : (
          <Square size={20} />
        )}
      </div>
    </>
  )
}

function RadioOptionLabel({
  label,
  description,
  isActive,
  checkClassName = 'tw-absolute rtl:tw-left-0 ltr:tw-right-0 tw-top-0',
}) {
  return (
    <>
      <span className={cx('tw-font-semibold', !isActive && 'tw-text-black')}>
        {label}
      </span>
      {!description ? null : (
        <span
          className={cx(
            'tw-text-wrap tw-font-normal',
            !isActive && 'tw-text-text-80',
          )}
        >
          {description}
        </span>
      )}

      <div className={cx(checkClassName)}>
        {isActive ? (
          <CheckCircle size={20} weight='fill' />
        ) : (
          <Circle size={20} />
        )}
      </div>
    </>
  )
}

function FieldOptions({ control, name, isSelectType }) {
  const { fields, append, remove } = useFieldArray({ control, name })

  if (!isSelectType) {
    return null
  }

  return (
    <div className='tw-border tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
      <div className='tw-text-base tw-font-semibold tw-text-black'>
        {t('Custom field options')}
      </div>
      <div className='tw-text-sm tw-font-normal tw-text-text-80'>
        {t('You can add at least two options')}
      </div>

      <div className='tw-mt-4 tw-flex tw-flex-col tw-gap-4'>
        {fields.map((field, index) => {
          return (
            <div key={field.id} className='tw-flex tw-items-center tw-gap-2'>
              <ControlledInput
                control={control}
                name={`${name}[${index}].text`}
                id={`${name}[${index}].text`}
                placeholder={`Option ${index + 1}`}
                wrapperClassName='tw-flex-grow'
                className='!tw-h-14 !tw-text-sm'
              />

              {fields.length <= 2 ? null : (
                <button
                  className='tw-flex tw-h-14 tw-w-14 tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-surface-30 tw-bg-white tw-p-2 tw-text-systemRed-100 tw-ring-systemRed-50 tw-transition-colors hover:tw-bg-systemRed-10 focus-visible:tw-ring-2'
                  onClick={() => remove(index)}
                  type='button'
                >
                  <TrashSimple size={24} />
                </button>
              )}
            </div>
          )
        })}

        <button
          className='tw-flex tw-w-full tw-gap-2 tw-rounded tw-border tw-border-dashed tw-border-primary-100 tw-bg-primary-10 tw-p-4 tw-text-base tw-font-bold tw-text-primary tw-ring-primary-80 tw-ring-offset-2 tw-transition-colors hover:tw-bg-primary-20 focus-visible:tw-ring-2'
          onClick={() => append({ text: '' })}
          type='button'
        >
          <PlusCircle size={24} weight='bold' />
          <span>Add option</span>
        </button>
      </div>
    </div>
  )
}
