import React from 'react'

import { useFetch } from '../../helpers/hooks'
import { getPayoneerLink } from '../../services/api'
import { t } from 'i18next'

export default function PayoneerAccount({
  alreadyHaveAnAccount = true,
  redirectUrl,
}) {
  const parsedUrl = new URL(redirectUrl ?? window.location?.href)
  const { isLoading, data, completed } = useFetch({
    action: getPayoneerLink,
    autoFetch: true,
    body: {
      redirect_url: parsedUrl.origin + parsedUrl.pathname,
      already_have_an_account: alreadyHaveAnAccount,
    },
  })

  if (!isLoading && completed) {
    window.location.replace(data?.registration_link)
  }

  return <div className='px-3 py-4'>{t('Redirecting to Payoneer ...')}</div>
}
