import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { Card } from 'reactstrap'

import Footer from '../../../components/VerticalLayout/Footer'
import Button from '../../../components/ui/button'
import { HeaderAnonym } from '../../../components/ui/header-anonym'
import { updateAdminToken } from '../../../store/auth/login/actions'
import googleGLogo from './../../../assets/images/Google__G__Logo.svg'

export default function LoginAdmin() {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const token = new URLSearchParams(location.search).get('token')

  if (token) {
    dispatch(updateAdminToken(token))
    history.push('/admin/home')
  }

  return (
    <React.Fragment>
      <HeaderAnonym />

      <h1 className='tw-mb-4 tw-mt-6 tw-text-center tw-text-xl tw-text-secondary-120 sm:tw-text-3xl md:tw-mb-6 md:tw-mt-12'>
        Admin panel
      </h1>

      <div className='tw-mt-12 tw-px-4'>
        <Card className='rp-shadow-2 tw-mx-auto tw-max-w-md tw-p-4 md:tw-p-6'>
          <Button
            tag='a'
            block
            color='light'
            outline
            href={`${process.env.REACT_APP_API_HOST}/saml2/google/login`}
          >
            <img
              className='mr-3'
              src={googleGLogo}
              style={{
                height: 16,
                display: 'inline',
              }}
              alt=''
            />
            Continue with Google
          </Button>
        </Card>

        <Footer className='tw-mb-4 tw-mt-12 tw-text-center' />
      </div>
    </React.Fragment>
  )
}
