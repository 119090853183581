import { yupResolver } from '@hookform/resolvers/yup'
import {
  useConnection,
  useIntegration,
  useIntegrationApp,
} from '@integration-app/react'
import { t } from 'i18next'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import ControlledCheckbox from '../../../components/controlled-checkbox'
import ControlledInput from '../../../components/ControlledInput'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import { useFetch } from '../../../helpers/hooks'
import { getConnectIntegration } from '../../../services/api-integrations'
import LabelContent from '../../Contract/CreateContract/components/label-content'

export function ConnectIntegrationModal({
  isOpen,
  toggle,
  integrationKey,
  onConnectionSuccess,
}) {
  const [connecting, setConnecting] = useState(false)

  const integrationApp = useIntegrationApp()
  const { integration, loading, error } = useIntegration(integrationKey)
  useConnection(integrationKey)

  const authOption = integration?.authOptions?.[0]
  const schema = authOption?.ui?.schema

  const fields = schema
    ? Object.entries(schema?.properties ?? {}).map(([key, field]) => ({
        key,
        required: schema?.required?.includes(key),
        ...field,
      }))
    : []

  const { handleSubmit, control } = useForm({
    // @todo: use fields to create a proper schema
    resolver: yupResolver(yup.object()),
  })

  async function onSubmit(data) {
    setConnecting(true)

    const connection = await integrationApp
      .integration(integrationKey)
      .connect({
        parameters: data,
        authOptionKey: authOption.key,
      })

    if (connection) {
      onConnectionSuccess(connection)
    } else {
      toastr.error(t('Error connecting to integration'))
    }
    setConnecting(false)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      {error ? (
        <p>
          {t('Error:')}
          {error.message}
        </p>
      ) : loading || !integration ? (
        <Loader />
      ) : (
        <>
          <ModalHeader close={<ModalCloseButton toggle={toggle} />}>
            {t('Connect to')}
            {integration?.name}
          </ModalHeader>

          <ModalBody>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='tw-flex tw-flex-col tw-gap-3'
            >
              {fields?.map((field) => {
                const labelElement = (
                  <LabelContent required={field.required}>
                    {field.title}
                  </LabelContent>
                )

                if (field.type === 'boolean') {
                  return (
                    <div key={field.key}>
                      {labelElement}
                      <ControlledCheckbox
                        control={control}
                        name={field.key}
                        label={t('Yes')}
                        leftLabel={t('No')}
                        containerClassName='tw-mt-1'
                        className='!tw-inline-flex tw-cursor-pointer tw-rounded tw-py-0.5 tw-pr-1.5 hover:tw-bg-surface-20'
                      />
                    </div>
                  )
                }

                return (
                  <ControlledInput
                    key={field.key}
                    control={control}
                    name={field.key}
                    label={labelElement}
                  />
                )
              })}

              <Button type='submit' loading={connecting} disabled={connecting}>
                {t('Connect')}
              </Button>
            </form>
          </ModalBody>
        </>
      )}
    </Modal>
  )
}

export function useConnectCustomIntegration({
  onConnectionSuccess,
  redirectUriPath,
} = {}) {
  const { startFetch: connectIntegration, isLoading: isConnecting } = useFetch({
    action: getConnectIntegration,
    onComplete: (data) => {
      window.location.href = data
      onConnectionSuccess?.()
    },
  })

  function onConnect({ integration }) {
    connectIntegration({
      integrationKey: integration.key,
      redirectUriPath:
        redirectUriPath || `/settings/integrations/${integration.key}/setup`,
    })
  }

  return { onConnect, isConnecting }
}

export function ConnectCustomIntegrationModal({
  isOpen,
  toggle,
  integration,
  onConnectionSuccess,
}) {
  const { onConnect, isConnecting } = useConnectCustomIntegration({
    integration,
    onConnectionSuccess,
  })

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader close={<ModalCloseButton toggle={toggle} />}>
        {t('Connect to')}
        {integration?.name}
      </ModalHeader>

      <ModalBody>
        <Button
          block
          onClick={onConnect}
          loading={isConnecting}
          disabled={isConnecting}
        >
          {t('Connect')}
        </Button>
      </ModalBody>
    </Modal>
  )
}
