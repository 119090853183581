import { Warning } from '@phosphor-icons/react'
import { t } from 'i18next'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import toastr from 'toastr'

import { cn } from 'ui'
import ConfirmationModal from '../../../../components/Common/ConfirmationModal'
import { ModalCloseButton } from '../../../../components/Common/modal-close-button'
import TimeOffPolicyDetails from '../../../../components/timeoff-policy-menu-details'
import Button from '../../../../components/ui/button'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../../../components/ui/side-menu'
import { useFetch } from '../../../../helpers/hooks'
import { deleteTimeOffPolicy } from '../../../../services/api-time-off-policies'
import { TimeOffPolicyTypes } from '../../../Contract/utils/constants'
import { DetailsInfoList } from '../../../review-center/review-layout-details-components'

export function ViewPolicy({ policy, refetchPolicies, toggleMenu, isOpen }) {
  const [showEdit, setShowEdit] = useState(false)

  function toggleShowEdit() {
    setShowEdit((showEdit) => !showEdit)
  }

  return (
    <>
      <SideMenu
        itemListClassName='tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain tw-grid-rows-[minmax(auto,max-content)_1fr_91px]'
        isOpen={isOpen}
        onClose={toggleMenu}
        className='xl:tw-min-w-[35%]'
      >
        <SideMenuHeader toggle={toggleMenu}>{policy?.name}</SideMenuHeader>
        <SideMenuBody className='tw-flex-grow !tw-p-0 !tw-pb-5'>
          <TimeOffPolicyDetails
            isSettingsOpenByDefault={
              policy?.type?.id !== TimeOffPolicyTypes.VACATION_POLICY_TYPE
            }
            isAccrualInfoOpenByDefault
            showWorkingDays
            policy={policy}
          />
        </SideMenuBody>

        <SideMenuFooter>
          <DeleteAction policy={policy} onSuccess={refetchPolicies} />

          <Button type='button' onClick={toggleMenu} color='light' outline>
            {t('Close')}
          </Button>
          <Button type='button' onClick={toggleShowEdit}>
            {t('Edit')}
          </Button>
        </SideMenuFooter>

        <EditAction isOpen={showEdit} policy={policy} toggle={toggleShowEdit} />
      </SideMenu>
    </>
  )
}

function TenureRuleRow({ label, value }) {
  return (
    <div className='tw-flex tw-items-center tw-justify-between tw-text-text-70'>
      <div>{label}</div>
      <div>{value}</div>
    </div>
  )
}

export function TenureRulesList({ policy }) {
  return (
    <div className='tw-flex tw-flex-col tw-gap-1'>
      <TenureRuleRow
        label={t('First year')}
        value={(policy.accrual_days ?? policy.amount) + ' ' + t('Days / Year')}
      />
      {policy.tenure_rules?.map((rule, index) => {
        const minYears = rule.min_years ?? rule.tenure
        const timeOffAmount = rule.time_off_amount ?? rule.amount
        return (
          <TenureRuleRow
            label={t('afterYears', { count: minYears })}
            value={`${timeOffAmount} ${t('Days / Year')}`}
            key={`tenure-rule-${index}`}
            rule={rule}
          />
        )
      })}
    </div>
  )
}

export function DetailsList({ title, items, titleClassName, bodyClassName }) {
  return (
    <div>
      {!title ? null : (
        <h4
          className={cn(
            'tw-z-[1] tw-mb-1 tw-mt-6 tw-flex tw-items-center tw-gap-1 tw-px-6 tw-text-base tw-font-bold',
            titleClassName,
          )}
        >
          <span>{title}</span>
        </h4>
      )}

      <DetailsInfoList
        className={cn(title && '-tw-my-6', bodyClassName)}
        items={items}
      />
    </div>
  )
}

function DeleteAction({ policy, onSuccess }) {
  const [isModalOpen, setIsModalOpen] = useState()
  function toggle() {
    setIsModalOpen((open) => !open)
  }

  const { startFetch: deletePolicy, isLoading: deletingPolicy } = useFetch({
    action: deleteTimeOffPolicy,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Failed to delete policy')
      } else {
        toastr.success('Policy deleted successfully')
        toggle()
        onSuccess?.()
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  return (
    <>
      <Button
        type='button'
        onClick={toggle}
        color='link'
        className='tw-mr-auto !tw-px-0 !tw-text-systemRed-100 focus:!tw-ring focus:!tw-ring-systemRed-20'
      >
        {t('Delete')}
      </Button>

      <ConfirmationModal
        isOpen={isModalOpen}
        toggle={toggle}
        negativeCaption={t('No, Close')}
        caption={t('Yes, delete')}
        buttonColor='danger'
        onConfirm={() => {
          deletePolicy({ policyId: policy?.id })
        }}
        confirmLoading={deletingPolicy}
        content={
          <>
            <div className='tw-relative tw-flex tw-items-center tw-justify-between tw-pb-2'>
              <Warning size={24} className='tw-text-systemRed-100' />
              <ModalCloseButton toggle={toggle} />
            </div>
            <h4 className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
              {t('Are you sure you want to delete policy', {
                policy: policy?.name,
              })}
            </h4>
            <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
              {t(
                'Neither existing nor future contracts will be able to use this policy',
              )}
            </p>
          </>
        }
      />
    </>
  )
}

export function EditAction({ policy, isOpen, toggle }) {
  const history = useHistory()
  return (
    <>
      <ConfirmationModal
        isOpen={isOpen}
        toggle={toggle}
        negativeCaption={t('No, Close')}
        caption={t('Yes, continue')}
        onConfirm={() => history.push('/time-off-policies/add', policy)}
        confirmLoading={false}
        content={
          <>
            <div className='tw-relative tw-flex tw-items-center tw-justify-between tw-pb-2'>
              <Warning size={24} className='tw-text-systemGold-100' />
              <ModalCloseButton toggle={toggle} />
            </div>
            <h4 className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
              {t('Are you sure you want to edit this policy?')}
            </h4>
            <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
              {t(
                'The updates will be applied to all contracts assigned to this policy',
              )}
            </p>
          </>
        }
      />
    </>
  )
}
