import React from 'react'
import { cn } from 'ui'

function CustomRadio({
  items,
  name,
  renderItem,
  className,
  onSelect,
  checkedItem,
}) {
  return items.map((item) => {
    const isChecked = checkedItem ? checkedItem === item.value : undefined
    return (
      <label
        key={item.value}
        htmlFor={item.value}
        className={cn(
          'tw-rounded tw-p-6 tw-font-semibold tw-ring-1 tw-ring-surface-30 has-[:checked]:tw-font-bold has-[:checked]:tw-text-primary has-[:checked]:tw-ring-2 has-[:checked]:tw-ring-primary-100',
          className,
        )}
      >
        {renderItem?.(item)}
        <input
          name={name}
          type='radio'
          id={item.value}
          className='tw-absolute tw-hidden'
          value={item.value}
          onChange={onSelect}
          checked={isChecked}
        />
      </label>
    )
  })
}

export default CustomRadio
