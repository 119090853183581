import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { useLocation } from 'react-router-dom'

import { cn } from 'ui'

export function PageNav({ children, className }) {
  return (
    <Nav
      className={cn(
        '!tw-flex-nowrap tw-overflow-x-auto tw-overflow-y-hidden tw-border-b tw-border-surface-30 tw-text-sm',
        className,
      )}
    >
      {children}
    </Nav>
  )
}

function PageNavLink({ children, isActive, className, ...props }) {
  return (
    <NavLink
      className={cn(
        'tw-mx-0 tw-h-full tw-border-b-2 tw-border-t-2 tw-border-transparent tw-border-t-transparent !tw-px-3 !tw-py-4 tw-text-start first:tw-rounded-tl last:tw-rounded-tr md:!tw-py-6',
        isActive
          ? 'tw-border-b-current tw-bg-primary-10 !tw-text-primary-100 [&>.rp-tab-count-wrapper]:tw-bg-primary [&>.rp-tab-count-wrapper]:tw-text-white'
          : '!tw-text-text-90 hover:!tw-text-text-120',
        className,
      )}
      style={{ whiteSpace: 'nowrap' }}
      {...props}
    >
      {children}
    </NavLink>
  )
}

function PageNavItem({ children }) {
  return <NavItem>{children}</NavItem>
}

function PageNavCount({ value }) {
  return (
    <div className='rp-tab-count-wrapper tw-flex tw-size-5 tw-items-center tw-justify-center tw-rounded tw-border tw-border-surface-30 tw-text-xs tw-font-bold'>
      {value}
    </div>
  )
}

export function useActiveTab({ defaultTab, param = 'tab' }) {
  const { search } = useLocation()
  const activeTab = new URLSearchParams(search).get(param) ?? defaultTab

  return { activeTab }
}

PageNav.Link = PageNavLink
PageNav.Item = PageNavItem
PageNav.Count = PageNavCount
