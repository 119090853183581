import {
  CalendarBlank,
  CalendarCheck,
  CoinVertical,
  Info,
  ListDashes,
  Money,
  Notebook,
  Storefront,
} from '@phosphor-icons/react'
import { t } from 'i18next'
import React from 'react'
import { useSelector } from 'react-redux'

import { useBillData } from '.'
import Button from '../../../components/ui/button'
import { SideMenu } from '../../../components/ui/side-menu'
import { useHideIntercomForPage } from '../../../helpers/hooks'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import {
  getCurrencyLabel,
  getOptionFromList,
} from '../../../utils/map-to-option'
import {
  FormSectionHr,
  FormSectionTitle,
} from '../../Contract/CreateContract/components/form-section-title'
import { DetailsInfoList } from '../../review-center/review-layout-details-components'
import { VendorBankDetails } from '../detail-section-title'

export function CreateBillPreview({
  values,
  onBack,
  onSave,
  onPayNow,
  submitLoading,
}) {
  const { currencies = [] } = useSelector(
    (state) => state?.Layout?.staticData ?? {},
  )

  const { categoriesList } = useBillData()

  const selectedVendor = values?.vendor_id?.__isNew__
    ? { name: values?.vendor_id?.label }
    : values.vendor_id?.raw

  const { items = [] } = values

  const currency = getOptionFromList(currencies, values?.currency_id, 'id')
  const formatter = getCurrencyFormatter(currency?.code)
  const currencyLabel = getCurrencyLabel(currency)
  useHideIntercomForPage()

  return (
    <div className='tw-grid tw-h-full tw-grid-rows-[1fr_auto] [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain'>
      <div>
        <DetailsInfoList
          title={
            <FormSectionTitle
              title={t('Details')}
              Icon={Info}
              className='tw-mb-1'
            />
          }
          items={[
            {
              icon: <Storefront size={24} />,
              label: t('Vendor'),
              value: selectedVendor?.name,
            },
            {
              icon: <Notebook size={24} />,
              label: t('Category'),
              value: getOptionFromList(categoriesList, values?.category_id)
                ?.label,
            },
            {
              icon: <CalendarBlank size={24} />,
              label: t('Issue date'),
              value: values?.issue_date,
            },
            {
              icon: <CalendarCheck size={24} />,
              label: t('Due date'),
              value: values?.due_date,
            },
            {
              icon: <Money size={24} />,
              label: t('Amount'),
              value: formatter.format(values?.total_amount),
            },
            {
              icon: <CoinVertical size={24} />,
              label: t('Currency'),
              value: currencyLabel,
            },
          ]}
        />

        {!items || items?.length <= 0 ? null : (
          <>
            <FormSectionHr className='!tw-mx-0 !tw-my-4' />

            <DetailsInfoList
              title={
                <FormSectionTitle
                  title={t('Items')}
                  Icon={ListDashes}
                  className='tw-mb-1'
                />
              }
              items={
                items.map((item) => {
                  const category = getOptionFromList(
                    categoriesList,
                    item?.category_id,
                  )?.label

                  return {
                    label: (
                      <TitleAndSub title={item?.description} sub={category} />
                    ),

                    value: (
                      <TitleAndSub
                        title={formatter.format(item?.amount)}
                        sub={currencyLabel}
                      />
                    ),

                    valueClassName: 'tw-text-end',
                    className: '!tw-flex-nowrap',
                  }
                }) ?? []
              }
            />
          </>
        )}

        {!selectedVendor?.bank_account ? null : (
          <>
            <FormSectionHr className='!tw-mx-0 !tw-my-4' />
            <div className='tw-px-6'>
              <VendorBankDetails
                bankInfo={selectedVendor?.bank_account}
                vendor={selectedVendor}
              />
            </div>
          </>
        )}
      </div>

      <SideMenu.Footer className='tw-sticky tw-bottom-0 tw-mt-auto tw-bg-white'>
        <Button
          type='button'
          color='light'
          outline
          onClick={onBack}
          className='tw-mr-auto'
          disabled={submitLoading}
        >
          {t('Back')}
        </Button>

        {!onPayNow || typeof onPayNow !== 'function' ? null : (
          <Button
            type='button'
            color='light'
            outline
            onClick={onPayNow}
            icon={<Money size={20} />}
            disabled={submitLoading}
            loading={submitLoading}
          >
            {t('Pay now')}
          </Button>
        )}
        <Button
          type='button'
          onClick={onSave}
          disabled={submitLoading}
          loading={submitLoading}
        >
          {t('Save')}
        </Button>
      </SideMenu.Footer>
    </div>
  )
}

function TitleAndSub({ title, sub }) {
  return (
    <>
      {title ? (
        <div className='tw-font-semibold tw-text-black'>{title}</div>
      ) : null}
      {sub ? <div className='tw-font-normal tw-text-text-80'>{sub}</div> : null}
    </>
  )
}
