import React from 'react'
import { useParams } from 'react-router-dom'
import { useConnectCustomIntegration } from './connect-integration-modal'
import { getIntegration } from '../../../services/api-integrations'
import { useFetch } from '../../../helpers/hooks'
import { integrationDataMap } from '.'
import Loader from '../../../components/ui/loader'

export function ExternalIntegrationConnection() {
  const params = useParams()

  const { onConnect } = useConnectCustomIntegration()

  const {
    isLoading: customIntegrationLoading,
    error,
    data,
  } = useFetch(
    {
      action: getIntegration,
      autoFetch: !!params.integrationKey,
      body: { integrationKey: params.integrationKey },
      onComplete: (data) => {
        if (data?.success !== false) {
          onConnect({ integration: integrationDataMap(data) })
        }
      },
    },
    [params.integrationKey],
  )

  if (error) {
    return <LoadingScreen>Error connecting</LoadingScreen>
  }

  if (customIntegrationLoading) {
    return <LoadingScreen>Preparing connection ...</LoadingScreen>
  }

  return (
    <LoadingScreen>
      Connecting to {data?.name || params.integrationKey} ...
    </LoadingScreen>
  )
}

function LoadingScreen({ children }) {
  return (
    <div className='tw-flex tw-h-screen tw-flex-col tw-items-center tw-justify-center tw-gap-8 tw-overflow-auto tw-px-4'>
      <Loader
        minHeight=''
        type='border'
        className='*:!tw-size-24 *:!tw-animate-spinTwo'
      />

      <h3 className='tw-text-2xl'>{children}</h3>
    </div>
  )
}
