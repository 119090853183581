import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import * as yup from 'yup'

import ControlledInput from '../../components/ControlledInput'
import ControlledPhoneInput from '../../components/ControlledPhoneInput'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import { useFetch } from '../../helpers/hooks'
import { addCashPoint } from '../../services/api'
import CashPointCard from '../Withdrawal/components/CashPointCard'
import useWithdrawAccounts from './use-contractor-withdraw-accounts'
import { t } from 'i18next'

const schema = yup.object().shape({
  beneficiary: yup.string().required(),
  id_number: yup.string().required(),
  phone: yup.string().required(),
})

const CashPointAccount = ({ onNext = () => {}, onAdd, editing }) => {
  const [checkedCard, setCheckedCard] = useState(-1)
  const [showModal, setShowModal] = useState(false)

  const {
    getAccountsList,
    accounts,
    loading: gettingAccounts,
  } = useWithdrawAccounts('cash')

  function onSuccess() {
    setShowModal(false)
    getAccountsList()
    onAdd()
  }

  function toggle() {
    setShowModal((open) => !open)
  }

  return (
    <>
      <div className='h-100'>
        {gettingAccounts ? (
          <Loader minHeight='15rem' />
        ) : editing ? (
          <div className='p-0 d-flex flex-column' style={{ gap: '1rem' }}>
            {accounts?.map((e, i) => (
              <CashPointCard
                onClick={() => {
                  onNext(e)
                  setCheckedCard(i)
                }}
                key={`ppAcc-${i}`}
                card={e}
                index={i}
                checked={checkedCard === i}
              />
            ))}
            <button
              onClick={toggle}
              className='rp-btn-nostyle align-self-start text-primary rp-font-bold'
              type='button'
            >
              {'+ ' + t('Add beneficiary details')}
            </button>
          </div>
        ) : (
          <CashPointForm onSuccess={onSuccess} onCancel={toggle} />
        )}
      </div>

      <Modal
        isOpen={showModal}
        scrollable
        centered
        className='newAccountModal'
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>{t('Add Cash Point account')}</ModalHeader>
        <ModalBody className='p-0'>
          <CashPointForm onSuccess={onSuccess} onCancel={toggle} />
        </ModalBody>
      </Modal>
    </>
  )
}

function CashPointForm({ onSuccess, onCancel }) {
  const user = useSelector((state) => state.userProfile?.userProfile)
  const fullName = [user?.first_name, user?.middle_name, user?.last_name]
    .filter(Boolean)
    .join(' ')

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { beneficiary: fullName },
  })

  const onSubmit = (d) => {
    addCashPointAccount.startFetch(d)
  }

  const addCashPointAccount = useFetch({
    action: addCashPoint,
    onComplete: () => {
      onSuccess?.()
    },
  })

  return (
    <Form
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
      className='d-flex flex-column h-100 pt-4 px-4'
    >
      <FormGroup>
        <Label>
          {t('Name')}

          <span className='text-danger font-size-16 mx-1'>*</span>
        </Label>
        <ControlledInput
          disabled={true}
          control={control}
          error={errors.beneficiary}
          name='beneficiary'
        />
      </FormGroup>
      <FormGroup>
        <Label>
          {t('ID Number (Passport or government ID number)')}
          <span className='text-danger font-size-16 mx-1'>*</span>
        </Label>
        <ControlledInput
          control={control}
          error={errors.id_number}
          name='id_number'
        />
      </FormGroup>
      <FormGroup>
        <Label>
          {t('Phone Number')}
          <span className='text-danger font-size-16 mx-1'>*</span>
        </Label>
        <ControlledPhoneInput
          defaultCountry='lb'
          control={control}
          name='phone'
        />
      </FormGroup>
      <ModalFooter className='px-0 mt-auto'>
        <Button
          color='light'
          outline
          type='button'
          onClick={onCancel}
          disabled={addCashPointAccount.isLoading}
        >
          {t('Cancel')}
        </Button>
        <Button
          type='submit'
          loading={addCashPointAccount.isLoading}
          disabled={addCashPointAccount.isLoading}
        >
          {t('Add account')}
        </Button>
      </ModalFooter>
    </Form>
  )
}

export default CashPointAccount
