import { XCircle } from '@phosphor-icons/react'
import { filter, includes, map, pull } from 'lodash'
import omit from 'lodash/omit'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import { cn } from 'ui'
import SearchBar from '../../../../../components/SearchBar'
import { CheckItem } from '../../../../../components/ui/check-item'
import { RemoveFilterModal } from '../modals/remove-filter-modal'

const Divider = () => <div className='tw-h-[1px] tw-w-full tw-bg-text-10' />

export function FilterButtons(props) {
  const { name, filters } = props
  const { getValues, setValue } = useFormContext()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const toggle = () => setDropdownOpen((prevState) => !prevState)
  const [selected, setSelected] = useState(getValues(`filters.${name}`))
  const [search, setSearch] = useState('')
  const currentFilter = useMemo(
    () => filters.all.find((a) => a.filter === name),
    [filters.all, name],
  )

  const items = useMemo(() => {
    return (filters?.[name] ?? []).filter((i) =>
      i.title.toLowerCase().includes(search.toLowerCase()),
    )
  }, [filters, name, search])

  const getLabel = useCallback(
    (value) => {
      const selectedTexts = map(
        filter(filters[name], (i) => includes(value, i.value)),
        'title',
      )
      return currentFilter?.title + (value?.length ? ` : ${selectedTexts}` : '')
    },
    [filters, currentFilter, name],
  )

  return (
    <Controller
      name={`filters.${name}`}
      render={({ field: { value = [], onChange } }) => (
        <>
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle tag='div'>
              <button
                id='add-filter-button'
                className={cn(
                  'tw-flex tw-flex-row tw-gap-1 tw-rounded-full tw-border tw-border-surface-30 tw-px-4 tw-py-1.5',
                  dropdownOpen && 'tw-bg-primary tw-text-white',
                )}
              >
                {getLabel(value)}
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    setDeleteModal(true)
                  }}
                >
                  <XCircle size={20} weight='fill' />
                </button>
              </button>
            </DropdownToggle>
            <DropdownMenu className='tw-w-[250px] tw-overflow-auto'>
              <SearchBar
                timeout={0}
                className='tw-m-4 tw-basis-full'
                query={search}
                placeholder='Find'
                onQueryChanged={setSearch}
                isClearable
                inputClassName='!tw-h-auto'
              />
              <Divider />
              <div className='tw-flex tw-max-h-[300px] tw-flex-col tw-gap-4 tw-overflow-auto tw-px-4 tw-py-4'>
                {items.map((f, index) => {
                  const checked = value?.includes?.(f.value)
                  return (
                    <CheckItem
                      key={`${f.title}-${index}`}
                      label={f.title}
                      defaultChecked={checked}
                      onChange={(e) => {
                        setSelected((prev) =>
                          !e.target.checked
                            ? pull(prev, f.value)
                            : [...prev, f.value],
                        )
                      }}
                    />
                  )
                })}
              </div>
              <Divider />
              <div className='tw-m-4 tw-flex tw-flex-1 tw-flex-row tw-justify-between'>
                <button
                  onClick={() => {
                    toggle()
                    setSelected([])
                    onChange([])
                  }}
                  className='tw-font-medium'
                >
                  Clear All
                </button>
                <button
                  onClick={() => {
                    toggle()
                    onChange(selected)
                  }}
                  className='tw-font-medium tw-text-primary'
                >
                  Apply
                </button>
              </div>
            </DropdownMenu>
          </Dropdown>

          <RemoveFilterModal
            title={currentFilter?.title}
            onRemove={() => {
              setValue('filters', omit(getValues('filters'), [name]))
            }}
            toggle={() => setDeleteModal((prev) => !prev)}
            isOpen={deleteModal}
          />
        </>
      )}
    />
  )
}
