import { t } from 'i18next'
import React from 'react'
import { cn } from 'ui'
import BadgeV2 from './badge-v2'

export function NewBadge({ className }) {
  return (
    <BadgeV2
      noPadding
      className={cn(
        'tw-rounded-full tw-px-1 tw-py-0.5 tw-font-bold tw-uppercase',
        className,
      )}
      size='sm'
      color='primary'
    >
      {t('New')}
    </BadgeV2>
  )
}
