import { t } from 'i18next'
import { Eye, PlusMinus } from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Card, CardBody, Col, Row } from 'reactstrap'
import capitalize from 'lodash/capitalize'
import { ActionsDropdown } from 'ui'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import BadgeX from '../../../components/Table/BadgeX'
import StyledTd from '../../../components/Table/StyledTd'
import StyledTh from '../../../components/Table/StyledTh'
import TableComp from '../../../components/Table/TableComp'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import Pagination from '../../../components/ui/pagination'
import {
  SideMenu,
  SideMenuBody,
  SideMenuHeader,
} from '../../../components/ui/side-menu'
import { userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions, useResize } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  approvePayments,
  cancelPayment,
  declinePayments,
  getAdminEORContractsAdjustments,
  getContractAdjustments,
  revertCancelledPayment,
} from '../../../services/api'
import { updateToPayList } from '../../../store/payment/actions'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { getFullName } from '../../../utils/get-full-name'
import { useFilters } from '../../AdminPanel/pages/cards/use-filters'
import { usePayrollApprovalEnabled } from '../../CompanySetting'
import { getPaymentIds } from '../../payInvoices'
import AdjustmentsModal from '../components/AdjustmentsModal'
import TabCardHeader from '../components/tab/tab-card-header'
import TabEmpty from '../components/tab/tab-empty'
import { TimelineType2 } from '../../../components/item-timeline'
import { WorkAttributes } from './submitted-work'

const Adjustments = ({ contract, isAdmin, contractLoading }) => {
  const [show, setShow] = useState(false)

  const [filters, handleFiltersChange] = useFilters({ page: 1, per_page: 5 })

  const user = useSelector((state) => state.Account?.user)
  const isMobile = useResize()
  const { hasAccess } = usePermissions()

  const {
    isLoading: loadingAdjustments,
    startFetch: refetchAdjustments,
    data: adjustmentsList,
    paginator: AdjustmentsPaginator,
  } = useFetch(
    {
      action: isAdmin
        ? getAdminEORContractsAdjustments
        : getContractAdjustments,
      body: { contract_id: contract?.id },
      autoFetch: !!contract?.id,
      withAdminAccess: !!isAdmin,
    },
    [contract?.id, filters],
  )

  const updateAdjustments = (loading = true) => {
    if (contract?.id) {
      refetchAdjustments({ contract_id: contract.id }, loading)
    }
  }
  const showAdjustBtn =
    (user?.type === userTypes.COMPANY || isAdmin) &&
    contract.type === 'Full Time' &&
    contract?.status?.id === 4
  const adjustBtnDisabled =
    [6].includes(contract?.status?.id) ||
    (!hasAccess(permissions.addAdjustments) && !isAdmin)

  return loadingAdjustments || contractLoading ? (
    <Loader minHeight='30rem' />
  ) : (
    <>
      <Card className='rp-shadow-2'>
        <TabCardHeader
          title={t('Adjustments')}
          extra={
            !showAdjustBtn || adjustmentsList?.length <= 0 ? null : (
              <Button
                disabled={adjustBtnDisabled}
                onClick={() => {
                  window.analytics.track('Clicked adjust', {
                    contract_id: contract?.ref,
                    contract_type: contract?.type?.name,
                    contract_status: contract?.status?.name,
                  })
                  setShow(true)
                }}
                iconRight={<PlusMinus size={20} />}
              >
                {t('Adjust')}
              </Button>
            )
          }
        />

        {adjustmentsList?.length === 0 ? (
          <TabEmpty
            title={t('No adjustment to show')}
            subtitle={t('Adjustments will be listed here')}
            icon={<PlusMinus size={250} color='var(--primary)' />}
          >
            {showAdjustBtn ? (
              <Button
                disabled={adjustBtnDisabled}
                onClick={() => {
                  window.analytics.track('Clicked adjust', {
                    contract_id: contract?.ref,
                    contract_type: contract?.type?.name,
                    contract_status: contract?.status?.name,
                  })
                  setShow(true)
                }}
                iconRight={<PlusMinus size={20} />}
              >
                {t('Adjust')}
              </Button>
            ) : null}
          </TabEmpty>
        ) : (
          <CardBody className='px-0'>
            <div className='table-responsive'>
              {isMobile ? (
                <div className='p-3' style={{ minHeight: '70vh' }}>
                  {React.Children.toArray(
                    adjustmentsList?.map((order, key) => (
                      <Line
                        key={key}
                        isAdmin={isAdmin}
                        item={order}
                        onUpdate={() => updateAdjustments(false)}
                      />
                    )),
                  )}
                </div>
              ) : (
                <TableComp className='table-centered'>
                  <thead className='thead-light'>
                    <tr>
                      <StyledTh>{t('Type')}</StyledTh>
                      <StyledTh>{t('Reason')}</StyledTh>
                      <StyledTh>{t('Added on')}</StyledTh>
                      <StyledTh>{t('Effective date')}</StyledTh>
                      <StyledTh>{t('Amount')}</StyledTh>
                      <StyledTh>{t('Status')}</StyledTh>
                      <StyledTh />
                    </tr>
                  </thead>
                  <tbody>
                    {React.Children.toArray(
                      adjustmentsList?.map((order, key) => (
                        <Line
                          key={key}
                          isAdmin={isAdmin}
                          item={order}
                          onUpdate={() => updateAdjustments(false)}
                        />
                      )),
                    )}
                  </tbody>
                </TableComp>
              )}
            </div>

            <div className='mt-2 mt-md-0 d-flex justify-content-md-end p-4.5'>
              <Pagination
                innerClass='pagination mb-0'
                activePage={filters?.page}
                onChange={(page) => handleFiltersChange('page', page)}
                itemsCountPerPage={AdjustmentsPaginator?.per_page ?? 50}
                totalItemsCount={AdjustmentsPaginator?.total ?? 0}
              />
            </div>
          </CardBody>
        )}
      </Card>

      {show && (
        <AdjustmentsModal
          contract={contract}
          isAdmin={isAdmin}
          show={show}
          hide={() => setShow(false)}
          updated={() => {
            updateAdjustments(false)
            setShow(false)
          }}
        />
      )}
    </>
  )
}

const getStatusColor = (status) => {
  switch (status) {
    case 'pending':
      return 'warning'
    case 'approved':
      return 'success'
    case 'declined':
      return 'danger'
    case 'Cancelled':
      return 'secondary'
    default:
      return 'primary'
  }
}

function Line({ item, onUpdate, isAdmin }) {
  const contract = useSelector((state) => state.Contract?.details)
  const formatter = getCurrencyFormatter(contract?.salary_currency?.code)

  const isMobile = useResize()

  const info = [
    { label: t('Reason'), value: item?.type },
    { label: t('Amount'), value: formatter.format(item?.amount) },
    {
      label: t('Added on'),
      value: format(new Date(item?.created_at), 'dd-MM-yy'),
    },
    {
      label: t('Effective date'),
      value: format(new Date(item?.effective_date), 'dd-MM-yy'),
    },
  ]

  return (
    <>
      {isMobile ? (
        <div className='bg-white border mb-3 position-relative py-0 rounded rp-shadow-2'>
          <Row className='p-3 m-0 border-bottom'>
            <Col xs={8} className='p-0 m-0'>
              <p className='font-size-14 rp-font-bold mb-0'>{t(item.kind)}</p>
            </Col>
            <Col
              xs={4}
              className='p-0 m-0 justify-content-end align-items-center d-flex'
            >
              <BadgeX
                status={getStatusColor(item.status)}
                textStatus={getStatusColor(item.status)}
                name={t(capitalize(item.status))}
              />
            </Col>
          </Row>

          <div className='p-3 d-flex flex-column gap-12'>
            {info.map(({ label, value }, key) => {
              return (
                <div
                  key={key}
                  className='d-flex align-items-center justify-content-between align-items-center'
                >
                  <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                    {label}
                  </h6>
                  <h6 className='mb-0 text-dark font-weight-normal font-size-14'>
                    {value}
                  </h6>
                </div>
              )
            })}
            <ActionSection
              item={item}
              isAdmin={isAdmin}
              onUpdate={onUpdate}
              showStatus={false}
            />
          </div>
        </div>
      ) : (
        <>
          <tr>
            <StyledTd>
              <span className='font-size-14'>{t(item.kind)}</span>
            </StyledTd>
            <StyledTd>
              <span className='font-size-14'>{t(item.type)}</span>
            </StyledTd>
            <StyledTd>
              {format(new Date(item?.created_at), 'dd-MM-yy')}
            </StyledTd>
            <StyledTd>
              {format(new Date(item?.effective_date), 'dd-MM-yy')}
            </StyledTd>
            <StyledTd>{formatter.format(item.amount)}</StyledTd>
            <StyledTd>
              <div className='d-flex justify-content-between align-items-center gap-10'>
                <ActionSection
                  item={item}
                  isAdmin={isAdmin}
                  onUpdate={onUpdate}
                />

                <ItemActionsDropdown
                  item={item}
                  onUpdate={onUpdate}
                  isAdmin={isAdmin}
                />
              </div>
            </StyledTd>
            <StyledTd>
              <AdjustmentSideMenu contract={contract} adjustment={item} />
            </StyledTd>
          </tr>
        </>
      )}
    </>
  )
}

function ActionSection({ item, isAdmin, onUpdate, showStatus = true }) {
  const [showConfirmation, setShowConfirmation] = useState(false)

  const { hasAccess } = usePermissions()

  const payrollApprovalEnabled = usePayrollApprovalEnabled()

  const canManagePayments = item?.can_approve

  const user = useSelector((state) => state.Account?.user)
  const dispatch = useDispatch()
  const history = useHistory()

  const { startFetch: cancelItem, isLoading: cancelingItem } = useFetch({
    action: cancelPayment,
    onComplete: onUpdate,
  })
  const { startFetch: _approvePayment, isLoading: approvingPayments } =
    useFetch({
      action: approvePayments,
      onComplete: onUpdate,
    })
  const { startFetch: _declinePayment, isLoading: decliningPayments } =
    useFetch({
      action: declinePayments,
      onComplete: onUpdate,
    })

  function handleClickPay() {
    const ids = getPaymentIds([item])
    dispatch(updateToPayList(ids))
    history.push('/pay-invoices')
  }

  const statusBadge = (
    <BadgeX
      status={getStatusColor(item.status)}
      textStatus={getStatusColor(item.status)}
      name={t(capitalize(item.status))}
    />
  )

  if (!!isAdmin || user?.type !== userTypes.COMPANY) {
    return statusBadge
  }

  const isPending = item?.approval_status === 'pending' // 'declined'
  const isDeclined = item?.approval_status === 'declined'

  const showApprovalProcess = payrollApprovalEnabled && item.status === 'Unpaid'

  const approvalProcessLoading = approvingPayments || decliningPayments

  if (showApprovalProcess) {
    if (isDeclined) {
      return <BadgeX status='danger'>{t('Declined')}</BadgeX>
    } else if (isPending) {
      if (canManagePayments) {
        return (
          <div className='d-flex gap-10'>
            <Button
              size='sm'
              color='success'
              onClick={() => _approvePayment({ payment_ids: [item?.id] })}
              loading={approvingPayments}
              disabled={approvalProcessLoading}
              className='font-size-14 flex-grow-1 flex-md-grow-0'
            >
              {t('Approve')}
            </Button>
            <Button
              size='sm'
              color='danger'
              outline
              onClick={() => _declinePayment({ payment_ids: [item?.id] })}
              loading={decliningPayments}
              disabled={approvalProcessLoading}
              className='font-size-14 flex-grow-1 flex-md-grow-0'
            >
              {t('Decline')}
            </Button>
          </div>
        )
      } else {
        return <BadgeX status='secondary'>{t('Pending approval')}</BadgeX>
      }
    }
  }

  const hasPermissions = hasAccess(permissions.PrepareTransactions)
  const showPayButton = hasPermissions && item.status === 'Unpaid'
  const showCancelButton =
    hasPermissions && item.status !== 'Cancelled' && item.status === 'Unpaid'

  if (!showCancelButton && !showPayButton && !showStatus) {
    return null
  }

  return (
    <>
      <div className='d-flex gap-10'>
        {showPayButton ? (
          <>
            {item?.amount === 0 ? null : (
              <Button
                size='sm'
                color='primary'
                outline
                onClick={handleClickPay}
                icon={<i className='bx bx-money font-size-18' />}
                className='font-size-14 flex-grow-1 flex-md-grow-0'
              >
                {t('Pay')}
              </Button>
            )}
          </>
        ) : !showStatus ? null : (
          statusBadge
        )}

        {!showCancelButton ? null : (
          <Button
            size='sm'
            color='danger'
            outline
            onClick={() => {
              setShowConfirmation(true)
            }}
            icon={<i className='bx bxs-x-circle font-size-18' />}
            className='font-size-14 border-transparent bg-soft-danger hover:bg-danger flex-grow-1 flex-md-grow-0'
            loading={cancelingItem}
            disabled={cancelingItem}
          >
            {t('Cancel')}
          </Button>
        )}
      </div>

      <ConfirmationModal
        toggle={() => setShowConfirmation((o) => !o)}
        isOpen={showConfirmation}
        title={t('Cancel Payment')}
        caption='Cancel Payment'
        buttonColor='danger'
        onConfirm={() => {
          setShowConfirmation(false)
          cancelItem({ payment_id: item?.id })
        }}
        message={t('Are you sure you want to cancel this payment?')}
        negativeCaption={t('Close')}
      />
    </>
  )
}

function ItemActionsDropdown({ item, onUpdate, isAdmin }) {
  const [revertModalOpen, setRevertModalOpen] = useState(false)

  const user = useSelector((state) => state.Account?.user)
  const userCanRevert = !isAdmin && user?.type === userTypes.COMPANY

  const { startFetch: revertPayment, isLoading: revertingPayment } = useFetch({
    action: revertCancelledPayment,
    onComplete: () => {
      setRevertModalOpen(false)
      onUpdate?.()
    },
  })

  const hasActivities = item?.activities?.length > 0

  if (!hasActivities) return null

  const lastActivity = item?.activities[item?.activities?.length - 1]

  return (
    <>
      <ActionsDropdown
        data={[
          {
            label: getActivityPhrase(lastActivity),
            className: 'tw-capitalize rp-disabled',
            disabled: true,
          },
          item.status !== 'Cancelled' || !userCanRevert
            ? null
            : {
                label: t('Revert status'),
                className:
                  'tw-decoration-[var(--primary)] tw-text-[var(--primary)] tw-underline hover:tw-no-underline',
                onClick: () => setRevertModalOpen(true),
              },
        ]}
      />

      <ConfirmationModal
        toggle={() => setRevertModalOpen((o) => !o)}
        isOpen={revertModalOpen}
        title={t('Revert cancelled payment')}
        caption={t('Revert cancelled payment')}
        buttonColor='danger'
        onConfirm={() => {
          revertPayment({ payment_id: item?.id })
        }}
        message={t('Are you sure you want to revert this cancelled payment?')}
        negativeCaption={t('Close')}
        confirmLoading={revertingPayment}
      />
    </>
  )
}

function getActivityPhrase(activity) {
  if (!activity) return null

  const formattedDate = format(
    new Date(activity?.date * 1000),
    'dd/MM/yy HH:mm',
  )

  return (
    <>
      {t('ActivityStatus', {
        status: activity?.status,
        user: activity?.user,
        date: formattedDate,
      })}
    </>
  )
}

export default Adjustments

export function AdjustmentSideMenu({ contract, adjustment }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const formatter = getCurrencyFormatter(contract?.salary_currency?.code)

  function toggle() {
    setIsMenuOpen((isOpen) => !isOpen)
  }

  return (
    <>
      <Button
        color='link'
        onClick={toggle}
        type='button'
        className='!tw-px-0 !tw-py-1'
        icon={<Eye size={20} className='tw-text-primary' />}
      >
        {t('Details')}
      </Button>

      <SideMenu
        onClose={toggle}
        isOpen={isMenuOpen}
        className='!tw-z-[1050] !tw-w-full tw-max-w-[532px] tw-text-black'
        itemListClassName='tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain tw-grid-rows-[auto_1fr]'
      >
        <SideMenuHeader toggle={toggle} className='!tw-items-start'>
          <div>
            <p className='font-size-20 tw-mb-0 tw-font-semibold'>
              {t(adjustment?.type)}
            </p>
            <p className='font-size-14 tw-mb-0 tw-text-text-60'>
              {t(adjustment?.sub_type)}
            </p>
          </div>
        </SideMenuHeader>

        <SideMenuBody>
          <h4 className='tw-font-semibold'>{t('Details')}</h4>
          <WorkAttributes invoice={adjustment?.timeline} />
          <div className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-b-surface-30 tw-py-4'>
            <div>
              <p className='font-size-14 mb-0 tw-text-text-80'>
                {t('Contractor')}
              </p>
            </div>
            <div className='font-size-14 !tw-px-6 tw-font-semibold tw-text-text-100'>
              {getFullName(contract?.contractor)}
            </div>
          </div>
          <div className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-b-surface-30 tw-py-4'>
            <div>
              <p className='font-size-14 mb-0 tw-text-text-80'>{t('Amount')}</p>
            </div>
            <div className='font-size-14 !tw-px-6 tw-font-semibold tw-text-text-100'>
              {formatter.format(adjustment?.amount)}
            </div>
          </div>
          <h4 className='tw-mb-4 tw-mt-8 tw-font-semibold'>{t('Note')}</h4>
          <div className='font-size-14 tw-text-text-80'>{adjustment?.note}</div>
          <TimelineType2 flowTimeline={adjustment?.timeline} />
        </SideMenuBody>
      </SideMenu>
    </>
  )
}
