import { Spinner, TrashSimple } from '@phosphor-icons/react'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { useSelector } from 'react-redux'
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import toastr from 'toastr'

import { cn } from 'ui'
import bankAccount from '../../assets/images/bankAccount.svg'
import brexLogo from '../../assets/images/brands/brex.png'
import ConfirmationModal from '../../components/Common/ConfirmationModal'
import Button from '../../components/ui/button'
import { BREX } from '../../core/config/payment-methods'
import { userTypes } from '../../helpers/enum'
import { useFetch, usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import {
  addContractorPaymentMethod,
  addPaymentMethod,
  deleteCC,
  deleteContractorCC,
  deleteSavedBrexAccount,
  getContractorPaymentMethods,
  getPaymentMethods,
  setupAch,
} from '../../services/api'
import { track } from '../../utils/analytics'
import StripeForm from '../due-payments-page/CheckoutForm'
import SepaForm from '../due-payments-page/SEPAForm'
import americanExpress from './../../assets/images/american_express.svg'
import creditCardLogo from './../../assets/images/creditCard.svg'
import discoverLogo from './../../assets/images/discover.svg'
import jcbLogo from './../../assets/images/jcb_logo.svg'
import masterCard from './../../assets/images/master-card.svg'
import sepaLogo from './../../assets/images/sepa_logo.svg'
import unionPay from './../../assets/images/union_pay.svg'
import visaLogo from './../../assets/images/visa_logo.svg'

const CreditCards = ({ onAccountAdded = () => {}, edit, plaidToken }) => {
  const [cards, setCards] = useState([])
  const [checkedCard, setCheckedCard] = useState(-1)
  const [editing, setEditing] = useState(false)
  const [editingSepa, setEditingSepa] = useState(false)
  const [menu, setMenu] = useState(false)
  const [actionLoading, setActionLoading] = useState(false)
  const user = useSelector((state) => state.Account?.user)

  const getMethods =
    user?.type === userTypes.COMPANY
      ? getPaymentMethods
      : getContractorPaymentMethods
  const addMethods =
    user?.type === userTypes.COMPANY
      ? addPaymentMethod
      : addContractorPaymentMethod
  const updateList = () => {
    getMethods(user?.token).then((r) => {
      if (r.data.success) {
        setCards(r.data.data)
      }
    })
  }

  const setupACH = useFetch({
    action: setupAch,
    onComplete: updateList,
  })
  const onSuccess = (publicToken, data) => {
    setupACH.startFetch({
      public_token: publicToken,
      account_id: data?.account_id,
    })
  }

  const config = {
    token: plaidToken,
    onSuccess,
  }
  const { open } = usePlaidLink(config)

  useEffect(() => {
    updateList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCCSubmitted = (stripData) => {
    if (stripData.error) {
      toastr.error(
        typeof stripData.error === 'string'
          ? stripData.error
          : 'An error occurred',
      )
      return
    }
    setActionLoading(true)
    addMethods(user?.token, {
      payment_method_id:
        stripData?.paymentMethod?.id || stripData?.setupIntent?.payment_method,
    })
      .then((r) => {
        if (r.data.success) {
          setEditing(false)
          onAccountAdded(r.data.data)
          updateList()
          toastr.success(r.data.message)
          window.analytics.track('Add payment Method', {
            email_id: user?.email,
          })
        } else {
          setActionLoading(false)
        }
      })
      .catch(() => {
        setActionLoading(false)
      })
  }

  return (
    <Row className='justify-content-center m-0 p-0'>
      <ModalSEPA
        editing={editingSepa}
        hide={() => setEditing(false)}
        handleCCSubmitted={handleCCSubmitted}
        actionLoading={actionLoading}
      />
      <ModalCreditCard
        editing={editing}
        hide={() => setEditing(false)}
        handleCCSubmitted={handleCCSubmitted}
        actionLoading={actionLoading}
      />
      <Col className='p-0 m-0' md={12}>
        {!edit && (
          <Row className='justify-content-center p-0 m-0'>
            <Col md={8} className='border-bottom border-light'>
              <h1 className='font-weight-light text-center'>
                {t('Select an account')}
              </h1>
            </Col>
          </Row>
        )}
        <Row className='p-0 m-0'>
          <Col md={12} className='p-md-4 pt-3 px-3'>
            {cards.map((e, i) => (
              <CCItem
                withBorder={i !== cards?.length - 1}
                key={e?.id}
                card={e}
                index={i}
                onCheck={() => {
                  setCheckedCard(i)
                }}
                onUpdate={updateList}
                withRemove
                checked={checkedCard === i}
              />
            ))}
          </Col>
        </Row>
      </Col>
      <div className='w-100 border-top' />
      <div className='p-md-4 p-3 w-100'>
        <Dropdown
          isOpen={menu}
          toggle={() => {
            setMenu(!menu)
          }}
        >
          <DropdownToggle
            className='btn h-100 m-0 p-0 header-item waves-effect text-primary rp-font-bold'
            id='withdraw-method-dropdown'
            tag='button'
          >
            <Button
              text={t('Add New Method')}
              style={{ width: window.innerWidth <= 745 ? '100%' : undefined }}
              className='font-size-14'
            />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              tag='a'
              onClick={() => {
                setEditing(true)
              }}
            >
              {t('Add Credit / Debit Card')}
            </DropdownItem>

            <DropdownItem
              tag='a'
              onClick={() => {
                setEditingSepa(true)
              }}
            >
              {t('Add SEPA')}
            </DropdownItem>
            <DropdownItem
              tag='a'
              onClick={() => {
                open()
              }}
            >
              {t('Add ACH')}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </Row>
  )
}

function CardWrapper({ clickable, children, onClick, className }) {
  return clickable ? (
    <button
      type='button'
      onClick={onClick}
      className={cn('tw-w-full', className)}
    >
      {children}
    </button>
  ) : (
    <div className={cn('tw-w-full', className)}>{children}</div>
  )
}

export const CCItem = ({
  card,
  onCheck,
  checked,
  onUpdate,
  updateBrexAccounts,
  isEditing,
}) => {
  const user = useSelector((state) => state.Account?.user)
  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  const { hasAccess } = usePermissions()

  const isClient = user?.type === userTypes.COMPANY

  const removeMethods = isClient ? deleteCC : deleteContractorCC

  const [showConfirmation, setShowConfirmation] = useState(false)

  function onSuccessfulDelete() {
    track('Removed payment method', { email_id: user?.email })
  }

  const deleteCard = useFetch({
    autoFetch: false,
    initResult: null,
    action: removeMethods,
    onComplete: () => {
      onUpdate()
      onSuccessfulDelete()
    },
  })

  const { startFetch: deleteBrexCard, isLoading: isDeletingBrex } = useFetch({
    action: deleteSavedBrexAccount,
    onComplete: () => {
      updateBrexAccounts()
      onSuccessfulDelete()
    },
  })

  const isDeleting = deleteCard.isLoading || isDeletingBrex

  const getIcon = () => {
    if (card?.type === 'sepa_debit') {
      return sepaLogo
    } else if (card?.type === 'card') {
      switch (card?.card?.brand) {
        case 'jcb':
          return jcbLogo
        case 'visa':
          return visaLogo
        case 'amex':
          return americanExpress
        case 'discover':
          return discoverLogo
        case 'mastercard':
          return masterCard
        case 'unionpay':
          return unionPay
        default:
          return creditCardLogo
      }
    } else if (card.type === BREX.type) {
      return brexLogo
    } else {
      return bankAccount
    }
  }
  const getAccountName = () => {
    if (card?.type === 'sepa_debit') {
      return `SEPA Direct Debit **** ${card?.sepa_debit?.last4}`
    } else if (card?.type === 'card') {
      switch (card?.card?.brand) {
        case 'jcb':
          return `JCB **** ${card?.card?.last4}`
        case 'visa':
          return `VISA **** ${card?.card?.last4}`
        default:
          return `VISA **** ${card?.card?.last4}`
      }
    } else {
      return `${card?.bank_name} **** ${card.last4}`
    }
  }

  const isClickable =
    hasAccess([
      permissions.ViewTransactionsMethods,
      permissions.PrepareTransactions,
    ]) && !isEditing

  return (
    <div className='tw-relative'>
      <CardWrapper
        onClick={onCheck}
        clickable={isClickable}
        className={cn(
          'tw-flex tw-w-full tw-items-center tw-gap-2 tw-rounded tw-border tw-border-surface-30 tw-bg-white tw-p-4 tw-text-start',
          checked
            ? 'tw-border-primary-40 tw-bg-primary-30'
            : isEditing
              ? ''
              : 'hover:tw-bg-surface-10',
        )}
      >
        {!card?.type ? (
          <div
            className='tw-flex tw-items-center tw-justify-center tw-overflow-clip tw-rounded tw-bg-surface-20'
            style={{ height: '38px', width: '38px' }}
          >
            <img
              src={getIcon()}
              style={{ height: '24px', width: '24px' }}
              alt=''
            />
          </div>
        ) : (
          <img
            className='tw-overflow-clip tw-rounded'
            src={getIcon()}
            style={{ height: '38px', width: '38px' }}
            alt=''
          />
        )}
        <Row className='p-0 m-0 pl-1 align-items-center justify-content-between'>
          <Col className='m-0 p-0'>
            <div
              className='text-dark font-size-14 rp-font-bold'
              style={{ fontWeight: 600 }}
            >
              {getAccountName()}{' '}
            </div>
            {!!card?.card && (
              <div className='text-secondary font-size-12'>
                {t('Expires')}
                {card?.card?.exp_month}/{card?.card?.exp_year}{' '}
              </div>
            )}
          </Col>
        </Row>
        <ConfirmationModal
          buttonColor='danger'
          toggle={() => setShowConfirmation(false)}
          onConfirm={() => {
            if (card?.type === BREX.type) {
              deleteBrexCard({
                userId: user?.id,
                companyId: userProfile?.company?.id,
              })
            } else {
              deleteCard.startFetch({ id: card?.id })
            }
            setShowConfirmation(false)
          }}
          content={
            <p className='tw-mb-0 tw-text-center tw-text-sm tw-text-text-80'>
              {t('Are you sure you want to remove this payment method?')}
            </p>
          }
          title={t('Remove Payment Method')}
          isOpen={showConfirmation}
          caption='Remove'
        />
      </CardWrapper>

      {isEditing && (
        <div className='tw-absolute tw-end-3 tw-top-1/2 -tw-translate-y-1/2'>
          {isDeleting ? (
            <Spinner
              className='tw-size-3 tw-animate-spinTwo tw-text-systemRed-100'
              weight='bold'
            />
          ) : !isClient || hasAccess(permissions.ManageCompanyPayment) ? (
            <button
              type='button'
              className='tw-flex tw-rounded tw-p-1.5 tw-text-text-90 hover:tw-bg-surface-20'
              onClick={(e) => {
                e.stopPropagation()
                setShowConfirmation(true)
              }}
            >
              <TrashSimple size={16} weight='fill' />
            </button>
          ) : null}
        </div>
      )}
    </div>
  )
}

function ModalCreditCard(props) {
  const { editing, hide, handleCCSubmitted, actionLoading } = props

  return (
    <div>
      <Modal
        centered
        isOpen={editing}
        toggle={hide}
        className='creditCardModal'
      >
        <ModalHeader toggle={hide}>{t('Add New Card')}</ModalHeader>
        <ModalBody className='p-0 m-0'>
          <StripeForm
            onSubmitted={handleCCSubmitted}
            loading={actionLoading}
            onCancel={hide}
          />
        </ModalBody>
      </Modal>
    </div>
  )
}

const ModalSEPA = (props) => {
  const { editing, hide, handleCCSubmitted, actionLoading } = props

  return (
    <div>
      <Modal
        centered
        isOpen={editing}
        toggle={hide}
        className='creditCardModal'
      >
        <ModalHeader toggle={hide}>{t('Add New Account')}</ModalHeader>
        <ModalBody className='p-0 m-0'>
          <SepaForm
            onSubmitted={handleCCSubmitted}
            loading={actionLoading}
            onCancel={hide}
          />
        </ModalBody>
      </Modal>
    </div>
  )
}

export default CreditCards
