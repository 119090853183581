import { yupResolver } from '@hookform/resolvers/yup'
import { FilePlus, TrashSimple, XCircle } from '@phosphor-icons/react'
import { format } from 'date-fns'
import { t } from 'i18next'
import { isNumber } from 'lodash'
import React, { useRef, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Container, Spinner } from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { cn } from 'ui'
import UploadPreview from '../../../components/Common/upload-preview'
import ControlledDropzoneInput from '../../../components/controlled-dropzone-input'
import Head from '../../../components/head'
import ModalHeader from '../../../components/ModalHeader'
import { BackgroundDots } from '../../../components/ui/background-dots'
import Button from '../../../components/ui/button'
import FEATURE_FLAGS from '../../../config/feature-flags'
import { toBase64 } from '../../../helpers/helper'
import { useFetch } from '../../../helpers/hooks'
import { parseInvoiceV2, uploadTempFile } from '../../../services/api'
import {
  createBill,
  createVendor,
  getBill,
  getBillCategories,
  getVendors,
  updateBill,
} from '../../../services/api-bill-payments'
import { track } from '../../../utils/analytics'
import { mapListToOption } from '../../../utils/map-to-option'
import { getEntitiesData } from '../../AdminPanel/pages/Partners/eor-partner-invoice-modal'
import { MAX_SIZE_READABLE } from '../../Contract/utils/constants'
import { useBillModuleActive } from '../bill-permission-hook'
import CreateBillForm, { defaultBillInvoiceItem } from './create-bill-form'
import { CreateBillPreview } from './create-bill-preview'

export function CreateAndEditBill() {
  const formPreviewRef = useRef(null)

  const history = useHistory()
  const { billId } = useParams()

  const isEditPath = history.location.pathname.includes('bills/edit')

  const isEdit = isEditPath && !!billId

  const { currencies = [] } = useSelector(
    (state) => state?.Layout?.staticData ?? {},
  )

  function handleReturn() {
    history.push(history.location.state?.backRoute ?? '/bills')
  }

  const { data: categories, isLoading: fetchingCategories } = useFetch({
    action: getBillCategories,
    autoFetch: true,
    onError: toastr.error,
  })

  const [previewImage, setPreviewImage] = useState(null)

  const { token: userToken, id: userID } = useSelector(
    (state) => state.Account?.user,
  )

  useBillModuleActive()
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isValidating },
  } = useForm({
    defaultValues: { items: [{ ...defaultBillInvoiceItem }] },
    resolver: yupResolver(
      yup.object().shape({
        vendor_id: yup
          .mixed()
          .test(
            'test-vendor_id',
            t('Should be a valid vendor'),
            async (value) => {
              if (!value) {
                return false
              }

              // Checking this shape: { label: string, value: string, __isNew__: boolean }
              if (value?.__isNew__ === true) {
                return typeof value?.value === 'string'
              }

              const vendors = (
                await getVendors(userToken, { search_key: value?.label })
              )?.data?.data

              if (vendors?.length >= 0) {
                return vendors.some(
                  (vendor) => String(vendor.id) === String(value?.value),
                )
              }

              if (!['string', 'number'].includes(typeof value?.value)) {
                return false
              }

              return true
            },
          )
          .label(t('Vendor')),
        category_id: yup
          .number()
          .oneOf(
            (categories || []).map((c) => c.id),
            t('Please select a valid category'),
          )
          .required()
          .label(t('Category')),
        issue_date: yup.date().required().label(t('Issue date')),
        due_date: yup.date().required().label(t('Due date')),
        total_amount: yup
          .number()
          .typeError(t('Please enter a valid number'))
          .required()
          .label(t('Total amount')),
        currency_id: yup
          .string()
          .oneOf(currencies.map((c) => String(c.id)))
          .required()
          .label(t('Currency')),
        items: yup.array().of(
          yup.object().shape({
            description: yup.string().required().label(t('Description')),
            amount: yup
              .number()
              .typeError(t('Please enter a valid number'))
              .required()
              .label('Amount'),
            category_id: yup
              .number()
              .oneOf(
                (categories || []).map((c) => c.id),
                t('Please select a valid category'),
              )
              .required()
              .label(t('Category')),
          }),
        ),
      }),
    ),
  })

  const {
    startFetch: uploadFile,
    isLoading: isUploading,
    error: uploadError,
    data: uploadedFile,
  } = useFetch({
    action: uploadTempFile,
    onComplete: (data) => {
      if (data.full_path) {
        setPreviewImage((prev) => ({ ...prev, data: data.full_path }))
        setValue('file', data?.path)
      } else {
        toastr.error(t('Error uploading file'))
      }
    },
    onError: (err) => {
      toastr.error(err, t('Error uploading file'))
    },
  })

  const values = useWatch({ control })

  const invoiceNotUploadedCorrectly =
    !!values?.file && (!!uploadError || !uploadedFile)

  const [scannedField, setScannedField] = useState([])
  const [foundItems, setFoundItems] = useState({
    items: [],
    itemsHaveBeenUpdated: false,
  })

  function onParsed(data) {
    const entities = getEntitiesData(data?.entities)
    const meta = getMetaInfo(data?.entities)

    const resetValues = {}

    if (meta?.due_date?.value) {
      resetValues.due_date = format(new Date(meta.due_date.value), 'yyyy-MM-dd')
    }
    if (meta?.invoice_date?.value) {
      resetValues.issue_date = format(
        new Date(meta.invoice_date.value),
        'yyyy-MM-dd',
      )
    }
    if (meta?.invoice_id?.value) {
      resetValues.details = meta.invoice_id.value
    }
    if (meta?.total_amount?.value) {
      resetValues.total_amount = meta.total_amount.value
    }
    if (meta?.currency?.value) {
      const foundCurrency = currencies.find(
        (currency) => currency.code === meta.currency.value,
      )
      if (foundCurrency) {
        resetValues.currency_id = foundCurrency?.id
      }
    }

    const totalEntitiesPlusVat =
      (entities?.length ?? 0) + (isNumber(meta?.vat?.value) ? 1 : 0)
    if (totalEntitiesPlusVat > 0) {
      const foundItems = entities.map(({ amount, desc: description }) => {
        return { description, amount }
      })
      if (meta?.vat?.value) {
        foundItems.push({ description: 'VAT', amount: meta.vat.value })
      }

      setFoundItems({ items: foundItems, itemsHaveBeenUpdated: false })
    }

    const resetValueEntries = Object.entries(resetValues)
    setScannedField(resetValueEntries.map(([key]) => key))

    resetValueEntries.forEach(([key, value]) => {
      setValue(key, value)
    })
  }

  const {
    startFetch: parseInvoice,
    isLoading: isParsing,
    data: parsedInvoiceData,
  } = useFetch({
    action: parseInvoiceV2,
    onError: (err) => toastr?.error(err),
    onComplete: onParsed,
  })

  function getIsScanned({ name }) {
    if (!parsedInvoiceData) {
      return false
    }

    return scannedField.includes(name)
  }

  const showFoundItems =
    parsedInvoiceData &&
    foundItems?.items?.length > 0 &&
    !foundItems.itemsHaveBeenUpdated

  function onExtractItems() {
    setValue('items', foundItems.items)

    setFoundItems((prev) => ({ ...prev, itemsHaveBeenUpdated: true }))
  }

  async function handleDropAccepted(files) {
    const [rawFile] = files ?? []

    const base64File = await toBase64(rawFile)
    setPreviewImage({ data: base64File, type: rawFile.type })

    // Upload the file to the server
    uploadFile({ file: rawFile, type: 'works' })
    setFoundItems({ items: [], itemsHaveBeenUpdated: false })
    if (FEATURE_FLAGS.BILL_PAYMENTS_OCR) {
      parseInvoice({ file: base64File?.split(',')[1] })
    }
    track('Uploaded Invoice', { userID })
  }

  const { isLoading: isLoadingBill } = useFetch(
    {
      action: getBill,
      body: { id: billId },
      autoFetch: isEdit,
      onComplete: (data) => {
        reset({
          vendor_id: {
            label: data?.vendor?.name,
            value: data?.vendor?.id,
            raw: data?.vendor,
          },
          category_id: data?.category?.id,
          issue_date: data?.issue_date,
          due_date: data?.due_date,
          total_amount: data?.amount,
          currency_id: data?.currency?.id,
          items: data?.items.map((item) => ({
            ...item,
            category_id: item.category.id,
          })),
          details: data?.details,
        })

        if (data?.file?.pdf) {
          setPreviewImage({ data: data?.file?.pdf, type: 'application/pdf' })
        }
      },
    },
    [billId, isEditPath],
  )

  const loadingData = fetchingCategories || isLoadingBill

  const [previewingData, setPreviewingData] = useState(null)
  function handleNewVendor({ next, vendorId }) {
    if (values.vendor_id?.__isNew__ && vendorId) {
      history.push(`/bills/create/go-to-vendor/${vendorId}`)
      return
    }

    next?.()
  }

  const { startFetch: _createBill, isLoading: creatingBill } = useFetch({
    action: createBill,
    onComplete: (data, body) => {
      if (data?.success === false) {
        toastr.error(t('Something went wrong while creating the bill'))
      } else {
        const vendorId = body?.vendor_id?.__isNew__
          ? createdVendor?.id
          : body?.vendor_id
        const vendorName = body?.vendor_id?.label ?? createdVendor?.name
        const logData = {
          userID,
          'Vendor Id': vendorId,
          'Vendor Name': vendorName,
          Category: categories?.find((c) => c.id === body?.category_id)?.name,
          isNewVendor: body?.vendor_id?.__isNew__ ? 1 : 0,
          'Issue Date': body?.issue_date,
          'Due Date': body?.due_date,
          'Total Amount': body?.amount,
          Currency: currencies.find((c) => c.id === body?.currency_id)?.name,
          items: body?.items,
          isVendorBankDetailsExist: body?.vendor_id?.row?.bank_account?.id
            ? 1
            : 0,
        }

        track('Bill Created', logData)
        toastr.success(t('Bill created successfully'))
        handleNewVendor({
          next: () => history.push('/bills'),
          vendorId: body?.vendor_id,
        })
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const { startFetch: _updateBill, isLoading: updatingBill } = useFetch({
    action: updateBill,
    onComplete: (data, body) => {
      if (data?.success === false) {
        toastr.error(t('Something went wrong while updating the bill'))
      } else {
        toastr.success(t('Bill updated successfully'))
        handleNewVendor({
          next: () => history.push('/bills'),
          vendorId: body?.vendor_id,
        })
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const {
    data: createdVendor,
    startFetch: createTheVendor,
    isLoading: creatingVendor,
  } = useFetch({
    action: createVendor,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(t('Something went wrong while creating the vendor'))
      } else {
        const logCreateVendorData = {
          userID,
          vendorID: data?.id,
          'Vendor Name': data?.name,
          Source: 'Add invoice',
        }
        track('Vendor Created', logCreateVendorData)
        handleCreateUpdateBill({ vendor_id: data?.id })
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const submitLoading = creatingVendor || creatingBill || updatingBill

  function onSubmit() {
    setPreviewingData(true)
    // scroll to the top of the form preview
    formPreviewRef.current.scrollTop = 0
  }

  function handleCreateUpdateBill(additionalValues) {
    const body = {
      amount: values.total_amount,
      currency_id: values.currency_id,
      category_id: values.category_id,
      issue_date: values.issue_date,
      due_date: values.due_date,
      vendor_id: additionalValues?.vendor_id || values.vendor_id?.value,
      items: values.items.map((item) => ({ ...item })),
      file: values?.file,
      details: values.details || undefined,
    }

    if (isEdit) {
      _updateBill({ ...body, id: billId })
    } else {
      _createBill(body)
    }
  }

  function handleSave() {
    if (values.vendor_id?.__isNew__) {
      createTheVendor({
        name: values.vendor_id.label,
        category_id: values.category_id,
      })
    } else {
      handleCreateUpdateBill()
    }
  }

  return (
    <Container fluid className='px-0'>
      <Head title={isEdit ? t('Edit bill') : t('Create bill')} />

      <ModalHeader action={handleReturn}>
        <h2 className='tw-mb-0 tw-text-center tw-text-sm tw-font-bold'>
          {isEdit ? t('Edit bill') : t('Bill creation')}
        </h2>
      </ModalHeader>

      <div className='tw-relative tw-grid tw-h-[calc(100vh-var(--header-height))] tw-overflow-hidden md:tw-grid-cols-2'>
        <BackgroundDots />
        <div
          className={cn(
            'tw-relative tw-gap-4 tw-overflow-auto tw-p-6',
            !previewImage
              ? 'tw-flex tw-flex-col'
              : 'tw-grid tw-grid-rows-[auto_1fr]',
          )}
        >
          <div className='tw-flex tw-justify-end'>
            {!previewImage ? (
              <div className='tw-h-10' />
            ) : (
              <div className='tw-flex tw-items-center tw-justify-end tw-gap-2'>
                {!isParsing ? null : (
                  <span className='tw-text-text-80'>
                    {t('Parsing file...')}

                    <Spinner
                      size='sm'
                      className='tw-ml-2 !tw-text-current tw-opacity-70'
                    />
                  </span>
                )}
                <Button
                  size='sm'
                  className='tw-size-10 !tw-border-surface-30 !tw-bg-white !tw-text-systemRed-100 hover:!tw-bg-systemRed-10'
                  icon={<TrashSimple size={16} />}
                  aria-label='Delete invoice'
                  onClick={() => {
                    setValue('file', undefined)
                    setPreviewImage(null)
                  }}
                  type='button'
                />
              </div>
            )}
          </div>

          <div
            className={cn(
              'tw-relative tw-m-auto tw-w-full tw-overflow-clip tw-rounded-lg tw-border tw-border-surface-30 tw-bg-white tw-shadow-[0px_16px_40px_0px_rgba(0,0,0,0.04)]',
              !previewImage && 'tw-max-w-[30rem] tw-p-6',
            )}
          >
            {loadingData ? (
              <div className={previewImage && 'tw-p-6'}>
                <Spinner color='primary' size='sm' />
                <p className='tw-mb-0 tw-mt-1'>{t('Loading preview ...')}</p>
              </div>
            ) : previewImage ? (
              <UploadPreview
                preview={previewImage}
                imagePreviewClassName='tw-contents'
                className='tw-size-full tw-min-h-[42rem]'
              />
            ) : (
              <>
                <XCircle className='tw-text-xl tw-text-systemRed-100' />
                <h3 className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
                  {t('No invoice added')}
                </h3>
                <p className='tw-mb-6 tw-text-sm tw-font-medium tw-text-text-80'>
                  {t(
                    'Add a valid invoice and we will fill out the fields for you',
                  )}
                </p>

                <ControlledDropzoneInput
                  control={control}
                  name='file_raw'
                  id='file_raw'
                  className='tw-rounded-lg tw-p-6 tw-transition-colors hover:tw-bg-primary-30'
                  onDropAccepted={handleDropAccepted}
                  accept={{ 'application/pdf': ['.pdf'] }}
                >
                  <div>
                    <Button
                      icon={<FilePlus size={20} />}
                      color='link'
                      className='!tw-p-0'
                      tag='div'
                    >
                      <div className='tw-hidden md:tw-block'>
                        {t('Drop file here or click to upload')}
                      </div>
                      <div className='md:tw-hidden'>{t('Click to upload')}</div>
                    </Button>

                    <p className='tw-mb-0 tw-mt-2 tw-text-xs tw-text-secondary-80'>
                      {t('*PDF only, max file size')}
                      {MAX_SIZE_READABLE}
                    </p>
                  </div>
                </ControlledDropzoneInput>
              </>
            )}
          </div>
        </div>

        <BillDataProvider
          data={{
            categoriesList: categories?.map((c) => mapListToOption(c)),
            loadingData,
            hasIntegration: !!categories?.[0]?.integration_id,
          }}
        >
          <div
            className='tw-relative tw-overflow-auto tw-bg-white'
            ref={formPreviewRef}
          >
            {previewingData ? (
              <CreateBillPreview
                values={values}
                onBack={() => {
                  setPreviewingData(false)
                }}
                onSave={handleSave}
                submitLoading={submitLoading}
              />
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <CreateBillForm
                  control={control}
                  setValue={setValue}
                  disabled={isParsing}
                  nextDisabled={
                    isUploading || invoiceNotUploadedCorrectly || isValidating
                  }
                  nextLoading={isValidating}
                  isScanned={getIsScanned}
                  showFoundItems={showFoundItems}
                  foundItemsCount={foundItems?.items?.length}
                  onExtractItems={onExtractItems}
                />
              </form>
            )}
          </div>
        </BillDataProvider>
      </div>
    </Container>
  )
}

const billDataContext = React.createContext(null)

function BillDataProvider({ data, children }) {
  return (
    <billDataContext.Provider value={data}>{children}</billDataContext.Provider>
  )
}

export function useBillData() {
  return React.useContext(billDataContext)
}

function formatStructuredData(data) {
  switch (data?.structuredValue) {
    case 'dateValue': {
      const { year, month, day } = data.dateValue
      return new Date(year, month - 1, day)
    }
    case 'moneyValue': {
      return Number(data.moneyValue.units)
    }
    default: {
      return data?.text || data
    }
  }
}
function getTextData(entity) {
  if (entity?.normalizedValue?.text) {
    return entity.normalizedValue.text
  } else if (entity?.normalizedValue?.value) {
    return entity.normalizedValue.value
  } else if (entity?.mentionText) {
    return entity.mentionText
  }
  return null
}

function getMetaInfo(entities, { confidence = 0.8 } = {}) {
  const meta = {}

  entities
    .filter((entity) => entity.confidence >= confidence)
    .forEach((entity) => {
      switch (entity.type) {
        case 'currency': {
          meta.currency = { value: getTextData(entity) }
          break
        }
        case 'due_date': {
          meta.due_date = {
            value: formatStructuredData(entity.normalizedValue),
          }
          break
        }
        case 'invoice_date': {
          meta.invoice_date = {
            value: formatStructuredData(entity.normalizedValue),
          }
          break
        }
        case 'invoice_id': {
          meta.invoice_id = { value: getTextData(entity) }
          break
        }
        case 'supplier_address': {
          meta.supplier_address = { value: getTextData(entity) }
          break
        }
        case 'supplier_name': {
          meta.supplier_name = { value: getTextData(entity) }
          break
        }
        case 'supplier_email': {
          meta.supplier_email = { value: getTextData(entity) }
          break
        }
        case 'total_amount': {
          meta.total_amount = {
            value: formatStructuredData(entity.normalizedValue),
          }
          break
        }
        case 'vat': {
          if (vatIsNumber(entity.mentionText)) {
            meta.vat = { value: Number(entity.mentionText) }
          }
          break
        }
        default: {
          break
        }
      }
    })

  return meta
}

function vatIsNumber(vat) {
  return isNumber(vat?.value) && !isNaN(vat?.value)
}
