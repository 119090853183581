import {
  Archive,
  ArchiveTray,
  CalendarBlank,
  CalendarCheck,
  Eye,
  FileArrowUp,
  PencilSimple,
  UserCircle,
  Warning,
} from '@phosphor-icons/react'
import { t } from 'i18next'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import toastr from 'toastr'
import { format } from 'date-fns'

import { ActionsDropdown, cn } from 'ui'
import ConfirmationModal from '../../../../components/Common/ConfirmationModal'
import { ModalCloseButton } from '../../../../components/Common/modal-close-button'
import { PageNav, useActiveTab } from '../../../../components/page-nav'
import Alert from '../../../../components/ui/alert'
import Button from '../../../../components/ui/button'
import Loader from '../../../../components/ui/loader'
import { useFetch, usePermissions } from '../../../../helpers/hooks'
import permissions from '../../../../helpers/permissions'
import {
  archivePolicy,
  getTimeOffPolicies,
  unArchivePolicy,
} from '../../../../services/api-time-off-policies'
import TabEmpty from '../../../Contract/components/tab/tab-empty'
import { TimeOffPolicyTypes } from '../../../Contract/utils/constants'
import {
  PolicyTypeIcon,
  policyTypeIconColors,
} from '../../../new-time-off-policy'
import { AddTimeOffPolicy } from '../manage-time-off-policy'
import {
  SettingSectionHeading,
  SettingSectionSubHeading,
} from '../settings-section-heading'
import { ManagePolicyWorkers } from './manage-policy-workers'
import { EditAction, ViewPolicy } from './view-policy'
import CustomRadio from '../../../../components/custom-radio'
import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'

const TABS = { ACTIVE: 'active', ARCHIVE: 'archive' }
const tabsData = (t) => [
  { label: t('Active policies'), key: TABS.ACTIVE },
  { label: t('Archive'), key: TABS.ARCHIVE },
]

function TabHeader({ activeTab }) {
  return (
    <PageNav className='tw-mt-4 !tw-flex-wrap tw-rounded tw-bg-white'>
      {tabsData(t).map((data) => (
        <PageNav.Item key={data.key}>
          <PageNav.Link
            to={`/settings/time-off-policies?tab=${data.key}`}
            tag={Link}
            isActive={activeTab === data.key}
            className='!tw-flex tw-items-center tw-gap-2'
          >
            {data.label}
          </PageNav.Link>
        </PageNav.Item>
      ))}
    </PageNav>
  )
}

export function TimeOffPoliciesTab() {
  const { hasAccess } = usePermissions()
  const canManageCompanySettings = hasAccess(permissions.ManageCompanyTimeOff)
  const { activeTab } = useActiveTab({ defaultTab: TABS.ACTIVE })

  return (
    <div className='tw-p-6'>
      <div className='tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-3 md:tw-flex-nowrap'>
        <div>
          <SettingSectionHeading
            learnMoreLink='https://help.remotepass.com/en/articles/9573740-default-time-off-time-off-policies#h_9ec797822e'
            learnMoreTitle={t('Learn more about time off policies')}
          >
            {t('Time Off Policies')}
          </SettingSectionHeading>

          <SettingSectionSubHeading className='tw-mb-0'>
            {t(
              'Create customized time off policies and assign them to your workers',
            )}
          </SettingSectionSubHeading>
        </div>

        <AddTimeOffPolicy hasPermission={canManageCompanySettings} />
      </div>

      <TabHeader activeTab={activeTab} />

      <div className='tw-mt-6'>
        <TimeOffPolicyList activeTab={activeTab} />
      </div>
    </div>
  )
}

function TimeOffPolicyList({ activeTab }) {
  const {
    data: policies,
    isLoading: policiesLoading,
    completed: policiesCompleted,
    startFetch: refetchPolicies,
    error: policiesError,
  } = useFetch(
    {
      action: getTimeOffPolicies,
      autoFetch: true,
      body: { archived: activeTab === TABS.ACTIVE ? '0' : '1' },
    },
    [activeTab],
  )

  const isEmpty = policies?.length <= 0

  const groupedPolicies = policies
    ?.reduce((acc, policy) => {
      const policyTypeId = policy?.type?.id

      const prevPolicies = acc.map((p) => p.type.id)

      if (prevPolicies.includes(policyTypeId)) {
        const foundIndex = acc.findIndex((p) => p.type.id === policyTypeId)
        acc[foundIndex].policies.push(policy)
      } else {
        acc.push({
          type: policy?.type,
          policies: [policy],
        })
      }

      return acc
    }, [])
    .sort((a, b) => a.type.order - b.type.order)

  return (
    <>
      {policiesError ? (
        <Alert color='danger' innerTag='div'>
          <h6 className='tw-mb-2 tw-text-sm tw-font-bold tw-text-current'>
            {t('Something went wrong while loading the time off policies')}
          </h6>
          <code className='tw-mb-0 tw-bg-systemRed-100/10 tw-text-current'>
            {policiesError}
          </code>
        </Alert>
      ) : policiesLoading || !policiesCompleted ? (
        <Loader minHeight='max(50vh, 550px)' />
      ) : isEmpty ? (
        <TabEmpty
          title={
            activeTab === TABS.ACTIVE
              ? t('No time off policy')
              : t('No archived Policies to show')
          }
          subtitle={
            activeTab === TABS.ACTIVE
              ? t('Time off policies will be listed here')
              : t('Archived policies will be shown here')
          }
          icon={
            activeTab === TABS.ACTIVE ? (
              <CalendarCheck
                size={250}
                weight='duotone'
                className='tw-text-primary'
              />
            ) : (
              <Archive
                size={250}
                weight='duotone'
                className='tw-text-primary-100'
              />
            )
          }
        />
      ) : (
        groupedPolicies?.map((group) => (
          <PolicyGroup
            key={group.type.id}
            title={group.type.name}
            policies={group.policies}
            refetchPolicies={refetchPolicies}
            companyPolicies={policies}
            onArchive={refetchPolicies}
            activeTab={activeTab}
          />
        ))
      )}
    </>
  )
}

function UnArchiveButton({ onSuccess, policy }) {
  const [showUnarchive, setShowUnarchive] = useState(false)

  function toggleShowUnArchive() {
    setShowUnarchive((showUnarchive) => !showUnarchive)
  }

  const { isLoading: isUnArchiving, startFetch: _unArchivePolicy } = useFetch({
    action: unArchivePolicy,
    onComplete: () => {
      toastr.success('Policy unarchived successfully')
      onSuccess()
    },
    onError: (error) => {
      toastr.error(error || 'An error occurred while unarchiving policy')
    },
  })

  return (
    <>
      <Button
        icon={<FileArrowUp size={20} />}
        outline
        className='tw-mt-4 tw-w-full !tw-text-primary hover:!tw-bg-white'
        type='button'
        onClick={toggleShowUnArchive}
      >
        {t('Unarchive')}
      </Button>

      <ConfirmationModal
        isOpen={showUnarchive}
        toggle={toggleShowUnArchive}
        negativeCaption={t('No, Close')}
        caption={t('Yes, Unarchive')}
        onConfirm={() => _unArchivePolicy(policy.id)}
        confirmLoading={isUnArchiving}
        content={
          <>
            <div className='tw-relative tw-flex tw-items-center tw-justify-between tw-pb-2'>
              <Warning size={24} className='tw-text-systemGold-100' />
              <ModalCloseButton toggle={toggleShowUnArchive} />
            </div>
            <h4 className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
              {t('Are you sure you want to unarchive this policy?')}
            </h4>
          </>
        }
      />
    </>
  )
}

const ARCHIVE_DAYS = { ALL: 'all_days', SOME: 'some_days' }

const archiveOptions = [
  {
    label: t('Archive all time off days'),
    value: ARCHIVE_DAYS.ALL,
    icon: <ArchiveTray weight='duotone' size={32} />,
    subTitle: t(
      'All time off days under this policy will be archived immediately.',
    ),
  },
  {
    label: t('Only archive days before a specific date'),
    value: ARCHIVE_DAYS.SOME,
    icon: <CalendarBlank weight='duotone' size={32} />,
    subTitle: t(
      'Only time off days before the selected date will be archived. Days after this date will remain active.',
    ),
  },
]

function PolicyGroup({
  title,
  policies,
  refetchPolicies,
  companyPolicies,
  onArchive,
  activeTab,
}) {
  const [showDetails, setShowDetails] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showArchive, setShowArchive] = useState(false)
  const [selectedPolicy, setSelectedPolicy] = useState({})
  const [archiveType, setArchiveType] = useState(ARCHIVE_DAYS.ALL)
  const [archiveFrom, setArchiveFrom] = useState()

  function toggleShowDetails() {
    setShowDetails((open) => !open)
  }

  function toggleShowEdit() {
    setShowEdit((showEdit) => !showEdit)
  }

  function toggleShowArchive() {
    setShowArchive((showArchive) => !showArchive)
  }

  const { isLoading: isArchiving, startFetch: _archivePolicy } = useFetch({
    action: archivePolicy,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data.message || 'An error occurred while archiving policy')
      } else {
        toastr.success('Policy archived successfully')
        onArchive()
      }
    },
    onError: (error) => {
      toastr.error(error || 'An error occurred while archiving policy')
    },
  })

  function getContractsCount(policy) {
    return policy?.contracts?.length || 0
  }

  return (
    <div className='tw-border-t tw-border-surface-30 tw-py-6 first:tw-border-t-0'>
      <SettingSectionHeading>{title}</SettingSectionHeading>

      <div className='tw-mt-4 tw-grid tw-gap-3 md:tw-grid-cols-3 xl:tw-grid-cols-4'>
        {policies?.map((item) => {
          return (
            <div
              key={item.id}
              className='tw-rounded tw-border tw-border-surface-30 tw-p-4'
            >
              <div className='tw-flex tw-flex-wrap tw-justify-between tw-gap-1'>
                <div
                  className={cn(
                    'tw-rounded tw-bg-systemGold-20 tw-p-2 tw-text-systemGold-110',
                    policyTypeIconColors({ typeId: item?.type?.id }),
                  )}
                >
                  <PolicyTypeIcon
                    typeId={item?.type?.id}
                    weight='duotone'
                    size={24}
                  />
                </div>

                <ActionsDropdown
                  data={[
                    {
                      label: t('View details'),
                      icon: <Eye />,
                      onClick: () => {
                        toggleShowDetails()
                        setSelectedPolicy(item)
                      },
                    },
                    {
                      label: t('Edit policy'),
                      icon: <PencilSimple />,
                      onClick: () => {
                        toggleShowEdit()
                        setSelectedPolicy(item)
                      },
                    },
                    activeTab === TABS.ACTIVE &&
                      item.type.id !==
                        TimeOffPolicyTypes.DEFAULT_POLICY_TYPE && {
                        label: t('Archive'),
                        icon: <Archive />,
                        onClick: () => {
                          toggleShowArchive()
                          setSelectedPolicy(item)
                        },
                      },
                  ].filter(Boolean)}
                />
              </div>

              <h4 className='tw-mb-0.5 tw-mt-4 tw-text-sm tw-font-bold'>
                {item.name}
              </h4>
              <p className='tw-mb-1 tw-text-xs tw-font-medium tw-text-text-80'>
                {item.type?.is_accrued === 1
                  ? t('Accrued time off balance')
                  : t('Flexible policy')}
              </p>

              <div className='tw-mt-2 tw-flex tw-items-center tw-gap-2'>
                <UserCircle size={16} className='tw-text-secondary-100' />
                <div className='tw-text-xs tw-font-semibold tw-text-text-80'>
                  {t('workersAssigned', { count: getContractsCount(item) })}
                </div>
              </div>

              {activeTab === TABS.ACTIVE ? (
                <ManagePolicyWorkers
                  policy={item}
                  onSuccess={refetchPolicies}
                  companyPolicies={companyPolicies}
                />
              ) : (
                <UnArchiveButton onSuccess={refetchPolicies} policy={item} />
              )}
            </div>
          )
        })}
      </div>

      <ViewPolicy
        policy={selectedPolicy}
        refetchPolicies={refetchPolicies}
        toggleMenu={toggleShowDetails}
        isOpen={showDetails}
      />

      <EditAction
        policy={selectedPolicy}
        isOpen={showEdit}
        toggle={toggleShowEdit}
      />

      <ConfirmationModal
        isOpen={showArchive}
        toggle={toggleShowArchive}
        negativeCaption={t('No, Close')}
        caption={t('Yes, Archive')}
        onConfirm={() =>
          _archivePolicy({
            id: selectedPolicy.id,
            effective_date:
              archiveType === ARCHIVE_DAYS.SOME
                ? format(archiveFrom, 'yyyy-MM-dd')
                : undefined,
          })
        }
        confirmLoading={isArchiving}
        title={
          <>
            <Warning size={24} className='tw-mb-2 tw-text-systemGold' />
            <h4 className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
              {t('Are you sure you want to archive this policy?')}
            </h4>
            <p className='tw-mb-0 tw-text-sm tw-normal-case tw-text-text-80'>
              {getContractsCount(selectedPolicy) > 0
                ? selectedPolicy?.is_accrual
                  ? t(
                      'Workers will be assigned to the Default Time Off policy.',
                    )
                  : t('Workers will be removed from this policy.')
                : t('You won’t be able to assign workers to this policy.')}{' '}
              {t('Archived policies can be found in the Archive tab.')}
            </p>
          </>
        }
        content={
          <span className='tw-flex tw-flex-col tw-gap-4'>
            <CustomRadio
              items={archiveOptions}
              renderItem={(item) => (
                <ArchiveType
                  item={item}
                  type={archiveType}
                  archiveFrom={archiveFrom}
                  setArchiveFrom={setArchiveFrom}
                />
              )}
              name='archiveFor'
              checkedItem={archiveType}
              onSelect={(event) => setArchiveType(event.target.value)}
              className='tw-flex tw-flex-col tw-gap-2 last:tw-mb-0 [&>#subTitle]:tw-text-text-60 [&>#subTitle]:has-[:checked]:tw-text-primary [&>svg]:tw-fill-text-30 [&>svg]:has-[:checked]:tw-fill-primary'
            />
          </span>
        }
        confirmDisabled={!archiveFrom && archiveType === ARCHIVE_DAYS.SOME}
      />
    </div>
  )
}

const ArchiveType = ({ item, type, archiveFrom, setArchiveFrom }) => (
  <>
    {item.icon}
    {item.label}
    <span id='subTitle' className='tw-font-normal'>
      {item.subTitle}
    </span>
    {type === ARCHIVE_DAYS.SOME && item.value === ARCHIVE_DAYS.SOME && (
      <span className='-tw-mx-6 tw-mt-6 tw-block tw-border-t tw-border-t-surface-30 tw-bg-surface-10 tw-p-6'>
        <CustomDatePicker
          placeholder={t('Select date')}
          value={archiveFrom}
          handleOnChange={(date) => setArchiveFrom(date)}
        />
      </span>
    )}
  </>
)
