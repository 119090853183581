import React from 'react'
import {
  CheckCircle,
  ArrowSquareOut,
  XCircle,
  ClockCounterClockwise,
} from '@phosphor-icons/react'
import { useSteps } from './step-context'
import isEqual from 'lodash/isEqual'
import values from 'lodash/values'
import { ModalFooter, ModalHeader } from 'reactstrap'
import { ModalCloseButton } from '../../../../../components/Common/modal-close-button'
import Button from '../../../../../components/ui/button'

export function Result() {
  const { answers, toggle, retest } = useSteps()
  const isEligible = isEqual(values(answers), ['yes', 'no', 'no'])
  return (
    <>
      <ModalHeader
        className='tw-items-center'
        toggle={toggle}
        close={<ModalCloseButton toggle={toggle} />}
      />
      <div className='tw-flex tw-flex-1 tw-flex-col tw-items-center tw-justify-center tw-p-12'>
        {isEligible ? (
          <>
            <CheckCircle
              size={170}
              weight='duotone'
              className='tw-text-systemGreen'
            />
            <span className='tw-text-xl tw-text-black'>
              This contract is eligible!
            </span>
          </>
        ) : (
          <>
            <XCircle
              size={170}
              weight='duotone'
              className='tw-text-secondary-70'
            />
            <span className='tw-text-xl tw-text-black'>
              This contract is not eligible for CoR!
            </span>
            <span className='tw-mb-2 tw-mt-4 tw-text-center tw-text-text-60'>
              You may retake the test and review your answers or learn more
              about the eligible criteria
            </span>

            <Button
              tag='a'
              target='_blank'
              rel='noreferrer'
              href='http://help.remotepass.com/en/articles/8512670-how-to-onboard-contractors-using-remotepass'
              color='tw-text-secondary'
              icon={<ArrowSquareOut size={20} />}
            >
              Learn more
            </Button>
          </>
        )}
      </div>
      <ModalFooter className='!tw-justify-center'>
        {isEligible ? (
          <Button onClick={() => toggle(true)}>Proceed</Button>
        ) : (
          <Button
            outline
            className='!tw-border-none'
            color='primary'
            onClick={retest}
            icon={<ClockCounterClockwise size={20} />}
          >
            Retake test
          </Button>
        )}
      </ModalFooter>
    </>
  )
}
