import {
  CircleHalf,
  ClockCountdown,
  ClockCounterClockwise,
  MinusCircle,
  Paperclip,
  Siren,
} from '@phosphor-icons/react'
import { t } from 'i18next'
import React from 'react'

import { cn } from 'ui'
import { DetailsInfoList } from '../pages/review-center/review-layout-details-components'
import { formatDays } from '../utils/formatters/format-days'
import Accordion from './Accordion'

export default function PolicyRequestSettings({
  bodyClassName,
  policy,
  className,
  isOpenByDefault,
}) {
  const isAccrued = policy?.type?.is_accrued === 1

  return (
    <Accordion
      className={cn(
        'tw-border-b tw-border-b-surface-30 tw-px-0 tw-py-4',
        className,
      )}
      isOpenByDefault={isOpenByDefault}
      iconClassName='tw-fill-primary'
      contentWrapperClassName='tw-px-0 tw-py-0'
      label={
        <div className='tw-text-start'>
          <p className='tw-mb-0 tw-text-sm tw-font-semibold tw-text-black'>
            {t('Requests settings')}
          </p>
          <span className='tw-text-sm tw-text-text-80'>
            {t('View request rules for this policy')}
          </span>
        </div>
      }
      value={
        <DetailsInfoList
          items={[
            {
              icon: <CircleHalf size={24} />,
              label: t('Half day request'),
              value: policy.can_request_half_day ? t('Yes') : t('No'),
              tip: t('Can a worker request a half day off?'),
            },
            {
              icon: <Siren size={24} />,
              label: t('Time off maximum'),
              value: policy.max_sequential_days
                ? formatDays(policy.max_sequential_days)
                : t('No maximum'),
              tip: t(
                'Is there a maximum number of sequential days per time off request?',
              ),
            },
            {
              icon: <ClockCounterClockwise size={24} />,
              label: t('Retrospective request'),
              value: policy.is_retrospective_enabled ? t('Yes') : t('No'),
              tip: t('Can a worker submit a request for a past period?'),
            },
            {
              icon: <ClockCountdown size={24} />,
              label: t('Request eligibility'),
              value: policy.days_after_hiring
                ? formatDays(policy.days_after_hiring)
                : t('No waiting time'),
              tip: t(
                'Is there a waiting time to request time off after date of hire?',
              ),
            },
            isAccrued && {
              icon: <MinusCircle size={24} />,
              label: t('Negative Balance'),
              value: policy.negative_balance_enabled ? t('Yes') : t('No'),
              tip: t('Can a worker request time off without enough balance?'),
            },
            {
              icon: <Paperclip size={24} />,
              label: t('Add Reason and Upload document'),
              value: policy.are_reason_and_file_required ? t('Yes') : t('No'),
              tip: t(
                'Is the worker required to add a reason and upload a document with the request?',
              ),
            },
          ]}
          className={cn('tw-py-0', bodyClassName)}
        />
      }
    />
  )
}
