import { yupResolver } from '@hookform/resolvers/yup'
import { Plus } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Panel } from '@xyflow/react'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledInput from '../../components/ControlledInput'
import ControlledSelect from '../../components/ControlledSelect'
import Button from '../../components/ui/button'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../components/ui/side-menu'
import { useFetch } from '../../helpers/hooks'
import { createDepartment } from '../../services/api-org-charts'
import { departmentColorOptions } from './department-colors'

export function AddDepartment({ refreshData }) {
  const [isOpen, setIsOpen] = useState(false)
  function toggleSidebar() {
    setIsOpen((open) => !open)
  }

  const { startFetch: createDep, isLoading: isCreatingDep } = useFetch({
    action: createDepartment,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Failed to create department')
      } else {
        toastr.success('Department created successfully')
        toggleSidebar()
        refreshData?.()
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  return (
    <>
      <Panel position='top-center' className='tw-z-10'>
        <Button
          color='light'
          className='tw-relative tw-h-10 !tw-bg-white !tw-px-6 !tw-text-xs hover:!tw-bg-surface-30'
          type='button'
          icon={<Plus size={16} />}
          onClick={toggleSidebar}
        >
          New Department
        </Button>
      </Panel>

      {!isOpen ? null : (
        <ManageDepartment
          isOpen={isOpen}
          toggleSidebar={toggleSidebar}
          onSubmit={(data) => {
            createDep(data)
          }}
          loading={isCreatingDep}
        />
      )}
    </>
  )
}

const manageDepartmentFormId = 'manageDepartmentForm'
export function ManageDepartment({
  isOpen,
  toggleSidebar,
  defaultValues,
  onSubmit,
  onDelete,
  loading,
}) {
  // We assume that if the defaultValues.id is null, then we are creating a new department
  const isEdit = !!defaultValues && defaultValues?.id !== null

  const { handleSubmit, control } = useForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().label('Title').required(),
        color: yup.string().label('Color').required(),
      }),
    ),
  })

  function _onSubmit(data) {
    onSubmit(data)
  }

  return (
    <SideMenu
      className='!tw-w-full tw-max-w-[440px]'
      itemListClassName='tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain tw-grid-rows-[minmax(auto,max-content)_1fr_91px]'
      isOpen={isOpen}
      onClose={toggleSidebar}
    >
      <SideMenuHeader toggle={toggleSidebar}>
        {isEdit ? 'Edit Department' : 'Add a new Department'}
      </SideMenuHeader>
      <SideMenuBody>
        <form onSubmit={handleSubmit(_onSubmit)} id={manageDepartmentFormId}>
          <ControlledInput
            name='name'
            label='Title'
            placeholder='Enter department title'
            control={control}
          />

          <ControlledSelect
            name='color'
            label='Color'
            control={control}
            options={departmentColorOptions}
            wrapperClassName='tw-mt-4'
          />
        </form>
      </SideMenuBody>
      <SideMenuFooter>
        {!isEdit ? null : (
          <Button
            color='link'
            className='tw-mr-auto !tw-px-0 !tw-text-systemRed-100'
            type='button'
            onClick={onDelete}
            disabled={loading}
          >
            Delete
          </Button>
        )}

        <Button
          color='light'
          outline
          onClick={toggleSidebar}
          type='button'
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          formId={manageDepartmentFormId}
          disabled={loading}
          loading={loading}
        >
          Save
        </Button>
      </SideMenuFooter>
    </SideMenu>
  )
}
