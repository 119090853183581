import React from 'react'
import * as yup from 'yup'
import { mapCountryToOption } from '../../../utils/map-to-option'
import { Col } from 'reactstrap'
import { cn } from 'ui'

import LabelContent from '../../../pages/Contract/CreateContract/components/label-content'
import { useSelector } from 'react-redux'
import ControlledDatePickerNew from '../../ControlledDatePickerNew'
import ControlledInputNew from '../../ControlledInputNew'
import ControlledSelectNew from '../../ControlledSelectNew'

import ControlledInputOld from '../../ControlledInput'
import ControlledSelectOld from '../../ControlledSelect'
import ControlledDatePickerOld from '../../ControlledDatePicker'
import DynamicFileUpload from './file-upload'

export const fieldTypes = {
  dropdown: 'dropdown',
  country_dropdown: 'country_dropdown',
  religion_dropdown: 'religion_dropdown',
  religious_faith_dropdown: 'religious_faith_dropdown',
  marital_status_dropdown: 'marital_status_dropdown',
  passport_type_dropdown: 'passport_type_dropdown',
  education_level_dropdown: 'education_level_dropdown',
  language_dropdown: 'language_dropdown',
  multi_select_dropdown: 'multi_select_dropdown',
  short_text_input: 'short_text_input',
  text: 'text',
  string: 'string',
  long_text_input: 'long_text_input',
  date_picker: 'date_picker',
  date: 'date',
  file_upload: 'file_upload',
  multi_file_upload: 'multi_file_upload',
}

const conditionTypes = {
  show: 'show',
  hide: 'hide',
}

const rulesEvaluationStrategies = {
  all: 'all',
  any: 'any',
}

/**
 * Generates the schema for the dynamic form using Yup.
 * @param {Object} form - The form object containing form fields, steps, and conditions.
 * @returns {Object} - Yup schema object.
 */
export const buildYupSchema = ({
  form = {},
  validateAllWhenField,
  condition,
}) => {
  // Initialize an empty schema object
  const schema = {}

  if (condition === false) return {}

  // If form is null or undefined
  if (!form) {
    form = {}
  }

  // Extract form fields, steps, and conditions from the form object
  const { form_fields: fields, form_steps: steps, conditions } = form

  // Merge form fields from steps if available
  const mergedFields =
    fields ??
    steps?.reduce((acc, step) => {
      return [...acc, ...step.form_fields]
    }, [])

  const requiredSchema = (schema, title) =>
    schema
      .required(`'${title}' is required`)
      .notOneOf([''], `'${title}' is required`)

  // Loop through each merged field
  mergedFields?.forEach((field) => {
    const { id, title, is_required: isRequired } = field

    // Create a new field schema using Yup's mixed type
    let fieldSchema = yup.mixed()

    // Apply required validation if necessary
    if (isRequired) {
      if (validateAllWhenField) {
        fieldSchema = fieldSchema.when(validateAllWhenField, {
          is: true,
          then: (schema) => requiredSchema(schema, title),
          otherwise: (schema) => schema.notRequired(),
        })
      } else {
        fieldSchema = requiredSchema(fieldSchema, title)
      }
    }

    // Apply condition-based validation
    fieldSchema = getFieldSchema(
      field,
      conditions,
      fieldSchema,
      validateAllWhenField,
    )

    // Add the field schema to the overall schema object
    schema[`form_field_${id}`] = fieldSchema
  })

  // Return the generated schema object
  return schema
}

/**
 * Applies condition-based validation to a field schema.
 * @param {Object} field - The field object.
 * @param {Array} conditions - Array of conditions for the field.
 * @param {Object} fieldSchema - Yup schema object for the field.
 * @returns {Object} - Yup schema object with condition-based validation applied.
 */
const getFieldSchema = (
  field,
  conditions,
  fieldSchema,
  validateAllWhenField,
) => {
  // Apply condition-based validation
  return fieldSchema.when((_, schema, { parent: values }) => {
    // Check if the field should be hidden based on conditions and values
    if (validateAllWhenField && values[validateAllWhenField] === false) {
      return schema
    }
    if (isHidden(field, conditions, values)) {
      // If the field should be hidden, mark it as not required
      return schema.notRequired()
    }
    // If the field should be visible, return the original schema
    return schema
  })
}
/**
 * Determines whether a field should be hidden based on conditions and form values.
 * @param {Object} field - The field object.
 * @param {Array} conditions - Array of conditions for the field.
 * @param {Object} values - Current form values.
 * @returns {boolean} - Indicates whether the field should be hidden.
 */
const isHidden = (field, conditions, values) => {
  // Get relevant conditions for the field
  const relevantConditions = getRelativeConditions(field, conditions) ?? []

  if (relevantConditions.length === 0) return false

  // Check if relevant conditions exist and match the 'show' type
  if (relevantConditions[0]?.type === conditionTypes.show) {
    return relevantConditions.every(
      (condition) => !satisfiesAllRules(field, condition, values),
    )
  } else {
    return relevantConditions.some((condition) =>
      satisfiesAllRules(field, condition, values),
    )
  }
}
/**
 * Determines whether a field satisfies all the rules specified in a condition.
 * @param {Object} field - The field object.
 * @param {Object} condition - The condition object.
 * @param {Object} values - Current form values.
 * @returns {boolean} - Indicates whether the field satisfies all rules.
 */
const satisfiesAllRules = (field, condition, values) => {
  // Extract rules and rules evaluation strategy from the condition
  const { rules, rules_evaluation_strategy: rulesEvaluationStrategy } =
    condition

  // Check if the condition applies to the field
  if (
    (condition.target_item_type === 'field' &&
      condition.target_item_id === field.id) ||
    (condition.target_item_type === 'step' &&
      condition.target_item_id === field.form_step_id)
  ) {
    // Evaluate the rules based on the specified strategy
    if (rulesEvaluationStrategy === rulesEvaluationStrategies.all) {
      // If the strategy is 'all', check if all rules are satisfied
      return rules.every((rule) => evaluateRule(rule, values))
    } else {
      // If the strategy is 'any', check if any rule is satisfied
      return rules.some((rule) => evaluateRule(rule, values))
    }
  }
  // Return false if the condition does not apply to the field
  return false
}

/**
 * Evaluates a single rule within a condition against the current form values.
 * @param {Object} rule - The rule object.
 * @param {Object} values - Current form values.
 * @returns {boolean} - Indicates whether the rule is satisfied.
 */
const evaluateRule = (rule, values) => {
  // Extract rule properties
  const { form_field_id: formFieldId, operator, value } = rule

  // Retrieve the value of the field associated with the rule
  const fieldValue = values[`form_field_${formFieldId}`]

  // Evaluate the rule based on the operator
  switch (operator) {
    case 'is_equal_to':
      // Check if the field value is equal to the rule value
      return fieldValue === value
    case 'is_not_equal_to':
      // Check if the field value is not equal to the rule value
      return fieldValue !== value
    // Add support for other operators as needed
    default:
      // Return false if the operator is not recognized
      return false
  }
}
/**
 * Retrieves conditions relevant to a specific field or step within a form.
 * @param {Object} field - The field object.
 * @param {Array} conditions - Array of conditions for the form.
 * @returns {Array} - Relevant conditions for the field.
 */
const getRelativeConditions = (field, conditions) => {
  if (!conditions) return []
  // Filter conditions to find those relevant to the given field
  return conditions?.filter((c) => {
    // Check if the condition targets the field or step associated with the field
    if (
      (c.target_item_type === 'field' && c.target_item_id === field.id) ||
      (c.target_item_type === 'step' && c.target_item_id === field.form_step_id)
    ) {
      // Return true if the condition is relevant to the field
      return true
    }
    // Return false if the condition is not relevant to the field
    return false
  })
}

/**
 * Determines if a step within a form is empty based on its fields' visibility conditions and current form values.
 * @param {Object} step - The step object.
 * @param {Array} conditions - Array of conditions for the form.
 * @param {Object} values - Current form values.
 * @returns {boolean} - Indicates whether the step is empty.
 */
export const isStepEmpty = (step, conditions, values) => {
  // Check if the step has no form fields
  if (!step?.form_fields || step?.form_fields.length === 0) {
    return true // Step is considered empty if it has no form fields
  }

  // Check if all form fields in the step are hidden based on conditions and values
  return step?.form_fields?.every((field) =>
    isHidden(field, conditions, values),
  )
}

/**
 * Format the values of an input object to a specific structure.
 * If any inputs has 'other', replace with the other specified value
 * @param {object} data - The input data object to be formatted.
 * @returns {object} - The formatted data object with 'form_inputs' array.
 */
export const formatValues = (data) => {
  const output = {
    form_inputs: [],
  }

  for (const key in data) {
    if (!key.startsWith('form_field_')) continue
    if (key.endsWith('_other')) continue
    if (Object.hasOwnProperty.call(data, key)) {
      let value = data[key]
      if (value === '' || value === null || value === undefined) continue

      if (Array.isArray(value)) {
        value = value.join(',') // Convert array to string

        if (value.includes('other')) {
          value = value.replace('other', data?.[key + '_other'])
        }
      } else if (
        typeof value === 'object' &&
        value !== null &&
        Object.prototype.hasOwnProperty.call(value, 'value')
      ) {
        value = value.value // Use 'value' property if it exists in the object
      }

      if (value === 'other') {
        value = data?.[key + '_other']
      }

      output.form_inputs.push({
        form_field_id: parseInt(key.split('_').pop()),
        value: value.toString(),
      })
    }
  }

  return output
}

/**
 * Remove unused form_field_* keys from the output object.
 * @param { } data
 */
export function removeFormFields(data) {
  const output = { ...data }
  for (const key in output) {
    if (!key.startsWith('form_field_')) continue
    delete output[key]
  }
  return output
}

/**
 * Generate default values for form fields based on input fields data.
 * @param {Array} data - The input array of objects to be converted.
 * @returns {Object} - The formatted object with keys based on 'id' and values based on 'form_input.value'.
 */
export const getDefaultValues = (fields) => {
  const output = {}

  // Iterate through each object in the input array
  fields?.forEach((item) => {
    // Check if the object has 'form_input' and its 'value' is not empty
    if (item.form_input && item.form_input.value) {
      // Add the value to the output object with key 'form_field_${item.id}'
      output[`form_field_${item.id}`] =
        item.type === fieldTypes.country_dropdown // BE sends value as string for country, needs to be integer
          ? parseInt(item.form_input.value)
          : item.form_input.value
    }
  })

  return output
}
/**

Renders a dynamic form based on the provided fields.
* @param {Object} props - Component props.
* @param {Array} props.fields - Array of form fields.
* @param {Object} props.control - Form control object from react-hook-form.
* @param {Object} props.errors - Form errors object from react-hook-form.
* @param {Function} props.watch - Function to watch form field values from react-hook-form.
* @param {Function} props.setValue - Function to set form field values from react-hook-form.
* @param {string} props.colClassName - Class name for the form fields' columns.
* @param {string} props.labelClassName - Class name for the form fields' labels.
* @param {Array} props.conditions - Array of form conditions objects
* @param {boolean} props.readOnly - Flag to disable all of the form fields.
* @param {Function} props.clearErrors - Function to clear form errors from react-hook-form.
* @param {boolean} props.isPreviewOnly - Flag to render form in preview mode (can edit but can't submit).
* @param {boolean} props.newUI - Flag to render form in new UI mode.
* @param {boolean} props.filesDownloadable - Flag to enable downloading files from the form.
* @param {boolean} props.canDeleteFiles - Flag to enable deleting submitted files from the form.
* @param {string} props.optionalPlaceholder - Placeholder text for optional fields.
* @returns {Array} - Array of React elements representing form fields.
*/
function DynamicForm({
  fields,
  conditions,
  control,
  setValue,
  watch,
  errors,
  colClassName,
  labelClassName,
  readOnly,
  clearErrors,
  isPreviewOnly,
  newUI = true,
  filesDownloadable = false,
  canDeleteFiles = false,
  optionalPlaceholder,
}) {
  const {
    all_countries: allCountries,
    languages,
    religions,
    education_levels: educationLevels,
    passport_types: passportTypes,
    religious_faiths: religiousFaiths,
    marital_statuses: maritalStatuses,
  } = useSelector((state) => state?.Layout?.staticData ?? { eor_countries: [] })

  /**
   * Retrieves options for a select input based on the field type.
   * @param {object} field - The field object.
   * @returns {array} - Array of options for the select input.
   */
  function getSelectInputOptions(field) {
    let options = []
    switch (field?.type) {
      case fieldTypes.country_dropdown: {
        options = (allCountries ?? [])?.map((c) =>
          mapCountryToOption(c, 'id', true),
        )
        break
      }
      case fieldTypes.language_dropdown: {
        options = languages?.map((l) => ({
          label: l.name,
          value: l.id?.toString(),
        }))
        break
      }
      case fieldTypes.religion_dropdown: {
        options = religions?.map((r) => ({
          label: r.name,
          value: r.id?.toString(),
        }))
        break
      }
      case fieldTypes.passport_type_dropdown: {
        options = passportTypes?.map((p) => ({
          label: p.name,
          value: p.id?.toString(),
        }))
        break
      }
      case fieldTypes.religious_faith_dropdown: {
        options = religiousFaiths?.map((r) => ({
          label: r.name,
          value: r.id?.toString(),
        }))
        options?.push({ label: 'None of the above', value: 'none' })
        break
      }
      case fieldTypes.marital_status_dropdown: {
        options = maritalStatuses?.map((m) => ({
          label: m.name,
          value: m.id?.toString(),
        }))
        break
      }
      case fieldTypes.education_level_dropdown: {
        options = educationLevels?.map((e) => ({
          label: e.name,
          value: e.id?.toString(),
        }))
        break
      }
      default: {
        options = field.options?.map((o) => ({
          label: o,
          value: o.toString(),
        }))
        break
      }
    }

    if (field.configs?.has_other_option) {
      options?.push({
        label: field.configs.other_option_label,
        value: 'other',
      })
    }

    return options
  }

  // choose between old and new UI components
  const ControlledInput = newUI ? ControlledInputNew : ControlledInputOld
  const ControlledSelect = newUI ? ControlledSelectNew : ControlledSelectOld
  const ControlledDatePicker = newUI
    ? ControlledDatePickerNew
    : ControlledDatePickerOld

  return fields?.map((field) => {
    if (isHidden(field, conditions, watch?.())) return null

    switch (field.type) {
      case fieldTypes.dropdown:
      case fieldTypes.country_dropdown:
      case fieldTypes.language_dropdown:
      case fieldTypes.marital_status_dropdown:
      case fieldTypes.education_level_dropdown:
      case fieldTypes.religion_dropdown:
      case fieldTypes.religious_faith_dropdown:
      case fieldTypes.passport_type_dropdown:
      case fieldTypes.multi_select_dropdown:
        return (
          <>
            <Col
              className={cn('tw-mb-2 tw-py-2', colClassName)}
              sm={field.is_full_row ? 12 : 6}
              key={field.id}
            >
              <ControlledSelect
                label={field.title}
                required={field.is_required}
                labelClassName={cn('tw-text-sm', labelClassName)}
                control={control}
                isMulti={[
                  fieldTypes.multi_select_dropdown,
                  fieldTypes.language_dropdown,
                ].includes(field.type)}
                name={`form_field_${field.id}`}
                error={errors?.[`form_field_${field.id}`]}
                disabled={readOnly}
                options={getSelectInputOptions(field)}
              />
            </Col>
            {watch(`form_field_${field.id}`)?.includes?.('other') &&
              field.configs?.has_other_option && (
                <Col
                  className={cn('tw-mb-2 tw-py-2', colClassName)}
                  sm={field.is_full_row ? 12 : 6}
                  key={field.id + 'other'}
                >
                  <ControlledInput
                    name={`form_field_${field.id}_other`}
                    labelClassName={cn('tw-text-base', labelClassName)}
                    required={field.is_required}
                    rows={3}
                    disabled={readOnly}
                    label={
                      <LabelContent
                        className={cn(
                          'tw-text-sm',
                          newUI ? 'tw-text-[10px]' : 'tw-mb-0',
                        )}
                      >{`${field?.configs?.other_option_label} ${
                        field.is_required ? '' : '(Optional)'
                      }`}</LabelContent>
                    }
                    control={control}
                    placeholder={
                      optionalPlaceholder || field?.configs?.other_option_label
                    }
                  />
                </Col>
              )}
          </>
        )
      case fieldTypes.short_text_input:
      case fieldTypes.long_text_input:
      case fieldTypes.text:
      case fieldTypes.string:
        return (
          <Col
            className={cn('tw-mb-2 tw-py-2', colClassName)}
            sm={field.is_full_row ? 12 : 6}
            key={field.id}
          >
            <ControlledInput
              name={`form_field_${field.id}`}
              labelClassName={cn('tw-text-base', labelClassName)}
              rows={3}
              disabled={readOnly}
              label={
                <LabelContent
                  className={cn(
                    'tw-text-sm',
                    newUI ? 'tw-text-[10px]' : 'tw-mb-0',
                  )}
                  required={field.is_required && !newUI}
                >
                  {`${field?.title} ${field.is_required ? '' : '(Optional)'}`}
                </LabelContent>
              }
              control={control}
              placeholder={optionalPlaceholder || field?.title}
            />
          </Col>
        )
      case fieldTypes.date_picker:
      case fieldTypes.date:
        return (
          <Col
            className={cn('tw-mb-2', colClassName)}
            sm={field.is_full_row ? 12 : 6}
            key={field.id}
          >
            <ControlledDatePicker
              control={control}
              disabled={readOnly}
              label={
                <LabelContent
                  className='tw-text-sm'
                  required={field?.is_required}
                  showOptionalLabelText={!field?.is_required}
                >
                  {field?.title}
                </LabelContent>
              }
              labelClassName={cn('tw-text-sm', labelClassName)}
              name={`form_field_${field.id}`}
              placeholder={optionalPlaceholder || field?.title}
            />
          </Col>
        )
      case fieldTypes.file_upload:
      case fieldTypes.multi_file_upload: {
        return (
          <Col className={cn('tw-mb-2', colClassName)} sm={12} key={field.id}>
            <DynamicFileUpload
              watch={watch}
              field={field}
              setValue={setValue}
              error={errors?.[`form_field_${field.id}`]?.message}
              canDeleteFiles={canDeleteFiles}
              readOnly={readOnly}
              filesDownloadable={filesDownloadable}
              clearErrors={clearErrors}
              isPreviewOnly={isPreviewOnly}
            />
          </Col>
        )
      }
      default:
        return null
    }
  })
}

export default DynamicForm
