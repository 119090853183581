import React, { useEffect, useState } from 'react'
import {
  SettingSectionHeading,
  SettingSectionSubHeading,
} from '../settings-section-heading'
import { AutomationTable } from './table/automation-table'
import { Link, useLocation } from 'react-router-dom'
import { Sparkle } from '@phosphor-icons/react'
import Button from '../../../../components/ui/button'
import { AutomationsTemplatesModal } from './modals/automations-templates-modal'
import { useAutomations } from './hooks/use-automations'
import Loader from '../../../../components/ui/loader'
import { useOnboardingDisplay } from './hooks/use-onboarding-display'
import { AutomationOnboarding } from './automation-onboarding'
import { automationLearnMoreLink } from './tools/constants'
import { OwnersProvider } from './contexts/owners-context'
import { PermissionTooltip } from '../../../../components/permission-tooltip'
import { PERMISSION_GROUP } from '../../manage-role'
import { usePermissions } from '../../../../helpers/hooks'
import permissions from '../../../../helpers/permissions'

function AutomationsList() {
  const location = useLocation()
  const openTemplates =
    new URLSearchParams(location.search).get('openTemplates') === 'true'
  const [isOpen, setIsOpen] = useState(false)
  const { isFirstLoading, isTotalEmpty, ...automation } = useAutomations({
    onlyTemplates: false,
  })
  const { isDisplayed, setDisplayed } = useOnboardingDisplay(automation)
  const { hasAccess } = usePermissions()

  useEffect(() => {
    if (openTemplates && isDisplayed && !isTotalEmpty) setIsOpen(true)
  }, [isDisplayed, isTotalEmpty, openTemplates])

  if (isFirstLoading) return <Loader minHeight='28rem' />
  if (isTotalEmpty && !isDisplayed)
    return <AutomationOnboarding onStart={() => setDisplayed(true)} />

  return (
    <div className='tw-p-6'>
      <div className='tw-flex tw-flex-wrap tw-items-center tw-gap-3 md:tw-flex-nowrap'>
        <div className='tw-flex-1'>
          <SettingSectionHeading learnMoreLink={automationLearnMoreLink}>
            Automations
          </SettingSectionHeading>

          <SettingSectionSubHeading className='tw-mb-0'>
            Set up reminders for your company
          </SettingSectionSubHeading>
        </div>

        <PermissionTooltip
          area={PERMISSION_GROUP.MANAGE_COMPANY_SETTINGS.name}
          showing={!hasAccess(permissions.ManageCompanyAutomation)}
          id='company-automations-btns-tooltip'
        >
          <div className='tw-flex tw-gap-3'>
            <button
              onClick={() => setIsOpen(true)}
              className='tw-flex tw-flex-row tw-items-center tw-gap-1 disabled:tw-text-text-50'
              disabled={!hasAccess(permissions.ManageCompanyAutomation)}
            >
              <Sparkle size={20} />
              <span className='tw-font-bold'>Templates</span>
            </button>
            <Button
              to='/automations/add'
              tag={Link}
              color='link'
              className='tw-align-self-center tw-mr-2 !tw-px-0'
              disabled={!hasAccess(permissions.ManageCompanyAutomation)}
            >
              Add New
            </Button>
          </div>
        </PermissionTooltip>
      </div>

      <AutomationTable onShowAll={() => setIsOpen(true)} {...automation} />

      <AutomationsTemplatesModal
        isOpen={isOpen}
        toggle={() => setIsOpen((isOpen) => !isOpen)}
      />
    </div>
  )
}

export function AutomationsTab() {
  return (
    <OwnersProvider>
      <AutomationsList />
    </OwnersProvider>
  )
}
