import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card } from 'reactstrap'
import toastr from 'toastr'

import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import SearchBar from '../../components/SearchBar'
import Pagination from '../../components/ui/pagination'
import Shimmer from '../../components/ui/shimmer'
import { userTypes } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import {
  downloadInvoice,
  getInvoicesList,
  getInvoicesMonths,
  getInvoicesYears,
} from '../../services/api'
import { useFilters } from '../AdminPanel/pages/cards/use-filters'
import DownloadInvoicesButton from './components/DownloadInvoicesButton'
import { InvoicesTable } from './invoices-table'

function formatFilters(filters) {
  const { year, month, ...rest } = filters

  return { ...rest, year: year?.value, month: month?.value }
}

export function RemotePassInvoices() {
  const [expanded, setExpanded] = useState(-1)
  const [filters, handleFiltersChange] = useFilters({ page: 1 })

  function handleDateChange(date) {
    if (isCompany) {
      handleFiltersChange('month', date)
    } else {
      handleFiltersChange('year', date)
    }
  }

  const user = useSelector((state) => state.Account?.user)
  const isCompany = user?.type === userTypes.COMPANY

  const {
    data: invoices,
    isLoading,
    paginator,
  } = useFetch(
    {
      action: getInvoicesList,
      autoFetch: filters?.year?.value || filters?.month?.value,
      body: formatFilters(filters),
    },
    [filters],
  )

  const months = useFetch({
    action: getInvoicesMonths,
    autoFetch: isCompany,
    onComplete: (data) => {
      if (data?.months?.length > 0 && isCompany) {
        handleFiltersChange('month', data?.months[0])
      }
    },
  })

  const years = useFetch({
    action: getInvoicesYears,
    autoFetch: !isCompany,
    onComplete: (data) => {
      if (data?.years?.length > 0 && user?.type === userTypes.CONTRACTOR) {
        handleFiltersChange('year', data?.years[0])
      }
    },
  })

  function handleDownloadInvoice(invoice) {
    window.analytics.track('Clicked download invoice', {
      invoice_id: invoice?.invoice_ref,
    })

    downloadInvoice(invoice.invoice_ref, user?.token, invoice.token).then(
      (r) => {
        if (r.data?.data?.error || r.data?.message) {
          toastr.error(
            r.data?.data?.error || r.data?.message || 'An error occurred',
          )
          return
        }
        const url = window.URL.createObjectURL(new Blob([r.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${invoice?.invoice_ref}.pdf`) // or any other extension
        document.body.appendChild(link)
        link.click()
      },
    )
  }

  useEffect(() => {
    window.analytics.track('Viewed invoices', { email_id: user?.email })
  }, [user?.email])

  function handleSearch(query) {
    handleFiltersChange('search_key', query, { action: 'clear' })
  }
  const isFiltering = !!filters?.search_key

  const dateList = isCompany ? months.data?.months : years.data?.years
  const dateValue = isCompany ? filters?.month : filters?.year

  const dataLoading =
    isLoading || !invoices || months.isLoading || years.isLoading

  const showPagination = paginator?.first_page_url !== paginator?.last_page_url

  return (
    <Card className='rp-shadow-2 tw-p-6' style={{ minHeight: '30rem' }}>
      <div className='tw-flex tw-flex-col tw-gap-3 tw-pb-4 max-md:tw-flex-wrap sm:tw-flex-row'>
        {dataLoading && !isFiltering ? (
          <>
            <div className='tw-grid tw-flex-grow tw-grid-cols-12 tw-gap-3 sm:tw-min-w-96'>
              <Shimmer
                width='100%'
                height='44px'
                className='tw-col-span-12 sm:tw-col-span-5'
              />

              <Shimmer
                width='100%'
                height='44px'
                className='tw-col-span-12 sm:tw-col-span-7'
              />
            </div>
            <Shimmer
              width='100%'
              height='44px'
              className='tw-col-span-12 tw-max-w-48'
            />
          </>
        ) : (
          <>
            <div className='tw-grid tw-flex-grow tw-grid-cols-12 tw-gap-y-3 sm:tw-min-w-96'>
              <CustomSelect
                options={dateList}
                value={dateValue}
                onChange={handleDateChange}
                withSearch
                searchable={false}
                wrapperClassName='tw-col-span-12 sm:tw-col-span-5 lg:tw-col-span-4 [&_.RS-Control\_\_control]:sm:tw-rounded-r-none [&_.RS-Control\_\_control]:tw-border-r-0 [&_.RS-Control\_\_control]:tw-h-[44px]'
              />

              <SearchBar
                roundedLeftNone
                onQueryChanged={(query) => handleSearch(query)}
                query={filters.search_key}
                className='tw-col-span-12 sm:tw-col-span-7 lg:tw-col-span-8'
              />
            </div>

            {!isCompany ? null : (
              <DownloadInvoicesButton invoices={invoices} month={dateValue} />
            )}
          </>
        )}
      </div>

      <div className={!dataLoading && 'md:-tw-mx-6'}>
        <InvoicesTable
          orders={invoices}
          dataLoading={dataLoading}
          expanded={expanded}
          setExpanded={setExpanded}
          handleDownloadInvoice={handleDownloadInvoice}
        />
      </div>

      {showPagination && (
        <div className='tw-mt-4 tw-flex tw-justify-end tw-px-6'>
          <Pagination
            activePage={filters.page}
            onChange={(page) => handleFiltersChange('page', page)}
            itemsCountPerPage={paginator?.per_page ?? 50}
            totalItemsCount={paginator?.total ?? 0}
          />
        </div>
      )}
    </Card>
  )
}
