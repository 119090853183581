import React from 'react'

import ConfirmationModal from './Common/ConfirmationModal'
import Button from './ui/button'

export default function ConfirmActionButton({
  title,
  message,
  onConfirm,
  isLoading,
  buttonLabel,
  className,
  isOpen,
  toggle,
  content,
  caption,
  buttonColor,
  confirmDisabled,
  icon,
}) {
  return (
    <>
      <Button
        color='transparent'
        className={className}
        onClick={toggle}
        icon={icon}
      >
        {buttonLabel}
      </Button>

      <ConfirmationModal
        toggle={toggle}
        isOpen={isOpen}
        title={title}
        message={message}
        onConfirm={onConfirm}
        confirmLoading={isLoading}
        content={content}
        caption={caption}
        buttonColor={buttonColor}
        confirmDisabled={confirmDisabled}
      />
    </>
  )
}
