import { api, apiV2, getConfig, getConfigV2 } from './api'

export const createAutomation = (token, data) => {
  return apiV2.post(
    `/automation/api/v1/workflow`,
    {
      name: data.name,
      isTemplate: data.isTemplate,
      description: data.description,
    },
    getConfigV2(token),
  )
}

export const updateAutomation = (token, { id, ...data }) => {
  return apiV2.patch(
    `/automation/api/v1/workflow/${id}`,
    data,
    getConfigV2(token),
  )
}

export const duplicateAutomation = (token, data) => {
  return apiV2.post(
    `automation/api/v1/workflow/${data.templateId}/duplicate`,
    {},
    getConfigV2(token),
  )
}

export const getAutomations = (token, data) => {
  return apiV2.get(
    `/automation/api/v1/workflow`,
    getConfigV2(token, null, data),
  )
}

export const getAutomationDetail = (token, data) => {
  return apiV2.get(
    `/automation/api/v1/workflow/${data.id}`,
    getConfigV2(token, null, data),
  )
}

export const getPlaceholders = (token) => {
  return apiV2.get(
    `/automation/api/v1/workflow/action/variables`,
    getConfigV2(token),
  )
}

export const getFilters = (token, _, extra) => {
  return apiV2.get(
    `/automation/api/v1/workflow/trigger/filters`,
    getConfigV2(token, _, extra),
  )
}

export const getFiltersFromCore = (token, _, extra) => {
  return api.get('automation/filters', getConfig(token, extra))
}

export const updateSteps = (token, data) => {
  return apiV2.put(
    `/automation/api/v1/workflow/${data.id}/steps`,
    { steps: data.steps },
    getConfigV2(token),
  )
}

export const deleteAutomation = (token, data) => {
  return apiV2.delete(
    `/automation/api/v1/workflow/${data.id}`,
    getConfigV2(token),
  )
}

export const getAutomationTitle = (token, data) => {
  return apiV2.get(
    `/automation/api/v1/workflow/available-name`,
    getConfigV2(token, data),
  )
}

export const getSlackUrl = (token) => {
  return apiV2.get(`/automation/api/v1/slack/auth`, getConfigV2(token))
}

export const addWorkspace = (token, data) => {
  return apiV2.get(
    `/automation/api/v1/slack/auth/callback`,
    getConfigV2(token, null, data),
  )
}

export const getSlackWorkspaces = (token) => {
  return apiV2.get(`/automation/api/v1/slack/workspaces`, getConfigV2(token))
}

export const getSlackRecipients = (token, { id, forceRefresh = false }) => {
  return apiV2.get(
    `/automation/api/v1/slack/workspaces/all`,
    getConfigV2(token, null, {
      params: {
        workspaceId: id,
        forceRefresh,
      },
    }),
  )
}

export const deleteSlackWorkspaces = (token, data) => {
  return apiV2.delete(
    `/automation/api/v1/slack/workspaces/${data.id}`,
    getConfigV2(token),
  )
}

export const getOwners = (token) => {
  return api.get(`/automation/owners`, getConfig(token))
}
