import { Info } from '@phosphor-icons/react'
import React from 'react'
import { getNumberFormatter } from '../../../../../utils/formatters/number'
import { useProrataState } from './prorata-context'

export default function FullAmountBody({ currencyCode }) {
  const { amount } = useProrataState()

  const formattedAmount = !amount ? '' : getNumberFormatter().format(amount)

  return (
    <div>
      <div className='tw-flex'>
        <div
          className='form-control !tw-rounded-r-none !tw-border-r-0'
          readOnly
        >
          {formattedAmount}
        </div>
        <span className='tw-flex tw-items-center tw-rounded-r tw-border tw-border-l-0 tw-border-surface-30 tw-bg-surface-30 tw-px-4 tw-text-text-80'>
          {currencyCode}
        </span>
      </div>

      <p className='tw-mb-0 tw-mt-1.5 tw-flex tw-items-center tw-gap-1 tw-text-xs tw-text-text-60'>
        <Info size={14} className='tw-inline' /> The full first payment will be
        generated
      </p>
    </div>
  )
}
