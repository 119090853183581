import React from 'react'
import toastr from 'toastr'
import Shimmer from '../../../../components/ui/shimmer'
import { useFetch } from '../../../../helpers/hooks'
import { getDocumentInfo } from '../../../../services/api-compliance'
import { format } from 'date-fns'
import { DocumentTableActions } from '../contractor/document-table-actions'

export function ExpandedDocumentRow(props) {
  const { id, refetch } = props
  const { isLoading, data } = useFetch({
    autoFetch: !!id,
    body: { contract_id: id },
    action: getDocumentInfo,
    onError: (err) => toastr.error(err),
  })

  if (isLoading)
    return (
      <tr>
        <th colSpan={9}>
          <Shimmer width='100%' height={20} />
        </th>
      </tr>
    )

  return (
    <>
      <tr className='!tw-bg-primary-10 tw-text-text'>
        <th colSpan={1}></th>
        <th colSpan={2}>Documents</th>
        <th colSpan={2}>Expires on</th>
        <th colSpan={2}>Status</th>
        <th colSpan={2}></th>
      </tr>

      {data?.documents?.map((d) => (
        <tr className='!tw-bg-primary-10' key={d?.id}>
          <th colSpan={1}></th>
          <td colSpan={2}>{d?.name}</td>
          <td colSpan={2}>
            {d?.expiry_date
              ? format(new Date(d?.expiry_date), 'yyyy/MM/dd')
              : '-'}
          </td>
          <td colSpan={2}>
            {d?.link ? (
              <span className='tw-rounded-full tw-bg-systemGreen-10 tw-px-2.5 tw-py-1 tw-font-bold tw-text-systemGreen'>
                UPLOADED
              </span>
            ) : (
              <span className='tw-rounded-full tw-bg-systemGold-10 tw-px-2.5 tw-py-1 tw-font-bold tw-text-systemGold'>
                REQUESTED
              </span>
            )}
          </td>
          <td colSpan={2}>
            <DocumentTableActions refetch={refetch} rowData={d} />
          </td>
        </tr>
      ))}
    </>
  )
}
