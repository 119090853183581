export function getFullName(user) {
  if (!user) return ''

  if (user?.full_name) {
    return user.full_name?.trim()
  }

  if (user?.name) {
    return user.name?.trim()
  }

  return [user?.first_name, user?.middle_name, user?.last_name]
    .map((part) => part?.trim())
    .filter(Boolean)
    .join(' ')
}
