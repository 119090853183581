import { Card } from 'reactstrap'
import { ArrowSquareOut, CursorClick, Play } from '@phosphor-icons/react'
import React, { Fragment } from 'react'
import { cn } from 'ui'
import Button from '../../../../components/ui/button'
import { ReactComponent as Asterisk } from '../../../../assets/images/asterisk.svg'
import { ReactComponent as Lightning } from '../../../../assets/images/lightning.svg'
import {
  automationLearnMoreLink,
  automationOnboardingCards,
  automationOnboardingFeatures,
} from './tools/constants'

export function AutomationOnboarding({ onStart }) {
  return (
    <div className='tw-relative !tw-overflow-hidden'>
      <Lightning className='tw-absolute tw-left-[0px] tw-h-[100%] tw-text-green-40' />
      <Asterisk className='tw-absolute tw-right-[0px] tw-h-[100%] tw-text-green-40' />
      <div className='py-5 tw-flex tw-flex-col'>
        <div className='tw-flex tw-w-[80%] tw-flex-col tw-items-center tw-gap-6 tw-self-center md:tw-w-[40%]'>
          <div className='tw-flex tw-w-[100%] tw-flex-col tw-items-center'>
            {automationOnboardingCards.map(
              ({
                title,
                description,
                hasBorder = true,
                leftIcon: LeftIcon,
                rightIcon: RightIcon,
                scale,
                className,
              }) => (
                <Fragment key={title}>
                  <Card
                    className={cn(
                      '!tw-mb-0 tw-flex !tw-flex-row tw-items-center tw-justify-center tw-gap-2 !tw-rounded-xl tw-border-s tw-border-surface-30 tw-p-4 tw-drop-shadow-md',
                      className,
                    )}
                  >
                    <div className='tw-rounded tw-bg-secondary-20 tw-p-2'>
                      <LeftIcon
                        size={25 * scale}
                        className='tw-text-secondary-100'
                      />
                    </div>
                    <div className='tw-flex tw-flex-1 tw-flex-col'>
                      <span className='tw-font-medium'>{title}</span>
                      <span className='tw-font-medium'>{description}</span>
                    </div>
                    <div className='tw-rounded-full tw-bg-systemGreen-20 tw-p-2'>
                      <RightIcon
                        size={25 * scale}
                        className='tw-text-systemGreen-120'
                      />
                    </div>

                    {!hasBorder ? (
                      <CursorClick
                        weight='fill'
                        className='tw-absolute tw-bottom-[0px] tw-right-[2px]'
                        size={20}
                      />
                    ) : null}
                  </Card>
                  {hasBorder ? (
                    <div className='tw-h-[30px] tw-w-0.5 tw-bg-surface-30' />
                  ) : null}
                </Fragment>
              ),
            )}
          </div>

          <button className='tw-flex tw-flex-row tw-items-center tw-text-text-80'>
            <span>Learn More</span>
            <ArrowSquareOut />
          </button>

          <span className='tw-text-2xl tw-font-bold tw-text-text'>
            Automate reminders
          </span>

          <div className='tw-flex tw-flex-row tw-gap-5'>
            {automationOnboardingFeatures.map(({ icon: Icon, title }) => (
              <div
                key={title}
                className='tw-flex tw-flex-col tw-items-center tw-gap-3'
              >
                <Icon weight='duotone' size={40} className='tw-text-green' />
                <span className='tw-font-semibold tw-text-text'>{title}</span>
              </div>
            ))}
          </div>
          <span>
            Never miss an important update or task, whether it is approvals or
            team celebrations.
          </span>

          <Button color='secondary' onClick={onStart}>
            Get Started
          </Button>

          <a
            href={automationLearnMoreLink}
            target='_blank'
            rel='noreferrer'
            className='tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-text-80'
          >
            <Play />
            <span>Watch video</span>
          </a>
        </div>
      </div>
    </div>
  )
}
