import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { Col, Container, Row, TabPane } from 'reactstrap'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { t } from 'i18next'

import { ReactComponent as RpCard } from '../../assets/images/card-withdraw.svg'
import CardSelector from '../../components/CardSelector'
import ModalHeader from '../../components/ModalHeader'
import Steps from '../../components/Steps'
import StepContainer from '../../components/Steps/StepContainer'
import Wootric from '../../components/Wootric/Wootric'
import TransactionError from '../../components/transaction-error'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import TabContent from '../../components/ui/tabs'
import FEATURE_FLAGS from '../../config/feature-flags'
import {
  PAYSEND,
  TOP_UP_CARD,
  WITHDRAW_METHODS,
} from '../../core/config/payment-methods'
import { useFetch } from '../../helpers/hooks'
import {
  createWithdraw,
  getContractorWithdrawMethods,
  prepareWithdraw,
} from '../../services/api'
import { updateWithdrawAmount } from '../../store/withdraw/actions'
import { TitleBadge } from '../Activity/generic-banner'
import { CARD_ADVANTAGES } from '../remotepass-cards/components/request-new-card'
import BankAccounts from './BankAccounts'
import CashPointAccount from './CashPointAccount'
import CoinbaseAccounts from './CoinbaseAccounts'
import ConfirmationPage from './ConfirmationPage'
import PaypalAccounts from './PaypalAccounts'
import CardWithdrawAccounts from './credit-cards'
import PayoneerWithdrawAccounts from './payoneer-withdraw-accounts'
import WithdrawReview from './withdrawReview'
import capitalizeFirstLetter from '../../utils/capitalize-first-letter'

const WithdrawProcess = ({ history }) => {
  const { type } = useParams()

  const [activeTab, setActiveTab] = useState(type ? 1 : 0)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [account, setAccount] = useState(null)
  const preWithdraw = useFetch({
    checkSuccess: true,
    action: prepareWithdraw,
    onComplete: () => {
      if (preWithdraw.error) {
        toastr.error(preWithdraw.error)
      }
    },
  })

  const withdrawAmount = useSelector((state) => state?.Withdraw?.withdrawAmount)
  const withdrawCurrency = withdrawAmount?.currency.code

  const { data: methods, isLoading: gettingMethods } = useFetch(
    {
      action: getContractorWithdrawMethods,
      autoFetch: !!withdrawCurrency,
      body: { currency: withdrawCurrency },
    },
    [withdrawCurrency],
  )

  const filteredMethods = methods?.filter((method) =>
    !FEATURE_FLAGS.PAYSEND_INTEGRATION ? method.id !== PAYSEND.id : true,
  )

  const withdraw = useFetch({
    autoFetch: false,
    initResult: null,
    action: createWithdraw,
    onComplete: () => {
      if (withdraw.error) {
        toastr.error(withdraw.error)
      } else {
        window.analytics.track('Withdraw step 4 - Withdraw Confirmation', {
          balance_amount: withdrawAmount?.amount,
          balance_currency: withdrawCurrency,
          amount_selected: withdrawAmount?.amount,
          withdraw_account: account?.id,
          withdraw_method: paymentMethod?.label,
          transaction_id: withdraw.data?.ref,
        })
        dispatch(updateWithdrawAmount(null))
        setActiveTab(activeTab + 1)
      }
    },
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (!withdrawAmount) history.push('/withdraw')

    return () => {
      dispatch(updateWithdrawAmount(null))
    }
    // We want to run this check only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectMethod = (method) => {
    setPaymentMethod(method)
    setAccount(null)
    window.analytics.track('Withdraw step 1 - Method', {
      balance_amount: withdrawAmount?.amount,
      balance_currency: withdrawCurrency,
      amount_selected: withdrawAmount?.amount,
      withdraw_method: method?.label,
    })
  }

  const handleSelectBankAccount = (account) => {
    setAccount(account)
    window.analytics.track('Withdraw step 2 - Account', {
      balance_amount: withdrawAmount?.amount,
      balance_currency: withdrawCurrency,
      amount_selected: withdrawAmount?.amount,
      withdraw_account: account?.id,
      withdraw_method: paymentMethod?.label,
    })
  }

  const handleBankAccountAdd = (account) => {
    window.analytics.track('Withdraw step 2 - Added account', {
      balance_amount: withdrawAmount?.amount,
      balance_currency: withdrawCurrency,
      amount_selected: withdrawAmount?.amount,
      withdraw_account: account?.id,
      withdraw_method: paymentMethod?.label,
    })
  }

  const handleAccountSelect = (account) => {
    setAccount(account)
    window.analytics.track('Withdraw step 2 - Account', {
      balance_amount: withdrawAmount?.amount,
      balance_currency: withdrawCurrency,
      amount_selected: withdrawAmount?.amount,
      withdraw_account: account?.id,
      withdraw_method: paymentMethod?.label,
    })
  }

  const handleCashPointAccountSelect = (account) => {
    setAccount(account)
  }

  const handleConfirmWithdraw = () => {
    window.analytics.track('Withdraw step 3 - Review', {
      balance_amount: withdrawAmount?.amount,
      balance_currency: withdrawCurrency,
      amount_selected: withdrawAmount?.amount,
      withdraw_account: account?.id,
      withdraw_method: paymentMethod?.label,
    })
    withdraw.startFetch({
      quote_id: preWithdraw.data?.quote_id,
    })
  }

  const handleStepTow = () => {
    const body = {
      amount: withdrawAmount?.amount,
      currency_id: withdrawAmount?.currency?.id,
      payment_method_id: paymentMethod?.id,
      account_id: account?.id,
    }

    if (paymentMethod?.id === PAYSEND.id) {
      body.card_currency_code = account?.currencyCode
      body.account_id = account?.account_id
    }

    preWithdraw.startFetch(body)

    setActiveTab(activeTab + 1)
  }
  const handleTopUpReview = () => {
    const paymentMethodId = paymentMethod?.id

    preWithdraw.startFetch({
      amount: withdrawAmount?.amount,
      currency_id: withdrawAmount?.currency?.id,
      payment_method_id: paymentMethodId,
      account_id: account?.id,
    })
    setActiveTab(activeTab + 2)
  }
  const stepMinHeight = '60vh'

  return (
    <div>
      <Container fluid className='!tw-px-0'>
        <ModalHeader
          action={() => {
            history.replace('/withdraw')
          }}
        >
          <Steps
            activeTab={activeTab}
            data={[
              t('Withdrawal methods'),
              capitalizeFirstLetter(t('account')),
              t('Review'),
              t('Withdraw'),
            ]}
            noLastAction
            className='d-none d-md-flex'
          />
        </ModalHeader>
        <Row className='justify-content-center'>
          <Col lg={activeTab === 0 ? 6 : 5}>
            <TabContent
              activeTab={activeTab}
              className='twitter-bs-wizard-tab-content'
            >
              <TabPane tabId={0}>
                <StepContainer
                  index={0}
                  total={4}
                  title={t('Withdrawal methods')}
                  onNext={() =>
                    setActiveTab(
                      paymentMethod?.id === TOP_UP_CARD.id
                        ? handleTopUpReview
                        : activeTab + 1,
                    )
                  }
                  onBack={() => setActiveTab(activeTab - 1)}
                  nextText={t('Next')}
                  disableNext={!paymentMethod}
                >
                  {gettingMethods ? (
                    <Loader minHeight={254} />
                  ) : (
                    <>
                      {filteredMethods &&
                      filteredMethods?.filter(
                        (p) =>
                          p.id === TOP_UP_CARD.id &&
                          !p.has_card &&
                          p?.is_qualified_to_order_card,
                      ).length > 0 ? (
                        <CardSection />
                      ) : null}
                      <CardSelector
                        options={
                          (filteredMethods &&
                          filteredMethods[0]?.id === 10 &&
                          !filteredMethods[0]?.has_card
                            ? filteredMethods.filter(
                                (p) => p.id !== TOP_UP_CARD.id,
                              )
                            : filteredMethods) ?? []
                        }
                        value={paymentMethod}
                        onSelect={handleSelectMethod}
                      />
                    </>
                  )}
                </StepContainer>
              </TabPane>
              <TabPane tabId={1}>
                <StepContainer
                  title={t('Select an Account')}
                  index={1}
                  total={4}
                  onNext={handleStepTow}
                  onBack={() => setActiveTab(activeTab - 1)}
                  disableNext={!account}
                  minHeight='50vh'
                >
                  {activeTab === 1 && (
                    <>
                      {paymentMethod?.id ===
                        WITHDRAW_METHODS.BANK_TRANSFER.id && (
                        <BankAccounts
                          onNext={handleSelectBankAccount}
                          onAdd={handleBankAccountAdd}
                          currency={withdrawCurrency}
                        />
                      )}
                      {paymentMethod?.id === PAYSEND.id && (
                        <CardWithdrawAccounts
                          onNext={handleSelectBankAccount}
                          currency={withdrawCurrency}
                        />
                      )}

                      {paymentMethod?.id === WITHDRAW_METHODS.PAYPAL.id && (
                        <PaypalAccounts
                          isPayoneer={paymentMethod?.id === 3}
                          onNext={handleAccountSelect}
                        />
                      )}

                      {paymentMethod?.id === WITHDRAW_METHODS.PAYONEER.id && (
                        <PayoneerWithdrawAccounts
                          onNext={handleAccountSelect}
                        />
                      )}

                      {paymentMethod?.id === WITHDRAW_METHODS.COINBASE.id && (
                        <CoinbaseAccounts onNext={handleAccountSelect} />
                      )}

                      {paymentMethod?.id === WITHDRAW_METHODS.CASH_POINT.id && (
                        <CashPointAccount
                          editing={true}
                          onNext={handleCashPointAccountSelect}
                        />
                      )}
                    </>
                  )}
                </StepContainer>
              </TabPane>
              <TabPane tabId={2}>
                <StepContainer
                  nextText={t('Confirm & Withdraw')}
                  disableNext={
                    preWithdraw.isLoading ||
                    preWithdraw.error ||
                    (paymentMethod?.id === TOP_UP_CARD.id &&
                      !paymentMethod?.has_full_kyc &&
                      preWithdraw?.data?.total > 1000)
                  }
                  title={t('Review & Withdraw')}
                  index={2}
                  total={4}
                  onNext={handleConfirmWithdraw}
                  onBack={() =>
                    setActiveTab(
                      paymentMethod?.id === TOP_UP_CARD.id
                        ? activeTab - 2
                        : activeTab - 1,
                    )
                  }
                  minHeight={stepMinHeight}
                  isFlat
                  loading={withdraw.isLoading}
                >
                  {preWithdraw.error ? (
                    <TransactionError
                      errorText={preWithdraw.error}
                      minHeight='max(55vh, 380px)'
                      genericErrorText={t(
                        'An error occurred while withdrawing your funds.',
                      )}
                    />
                  ) : (
                    <WithdrawReview
                      onNext={handleConfirmWithdraw}
                      bankInfo={preWithdraw.data}
                      loading={preWithdraw.isLoading}
                      actionLoading={withdraw.isLoading}
                      method={paymentMethod}
                      onBack={() => {
                        setActiveTab(
                          paymentMethod?.id === TOP_UP_CARD.id
                            ? activeTab - 2
                            : activeTab - 1,
                        )
                      }}
                    />
                  )}
                </StepContainer>
              </TabPane>
              <TabPane tabId={3}>
                <ConfirmationPage
                  onComplete={() => {
                    history.push('/')
                  }}
                  paymentMethod={paymentMethod}
                  data={withdraw.data}
                />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
      {activeTab === 3 && <Wootric />}
    </div>
  )
}

function CardSection() {
  return (
    <div className='d-flex flex-column flex-md-row border border-ligh rounded bg-transparent text-left align-items-center justify-content-start p-0 mb-3'>
      <div
        className='d-none d-md-block p-3 py-5'
        style={{ backgroundColor: '#F0F4FE' }}
      >
        <RpCard />
      </div>
      <div>
        <div className='align-items-center d-flex pt-4 px-3'>
          <p
            className='font-size-24 text-left mb-0'
            style={{ fontWeight: 700 }}
          >
            {t('RemotePass Card')}{' '}
          </p>
          <TitleBadge className='d-block ml-1 px-1' />
        </div>
        <div className='d-flex flex-column flex-md-row'>
          <div className='pb-4 px-3'>
            {CARD_ADVANTAGES.map((e, i) => (
              <div key={i} className='d-flex py-1'>
                <div
                  className='bg-soft-success rounded-circle align-items-center justify-content-center'
                  style={{
                    height: 24,
                    width: 24,
                    paddingLeft: 2,
                    paddingTop: 2,
                  }}
                >
                  <i className='bx bx-check text-success font-size-20 text-center' />
                </div>
                <div>
                  <p
                    className='font-size-14 text-slate-700 text-left mb-0 pl-1'
                    style={{ fontWeight: 400 }}
                  >
                    {e?.title}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className='d-flex flex-column align-items-center justify-content-evenly px-2 pr-3'>
            <Button
              block
              tag={Link}
              to='/cards'
              style={{ textDecoration: 'none' }}
            >
              {t('Get Started')}
            </Button>
            <a
              className='underline text-primary mt-1'
              target='_blank'
              href='https://help.remotepass.com/en/collections/3728752-remotepass-card'
              rel='noreferrer'
            >
              {t('Learn more')}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WithdrawProcess
