import { t } from 'i18next'
import React, { Suspense, useState } from 'react'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import { Container } from 'reactstrap'

import { Stepper } from 'ui'
import Head from '../../../components/head'
import ModalHeader from '../../../components/ModalHeader'
import StepContainer from '../../../components/Steps/StepContainer'
import Loader from '../../../components/ui/loader'
import { useFetch } from '../../../helpers/hooks'
import { getIntegrations } from '../../../services/api-integrations'
import { integrationDataMap } from '../integrations-v2'

const IntegrationSetupStep1 = React.lazy(
  () => import('./integration-setup-step-1'),
)
const IntegrationSetupStep2 = React.lazy(
  () => import('./integration-setup-step-2'),
)
const IntegrationSetupStep3 = React.lazy(
  () => import('./integration-setup-step-3'),
)

const getBasePath = ({ key }) => `/settings/integrations/${key}/setup`

export function getIntegrationFormStepId(step) {
  return `integration-setup-form-step-${step.key}`
}
function getPrevStepLink({ step, steps, basePath }) {
  const stepIndex = steps.findIndex((s) => s.key === step.key)
  const prevStepKey = steps[stepIndex - 1]?.key

  if (!prevStepKey) return null

  return `${basePath}/${prevStepKey}`
}
function getNextStepLink({ step, steps, basePath }) {
  const stepIndex = steps.findIndex((s) => s.key === step.key)
  const nextStepKey = steps[stepIndex + 1]?.key

  if (!nextStepKey) return null

  return `${basePath}/${nextStepKey}`
}

export function IntegrationSetup() {
  const history = useHistory()
  const params = useParams()
  const { stepKey, integrationKey } = params

  const [loading, setLoading] = useState(false)
  const [disabledNext, setDisabledNext] = useState(false)

  const disableNext = loading || disabledNext

  const { data: integrations, isLoading: loadingIntegrations } = useFetch(
    { action: getIntegrations, autoFetch: !!integrationKey },
    [integrationKey],
  )

  const integration = integrations
    ?.map(integrationDataMap)
    ?.find((i) => i.key === integrationKey)

  if (!integrationKey) {
    history.push('/settings/integrations')

    return t('Redirecting') + ' ...'
  }

  const integrationSteps = integrationSetupSteps({ t })

  const stepperSteps = integrationSteps.map((step) => step.name)
  const stepKeys = integrationSteps.map((step) => step.key)
  const activeIndex = stepKeys.indexOf(stepKey ?? '')
  const activeStep = integrationSteps[activeIndex]

  const isLastStep = activeIndex === integrationSteps.length - 1

  function handleReturn() {
    history.push(history.location.state?.backRoute ?? '/settings/integrations')
  }

  const pageTitleWithStepName = [activeStep?.name, t('Integration Setup')]
    .filter(Boolean)
    .join(' - ')

  function handlePrev(step) {
    const prevStepLink = getPrevStepLink({
      step,
      steps: integrationSteps,
      basePath: getBasePath({ key: integrationKey }),
    })
    history.push(prevStepLink ?? getBasePath({ key: integrationKey }), {
      backRoute: history.location.state?.backRoute,
    })
  }
  function handleNext(step) {
    const nextStepLink = getNextStepLink({
      step,
      steps: integrationSteps,
      basePath: getBasePath({ key: integrationKey }),
    })

    if (nextStepLink) {
      history.push(nextStepLink, {
        backRoute: history.location.state?.backRoute,
      })
    }
  }

  return (
    <Container fluid className='!tw-px-0'>
      <Head title={pageTitleWithStepName} />

      <ModalHeader action={handleReturn}>
        <Stepper
          steps={stepperSteps}
          activeStep={activeIndex}
          className='tw-flex-grow'
        />
      </ModalHeader>

      <div className='tw-px-4 tw-pb-11'>
        <Switch>
          {integrationSteps.map((step, index) => {
            const { key, Component, name } = step

            if (index !== 0) {
              return (
                <Route
                  path={`${getBasePath({ key: integrationKey })}/${key}`}
                  key={index}
                  exact
                >
                  <Suspense fallback={<Loader minHeight='100%' />}>
                    {loadingIntegrations ? (
                      <Loader minHeight='100%' />
                    ) : (
                      <Component
                        step={step}
                        goToNextStep={() => handleNext(step)}
                        basePath={getBasePath({ key: integrationKey })}
                        setLoading={setLoading}
                        setDisabledNext={setDisabledNext}
                        integration={integration}
                      />
                    )}
                  </Suspense>
                </Route>
              )
            }

            return (
              <Route
                path={`${getBasePath({ key: integrationKey })}/${key}`}
                key={index}
                exact
              >
                <StepContainer
                  title={name}
                  index={activeIndex}
                  total={integrationSteps.length}
                  nextType='submit'
                  nextFormId={getIntegrationFormStepId(step)}
                  nextText={isLastStep ? t('Save') : t('Continue')}
                  noBack={index === 0}
                  disableNext={disableNext || loadingIntegrations}
                  loading={loading}
                  onBack={() => handlePrev(step)}
                >
                  <Suspense fallback={<Loader minHeight='100%' />}>
                    {loadingIntegrations ? (
                      <Loader minHeight='100%' />
                    ) : (
                      <Component
                        step={step}
                        goToNextStep={() => handleNext(step)}
                        basePath={getBasePath({ key: integrationKey })}
                        setLoading={setLoading}
                        setDisabledNext={setDisabledNext}
                        integration={integration}
                      />
                    )}
                  </Suspense>
                </StepContainer>
              </Route>
            )
          })}
        </Switch>
      </div>
    </Container>
  )
}

function integrationSetupSteps({ t }) {
  return [
    {
      name: t('Account Mapping'),
      key: '',
      Component: IntegrationSetupStep1,
      next: 'worker-mapping',
    },
    {
      name: t('Worker Mapping'),
      key: 'worker-mapping',
      Component: IntegrationSetupStep2,
      next: 'completed',
    },
    {
      name: t('Completed'),
      key: 'completed',
      Component: IntegrationSetupStep3,
    },
  ]
}
