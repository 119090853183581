import React from 'react'
import lowerCase from 'lodash/lowerCase'
import {
  ACTION_TYPE,
  allTimezones,
  defaultValue,
  minutesInDay,
  rangeOccurrences,
  slackConnections,
  slackRecipients,
  TRIGGER_FILTER,
  triggerActionType,
  triggerFilterConditions,
  triggers,
} from './constants'
import set from 'lodash/set'
import compact from 'lodash/compact'
import upperCase from 'lodash/upperCase'
import {
  updateAutomation as updateAutomationApi,
  updateSteps as updateStepsApi,
} from '../../../../../services/api-automations'
import isEqual from 'lodash/isEqual'
import reduce from 'lodash/reduce'
import mapValues from 'lodash/mapValues'
import isEmpty from 'lodash/isEmpty'
import entries from 'lodash/entries'
import mapKeys from 'lodash/mapKeys'
import uniq from 'lodash/uniq'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import { format } from 'date-fns-tz'
import { includes, sortBy, values } from 'lodash'
import isArray from 'lodash/isArray'
import { SlackRemoveConnection } from '../slack-remove-connection'
import { makeHtml, makeMarkDown } from './html-convertor'
import { transformForBackend } from './placeholder-convertor'

export function transformAutomationResponse(res) {
  const transformSteps = (_steps) => {
    let hasTrigger = false
    let hasAction = false
    let filters
    const steps = _steps.map((s) => {
      const type = lowerCase(s.type)
      const step = { type }
      if (type === 'trigger') {
        const {
          minutesBefore,
          minutesLong,
          event,
          filters: _filters = {},
        } = s.trigger

        const { filtersNotFound } = reduce(
          entries(_filters),
          (result, [key, value]) => {
            if (includes(values(TRIGGER_FILTER), key)) {
              const condition = triggerFilterConditions.find(
                (tf) => tf.value === value.operator,
              )
              set(step, `filters.${key}`, value?.value)
              set(step, 'filterCondition', condition)
            } else {
              result.filtersNotFound[key] = value
            }
            return result
          },
          { filtersNotFound: {} },
        )

        const trigger = triggers
          .reduce((c, p) => [...c, ...p.options], [])
          .find((t) => {
            if (step?.filters) {
              return t.apiValue === event
            }
            return t.value === event
          })
        set(step, 'event', trigger)
        if (!isNil(minutesBefore) || !isNil(minutesLong)) {
          const value = !isNil(minutesLong) ? minutesLong : minutesBefore
          set(step, 'occurrenceDays', value / minutesInDay)
          set(
            step,
            'typeOfOccurrence',
            rangeOccurrences[!isNil(minutesBefore) ? 0 : 1],
          )
        }

        filters = mapKeys(
          mapValues(filtersNotFound, (f) => f.value),
          (value, key) => key.replaceAll('.', '$').replaceAll(',', '#'),
        )

        hasTrigger = true
      }

      if (type === 'action') {
        const channel = s?.action?.channel
        set(
          step,
          'actionType',
          triggerActionType.find((i) => i.value === channel),
        )
        if (channel === ACTION_TYPE.EMAIL) {
          const message = s?.action?.customMessage
          const recipients = (s?.action?.notificationTargets ?? []).map((r) => {
            if (r?.email) {
              return {
                value: r.email,
                email: r.email,
                label: r.name,
              }
            } else
              return {
                value: r.targetVariable,
                label: r.name,
              }
          })
          set(step, 'subject', message?.emailSubject ?? '')
          set(step, 'body', message?.emailBody ?? '')
          set(step, 'recipients', recipients)
        }

        if (channel === ACTION_TYPE.SLACK) {
          const message = s?.action?.customMessage

          const connectionId = s.action?.connectionId
          if (connectionId) set(step, 'connection', { value: connectionId })
          set(
            step,
            'recipients',
            (s.action?.slackNotificationTargets ?? []).map((i) => ({
              type: i.targetType,
              value: i.channel,
            })),
          )
          set(
            step,
            'body',
            message?.emailBody ? makeHtml(message?.emailBody) : '',
          )
        }

        const scheduledOn = s?.action?.scheduledOn
        if (scheduledOn) {
          const [, time, offset] = scheduledOn.match(
            /^(\d{2}:\d{2})([+-]\d{2}:\d{2})$/,
          )

          const timeZone = getTimeZones(allTimezones).find(
            (i) => i.offset === offset,
          )

          set(step, 'scheduled', true)
          set(step, 'time', time)
          set(step, 'timeZone', timeZone)
        }

        hasAction = true
      }

      if (type === 'delay') {
        const minute = s.delay.minutes
        set(step, 'numberOfDays', minute / minutesInDay)
      }
      return step
    })
    if (!hasTrigger) steps.unshift(defaultValue.steps[0])
    if (!hasAction) steps.push(defaultValue.steps[1])
    return { steps, filters }
  }
  const { steps, filters } = transformSteps(res?.steps ?? [])
  return {
    name: res?.name ?? '',
    focusedNode: null,
    filters,
    description: res?.description ?? '',
    isPublished: res?.is_published ?? false,
    steps,
  }
}

export function transformStepsRequest(_steps = [], _filters) {
  return compact(
    _steps.map((s) => {
      const step = { type: upperCase(s.type) }
      if (s.type === 'trigger') {
        const {
          typeOfOccurrence,
          occurrenceDays,
          event,
          filterCondition,
          filters: triggerFilter,
        } = s ?? {}
        const {
          value,
          apiValue,
          occurrence,
          filter: { key } = {},
        } = event ?? {}
        let filters = {}
        if (apiValue || value) set(step, 'trigger.event', apiValue || value)
        else return null
        if (occurrence && !isNil(occurrenceDays))
          set(
            step,
            `trigger.${occurrence?.key ?? typeOfOccurrence?.key}`,
            Number(occurrenceDays) * minutesInDay,
          )

        if (triggerFilter && key) {
          set(filters, key, {
            value: triggerFilter[key],
            operator: filterCondition?.value,
          })
        }

        if (!isEmpty(_filters)) {
          filters = {
            ...filters,
            ...reduce(
              entries(_filters),
              (prev, [name, value]) => ({
                ...prev,
                [name.replaceAll('$', '.').replaceAll('#', ',')]: {
                  value,
                  operator: isArray(value) ? 'in' : '>=',
                },
              }),
              {},
            ),
          }
        }

        set(step, 'trigger.filters', filters)
      }
      if (s.type === 'action') {
        if (!s?.actionType) return null
        const type = s.actionType.value
        if (type === ACTION_TYPE.EMAIL) {
          const emailSubject = s?.subject ?? ''
          const emailBody = s?.body ?? ''
          const to = (s?.recipients ?? []).map((t) => {
            if (t?.email) {
              return {
                targetType: 'EMAIL',
                email: t.email,
                name: t.label,
              }
            } else
              return {
                targetType: 'VARIABLE',
                targetVariable: t.value,
                name: t.label,
              }
          })
          set(step, 'action.customMessage', {
            emailSubject,
            emailBody: transformForBackend(emailBody),
          })
          set(step, 'action.notificationTargets', to)
        }
        if (type === ACTION_TYPE.SLACK) {
          const connection = s?.connection?.value
          if (connection) {
            set(step, 'action.connectionId', connection)
          }
          const recipients = (s?.recipients ?? []).map((i) => ({
            type: i.type,
            channel: i.value,
          }))
          set(step, 'action.slackNotificationTargets', recipients)

          const body = s?.body ?? ''
          set(
            step,
            'action.customMessage.emailBody',
            makeMarkDown(transformForBackend(body)),
          )
        }

        if (s?.scheduled && s?.time && s?.timeZone)
          set(step, 'action.scheduledOn', `${s.time}${s.timeZone.offset}`)

        set(step, 'action.type', 'SEND')
        set(step, 'action.channel', type)
      }
      if (s.type === 'delay') {
        if (!s.numberOfDays) return null
        set(step, 'delay.minutes', s.numberOfDays * minutesInDay)
      }
      return step
    }),
  )
}

export function transformFilters(array) {
  const data = reduce(
    array,
    (acc, item) => {
      if (Object.values(TRIGGER_FILTER).includes(item.key)) {
        return acc
      }
      const key = item.key.replaceAll('.', '$').replaceAll(',', '#')
      if (!acc.all.some((item) => item.filter === key)) {
        acc.all.push({
          title: item.title,
          displayCategory: item?.display_category,
          categories: item?.available_categories ?? [],
          type: item?.value_type,
          filter: key,
        })
        acc[key] = item.available_values
      } else {
        if (acc[key] === null) {
          acc[key] = item.available_values
        }
      }
      return acc
    },
    { all: [] },
  )

  const allWithHeaders = sortBy(data.all, 'displayCategory').reduce(
    (result, item) => {
      const lastHeader = result.find(
        (entry) =>
          entry.type === 'header' &&
          entry.displayCategory === item.displayCategory,
      )

      if (!lastHeader) {
        result.push({
          title: item.displayCategory,
          type: 'header',
          displayCategory: item.displayCategory,
          categories: [...item.categories],
        })
      } else {
        lastHeader.categories = Array.from(
          new Set([...lastHeader.categories, ...item.categories]),
        )
      }

      result.push(item)
      return result
    },
    [],
  )

  return { ...data, all: allWithHeaders }
}

export function transformSlackRecipients(recipients) {
  if (recipients) {
    return [
      {
        ...slackRecipients.directs,
        options: recipients?.users?.map((u) => ({
          label: `@${u.real_name}`,
          type: 'DM',
          value: u.id,
        })),
      },
      {
        ...slackRecipients.channels,
        options: recipients?.channels?.map((i) => ({
          label: i.name,
          value: i.id,
          type: 'CHANNEL',
          description: `${i.num_members} Member${i?.num_members > 1 ? 's' : ''} ( ${i.is_private ? 'Private' : 'Public'} )`,
        })),
      },
    ]
  }
  return [slackRecipients.directs, slackRecipients.channels]
}

export function transformSlackConnection(workspaces) {
  if (workspaces) {
    return [
      {
        ...slackConnections,
        options: (workspaces ?? []).map(
          ({
            _id,
            teamName,
            owner = [],
            is_shared: shared = true,
            automations_no: usedCount = 0,
          }) => ({
            value: _id,
            label: owner?.[0]?.username
              ? `@${owner[0].username} | ${teamName}`
              : teamName,
            shared,
            description: `${shared ? 'Shared' : 'Not shared'} | Used in ${usedCount} Automation(s)`,
            rightIcon: <SlackRemoveConnection id={_id} />,
          }),
        ),
      },
    ]
  } else return []
}

export function transformParams({
  page,
  limit,
  onlyTemplates,
  actionType,
  triggerType,
  status,
  name,
}) {
  const statusFilters = {
    draft: { isPublishable: false },
    active: { isPublished: true },
    inactive: { isPublishable: true, isPublished: false },
  }
  return omitBy(
    {
      page,
      limit,
      name,
      isTemplate: onlyTemplates,
      actionChannel: actionType?.value,
      triggerEventType: triggerType?.apiValue || triggerType?.value,
      ...statusFilters[status?.value],
    },
    isNil,
  )
}

export async function updateAutomation(
  token,
  {
    id,
    steps,
    name,
    isPublished,
    currentAutomation = {},
    filters,
    forceCreate,
  },
) {
  try {
    const stepsRequest = transformStepsRequest(steps, filters)
    const currentRequest = currentAutomation
      ? transformStepsRequest(
          currentAutomation.steps,
          currentAutomation.filters,
        )
      : null
    const isStepsSame = isEqual(stepsRequest, currentRequest)
    const isAutomationSame = isEqual(
      { name, isPublished },
      {
        name: currentAutomation?.name,
        isPublished: currentAutomation?.isPublished,
      },
    )
    if (!isStepsSame || forceCreate)
      await updateStepsApi(token, { steps: stepsRequest, id })
    if (!isAutomationSame || forceCreate)
      await updateAutomationApi(token, { id, name, isPublished })
    return await Promise.resolve({})
  } catch (e) {
    return await Promise.reject(e)
  }
}

export function getTimeZones(allTimezones) {
  const now = new Date()
  return Object.entries(allTimezones).map(([timeZone, description]) => {
    const offset = format(now, 'xxx', { timeZone })
    const label = `GMT${offset}`
    const value = timeZone.toLowerCase().replace(/\//g, '_')

    return { label, description, value, offset }
  })
}

export function getStepsCount(steps = []) {
  return steps.reduce(
    (prev, cur) => {
      return {
        actions: prev.actions + (cur.type === 'action' ? 1 : 0),
        delays: prev.delays + (cur.type === 'delay' ? 1 : 0),
      }
    },
    {
      actions: 0,
      delays: 0,
    },
  )
}

export function getSlackConnectionInputValue(v, connections) {
  if (v) {
    if (v.label) return v
    return connections[0].options.find((i) => i.value === v.value)
  }
  return null
}

export function getSlackRecipientInputValue(v, recipients) {
  if (v?.length) {
    return recipients
      .reduce((prv, curr) => [...prv, ...curr.options], [])
      ?.filter((i) => v.map((b) => b.value).includes(i.value))
  }
  return null
}

export function groupPlaceholders(placeholders) {
  return sortBy(placeholders, 'display_category').reduce((result, item) => {
    const lastHeader = result.at(-1)

    if (!lastHeader || lastHeader.display_category !== item.display_category) {
      result.push({ ...item, title: item.display_category, type: 'header' })
    } else {
      lastHeader.available_categories = uniq([
        ...lastHeader.available_categories,
        ...item.available_categories,
      ])
    }

    result.push(item)
    return result
  }, [])
}
