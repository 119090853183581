import { Eye, EyeClosed } from '@phosphor-icons/react'
import { AvField } from 'availity-reactstrap-validation'
import React, { useState } from 'react'

const TextInput = ({
  disabled,
  value,
  name,
  id,
  label,
  required,
  type,
  placeholder,
  validate,
  autocomplete,
  onChange,
  onKeyUp,
  onBlur,
  translate,
  labelClassName,
  labelFor,
}) => {
  const [inputType, setInputType] = useState(type)

  return (
    <div className='tw-text-start'>
      {!label ? null : (
        <label className={labelClassName} htmlFor={id ?? labelFor}>
          {label}
          {required ? (
            <span
              className='tw-ms-1 tw-text-base tw-text-systemRed-100'
              style={{ lineHeight: 1 }}
            >
              *
            </span>
          ) : null}
        </label>
      )}

      <div className='tw-relative'>
        <AvField
          disabled={disabled}
          autoComplete={autocomplete}
          validate={validate}
          required={required}
          type={inputType || 'text'}
          placeholder={placeholder || ''}
          name={name}
          value={value}
          id={id}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          translate={translate}
        />

        {type === 'password' && (
          <PasswordInputTypeToggle
            className='tw-absolute tw-top-[21px] tw-flex -tw-translate-y-1/2 tw-rounded-sm tw-bg-white tw-p-1 focus-visible:tw-ring focus-visible:tw-ring-surface-30 ltr:tw-right-3 rtl:tw-left-3'
            inputType={inputType}
            toggle={setInputType}
          />
        )}
      </div>
    </div>
  )
}

export function PasswordInputTypeToggle({
  toggle,
  inputType,
  className,
  style,
}) {
  return (
    <button
      style={style}
      className={className}
      type='button'
      onClick={() => {
        toggle(inputType === 'password' ? 'text' : 'password')
      }}
    >
      {inputType === 'password' ? (
        <Eye weight='fill' size={18} />
      ) : (
        <EyeClosed size={18} />
      )}
    </button>
  )
}

export default TextInput
