'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Minus } from '@phosphor-icons/react';
import { cn } from '../lib/utils/cn';
const iconSize = 16;
export function ExpandIcon({ isExpanded, className, onClick, }) {
    return (_jsxs("button", { className: cn('tw-relative tw-flex tw-items-center tw-justify-center', className), onClick: onClick, style: {
            '--size': iconSize + 4 + 'px',
            width: 'var(--size)',
            height: 'var(--size)',
        }, children: [_jsx(Minus, { size: iconSize, weight: 'bold', className: 'tw-absolute' }), _jsx(Minus, { size: iconSize, weight: 'bold', className: cn('tw-absolute tw-transition-transform', isExpanded ? 'tw-rotate-180' : 'tw-rotate-90') })] }));
}
