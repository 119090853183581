import React, { useMemo, useState } from 'react'
import { Card } from 'reactstrap'
import { t } from 'i18next'
import { Paperclip, UploadSimple, Warning } from '@phosphor-icons/react'
import set from 'lodash/set'
import toastr from 'toastr'
import { format, isBefore } from 'date-fns'
import { cn } from 'ui'
import Head from '../../../../components/head'
import PageHeading from '../../../../components/ui/page-heading'
import Button from '../../../../components/ui/button'
import { useFetch, useProfile } from '../../../../helpers/hooks'
import DataTable from '../../../../components/ui/data-table'
import { getContractDocs, getContractList } from '../../../../services/api'
import Loader from '../../../../components/ui/loader'
import TabEmpty from '../../../Contract/components/tab/tab-empty'
import { DocumentTableActions } from './document-table-actions'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import UploadModal from '../../components/UploadModal'

export function ContractorGlobalDocuments() {
  const { Country_of_Tax_Residence: country } = useProfile()
  const [uploadDocumentModal, setUploadDocumentModal] = useState(false)
  const [contract, setContract] = useState()
  const { isLoading: isContractsLoading, data: contracts } = useFetch({
    action: (...args) =>
      getContractList(...args).then((res) => {
        set(
          res,
          'data.data',
          res.data?.data?.map((c) => ({
            label: `${c?.ref} - ${c?.name} (${c?.type})`,
            value: c?.id,
          })),
        )
        return res
      }),
    autoFetch: true,
    onError: (error) => toastr.error(error),
    onComplete: (res) => setContract(res[0]),
  })
  const {
    data: documents,
    startFetch: getDocuments,
    isLoading,
  } = useFetch(
    {
      action: getContractDocs,
      autoFetch: !!contract,
      onError: (err) => toastr.error(err),
      body: { id: contract?.value },
    },
    [contract],
  )

  const columns = useMemo(
    () => [
      {
        Header: t('Name'),
        Cell: ({ rowData }) => (
          <span className='tw-text-text'>{rowData?.name}</span>
        ),
      },
      {
        Header: t('Status'),
        Cell: ({ rowData }) =>
          rowData?.link ? (
            <span className='tw-rounded-full tw-bg-systemGreen-10 tw-px-2.5 tw-py-1 tw-font-bold tw-text-systemGreen'>
              UPLOADED
            </span>
          ) : (
            <span className='tw-rounded-full tw-bg-systemGold-10 tw-px-2.5 tw-py-1 tw-font-bold tw-text-systemGold'>
              REQUESTED
            </span>
          ),
      },
      {
        Header: t('Expires on'),
        Cell: ({ rowData }) => {
          const expiryDate = rowData?.expiry_date
            ? new Date(rowData?.expiry_date)
            : null
          const isExpired = expiryDate && isBefore(expiryDate, new Date())
          return (
            <div
              className={cn(
                'tw-flex tw-items-center tw-gap-1',
                isExpired && 'tw-text-red',
              )}
            >
              {isExpired ? <Warning /> : null}
              {expiryDate ? format(expiryDate, 'yyyy/MM/dd') : '-'}
            </div>
          )
        },
      },
      {
        Header: '',
        Cell: ({ rowData }) => (
          <DocumentTableActions rowData={rowData} refetch={getDocuments} />
        ),
      },
    ],
    [getDocuments],
  )
  return (
    <div className='page-content'>
      <Head title={t('Documents')} />
      <PageHeading className='tw-items-center'>
        <PageHeading.Title
          subtitle={t('base_on_tax_country', { country: country?.name ?? '' })}
        >
          Documents
        </PageHeading.Title>
        <Button
          onClick={() => setUploadDocumentModal(true)}
          icon={<UploadSimple />}
          className='tw-h-fit'
        >
          {t('Upload document')}
        </Button>
      </PageHeading>
      <Card className='tw-p-6'>
        <CustomSelect
          placeholder='Contract'
          options={contracts}
          wrapperClassName='tw-self-end md:tw-w-[25%] tw-w-[100%] tw-mb-6'
          isLoading={isContractsLoading}
          isClearable={false}
          value={contract}
          onChange={setContract}
        />

        {!contract ? null : isLoading ? (
          <Loader minHeight='28rem' />
        ) : documents?.length ? (
          <DataTable striped columns={columns} data={documents} responsive />
        ) : (
          <TabEmpty
            icon={
              <Paperclip size={250} color='var(--primary)' weight='duotone' />
            }
            title={t('No documents added')}
            subtitle={t('Submitted compliance documents will be shown here')}
          />
        )}
      </Card>

      <UploadModal
        isOpen={uploadDocumentModal}
        toggle={() => setUploadDocumentModal((prev) => !prev)}
        update={getDocuments}
      />
    </div>
  )
}
