import { Warning } from '@phosphor-icons/react'
import { t } from 'i18next'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CardBody } from 'reactstrap'
import toastr from 'toastr'

import ConfirmationModal from '../../components/Common/ConfirmationModal'
import { ModalCloseButton } from '../../components/Common/modal-close-button'
import Loader from '../../components/ui/loader'
import FEATURE_FLAGS from '../../config/feature-flags'
import { useFetch } from '../../helpers/hooks'
import { getIntegrationList } from '../../services/api'
import {
  getDisconnectIntegration,
  getIntegrations,
} from '../../services/api-integrations'
import IntegrationCard from './components/IntegrationCard'
import { integrationDataMap, IntegrationsV2 } from './integrations-v2'
import {
  ConnectCustomIntegrationModal,
  useConnectCustomIntegration,
} from './integrations-v2/connect-integration-modal'
import { IntegrationsListItem } from './integrations-v2/integration-item'

export default function Integrations() {
  return (
    <CardBody className='!tw-py-5'>
      {FEATURE_FLAGS.INTEGRATION_APP_V2 ? <IntegrationsV2 /> : null}

      <IntegrationsV1 />
    </CardBody>
  )
}

function IntegrationsV1() {
  const {
    data: integrations,
    isLoading,
    startFetch: fetchIntegrations,
  } = useFetch({
    action: getIntegrationList,
    autoFetch: true,
  })

  const {
    data: customIntegrationsData,
    isLoading: isLoadingCustomIntegrations,
    startFetch: refreshCustomIntegrations,
  } = useFetch({
    action: getIntegrations,
    autoFetch: FEATURE_FLAGS.INTEGRATION_APP,
  })

  if (isLoading || isLoadingCustomIntegrations) {
    return <Loader minHeight='max(40vh, 380px)' />
  }

  const integrationsByCategory = (
    customIntegrationsData?.map(integrationDataMap) ?? []
  ).reduce((acc, current) => {
    if (!acc[current.category]) {
      acc[current.category] = []
    }

    const subtitle = [current.organizationName, current.organizationId]
      .filter(Boolean)
      .join(' - ')

    acc[current.category].push({
      ...current,
      subtitle,
      isNewIntegrations: true,
    })

    return acc
  }, {})

  const integrationsList = integrations?.map((cat) => {
    const customCatItems = integrationsByCategory?.[cat?.category] ?? []
    return { ...cat, list: [...customCatItems, ...cat.list] }
  })

  return integrationsList?.map((cat, index) => (
    <React.Fragment key={index}>
      <h3 className='tw-mt-4 tw-text-2xl tw-font-bold'>{cat?.category}</h3>
      <div className='tw-grid tw-gap-2 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4'>
        {cat?.list?.map((integration, i) => {
          if (integration.isNewIntegrations) {
            return (
              <IntegrationV2Item
                integration={integration}
                key={integration.id}
                onUpdate={() => refreshCustomIntegrations()}
              />
            )
          }

          return (
            <IntegrationCard
              key={`integration-${integration.id}-${i}`}
              item={integration}
              onUpdate={fetchIntegrations}
            />
          )
        })}
      </div>
    </React.Fragment>
  ))
}

function IntegrationV2Item({ integration, onUpdate }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  function toggleModal() {
    setIsModalOpen((open) => !open)
  }
  const [disabling, setDisabling] = useState(false)
  const [selectedCustomIntegration, setSelectedCustomIntegration] =
    useState(null)

  const history = useHistory()

  const { startFetch: disconnectCustomIntegration } = useFetch({
    action: getDisconnectIntegration,
    onComplete: () => {
      onUpdate?.()
      setDisabling(false)
      toastr.success('Integration disconnected')
    },
    onError: () => {
      setDisabling(false)
      toastr.error('Failed to disconnect integration')
    },
  })

  function onConnectionSuccess() {
    onUpdate?.()
  }

  async function onDisable(integration) {
    setDisabling(true)
    disconnectCustomIntegration({ integrationKey: integration.key })
  }

  const { onConnect, isConnecting } = useConnectCustomIntegration({
    onConnectionSuccess,
  })

  return (
    <>
      <IntegrationsListItem
        integration={integration}
        onConnect={() => onConnect({ integration })}
        connectLoading={isConnecting}
        onDisable={toggleModal}
        onReview={() => {
          history.push(`/settings/integrations/${integration.key}/accounts`)
        }}
        disabling={disabling}
      />

      {!selectedCustomIntegration ? null : (
        <ConnectCustomIntegrationModal
          isOpen={!!selectedCustomIntegration}
          toggle={() => setSelectedCustomIntegration(null)}
          integration={selectedCustomIntegration}
          onConnectionSuccess={onConnectionSuccess}
        />
      )}

      <ConfirmationModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        negativeCaption={t('No, Close')}
        caption={t('Yes, Disconnect')}
        buttonColor='danger'
        onConfirm={() => onDisable(integration)}
        content={
          <>
            <div className='tw-relative tw-flex tw-items-center tw-justify-between tw-pb-2'>
              <Warning size={24} className='tw-text-systemRed-100' />
              <ModalCloseButton toggle={toggleModal} />
            </div>
            <h4 className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
              Are you sure you want to disable {integration.name} Integration?
            </h4>
            <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
              Once you click Disable, synchronization will stop and all previous
              mappings will be lost. Previously synced transactional data will
              remain and will not be reverted.
            </p>
          </>
        }
        confirmLoading={disabling}
      />
    </>
  )
}
