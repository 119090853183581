import Toggle from '../../../../../components/Forms/Toggle/Toggle'
import React, { useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap'
import {
  WarningOctagon,
  PlayCircle,
  PauseCircle,
  DotsThreeCircleVertical,
} from '@phosphor-icons/react'
import { useFetch, usePermissions } from '../../../../../helpers/hooks'
import { updateAutomation } from '../../../../../services/api-automations'
import { AutomationMetrics } from '../tools/automation-metrics'
import { PermissionTooltip } from '../../../../../components/permission-tooltip'
import { PERMISSION_GROUP } from '../../../manage-role'
import permissions from '../../../../../helpers/permissions'

export function AutomationStatusColumn({ rowData }) {
  const [open, setOpen] = useState()
  const toggle = () => setOpen((prv) => !prv)
  const [published, setPublished] = useState(rowData.is_published)
  const { hasAccess } = usePermissions()
  const { isLoading, startFetch } = useFetch({
    action: updateAutomation,
    isOpenApi: true,
    onComplete: (_, body) => {
      const metricPayload = {
        Source: 'List',
        Type: 'scratch',
        Trigger: rowData?.workflow_steps?.find((s) => s.type === 'TRIGGER'),
        Steps: rowData?.workflow_steps?.filter((s) => s.type !== 'TRIGGER'),
      }
      if (body.isPublished) {
        AutomationMetrics.logPublished(metricPayload)
      } else {
        AutomationMetrics.logUnpublished(metricPayload)
      }
      setPublished((prv) => !prv)
      toggle()
    },
  })

  return (
    <>
      <div className='tw-flex tw-flex-row tw-items-center'>
        <PermissionTooltip
          area={PERMISSION_GROUP.MANAGE_COMPANY_SETTINGS.name}
          showing={!hasAccess(permissions.ManageCompanyAutomation)}
          id='company-automations-statu-toggle-tooltip'
        >
          <Toggle
            check={published}
            change={() => setOpen(true)}
            disabled={Boolean(
              !hasAccess(permissions.ManageCompanyAutomation) ||
                isLoading ||
                !rowData?.is_publishable,
            )}
          />
        </PermissionTooltip>

        {published ? (
          <>
            <PlayCircle
              id='icon-active'
              size={25}
              className='tw-text-systemGreen'
            />
            <UncontrolledTooltip placement='right' target='icon-active'>
              Automation is active. Turn off the toggle to deactivate.
            </UncontrolledTooltip>
          </>
        ) : rowData.is_publishable ? (
          <>
            <PauseCircle
              size={25}
              id='icon-inactive'
              className='tw-text-secondary'
            />
            <UncontrolledTooltip placement='right' target='icon-inactive'>
              Automation is inactive. Turn on the toggle to activate.
            </UncontrolledTooltip>
          </>
        ) : (
          <>
            <DotsThreeCircleVertical
              id='icon-failed'
              size={25}
              className='tw-text-systemGold'
            />
            <UncontrolledTooltip placement='right' target='icon-failed'>
              Automation can&#39;t be published due to missing information.
            </UncontrolledTooltip>
          </>
        )}
      </div>

      <Modal centered isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <WarningOctagon size={20} weight='fill' color='var(--red)' />
        </ModalHeader>
        <ModalBody className='d-flex flex-column'>
          <span className='tw-text-base tw-font-bold'>
            Are you sure you want to change status of this automation?
          </span>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' outline={true} onClick={toggle}>
            No
          </Button>
          <Button
            color='danger'
            onClick={() => {
              startFetch({
                id: rowData._id,
                isPublished: !published,
              })
            }}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
