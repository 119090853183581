import React from 'react'

import BadgeV2 from '../../../../components/ui/badge-v2'

export function ContractorCell({ contractor }) {
  const isVendor = contractor.type === 'vendor'
  const isIndividual = contractor.type === 'individual'
  const fullName = [
    contractor.firstName,
    !isVendor ? contractor.lastName : null,
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <>
      <div className='tw-flex tw-items-center tw-gap-2'>
        <div className='tw-text-sm tw-font-semibold tw-text-text-120'>
          {fullName}
        </div>

        {isIndividual ? null : (
          <BadgeV2 status='secondary' size='sm'>
            {contractor?.type?.replace('-', ' ')}
          </BadgeV2>
        )}
      </div>
      <div className='tw-mt-0.5 tw-text-xs tw-text-disabled'>
        {contractor.email}
      </div>
    </>
  )
}
