import { t } from 'i18next'
import React from 'react'
import { CalendarX, FileX } from '@phosphor-icons/react'
import { UncontrolledTooltip } from 'reactstrap'

import BadgeV2 from '../../../../components/ui/badge-v2'

const Message = ({ main, when }) => (
  <span className='tw-text-start tw-text-sm tw-font-medium'>
    {main}
    <div className='tw-text-text-30'>{when}</div>
  </span>
)

const ContractTerminationBadge = ({ contract }) => {
  const isScheduledTermination = !!contract.is_scheduled_terminated

  return (
    <>
      <BadgeV2
        className='!tw-bg-systemRed !tw-p-3'
        leftIcon={
          isScheduledTermination ? <CalendarX size={16} /> : <FileX size={16} />
        }
        id='badge'
      >
        {isScheduledTermination ? t('Termination scheduled') : t('Terminated')}
      </BadgeV2>

      <UncontrolledTooltip target='badge' placement='bottom'>
        <Message
          main={
            isScheduledTermination
              ? t('Termination scheduled')
              : t('Contract terminated')
          }
          when={
            isScheduledTermination
              ? t('for terminationDate', {
                  terminationDate: contract.termination_date,
                })
              : t('as of terminationDate', {
                  terminationDate: contract.termination_date,
                })
          }
        />
      </UncontrolledTooltip>
    </>
  )
}

export default ContractTerminationBadge
