import { Money } from '@phosphor-icons/react'
import { format, isPast } from 'date-fns'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Card, NavItem, UncontrolledTooltip } from 'reactstrap'
import toastr from 'toastr'

import { Avatar, cn } from 'ui'
import Head from '../../components/head'
import DataTable from '../../components/ui/data-table'
import Loader from '../../components/ui/loader'
import PageHeading from '../../components/ui/page-heading'
import { useFetch, usePermissions } from '../../helpers/hooks'
import { getUnpaidList } from '../../services/api'
import { updateToPayList } from '../../store/payment/actions'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import { getFullName } from '../../utils/get-full-name'
import ContractRef from '../AdminPanel/components/ContractRef'
import { PageNav } from '../../components/page-nav'
import TabEmpty from '../Contract/components/tab/tab-empty'
import { AED_CODE } from './all-due-payments'
import { AllPaymentActions } from './payment-actions'
import { CheckItem } from '../../components/ui/check-item'
import { userTypes } from '../../helpers/enum'
import permissions from '../../helpers/permissions'
import { t } from 'i18next'

function _getPaymentIds(item) {
  return item?.payments
    ?.filter((p) => p.is_processable)
    ?.flatMap((p) => p?.works?.map((w) => w?.payment_item_id))
    ?.filter(Boolean)
}

const tabsData = (t) => {
  return [{ key: '', label: t('All Payments') }]
}

function updateToPayIds({ selectedElements, dispatch }) {
  dispatch(updateToPayList(selectedElements))
}
export function DuePaymentsPage() {
  const dispatch = useDispatch()
  const [selectedItems, setSelectedItems] = useState([])

  const {
    data: unpaidList,
    isLoading: unpaidListLoading,
    completed: unpaidListCompleted,
    error: unpaidListError,
  } = useFetch({
    action: getUnpaidList,
    initResult: [],
    autoFetch: true,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(t('Failed to fetch payments'))
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  useEffect(() => {
    updateToPayIds({ selectedElements: selectedItems, dispatch })
  }, [selectedItems])

  function handleToggleItem(item, works = null) {
    if (works) {
      const paymentItemIds = works?.map((w) => w?.payment_item_id)
      updatePaymentIds(paymentItemIds)
      return
    }

    const itemPaymentIds = _getPaymentIds(item)
    if (itemPaymentIds.every((id) => selectedItems.includes(id))) {
      // remove all the ids
      setSelectedItems((prev) =>
        prev.filter((id) => !itemPaymentIds.includes(id)),
      )
    } else {
      setSelectedItems(
        Array.from(new Set([...selectedItems, ...itemPaymentIds])),
      )
    }
  }

  // add or remove a group of related work payment item ids
  function updatePaymentIds(paymentIds) {
    if (paymentIds.some((paymentId) => selectedItems.includes(paymentId))) {
      setSelectedItems((prev) => prev.filter((id) => !paymentIds.includes(id)))
    } else {
      setSelectedItems((prev) => [...prev, ...paymentIds])
    }
  }

  const allUnpaidPaymentIds = unpaidList.flatMap(_getPaymentIds)

  const isAllSelected =
    selectedItems?.length > 0 &&
    selectedItems?.length === allUnpaidPaymentIds?.length

  return (
    <div className='page-content'>
      <Head title={t('Payments due')} />

      <PaymentsDueList
        data={unpaidList}
        loading={unpaidListLoading}
        loadingError={unpaidListError}
        selectedItems={selectedItems}
        onToggleItem={handleToggleItem}
        dataCompleted={unpaidListCompleted}
        isAllSelected={isAllSelected}
        toggleSelectAll={() => {
          if (isAllSelected) {
            setSelectedItems([])
          } else {
            setSelectedItems(allUnpaidPaymentIds)
          }
        }}
      />
    </div>
  )
}

function PaymentsDuePageNav() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const activeTab = params.get('tab') ?? ''

  return (
    <PageNav className='rp-shadow-2 !tw-mb-4 tw-rounded tw-bg-white'>
      {tabsData(t)?.map((nav) => {
        return (
          <NavItem key={nav.key}>
            <PageNav.Link
              isActive={activeTab === nav.key}
              tag={Link}
              to={nav.key ? `/payment?tab=${nav.key}` : '/payment'}
            >
              {nav.label}
            </PageNav.Link>
          </NavItem>
        )
      })}
    </PageNav>
  )
}

function PaymentsDueList({
  data,
  loading,
  loadingError,
  dataCompleted,
  selectedItems,
  onToggleItem,
  toggleSelectAll,
  isAllSelected,
}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { hasAccess } = usePermissions()

  const profile = useSelector((state) => state.userProfile.userProfile)
  const isClient = profile?.type === userTypes.COMPANY
  const currency = profile?.company?.currency || profile?.currency

  const isClientAndCanPrepareTransactions =
    isClient && hasAccess(permissions.PrepareTransactions)

  const columns = [
    {
      Header: t('Contract ref'),
      accessor: 'contract_ref',
      Cell: ({ cellData: contractRef }) => {
        return <ContractRef contractId={contractRef} />
      },
    },
    {
      Header: t('Name'),
      accessor: 'contractor_name',
      Cell: ({ rowData }) => {
        const firstPaymentContractor = rowData.payments?.[0]?.contractor
        return (
          <div className='tw-flex tw-items-center tw-gap-3'>
            <Avatar
              photo={firstPaymentContractor?.photo}
              name={firstPaymentContractor?.first_name}
              flag={firstPaymentContractor?.flag}
            />
            <div>{getFullName(firstPaymentContractor)}</div>
          </div>
        )
      },
    },
    { Header: t('Job title'), accessor: 'contract_name' },
    {
      Header: t('Total'),
      accessor: 'total_amount',
      className: 'tw-w-[260px]',
      Cell: ({ cellData, rowData }) => {
        const formatter = getCurrencyFormatter(rowData.currency?.code)
        return formatter.format(cellData)
      },
    },
    {
      Header: '',
      accessor: 'contract_ref',
      Cell: ({ cellData: contractRef, rowData: item }) => {
        if (!isClientAndCanPrepareTransactions) {
          return null
        }
        const paymentIds = _getPaymentIds(item)
        const isAllSubPaymentSelected =
          paymentIds.length &&
          paymentIds.every((id) => selectedItems.includes(id))
        const isSomeSubPaymentSelected =
          paymentIds.length &&
          paymentIds.some((id) => selectedItems.includes(id))

        const labelId = `item-${contractRef}`
        const isDisabled = item?.payments?.every((p) => !p.is_processable)
        return (
          <>
            <CheckItem
              labelId={labelId}
              className='tw-w-min'
              inputClassName='disabled:tw-opacity-80 disabled:tw-bg-surface-30'
              checked={isAllSubPaymentSelected}
              indeterminate={
                isSomeSubPaymentSelected && !isAllSubPaymentSelected
              }
              onChange={() => {
                onToggleItem(item)
              }}
              disabled={isDisabled}
            />
            {isDisabled && (
              <UncontrolledTooltip target={labelId}>
                {item?.payments?.[0]?.resolution_message}
              </UncontrolledTooltip>
            )}
          </>
        )
      },
    },
  ]

  const isEmpty =
    (dataCompleted || (!dataCompleted && loadingError)) &&
    (!data || data?.length <= 0)

  const totalToPay = data
    ?.flatMap((d) => d?.payments)
    ?.filter((payment) =>
      payment?.works
        ?.flatMap((work) => work?.payment_item_id)
        // .some because all works are calculated as one payment transaction amount
        ?.some((id) => selectedItems.includes(id)),
    )
    ?.reduce((prev, current) => {
      return prev + current?.trans_amount
    }, 0)

  const formatter = getCurrencyFormatter(currency?.code)
  const formattedTotal = formatter.format(totalToPay)

  const aedPayments = data?.filter((e) => e?.currency?.code === AED_CODE)

  const showAedPayments =
    currency?.code === AED_CODE &&
    aedPayments?.length > 0 &&
    aedPayments?.length < data?.length

  return (
    <div>
      <PageHeading>
        <PageHeading.Title>{t('Payments due')}</PageHeading.Title>

        {loading || isEmpty ? null : (
          <AllPaymentActions
            onPayAedPayments={() => {
              updateToPayIds({ selectedElements: aedPayments, dispatch })

              history.push('/pay-invoices')
            }}
            showAction={isClientAndCanPrepareTransactions}
            formattedTotalToPay={formattedTotal}
            rawTotalToPay={totalToPay}
            showAedPayments={showAedPayments}
          />
        )}
      </PageHeading>

      <PaymentsDuePageNav />

      <Card className='rp-shadow-2 tw-min-h-[30rem] tw-py-6'>
        {loading || (!dataCompleted && !loadingError) ? (
          <Loader minHeight='30rem' />
        ) : isEmpty ? (
          <TabEmpty
            icon={<Money size={250} color='var(--primary)' />}
            title={t('No payments due')}
            subtitle={t('Payments due will be shown here')}
          />
        ) : (
          <>
            {isClientAndCanPrepareTransactions && (
              <div className='tw-flex tw-justify-end tw-px-6'>
                <CheckItem
                  name='select-all-payment'
                  label={isAllSelected ? t('Unselect all') : t('Select all')}
                  className='tw-flex-row-reverse'
                  checked={isAllSelected}
                  indeterminate={selectedItems.length && !isAllSelected}
                  onChange={toggleSelectAll}
                />
              </div>
            )}

            <DataTable
              responsive
              isRowExpandable
              striped
              fontClassName='tw-text-text-100'
              columns={columns}
              data={data}
              expandable={{
                expandedRowRender: (record) => {
                  return record?.payments?.map((payment) => {
                    const formatter = getCurrencyFormatter(
                      payment.currency?.code,
                    )

                    const isInPast = isPast(new Date(payment?.due_date))

                    return payment.works.map((work) => {
                      const workPaymentId = `id_${work.payment_item_id}`
                      return (
                        <tr
                          key={work?.work_id}
                          className='!tw-bg-primary-10/90 !tw-text-start'
                        >
                          <td></td>
                          <td colSpan='3'>
                            <p className='tw-mb-1 tw-font-bold'>{work?.name}</p>
                            <p className='tw-mb-0'>{work?.details}</p>
                          </td>
                          <td>
                            {formatter.format(work?.amount)}
                            <div
                              className={cn(
                                'tw-mt-1',
                                isInPast && 'tw-text-systemRed-100',
                              )}
                            >
                              {t('Due date')}: {payment?.due_date}
                            </div>
                          </td>
                          <td>
                            {isClientAndCanPrepareTransactions && (
                              <>
                                <CheckItem
                                  className='tw-w-min'
                                  inputClassName='disabled:tw-opacity-80 disabled:tw-bg-surface-30'
                                  labelId={workPaymentId}
                                  checked={selectedItems.includes(
                                    work.payment_item_id,
                                  )}
                                  onChange={() => {
                                    onToggleItem(record, payment.works)
                                  }}
                                  disabled={!payment.is_processable}
                                />
                                {!payment.is_processable && (
                                  <UncontrolledTooltip target={workPaymentId}>
                                    {payment.resolution_message}
                                  </UncontrolledTooltip>
                                )}
                              </>
                            )}
                          </td>
                        </tr>
                      )
                    })
                  })
                },
                rowExpandable: (record) => record.payments.length > 0,
              }}
            />
          </>
        )}
      </Card>
    </div>
  )
}
