import { yupResolver } from '@hookform/resolvers/yup'
import { Plus } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'
import { t } from 'i18next'

import { ModalCloseButton } from '../../components/Common/modal-close-button'
import ControlledInput from '../../components/ControlledInput'
import ControlledSelect from '../../components/ControlledSelect'
import Button from '../../components/ui/button'
import { useFetch } from '../../helpers/hooks'
import { createExternalInvoiceClient } from '../../services/api-external-invoices'
import { getErrorMessage } from '../../utils/get-errors'
import { mapCountryToOption } from '../../utils/map-to-option'
import { EXTERNAL_CLIENT_TYPES } from './clients-list'

const newClientFormId = 'new-client-form'
export function AddNewClient({ btnProps, onCreateSuccess }) {
  const countries = useSelector((state) => state.Layout?.staticData?.countries)

  const [isModalOpen, setIsModalOpen] = useState(false)
  function toggle() {
    setIsModalOpen((isOpen) => {
      if (!isOpen) {
        reset()
      }

      return !isOpen
    })
  }

  const { startFetch: createExternalClient, isLoading: creatingClient } =
    useFetch({
      action: createExternalInvoiceClient,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(t('Failed to create client'))
        } else {
          toastr.success(t('Client created successfully'))
          onCreateSuccess?.(data)
          toggle()
        }
      },
      onError: (error) => {
        toastr.error(getErrorMessage(error))
      },
    })

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        type: yup
          .string()
          .oneOf([
            EXTERNAL_CLIENT_TYPES.INDIVIDUAL,
            EXTERNAL_CLIENT_TYPES.ENTITY,
          ])
          .required(t('Client type is required')),
        first_name: yup.string().required(t('First name is required')),
        last_name: yup.string().required(t('Last name is required')),
        email: yup.string().email(t('Invalid email address')),
        country_id: yup.number().required(t('Country is required')),
        city: yup.string().required(t('City is required')),
        address_line_1: yup.string().required(t('Address 1 is required')),
        address_line_2: yup.string(),
      }),
    ),
  })

  function onSubmit(values) {
    if (!values.email) {
      delete values.email
    }
    createExternalClient(values)
  }

  const { className: btnClassName, ...restBtnProps } = btnProps ?? {}

  return (
    <>
      <Button
        type='button'
        icon={<Plus size={20} />}
        color='link'
        {...restBtnProps}
        className={btnClassName}
        onClick={toggle}
        disabled={creatingClient}
      >
        {t('Add New Client')}
      </Button>

      <Modal isOpen={isModalOpen} toggle={toggle}>
        <ModalHeader close={<ModalCloseButton toggle={toggle} />}>
          {t('New client')}
        </ModalHeader>

        <ModalBody>
          <form
            onSubmit={stopPropagation(handleSubmit(onSubmit))}
            id={newClientFormId}
            className='tw-grid tw-grid-cols-2 tw-gap-4'
          >
            <ControlledSelect
              control={control}
              label={t('Client Type')}
              name='type'
              options={[
                {
                  label: t('Individual'),
                  value: EXTERNAL_CLIENT_TYPES.INDIVIDUAL,
                },
                { label: t('Business'), value: EXTERNAL_CLIENT_TYPES.ENTITY },
              ]}
              placeholder={t('Select client type')}
              wrapperClassName='tw-col-span-2'
            />

            <ControlledInput
              control={control}
              label={t('First Name')}
              name='first_name'
              placeholder={t('Enter first name')}
              wrapperClassName='tw-col-span-2'
            />

            <ControlledInput
              control={control}
              label={t('Last Name')}
              name='last_name'
              placeholder={t('Enter last name')}
              wrapperClassName='tw-col-span-2'
            />

            <ControlledInput
              control={control}
              label={t('Email (optional)')}
              name='email'
              placeholder={t('Enter email')}
              wrapperClassName='tw-col-span-2'
            />

            <ControlledSelect
              control={control}
              label={t('Country')}
              name='country_id'
              options={countries?.map((c) => mapCountryToOption(c))}
              placeholder={t('Select country')}
            />

            <ControlledInput
              control={control}
              label={t('City')}
              name='city'
              placeholder={t('Enter city')}
            />

            <ControlledInput
              control={control}
              label={t('Address 1')}
              name='address_line_1'
              placeholder={t('Enter address 1')}
              wrapperClassName='tw-col-span-2'
            />

            <ControlledInput
              control={control}
              label={t('Address 2 (optional)')}
              name='address_line_2'
              placeholder={t('Enter address 2')}
              wrapperClassName='tw-col-span-2'
            />
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={toggle}
            color='light'
            outline
            disabled={creatingClient}
          >
            {t('Cancel')}
          </Button>
          <Button
            formId={newClientFormId}
            type='submit'
            disabled={creatingClient}
            loading={creatingClient}
          >
            {t('Save')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

function stopPropagation(callback) {
  return function (event) {
    callback?.()

    if (event) {
      // sometimes not true, e.g. React Native
      if (typeof event.preventDefault === 'function') {
        event.preventDefault()
      }
      if (typeof event.stopPropagation === 'function') {
        // prevent any outer forms from receiving the event too
        event.stopPropagation()
      }
    }
  }
}
