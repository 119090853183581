import cx from 'classnames'
import { isPast } from 'date-fns'
import { t } from 'i18next'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { NavItem } from 'reactstrap'
import toastr from 'toastr'

import { cn } from 'ui'
import noTransactions from '../../assets/images/no-transactions.svg'
import Toggle from '../../components/Forms/Toggle/Toggle'
import Head from '../../components/head'
import NoContent from '../../components/NoContent'
import { PageNav } from '../../components/page-nav'
import { SimpleCard } from '../../components/simple-card'
import Button from '../../components/ui/button'
import ContractorDisplay from '../../components/ui/contractor-display'
import DataTable from '../../components/ui/data-table'
import Loader from '../../components/ui/loader'
import PageHeading from '../../components/ui/page-heading'
import { clientRole } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import { approvePayments, getPaymentsForReview } from '../../services/api'
import {
  getCurrencyFormatter,
  usdFormatter,
} from '../../utils/formatters/currency'
import ContractRef from '../AdminPanel/components/ContractRef'

function getPaymentsAsApprover(payments) {
  return payments?.filter((payment) => payment?.is_approver === true) ?? []
}

const getAedPayments = (payments) =>
  payments?.filter((payment) => payment.currency.code === 'AED')

const TABS = { AS_APPROVER: '1', ALL_PAYMENTS: '2', AED_PAYMENTS: '3' }

export default function ReviewPayments() {
  const [selectedPayments, setSelectedPayments] = useState({})

  const history = useHistory()

  const userProfile = useSelector((state) => state.userProfile?.userProfile)
  const canManagePayments = userProfile?.is_payroll_approver
  const isHr = userProfile?.role_name === clientRole.HR
  const isAdmin = userProfile?.role_name === clientRole.ADMIN
  function getFilters({ paymentsAsApprover, isHr, aedPayments }) {
    return [
      paymentsAsApprover?.length <= 0
        ? null
        : { label: t('Payments as Approver'), value: '1' },
      paymentsAsApprover?.length <= 0 && !isHr
        ? null
        : { label: t('All Payments'), value: '2' },
      aedPayments?.length <= 0
        ? null
        : { label: t('AED Payments'), value: '3' },
    ].filter(Boolean)
  }
  const { data: payments, isLoading } = useFetch({
    action: getPaymentsForReview,
    autoFetch: true,
    onComplete: (data) => {
      const filters = getFilters({
        paymentsAsApprover: getPaymentsAsApprover(data),
        isHr,
        aedPayments: getAedPayments(data),
      })

      setActiveTab(filters[0]?.value)
    },
  })

  const paymentsAsApprover = useMemo(
    () => getPaymentsAsApprover(payments),
    [payments],
  )

  const filters = getFilters({
    paymentsAsApprover,
    isHr,
    aedPayments: getAedPayments(payments),
  })

  const [activeTab, setActiveTab] = useState(filters[0]?.value)

  const { startFetch: _approvePayments, isLoading: approvingPayments } =
    useFetch({
      action: approvePayments,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.clear()
          toastr.error(t('Error approving payments'))
        } else {
          setSelectedPayments([])
          history.push('/review-payments-confirmed')
        }
      },
      onError: () => {
        toastr.clear()
        toastr.error(t('Error approving payments'))
      },
    })

  function handleApprovePayments() {
    const allPaymentIds = Object.values(selectedPayments).flat()

    _approvePayments({ payment_ids: allPaymentIds })
  }

  function handleTogglePayment({ contractRef, paymentIds }) {
    setSelectedPayments((prev) => {
      if (Object.keys(prev).includes(contractRef)) {
        const newSelected = { ...prev }
        delete newSelected[contractRef]
        return newSelected
      }

      return { ...prev, [contractRef]: paymentIds }
    })
  }

  const columns = useMemo(
    () =>
      [
        {
          Header: t('Contract Ref'),
          accessor: 'contract_ref',

          Cell: ({ cellData }) => (
            <ContractRef contractId={cellData} isAdmin={false} />
          ),
        },
        {
          Header: t('Name'),
          accessor: 'payments',

          Cell: ({ cellData }) => {
            return <ContractorDisplay contractor={cellData?.[0]?.contractor} />
          },
        },
        {
          Header: t('Contract Name'),
          accessor: 'contract_name',
        },
        {
          Header: t('Due date'),
          accessor: 'payments',

          Cell: ({ cellData }) => {
            const isPastDueDate = isPast(new Date(cellData?.[0]?.due_date))

            return (
              <span className={cx({ 'text-danger': isPastDueDate })}>
                {cellData?.[0]?.due_date}
              </span>
            )
          },
        },
        {
          Header: t('Total'),
          accessor: 'total_amount',

          Cell: ({ cellData, rowData }) => {
            const formatter = getCurrencyFormatter(rowData?.currency?.code)
            return formatter.format(cellData)
          },
        },
        !canManagePayments
          ? null
          : {
              Header: '',
              accessor: 'payments',
              Cell: ({ cellData, rowData }) => {
                const contractRef = rowData?.contract_ref
                const paymentIds = cellData.map((payment) => payment.id)

                if (rowData?.is_approver === false && !isAdmin) {
                  return null
                }

                return (
                  <Toggle
                    check={Object.keys(selectedPayments).includes(contractRef)}
                    change={() => {
                      handleTogglePayment({ contractRef, paymentIds })
                    }}
                    disabled={approvingPayments}
                  />
                )
              },
            },
      ].filter(Boolean),
    [approvingPayments, canManagePayments, isAdmin, selectedPayments],
  )

  const hideNavTabs =
    isLoading ||
    !payments ||
    (!isAdmin && paymentsAsApprover?.length === payments?.length)

  const getTableData = () => {
    switch (activeTab) {
      case TABS.AS_APPROVER:
        return paymentsAsApprover
      case TABS.ALL_PAYMENTS:
        return payments
      case TABS.AED_PAYMENTS:
        return getAedPayments(payments)
      default:
        return []
    }
  }

  return (
    <div className='page-content'>
      <Head title={t('Review payments')} />

      <PageHeading>
        <PageHeading.Title>{t('Review payments')}</PageHeading.Title>

        {!canManagePayments ? null : (
          <Button
            disabled={
              Object.keys(selectedPayments).length <= 0 || approvingPayments
            }
            loading={approvingPayments}
            onClick={handleApprovePayments}
          >
            {t('Approve')}
          </Button>
        )}
      </PageHeading>

      <PageNav
        className={cn(
          'rp-shadow-2 !tw-mb-4 tw-rounded tw-bg-white',
          hideNavTabs && '!tw-hidden',
        )}
      >
        {filters?.map((filter) => {
          return (
            <NavItem key={filter.value}>
              <PageNav.Link
                isActive={activeTab === filter.value}
                tag='button'
                onClick={() => setActiveTab(filter.value)}
              >
                {filter.label}
              </PageNav.Link>
            </NavItem>
          )
        })}
      </PageNav>

      <SimpleCard>
        {isLoading || !payments ? (
          <Loader minHeight='max(50vh, 485px)' />
        ) : payments.length <= 0 ? (
          <NoContent
            headline={t('Payments For Review')}
            subtitle={t('Payments for review will be shown here')}
            image={noTransactions}
          />
        ) : (
          <>
            <DataTable
              columns={columns}
              data={getTableData()}
              responsive
              className='tw-hidden md:tw-table'
              expandable={{
                expandedRowRender: (record) => {
                  const formatter = getCurrencyFormatter(record?.currency?.code)

                  return record?.payments?.map((payment) => {
                    return (
                      <tr key={payment?.id} className='tw-bg-surface-20'>
                        {/*
                        Do not remove height from the td element.
                        The height in the td element is required for the div to take the full height of the cell.
                        */}
                        <td className='!tw-p-0' style={{ height: 44 }}>
                          <div className='tw-h-full tw-border-s-4 tw-border-s-primary-100 tw-p-4' />
                        </td>
                        <td colSpan={3}>
                          {payment?.works?.length <= 0 ? null : (
                            <div className='tw-flex tw-flex-col tw-gap-3'>
                              {payment?.works?.map((work) => {
                                return (
                                  <div key={work?.work_id}>
                                    <div className='tw-mb-1 tw-text-sm tw-font-bold tw-text-text-100'>
                                      {work?.name}
                                    </div>
                                    <div>{work?.details}</div>
                                  </div>
                                )
                              })}
                            </div>
                          )}
                        </td>
                        <td
                          className={cx({
                            'tw-text-systemRed-100': isPast(
                              new Date(payment?.due_date),
                            ),
                          })}
                        >
                          {payment?.due_date}
                        </td>
                        <td colSpan={2}>{formatter.format(payment?.amount)}</td>
                      </tr>
                    )
                  })
                },
                rowExpandable: (record) => record.payments.length > 0,
              }}
            />

            <div className='tw-flex tw-flex-col tw-gap-4 tw-p-4 md:tw-hidden'>
              {payments.map((payment) => {
                return (
                  <PaymentCard
                    key={payment?.contract_ref}
                    payment={payment}
                    canManagePayments={canManagePayments}
                    selectedPayments={selectedPayments}
                    onTogglePayment={handleTogglePayment}
                    approvingPayments={approvingPayments}
                  />
                )
              })}
            </div>
          </>
        )}
      </SimpleCard>
    </div>
  )
}

function PaymentCard({
  payment,
  canManagePayments,
  selectedPayments,
  onTogglePayment,
  approvingPayments,
}) {
  const info = [
    { title: t('Contact name'), value: payment?.contract_name },
    { title: t('Total'), value: usdFormatter.format(payment.total_amount) },
  ]

  const contractRef = payment?.contract_ref
  const paymentIds = payment?.payments?.map((payment) => payment.id)

  return (
    <div className='tw-rounded tw-border tw-border-surface-30'>
      <div className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-surface-30 tw-p-4'>
        <ContractorDisplay contractor={payment?.payments?.[0]?.contractor} />

        {!canManagePayments ? null : (
          <Toggle
            check={Object.keys(selectedPayments).includes(contractRef)}
            change={() => onTogglePayment({ contractRef, paymentIds })}
            disabled={approvingPayments}
            marginRight=''
          />
        )}
      </div>
      <div className='tw-flex tw-flex-col tw-gap-3 tw-p-4'>
        {info.map((item, index) => {
          return (
            <div key={index} className='tw-flex tw-justify-between'>
              <div>{item.title}</div>
              <div>{item.value}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
