import React from 'react'

import { CustomCurrencyInput } from '../../../../../components/ControlledCurrencyInput'

export default function CustomProrataBody({
  currencyCode,
  onAmountChange,
  amount,
}) {
  return (
    <div className='tw-flex'>
      <CustomCurrencyInput
        name='amount'
        className='!tw-rounded-r-none !tw-border-r-0'
        placeholder='Amount'
        value={amount}
        onChange={onAmountChange}
      />
      <span className='tw-flex tw-items-center tw-rounded-r tw-border tw-border-l-0 tw-border-surface-30 tw-px-4'>
        {currencyCode}
      </span>
    </div>
  )
}
