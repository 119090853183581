import { t } from 'i18next'
import cx from 'classnames'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ImageModal } from '../../Contract/ContractPage/Expenses'
import { ExpenseActions } from '../../Contract/ContractPage/expenses-actions'
import { BaseAmount } from './base-amount'
import { ExpenseStatusBadge } from './expense-line'

export default function ExpenseCard({
  expense,
  contract,
  className,
  refreshData,
}) {
  const defaultContract =
    useSelector((state) => state?.Contract?.details) ?? contract

  const history = useHistory()
  const location = history.location

  const isAdmin = location.pathname?.startsWith('/admin/')

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: defaultContract?.currency?.code || 'USD',
  })

  const [showImg, setShowImg] = useState(false)
  const downloadExpense = (base64) => {
    // const url = window.URL.createObjectURL(new Blob())
    const link = document.createElement('a')
    link.href = base64
    link.setAttribute('download', 'expense.pdf') // or any other extension
    document.body.appendChild(link)
    link.click()
  }

  return (
    <div
      className={cx(
        'mb-3 bg-white border border-surface-30 rounded',
        className,
      )}
    >
      <div className='p-3 border-bottom d-flex justify-content-between align-items-center w-100'>
        <ExpenseStatusBadge item={expense} size='md' />

        <ExpenseActions
          isAdmin={isAdmin}
          item={expense}
          contract={contract}
          refreshData={refreshData}
        />
      </div>
      <div className='tw-flex tw-flex-col tw-gap-3 tw-p-4'>
        {[
          { label: t('Expense'), value: expense?.name },
          { label: t('Date'), value: expense?.date },
          {
            label: t('Amount'),
            value: (
              <>
                {formatter.format(expense?.amount)}
                <BaseAmount
                  baseAmount={expense?.base_amount}
                  baseCurrency={expense?.base_currency}
                />
              </>
            ),
          },
          { label: t('Category'), value: expense?.category?.name },
        ].map((info, index) => {
          return (
            <div
              className='tw-flex tw-items-center tw-justify-between tw-text-sm'
              key={index}
            >
              <span className='tw-text-text-70'>{info.label}</span>
              <span className=''>{info.value}</span>
            </div>
          )
        })}

        {!!expense?.photo && (
          <div className='tw-flex tw-items-center tw-justify-end'>
            {expense?.photo.includes('/pdf') ? (
              <a onClick={() => downloadExpense(expense?.photo)}>
                <i className='bx bxs-file-pdf text-primary font-size-24' />
              </a>
            ) : (
              <a onClick={() => downloadExpense(expense?.photo)}>
                <img
                  style={{ height: 40, width: 40 }}
                  src={expense?.photo}
                  alt=''
                />
              </a>
            )}
            <ImageModal
              show={showImg}
              hide={() => setShowImg(false)}
              photo={expense?.photo}
            />
          </div>
        )}
      </div>
    </div>
  )
}
