import React, { useEffect, useState } from 'react'
import { getRemovableStorageKey } from '../helpers/removable-storage'

export function DismissWrapper(props) {
  const { children, storageKey } = props
  const key = getRemovableStorageKey(storageKey)
  const [dismissed, setDismissed] = useState(
    JSON.parse(localStorage.getItem(key)),
  )

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(dismissed))
  }, [dismissed, key])

  const handleDismiss = () => setDismissed(true)

  if (dismissed) return null

  return <>{children({ handleDismiss })}</>
}
