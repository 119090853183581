import React, { createContext, useContext, useMemo } from 'react'
import { useFetch } from '../../../../../helpers/hooks'
import { emailRecipientUserType } from '../tools/constants'
import { ListBullets, UserCircleGear } from '@phosphor-icons/react'
import { getContractors, getUsers } from '../../../../../services/api'

export const EmailRecipientsContext = createContext({
  isLoading: true,
  items: [],
  quillRef: null,
  insertPlaceholder: null,
})

export function EmailRecipientsProvider(props) {
  const { children } = props
  const { isLoading: isLoadingClients, data: clients } = useFetch({
    autoFetch: true,
    action: getUsers,
    body: {
      status: 'active',
    },
  })
  const { isLoading: isLoadingContractors, data: contractors } = useFetch({
    autoFetch: true,
    body: {
      params: {
        contract_status: 'Ongoing',
      },
    },
    action: getContractors,
  })

  const isLoading = isLoadingClients || isLoadingContractors

  const recipients = useMemo(() => {
    if (clients?.list?.length || contractors?.length) {
      return [
        {
          label: 'User Type',
          options: emailRecipientUserType,
          icon: (props) => <UserCircleGear {...props} />,
        },
        {
          label: 'All users',
          icon: (props) => <ListBullets {...props} />,
          options: [...(clients?.list ?? []), ...(contractors ?? [])].map(
            (i) => ({
              value: i.email,
              email: i.email,
              description: i.email,
              label: `${i?.first_name} ${i?.last_name}`,
            }),
          ),
        },
      ]
    } else return []
  }, [contractors, clients])

  return (
    <EmailRecipientsContext.Provider value={{ isLoading, recipients }}>
      {children}
    </EmailRecipientsContext.Provider>
  )
}

export function useEmailRecipients() {
  return useContext(EmailRecipientsContext)
}
