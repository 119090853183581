import { yupResolver } from '@hookform/resolvers/yup'
import { CalendarPlus, Info, UserCircleGear } from '@phosphor-icons/react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import { t } from 'i18next'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import toastr from 'toastr'

import { cn } from 'ui'
import { boolean, date, number, object, string } from 'yup'
import ConfirmationModal from '../../../../components/Common/ConfirmationModal'
import { ModalCloseButton } from '../../../../components/Common/modal-close-button'
import ControlledDatePicker from '../../../../components/ControlledDatePicker'
import ControlledInput from '../../../../components/ControlledInput'
import Button from '../../../../components/ui/button'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../../../components/ui/side-menu'
import { useFetch } from '../../../../helpers/hooks'
import { getContractDetail } from '../../../../services/api'
import {
  assignContractsToPolicy,
  getUnassignedContracts,
  reassignPolicies,
  unassignContractsToPolicy,
} from '../../../../services/api-time-off-policies'
import { track } from '../../../../utils/analytics'
import { getFullName } from '../../../../utils/get-full-name'
import { ConfirmFormField } from '../../../Contract/CreateContract/components/confirm-field'
import { TimeOffPolicyTypes } from '../../../Contract/utils/constants'
import { TIMEOFF_EVENTS } from '../../../new-time-off-policy/events'
import ManagePolicyFooters from './manage-policy-footers'
import ManagePolicyBody from './manage-policy-body'
import ManagePolicyHeader from './manage-policy-header'
import capitalizeFirstLetter from '../../../../utils/capitalize-first-letter'

export function labelFromType(type, t) {
  if (!type) {
    return ''
  }

  if (type === 'bi_weekly') {
    return t('Bi-Weekly')
  }

  const label = String(type).trim().replaceAll('_', ' ').replaceAll('-', ' ')
  const capitalized = label.charAt(0).toUpperCase() + label.slice(1)

  return capitalized
}

function formatContract(contract, t) {
  return {
    id: contract?.id,
    name: getFullName(contract?.contractor),
    role: contract?.name,
    flag: contract?.contractor?.flag,
    photo: contract?.contractor?.photo,
    contractorType: labelFromType(contract?.contractor?.contractor_type, t),
    contractType: contract?.type,
    companyName: contract?.entity_name,
    isFormatted: true,
    ref: contract?.ref,
  }
}

function getAssignmentChanges({ policy, assignedWorkers }) {
  const defaultSelectedWorkersIds = (policy?.contracts ?? [])?.map((c) => c.id)
  const newAssignedWorkers = assignedWorkers.map((w) => w.id)

  const workersToAssign = []
  const workersToUnassign = []

  const all = new Set([...newAssignedWorkers, ...defaultSelectedWorkersIds])

  all.forEach((workerId) => {
    if (
      defaultSelectedWorkersIds.includes(workerId) &&
      !newAssignedWorkers.includes(workerId)
    ) {
      workersToUnassign.push(workerId)
    } else if (
      !defaultSelectedWorkersIds.includes(workerId) &&
      newAssignedWorkers.includes(workerId)
    ) {
      workersToAssign.push(workerId)
    }
  })

  return { workersToAssign, workersToUnassign }
}

export const workerTabs = {
  assignedWorkers: 'assigned-workers',
  unassignedWorkers: 'add-workers',
}

export const EARNING_DATE_TYPE = {
  CUSTOM: 'custom_date',
  EARNING_START_DAYS: 'earning_start_days',
}

export const WORKER_ACTION_TEXT = { REMOVE: 'Remove', ASSIGN: 'Assign' }

const schema = object().shape({
  earning_start_date: string()
    .label(t('Earning start date'))
    .oneOf([EARNING_DATE_TYPE.CUSTOM, EARNING_DATE_TYPE.EARNING_START_DAYS])
    .required(),
  [EARNING_DATE_TYPE.EARNING_START_DAYS]: boolean().notRequired(),
  [EARNING_DATE_TYPE.CUSTOM]: date().when('earning_start_date', {
    is: EARNING_DATE_TYPE.CUSTOM,
    then: (schema) => schema.required(t('Please select a custom date')),
    otherwise: (schema) => schema.notRequired(),
  }),
  [EARNING_DATE_TYPE.EARNING_START_DAYS]: number()
    .typeError(t('Please insert a number'))
    .when('earning_start_date', {
      is: EARNING_DATE_TYPE.EARNING_START_DAYS,
      then: (schema) => schema.required(t('Please insert amount of days')),
      otherwise: (schema) => schema.notRequired(),
    }),
})

function getTrackingData(policy) {
  return {
    type: policy.type,
    accrual_frequency: policy.accrual_frequency ?? null,
    carryover_used: policy.carryover_type.toLowerCase() === 'limited',
    source: 'Company settings',
    is_tenure_used:
      policy.type?.id !== TimeOffPolicyTypes.VACATION_POLICY_TYPE
        ? undefined
        : policy?.tenure_rules?.length > 0,
    is_limited:
      policy.type?.id === TimeOffPolicyTypes.VACATION_POLICY_TYPE
        ? undefined
        : policy?.max_annual_days !== null,
  }
}

export function ManagePolicyWorkers({ policy, onSuccess, companyPolicies }) {
  const [assignedWorkers, setAssignedWorkers] = useState(
    policy?.contracts?.map((contract) => formatContract(contract, t)) ?? [],
  )
  const [showEarningDateModal, setShowEarningDateModal] = useState(false)
  const [selectedContract, setSelectedContract] = useState()
  const [activeTab, setActiveTab] = useState(workerTabs.assignedWorkers)
  const [isCloseConfirmOpen, setIsCloseConfirmOpen] = useState()
  const [isOpen, setIsOpen] = useState()
  const [selectedWorkers, setSelectedWorkers] = useState(null)
  const [isReAssigning, setIsReAssigning] = useState({})
  const [earningDate, setEarningDate] = useState()

  function toggleCloseConfirmModal() {
    setIsCloseConfirmOpen((open) => !open)
  }

  function toggleMenu() {
    setIsOpen((open) => {
      if (open) {
        setAssignedWorkers(
          policy?.contracts?.map((contract) => formatContract(contract, t)) ??
            [],
        )
        setActiveTab(workerTabs.assignedWorkers)
      }
      return !open
    })
  }

  function handleClose() {
    if (unsavedChanges) {
      toggleCloseConfirmModal()
    } else {
      toggleMenu?.()
      setSelectedContract(undefined)
    }
  }

  // Calls the api
  const [unAssignedWorkersSearch, setUnAssignedWorkersSearch] = useState('')
  // Search in place
  const [assignedWorkersSearch, setAssignedWorkersSearch] = useState('')

  const userToken = useSelector((state) => state.Account?.user?.token)

  const { data: contractDetails, isLoading: fetchingContractDetails } =
    useFetch(
      {
        action: getContractDetail,
        autoFetch: selectedContract,
        body: { id: selectedContract },
      },
      [selectedContract],
    )

  const {
    fetchNextPage,
    isFetchingNextPage,
    data: unassignedContractsData,
  } = useInfiniteQuery({
    queryKey: [
      userToken,
      'unassigned_contracts',
      policy?.id,
      unAssignedWorkersSearch,
    ],
    queryFn: ({ pageParam, signal, queryKey }) => {
      // allow users to search workers with or without #
      const searchFromQueryKey =
        queryKey[3].charAt(0) === '#'
          ? queryKey[3].substring(1).toLowerCase()
          : queryKey[3].toLowerCase()

      const policyId = queryKey[2]

      return getUnassignedContracts(
        userToken,
        { policyId, page: pageParam, search_key: searchFromQueryKey },
        { signal },
      )
    },
    select: (data) => {
      return {
        pages: data?.pages.map((page) => page.data),
        pageParams: data?.pageParams,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const currentPage = lastPage.data.paginator.current_page
      const hasMorePages = lastPage.data.paginator.has_more_pages

      const nextPage = hasMorePages ? currentPage + 1 : undefined
      return nextPage
    },
    enabled: !!isOpen,
  })

  const unassignedFlatItems = unassignedContractsData?.pages
    ?.map((item) => item.data)
    ?.flat()

  const totalUnassignedWorkers =
    unassignedContractsData?.pages?.[0]?.paginator?.total

  const unassignedWorkers = (unassignedFlatItems ?? [])
    ?.map((contract) => {
      if (contract?.isFormatted) {
        return contract
      } else {
        return formatContract(contract, t)
      }
    })
    .filter((contract) => {
      return assignedWorkers.length <= 0
        ? true
        : !assignedWorkers.map((w) => w.id).includes(contract.id)
    })

  const filteredWorkers = useCallback(
    function (value) {
      // Unassigned worker are filtered from the BE
      if (value === workerTabs.unassignedWorkers) {
        return unassignedWorkers
      }

      // Assigned workers are filtered here
      return assignedWorkers.filter(({ name, ref }) => {
        if (!assignedWorkersSearch) {
          return true
        } else if (assignedWorkersSearch.length > 0) {
          if (name?.length <= 0) {
            return false
          } else {
            // allowing the user to search with or without '#'
            const filteredTagSearch =
              assignedWorkersSearch.charAt(0) === '#'
                ? assignedWorkersSearch.substring(1).toLowerCase()
                : assignedWorkersSearch.toLowerCase()
            return (
              name?.toLowerCase().includes(filteredTagSearch) ||
              ref?.toLowerCase().includes(filteredTagSearch)
            )
          }
        }
        return true
      })
    },
    [assignedWorkers, assignedWorkersSearch, unassignedWorkers],
  )

  function assignWorker(worker) {
    setAssignedWorkers((workers) => [...workers, worker])
  }
  function removeWorker(worker) {
    setAssignedWorkers((workers) =>
      workers.filter(({ id }) => id !== worker.id),
    )
  }

  const tabs = [
    {
      label: t('Assigned workers'),
      count: assignedWorkers.length,
      value: workerTabs.assignedWorkers,
    },
    {
      label: t('Add workers'),
      value: workerTabs.unassignedWorkers,
    },
  ]

  const { startFetch: assignContracts, isLoading: assigningContracts } =
    useFetch({
      action: assignContractsToPolicy,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(data?.error || 'Failed to assign contracts to policy')
        } else {
          toastr.success(
            'Workers successfully updated',
            'Time off policy saved',
          )
          onSuccess?.()
          track(TIMEOFF_EVENTS.ASSIGNED_WORKER, {
            ...getTrackingData(policy),
            custom_accrual_date:
              watch('earning_start_date') === EARNING_DATE_TYPE.CUSTOM,
          })
        }
      },
      onError: (error) => toastr.error(error),
    })

  const { startFetch: unassignContracts, isLoading: unassigningContracts } =
    useFetch({
      action: unassignContractsToPolicy,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error('Failed to unassign contracts to policy')
        } else {
          toastr.success(
            'Workers successfully updated',
            'Time off policy saved',
          )
          onSuccess?.()
          track(TIMEOFF_EVENTS.UNASSIGNED_WORKER, getTrackingData(policy))
        }
      },
      onError: (error) => {
        toastr.error(error)
      },
    })

  const loading = assigningContracts || unassigningContracts

  const { workersToAssign, workersToUnassign } = getAssignmentChanges({
    policy,
    assignedWorkers,
  })

  const unsavedChanges =
    workersToAssign.length > 0 || workersToUnassign.length > 0
  const workers = filteredWorkers(activeTab)

  function handleSave() {
    if (!unsavedChanges) {
      toggleMenu()
      return
    }

    if (workersToAssign.length > 0) {
      if (policy.type.is_accrued) {
        setShowEarningDateModal(true)
        return
      }

      assignContracts({
        policyId: policy?.id,
        contracts: workersToAssign,
      })
    }
    if (workersToUnassign.length > 0) {
      unassignContracts({
        policyId: policy?.id,
        contracts: workersToUnassign,
      })
    }
  }

  const { control, watch, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      earning_start_date: EARNING_DATE_TYPE.EARNING_START_DAYS,
      earning_start_days: policy.earning_start_days,
    },
  })

  const onSubmit = (data) => {
    let startDate
    let days

    if (data.earning_start_date !== EARNING_DATE_TYPE.EARNING_START_DAYS) {
      startDate = format(new Date(data[EARNING_DATE_TYPE.CUSTOM]), 'yyyy-MM-dd')
    } else {
      days = data[EARNING_DATE_TYPE.EARNING_START_DAYS]
    }

    assignContracts({
      policyId: policy?.id,
      contracts: workersToAssign,
      start_date: startDate,
      [EARNING_DATE_TYPE.EARNING_START_DAYS]: days,
    })
  }

  const { startFetch: _reassignPolicies, isLoading: reassigningWorkers } =
    useFetch({
      action: reassignPolicies,
      onError: toastr.error,
      onComplete: () => {
        toastr.success(
          `${capitalizeFirstLetter(
            t('worker', {
              count: selectedWorkers.length,
            }),
          )} ${t('moved to')} "${companyPolicies.find((policy) => policy.id === isReAssigning.policy).name}"`,
          `${selectedWorkers.length} ${t('worker', { count: selectedWorkers.length })} ${t('reassigned')}`,
        )
        onSuccess({ archived: 0 })
      },
    })

  return (
    <>
      <Button
        type='button'
        onClick={toggleMenu}
        className='tw-mt-4'
        block
        icon={<UserCircleGear size={20} className='tw-flex-shrink-0' />}
        outline
      >
        {t('Manage workers')}
      </Button>

      <SideMenu
        itemListClassName='tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain tw-grid-rows-[minmax(auto,max-content)_1fr_91px]'
        isOpen={isOpen}
        onClose={handleClose}
      >
        <SideMenuHeader className='tw-block tw-p-0'>
          <ManagePolicyHeader
            selectedContract={selectedContract}
            isReAssigning={isReAssigning.value}
            handleClose={handleClose}
            contractDetails={contractDetails}
            setSelectedContract={setSelectedContract}
            setIsReAssigning={setIsReAssigning}
            policy={policy}
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            setUnAssignedWorkersSearch={setUnAssignedWorkersSearch}
            totalUnassignedWorkers={totalUnassignedWorkers}
            selectedWorkers={selectedWorkers}
          />
        </SideMenuHeader>
        <SideMenuBody className='tw-flex-grow !tw-p-0'>
          <ManagePolicyBody
            contractDetails={contractDetails}
            selectedContract={selectedContract}
            activeTab={activeTab}
            assignWorker={assignWorker}
            assignedWorkersSearch={assignedWorkersSearch}
            companyPolicies={companyPolicies}
            fetchNextPage={fetchNextPage}
            fetchingContractDetails={fetchingContractDetails}
            isFetchingNextPage={isFetchingNextPage}
            policy={policy}
            removeWorker={removeWorker}
            selectedWorkers={selectedWorkers}
            setAssignedWorkersSearch={setAssignedWorkersSearch}
            setSelectedContract={setSelectedContract}
            setSelectedWorkers={setSelectedWorkers}
            setUnAssignedWorkersSearch={setUnAssignedWorkersSearch}
            tabs={tabs}
            unAssignedWorkersSearch={unAssignedWorkersSearch}
            workers={workers}
            isReAssigning={isReAssigning.value}
            setIsReAssigning={setIsReAssigning}
            earningDate={earningDate}
            setEarningDate={setEarningDate}
          />
        </SideMenuBody>
        <SideMenuFooter
          className={cn(
            'tw-items-center',
            { 'tw-justify-between': !!selectedContract },
            { 'tw-flex-wrap tw-gap-0': !!selectedWorkers },
          )}
        >
          <ManagePolicyFooters
            selectedContract={selectedContract}
            unsavedChanges={unsavedChanges}
            handleClose={handleClose}
            handleSave={handleSave}
            isCloseConfirmOpen={isCloseConfirmOpen}
            loading={loading}
            toggleCloseConfirmModal={toggleCloseConfirmModal}
            toggleMenu={toggleMenu}
            onSelectAll={() =>
              setSelectedWorkers(
                selectedWorkers?.length !== workers.length ? [...workers] : [],
              )
            }
            selectedWorkers={selectedWorkers}
            workers={workers}
            onReAssignClick={() => setIsReAssigning({ value: true })}
            isReAssigning={isReAssigning.value}
            activeTab={activeTab}
            earningDate={earningDate}
            disableReAssignConfirm={
              earningDate === null ||
              !isReAssigning.policy ||
              reassigningWorkers
            }
            onReAssignCancelClick={() => setIsReAssigning({})}
            onReAssignConfirm={() =>
              _reassignPolicies({
                from_timeoff_policy_id: policy.id,
                to_timeoff_policy_id: isReAssigning.policy,
                contract_ids: selectedWorkers.map((worker) => worker.id),
                earning_start_date: earningDate
                  ? format(earningDate, 'yyyy-MM-dd')
                  : earningDate,
              })
            }
            reassigningWorkers={reassigningWorkers}
          />
        </SideMenuFooter>
      </SideMenu>

      <ConfirmationModal
        isOpen={showEarningDateModal}
        toggle={() => setShowEarningDateModal(false)}
        caption={t('Save')}
        onConfirm={handleSubmit(onSubmit)}
        confirmLoading={assigningContracts}
        content={
          <EarningDate
            watch={watch}
            control={control}
            setValue={setValue}
            onCloseClick={() => setShowEarningDateModal(false)}
            policyDefaultEarningDays={policy.earning_start_days}
          />
        }
      />
    </>
  )
}

function EarningDate({
  watch,
  control,
  setValue,
  onCloseClick,
  policyDefaultEarningDays = 0,
}) {
  return (
    <>
      <div className='tw-mb-2 tw-flex tw-items-center tw-justify-between'>
        <CalendarPlus size={24} className='tw-fill-black' />
        <ModalCloseButton
          toggle={onCloseClick}
          className='!tw-text-secondary-100'
          size={24}
        />
      </div>
      <div className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
        {t('Earning start date')}
      </div>
      <div className='tw-text-sm tw-font-medium tw-text-text-80'>
        {t('When does the worker start earning time off balance?')}
      </div>
      <hr className='-tw-mx-6 tw-my-6' />

      <ConfirmFormField
        control={control}
        name='earning_start_date'
        fieldOptions={[
          {
            label: t('Days after contract start'),
            value: EARNING_DATE_TYPE.EARNING_START_DAYS,
          },
          { label: t('Custom date'), value: EARNING_DATE_TYPE.CUSTOM },
        ]}
        innerClassName='tw-rounded-b-none'
        transform={{
          output: (event) => {
            setValue('custom_earning_date', null)
            setValue('earning_start_days', policyDefaultEarningDays)
            return event
          },
        }}
      />
      <div className='tw-rounded-b tw-border tw-border-t-0 tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
        {watch('earning_start_date') === EARNING_DATE_TYPE.CUSTOM && (
          <ControlledDatePicker
            control={control}
            name={EARNING_DATE_TYPE.CUSTOM}
            id='custom_earning_date'
            placeholder={t('Earning date')}
            label={t('Earning date')}
          />
        )}
        {watch('earning_start_date') ===
          EARNING_DATE_TYPE.EARNING_START_DAYS && (
          <>
            <ControlledInput
              control={control}
              name={EARNING_DATE_TYPE.EARNING_START_DAYS}
              postFix={t('Calendar Days')}
              type='number'
              placeholder={t('Insert amount')}
            />
            <div className='tw-mt-2 tw-flex tw-items-center tw-gap-1 tw-text-xs tw-text-text-80'>
              <Info size={16} />
              <span>
                {t('earningDatePolicyText', {
                  count: policyDefaultEarningDays,
                })}
              </span>
            </div>
          </>
        )}
      </div>
    </>
  )
}
