import React from 'react'

const UtilContext = React.createContext()

export function UtilProvider({ children, value }) {
  return <UtilContext.Provider value={value}>{children}</UtilContext.Provider>
}

export function useUtil() {
  return React.useContext(UtilContext)
}
