import React from 'react'
import { useSlackConnection } from './contexts/slack-connection-context'
import { useFetch } from '../../../../helpers/hooks'
import { deleteSlackWorkspaces } from '../../../../services/api-automations'
import { Spinner } from 'reactstrap'

export function SlackRemoveConnection({ id }) {
  const { fetchSlackWorkspaces } = useSlackConnection()
  const { startFetch: removeConnection, isLoading: isRemoving } = useFetch({
    autoFetch: false,
    isOpenApi: true,
    action: deleteSlackWorkspaces,
    body: { id },
    onComplete: () => {
      fetchSlackWorkspaces()
    },
    onError: () => {},
  })
  return (
    <div className='tw-px-2'>
      {isRemoving ? (
        <Spinner size='sm' color='danger' />
      ) : (
        <button className='tw-text-red' onClick={() => removeConnection()}>
          Remove
        </button>
      )}
    </div>
  )
}
