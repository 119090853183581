import { t } from 'i18next'
import cx from 'classnames'
import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { ModalCloseButton } from '../../../../components/Common/modal-close-button'
import Button from '../../../../components/ui/button'
import { useResize } from '../../../../helpers/hooks'

const FormsModal = ({
  isOpen,
  toggle,
  onConfirm = () => {},
  children,
  compact,
  title,
  isLoading,
  isActionsDisabled,
  unmountOnClose = true,
  scrollable = false,
  className,
}) => {
  const isMobile = useResize()

  return (
    <Modal
      isOpen={isOpen}
      autoFocus
      scrollable={scrollable}
      toggle={toggle}
      unmountOnClose={unmountOnClose}
      size={compact ? 'md' : 'lg'}
      className={className}
    >
      <ModalHeader close={<ModalCloseButton toggle={toggle} />}>
        {title}
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter className={cx({ 'fixed-bottom': isMobile })}>
        <Button
          color='light'
          disabled={isActionsDisabled}
          outline
          onClick={toggle}
        >
          {t('Cancel')}
        </Button>
        <Button
          loading={isLoading}
          disabled={isActionsDisabled}
          onClick={onConfirm}
        >
          {t('Save Changes')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default FormsModal
