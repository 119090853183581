import cx from 'classnames'
import React from 'react'

export default function RadioGroup({
  options,
  defaultValue,
  onChange,
  name,
  className,
  isDisabled,
  value,
}) {
  return (
    <div className={cx('d-flex flex-column gap-8', className)}>
      {React.Children.toArray(
        options.map((option, i) => (
          <RadioItem
            key={i}
            option={option}
            name={name}
            isDefault={option?.value === defaultValue?.value}
            onChange={(event) => {
              if (event.target.checked) {
                onChange?.(option)
              }
            }}
            isDisabled={isDisabled || option?.isDisabled}
            checked={option?.value === value}
          />
        )),
      )}
    </div>
  )
}

function RadioItem({ option, isDefault, onChange, name, isDisabled, checked }) {
  const id = option?.id || option?.value

  return (
    <div
      className={cx(
        'form-check rounded !tw-ps-4 !tw-text-start',
        isDisabled ? 'opacity-80' : 'hover:bg-light',
      )}
    >
      <input
        className='form-check-input cursor-pointer ltr:!tw-left-0 rtl:!tw-right-0'
        onChange={onChange}
        type='radio'
        name={name}
        id={id}
        value={option?.value}
        defaultChecked={isDefault}
        disabled={isDisabled}
        checked={checked}
      />
      <label
        className='form-check-label py-1 w-100'
        htmlFor={isDisabled ? null : id}
      >
        <span>{option.label}</span>
        {!option?.description ? null : (
          <p className='text-muted mb-0'>{option?.description}</p>
        )}
      </label>
    </div>
  )
}
