import { t } from 'i18next'
import { FolderOpen, Gear, ListChecks, Warning } from '@phosphor-icons/react'
import { format, isValid } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { components } from 'react-select'
import {
  Badge,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  Table,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'
import { ActionsDropdown, Avatar, cn } from 'ui'

import { CONTRACT_DETAILS_TABS } from '.'
import archiveIcon from '../../../assets/images/archiveBlack.svg'
import selectedIcon from '../../../assets/images/selectedBlue.svg'
import unArchiveIcon from '../../../assets/images/unarchive.svg'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import NoContent from '../../../components/NoContent'
import SearchBar from '../../../components/SearchBar'
import { StyledH6, StyledP } from '../../../components/Typo'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import Button from '../../../components/ui/button'
import Flag, { getFlagUrlFromIso2 } from '../../../components/ui/flag'
import Pagination from '../../../components/ui/pagination'
import Shimmer from '../../../components/ui/shimmer'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { CONTRACT_STATUS, userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  archiveAdminContract,
  archiveContract,
  deleteContractTag,
  getContractDetail,
  getTagsList,
  getAttributes,
} from '../../../services/api'
import { setContractToDuplicate } from '../../../store/contract/actions'
import ContractRef from '../../AdminPanel/components/ContractRef'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import {
  BULK_ADJUSTMENT_EVENTS,
  useBulkContractActionEvents,
} from '../bulk-creation/events'
import { TagsV2 } from '../components/tags-v2'
import TabEmpty from '../components/tab/tab-empty'
import { CONTRACT_TYPES_SELECT_OPTIONS } from '../utils/constants'
import welcomeImage from './../../../assets/images/welcome.svg'
import { getContractStatusColor } from './ContractList'
import FiltersDropdown from './FiltersDropdown'
import ContractStatus from './contract-status'
import { InvitePendingContracts } from './invite-pending-contracts'
import { WorkerName } from './worker-name'
import { mapCountryToOption } from '../../../utils/map-to-option'
import { CheckItem } from '../../../components/ui/check-item'
import i18n from '../../../i18n'

const persistedCustomColumns =
  JSON.parse(localStorage.getItem('selectedContractsTableColumns')) || {}

export default function ClientContractList({
  onQuery,
  onContractTypeChange,
  activeTab,
  Orders: orders,
  onUpdate,
  ...props
}) {
  const { paginator, page, setPage, showTags, setSelectedCustomFields } = props
  const user = useSelector((state) => state?.Account?.user)
  const userProfile = useSelector((state) => state.userProfile?.userProfile)
  const { hasAccess } = usePermissions()
  const [query, setQuery] = useState(null)
  const [dataToDelete, setDataToDelete] = useState(null)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const { trackEvent } = useBulkContractActionEvents()

  const isListEmpty = orders?.length === 0 && !props?.loading && query !== ''

  const { data: customFields, isLoading: isCustomFieldsLoading } = useFetch({
    action: getAttributes,
    initResult: [],
    autoFetch: true,
    body: {
      area: 'contract',
    },
  })

  const [columns, setColumns] = useState([
    { title: t('Name'), name: 'name' },
    { title: t('Created on'), name: 'created_on', disabled: !props.isAdmin },
    { title: t('Country'), name: 'country', disabled: !showTags },
    { title: t('Type'), name: 'type' },
    { title: t('Job Title'), name: 'job_title' },
    { title: t('Tags'), name: 'tags', disabled: !showTags },
    { title: t('Compensation'), name: 'compensation' },
    { title: t('Client'), name: 'client', disabled: !props.isAdmin },
    { title: t('Company'), name: 'company', disabled: !props.isAdmin },
    { title: t('Status'), name: 'status' },
    { title: t('Creator'), name: 'creator', defaultSelected: false },
  ])

  const defaultColumns = columns.filter((c) => c.disabled !== true)
  const defaultSelectedColumns = defaultColumns.filter(
    (c) => c.defaultSelected !== false,
  )

  const [visibleDefaultColumns, setVisibleDefaultColumns] = useState(
    persistedCustomColumns?.[user?.company_id]?.defaultColumns ||
      defaultSelectedColumns,
  )

  const [selectedDefaultColumns, setSelectedDefaultColumns] = useState(
    persistedCustomColumns?.[user?.company_id]?.defaultColumns ||
      defaultSelectedColumns,
  )

  const [visibleCustomColumns, setVisibleCustomColumns] = useState(
    persistedCustomColumns?.[user?.company_id]?.customColumns || [],
  )
  const [selectedCustomColumns, setSelectedCustomColumns] = useState(
    persistedCustomColumns?.[user?.company_id]?.customColumns || [],
  )
  const [searchCols, setSearchCols] = useState('')

  const { contract_statuses: status, countries } = useSelector(
    (state) => state?.Layout?.staticData ?? {},
  )
  const [customFieldsDropdownOpen, setCustomFieldsDropdownOpen] =
    useState(false)

  const toggleCustomFieldsDropdown = () => {
    // if closed on click outside reset to the last selected state
    if (customFieldsDropdownOpen) {
      setVisibleDefaultColumns(visibleDefaultColumns)
      setSelectedDefaultColumns(visibleDefaultColumns)
      setVisibleCustomColumns(visibleCustomColumns)
      setSelectedCustomColumns(visibleCustomColumns)
    }
    setCustomFieldsDropdownOpen((prev) => !prev)
  }

  const restoreTableDefaults = () => {
    setColumns(defaultColumns)
    setVisibleDefaultColumns(defaultSelectedColumns)
    setSelectedDefaultColumns(defaultSelectedColumns)
    setVisibleCustomColumns([])
    setSelectedCustomColumns([])
    setSelectedCustomFields([])
    persistCustomColumns(defaultColumns, [])

    setCustomFieldsDropdownOpen(false)
  }

  const isColumnVisible = (colName, type = 'default') => {
    return Boolean(
      type === 'default'
        ? visibleDefaultColumns.find((obj) => obj.name === colName)
        : visibleCustomColumns.find((obj) => obj.name === colName),
    )
  }

  const persistCustomColumns = (
    selectedDefaultColumns,
    selectedCustomColumns,
  ) => {
    const cID = user?.company_id

    persistedCustomColumns[cID] = {
      defaultColumns: selectedDefaultColumns,
      customColumns: selectedCustomColumns,
      userId: user?.id,
    }

    localStorage.setItem(
      'selectedContractsTableColumns',
      JSON.stringify(persistedCustomColumns),
    )
  }

  const handleColumnsSearch = (query) => {
    setSearchCols(query)
  }

  const columnSearch = (column) => {
    return (
      !searchCols ||
      column?.name?.toLowerCase?.().includes(searchCols?.toLowerCase())
    )
  }

  const history = useHistory()

  useEffect(() => {
    if (!hasAccess(permissions.ViewContracts)) {
      history.push('/activity')
    }
  }, [hasAccess, history])

  const kycNotVerified =
    userProfile?.kyc_verified !== 1 &&
    userProfile?.type === userTypes.COMPANY &&
    userProfile?.is_company_creator

  const tags = useFetch({ action: getTagsList, autoFetch: true })
  const allTags = tags?.data

  function handleSearch(query) {
    setQuery(query)
    onQuery(query)
  }

  function deleteTagTotally(data) {
    setDataToDelete(data)
    setConfirmDelete(true)
  }

  function getContractDetailUrl(contract) {
    if (
      !props.isAdmin &&
      (hasAccess(permissions.ViewContractsDetails) ||
        user?.type === userTypes.CONTRACTOR)
    ) {
      return `/contract/detail?id=${contract.ref}`
    } else if (props.isAdmin) {
      return `/admin/contract/detail?id=${contract.ref}`
    } else {
      return '#'
    }
  }

  const showFiltersBlock = user?.type !== userTypes.CONTRACTOR || props.isAdmin

  const activeContracts = orders?.filter((item) => item.status?.id === 4)
  const hasActiveContracts = activeContracts?.length > 0
  const canBulkEdit =
    hasActiveContracts && hasAccess(permissions.addAdjustments)

  function handleBulkAction() {
    history.push('/contract/bulk-operations')
    trackEvent(BULK_ADJUSTMENT_EVENTS.CLICKED_BULK_UPDATE)
  }

  const filtersBlock = !showFiltersBlock ? null : (
    <Container fluid className='tw-p-4 md:tw-px-6'>
      <div className='tw-flex tw-flex-col tw-flex-wrap tw-gap-2 sm:tw-flex-row'>
        <SearchBar
          className='tw-flex-grow'
          query={query}
          placeholder={
            props.isAdmin ? 'Search by name, contract ID' : undefined
          }
          onQueryChanged={handleSearch}
          isClearable
          inputClassName='!tw-h-auto'
        />

        {props?.withCustomFilter && user?.type === userTypes.COMPANY && (
          <>
            {props?.loading || props.filtering ? (
              <Shimmer width={160} height='44px' />
            ) : (
              <FiltersDropdown
                style={{ minWidth: 160 }}
                className='rounded'
                tagsFilters={allTags}
                statusFilters={status?.map((e) => ({
                  label: t(e.name),
                  value: e.id,
                }))}
                isOpen={showFilters}
                toggle={() => setShowFilters((t) => !t)}
                filterByStatus={props.filterByStatus}
                filterByTag={props.filterByTag}
                clearFilters={() => {
                  setShowFilters(false)
                  onUpdate([], [], 'clearF')
                }}
                tagQueries={props.tagQueries}
                statusQueries={props.statusQueries}
              />
            )}
          </>
        )}

        <CustomSelect
          options={CONTRACT_TYPES_SELECT_OPTIONS.map((type) => ({
            ...type,
            label: t(type.label),
          }))}
          wrapperClassName='tw-min-w-44'
          placeholder={t('Contract type')}
          onChange={(val) => onContractTypeChange?.(val?.value)}
          isClearable
        />

        <CustomSelect
          wrapperClassName='tw-min-w-44'
          isClearable
          clearValue={[]}
          isMulti
          placeholder={t('Country')}
          options={countries?.map((c) => mapCountryToOption(c))}
          onChange={(t) => {
            props.filterByCountry?.(t.map((country) => country.id))
          }}
        />

        {activeTab === CONTRACT_DETAILS_TABS({ t }).pendingInvites.value ? (
          <InvitePendingContracts onUpdate={onUpdate} />
        ) : (
          <PermissionTooltip
            showing={!canBulkEdit}
            id='bulk-edit-btn'
            area={PERMISSION_GROUP.ADJUST_PAYMENTS.name}
            action=''
            customMessage={
              hasActiveContracts
                ? undefined
                : t('You do not have any active contracts to bulk edit.')
            }
          >
            <Button
              onClick={handleBulkAction}
              outline
              disabled={!canBulkEdit}
              icon={<ListChecks size={16} />}
            >
              {t('Bulk Update')}
            </Button>
          </PermissionTooltip>
        )}
        <div className='tw-relative tw-hidden tw-flex-wrap md:tw-flex'>
          <Dropdown
            isOpen={customFieldsDropdownOpen}
            will
            toggle={toggleCustomFieldsDropdown}
            className='tw-relative tw-flex'
          >
            <DropdownToggle tag='div'>
              <>
                <Button
                  color='light'
                  lightOutline
                  outline
                  onlyIcon
                  icon={<Gear size={20} />}
                  className='!tw-bg-white !tw-p-2.5'
                  id='contractListColumnsSet'
                />

                <UncontrolledTooltip target='contractListColumnsSet'>
                  {t('Configure columns')}
                </UncontrolledTooltip>
              </>
            </DropdownToggle>
            <DropdownMenu
              right={i18n.dir() !== 'rtl'}
              className='!tw-min-w-[250px] !tw-transform-none'
              container='body'
            >
              <DropdownItem toggle={false}>
                <SearchBar
                  className='tw-basis-full'
                  query={searchCols}
                  placeholder={t('Search field names')}
                  onQueryChanged={handleColumnsSearch}
                  isClearable
                  inputClassName='!tw-h-auto'
                />
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem header>{t('Default fields')}</DropdownItem>
              {defaultColumns?.filter(columnSearch).map((column, i) => (
                <DropdownItem key={i} toggle={false}>
                  <CheckItem
                    label={column.title}
                    labelId={column.title}
                    value={column.name}
                    checked={Boolean(
                      selectedDefaultColumns.find(
                        (obj) => obj.name === column.name,
                      ),
                    )}
                    onChange={(event) => {
                      const { value, checked } = event.target
                      const c = defaultColumns.find((obj) => obj.name === value)
                      setSelectedDefaultColumns((p) =>
                        checked
                          ? [...p, c]
                          : p.filter((obj) => obj.name !== value),
                      )
                    }}
                  />
                </DropdownItem>
              ))}
              <DropdownItem divider />
              <DropdownItem header>{t('Custom fields')}</DropdownItem>
              {!isCustomFieldsLoading ? (
                customFields?.filter(columnSearch).map((column, i) => (
                  <DropdownItem key={i} toggle={false}>
                    <CheckItem
                      label={column?.name}
                      labelId={column?.name}
                      checked={selectedCustomColumns.includes(column?.name)}
                      onChange={(event) => {
                        const { name, checked } = event.target
                        const _name = name?.replace(/-/gi, ' ')
                        setSelectedCustomColumns((p) =>
                          checked
                            ? [...p, _name]
                            : p.filter((i) => i !== _name),
                        )
                      }}
                    />
                  </DropdownItem>
                ))
              ) : (
                <div className='d-flex justify-content-center align-items-center flex-grow-1 py-3'>
                  <Spinner size='md' />
                </div>
              )}
              <DropdownItem divider />
              <DropdownItem toggle={false}>
                <div className='tw-flex tw-justify-between'>
                  <Button
                    className='!tw-px-0'
                    color='link'
                    type='button'
                    onClick={() => restoreTableDefaults()}
                    textClassName='tw-text-black'
                  >
                    {t('Restore defaults')}
                  </Button>

                  <Button
                    className='!tw-px-0'
                    color='link'
                    type='button'
                    onClick={() => {
                      setVisibleDefaultColumns(selectedDefaultColumns)
                      setVisibleCustomColumns(selectedCustomColumns)
                      setSelectedCustomFields([...selectedCustomColumns])

                      persistCustomColumns(
                        selectedDefaultColumns,
                        selectedCustomColumns,
                      )

                      setCustomFieldsDropdownOpen(false)
                    }}
                    disabled={
                      selectedCustomColumns.length === 0 &&
                      selectedDefaultColumns.length === 0
                    }
                  >
                    {t('Apply')}
                  </Button>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </Container>
  )

  return (orders && orders?.length > 0) ||
    props.isAdmin ||
    props.filtering ||
    props.loading ||
    props.statusQueries.length > 0 ||
    query !== '' ||
    props.tagQueries.length > 0 ? (
    <div
      className='tw-rounded tw-bg-white'
      style={{ boxShadow: '0px 1px 0px #dfe1e6' }}
    >
      {filtersBlock}
      <ConfirmationModal
        toggle={() => setConfirmDelete(false)}
        isOpen={confirmDelete}
        caption='Delete'
        content={
          <>
            <div className='tw-mb-2 tw-flex tw-items-center tw-justify-between'>
              <Warning size={24} className='tw-fill-red' />

              <ModalCloseButton toggle={() => setConfirmDelete(false)} />
            </div>

            <p className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
              {t('Are you sure you want to delete')}
              {dataToDelete?.name}
              {t('tag?')}
            </p>
            <p className='tw-text-sm tw-text-text-80'>
              {t(
                'This tag will be removed from all contracts in which it is assigned',
              )}
            </p>
          </>
        }
        onConfirm={() => {
          deleteContractTag(user?.token, { tag_id: dataToDelete.id })
            .then((r) => {
              if (r.data.success) {
                tags.startFetch()
                onUpdate([], [], '')
                setConfirmDelete(false)
              }
            })
            .catch((e) => {
              toastr.error(e)
            })
        }}
        buttonColor='danger'
      />

      <>
        <div
          className='p-3 d-md-none'
          style={{
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 1px 0px #DFE1E6',
            borderRadius: 4,
          }}
        >
          {props?.loading || props?.filtering ? (
            <div className='p-3 w-100' style={{ '--s-height': '236px' }}>
              <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
              <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
              <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
            </div>
          ) : (
            orders?.map((order, i) => (
              <ContractCard
                index={i}
                key={'_order_' + i}
                archiveValue={props.archiveValue}
                withArchive={props.withArchive}
                order={order}
                isAdmin={props.isAdmin}
                getContractDetailUrl={getContractDetailUrl}
                getStatusColor={getContractStatusColor}
                onUpdate={onUpdate}
              />
            ))
          )}
        </div>

        <div className='d-none d-md-block'>
          <div className='table-responsive'>
            {isListEmpty ? (
              <TabEmpty
                title={t('No results match your search')}
                icon={
                  <FolderOpen
                    size={240}
                    color='var(--primary)'
                    weight='duotone'
                  />
                }
              />
            ) : props?.loading || props?.filtering ? (
              <div
                className={cn('w-100 px-3 pb-3', !showFiltersBlock && 'pt-3')}
                style={{ '--s-height': '57px' }}
              >
                <Shimmer
                  width='100%'
                  height='var(--s-height)'
                  className='mb-1'
                />

                <Shimmer
                  width='100%'
                  height='var(--s-height)'
                  className='mb-1'
                />

                <Shimmer width='100%' height='var(--s-height)' />
              </div>
            ) : (
              <Table className='table-centered table-nowrap text-muted mb-0'>
                <thead>
                  <tr
                    style={{
                      borderTop: 'hidden',
                      borderBottom: '1px solid #E7E8F2',
                      width: '100px',
                      maxWidth: '100px',
                    }}
                  >
                    {isColumnVisible('name') && (
                      <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                        {t('Name')}
                      </th>
                    )}

                    {isColumnVisible('created_on') && (
                      <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                        {t('Created on')}{' '}
                      </th>
                    )}

                    {isColumnVisible('country') && (
                      <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                        {t('Country')}
                      </th>
                    )}

                    {isColumnVisible('type') && (
                      <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                        {t('Contract type')}
                      </th>
                    )}
                    {isColumnVisible('job_title') && (
                      <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                        {t('Job title')}
                      </th>
                    )}
                    {isColumnVisible('tags') && (
                      <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                        {t('Tags')}
                      </th>
                    )}

                    {isColumnVisible('compensation') && (
                      <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                        {t('Compensation')}
                      </th>
                    )}
                    {isColumnVisible('company') && (
                      <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                        {t('Company')}
                      </th>
                    )}

                    {isColumnVisible('client') && (
                      <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                        {t('Client')}
                      </th>
                    )}

                    {isColumnVisible('status') && (
                      <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                        {t('Status')}
                      </th>
                    )}

                    {isColumnVisible('creator') && (
                      <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                        {t('Creator')}
                      </th>
                    )}

                    {visibleCustomColumns?.map((ccol, i) => (
                      <th
                        key={ccol + i}
                        className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'
                      >
                        {ccol}
                      </th>
                    ))}

                    {!props.isAdmin &&
                      props.withArchive &&
                      hasAccess(permissions.archiveContracts) && (
                        <th
                          style={{ fontWeight: '500' }}
                          className='text-dark font-size-14'
                        ></th>
                      )}
                  </tr>
                </thead>
                {!props.filtering && (
                  <tbody>
                    {orders?.map((order, index) => {
                      return (
                        <ContractLine
                          key={`contract-${order?.ref}`}
                          allTags={allTags}
                          index={index}
                          showTags={showTags}
                          order={order}
                          isAdmin={props.isAdmin}
                          archiveValue={props.archiveValue}
                          withArchive={props?.withArchive}
                          getStatusColor={getContractStatusColor}
                          getContractDetailUrl={getContractDetailUrl}
                          onUpdate={onUpdate}
                          deleteTagTotally={deleteTagTotally}
                          visibleDefaultColumns={visibleDefaultColumns}
                          visibleCustomColumns={visibleCustomColumns}
                          isColumnVisible={isColumnVisible}
                          updateTags={({ loading } = {}) =>
                            tags.startFetch(undefined, loading)
                          }
                          handleUserClick={props.handleUserClick}
                        />
                      )
                    })}
                  </tbody>
                )}
              </Table>
            )}
          </div>
        </div>

        {!paginator || isListEmpty || props?.loading ? null : (
          <div className='mt-2 mt-md-0 d-flex justify-content-md-end p-4.5'>
            <Pagination
              innerClass='pagination mb-0'
              activePage={page}
              onChange={setPage}
              itemsCountPerPage={paginator?.per_page ?? 50}
              totalItemsCount={paginator?.total ?? 0}
            />
          </div>
        )}
      </>
    </div>
  ) : (
    <div
      className='tw-rounded tw-bg-white'
      style={{ boxShadow: '0px 1px 0px #dfe1e6' }}
    >
      {filtersBlock}
      <NoContent
        image={welcomeImage}
        subtitle={
          user?.type === userTypes.COMPANY
            ? undefined
            : 'Manage contracts, invoices and get paid in +126 currencies'
        }
        title=''
        withAction={hasAccess(permissions.CreateContracts) && !kycNotVerified}
        actionTitle='New Contract'
        onAction={() => {
          history.push('/contract/create')
        }}
      />
    </div>
  )
}

function formatDateLong(date) {
  if (!date || !isValid(new Date(date))) {
    return ''
  }

  return format(new Date(date), 'dd/MM/yyyy HH:mm')
}

const ContractCard = ({
  order,
  withArchive,
  archiveValue,
  getContractDetailUrl,
  getStatusColor,
  isAdmin,
  onUpdate,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const { hasAccess } = usePermissions()

  const secondPart =
    user?.type === userTypes.COMPANY ? order?.contractor : order?.client

  const name =
    secondPart?.first_name || secondPart?.first_name
      ? `${secondPart?.first_name || ''} ${secondPart?.last_name || ''}`
      : secondPart?.email

  const archive = useFetch({
    action: isAdmin ? archiveAdminContract : archiveContract,
    withAdminAccess: isAdmin,
    onComplete: (_, body) => {
      onUpdate([], [])
      window.analytics.track('Archived a contract', {
        contract_id: body?.contract.ref,
        contract_status: body?.contract?.status?.name,
        contract_type: body?.contract?.type,
      })
    },
  })
  return (
    <div
      className='py-0 mb-3'
      style={{
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 0px #DFE1E6',
        borderRadius: 4,
        border: '1px solid #E7E8F2',
        position: 'relative',
      }}
    >
      <Row className='p-3 m-0 border-bottom'>
        <Col xs={8} className='p-0 m-0'>
          <p className='text-dark rp-font-bold tw-mb-0 tw-overflow-hidden tw-text-ellipsis tw-text-sm'>
            {order.name}
          </p>
          <Link
            style={{ textDecoration: 'none' }}
            className='text-primary border-bottom border-secondary font-size-12'
            to={getContractDetailUrl(order)}
          >
            {order.ref}
          </Link>
        </Col>
        <Col
          xs={4}
          className='align-items-center justify-content-end d-flex p-0 m-0'
        >
          <Badge
            className={`font-size-14 rounded bg-soft-${getStatusColor(
              order?.status,
            )}`}
            color='white'
            pill
          >
            <StyledP
              style={{ padding: 4 }}
              min='12px'
              max='14px'
              className={`mb-0 text-${getStatusColor(order?.status)}`}
            >
              {order?.status?.name.includes('Pending')
                ? 'Pending'
                : order?.status?.name}
            </StyledP>
          </Badge>
        </Col>
      </Row>
      <div className='p-3'>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            {t('Created on')}
          </h6>
          <h6 className='font-weight-normal mb-0 text-dark font-size-14'>
            {formatDateLong(order?.created_at * 1000)}
          </h6>
        </div>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            {t('Compensation')}
          </h6>
          <h6 className='text-dark font-size-14 font-weight-normal mb-0'>
            {order.total}
          </h6>
        </div>

        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            {t('Type')}
          </h6>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            {order.type}
          </h6>
        </div>

        <div className='d-flex mb-0 justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            {t('Contractor')}
          </h6>
          {secondPart
            ? !secondPart?.photo
            : !isAdmin && (
                <div className='text-dark font-size-14'>
                  <Link
                    to={`/contract/detail?id=${order.ref}`}
                    className='rp-font-bold text-primary font-size-14'
                  >
                    {t('Invite')}
                  </Link>
                </div>
              )}
          {!name ? null : (
            <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
              {name}
            </h6>
          )}
        </div>
        {!!isAdmin && (
          <div className='d-flex mt-3 justify-content-between align-items-center'>
            <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
              {t('Company')}
            </h6>
            <StyledH6
              className='font-size-14'
              style={{
                color: '#4A4E5B',
                fontWeight: '400',
                marginLeft: '10px',
              }}
            >
              {order?.company}
            </StyledH6>
          </div>
        )}
        {!!isAdmin && (
          <div className='d-flex mt-3 justify-content-between align-items-center'>
            <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
              {t('Client')}
            </h6>
            <StyledH6
              className='font-size-14'
              style={{
                color: '#4A4E5B',
                fontWeight: '400',
                marginLeft: '10px',
              }}
            >
              {order?.client?.first_name || order?.client?.first_name
                ? `${order?.client?.first_name || ''} ${
                    order?.client?.last_name || ''
                  }`
                : order?.client?.email}
            </StyledH6>
          </div>
        )}
      </div>
      {withArchive &&
        hasAccess(permissions.archiveContracts) &&
        (order?.status?.id !== 4 || archiveValue === 0 || isAdmin) && (
          <div className='px-3 pb-3 w-100'>
            {archive.isLoading ? (
              <div className='d-flex justify-content-center align-items-center'>
                <i className='bx bx-loader bx-spin font-size-16 text-primary' />
              </div>
            ) : (
              <Button
                type='button'
                icon={
                  archiveValue === 0 ? (
                    <img
                      src={unArchiveIcon}
                      alt='archive icon'
                      style={{ width: 16, marginRight: 8 }}
                    />
                  ) : (
                    <img
                      src={archiveIcon}
                      alt='archive icon'
                      style={{ width: 16, marginRight: 8 }}
                    />
                  )
                }
                text={archiveValue === 0 ? 'Un-archive' : 'Archive'}
                style={{ width: '100%', gap: 0 }}
                color='white'
                className='rp-border font-size-14'
                onClick={() => {
                  archive.startFetch({
                    contract_id: order.id,
                    status: archiveValue,
                  })
                }}
              />
            )}
          </div>
        )}
    </div>
  )
}

const ContractLine = ({
  order,
  isAdmin,
  withArchive,
  archiveValue,
  getStatusColor,
  onUpdate,
  allTags,
  deleteTagTotally,
  updateTags,
  index,
  visibleCustomColumns,
  isColumnVisible,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const company = useSelector(
    (state) => state?.userProfile?.userProfile?.company,
  )

  const { hasAccess } = usePermissions()
  const dispatch = useDispatch()
  const history = useHistory()

  const toDuplicate = useFetch({
    action: getContractDetail,
    onComplete: (data) => {
      dispatch(setContractToDuplicate(data))
      history.push('/contract/create', {
        duplicating: true,
        backRoute: '/contracts',
      })
    },
  })

  const archive = useFetch({
    action: isAdmin ? archiveAdminContract : archiveContract,
    withAdminAccess: isAdmin,
    onComplete: (_, body) => {
      onUpdate([], [])
      window.analytics.track('Archived a contract', {
        contract_id: body?.contract.ref,
        contract_status: body?.contract?.status?.name,
        contract_type: body?.contract?.type,
      })
    },
  })

  const total = order?.total?.split('/') ?? []

  const canArchive =
    (order?.status?.id !== CONTRACT_STATUS.ONGOING.value ||
      archiveValue === 0 ||
      isAdmin) &&
    withArchive &&
    hasAccess(permissions.archiveContracts)

  const canDuplicate =
    order?.type !== CONTRACT_TYPES.FULL_TIME &&
    hasAccess(permissions.archiveContracts)

  const actionsOptions = []

  if (canArchive) {
    actionsOptions[0] = {
      label: archiveValue === 0 ? t('Unarchive') : t('Archive'),
      onClick: () => {
        archive.startFetch({
          contract_id: order.id,
          status: archiveValue,
          contract: order,
        })
      },
    }
  }

  if (canDuplicate && user?.type !== userTypes.CONTRACTOR) {
    actionsOptions[1] = {
      label: t('Duplicate'),
      onClick: () => {
        toDuplicate.startFetch({ id: order?.ref })
      },
    }
  }

  const isDeContract = order?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE

  const contractorCountry =
    isDeContract && order?.contractor?.userable?.tax_residence
      ? order?.contractor?.userable?.tax_residence
      : order?.contractor?.country

  const contractorFlag = contractorCountry
    ? getFlagUrlFromIso2(contractorCountry?.iso2)
    : null

  const {
    type,
    name,
    client: { flag: clientFlag, country: clientCountry } = {},
  } = order || {}

  return (
    <tr
      className={cn('tw-border-b tw-border-b-surface-30 odd:tw-bg-surface-10')}
    >
      {isColumnVisible('name') && (
        <td className='tw-p-4'>
          {isAdmin ? (
            <ContractRef contractId={order.ref} />
          ) : (
            <WorkerName index={index} item={order} />
          )}
        </td>
      )}

      {isColumnVisible('created_on') && (
        <td className='tw-text-sm tw-font-medium tw-text-text'>
          {formatDateLong(order?.created_at * 1000)}
        </td>
      )}

      {isColumnVisible('country') && (
        <td className='tw-p-4'>
          <span className='tw-flex tw-items-center tw-gap-2'>
            <Flag size='24px' url={contractorFlag ?? clientFlag} />
            <span className='tw-text-sm tw-text-text'>
              {contractorCountry?.name ??
                clientCountry?.name ??
                company?.country?.name}
            </span>
          </span>
        </td>
      )}

      {isColumnVisible('type') && (
        <td className='tw-p-4'>
          <span className='tw-text-sm tw-font-medium tw-text-text'>{type}</span>
        </td>
      )}

      {isColumnVisible('job_title') && (
        <td className='tw-max-w-56 tw-truncate tw-p-4'>
          <span
            className='tw-text-sm tw-font-medium tw-capitalize tw-text-text'
            title={name}
          >
            {name}
          </span>
        </td>
      )}

      {isColumnVisible('tags') && (
        <td className='tw-p-4'>
          <TagsV2
            contract={order}
            tags={order?.tags}
            tagsList={allTags}
            onUpdate={onUpdate}
            deleteTagTotally={deleteTagTotally}
            updateTags={updateTags}
          />
        </td>
      )}

      {isColumnVisible('compensation') && (
        <td className='tw-p-4'>
          {total.length <= 0 ? null : (
            <span className='tw-flex tw-flex-col'>
              <span className='tw-text-sm tw-font-medium tw-text-text'>
                {total[0]?.trim()}
              </span>
              <span className='tw-text-xs tw-font-medium tw-capitalize'>
                /{total[1]}
              </span>
            </span>
          )}
        </td>
      )}

      {isColumnVisible('company') && (
        <td
          className='text-dark font-size-14 tw-p-4'
          style={{ fontWeight: '400' }}
        >
          {order.company}
        </td>
      )}

      {isColumnVisible('client') && (
        <td className='tw-p-4'>
          <Col>
            <Row className='align-items-center flex-nowrap'>
              {!!order?.client && (
                <Avatar
                  photo={order?.client?.photo}
                  name={order?.client?.first_name || order?.client?.email}
                  flag={order?.client?.flag}
                />
              )}
              <div
                className='font-size-14'
                style={{
                  color: '#4A4E5B',
                  fontWeight: '600',
                  marginLeft: '10px',
                }}
              >
                {order?.client?.first_name || order?.client?.first_name
                  ? `${order?.client?.first_name || ''} ${
                      order?.client?.last_name || ''
                    }`
                  : order?.client?.email}
              </div>
            </Row>
          </Col>
        </td>
      )}

      {isColumnVisible('status') && (
        <td className='tw-p-4'>
          <ContractStatus
            contract={order}
            badgeStatus={getStatusColor(order?.status)}
          />
        </td>
      )}

      {isColumnVisible('creator') && (
        <td className='tw-max-w-56 tw-truncate tw-p-4'>
          <span
            className='tw-text-sm tw-font-medium tw-capitalize tw-text-text'
            title={order.creator}
          >
            {order.creator}
          </span>
        </td>
      )}

      {visibleCustomColumns?.map((ccol) => (
        <td
          key={ccol}
          className='text-dark font-size-14 tw-p-4'
          style={{ fontWeight: '400' }}
        >
          <span className='tw-text-sm tw-font-medium tw-text-text'>
            {order.custom_fields?.length &&
            order.custom_fields.find((obj) => obj.attribute_name === ccol)
              ? order.custom_fields.find((obj) => obj.attribute_name === ccol)
                  .attribute_value
              : 'N/A'}
          </span>
        </td>
      ))}

      {!isAdmin && (
        <td className='position-relative'>
          {actionsOptions?.length > 0 && (
            <ActionsDropdown
              data={actionsOptions}
              loading={archive.isLoading || toDuplicate.isLoading}
            />
          )}
        </td>
      )}
    </tr>
  )
}

const { Option } = components
export const customOption = (props) => {
  return (
    <Option {...props}>
      <div>
        <div className='d-flex flex-row align-items-center justify-content-between'>
          <span
            className={`text-${props.isSelected ? 'primary' : 'dark'}`}
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'visible',
              backgroundColor: 'white',
              fontSize: '14px',
              fontWeight: '400',
            }}
          >
            {props.data.label}
          </span>
          {props.isSelected && <img src={selectedIcon} width={8.8} alt='' />}
        </div>
      </div>
    </Option>
  )
}

export const customSingleValue = (props) => {
  return (
    <div className='input-select'>
      <div className='input-select__single-value d-flex flex-row align-items-center'>
        {props.data.icon}
        <span
          className={props.data.def ? 'text-dark' : 'text-secondary'}
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          <span
            className='text-secondary'
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {props?.data.def}{' '}
          </span>
          {props.data.label}
        </span>
      </div>
    </div>
  )
}
