import {
  ArrowRight,
  ArrowsClockwise,
  Download,
  Info,
} from '@phosphor-icons/react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { t } from 'i18next'
import uniqueId from 'lodash/uniqueId'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ModalFooter, UncontrolledTooltip } from 'reactstrap'
import toastr from 'toastr'

import { cn } from 'ui'
import noInvoicesImage from '../../../assets/images/no-invoices.svg'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import Toggle from '../../../components/Forms/Toggle/Toggle'
import NoContent from '../../../components/NoContent'
import { SimpleCard } from '../../../components/simple-card'
import BadgeV2 from '../../../components/ui/badge-v2'
import Button from '../../../components/ui/button'
import DataTable from '../../../components/ui/data-table'
import Loader from '../../../components/ui/loader'
import Shimmer from '../../../components/ui/shimmer'
import { useFetch } from '../../../helpers/hooks'
import { downloadInvoiceV2 } from '../../../services/api'
import {
  getIntegrationAccounts,
  getIntegrationCostCenters,
  getIntegrationInvoices,
  getIntegrationVendors,
  putIntegrationAccounts,
  putIntegrationCostCenters,
  putIntegrationInvoices,
  putIntegrationVendors,
} from '../../../services/api-integrations'
import openFileV2 from '../../../utils/file/open-v2'
import ContractRef from '../../AdminPanel/components/ContractRef'
import { ContractorCell } from './components/contractor-cell'
import { UpdateContractorCategories } from './components/update-contractor-categories'

export function useIntegrationAccounts({ integrationKey, enabled = true }) {
  const token = useSelector((state) => state.Account?.user?.token)

  const {
    error: integrationError,
    data: integrationAccounts,
    isPending: accountsLoading,
    refetch,
  } = useQuery({
    queryKey: ['integrationAccounts', token, integrationKey],
    queryFn: () => {
      return getIntegrationAccounts(token, { integrationKey })
    },
    throwOnError: (axiosError) => {
      toastr.error(
        axiosError?.response?.data?.message ||
          'Error while fetching integration accounts',
      )
      // Don't throw the error
      return false
    },
    select: (data) => data.data,
    enabled,
  })

  return {
    integrationError,
    integrationAccounts: integrationError
      ? { integrationAccounts: [], internalAccounts: [] }
      : integrationAccounts,
    accountsLoading,
    refetch,
  }
}

export function IntegrationReviewAccounts({ integrationKey, integration }) {
  const [accounts, setAccounts] = useState({})

  function handleAccountChange({ value }, account) {
    setAccounts((prev) => ({ ...prev, [account.accountId]: value }))
  }

  const { integrationAccounts, accountsLoading, refetch } =
    useIntegrationAccounts({ integrationKey })

  const { startFetch: syncAccounts, isLoading: syncingAccounts } = useFetch({
    action: putIntegrationAccounts,
    autoFetch: false,
    onError: (error) => toastr.error(error || 'Something went wrong'),
    onComplete: () => {
      toastr.success(t('Accounts updated successfully'))
      refetch()
    },
  })

  const integrationName = integration.connection?.name

  function handleSave() {
    const accountsMap = integrationAccounts.internalAccounts
      .map((internalAccount) => {
        const internalAccountId = internalAccount.accountId

        if (!accounts?.[internalAccountId]) {
          const integrationAccountId = integrationAccounts.accountsMap.find(
            (accountMap) => {
              return accountMap.rpAccountId === internalAccountId
            },
          )?.integrationAccountId

          if (!integrationAccountId) {
            return null
          }

          return {
            rpAccountId: Number(internalAccountId),
            integrationAccountId,
          }
        }

        return {
          rpAccountId: Number(internalAccountId),
          integrationAccountId: accounts?.[internalAccountId],
        }
      })
      .filter(Boolean)

    if (accountsMap.length !== integrationAccounts.internalAccounts.length) {
      toastr.error(t('Please select all accounts'))
      return
    }

    syncAccounts({ integrationKey: integration.key, accountsMap })
  }

  return (
    <SimpleCard className='tw-mt-6 tw-p-6'>
      <h4 className='tw-text-base tw-font-bold'>
        {t('Select the account to use for each bill', { integrationName })}
      </h4>
      <p className='tw-mb-0 tw-text-sm tw-text-disabled'>
        {t(
          'RemotePass categories on the left will be posted to the accounts on the right',
          { integrationName },
        )}
      </p>

      <div className='tw-mt-6 tw-grid tw-items-center tw-gap-2 sm:tw-grid-cols-[1fr_auto_1fr] sm:tw-gap-3'>
        <MappingItem className='tw-hidden sm:tw-contents'>
          <div>{t('Categories')}</div>
          <div />
          <div>{t('Accounts')}</div>
        </MappingItem>

        {accountsLoading ? (
          <MappingItem>
            <Shimmer width='100%' height={42} />
            <div />
            <Shimmer width='100%' height={42} />
          </MappingItem>
        ) : (
          integrationAccounts.internalAccounts.map((account) => {
            const defaultAccountsMap = integrationAccounts.accountsMap.find(
              (map) => map.rpAccountId === account.accountId,
            )
            const idValue =
              accounts?.[account.accountId] ||
              defaultAccountsMap?.integrationAccountId

            const selectedValue = integrationAccounts.integrationAccounts.find(
              (account) => String(account.id) === String(idValue),
            )

            return (
              <MappingItem key={account.accountId}>
                <div className='form-control' readOnly>
                  {account.name}
                </div>
                <ArrowRight
                  size={20}
                  weight='bold'
                  className='tw-mx-auto tw-rotate-90 tw-text-primary-100 sm:tw-rotate-0 sm:rtl:tw-rotate-180'
                />

                <CustomSelect
                  options={integrationAccounts.integrationAccounts?.map(
                    (account) => {
                      return { label: account.name, value: account.id }
                    },
                  )}
                  isLoading={accountsLoading}
                  isDisabled={accountsLoading}
                  wrapperClassName='tw-mb-6 sm:tw-mb-0'
                  value={
                    !selectedValue
                      ? null
                      : { label: selectedValue.name, value: selectedValue.id }
                  }
                  onChange={(value) => handleAccountChange(value, account)}
                />
              </MappingItem>
            )
          })
        )}
      </div>

      <div className='tw-mt-4 tw-flex tw-justify-end'>
        <Button
          type='button'
          onClick={handleSave}
          loading={syncingAccounts}
          disabled={syncingAccounts}
        >
          {t('Save')}
        </Button>
      </div>
    </SimpleCard>
  )
}

export function MappingItem({ children, className }) {
  return <div className={cn('tw-contents', className)}>{children}</div>
}

export function integrationVendorsKeys({ token, integrationKey }) {
  return ['integrationVendors', integrationKey, token]
}

export function IntegrationStatus({ status, size = 'sm', error }) {
  const targetId = uniqueId('info-')

  const STATUS_COLORS = {
    not_synced: 'warning',
    syncing: 'warning',
    sync_in_progress: 'warning',
    failed: 'danger',
    synced: 'success',
    up_to_date: 'success',
  }

  return (
    <div className='tw-flex tw-items-center tw-gap-2'>
      <BadgeV2 status={STATUS_COLORS?.[status]} size={size}>
        {t(status)}
      </BadgeV2>
      {!error ? null : (
        <>
          <Info
            size={14}
            id={targetId}
            className='tw-text-text-100'
            weight='bold'
          />

          <UncontrolledTooltip placement='top' target={targetId}>
            {error}
          </UncontrolledTooltip>
        </>
      )}
    </div>
  )
}

export function IntegrationReviewContractors({ integrationKey, integration }) {
  const [contractors, setContractors] = useState({})

  const token = useSelector((state) => state.Account?.user?.token)

  const { isPending: vendorsLoading, data: integrationVendors } = useQuery({
    queryKey: integrationVendorsKeys({ token, integrationKey }),
    queryFn: () => {
      return getIntegrationVendors(token, { integrationKey })
    },
    select: (data) => data.data,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const queryClient = useQueryClient()

  const { startFetch: syncContractors, isLoading: syncingContractors } =
    useFetch({
      action: putIntegrationVendors,
      autoFetch: false,
      onError: (error, _, body) => {
        toastr.error(error || 'Something went wrong')

        queryClient.setQueryData(
          integrationVendorsKeys({ token, integrationKey }),
          (old) => ({
            ...old,
            data: { ...old.data, autoSyncVendors: !body.autoSyncVendors },
          }),
        )
      },
      onComplete: () => {
        toastr.success(t('Workers synced successfully'))

        queryClient.invalidateQueries({
          queryKey: integrationVendorsKeys({ token, integrationKey }),
        })
      },
    })

  function handleSave() {
    const mappedContractors = getMappedContractors({
      contractorVendorMaps: integrationVendors.vendorMappings,
      changedContractors: contractors,
    })

    if (mappedContractors.length < integrationVendors?.vendorMappings.length) {
      toastr.error(t('Please select all contractors'))
    } else {
      syncContractors({ integrationKey, vendorMappings: mappedContractors })
    }
  }

  return (
    <>
      <IntegrationContractorAutoSync
        integrationKey={integrationKey}
        autoSyncVendors={integrationVendors?.autoSyncVendors}
        loading={vendorsLoading}
      />

      <SimpleCard className='tw-mt-6'>
        <IntegrationContractorsList
          data={integrationVendors}
          loading={vendorsLoading}
          integration={integration}
          contractors={contractors}
          setContractors={setContractors}
        />

        <ModalFooter className='tw-sticky tw-bottom-0 tw-bg-white'>
          <Button
            type='button'
            color='primary'
            onClick={handleSave}
            loading={syncingContractors}
            disabled={
              syncingContractors ||
              vendorsLoading ||
              integrationVendors?.vendorMappings?.length === 0
            }
          >
            {t('Save Changes')}
          </Button>
        </ModalFooter>
      </SimpleCard>
    </>
  )
}

export function IntegrationContractorsList({
  data,
  loading,
  integration,
  contractors,
  setContractors,
}) {
  const integrationKey = integration.key

  const columns = useMemo(() => {
    return [
      {
        Header: t('Contractor'),
        Cell: ({ rowData }) => {
          return <ContractorCell contractor={rowData.contractor} />
        },
      },
      {
        Header: t('Vendor'),
        className: 'tw-w-96',
        Cell: ({ rowData }) => {
          const createNewVendorOption = {
            name: 'Create new vendor',
            id: 'create-new-vendor',
          }

          const foundVendor = !rowData.vendor.id
            ? createNewVendorOption
            : (data?.integrationVendors?.find(
                (vendor) => String(vendor.id) === String(rowData.vendor.id),
              ) ?? { name: rowData.vendor.id, id: rowData.vendor.id })

          return (
            <CustomSelect
              wrapperClassName='tw-min-w-52'
              options={[
                {
                  label: createNewVendorOption.name,
                  value: createNewVendorOption.id,
                },
                ...(data?.integrationVendors?.map((vendor) => {
                  return { label: vendor.name, value: vendor.id }
                }) ?? []),
              ]}
              isLoading={loading}
              isDisabled={loading}
              value={
                contractors[rowData.contractor.id] ||
                (foundVendor
                  ? { label: foundVendor?.name, value: foundVendor?.id }
                  : null)
              }
              onChange={(value) => {
                setContractors((contractors) => ({
                  ...contractors,
                  [rowData.contractor.id]: value,
                }))
              }}
            />
          )
        },
      },
      {
        Header: t('Accounts'),
        Cell: ({ rowData }) => {
          return (
            <UpdateContractorCategories
              itemData={rowData}
              integration={{
                key: integrationKey,
                name: integration.connection?.name,
              }}
            />
          )
        },
      },
      {
        Header: t('Status'),
        Cell: ({ rowData }) => {
          return (
            <IntegrationStatus
              status={rowData.status}
              error={rowData?.error?.message}
            />
          )
        },
      },
    ]
  }, [
    data?.integrationVendors,
    loading,
    contractors,
    setContractors,
    integration.connection?.name,
    integrationKey,
  ])

  return (
    <>
      <DataTable columns={columns} data={data?.vendorMappings} />

      {!loading ? null : (
        <div className='tw-flex tw-gap-6 tw-px-3 tw-py-4'>
          <Shimmer height={46} width={370} />
          <Shimmer height={46} width='17.6rem' />
          <Shimmer height={46} width='338px' />
          <Shimmer height={46} width='122px' />
        </div>
      )}
    </>
  )
}

export function IntegrationContractorAutoSync({
  integrationKey,
  autoSyncVendors,
  loading,
}) {
  const token = useSelector((state) => state.Account?.user?.token)

  const queryClient = useQueryClient()

  const { startFetch: syncContractors, isLoading: syncingContractors } =
    useFetch({
      action: putIntegrationVendors,
      autoFetch: false,
      onError: (error, _, body) => {
        toastr.error(error || 'Something went wrong')

        queryClient.setQueryData(
          integrationVendorsKeys({ token, integrationKey }),
          (old) => ({
            ...old,
            data: { ...old.data, autoSyncVendors: !body.autoSyncVendors },
          }),
        )
      },
      onComplete: (data) => {
        toastr.success(
          data?.autoSyncVendors
            ? 'Workers auto sync enabled'
            : 'Workers auto sync disabled',
        )

        queryClient.invalidateQueries({
          queryKey: integrationVendorsKeys({ token, integrationKey }),
        })
      },
    })

  function handleSyncContractors(event) {
    queryClient.setQueryData(
      integrationVendorsKeys({ token, integrationKey }),
      (old) => ({
        ...old,
        data: { ...old.data, autoSyncVendors: event.target.checked },
      }),
    )

    syncContractors({
      integrationKey,
      autoSyncVendors: event.target.checked,
      vendorMappings: [],
    })
  }

  return (
    <SimpleCard className='tw-mt-6 tw-flex tw-items-center tw-gap-4 tw-p-6'>
      <Toggle
        marginRight=''
        check={autoSyncVendors}
        change={handleSyncContractors}
        disabled={loading || syncingContractors}
      />

      <div>
        <h4 className='tw-mb-0.5 tw-text-base tw-font-bold'>
          {t('Workers auto sync')}
        </h4>
        <p className='tw-mb-0 tw-text-disabled'>
          {t('Automatically sync all new contractors with the integration.')}
        </p>
      </div>
    </SimpleCard>
  )
}

export function getMappedContractors({
  contractorVendorMaps,
  changedContractors,
}) {
  const mappedContractors = contractorVendorMaps?.map((contractorVendorMap) => {
    const baseMap = {
      contractor: contractorVendorMap.contractor,
      vendor: contractorVendorMap.vendor,
    }

    const changedContractor =
      changedContractors[contractorVendorMap.contractor.id]

    if (changedContractor) {
      baseMap.vendor = { id: changedContractor.value }
    }

    return baseMap
  })

  return mappedContractors
}

function integrationInvoicesKeys({ token, integrationKey }) {
  return ['integrationInvoices', integrationKey, token]
}
export function IntegrationReviewInvoices({ integrationKey, integration }) {
  const integrationName = integration.connection?.name

  const token = useSelector((state) => state.Account?.user?.token)

  const { isPending: invoicesLoading, data: integrationInvoices } = useQuery({
    queryKey: integrationInvoicesKeys({ token, integrationKey }),
    queryFn: () => {
      return getIntegrationInvoices(token, { integrationKey })
    },
    select: (data) => data.data,
  })

  const isEmpty = integrationInvoices?.invoiceMappings?.length <= 0

  const queryClient = useQueryClient()

  const { startFetch: syncInvoices, isLoading: syncingInvoices } = useFetch({
    action: putIntegrationInvoices,
    autoFetch: false,
    onError: (error, _, body) => {
      toastr.error(error || 'Something went wrong while syncing invoices')

      queryClient.setQueryData(
        integrationInvoicesKeys({ token, integrationKey }),
        (old) => {
          const newData = { ...old.data }

          if (typeof body.autoSyncInvoices === 'boolean') {
            newData.autoSyncInvoices = !body.autoSyncInvoices
          }
          if (typeof body.autoSyncBills === 'boolean') {
            newData.autoSyncBills = !body.autoSyncBills
          }

          return { ...old, data: newData }
        },
      )
    },
    onComplete: (_, body) => {
      toastr.success(body?.successMessage || 'Invoices synced successfully')

      queryClient.invalidateQueries({
        queryKey: integrationInvoicesKeys({ token, integrationKey }),
      })
    },
  })

  function handleAutoSyncInvoices(event) {
    queryClient.setQueryData(
      integrationInvoicesKeys({ token, integrationKey }),
      (old) => ({
        ...old,
        data: { ...old.data, autoSyncInvoices: event.target.checked },
      }),
    )

    syncInvoices({
      integrationKey,
      autoSyncInvoices: event.target.checked,
      autoSyncBills: integrationInvoices?.autoSyncBills,
      invoiceMappings: [],
      successMessage: event.target.checked
        ? 'Auto-sync invoices enabled successfully'
        : 'Auto-sync invoices disabled successfully',
    })
  }
  function handleAutoSyncBills(event) {
    queryClient.setQueryData(
      integrationInvoicesKeys({ token, integrationKey }),
      (old) => ({
        ...old,
        data: { ...old.data, autoSyncBills: event.target.checked },
      }),
    )

    syncInvoices({
      integrationKey,
      autoSyncInvoices: integrationInvoices?.autoSyncInvoices,
      autoSyncBills: event.target.checked,
      invoiceMappings: [],
      successMessage: event.target.checked
        ? 'Auto-sync bills enabled successfully'
        : 'Auto-sync bills disabled successfully',
    })
  }

  function handleSyncAll() {
    syncInvoices({
      integrationKey,
      invoiceMappings: [],
      autoSyncInvoices: integrationInvoices?.autoSyncInvoices,
      autoSyncBills: integrationInvoices?.autoSyncBills,
    })
  }

  const columns = useMemo(
    () => [
      { Header: t('Date'), accessor: 'dueDate' },
      {
        Header: t('Contract'),
        accessor: 'contractRef',
        Cell: ({ cellData }) => {
          return <ContractRef isAdmin={false} contractId={cellData} />
        },
      },
      { Header: t('Contractor'), accessor: 'contractorName' },
      { Header: t('Ref'), accessor: 'ref' },
      {
        Header: t('Status'),
        accessor: 'status',
        Cell: ({ rowData }) => {
          return (
            <IntegrationStatus
              status={rowData.status}
              error={
                typeof rowData?.error?.context?.message === 'string'
                  ? rowData.error.context.message
                  : typeof rowData?.error?.context?.message?.[0] === 'string'
                    ? rowData.error.context.message[0]
                    : rowData?.error?.message
              }
            />
          )
        },
      },
      {
        Header: t('Download'),
        Cell: ({ rowData }) => {
          return (
            <DownloadIntegrationInvoice
              invoiceRef={rowData.ref}
              invoiceToken={rowData.token}
            />
          )
        },
      },
    ],

    [],
  )

  return (
    <>
      <div className='tw-mt-6 tw-grid tw-gap-4 md:tw-grid-cols-2'>
        <SimpleCard className='tw-flex tw-items-center tw-gap-4 tw-p-6'>
          <Toggle
            marginRight=''
            check={integrationInvoices?.autoSyncInvoices}
            change={handleAutoSyncInvoices}
            disabled={invoicesLoading || syncingInvoices}
          />

          <div>
            <h4 className='tw-mb-0.5 tw-text-base tw-font-bold'>
              {t('Invoices auto sync')}
            </h4>
            <p className='tw-mb-0 tw-text-disabled'>
              {t('Automatically synchronize with every time I run payroll', {
                integrationName,
              })}
            </p>
          </div>
        </SimpleCard>

        <SimpleCard className='tw-flex tw-items-center tw-gap-4 tw-p-6'>
          <Toggle
            marginRight=''
            check={integrationInvoices?.autoSyncBills}
            change={handleAutoSyncBills}
            disabled={invoicesLoading || syncingInvoices}
          />

          <div>
            <h4 className='tw-mb-0.5 tw-text-base tw-font-bold'>
              {t('Bills auto sync')}
            </h4>
            <p className='tw-mb-0 tw-text-disabled'>
              {t(
                'Automatically synchronize with every every time I add bills.',
                { integrationName },
              )}
            </p>
          </div>
        </SimpleCard>
      </div>

      <SimpleCard className='tw-mt-6 tw-p-4'>
        <div className='tw-flex tw-flex-wrap tw-justify-between tw-p-3'>
          <h4 className='tw-text-2xl'>{t('Invoices')}</h4>

          <Button
            icon={<ArrowsClockwise weight='bold' size={16} />}
            onClick={handleSyncAll}
            disabled={syncingInvoices || invoicesLoading || isEmpty}
          >
            {t('Sync all')}
          </Button>
        </div>

        {invoicesLoading ? (
          <Loader minHeight='max(50vh, 550px)' />
        ) : isEmpty ? (
          <NoContent
            headline='No Invoices'
            subtitle='All your invoices will be shown here after each payment'
            image={noInvoicesImage}
          />
        ) : (
          <DataTable
            columns={columns}
            data={integrationInvoices.invoiceMappings}
          />
        )}
      </SimpleCard>
    </>
  )
}

function DownloadIntegrationInvoice({ invoiceRef, invoiceToken }) {
  const { startFetch: downloadAttachment, isLoading: downloadingAttachment } =
    useFetch({
      action: downloadInvoiceV2,
      onComplete: (data) => {
        openFileV2(data, {
          download: true,
          name: `invoice-${invoiceRef}.pdf`,
          type: 'application/pdf',
        })
      },
    })

  function handleDownload() {
    downloadAttachment({ ref: invoiceRef, invoiceToken })
  }

  return (
    <Button
      icon={<Download size={16} />}
      color='link'
      className='!tw-p-0 !tw-font-normal !tw-text-text-100'
      onClick={handleDownload}
      loading={downloadingAttachment}
      disabled={downloadingAttachment}
    >
      {t('Invoice', { count: 1 })}
    </Button>
  )
}

export function IntegrationReviewCostCenters({ integrationKey }) {
  const [selectedField, setSelectedField] = useState(null)
  const [costCentersObject, setCostCentersObject] = useState({})
  const [errors, setErrors] = useState({})

  const {
    data: costCenters,
    isLoading: fetchingCostCenters,
    startFetch: refreshCostCenters,
  } = useFetch({
    action: getIntegrationCostCenters,
    autoFetch: true,
    body: { integrationKey },
    onComplete: (data) => {
      const foundField = data.internalCustomFields.find(
        ({ id }) => id === data?.costCenterMappings?.rpContractCustomFieldId,
      )

      const valueField = !foundField
        ? null
        : { label: foundField.name, value: foundField.id }

      setSelectedField(valueField)
    },
  })

  const { startFetch: updateCostCenters, isLoading: updatingCostCenters } =
    useFetch({
      action: putIntegrationCostCenters,
      onComplete: () => {
        toastr.success(t('Cost centers update successfully'))
        refreshCostCenters?.()
      },
      onError: toastr.error,
    })

  const selectedFieldOptions = costCenters?.internalCustomFields?.find(
    ({ id }) => id === selectedField?.value,
  )?.options

  const customFieldOptions = costCenters?.internalCustomFields
    ?.filter(({ options }) => options.length > 0)
    ?.map(({ name, id }) => ({ label: name, value: id }))

  function validateCostCenters() {
    setErrors({})

    const errors = {}

    selectedFieldOptions.forEach((option) => {
      const defaultValue = costCenters?.costCenterMappings?.fieldsMapping?.find(
        (fieldMap) => {
          return fieldMap?.rpCostCenterFieldId === option.id
        },
      )

      const value =
        costCentersObject[option.id]?.value ??
        defaultValue?.integrationCostCenterFieldId

      if (!value) {
        errors[option.id] = 'Field is required'
      }
    })

    if (Object.keys(errors).length > 0) {
      setErrors(errors)
      return { isValid: false, errors }
    }

    return { isValid: true, errors }
  }

  function handleSave() {
    const { isValid } = validateCostCenters()

    if (!isValid) {
      return
    }

    const fieldsMapping = selectedFieldOptions
      .map((option) => {
        const defaultValue =
          costCenters?.costCenterMappings?.fieldsMapping?.find((fieldMap) => {
            return fieldMap?.rpCostCenterFieldId === option.id
          })

        const value =
          costCentersObject[option.id]?.value ??
          defaultValue?.integrationCostCenterFieldId

        if (!value) return null

        return {
          rpCostCenterFieldId: Number(option.id),
          integrationCostCenterFieldId: value,
        }
      })
      .filter(Boolean)

    if (fieldsMapping.length < selectedFieldOptions.length) {
      toastr.error(t('Please select all fields'))
      return
    }

    const body = {
      rpContractCustomFieldId: Number(selectedField.value),
      fieldsMapping,
    }

    updateCostCenters({ integrationKey, ...body })
  }

  return (
    <div>
      <SimpleCard className='tw-mt-6 tw-p-6'>
        <h4 className='tw-text-base tw-font-bold'>
          {t(
            'Select the Custom Field and corresponding NetSuite Parameter to map',
          )}
        </h4>
        <p className='tw-mb-0 tw-text-sm tw-text-disabled'>
          {t(
            'RemotePass Custom Fields will be listed on the left and NetSuite parameters on the right',
          )}
        </p>

        <div className='tw-mt-8'>
          <CustomSelect
            label={t('RemotePass Contract Custom Field')}
            inputId='rpCustomField'
            options={customFieldOptions}
            isDisabled={fetchingCostCenters}
            isLoading={fetchingCostCenters}
            wrapperClassName='tw-max-w-xs'
            value={selectedField}
            onChange={setSelectedField}
          />
        </div>

        <hr className='tw-my-8' />

        <div className='tw-mt-8 tw-grid tw-gap-2 sm:tw-grid-cols-[1fr_auto_1fr] sm:tw-gap-3'>
          <MappingItem className='tw-hidden sm:tw-contents'>
            <div>
              {t('RP Custom Field')}

              {selectedField?.label ? ` (${selectedField?.label})` : ''}
            </div>
            <div />
            <div>{t('Cost center')}</div>
          </MappingItem>

          {fetchingCostCenters ? (
            <MappingItem>
              <Shimmer width='100%' height={42} />
              <div />
              <Shimmer width='100%' height={42} />
            </MappingItem>
          ) : (
            selectedFieldOptions?.map((field) => {
              const defaultValue =
                costCenters?.costCenterMappings?.fieldsMapping?.find(
                  (fieldMap) => {
                    return fieldMap?.rpCostCenterFieldId === field.id
                  },
                )

              const foundValue = costCenters?.integrationCostCenters?.find(
                (costCenter) => {
                  return (
                    costCenter.id === defaultValue?.integrationCostCenterFieldId
                  )
                },
              )

              const selectedValue =
                costCentersObject?.[field.id] ??
                (!foundValue
                  ? null
                  : { label: foundValue.name, value: foundValue.id })

              return (
                <MappingItem key={field.id}>
                  <div className='form-control' readOnly>
                    {field.name}
                  </div>
                  <ArrowRight
                    size={20}
                    weight='bold'
                    className='tw-mx-auto tw-mt-3 tw-rotate-90 tw-text-primary-100 sm:tw-rotate-0 sm:rtl:tw-rotate-180'
                  />

                  <CustomSelect
                    options={costCenters.integrationCostCenters?.map(
                      (account) => {
                        return { label: account.name, value: account.id }
                      },
                    )}
                    isLoading={fetchingCostCenters}
                    isDisabled={fetchingCostCenters}
                    wrapperClassName='tw-mb-6 sm:tw-mb-0'
                    value={selectedValue}
                    onChange={(value) => {
                      setCostCentersObject((prevObject) => {
                        return { ...prevObject, [field.id]: value }
                      })
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        [field.id]: '',
                      }))
                    }}
                    error={errors?.[field.id]}
                  />
                </MappingItem>
              )
            })
          )}
        </div>

        <div className='tw-mt-4 tw-flex tw-justify-end'>
          <Button
            type='button'
            onClick={handleSave}
            loading={updatingCostCenters}
            disabled={updatingCostCenters}
          >
            {t('Save')}
          </Button>
        </div>
      </SimpleCard>
    </div>
  )
}
