import React from 'react'
import { Spinner } from 'reactstrap'

import { useFetch } from '../../../../helpers/hooks'
import { getIntegrationSyncStatus } from '../../../../services/api-integrations'
import { IntegrationStatus } from '../integration-review-tabs'

export function IntegrationSyncStatus({ className, integration }) {
  const { key } = integration

  const { data: syncStatus, isLoading: isLoadingSyncStatus } = useFetch({
    action: getIntegrationSyncStatus,
    autoFetch: true,
    body: { integrationKey: key },
  })

  return (
    <div className={className}>
      {isLoadingSyncStatus ? (
        <div>
          <Spinner size='sm' color='secondary' />
        </div>
      ) : (
        <>
          <IntegrationStatus
            integration={integration}
            status={syncStatus?.invoiceSyncStatus}
            size='md'
          />
        </>
      )}
    </div>
  )
}
