import React from 'react'
import { t } from 'i18next'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { WarningOctagon } from '@phosphor-icons/react'
import Button from '../../../components/ui/button'

export function DeleteDocumentModal({ isOpen, toggle, onDelete }) {
  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <WarningOctagon size={25} className='tw-text-systemRed-100' />
      </ModalHeader>
      <ModalBody className='d-flex flex-column'>
        <span className='tw-text-base tw-font-bold'>
          {t('Are you sure you want to delete this document?')}
        </span>
        <span>{t('This document will be permanently deleted')}</span>
      </ModalBody>
      <ModalFooter className='!tw-flex-nowrap'>
        <Button
          className='tw-mb-2 !tw-bg-white'
          textClassName='!tw-text-black'
          color='surface-30'
          outline={true}
          onClick={toggle}
        >
          {t('No, close')}
        </Button>
        <Button
          color='danger'
          onClick={() => {
            onDelete()
            toggle()
          }}
        >
          {t('Yes, Delete')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
