import { ArrowSquareIn, Info } from '@phosphor-icons/react'
import cx from 'classnames'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { UncontrolledTooltip } from 'reactstrap'
import { ReactComponent as RPIconSVG } from '../../../../assets/images/identity/symbol_main.svg'
import RemotePassIcon from '../../../../assets/images/identity/symbol_main.png'
import Toggle from '../../../../components/Forms/Toggle/Toggle'
import Alert from '../../../../components/ui/alert'
import Button from '../../../../components/ui/button'
import InputFeedback from '../../../../components/ui/input-feedback'
import { EligibilityModal } from './eligibility-test/eligibility-modal'
import { PremiumBenefitModal } from './premium-benefit-modal'

export function ControlledPremiumRecruitment({
  control,
  name,
  required,
  transform,
  disabled,
  companyCountry,
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field, fieldState: { error } }) => {
        const { value, onChange } = field

        function handleChange(newVal) {
          typeof transform?.output === 'function'
            ? onChange(transform.output(newVal))
            : onChange(newVal)
        }

        return (
          <RenderControlledBlock
            error={error}
            value={value}
            transform={transform}
            handleChange={handleChange}
            disabled={disabled}
            companyCountry={companyCountry}
          />
        )
      }}
    />
  )
}

function RenderControlledBlock({
  transform,
  value,
  error,
  handleChange,
  disabled,
  companyCountry,
}) {
  const [premiumModalOpen, setPremiumModalOpen] = useState(false)

  return (
    <>
      <PremiumRecruitment
        checked={transform?.input ? transform.input(value) : value}
        onChange={() => {
          if (value === true) {
            handleChange(false)
          } else {
            setPremiumModalOpen(true)
          }
        }}
        disabled={disabled}
        companyCountry={companyCountry}
      />

      {!error ? null : (
        <InputFeedback className='tw-mt-1'>
          {typeof error?.message === 'string' ? error.message : error}
        </InputFeedback>
      )}

      {premiumModalOpen ? (
        <EligibilityModal
          isOpen={premiumModalOpen}
          toggle={(eligible) => {
            if (eligible === true) {
              handleChange(true)
            }
            setPremiumModalOpen(false)
          }}
        />
      ) : null}
    </>
  )
}

const blockId = 'premium-recruitment'
function PremiumRecruitment({ checked, onChange, disabled, companyCountry }) {
  const [premiumBenefitModalOpen, setPremiumBenefitModalOpen] = useState(false)

  return (
    <Alert
      className='p-0 bg-transparent border-0 tw-overflow-hidden'
      innerTag='div'
      innerClassName={cx(
        'rounded p-3 border d-flex align-items-center gap-10 w-100',
        disabled ? 'bg-secondary-10' : 'bg-primary-10',
      )}
      customIcon={false}
      id={blockId}
    >
      <div
        className={cx(
          'p-2.5 flex-shrink-0 align-self-start tw-rounded-lg',
          disabled ? 'bg-secondary-20' : 'bg-primary-20',
        )}
      >
        <img
          src={RemotePassIcon}
          style={{
            ...(disabled
              ? { filter: 'grayscale(1)', opacity: 0.6 }
              : undefined),
            height: 26,
            width: 26,
          }}
        />
      </div>
      <div
        className={cx(disabled ? 'text-secondary-60' : 'text-secondary-100')}
      >
        <div className='tw-flex tw-flex-row tw-items-center tw-gap-1'>
          <h3
            className={cx(
              'font-size-16 font-weight-medium tw-mb-0',
              disabled && 'text-secondary-70',
            )}
          >
            Contractor of record
          </h3>
          <Info
            onClick={() => setPremiumBenefitModalOpen(true)}
            size={20}
            className='tw-cursor-pointer tw-text-primary'
          />
        </div>

        <p className='mb-0 text-current'>
          Let RemotePass hire and pay global contractors for you—effortlessly.
        </p>

        <Button
          iconRight={<ArrowSquareIn size={20} />}
          color='link'
          className={cx('px-0.5 py-1 mt-2', disabled && 'text-secondary-70')}
          tag='a'
          target='_blank'
          rel='noreferrer'
          href='https://help.remotepass.com/en/articles/8512670-how-to-onboard-contractors-using-remotepass'
        >
          Learn more
        </Button>
      </div>

      {!disabled ? null : (
        <UncontrolledTooltip target={blockId}>
          {companyCountry ?? 'The company country'} is not yet supported for the
          Contractor of record service.
        </UncontrolledTooltip>
      )}

      <Toggle
        check={checked}
        change={(event) => onChange(event.target.checked)}
        marginRight={null}
        className='ml-auto'
        disabled={disabled}
      />

      <PremiumBenefitModal
        toggle={() => setPremiumBenefitModalOpen((prv) => !prv)}
        isOpen={premiumBenefitModalOpen}
      />

      <div className='tw-pointer-events-none tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center'>
        <RPIconSVG className='tw-h-[300px] tw-w-[300px] tw-opacity-5' />
      </div>
    </Alert>
  )
}

export default PremiumRecruitment
