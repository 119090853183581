import React from 'react'
import { Col, Row } from 'reactstrap'
import toastr from 'toastr'
import { Spinner, Trash } from '@phosphor-icons/react'
import { t } from 'i18next'

import { useFetch, useResize } from '../../../helpers/hooks'
import {
  deletePayoneerAccount,
  updatePaypalAccount,
} from '../../../services/api'
import paypal from '../../../assets/images/paypal.svg'
import paypalSelected from '../../../assets/images/paypal_selected.svg'
import payoneer from '../../../assets/images/payoneer-logo.png'
import EVENTS from '../../../utils/events'
import { track } from '../../../utils/analytics'
import { cn } from 'ui'
import Button from '../../../components/ui/button'
import IconButton from '../../../components/ui/icon-button'
import { WITHDRAW_METHODS } from '../../../core/config/payment-methods'

export const PaymentMethodCard = ({
  className,
  withRemove,
  onClick,
  deleteCard,
  item,
  children,
  withdrawMethod,
  reactivateComponent,
}) => (
  <button
    className={cn(
      'tw-relative tw-mb-4 tw-rounded tw-border tw-border-surface-130 tw-bg-white tw-text-left md:tw-mb-0',
      { '!tw-cursor-default': !onClick },
      className,
    )}
    onClick={onClick}
    type='button'
  >
    <div className='tw-absolute tw-end-3 tw-top-3 tw-z-[9]'>
      {withRemove ? (
        <>
          {deleteCard.isLoading ? (
            <Spinner
              className='tw-size-3.5 tw-animate-[spin_2s_linear_infinite] tw-text-systemRed-100'
              weight='bold'
            />
          ) : (
            <IconButton
              icon={
                <Trash
                  size={18}
                  weight='fill'
                  className='tw-m-1 tw-fill-disabled'
                />
              }
              className='!tw-h-fit !tw-w-fit !tw-border-0 !tw-bg-white'
              onClick={() => {
                deleteCard.startFetch({
                  id: item?.id,
                  is_active:
                    withdrawMethod === WITHDRAW_METHODS.PAYPAL.name
                      ? false
                      : undefined,
                })
              }}
              type='button'
            />
          )}
        </>
      ) : (
        reactivateComponent
      )}
    </div>
    {children}
  </button>
)

const PaypalMethodCard = ({
  account,
  withTag,
  onUpdate,
  selected,
  onSelect,
  onClick = () => {},
  withRemove,
  checked,
  isPayoneer,
  isLoading,
}) => {
  const isMobile = useResize()
  const isActive = account.active

  const deleteCard = useFetch({
    action: isPayoneer ? deletePayoneerAccount : updatePaypalAccount,
    onComplete: () => {
      onUpdate()
      track(EVENTS.REMOVED_WITHDRAW_METHODS, {
        withdraw_method: isPayoneer ? 'Payoneer' : 'Paypal',
        is_default: false,
      })
    },
  })

  const { startFetch: activateAccount, isLoading: activatingPaypalAccount } =
    useFetch({
      action: updatePaypalAccount,
      onComplete: () => {
        onUpdate()
      },
      onError: toastr.error,
    })

  return (
    <PaymentMethodCard
      className={cn({
        'tw-border-primary': checked,
        'tw-bg-secondary/10': !isActive,
      })}
      withRemove={withRemove}
      deleteCard={deleteCard}
      item={account}
      onClick={isActive ? () => onClick(account) : undefined}
      withdrawMethod={
        isPayoneer
          ? WITHDRAW_METHODS.PAYONEER.name
          : WITHDRAW_METHODS.PAYPAL.name
      }
      reactivateComponent={
        <Button
          size='sm'
          color='link'
          onClick={() => activateAccount({ is_active: true, id: account.id })}
          loading={activatingPaypalAccount}
          disabled={activatingPaypalAccount}
        >
          {t('Reactivate')}
        </Button>
      }
    >
      <Row
        className={`${isMobile ? 'p-md-3 p-3' : 'p-md-0 p-0'} py-md-3 py-3 m-0`}
      >
        <Col xs={12} md={2} className='p-0 m-0'>
          <div
            style={{ height: '100%', width: '100%' }}
            className={`d-flex align-items-center ${
              isMobile ? 'justify-content-start' : 'justify-content-center'
            }`}
          >
            <img
              className='mb-3 mb-md-0'
              src={isPayoneer ? payoneer : checked ? paypalSelected : paypal}
              alt='Wallet icon'
              style={{
                width: '1.8rem',
                filter: isPayoneer && !checked ? 'grayscale(100%)' : undefined,
              }}
            />
          </div>
        </Col>

        <Col xs={12} md={10} className='!tw-m-0 !tw-p-0'>
          <h5
            className={`${
              checked ? 'tw-text-primary' : 'tw-text-black'
            } tw-mb-2 tw-text-start tw-text-base`}
          >
            {t('Email')}
          </h5>
          <div
            className={`${
              checked ? 'tw-text-primary' : 'tw-text-secondary'
            } tw-mb-2 tw-text-start tw-text-sm`}
          >
            {account.email}
          </div>
          {withTag &&
            (isLoading ? (
              <>
                <i className='bx bx-loader bx-spin font-size-14' />
              </>
            ) : (
              <Button
                size='sm'
                className={cn(
                  '!tw-rounded-[1.6px] !tw-border-surface-30 !tw-px-1 !tw-py-0 !tw-text-[10px]',
                  { '!tw-bg-transparent !tw-text-text': !selected },
                  { '!tw-bg-disabled !tw-text-white': !isActive },
                )}
                disabled={!isActive}
                onClick={onSelect}
              >
                {selected
                  ? t('Default')
                  : !isActive
                    ? t('Deactivated')
                    : t('Make default')}
              </Button>
            ))}
        </Col>
      </Row>
    </PaymentMethodCard>
  )
}

export default PaypalMethodCard
