import { t } from 'i18next'
import { Bank, Check, Gear, Plus, TrashSimple } from '@phosphor-icons/react'
import cx from 'classnames'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'
import { ActionsDropdown } from 'ui'

import globe from '../../assets/images/identity/symbol_main.png'
import ConfirmationModal from '../../components/Common/ConfirmationModal'
import BadgeX from '../../components/Table/BadgeX'
import { PermissionTooltip } from '../../components/permission-tooltip'
import { PrimaryAlert } from '../../components/ui/alert'
import { Box } from '../../components/ui/box'
import Button from '../../components/ui/button'
import DataTable from '../../components/ui/data-table'
import NavTabsV2 from '../../components/ui/nav-tabs/nav-tabs-v2'
import Shimmer from '../../components/ui/shimmer'
import { useFetch, usePermissions, useResize } from '../../helpers/hooks'
import { useUrlState } from '../../helpers/hooks/use-url-state'
import permissions from '../../helpers/permissions'
import {
  deactivateBankAccount,
  getDepositBalance,
  getDepositBankAccounts,
  getDepositList,
  getRefundsList,
  updateCompanyInfo,
  withdrawCurrencies,
} from '../../services/api'
import { updateProfileCompany } from '../../store/profile/actions'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import { mapCurrencyToOption } from '../../utils/map-to-option'
import ContractRef from '../AdminPanel/components/ContractRef'
import { getContractStatusColor } from '../Contract/ContractList/ContractList'
import ContractStatus from '../Contract/ContractList/contract-status'
import { TransactionStatusBadge } from '../Transactions'
import { getAccountNumber } from '../Withdrawal/components/WithdrawMethodCard'
import { NewAddBankForm } from '../withdrawProcess/BankAccounts'
import { PERMISSION_GROUP } from './manage-role'
import SearchBar from '../../components/SearchBar'
import { CheckItem } from '../../components/ui/check-item'
import i18n from '../../i18n'

export function DepositsRefundsTab() {
  return (
    <div className='rounded-top-0 mb-0 p-3 d-flex flex-column gap-24'>
      <RefundBalanceSection />

      <RefundMethodsSection />

      <RefundDepositTableSection />
    </div>
  )
}
const defaultElement = (t) => (
  <div className='!tw-ms-auto tw-flex tw-items-center'>
    <BadgeX status='primary' pill size='sm'>
      {t('Default')}
    </BadgeX>
  </div>
)

function TabSectionHeading({ children, className }) {
  return (
    <h3
      className={cx('text-secondary-100 tw-text-xl tw-font-bold', className)}
      style={{ marginBottom: 0 }}
    >
      {children}
    </h3>
  )
}

function RefundBalanceSection() {
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )
  const companyCurrency = company.currency?.code

  const { data, isLoading } = useFetch({
    action: getDepositBalance,
    autoFetch: true,
  })

  return (
    <div className='tw-grid tw-gap-4 md:tw-grid-cols-2'>
      <Box className='d-flex align-items-center gap-16'>
        <div>
          <h5 className='uppercase font-size-12 mb-1'>{t('Refund balance')}</h5>

          <RefundBalance
            loading={isLoading}
            value={data?.balance}
            currency={companyCurrency}
          />
        </div>

        {/* These action will may be implemented later */}
        {/* Hiding for release */}
        {/* {data?.balance === 0 || isLoading ? null : (
           <div className='!tw-ms-auto'>
             {data?.balance > 0 ? (
               <Button className='px-0' color='link'>
                 Withdraw
               </Button>
             ) : (
               <Button className='px-0' color='link'>
                 Settle
               </Button>
             )}
           </div>
          )} */}
      </Box>

      <PrimaryAlert>
        {t(
          'The balance of deposit refunds and billing credits will be displayed here. This balance will be automatically used for your future transactions.',
        )}
      </PrimaryAlert>
    </div>
  )
}

function RefundBalance({ value, currency, loading }) {
  const numberValue = Number(value)

  const formatter = getCurrencyFormatter(currency, undefined, {
    signDisplay: 'exceptZero',
  })
  const _value = formatter.format(value)

  return (
    <div
      className={cx('font-size-24 tw-flex tw-items-center tw-tabular-nums', {
        'tw-text-systemRed-100': numberValue < 0,
        'tw-text-systemGreen-100': numberValue > 0,
      })}
      style={{ height: '2rem' }}
    >
      {loading ? <Shimmer width={100} className='h-100' /> : _value}
    </div>
  )
}

function RefundMethodsSection() {
  const {
    data,
    isLoading,
    startFetch: fetchBankAccounts,
  } = useFetch({ action: getDepositBankAccounts, autoFetch: true })

  const { hasAccess } = usePermissions()

  const canManage = hasAccess(permissions.ManageCompanyDepositAndRefund)

  const haveBankAccounts = data?.bank_accounts?.length > 0
  const isBankAccountDefault = data?.is_bank_refund_enabled === 1

  return (
    <div>
      <TabSectionHeading className='mb-3 tw-text-start'>
        {t('Refund methods')}
      </TabSectionHeading>

      <div className='tw-grid tw-gap-4 md:tw-grid-cols-2'>
        <Box className='d-flex align-items-center gap-16'>
          <RemotePassBalanceMethodBlock
            isDefault={!isBankAccountDefault}
            fetchBankAccounts={fetchBankAccounts}
            canManage={canManage}
            isLoading={isLoading}
          />
        </Box>

        <Box borderDashed={!haveBankAccounts} noPadding>
          <RefundBankAccountBlock
            isLoading={isLoading}
            haveBankAccounts={haveBankAccounts}
            data={data}
            canManage={canManage}
            fetchBankAccounts={fetchBankAccounts}
            isDefault={isBankAccountDefault}
          />
        </Box>
      </div>
    </div>
  )
}

function RemotePassBalanceMethodBlock({
  isDefault = false,
  fetchBankAccounts,
  isLoading,
  canManage,
}) {
  const dispatch = useDispatch()

  const { startFetch: updateCompany, isLoading: updatingCompanyInfo } =
    useFetch({
      action: updateCompanyInfo,
      onComplete: (data) => {
        if (data?.success !== false) {
          dispatch(updateProfileCompany(data))
          fetchBankAccounts?.()
        }
      },
    })

  return (
    <>
      <div
        style={{ height: '2.5rem', width: '2.5rem' }}
        className='bg-primary-10 rounded d-flex justify-content-center align-items-center'
      >
        <img
          style={{ height: '1.5rem', width: 'auto', objectFit: 'contain' }}
          src={globe}
          alt='RemotePass icon logo'
        />
      </div>

      <div className='tw-text-start'>
        <h5 className='mb-1 font-bold tw-text-black'>{t('RemotePass')}</h5>
        <p className='mb-0 text-secondary-100'>
          {t('Will be added to your refund balance above')}
        </p>
      </div>

      {isLoading ? null : !isDefault ? (
        <PermissionTooltip
          showing={!canManage}
          id='make-payment-default-btn'
          className='!tw-ms-auto'
          area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
        >
          <Button
            className='px-0 !tw-ms-auto'
            color='link'
            type='button'
            onClick={() => updateCompany({ is_bank_refund_enabled: 0 })}
            loading={updatingCompanyInfo}
            disabled={updatingCompanyInfo || !canManage}
          >
            {t('Make default')}
          </Button>
        </PermissionTooltip>
      ) : (
        defaultElement(t)
      )}
    </>
  )
}

const bankIcon = (
  <div
    className='rounded bg-surface-20 d-flex justify-content-center align-items-center'
    style={{ width: '2rem', height: '2rem' }}
  >
    <Bank size={24} />
  </div>
)

function RefundBankAccountBlock({
  isLoading,
  haveBankAccounts,
  data,
  fetchBankAccounts,
  isDefault,
  canManage,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const dispatch = useDispatch()

  const commonClasses = 'd-flex align-items-center gap-16 h-100 p-3'

  const { startFetch: updateCompany, isLoading: updatingCompanyInfo } =
    useFetch({
      action: updateCompanyInfo,
      onComplete: (data) => {
        if (data?.success !== false) {
          dispatch(updateProfileCompany(data))
          fetchBankAccounts?.()
        }
      },
    })

  const { startFetch: deactivateBank, isLoading: deactivatingBank } = useFetch({
    action: deactivateBankAccount,
    onComplete: (data) => {
      if (data?.success !== false) {
        toastr.success(t('Bank account deleted successfully'))
        setConfirmDeleteOpen(false)
        fetchBankAccounts?.()
      }
    },
  })

  const actionsDropdownLoading = updatingCompanyInfo || deactivatingBank

  if (isLoading) {
    return <Shimmer className={cx('w-100', commonClasses)} />
  }

  if (haveBankAccounts) {
    const { currency, details } = data.bank_accounts[0]
    const accountNumber = getAccountNumber(details)

    return (
      <div className={cx(commonClasses)}>
        {bankIcon}

        <h5 className='mb-0 font-bold tw-text-black'>
          {currency}
          {t('account ending in')}{' '}
          {accountNumber?.slice(
            accountNumber?.length - 4,
            accountNumber?.length,
          )}
        </h5>

        <div className='d-flex align-items-center gap-16 !tw-ms-auto'>
          {!isDefault ? null : defaultElement(t)}

          {canManage ? (
            <>
              <ActionsDropdown
                loading={actionsDropdownLoading}
                data={[
                  !isDefault && {
                    label: t('Make default refund method'),
                    icon: <Check size={16} />,
                    onClick: () => {
                      updateCompany({ is_bank_refund_enabled: 1 })
                    },
                  },
                  {
                    label: t('Delete bank account'),
                    icon: <TrashSimple size={16} />,
                    className: 'tw-text-red-100',
                    onClick: () => setConfirmDeleteOpen(true),
                  },
                ]}
              />

              <ConfirmationModal
                title={t('Delete refund bank account')}
                content={
                  <p className='tw-text-xl tw-text-text-100'>
                    {t(
                      'Are you sure you want to delete the refund bank account?',
                    )}
                  </p>
                }
                toggle={() => setConfirmDeleteOpen((open) => !open)}
                caption='Confirm Delete'
                buttonColor='danger'
                isOpen={confirmDeleteOpen}
                onCancel={() => setConfirmDeleteOpen(false)}
                onConfirm={() => {
                  deactivateBank({ id: data.bank_accounts[0].id })
                }}
                confirmLoading={deactivatingBank}
              />
            </>
          ) : null}
        </div>
      </div>
    )
  }

  return (
    <>
      <PermissionTooltip
        showing={!canManage}
        id='add-bank-acct-btn'
        className='tw-ms-auto tw-h-full'
        area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
      >
        <button
          type='button'
          className={cx(commonClasses, 'p-0 w-100 hover:bg-surface-10')}
          onClick={() => setIsOpen(true)}
          disabled={!canManage}
        >
          {bankIcon}

          <h5 className='mb-0 font-bold tw-text-black'>
            {t('Add bank account')}
          </h5>

          <div className='!tw-ms-auto'>
            <Plus size={24} />
          </div>
        </button>
      </PermissionTooltip>

      <AddBankAccountModal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        onSuccess={() => fetchBankAccounts()}
      />
    </>
  )
}

function AddBankAccountModal({ isOpen, toggle, onSuccess }) {
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )
  const currency = company.currency?.code

  const { data: currencies } = useFetch(
    { action: withdrawCurrencies, autoFetch: true, body: { currency } },
    [currency],
  )

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader toggle={toggle}>{t('Add refund bank account')}</ModalHeader>
      <ModalBody className='p-0'>
        <NewAddBankForm
          currency={currency}
          currencies={currencies?.map((c) => mapCurrencyToOption(c))}
          toggle={toggle}
          updateList={() => {
            toggle()
            onSuccess()

            toastr.success(t('Refund bank account added successfully'))
          }}
        />
      </ModalBody>
    </Modal>
  )
}

function RefundDepositTableSection() {
  const [activeTab, setActiveTab] = useUrlState({
    name: 'deposits-tab',
    defaultValue: 'deposits',
  })

  return (
    <div>
      <DepositNav activeTab={activeTab} onTabChange={setActiveTab} />

      {
        {
          deposits: <DepositTable />,
          refunds: <RefundsTable />,
        }[activeTab]
      }
    </div>
  )
}

function DepositNav({ activeTab, onTabChange }) {
  return (
    <NavTabsV2
      options={[
        { value: 'deposits', label: t('Deposits') },
        { value: 'refunds', label: t('Refunds') },
      ]}
      activeOption={activeTab}
      onClickOption={(nav) => onTabChange(nav.value)}
      className='mb-3'
    />
  )
}

function DateCell({ date }) {
  return (
    <>
      <span className='tw-text-text-120'>
        {`${t(format(new Date(date), 'MMM'))} ${format(date, 'dd, yyyy')}`}
      </span>
      <br />
      <span className='font-size-12'>{format(date, 'hh:mm:ss')}</span>
    </>
  )
}
function DepositTransactionStatus({ status }) {
  const colors = {
    [t('Paid')]: 'info',
    [t('Refund Processing')]: 'warning',
    [t('Processing')]: 'warning',
    [t('Refunded')]: 'success',
  }
  const color = colors[status] || 'secondary'

  return (
    <BadgeX status={color} size='md'>
      {status}
    </BadgeX>
  )
}

function depositColumns({ hasViewContractsAccess, customFields = [] }) {
  const defaultDepositColumns = [
    { Header: t('Deposit ID'), accessor: 'id' },
    {
      Header: t('Date'),
      accessor: 'created_at',
      Cell: ({ cellData }) => {
        const date = new Date(cellData * 1000)
        return <DateCell date={date} />
      },
    },
    {
      Header: t('Contract ID'),
      accessor: 'contract_id',
      Cell: ({ cellData }) => {
        if (!hasViewContractsAccess) {
          return cellData
        }
        return <ContractRef contractId={cellData} />
      },
    },
    {
      Header: t('Contract status'),
      accessor: 'contract_status.name',
      Cell: ({ rowData }) => {
        return (
          <ContractStatus
            contract={{
              amended: rowData?.contract_amended,
              status: rowData?.contract_status,
            }}
            badgeStatus={getContractStatusColor(rowData?.contract_status)}
          />
        )
      },
    },
    { Header: t('Employee'), accessor: 'employee_name' },
    {
      Header: t('Amount'),
      accessor: 'amount',
      Cell: ({ rowData }) => {
        const formatter = getCurrencyFormatter(rowData?.currency?.code)
        return formatter.format(rowData.amount)
      },
    },
    {
      Header: t('Status'),
      accessor: 'status',
      Cell: ({ cellData }) => {
        return <DepositTransactionStatus status={cellData} />
      },
    },
  ]

  const customDepositColumns = customFields?.map((c) => ({
    Header: c,
    Cell: ({ rowData }) => {
      return (
        rowData?.custom_fields?.find((f) => f?.attribute_name === c)
          ?.attribute_value ?? 'N/A'
      )
    },
  }))

  return [...defaultDepositColumns, ...customDepositColumns]
}

function DepositTable() {
  const { hasAccess } = usePermissions()
  const isMobile = useResize()

  const hasViewContractsAccess = hasAccess(permissions.ViewContracts)
  const allDefaultColumns = depositColumns({ hasViewContractsAccess })?.map(
    (c) => c.Header,
  )
  const [_columns, _setColumns] = useState(
    depositColumns({ hasViewContractsAccess }),
  )
  const defaultColumns = _columns?.map((c) => c.Header)

  const [searchCols, setSearchCols] = useState('')
  const [searchDeposits, setSearchDeposits] = useState('')
  const [visibleDefaultColumns, setVisibleDefaultColumns] =
    useState(defaultColumns)
  const [visibleCustomColumns, setVisibleCustomColumns] = useState([])
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen((o) => !o)

  const {
    data,
    isLoading,
    startFetch: fetchDeposits,
  } = useFetch({
    action: getDepositList,
    body: { page: 1, selected_custom_fields: [] },
    autoFetch: true,
    onError: (err) => toastr.error(err),
  })

  useEffect(() => {
    const customFields = data?.custom_fields
    _setColumns(
      depositColumns({ hasViewContractsAccess, customFields }).filter((c) =>
        [...visibleDefaultColumns, ...visibleCustomColumns].includes(c.Header),
      ),
    )
  }, [data?.custom_fields])

  function handleDepositsSearch(query) {
    setSearchDeposits(query)
    fetchDeposits({ search_key: query, page: 1 })
  }

  function handleColumnsSearch(query) {
    setSearchCols(query)
  }

  function columnSearch(columnName) {
    return (
      !searchCols ||
      columnName?.toLowerCase?.().includes(searchCols?.toLowerCase?.())
    )
  }

  return (
    <>
      <div className='tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2'>
        <SearchBar
          className='tw-basis-full md:tw-basis-1/2'
          query={searchDeposits}
          placeholder={t('Search by Employee name or Contract ID')}
          onQueryChanged={handleDepositsSearch}
          isClearable
          inputClassName='!tw-h-auto'
        />

        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle data-toggle='dropdown' tag='div'>
            <>
              <Button
                color='light'
                outline
                icon={<Gear size={20} />}
                className='!tw-bg-white !tw-p-2.5'
                id='depositRefundsColumnsSet'
              />

              <UncontrolledTooltip target='depositRefundsColumnsSet'>
                {t('Configure columns')}
              </UncontrolledTooltip>
            </>
          </DropdownToggle>
          <DropdownMenu
            right={!isMobile && i18n.dir() !== 'rtl'}
            className='!tw-min-w-[250px] !tw-transform-none !tw-text-start'
            container='body'
          >
            <DropdownItem toggle={false}>
              <SearchBar
                className='tw-basis-full'
                query={searchCols}
                placeholder={t('Search field names')}
                onQueryChanged={handleColumnsSearch}
                isClearable
                inputClassName='!tw-h-auto'
              />
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem header>{t('Default fields')}</DropdownItem>
            {allDefaultColumns?.filter(columnSearch)?.map((column, i) => (
              <DropdownItem key={i} toggle={false}>
                <CheckItem
                  label={column}
                  labelId={column}
                  checked={visibleDefaultColumns.includes(column)}
                  onChange={(event) => {
                    const { name, checked } = event.target
                    const _name = name?.replace(/-/gi, ' ')
                    setVisibleDefaultColumns((p) =>
                      checked ? [...p, _name] : p.filter((i) => i !== _name),
                    )
                  }}
                />
              </DropdownItem>
            ))}
            <DropdownItem divider />
            <DropdownItem header>{t('Custom fields')}</DropdownItem>
            {data?.custom_fields?.filter(columnSearch)?.map((column, i) => (
              <DropdownItem key={i} toggle={false}>
                <CheckItem
                  label={column}
                  labelId={column}
                  checked={visibleCustomColumns.includes(column)}
                  onChange={(event) => {
                    const { name, checked } = event.target
                    const _name = name?.replace(/-/gi, ' ')
                    setVisibleCustomColumns((p) =>
                      checked ? [...p, _name] : p.filter((i) => i !== _name),
                    )
                  }}
                />
              </DropdownItem>
            ))}
            <DropdownItem divider />
            <DropdownItem toggle={false}>
              <div className='tw-flex tw-justify-between'>
                <Button
                  className='!tw-px-0'
                  color='link'
                  type='button'
                  onClick={() => {
                    _setColumns(depositColumns({ hasViewContractsAccess }))
                    setVisibleDefaultColumns(allDefaultColumns)
                    setVisibleCustomColumns([])

                    toggle()
                  }}
                  textClassName='tw-text-black'
                >
                  {t('Restore defaults')}
                </Button>

                <Button
                  className='!tw-px-0'
                  color='link'
                  type='button'
                  onClick={() => {
                    fetchDeposits({
                      selected_custom_fields: [...visibleCustomColumns],
                      page: 1,
                    })

                    toggle()
                  }}
                  disabled={
                    visibleDefaultColumns.length === 0 &&
                    visibleCustomColumns.length === 0
                  }
                >
                  {t('Apply')}
                </Button>
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>

      {isLoading ? (
        <div className='d-flex flex-column gap-4 tw-mt-4'>
          <Shimmer className='w-100' height={53} />
          <Shimmer className='w-100' height={53} />
          <Shimmer className='w-100' height={53} />
          <Shimmer className='w-100' height={53} />
          <Shimmer className='w-100' height={53} />
        </div>
      ) : (
        <DataTable
          className='font-size-14 !tw-text-start'
          cellClassName='!tw-text-start'
          headClassName='!tw-text-start'
          columns={_columns}
          data={data.deposits}
          responsive
        />
      )}
    </>
  )
}

function RefundsTable() {
  const refundColumns = [
    { Header: t('Transaction ID'), accessor: 'id' },
    { Header: t('Reference'), accessor: 'base_transaction_ref' },
    {
      Header: t('Amount'),
      accessor: 'amount',
      Cell: ({ rowData }) => {
        const formatter = getCurrencyFormatter(rowData?.currency?.code)
        return formatter.format(rowData.amount)
      },
    },
    {
      Header: t('Refund status'),
      accessor: 'status',
      Cell: ({ rowData }) => {
        return <TransactionStatusBadge order={rowData} />
      },
    },
    {
      Header: t('Refund date'),
      accessor: 'created_at',
      Cell: ({ cellData }) => {
        const date = new Date(cellData * 1000)
        return <DateCell date={date} />
      },
    },
  ]
  const { data, isLoading } = useFetch({
    action: getRefundsList,
    autoFetch: true,
  })

  return isLoading ? (
    <div className='d-flex flex-column gap-4'>
      <Shimmer className='w-100' height={53} />
      <Shimmer className='w-100' height={53} />
      <Shimmer className='w-100' height={53} />
      <Shimmer className='w-100' height={53} />
      <Shimmer className='w-100' height={53} />
    </div>
  ) : (
    <DataTable
      columns={refundColumns}
      data={data}
      responsive
      className='!tw-text-start'
      cellClassName='tw-text-start'
      headClassName='tw-text-start'
    />
  )
}
