import React, { useEffect, useRef } from 'react'

import { cn } from 'ui'

export function CheckItem({
  labelId,
  label,
  name,
  value,
  rounded,
  checked,
  disabled,
  className,
  labelClassName,
  defaultChecked,
  inputClassName,
  onChange = () => {},
  indeterminate = false,
}) {
  const checkboxRef = useRef()
  useEffect(() => {
    checkboxRef.current.indeterminate = indeterminate
  }, [checkboxRef, indeterminate])
  // we derive the name from the label if it's not provided
  const formattedLabel =
    typeof label !== 'string' ? null : (label ?? '').replace(/\s/g, '-')
  const theName = [name, formattedLabel].filter(Boolean).join('-')

  return (
    <label
      className={cn(
        'rp-checkbox-label tw-m-0 tw-flex tw-items-center tw-gap-2.5 tw-text-sm tw-font-normal tw-text-text-80',
        disabled && 'tw-cursor-not-allowed',
        className,
      )}
      id={labelId}
    >
      <input
        ref={checkboxRef}
        type='checkbox'
        className={cn(
          'rp-checkbox-input tw-flex-shrink-0',
          rounded && '!tw-rounded-full',
          inputClassName,
        )}
        name={theName}
        value={value}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        disabled={disabled}
      />
      {label && (
        <span
          className={cn(
            'tw-leading-none',
            disabled && '!tw-opacity-30',
            labelClassName,
          )}
        >
          {label}
        </span>
      )}
    </label>
  )
}
