import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { StepsProvider, useSteps } from './step-context'

function EligibilityForm() {
  const { renderStep, isOpen, toggle } = useSteps()
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody className='tw-flex tw-min-h-[500px] tw-flex-col !tw-p-0'>
        {renderStep()}
      </ModalBody>
    </Modal>
  )
}

export function EligibilityModal({ isOpen, toggle }) {
  return (
    <StepsProvider isOpen={isOpen} toggle={toggle}>
      <EligibilityForm />
    </StepsProvider>
  )
}
