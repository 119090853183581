import {
  City,
  Eye,
  Flag,
  FlowArrow,
  Info,
  MapPin,
  MapTrifold,
  Notebook,
  Storefront,
} from '@phosphor-icons/react'
import { t } from 'i18next'
import React, { useState } from 'react'
import toastr from 'toastr'

import { Title } from '.'
import ConfirmationModal from '../../../../components/Common/ConfirmationModal'
import ApprovalFlowNameList from '../../../../components/approval-flow-name-list'
import ApprovalFlowSummary from '../../../../components/approval-flow-summary'
import Button from '../../../../components/ui/button'
import CountryFlag, { getFlagUrlFromIso2 } from '../../../../components/ui/flag'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  sideMenuGridClass,
  SideMenuHeader,
  SideMenuHeading,
  useSideMenuState,
} from '../../../../components/ui/side-menu'
import { useFetch, usePermissions } from '../../../../helpers/hooks'
import permissions from '../../../../helpers/permissions'
import { deleteVendor } from '../../../../services/api-bill-payments'
import { FormSectionHr } from '../../../Contract/CreateContract/components/form-section-title'
import { DetailsInfoList } from '../../../review-center/review-layout-details-components'
import {
  DetailSectionTitle,
  VendorBankDetails,
} from '../../detail-section-title'
import { BillInfoText } from '../../list'
import { AddBankSideMenu } from './add-bank-side-menu'
import EditVendorApprovalFlow from './edit-vendor-approval-flow'
import { EditVendorDetailsSideMenu } from './edit-vendor-details'

const DeleteButton = ({ onSuccess, vendor }) => {
  const [showConfirm, setShowConfirm] = useState()
  const { isLoading: deletingVendor, startFetch: _deleteVendor } = useFetch({
    action: deleteVendor,
    onComplete: () => {
      toastr.success(
        t('Vendor deleted successfully.', { vendorName: vendor.name }),
      )
      onSuccess()
      setShowConfirm(false)
    },
    onError: (error) => toastr.error(error),
  })

  return (
    <>
      <Button
        color='transparent'
        className='!tw-px-0 !tw-text-systemRed'
        onClick={() => setShowConfirm(true)}
      >
        {t('Delete')}
      </Button>

      <ConfirmationModal
        toggle={() => setShowConfirm(false)}
        isOpen={showConfirm}
        title={t('Delete Vendor')}
        message={`${t('Are you sure you want to delete')} ${vendor.name}?`}
        onConfirm={() => _deleteVendor({ id: vendor.id })}
        confirmLoading={deletingVendor}
        caption={t('Delete')}
        buttonColor='danger'
      />
    </>
  )
}

export default function DetailsButton({
  item,
  fetchVendors,
  activeItem,
  showItem,
  closeItem,
  hasIntegration,
}) {
  const [isAddBankOpen, toggleAddBank] = useSideMenuState()
  const [isEditOpen, toggleEdit] = useSideMenuState()
  const { hasAccess } = usePermissions()
  const canManageBills = hasAccess(permissions.ManageBill)
  const [EditApprovalFlow, toggleEditApprovalFlow] = useSideMenuState()

  const {
    bank_account: bankAccount,
    name,
    country,
    category,
    city,
    state,
    address,
    approval_flow: approvalFlow,
  } = item
  const iconStyle = 'tw-fill-text-60 tw-size-6'
  const isActive = activeItem === item?.id
  const sideMenuItems = [
    {
      label: t('Vendor'),
      value: <BillInfoText title={name} />,
      icon: <Storefront className={iconStyle} />,
    },
    {
      label: hasIntegration ? 'GL Account' : t('Category'),
      value: category ? <BillInfoText title={category.name} /> : 'N/A',
      icon: <Notebook className={iconStyle} />,
    },
    {
      label: t('Country'),
      value: country ? (
        <span className='tw-flex tw-gap-2'>
          <BillInfoText title={country.name} />
          <CountryFlag url={getFlagUrlFromIso2(country.iso2)} />
        </span>
      ) : (
        t('N/A')
      ),

      icon: <Flag className={iconStyle} />,
    },
    country?.id === 233
      ? {
          label: t('State'),
          value: state ? <BillInfoText title={state?.name} /> : t('N/A'),
          icon: <City className={iconStyle} />,
        }
      : null,
    {
      label: t('City'),
      value: city ? <BillInfoText title={city} /> : t('N/A'),
      icon: <MapTrifold className={iconStyle} />,
    },
    {
      label: t('Recipient address'),
      value: address ? <BillInfoText title={address} /> : t('N/A'),
      icon: <MapPin className={iconStyle} />,
    },
  ]

  return (
    <>
      <Button
        color='link'
        onClick={() => showItem?.()}
        className='tw-text-sm !tw-text-primary-100'
        icon={<Eye size={16} />}
      >
        {t('Details')}
      </Button>

      {isActive && (
        <SideMenu
          isOpen
          onClose={() => closeItem?.()}
          itemListClassName={sideMenuGridClass()}
        >
          <SideMenuHeader toggle={() => closeItem?.()}>
            <SideMenuHeading title={name} subTitle={t('Vendor')} />
          </SideMenuHeader>

          <SideMenuBody className='tw-flex-1'>
            <DetailsInfoList
              items={sideMenuItems}
              className='tw-p-0'
              title={
                <DetailSectionTitle
                  title={<Title details={{ ...item }} />}
                  icon={
                    <Info size={20} className='tw-me-2 tw-fill-primary-100' />
                  }
                  containerClassName='tw-mb-2'
                  rightItem={
                    canManageBills && (
                      <Button
                        color='link'
                        className='!tw-px-0 !tw-py-1'
                        onClick={toggleEdit}
                      >
                        {t('Edit')}
                      </Button>
                    )
                  }
                />
              }
            />

            <FormSectionHr className='tw-my-6' />

            <DetailSectionTitle
              title={t('Approval flow')}
              icon={<FlowArrow size={20} className='tw-fill-primary' />}
              rightItem={
                hasAccess(permissions.ManageVendors) && (
                  <Button
                    color='link'
                    className='!tw-px-0 !tw-text-primary'
                    onClick={toggleEditApprovalFlow}
                  >
                    {t('Edit')}
                  </Button>
                )
              }
              containerClassName='tw-justify-between tw-gap-2 tw-mb-4'
              className='tw-justify-between tw-gap-2'
            />

            <ApprovalFlowSummary
              icon={<FlowArrow size={24} className='tw-fill-primary' />}
              iconClassName='!tw-bg-primary-20'
              title={approvalFlow.name}
              description={t('levelSequentialApprovalFlow', {
                count: approvalFlow.steps?.length,
              })}
              footer={
                <ApprovalFlowNameList
                  nameList={approvalFlow.steps?.map((step) => step?.user_name)}
                />
              }
            />

            {EditApprovalFlow && (
              <EditVendorApprovalFlow
                toggle={toggleEditApprovalFlow}
                vendor={item}
                refetchVendors={fetchVendors}
                activeFlow={approvalFlow}
              />
            )}

            <FormSectionHr className='tw-my-6' />

            <VendorBankDetails
              bankInfo={bankAccount}
              handleAddBankAccount={toggleAddBank}
              canManageBills={canManageBills}
              vendor={item}
              updateVendors={() => fetchVendors(undefined, false)}
            />

            <AddBankSideMenu
              isOpen={isAddBankOpen}
              toggle={toggleAddBank}
              updateVendors={() => fetchVendors(undefined, false)}
              vendor={item}
            />
          </SideMenuBody>

          {canManageBills && (
            <SideMenuFooter className='tw-justify-between'>
              <DeleteButton
                onSuccess={() => {
                  closeItem?.()
                  fetchVendors?.()
                }}
                vendor={item}
              />
            </SideMenuFooter>
          )}
        </SideMenu>
      )}
      <EditVendorDetailsSideMenu
        isOpen={isEditOpen}
        toggle={toggleEdit}
        updateVendors={() => fetchVendors(undefined, false)}
        vendor={item}
      />
    </>
  )
}
