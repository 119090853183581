import React, { useEffect, useState } from 'react'
import { Card } from 'reactstrap'
import { CaretDown, CaretUp, Play, TrashSimple } from '@phosphor-icons/react'
import { cn } from 'ui'
import { useFormContext, Controller } from 'react-hook-form'
import { ActionMessageForm } from './action-message-form'
import { RemoveStepModal } from '../modals/remove-step-modal'
import CustomSelect from '../../../../../components/Forms/CustomSelect/CustomSelect'
import { triggerActionType } from '../tools/constants'
import { ActionTitle } from './action-title'

export function Action(props) {
  const {
    id,
    index,
    stepsField: { fields: steps, remove },
  } = props
  const {
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext()
  const focused = watch('focusedNode') === id
  const hasError = errors?.steps?.[index]
  const [removeModal, setRemoveModal] = useState(false)
  const actions = steps.filter((s) => s.type === 'action')
  const isFirstAction = actions?.[0]?.id === id
  useEffect(() => {
    if (actions?.length > 1) setValue('focusedNode', id)
  }, [])

  return (
    <div
      className={cn(
        'tw-rounded-md',
        hasError ? 'tw-border-2 tw-border-red' : undefined,
        focused ? 'tw-border-2 tw-border-primary-60' : undefined,
      )}
    >
      <Card
        className='!tw-mb-0 tw-flex tw-cursor-pointer tw-flex-col'
        onClick={() => setValue('focusedNode', focused ? null : id)}
      >
        <div className='tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2 tw-p-4'>
          <div
            className={cn(
              'tw-rounded tw-bg-secondary-20 tw-p-2',
              focused && 'tw-bg-primary-20',
              hasError && 'tw-bg-red-20',
            )}
          >
            <Play
              size={25}
              className={cn(
                'tw-text-secondary-100',
                focused && 'tw-text-primary',
                hasError && 'tw-text-red',
              )}
            />
          </div>
          <div className='tw-flex tw-flex-1 tw-flex-col'>
            <span className='tw-font-medium'>
              {isFirstAction ? 'Then do this' : 'And do this'}
            </span>
            <ActionTitle index={index} />
          </div>
          {actions?.length > 1 ? (
            <button
              className='tw-rounded tw-bg-secondary-20 tw-p-2'
              onClick={() => setRemoveModal(true)}
            >
              <TrashSimple size={25} className='tw-text-secondary' />
            </button>
          ) : null}
          <button className='tw-rounded tw-bg-primary-20 tw-p-2'>
            {focused ? (
              <CaretUp size={25} className='tw-text-primary' />
            ) : (
              <CaretDown size={25} className='tw-text-primary' />
            )}
          </button>
        </div>
      </Card>

      <div className='tw-bg-white'>
        {focused ? (
          <Controller
            name={`steps.${index}.actionType`}
            render={({
              field: { value: actionType, onChange },
              fieldState: { error },
            }) => (
              <>
                <div className='tw-h-0.5 tw-bg-secondary-20' />
                <div className='tw-flex tw-flex-col tw-gap-3 tw-p-4'>
                  <CustomSelect
                    hideInputIcon
                    hasError={!!error}
                    error={error}
                    options={triggerActionType}
                    placeholder='Select action'
                    onChange={(v) => {
                      onChange(v)
                      trigger(`steps.${index}`)
                    }}
                    value={actionType}
                    isClearable
                  />

                  {actionType ? (
                    <ActionMessageForm
                      actionType={actionType.value}
                      index={index}
                    />
                  ) : null}
                </div>
              </>
            )}
          />
        ) : null}
      </div>

      <RemoveStepModal
        onRemove={() => remove(index)}
        isOpen={removeModal}
        toggle={() => setRemoveModal((prevState) => !prevState)}
      />
    </div>
  )
}
