import { t } from 'i18next'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import UserFlagging from '../../../components/userFlag'
import { userTypes } from '../../../helpers/enum'
import { BurgerDetails } from '../../AdminPanel/pages/Users/users'
import { Avatar } from 'ui'

export default function UserCell({ isAdmin, item, onUpdate }) {
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [show, setShow] = useState(false)

  const user = useSelector((state) => state?.Account?.user)

  const secondPart =
    !!isAdmin || user?.type === userTypes.COMPANY
      ? item?.contractor
      : item?.client

  if (!secondPart) {
    if (isAdmin) return null

    return (
      <Link to={`/contract/detail?id=${item.ref}`} className='rp-font-bold'>
        {t('Invite')}
      </Link>
    )
  }

  const contractUser =
    user?.type === userTypes.CONTRACTOR && !isAdmin
      ? item?.company_name || secondPart?.email
      : (secondPart?.full_name ?? secondPart?.first_name)
        ? secondPart?.full_name
        : secondPart?.email

  const contractPhoto =
    user?.type === userTypes.CONTRACTOR && !isAdmin
      ? item?.company_logo
      : secondPart?.photo

  return (
    <div className='d-flex align-items-center gap-8'>
      <BurgerDetails
        id={selectedUserId}
        hide={() => setShow(false)}
        show={show}
      />

      <div className='position-relative d-flex align-items-center gap-8 rp-font-bold text-primary'>
        <Avatar
          name={contractUser}
          photo={contractPhoto}
          flag={
            (user?.type === userTypes.COMPANY || isAdmin) && secondPart?.flag
          }
        />

        <div
          className='text-truncate'
          style={{ fontWeight: 'inherit', maxWidth: '17ch' }}
          translate='no'
        >
          {contractUser}
        </div>

        {!isAdmin ? null : (
          <button
            className='rp-btn-nostyle text-primary px-0 text-truncate position-absolute w-100 h-100'
            onClick={() => {
              setSelectedUserId(item?.contractor?.id)
              setShow(true)
            }}
            translate='no'
          />
        )}
      </div>

      {(isAdmin && item?.contractor?.is_flagged === 1) ||
      item?.contractor?.is_flagged === true ? (
        <UserFlagging user={item?.contractor} onCompleteAction={onUpdate} />
      ) : null}
    </div>
  )
}
