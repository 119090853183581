import { t } from 'i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  ArrowsClockwise,
  Check,
  CornersOut,
  DownloadSimple,
  Eye,
  PencilSimpleLine,
  Plus,
  Receipt,
  ReceiptX,
  Spinner,
  UploadSimple,
} from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import toastr from 'toastr'
import * as yup from 'yup'

import { Badge, Button, Search, cn } from 'ui'
import ControlledDatePicker from '../../components/ControlledDatePicker'
import ControlledInput from '../../components/ControlledInput'
import ControlledSelect from '../../components/ControlledSelect'
import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import DataTable from '../../components/ui/data-table'
import IconButton from '../../components/ui/icon-button'
import Loader from '../../components/ui/loader'
import Pagination from '../../components/ui/pagination'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../components/ui/side-menu'
import { userTypes } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import { uploadTempFile } from '../../services/api'
import {
  createExternalInvoice,
  getExternalClientsList,
  getExternalInvoicesList,
  updateExternalInvoice,
} from '../../services/api-external-invoices'
import { getFullName } from '../../utils/get-full-name'
import { mapCurrencyToOption } from '../../utils/map-to-option'
import { useFilters } from '../AdminPanel/pages/cards/use-filters'
import { ConfirmFormField } from '../Contract/CreateContract/components/confirm-field'
import TabEmpty from '../Contract/components/tab/tab-empty'
import { AddNewClient } from '../new-external-invoice/add-new-client'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import openFileV2 from '../../utils/file/open-v2'

const statsType = {
  PAID: 'PAID',
  UNPAID: 'UNPAID',
  DRAFT: 'DRAFT',
}

const CTA_KEY = 'hideExternalInvoicesCTA'

function getStatusBadge(status) {
  switch (status) {
    case statsType.PAID:
      return (
        <Badge color='green' className='tw-capitalize'>
          {t('paid')}
        </Badge>
      )
    case statsType.UNPAID:
      return <Badge color='red'>{t('Unpaid')}</Badge>
    case statsType.DRAFT:
      return <Badge color='yellow'>{t('Draft')}</Badge>
    default:
      return ''
  }
}

export function ExternalInvoices() {
  // State variables
  const [isCTADismissed, setIsCTADismissed] = useState(false)
  const [filesToUpload, setFilesToUpload] = useState([])
  const [invoiceUpdate, setInvoiceUpdate] = useState({
    data: null,
    show: false,
  })

  const [invoiceDetails, setInvoiceDetails] = useState({
    data: null,
    show: false,
  })

  // Hooks
  const history = useHistory()
  const [filters, handleFiltersChange] = useFilters({
    page: 1,
    search_key: null,
    status: null,
  })

  const userType = useSelector((state) => state?.Account?.user?.type)

  useEffect(() => {
    if (userType !== userTypes.CONTRACTOR) {
      history.push('/invoices')
    }
  }, [history, userType])

  const { open } = useDropzone({
    accept: { 'application/pdf': ['.pdf'] },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 1) {
        setFilesToUpload(acceptedFiles)
      } else {
        uploadFile({
          file: acceptedFiles[0],
          type: 'external_invoices',
        })
        setInvoiceUpdate({
          show: true,
          data: {
            fileName: acceptedFiles[0].name,
            fileType: acceptedFiles[0].type,
          },
        })
      }
    },
  })

  // Fetch hooks
  const {
    data,
    isLoading,
    paginator,
    startFetch: refreshInvoices,
  } = useFetch(
    {
      action: getExternalInvoicesList,
      autoFetch: true,
      body: filters,
    },
    [filters],
  )

  const { data: clients, startFetch: refreshClients } = useFetch({
    action: getExternalClientsList,
    autoFetch: true,
  })

  const { startFetch: submitInvoice, isLoading: isSubmitting } = useFetch({
    action: invoiceUpdate?.data?.id
      ? updateExternalInvoice
      : createExternalInvoice,
    onComplete: () => {
      toastr.success(t('Invoice created successfully'))
      refreshInvoices(undefined, false)
      setInvoiceUpdate({
        show: false,
        data: null,
      })
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const {
    isLoading: isFileLoading,
    data: fileData,
    setData: setFileData,
    startFetch: uploadFile,
  } = useFetch({
    action: uploadTempFile,
    onComplete: (data, body) => {
      if (body.isMulti) {
        setFilesToUpload((prevFiles) => prevFiles.slice(1))
        submitInvoice({
          file: data.path,
          status: statsType.DRAFT,
          is_imported: true,
        })
      }
    },
  })

  // Effect hook
  useEffect(() => {
    // Effect for uploading files
    if (filesToUpload.length > 0) {
      uploadFile({
        file: filesToUpload[0],
        type: 'external_invoices',
        isMulti: true,
      })
    }
  }, [filesToUpload])

  // Memoized value
  const hideCTA = useMemo(() => localStorage.getItem(CTA_KEY), []) // Memoized value for CTA key

  // Function to dismiss CTA
  function dismissCTA() {
    localStorage.setItem(CTA_KEY, 'true')
    setIsCTADismissed(true)
  }

  // Columns for the table
  const columns = [
    {
      Header: t('Invoice', { count: 1 }),
      accessor: 'ref',
    },
    {
      Header: t('Client'),
      accessor: 'client',
      Cell: ({ cellData }) => (cellData ? getFullName(cellData) : null),
    },
    {
      Header: t('Issue Date'),
      accessor: 'invoice_date',
      Cell: ({ cellData }) =>
        cellData ? format(new Date(cellData), 'MM/dd/yyyy') : null,
    },
    {
      Header: t('Due date'),
      accessor: 'due_date',
      Cell: ({ cellData }) =>
        cellData ? format(new Date(cellData), 'MM/dd/yyyy') : null,
      headerClassName: 'tw-capitalize',
    },
    {
      Header: t('Amount'),
      accessor: 'total_amount',
      Cell: ({ cellData, rowData }) => {
        return getCurrencyFormatter(rowData?.currency?.code).format(cellData)
      },
    },
    {
      Header: t('Status'),
      accessor: 'status',
      Cell: ({ cellData }) => getStatusBadge(cellData),
    },
    {
      Cell: ({ rowData }) => (
        <div className='tw-flex tw-gap-2'>
          <Button
            color='transparent'
            textClassName='tw-flex tw-items-center tw-gap-2 tw-text-primary'
            onClick={() => {
              if (rowData?.status !== 'DRAFT') {
                setInvoiceDetails({
                  show: true,
                  data: rowData,
                })
              } else {
                setInvoiceUpdate({
                  show: true,
                  data: rowData,
                })
              }
            }}
          >
            <Eye size={20} />
            {t('Details')}
          </Button>
        </div>
      ),
    },
  ]

  const showPaginator = paginator?.first_page_url !== paginator?.last_page_url
  const notFiltered =
    !filters?.search_key &&
    !filters?.status &&
    !filters?.month &&
    filters?.page === 1

  return (
    <>
      <div className='tw-flex tw-flex-col tw-gap-4'>
        {/* CTA row */}
        {hideCTA || isCTADismissed ? null : (
          <div className='tw-flex tw-items-center tw-justify-between tw-rounded tw-bg-white tw-p-4'>
            <div className='tw-flex tw-items-center tw-gap-4'>
              <div className='tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-20 tw-text-primary'>
                <Receipt size={32} />
              </div>
              <div className='tw-flex tw-flex-col tw-gap-1'>
                <div className='tw-text-2xl tw-font-bold'>
                  {t('Centralize Financial data in one place')}
                </div>
                <div className='tw-text-text-80'>
                  {t(
                    'This is a free tool allowing you to invoice organizations that are not registered on the RemotePass platform',
                  )}
                </div>
              </div>
            </div>
            <div>
              <Button
                color='transparent'
                className='tw-border-surface-30'
                outline
                onClick={dismissCTA}
              >
                {t('Dismiss')}
              </Button>
            </div>
          </div>
        )}
        {/* Invoices Body */}
        {!data?.invoices?.length && notFiltered && !isLoading ? (
          <TabEmpty
            title={t('No Invoices to show')}
            subtitle={t('Invoices will be shown here')}
            icon={
              <Receipt color='var(--primary)' weight='duotone' size={250} />
            }
          >
            <div className='tw-mt-4 tw-flex tw-items-center tw-justify-center tw-gap-2'>
              <Button
                color='transparent'
                className='tw-border-surface-30'
                icon={<DownloadSimple size={20} />}
                outline
                onClick={() => {}}
              >
                {t('Import')}
              </Button>
              <Button
                color='primary'
                icon={<Plus color='white' size={20} />}
                textClassName='tw-flex tw-items-center tw-gap-2'
                onClick={() => {
                  history.push('/external-invoice/create')
                }}
              >
                {t('Generate Invoice')}
              </Button>
            </div>
          </TabEmpty>
        ) : (
          <div className='tw-flex tw-flex-col tw-gap-4'>
            <div className='tw-flex tw-flex-wrap tw-gap-4'>
              <StatCard
                total={data?.stats?.paid_total_amount}
                count={data?.stats?.paid_count}
                type={statsType.PAID}
              />

              <StatCard
                total={data?.stats?.unpaid_total_amount}
                count={data?.stats?.unpaid_count}
                type={statsType.UNPAID}
              />

              <StatCard
                total={t('Incomplete invoices')}
                count={data?.stats?.draft_count}
                type={statsType.DRAFT}
              />
            </div>
            <div className='tw-rounded tw-bg-white tw-p-6'>
              <div className='tw-flex tw-flex-wrap tw-items-center tw-gap-2'>
                <CustomSelect
                  placeholder={t('Month')}
                  aria-label={t('Month')}
                  wrapperClassName='tw-min-w-[215px]'
                  options={[
                    { label: t('All'), value: undefined },
                    ...(data?.months || []),
                  ]}
                  onChange={(value) =>
                    handleFiltersChange('month', value?.value)
                  }
                />

                <CustomSelect
                  placeholder={t('Status')}
                  aria-label={t('Status')}
                  wrapperClassName='tw-min-w-[215px]'
                  onChange={(value) =>
                    handleFiltersChange('status', value?.value)
                  }
                  isClearable
                  options={[
                    { label: t('Paid'), value: statsType.PAID },
                    { label: t('Unpaid'), value: statsType.UNPAID },
                    { label: t('Draft'), value: statsType.DRAFT },
                  ]}
                />

                <Search
                  placeholder={t('Search')}
                  wrapperClassName='tw-grow tw-shrink-0'
                  aria-label='search'
                  query={filters?.search_key}
                  onQueryChanged={(query) =>
                    handleFiltersChange('search_key', query)
                  }
                />

                <Button
                  color='transparent'
                  className='tw-h-11 tw-border-surface-30'
                  textClassName='tw-flex tw-items-center tw-gap-2'
                  outline
                  onClick={() => {
                    open()
                  }}
                >
                  <UploadSimple size={20} />
                  {t('Import')}
                </Button>
                <Button
                  color='primary'
                  textClassName='tw-flex tw-items-center tw-gap-2'
                  icon={<Plus color='white' size={20} />}
                  className='tw-h-11'
                  onClick={() => {
                    history.push('/external-invoice/create')
                  }}
                >
                  {t('Generate Invoice')}
                </Button>
              </div>
              <div className='tw-mt-8'>
                {isLoading ? (
                  <div className='tw-flex tw-min-h-60 tw-items-center tw-justify-center tw-rounded tw-bg-white'>
                    <Loader />
                  </div>
                ) : data?.invoices?.length ? (
                  <DataTable
                    data={data?.invoices}
                    columns={columns}
                    responsive
                  />
                ) : (
                  <div className='tw-flex tw-items-center tw-justify-center tw-p-10'>
                    {t('No invoices found')}
                  </div>
                )}
              </div>

              {!showPaginator ? null : (
                <div className='tw-mt-8 tw-flex tw-justify-end'>
                  <Pagination
                    activePage={filters?.page}
                    itemsCountPerPage={paginator?.per_page ?? 10}
                    totalItemsCount={paginator?.total ?? 0}
                    onChange={(newPage) => handleFiltersChange('page', newPage)}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {invoiceUpdate.show && (
        <InvoiceUpdatePanel
          show={invoiceUpdate.show}
          invoiceData={invoiceUpdate.data}
          uploadFile={uploadFile}
          fileData={fileData}
          clients={clients}
          refreshClients={refreshClients}
          isSubmitting={isSubmitting}
          submitInvoice={submitInvoice}
          isFileLoading={isFileLoading}
          toggle={() => {
            setFileData(null)
            setInvoiceUpdate({
              show: false,
              data: null,
            })
          }}
        />
      )}
      {invoiceDetails.show && (
        <InvoiceDetailsPanel
          show={invoiceDetails.show}
          invoiceData={invoiceDetails.data}
          refreshInvoices={refreshInvoices}
          toggle={() => {
            setFileData(null)
            setInvoiceDetails({
              show: false,
              data: null,
            })
          }}
        />
      )}
    </>
  )
}

function StatCard({ type, count, total }) {
  return (
    <div
      className={cn(
        'tw-flex tw-flex-1 tw-items-center tw-justify-between tw-rounded tw-border-l-2 tw-bg-white tw-p-4',
        {
          'tw-border-systemGreen': type === statsType.PAID,
          'tw-border-systemRed': type === statsType.UNPAID,
          'tw-border-systemGold': type === statsType.DRAFT,
        },
      )}
    >
      <div className='tw-flex tw-items-center tw-gap-4'>
        <div
          className={cn(
            'tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded',
            {
              'tw-bg-systemGreen-10 tw-text-systemGreen':
                type === statsType.PAID,
              'tw-bg-systemRed-10 tw-text-systemRed': type === statsType.UNPAID,
              'tw-bg-systemGold-10 tw-text-systemGold':
                type === statsType.DRAFT,
            },
          )}
        >
          {type === statsType.PAID ? (
            <Receipt size={24} />
          ) : type === statsType.UNPAID ? (
            <ReceiptX size={24} />
          ) : (
            <PencilSimpleLine size={24} />
          )}
        </div>
        <div>
          <div className='tw-text-sm tw-font-bold'>
            {type === statsType.PAID
              ? t('Paid')
              : type === statsType.UNPAID
                ? t('Unpaid')
                : t('Draft')}
          </div>
          <div className='tw-text-text-80'>{total}</div>
        </div>
      </div>
      {count ? (
        <div className='tw-lowercase tw-text-text-80'>
          {`${count} ${t('Invoice', { count })}`}
        </div>
      ) : (
        <div className='tw-text-text-80'>{t('No invoices')}</div>
      )}
    </div>
  )
}

const schema = yup.object().shape({
  ref: yup.string().required('Invoice number is required'),
  total_amount: yup
    .number()
    .min(0, 'Minimum amount is 0')
    .required('Total amount is required')
    .typeError('Total amount is required'),
  vat: yup.number().min(0, 'Minimum vat is 0').nullable().typeError('Invalid'),
  status: yup
    .string()
    .oneOf(['PAID', 'UNPAID'], 'Payment status is required')
    .required('Payment status is required'),
  paid_at: yup.string().when('status', {
    is: 'PAID',
    then: (schema) => schema.required('Paid date is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  file: yup.string(),
  external_invoice_client_id: yup.string().required('Client is required'),
  due_date: yup.string().required('Due date is required'),
  invoice_date: yup.string().required('Issue date is required'),
  currency_id: yup.number().required('Currency is required'),
})

function InvoiceUpdatePanel({
  toggle,
  show,
  fileData,
  uploadFile,
  invoiceData,
  isFileLoading,
  clients,
  refreshClients,
  submitInvoice,
  isSubmitting,
}) {
  // Selectors
  const currencies = useSelector(
    (state) => state.Layout?.staticData?.currencies ?? [],
  )

  // Memoized values
  const clientsOptions = useMemo(
    () =>
      clients?.map((client) => ({
        value: client.id,
        label: getFullName(client),
      })),
    [clients],
  )

  const currency = useMemo(
    () => currencies.find((c) => (c.code = invoiceData?.currency?.code)),
    [invoiceData?.currency, currencies],
  )

  // Dropzone hook
  const { open } = useDropzone({
    multiple: false,
    accept: { 'application/pdf': ['.pdf'] },
    onDrop: (acceptedFiles) => {
      uploadFile({
        file: acceptedFiles[0],
        type: 'external_invoices',
      })
    },
  })

  // Form hook
  const {
    control,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      ref: invoiceData?.ref,
      total_amount: invoiceData?.total_amount,
      vat: invoiceData?.vat,
      status: invoiceData?.status,
      paid_at: invoiceData?.paid_at,
      file: fileData?.path,
      external_invoice_client_id: invoiceData?.client?.id,
      due_date: invoiceData?.due_date
        ? format(new Date(invoiceData.due_date), 'yyyy-MM-dd')
        : null,
      invoice_date: invoiceData?.invoice_date
        ? format(new Date(invoiceData.invoice_date), 'yyyy-MM-dd')
        : null,
      currency_id: currency?.id,
    },
    resolver: yupResolver(schema),
  })

  // File path
  const filePath = fileData?.full_path ?? invoiceData?.file

  // Event handlers
  function handleViewFile(ev) {
    ev.stopPropagation()
    window.open(fileData?.full_path, '_blank')
  }

  function handleChangeFile(ev) {
    ev.stopPropagation()
    open()
  }

  function handleValidSubmit(data) {
    data.file = fileData?.path
    data.id = invoiceData?.id
    data.vat = data?.vat || 0
    data.sub_amount = data.total_amount / (1 + data.vat / 100)
    if (!data.paid_at) {
      delete data.paid_at
    }
    submitInvoice(data)
  }
  return (
    <SideMenu
      onClose={toggle}
      isOpen={show}
      className='!tw-w-full tw-max-w-[480px]'
      itemListClassName='tw-grid tw-grid-rows-[77px_1fr_91px] [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain'
    >
      <form onSubmit={handleSubmit(handleValidSubmit)}>
        <SideMenuHeader toggle={toggle}>{t('Invoice details')}</SideMenuHeader>
        <SideMenuBody>
          <div>
            {isFileLoading ? (
              <div className='tw-flex tw-items-center tw-justify-between tw-rounded tw-border tw-border-surface-30 tw-bg-primary-10 tw-p-6'>
                <div>
                  <div className='tw-text-base tw-font-bold'>
                    {t('Invoice', { count: 1 })}
                  </div>
                  <div className='tw-text-text-80'>{invoiceData?.fileName}</div>
                </div>
                <div className='tw-flex tw-items-center tw-gap-2 tw-font-semibold tw-text-primary'>
                  <Spinner
                    className='tw-animate-spin tw-duration-300'
                    size={20}
                  />{' '}
                  <span>{t('Uploading')}</span>
                </div>
              </div>
            ) : filePath ? (
              <div className='tw-relative tw-flex tw-min-h-96 tw-justify-center tw-rounded tw-bg-surface-20 tw-p-6'>
                <div className='tw-absolute tw-bottom-0 tw-left-0 tw-z-50 tw-flex tw-w-full tw-items-center tw-justify-between tw-p-6'>
                  <IconButton
                    color='none'
                    size={48}
                    onClick={handleChangeFile}
                    className='!tw-border !tw-border-surface-30 !tw-bg-white'
                    icon={<ArrowsClockwise size={20} />}
                  />

                  <IconButton
                    color='none'
                    size={48}
                    onClick={handleViewFile}
                    className='!tw-border !tw-border-surface-30 !tw-bg-white'
                    icon={<CornersOut size={20} />}
                  />
                </div>
                <iframe
                  src={filePath + '#toolbar=0&navpanes=0&scrollbar=0'}
                  height='auto'
                />
              </div>
            ) : null}
          </div>
          {isFileLoading || filePath ? <hr className='tw-my-8' /> : null}
          <div className='tw-mb-4 tw-text-base tw-font-bold'>
            {t('Invoice info')}
          </div>
          <div className='tw-flex tw-flex-col tw-gap-4'>
            <ControlledInput
              control={control}
              label={t('Invoice Number')}
              name='ref'
              placeholder={t('Invoice Number')}
            />

            <ControlledDatePicker
              name='invoice_date'
              label={t('Issue Date')}
              transform={{
                output: (val) => {
                  setValue('due_date', null)
                  return format(new Date(val), 'yyyy-MM-dd')
                },
              }}
              control={control}
              placeholder={t('Issue Date')}
            />

            <ControlledDatePicker
              name='due_date'
              label={t('Due date')}
              minDate={new Date(watch('invoice_date'))}
              control={control}
              placeholder={t('Due date')}
            />

            <ControlledSelect
              label={t('Currency')}
              placeholder={t('Currency')}
              control={control}
              name='currency_id'
              options={currencies.map((c) => mapCurrencyToOption(c, 'id'))}
            />

            <ControlledInput
              control={control}
              label={t('Total Amount')}
              name='total_amount'
              type='number'
              placeholder={t('Total Amount')}
            />

            <ControlledInput
              control={control}
              label={t('VAT amount')}
              name='vat'
              type='number'
              placeholder={t('VAT amount')}
            />

            <ConfirmFormField
              control={control}
              error={errors.status}
              name='status'
              title={t('Payment status')}
              description={t('Is this invoice paid or unpaid')}
              fieldOptions={[
                { label: t('Paid'), value: 'PAID' },
                { label: t('Unpaid'), value: 'UNPAID' },
              ]}
              itemClassName='tw-p-4'
              className='md:tw-col-span-2'
            />

            {watch('status') === 'PAID' && (
              <ControlledDatePicker
                name='paid_at'
                label={t('Paid on')}
                control={control}
                placeholder={t('Paid on')}
              />
            )}
            <hr className='tw-my-8' />
            <div className='tw-mb-4 tw-flex tw-items-center tw-justify-between tw-text-base tw-font-bold'>
              <span>{t('Client info')}</span>
              <AddNewClient
                btnProps={{
                  className: '!tw-px-0',
                }}
                onCreateSuccess={() => {
                  refreshClients()
                }}
              />
            </div>
          </div>
          <ControlledSelect
            options={clientsOptions}
            control={control}
            name='external_invoice_client_id'
            label={t('Client')}
            placeholder={t('Client')}
          />
        </SideMenuBody>

        <SideMenuFooter>
          <Button
            loading={isSubmitting}
            isDisabled={isSubmitting}
            type='submit'
            color='primary'
            className='tw-w-full'
          >
            {t('Save')}
          </Button>
        </SideMenuFooter>
      </form>
    </SideMenu>
  )
}

function InvoiceDetailsPanel({ show, toggle, invoiceData, refreshInvoices }) {
  const formatter = getCurrencyFormatter(invoiceData?.currency?.code)

  const { startFetch: markAsPaid, isLoading } = useFetch({
    action: updateExternalInvoice,
    body: {
      id: invoiceData?.id,
      status: 'PAID',
    },
    onComplete: () => {
      toggle()
      refreshInvoices?.()
    },
  })
  return (
    <SideMenu
      onClose={toggle}
      isOpen={show}
      className='!tw-w-full tw-max-w-[480px]'
      itemListClassName='tw-grid tw-grid-rows-[77px_1fr_91px] [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain'
    >
      <SideMenuHeader toggle={toggle}>
        <div>
          <div>{invoiceData?.ref}</div>
          <div className='tw-text-sm tw-text-text-60'>
            {t('Invoice', { count: 1 })}
          </div>
        </div>
      </SideMenuHeader>
      <SideMenuBody>
        <InvoiceDetailRow
          title={t('Status')}
          value={getStatusBadge(invoiceData?.status)}
        />

        <InvoiceDetailRow
          title={t('Amount')}
          value={formatter.format(invoiceData?.total_amount)}
        />

        <InvoiceDetailRow
          title={t('Issue Date')}
          value={format(
            invoiceData?.invoice_date
              ? new Date(invoiceData?.invoice_date)
              : null,
            'MM/dd/yyyy',
          )}
        />

        <InvoiceDetailRow
          title={t('Due date')}
          value={format(
            invoiceData?.due_date ? new Date(invoiceData?.due_date) : null,
            'MM/dd/yyyy',
          )}
        />

        <InvoiceDetailRow
          title={t('Invoice', { count: 1 })}
          value={
            <Button
              color='transparent'
              textClassName='tw-text-primary'
              className='!tw-p-0'
              onClick={() => {
                openFileV2(invoiceData?.file, {
                  isDataUrl: true,
                })
              }}
              iconRight={<DownloadSimple color='var(--primary)' size={20} />}
            >
              {t('Download')}
            </Button>
          }
        />
      </SideMenuBody>
      {invoiceData?.status === 'UNPAID' && (
        <SideMenuFooter>
          <Button
            onClick={() => {
              markAsPaid()
            }}
            loading={isLoading}
            isDisabled={isLoading}
            block
            icon={<Check size={20} color='var(--primary)' />}
          >
            {t('Mark As Paid')}
          </Button>
        </SideMenuFooter>
      )}
    </SideMenu>
  )
}

function InvoiceDetailRow({ title, value }) {
  return (
    <div className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-surface-30 tw-py-4'>
      <div className='tw-text-text-100'>{title}</div>
      <div className='tw-font-semibold tw-text-black'>{value}</div>
    </div>
  )
}

export default InvoiceDetailRow
