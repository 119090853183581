import { t } from 'i18next'
import { TrashSimple } from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Row, Tooltip } from 'reactstrap'
import toastr from 'toastr'

import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import Toggle from '../../../components/Forms/Toggle/Toggle'
import StyledTd from '../../../components/Table/StyledTd'
import StyledTh from '../../../components/Table/StyledTh'
import TableComp from '../../../components/Table/TableComp'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import Button from '../../../components/ui/button'
import { useFetch, useResize } from '../../../helpers/hooks'
import {
  addRecurringExpense,
  deleteRecurringExpense,
  updateRecurringExpense,
} from '../../../services/api'
import {
  SettingSectionHeading,
  SettingSectionSubHeading,
} from '../../CompanySetting/components/settings-section-heading'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import ExpenseModal from '../components/ExpensesModal'
import useRecurringExpenses from '../hooks/use-recurring-expenses'
import { track } from '../../../utils/analytics'

export default function RecurringExpenses({ isAdmin, canManage }) {
  const contract = useSelector((state) => state.Contract?.details)
  const [show, setShow] = useState(false)

  const list = useRecurringExpenses({ isAdmin, contract })
  const user = useSelector((state) => state.Account?.user)

  const create = useFetch({
    action: addRecurringExpense,
    onError: toastr.error,
    onComplete: (data, body) => {
      list.startFetch({ contract_id: contract?.id })
      setShow(false)
      track('Expense submitted', {
        id: user.id,
        expense_id: data.id,
        contract_id: body.contract_id,
        category: data.category.name,
        amount: data.amount,
        currency: data.currency.name,
        description: data.name,
      })
    },
  })

  const isMobile = useResize()

  const addBtnDisabled = !canManage

  return (
    <div className='py-4 tw-border-b tw-border-surface-30'>
      <div className='tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-4 tw-px-6'>
        <div>
          <SettingSectionHeading>
            {t('Recurring expenses')}
          </SettingSectionHeading>

          <SettingSectionSubHeading className='tw-mb-0'>
            {t('Set up and manage recurring expenses for this contract')}
          </SettingSectionSubHeading>
        </div>

        <PermissionTooltip
          showing={addBtnDisabled}
          id='add-new-recurring-expense-btn'
          area={PERMISSION_GROUP.CONTRACT_SETTINGS.name}
        >
          <Button
            onClick={() => setShow(true)}
            color='link'
            className='px-0'
            disabled={addBtnDisabled}
          >
            {t('Add New')}
          </Button>
        </PermissionTooltip>
      </div>

      {!!list.data?.length && !isMobile && (
        <TableComp className='mt-3 mt-md-4'>
          <thead className='thead-light'>
            <tr
              style={{
                borderTop: 'hidden',
                borderBottom: '1px solid #E7E8F2',
              }}
            >
              <StyledTh>{t('Expense')}</StyledTh>
              <StyledTh>{t('Category')}</StyledTh>
              <StyledTh>{t('Start Date')}</StyledTh>
              <StyledTh>{t('Occurrence')}</StyledTh>
              <StyledTh>{t('Amount')}</StyledTh>
              <StyledTh>{t('Status')}</StyledTh>
            </tr>
          </thead>
          <tbody>
            {list.data?.map((item, key) => {
              return (
                <RecurringExpLine
                  key={'_order_' + key}
                  item={item}
                  isAdmin={isAdmin}
                  onUpdate={() =>
                    list.startFetch({ contract_id: contract?.id })
                  }
                />
              )
            })}
          </tbody>
        </TableComp>
      )}
      {!!list.data?.length && isMobile && (
        <div className='px-4 w-100 mt-4'>
          {list.data?.map((item) => {
            return (
              <RecurringExpLine
                key={item.id}
                item={item}
                onUpdate={() => list.startFetch({ contract_id: contract?.id })}
              />
            )
          })}
        </div>
      )}

      {show && (
        <ExpenseModal
          isRecurring
          show={show}
          hide={() => setShow(false)}
          actionsLoading={create.isLoading}
          onSubmit={(v) => {
            create.startFetch({
              ...v,
              contract_id: contract?.id,
            })
          }}
        />
      )}
    </div>
  )
}

function RecurringExpLine({ item, onUpdate, isAdmin }) {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const isMobile = useResize()
  const remove = useFetch({
    action: deleteRecurringExpense,
    onError: toastr.error,
    onComplete: onUpdate,
  })
  const update = useFetch({
    action: updateRecurringExpense,
    onError: toastr.error,
    onComplete: () => {
      setShowConfirmation(false)
      onUpdate()
    },
  })
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: item?.currency?.code || 'USD',
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      if (tooltipOpen) {
        setTooltipOpen(false)
      }
    }, 3000)
    return () => clearTimeout(timer)
  }, [tooltipOpen])

  const isInsuranceAllowance = item?.type === RECURRING_EXPENSE_TYPE.allowance

  function onChangeStatus() {
    if (isInsuranceAllowance) {
      setTooltipOpen((isOpen) => !isOpen)
    } else {
      setShowConfirmation(true)
    }
  }

  const tooltipBaseId = 'tooltip-' + item?.id
  const isToggleDisabled = isAdmin || item?.can_enable === false

  const toggleElement = (
    <>
      <Toggle
        readOnly={isInsuranceAllowance}
        disabled={isToggleDisabled}
        check={item?.status === 'active'}
        change={onChangeStatus}
        name={item?.name}
        id={tooltipBaseId}
      />

      {!isInsuranceAllowance ? null : (
        <Tooltip isOpen={tooltipOpen} target={tooltipBaseId} placement='bottom'>
          {t('Go to the Allowance tab to manage this expense.')}
        </Tooltip>
      )}
    </>
  )

  return (
    <>
      {isMobile ? (
        <div className='bg-white border border-secondary-30 rounded mb-3'>
          <div className='p-3 border-bottom d-flex justify-content-between align-items-center w-100'>
            <div className='w-100'>
              <Row className='p-0 m-0 justify-content-between align-items-center'>
                <label className='align-items-center d-flex mb-0 cursor-pointer'>
                  {toggleElement}
                  <span>
                    {t('Expense is')}{' '}
                    {item?.status === 'active' ? 'active' : 'inactive'}
                  </span>
                </label>

                {item.can_delete && (
                  <Button
                    type='button'
                    icon={<TrashSimple size={16} weight='duotone' />}
                    size='sm'
                    color='danger'
                    outline
                    className='!tw-border-none !tw-p-1'
                    aria-label='Delete expense'
                    onClick={() => {
                      remove.startFetch({ recurring_expense_id: item?.id })
                    }}
                    loading={remove.isLoading}
                  />
                )}
              </Row>
            </div>
          </div>
          <div className='p-3'>
            <div className='mb-3 d-flex justify-content-between align-items-center'>
              <p className='mb-0 font-size-14 font-weight-light'>
                {t('Expense')}
              </p>
              <p className='mb-0 font-size-14 font-weight-light'>
                {item?.name}
              </p>
            </div>
            <div className='mb-3 d-flex justify-content-between align-items-center'>
              <p className='mb-0 font-size-14 font-weight-light'>
                {t('Occurrence')}
              </p>
              <p className='mb-0 font-size-14 font-weight-light'>
                {item?.occurrence?.name}
              </p>
            </div>
            <div className='mb-3 d-flex justify-content-between align-items-center'>
              <p className='mb-0 font-size-14 font-weight-light'>
                {t('Start Date')}
              </p>
              <p className='mb-0 font-size-14 font-weight-light'>
                {format(new Date(item?.start_date * 1000), 'dd/MM/yyyy')}
              </p>
            </div>
            <div className='mb-3 d-flex justify-content-between align-items-center'>
              <p className='mb-0 font-size-14 font-weight-light'>
                {t('Amount')}
              </p>
              <p className='mb-0 font-size-14 font-weight-light'>
                {formatter.format(item?.amount)}
              </p>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
              <p className='mb-0 font-size-14 font-weight-light'>
                {t('Category')}
              </p>
              <p className='mb-0 font-size-14 font-weight-light'>
                {item?.category?.name}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <tr style={{ borderBottom: '1px solid #E7E8F2' }}>
          <StyledTd>{item?.name}</StyledTd>
          <StyledTd>{item?.category?.name}</StyledTd>
          <StyledTd>
            {format(new Date(item?.start_date * 1000), 'dd/MM/yyyy')}
          </StyledTd>
          <StyledTd>{item?.occurrence?.name}</StyledTd>
          <StyledTd>{formatter.format(item?.amount)}</StyledTd>
          <StyledTd>{toggleElement}</StyledTd>
          <StyledTd>
            {item.can_delete && (
              <Button
                type='button'
                icon={<TrashSimple size={16} weight='duotone' />}
                size='sm'
                color='danger'
                outline
                className='!tw-border-none !tw-p-1'
                aria-label='Delete expense'
                onClick={() => {
                  remove.startFetch({ recurring_expense_id: item?.id })
                }}
                loading={remove.isLoading}
              />
            )}
          </StyledTd>
        </tr>
      )}

      <ConfirmationModal
        isOpen={showConfirmation}
        loading={update.isLoading}
        onConfirm={() => {
          update.startFetch({
            recurring_expense_id: item?.id,
            status: item?.status === 'active' ? 'inactive' : 'active',
          })
        }}
        toggle={() => setShowConfirmation(false)}
        message={
          item?.status === 'active' ? (
            <>
              <p>
                {t('Are you sure you want to disable this recurring expense?')}
              </p>
              <p>
                {t(
                  'If you disable, future expenses will not be created and current unpaid or processing expenses will not be impacted.',
                )}
              </p>
            </>
          ) : (
            <>
              <p>
                {t('Are you sure you want to enable this recurring expense?')}
              </p>
              <p>
                {t(
                  'If you enable, future expenses will be created automatically according to the schedule set',
                )}
              </p>
            </>
          )
        }
        title={t('Recurring Expense')}
        caption={item?.status === 'active' ? 'Disable' : 'Enable'}
        buttonColor={item?.status === 'active' ? 'danger' : 'primary'}
      />
    </>
  )
}

const RECURRING_EXPENSE_TYPE = {
  allowance: 'allowance',
  recurring: 'recurring',
}
