import { AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CardBody, Col, Container, FormGroup, Row } from 'reactstrap'
import toastr from 'toastr'

import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import TextInput from '../../components/Forms/TextInput'
import CustomPhoneInput from '../../components/Forms/phoneInput/CustomPhoneInput'
import Alert from '../../components/ui/alert'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import { useFetch } from '../../helpers/hooks'
import { areNamesLatin } from '../../helpers/info-latin-regex'
import { getClientInfo, updateClientInfo } from '../../services/api'
import { updateUserInfo } from '../../store/auth/register/actions'
import { updateUserProfileInfo } from '../../store/profile/actions'
import { mapCountryToOption } from '../../utils/map-to-option'
import { t } from 'i18next'

const ProfileInfo = React.forwardRef(function ProfileInfo({ clientInfo }, ref) {
  const [formData, setFormData] = useState({})

  const user = useSelector((state) => state?.Account?.user)
  const userProfile = useSelector(
    (state) => state?.userProfile?.userProfile ?? {},
  )
  const dispatch = useDispatch()

  useEffect(() => {
    setFormData((data) => ({
      ...data,
      phone: userProfile?.phone,
      country_id: userProfile?.country?.id,
    }))
  }, [userProfile])
  const { isLoading: gettingClientInfo, startFetch: refetchClientInfo } =
    useFetch({
      action: getClientInfo,
      autoFetch: false,
      onComplete: (data) => {
        dispatch(updateUserProfileInfo(data))
        window.analytics.track('Viewed profile', {
          email_id: user?.email,
        })
      },
    })
  const { startFetch: updateInfo, isLoading: updatingInfo } = useFetch({
    action: updateClientInfo,
    onComplete: (data) => {
      if (data.success !== false) {
        refetchClientInfo()
        window.analytics.track('Updated profile', {
          email_id: user?.email,
        })

        dispatch(updateUserInfo(data || {}))
        toastr.success('General information submitted.')
      }
    },
    onError: () => {
      toastr.error('Failed to updated the information')
    },
  })

  const countries = useSelector(
    (state) => state.Layout?.staticData?.countries ?? [],
  )

  if (gettingClientInfo) {
    return <Loader minHeight='406px' />
  }

  return (
    <div>
      <CardBody className='p-0'>
        {userProfile?.is_company_creator && !userProfile?.kyc_verified && (
          <Col sm={12} className='mb-3 mt-3'>
            <Alert color='info'>
              {t(
                'Spell your name exactly as it’s shown on your passport or ID card. After you’ve been verified, you can’t edit some of the fields',
              )}
            </Alert>
          </Col>
        )}
        <AvForm
          ref={ref}
          onValidSubmit={(e, values) => {
            const {
              first_name: firstName,
              last_name: lastName,
              middle_name: middleName,
            } = values
            const names = [firstName, lastName, middleName]
            const namesAreLatin = areNamesLatin(names)

            const error = validateChecks([
              [namesAreLatin, t('Your name should only contain latin letters')],
              [
                formData.phone?.length >= 4,
                t('Please enter a valid phone number'),
              ],

              [firstName, t('First name is required')],
              [lastName, t('Last name is required')],
              [formData.country_id, t('Country is required')],
            ])

            if (error) {
              toastr.error(error)
            } else {
              const dataInfo = {
                ...values,
                ...formData,
              }
              if (clientInfo?.photo && !clientInfo?.photo?.startsWith('http')) {
                dataInfo.photo = clientInfo?.photo
              }

              updateInfo(dataInfo)
            }
          }}
        >
          <fieldset disabled={updatingInfo}>
            <Container fluid className='py-4'>
              <Row className='px-2.5'>
                <Col xs={12} md={6} className='px-2.5'>
                  <FormGroup>
                    <TextInput
                      type='text'
                      name='first_name'
                      value={clientInfo?.first_name}
                      label={t('First Name')}
                      required
                      disabled={userProfile?.kyc_verified === 1}
                      translate='no'
                      validate={{
                        required: {
                          value: true,
                          errorMessage: t('First Name is required'),
                        },
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} className='px-2.5'>
                  <FormGroup>
                    <TextInput
                      type='text'
                      name='middle_name'
                      value={clientInfo?.middle_name}
                      label={t('Middle Name')}
                      translate='no'
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} className='px-2.5'>
                  <FormGroup>
                    <TextInput
                      value={clientInfo?.last_name}
                      name='last_name'
                      type='text'
                      label={t('Last Name')}
                      required
                      disabled={userProfile?.kyc_verified === 1}
                      translate='no'
                      validate={{
                        required: {
                          value: true,
                          errorMessage: t('Last Name is required'),
                        },
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} className='px-2.5'>
                  <FormGroup>
                    <CustomPhoneInput
                      label={t('Phone Number')}
                      required
                      name='phone'
                      value={clientInfo?.phone || ''}
                      country='ae'
                      isValid={formData.phone?.length >= 4}
                      disabled={userProfile.kyc_verified === 1}
                      enableAreaCodes
                      onChange={(phone, isValid) =>
                        setFormData((data) => ({
                          ...data,
                          phone,
                          isPhoneValid: isValid,
                        }))
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} className='px-2.5'>
                  <FormGroup>
                    <TextInput
                      type='text'
                      name='job_title'
                      value={clientInfo?.job_title}
                      label={t('Job title')}
                      placeholder={t('Job title')}
                      translate='no'
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} className='px-2.5'>
                  <FormGroup>
                    <CustomSelect
                      name='country_id'
                      required
                      defaultValue={
                        !clientInfo?.country
                          ? null
                          : mapCountryToOption(clientInfo?.country)
                      }
                      label={t('Country')}
                      placeholder={t('Country')}
                      options={countries.map((c) => mapCountryToOption(c))}
                      onChange={(country) =>
                        setFormData((data) => ({
                          ...data,
                          country_id: country?.id,
                        }))
                      }
                      isDisabled={
                        updatingInfo || userProfile.kyc_verified === 1
                      }
                      validate={{
                        required: {
                          value: true,
                          errorMessage: t('Country is required'),
                        },
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Container>

            <div className='border-top divider w-100' />

            <Row className='p-md-4 p-3 m-0'>
              <Button
                onClick={() => ref.current.submit()}
                loading={gettingClientInfo || updatingInfo}
                disabled={gettingClientInfo || updatingInfo}
              >
                {t('Save')}
              </Button>
            </Row>
          </fieldset>
        </AvForm>
      </CardBody>
    </div>
  )
})

export default ProfileInfo

export function validateChecks(arr) {
  if (!Array.isArray(arr) || arr.length === 0 || arr.every((a) => !!a[0])) {
    return null
  }

  const falseCheck = arr.filter(Boolean).find((a) => !a[0])
  const falseCheckError = falseCheck[1]

  return falseCheckError ?? false
}
