import { AvForm, AvInput } from 'availity-reactstrap-validation'
import cx from 'classnames'
import { format } from 'date-fns'
import { t } from 'i18next'
import moment from 'moment'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  Col,
  Container,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import toastr from 'toastr'

import ControlledSelect from '../../../../components/ControlledSelect'
import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import RelatedFieldContent from '../../../../components/related-field-content'
import Alert from '../../../../components/ui/alert'
import Shimmer from '../../../../components/ui/shimmer'
import { CONTRACT_TYPES } from '../../../../core/config/contract-types'
import { useFetch, useResize } from '../../../../helpers/hooks'
import { getCycleText, getOccurrences } from '../../../../services/api'
import { mapCurrencyToOption } from '../../../../utils/map-to-option'
import {
  PRORATA_CALCULATION_TYPE,
  PRORATA_TYPE,
  WORKING_DAYS,
} from '../../CreateContract/components/prorata/constants'
import { ProrataStateProvider } from '../../CreateContract/components/prorata/prorata-context'
import { ProrataSettings } from '../../CreateContract/components/prorata/prorata-settings'
import { useProrataEdit } from '../../CreateContract/components/prorata/use-prorata'
import {
  CycleStartEndText,
  fieldKeys,
  useProRataUpdate,
} from '../../CreateContract/create-contract-v3'
import useRecurringExpenses from '../../hooks/use-recurring-expenses'
import styles from './payment-form.module.scss'
import { customOption, customSingleValue } from './select-components'

export function getCurrencyFromTaxCountry(currencies = [], currencyCode) {
  return currencies.find((c) => c.code === currencyCode)
}

function getErrors(checks = []) {
  if (checks.length === 0) {
    return true
  }

  return checks.filter(({ check }) => check).map(({ error }) => error)
}

const currencyInputAttrs = {
  autoComplete: 'off',
  allowDecimals: true,
  decimalsLimit: 2,
  decimalSeparator: '.',
  groupSeparator: ',',
}

const PaymentForm = forwardRef(function PaymentForm(
  {
    data,
    duplicatingData,
    onSubmit,
    isAmend,
    amendData,
    setTempData,
    tempData,
    isAdmin,
  },
  ref,
) {
  const occurrences = useFetch({ action: getOccurrences })

  const toDuplicate = useSelector((state) => state.Contract.toDuplicate)
  const isMobile = useResize()
  const firstPaymentMoment = moment(
    amendData?.first_payment_date || data?.first_payment_date,
    'YYYY-MM-DD',
  )
  const defaultStartDate =
    amendData?.start_date ||
    (toDuplicate ? new Date() : (data?.start_date ?? new Date()))

  const startDateMoment = moment(defaultStartDate)
  const endDateMoment =
    !amendData?.end_date && !data?.end_date
      ? null
      : moment(amendData?.end_date || data?.end_date)

  const staticData = useSelector((state) => state.Layout.staticData)
  const savedFrequency = data?.frequency
    ? { value: data.frequency.id, label: data.frequency?.name }
    : staticData?.frequencies
        ?.map((e) => ({ value: e.id, label: e.name }))
        .find((elm) => elm.value === data?.frequency_id)

  const savedCurrency = data?.currency
    ? mapCurrencyToOption(data.currency, 'id')
    : staticData?.currencies
        ?.filter((o) => o.id === data?.currency_id)
        ?.map((c) => mapCurrencyToOption(c, 'id'))
        .find((elm) => elm.value === data?.currency_id)

  const [frequencyId, setFrequencyId] = useState(
    amendData?.frequency_id || data?.frequency_id || data?.frequency
      ? savedFrequency
      : null,
  )

  const [currency, setCurrency] = useState(getDefaultCurrency())

  function getDefaultCurrency() {
    if (tempData?.currencyChangedManually && data?.currency) {
      return data?.currency
    }

    if (amendData?.currency_id || data?.currency_id || data?.currency) {
      return savedCurrency
    }

    if (data?.tax?.currency) {
      const newCurrency = getCurrencyFromTaxCountry(
        staticData?.currencies,
        data.tax.currency,
      )
      return newCurrency
    }

    const taxResidenceCountryCurrency = staticData?.countries?.filter(
      (country) => country.id === data?.tax_residence_id,
    )[0]?.currency

    const newCurrency = staticData?.currencies?.filter(
      (item) => item.code === taxResidenceCountryCurrency,
    )[0]?.id

    return newCurrency
  }

  const [startDate, setStartDate] = useState(
    startDateMoment?.isValid() ? new Date(startDateMoment.toString()) : null,
  )
  const [endDate, setEndDate] = useState(
    endDateMoment?.isValid() ? new Date(endDateMoment.toISOString()) : null,
  )
  const [amount, setAmount] = useState(
    amendData?.amount || data?.amount || duplicatingData?.amount,
  )
  const [paygType, setPaygType] = useState(
    amendData?.rate_id || {
      label: data?.rate_id?.name,
      value: data?.rate_id?.id,
    },
  )

  const savedOccurrence = data?.occurrence
    ? { value: data.occurrence.id, label: data.occurrence.name }
    : occurrences.data
        ?.filter((o) => o.frequency_id === frequencyId?.value)
        ?.map((e) => ({ value: e.id, label: e.name }))
        .find((elm) => elm.value === data?.occurrence_id)

  const [occurrence, setOccurrence] = useState(
    amendData?.occurrence_id || data?.occurrence_id || data?.occurrence
      ? savedOccurrence
      : null,
  )
  const [firstPayment, setFirstPayment] = useState(
    amendData?.first_payment_date || isAmend
      ? null
      : firstPaymentMoment.isValid()
        ? {
            label: firstPaymentMoment.format('dddd, Do MMM'),
            value: firstPaymentMoment.format('YYYY-MM-DD'),
          }
        : null,
  )
  const [prorataType, setProrataType] = useState(null)
  const [isProrata, setIsProrata] = useState(
    isAmend
      ? false
      : !!(amendData?.first_payment_prorata || data?.first_payment_prorata),
  )
  const [isPaymentCycle, setIsPaymentCycle] = useState(
    isAmend ? false : !!(amendData?.is_monthly_cycle || data?.is_monthly_cycle),
  )
  const [prorataValue, setProrataValue] = useState(
    amendData?.prorata_amount ||
      data?.prorata_amount ||
      data?.first_payment_amount,
  )

  const {
    control,
    formState: { errors },
  } = useForm({})

  const didMountRef = useRef(false)

  useEffect(() => {
    if (startDate) {
      occurrences.startFetch({
        start_date: moment(startDate).format('YYYY-MM-DD'),
      })
    }
    if (didMountRef.current) {
      setFirstPayment(null)
    } else {
      didMountRef.current = true
    }
  }, [startDate])

  useEffect(() => {
    if (tempData?.currencyChangedManually) {
      return () => {}
    }

    const tax = data?.tax_residence
      ? data?.tax_residence?.id
      : data?.tax_residence_id

    const cur = staticData?.currencies?.filter(
      (item) =>
        item.code ===
        staticData?.countries?.filter((countr) => countr.id === tax)[0]
          ?.currency,
    )[0]

    if (data && data.tax_residence_id && cur) {
      const currency = mapCurrencyToOption(cur, 'id')

      setCurrency(currency)
    } else if (amendData?.currency_id || data?.currency_id || data?.currency) {
      setCurrency(savedCurrency)
    } else {
      const usdCurrency = mapCurrencyToOption(
        staticData?.currencies?.find((c) => c.code === 'USD'),
        'id',
      )
      setCurrency(usdCurrency)
    }
  }, [data])

  const handleValidSubmit = useCallback(
    (e, v) => {
      const bodyData = {
        ...v,
        amount,
        currency,

        first_payment_date: firstPayment?.value,
        end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
        start_date: moment(startDate).format('YYYY-MM-DD'),

        occurrence_id: isAmend
          ? occurrence
          : data?.occurrence_id
            ? data.occurrence_id
            : occurrence?.value,
        frequency_id: isAmend ? frequencyId : frequencyId?.value,
        rate_id: isAmend ? paygType : paygType?.value,
        currency_id: isAmend ? currency : currency?.value,
      }

      if (isProrata) {
        bodyData.first_payment_prorata = true
        bodyData.prorata_amount = prorataValue
        bodyData.prorata_type = prorataType
      } else {
        bodyData.first_payment_prorata = false
        delete bodyData.prorata_amount
        delete bodyData.prorata_type
      }
      if (!endDate) delete bodyData.end_date

      const errors = getErrors([
        {
          check: !startDate && !isFullTime,
          error: t('Start Date is required'),
        },
        { check: !amount, error: t('Amount is required') },
        {
          check:
            parseFloat(amount) < parseFloat(data.amount) &&
            data.type === CONTRACT_TYPES.FULL_TIME,
          error: t('Salary decrease is not allowed'),
        },
        { check: !currency, error: t('Amount currency is required') },
        {
          check: !(
            data?.type?.toUpperCase() !== 'PAY AS YOU GO' || paygType?.value
          ),

          error: t('Unit (of work) is required'),
        },
        { check: !frequencyId, error: t('Frequency of payments is required') },
        {
          check: !(occurrence || data?.occurrence_id),
          error: t('Payments occurrence is required'),
        },
        {
          check: !firstPayment && !isFullTime,
          error: t('Date for the first payment is required'),
        },
        {
          check: !(isProrata ? !!prorataValue : true),
          error: t('Prorata is required'),
        },
      ])

      if (errors.length > 0) {
        toastr.error(errors[0])
        return
      }

      if (
        amount &&
        (firstPayment || isFullTime) &&
        frequencyId &&
        startDate &&
        currency &&
        (occurrence || data?.occurrence_id) &&
        (data?.type?.toUpperCase() !== CONTRACT_TYPES.PAY_AS_YOU_GO ||
          paygType?.value) &&
        (isProrata ? !!prorataValue : true)
      ) {
        onSubmit(bodyData)
      } else {
        toastr.error(t('You need to fill all required fields'))
      }
    },
    [
      amount,
      currency,
      data.occurrence_id,
      data?.type,
      endDate,
      firstPayment,
      frequencyId,
      isAmend,
      isProrata,
      prorataType,
      occurrence,
      onSubmit,
      paygType,
      prorataValue,
      startDate,
    ],
  )

  const contract = useSelector((state) => state.Contract?.details)
  const { data: recurringExpenses } = useRecurringExpenses({
    isAdmin,
    contract,
  })

  const [showRecurringExpensesMessage, setShowRecurringExpensesMessage] =
    useState(false)

  function handleCurrencyChange(value) {
    const activeExpenses = recurringExpenses?.filter(
      (expense) => expense.status === 'active',
    )

    if (activeExpenses?.length > 0) {
      setShowRecurringExpensesMessage(true)
    }

    setCurrency(value)
    if (setTempData) {
      setTempData({ currencyChangedManually: true })
    }
  }
  const cycle = useFetch({ action: getCycleText })
  const isFullTime = data?.type === CONTRACT_TYPES.FULL_TIME

  const hideProRata =
    !firstPayment ||
    ![CONTRACT_TYPES.FIXED, CONTRACT_TYPES.FULL_TIME].includes(data?.type)

  const prorataValues = useProrataEdit()
  const {
    prorataDays,
    setProrataDays,
    workDays,
    setWorkDays,
    showProrataEdit,
    setShowProrataEdit,
    setProrataTab,
  } = prorataValues

  const { isLoading: fetchingProrata, data: prorataData } = useProRataUpdate({
    amount,
    endDate,
    firstPaymentDate: firstPayment?.value,
    frequencyId: frequencyId?.value,
    occurrenceId: occurrence?.value,
    proRata: isProrata,
    startDate: format(startDate, 'yyyy-MM-dd'),
    daysType: prorataDays,
    workWeek: workDays,
    onComplete: (data) => {
      if (showProrataEdit) {
        // If we have the edit open, we don't want to update the prorata amount
        return
      }

      // If the prorata amount is greater than the amount, set the default amount to the amount
      const defaultAmount = Math.min(amount, data?.pro_rata_amount)

      if (Number(data?.pro_rata_amount) > Number(amount)) {
        setProrataTab(PRORATA_TYPE.FULL_AMOUNT)
      } else {
        setProrataTab(PRORATA_TYPE.CALCULATED)
      }

      setProrataValue(defaultAmount)
    },
  })

  return (
    <AvForm
      ref={ref}
      className='align-items-center'
      onValidSubmit={handleValidSubmit}
    >
      <Container>
        {!showRecurringExpensesMessage ? null : (
          <Row>
            <Col>
              <Alert color='info'>
                {t(
                  'If the currency is changed, this contract’s active recurring expenses will be deactivated.',
                )}
              </Alert>
            </Col>
          </Row>
        )}

        <Row>
          {isFullTime ? null : (
            <Col md={6}>
              <FormGroup>
                <Label className='col-form-label pt-0 pt-0'>
                  {t('Start Date:')}

                  <span className='tw-ms-1 tw-text-base tw-text-systemRed-100'>
                    *
                  </span>
                </Label>
                <Col className='p-0'>
                  <CustomDatePicker
                    value={startDate}
                    handleOnChange={setStartDate}
                    dateFormat='dd/MM/yyyy'
                    inputClassName={styles.datePicker}
                  />
                </Col>
              </FormGroup>
            </Col>
          )}
          {isFullTime ? null : (
            <Col md={6}>
              <FormGroup>
                <Label className='col-form-label pt-0'>{t('End Date:')}</Label>
                <Col className='p-0'>
                  <CustomDatePicker
                    value={endDate}
                    handleOnChange={setEndDate}
                    placeholder={`(${t('Optional')})`}
                    dateFormat='dd/MM/yyyy'
                    minDate={startDate}
                    inputClassName={styles.datePicker}
                    handleClear={() => setEndDate(null)}
                    clearable
                  />
                </Col>
              </FormGroup>
            </Col>
          )}
          <Col className={cx({ 'p-3': isFullTime })} md={isFullTime ? 12 : 6}>
            {!isFullTime ? null : (
              <Alert color='danger'>
                <span className='tw-ml-3 tw-block tw-text-sm tw-text-black'>
                  {t(
                    'The effective date is determined after your signature of the new Statement of Work.',
                  )}
                </span>
              </Alert>
            )}
            <FormGroup>
              <Label className='col-form-label pt-0'>
                {isFullTime ? t('Gross Yearly Salary') : t('Amount')}:
                <span className='tw-ms-1 tw-text-base tw-text-systemRed-100'>
                  *
                </span>
              </Label>
              <InputGroup>
                <CurrencyInput
                  {...currencyInputAttrs}
                  id='contract-currency'
                  name='amount'
                  className={cx('form-control', styles.datePicker)}
                  placeholder={t('Amount')}
                  type='text'
                  onKeyPress={(e) => {
                    if (!/[0-9.]/.test(e.key)) {
                      e.preventDefault()
                    }
                  }}
                  value={amount}
                  onValueChange={setAmount}
                  // currency input props
                  allowDecimals={true}
                  decimalsLimit={2}
                  decimalSeparator='.'
                  groupSeparator=','
                />

                {isFullTime ? (
                  <InputGroupText className='tw-ml-[-1px] !tw-rounded-bl-none !tw-rounded-tl-none'>
                    {data?.salary_currency.code}
                  </InputGroupText>
                ) : (
                  <ControlledSelect
                    className={cx(
                      'input-group-text p-0',
                      styles.selectAddonAppend,
                    )}
                    selectStyles={{
                      control: (provided) => ({ ...provided, width: '100%' }),
                      valueContainer: (provided) => ({
                        ...provided,
                        paddingLeft: 0,
                      }),
                    }}
                    placeholder={t('Currency')}
                    error={errors.currency_id}
                    control={control}
                    name='currency_id'
                    options={staticData?.currencies?.map((c) =>
                      mapCurrencyToOption(c, 'id'),
                    )}
                    value={isFullTime ? data.salary_currency : currency}
                    disabled={isFullTime}
                    onChange={handleCurrencyChange}
                    classNamePrefix='RS-Addon'
                    customComponents={{
                      Option: customOption,
                      Value: customSingleValue,
                      SingleValue: customSingleValue,
                    }}
                    leftDir={isMobile}
                  />
                )}
              </InputGroup>
            </FormGroup>
          </Col>

          {data?.type?.toUpperCase() === 'PAY AS YOU GO' && (
            <Col md={6}>
              <FormGroup>
                <label className='col-form-label pt-0'>
                  {t('Unit (of work):')}

                  <span className='tw-ms-1 tw-text-base tw-text-systemRed-100'>
                    *
                  </span>
                </label>
                <div>
                  <CustomSelect
                    className='border-0'
                    name='rate_id'
                    onChange={setPaygType}
                    value={paygType}
                    isSearchable={false}
                    options={staticData?.rates?.map((e) => ({
                      value: e.id,
                      label: e.name,
                    }))}
                  />
                </div>
              </FormGroup>
            </Col>
          )}

          {isFullTime ? null : (
            <Col md={6}>
              <FormGroup>
                <Label className='col-form-label pt-0'>
                  {t('Frequency of payments')}:
                  <span className='tw-ms-1 tw-text-base tw-text-systemRed-100'>
                    *
                  </span>
                </Label>
                <CustomSelect
                  onChange={(s) => {
                    setFrequencyId(s)
                    setOccurrence(null)
                    setFirstPayment(null)
                  }}
                  value={frequencyId}
                  name='frequency_id'
                  isSearchable={false}
                  options={staticData?.frequencies?.map((e) => ({
                    value: e.id,
                    label: e.name,
                  }))}
                />
              </FormGroup>
            </Col>
          )}
          {isFullTime ? null : (
            <Col md={6}>
              {(frequencyId !== null || data?.occurrence_id) && (
                <FormGroup>
                  <Label className='col-form-label pt-0'>
                    {t('When would you process payments?')}

                    <span className='tw-ms-1 tw-text-base tw-text-systemRed-100'>
                      *
                    </span>
                  </Label>
                  <CustomSelect
                    name='occurrence_id'
                    onChange={(s) => {
                      setOccurrence(s)
                      setFirstPayment(null)
                    }}
                    isSearchable={false}
                    value={occurrence || savedOccurrence}
                    styles={simpleStyles}
                    options={occurrences.data
                      ?.filter(
                        (o) =>
                          o.frequency_id ===
                          (data?.frequency_id
                            ? data.frequency_id
                            : frequencyId?.value),
                      )
                      ?.map((e) => ({
                        value: e.id,
                        label: e.name,
                      }))}
                  />
                </FormGroup>
              )}
            </Col>
          )}
          {isFullTime ? null : (
            <Col md={6}>
              {(occurrence !== null || data?.occurrence_id) && (
                <FormGroup>
                  <Label className='col-form-label pt-0'>
                    {t('Date for the first payment')}:
                    <span className='tw-ms-1 tw-text-base tw-text-systemRed-100'>
                      *
                    </span>
                  </Label>
                  <CustomSelect
                    name='first_payment'
                    onChange={(s) => {
                      setFirstPayment(s)
                      cycle.startFetch({ first_payment_date: s.value })
                    }}
                    isSearchable={false}
                    value={firstPayment}
                    styles={simpleStyles}
                    options={
                      occurrences.data?.find((o) => o.id === occurrence?.value)
                        ?.dates
                    }
                  />
                </FormGroup>
              )}
            </Col>
          )}
          {hideProRata || frequencyId.value !== 4 ? null : (
            <Col md={6}>
              <RelatedFieldContent className='tw-rounded-t tw-border-t tw-bg-transparent tw-p-0'>
                <div className='tw-p-4'>
                  <h5 className='tw-text-sm tw-font-bold'>Payment cycle</h5>

                  <div className='tw-min-h-[32px] tw-text-xs'>
                    {isPaymentCycle ? (
                      <CycleStartEndText starts='the first' ends='last day' />
                    ) : cycle.isLoading ? (
                      <Shimmer
                        width='100%'
                        height='32px'
                        backgroundColor='var(--slate-100)'
                      />
                    ) : (
                      <p
                        className='tw-mb-0'
                        dangerouslySetInnerHTML={{
                          __html: cycle?.data?.default_period,
                        }}
                      />
                    )}
                  </div>
                </div>

                <div className='tw-border-t tw-border-surface-30 tw-p-4'>
                  <label className='custom-control custom-switch tw-mb-0 !tw-flex tw-w-full'>
                    <div className='-tw-ms-8 tw-flex-grow tw-select-none tw-pe-12 tw-text-start'>
                      {t('Reset to 1st to the end of every month')}
                    </div>
                    <AvInput
                      name='is_monthly_cycle'
                      type='checkbox'
                      className='custom-control-input'
                      onChange={(e) => {
                        setIsPaymentCycle(e.target.checked)
                      }}
                      defaultChecked={isPaymentCycle}
                    />
                    <div className='custom-control-label tw-pointer-events-none tw-select-none before:!tw-pointer-events-none after:!tw-pointer-events-none' />
                  </label>
                </div>
              </RelatedFieldContent>
            </Col>
          )}

          {hideProRata ? null : (
            <Col md={6}>
              <ProrataStateProvider
                value={{
                  ...prorataValues,
                  gettingProrataData: fetchingProrata,
                  prorataData,

                  amount,
                  [fieldKeys.proRataAmount]: prorataValue,

                  firstPaymentProrata: isProrata,
                  setFirstPaymentProrata: (event) =>
                    setIsProrata(event.target.checked),
                }}
              >
                <ProrataSettings
                  currency={currency}
                  dates={{
                    startDate: format(startDate, 'yyyy-MM-dd'),
                    firstPaymentDate: firstPayment?.value,
                  }}
                  calculationType={{
                    value: prorataDays,
                    onChange: (val) => {
                      setProrataDays(val)
                      if (val === PRORATA_CALCULATION_TYPE.CALENDAR_DAYS) {
                        setWorkDays(null)
                        setProrataType(val)
                      } else {
                        setWorkDays(WORKING_DAYS.MONDAY_FRIDAY)
                        setProrataType(null)
                      }
                    },
                  }}
                  workDays={{
                    value: workDays,
                    onChange: (val) => {
                      setWorkDays(val)
                      setProrataType(val)
                    },
                  }}
                  onSave={(amount) => {
                    if (amount) {
                      setProrataValue(amount)
                    }
                    setShowProrataEdit(false)
                  }}
                  onEditClick={() => setShowProrataEdit(true)}
                  onEditClose={() => setShowProrataEdit(false)}
                  showEdit={showProrataEdit}
                />
              </ProrataStateProvider>
            </Col>
          )}
        </Row>
      </Container>
    </AvForm>
  )
})

const simpleStyles = {
  menu: () => ({
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: 99999,
    width: '100%',
    borderRadius: '3px',
    borderColor: '#dddddd',
    borderWidth: '1px',
    borderStyle: 'solid',
  }),
  dropdownIndicator: () => ({
    color: '#114EF7',
    paddingRight: '10px',
    paddingLeft: '10px',
  }),
  indicatorSeparator: () => ({
    width: 0,
  }),
}

export default PaymentForm
