import { t } from 'i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import * as yup from 'yup'
import toastr from 'toastr'

import ControlledInput from '../../../components/ControlledInput'
import { useFetch } from '../../../helpers/hooks'
import { requestDoc } from '../../../services/api'
import Button from '../../../components/ui/button'

const schema = yup.object().shape({
  title: yup.string().required(),
})

export function RequestFormModal({ show, hide, contractId, onUpdate }) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const add = useFetch({
    action: requestDoc,
    onComplete: () => {
      toastr.success(t('Document requested successfully'))
      hide?.()
      onUpdate?.(false)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  function onSubmit(values) {
    add.startFetch({ ...values, contract_id: contractId })
  }

  return (
    <Modal isOpen={show} toggle={hide} centered className='modal-sm-2'>
      <Form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={hide}>{t('Request Document')}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label className='col-form-label pt-0'>{t('Document Name')}</Label>
            <ControlledInput
              control={control}
              name='title'
              error={errors.title}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color='light' outline onClick={hide} disabled={add.isLoading}>
            {t('Cancel')}
          </Button>
          <Button
            type='submit'
            disabled={add.isLoading}
            loading={add.isLoading}
          >
            {t('Submit')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
