import { track } from '../../../../../utils/analytics'

const EVENTS = {
  AUTOMATION_PAGE_OPENED: 'automation_page_opened',
  AUTOMATION_ADDED: 'automation_added',
  AUTOMATION_SAVED: 'automation_saved',
  AUTOMATION_PUBLISHED: 'automation_published',
  AUTOMATION_UNPUBLISHED: 'automation_unpublished',
  AUTOMATION_DUPLICATED: 'automation_duplicated',
  AUTOMATION_LIST_FILTERED: 'automation_list_filtered',
}

export class AutomationMetrics {
  static log(event, data) {
    try {
      track(event, data)
    } catch (e) {}
  }

  static logPageOpened(params) {
    this.log(EVENTS.AUTOMATION_PAGE_OPENED, params)
  }

  static logAdded(params) {
    this.log(EVENTS.AUTOMATION_ADDED, params)
  }

  static logSaved(params) {
    this.log(EVENTS.AUTOMATION_SAVED, params)
  }

  static logPublished(params) {
    this.log(EVENTS.AUTOMATION_PUBLISHED, params)
  }

  static logUnpublished(params) {
    this.log(EVENTS.AUTOMATION_UNPUBLISHED, params)
  }

  static logDuplicated(params) {
    this.log(EVENTS.AUTOMATION_DUPLICATED, params)
  }

  static logListFiltered(params) {
    this.log(EVENTS.AUTOMATION_LIST_FILTERED, params)
  }
}
