import {
  CaretCircleDoubleRight,
  CaretCircleUpDown,
  Warning,
} from '@phosphor-icons/react'
import { t } from 'i18next'
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { cn } from 'ui'
import { ModalCloseButton } from '../../components/Common/modal-close-button'
import CustomRadio from '../../components/custom-radio'
import CustomDatePicker from '../../components/Forms/CustomDatePicker/CustomDatePicker'
import Button from '../../components/ui/button'
import { datePickerDateFormat } from '../../utils/formatters/date-picker-date-format'

const SETTINGS_TYPE = {
  ALL: 'past_and_future',
  FUTURE_ONLY: 'future_only',
  CUSTOM: 'from_date',
}
function radioItems({ t }) {
  return [
    {
      label: t('Future cycles & past balances'),
      value: SETTINGS_TYPE.ALL,
      icon: (
        <CaretCircleUpDown
          weight='duotone'
          size={32}
          className='tw-mb-3 tw-rotate-90'
          id='icon'
        />
      ),
      description: t(
        'The updates will immediately be applied to past balances and will also affect future accrual cycles.',
      ),
    },
    {
      label: t('Future cycles only'),
      value: SETTINGS_TYPE.FUTURE_ONLY,
      description: t(
        'The updates will be applied in the next accrual cycle. Past balances remain unchanged.',
      ),
      icon: (
        <CaretCircleDoubleRight
          weight='duotone'
          size={32}
          className='tw-mb-3'
          id='icon'
        />
      ),
    },
  ]
}

export default function SettingsApplication({ toggle, handleEdit, isEditing }) {
  const [selected, setSelected] = useState()
  const [effectiveDate, setEffectiveDate] = useState()

  return (
    <Modal centered isOpen toggle={toggle}>
      <ModalHeader className='tw-w-full !tw-px-6'>
        <div className='tw-mb-2 tw-flex tw-justify-between'>
          <Warning className='tw-fill-systemGold-110' size={24} />
          <ModalCloseButton className='tw-fill-secondary' toggle={toggle} />
        </div>
        <div className='tw-text-secondary-120'>{t('Vacation Balance')}</div>
        <span className='tw-text-sm tw-font-medium tw-text-text-80'>
          {t(
            'Choose how to apply the new policy settings to both existing and future balances for assigned workers.',
          )}
        </span>
      </ModalHeader>
      <ModalBody className='tw-flex tw-flex-col !tw-px-6'>
        <CustomRadio
          items={radioItems({ t })}
          name='settingsType'
          renderItem={(item) => (
            <span>
              {item.icon}
              <div className='tw-mb-2 tw-text-base' id='label'>
                {item.label}
              </div>
              <span className='tw-mb-0 tw-text-sm' id='description'>
                {item.description}
              </span>
              {item.value === SETTINGS_TYPE.CUSTOM && (
                <span className='-tw-mx-6 tw-mt-6 tw-block tw-border-t tw-border-t-surface-30 tw-bg-surface-10 tw-p-6'>
                  <CustomDatePicker
                    placeholder={t('Select date')}
                    value={effectiveDate}
                    handleOnChange={(date) =>
                      setEffectiveDate(datePickerDateFormat(date))
                    }
                  />
                </span>
              )}
            </span>
          )}
          className={cn(
            '[&>span>div]:tw-text-black',
            '[&>span>#icon]:has-[:checked]:tw-fill-primary [&>span>div]:has-[:checked]:tw-text-primary [&>span>span]:has-[:checked]:tw-text-primary',
            '[&>span>span]:tw-text-text-60',
            '[&>span>#icon]:tw-fill-text-30',
            'tw-mb-6',
            // @TODO: Re-add when custom date option is readded
            // last:tw-pb-0
          )}
          onSelect={(e) => {
            if (e.target.checked) {
              setSelected(e.target.value)
            }
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          outline
          className='!tw-border-surface-30 !tw-text-black hover:!tw-bg-surface-10'
          onClick={toggle}
          disabled={isEditing}
        >
          {t('Cancel')}
        </Button>
        <Button
          onClick={() =>
            handleEdit({
              balance_update: {
                mode: selected,
                effective_date:
                  selected === SETTINGS_TYPE.CUSTOM ? effectiveDate : undefined,
              },
            })
          }
          disabled={isEditing || !selected}
          loading={isEditing}
        >
          {t('Confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
