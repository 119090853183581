import React from 'react'
import { cn } from 'ui'

export default function RelatedFieldContent({ children, className }) {
  return (
    <div
      className={cn(
        'tw-rounded-b tw-border tw-border-t-0 tw-border-surface-30 tw-bg-surface-10 tw-p-4 tw-text-text-60',
        className,
      )}
    >
      {children}
    </div>
  )
}
