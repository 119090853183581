import { t } from 'i18next'
import { Info, Warning } from '@phosphor-icons/react'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Col, Label, Row, Spinner, UncontrolledTooltip } from 'reactstrap'
import toastr from 'toastr'

import TransactionError from '../../components/transaction-error'
import Alert from '../../components/ui/alert'

const PaymentReview = ({
  onBack,
  bankInfo,
  card,
  method,
  loading,
  data,
  dataError,
}) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: data?.currency?.code || 'USD',
  })

  const companyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: data?.company_currency?.code || 'USD',
  })

  if (loading) {
    return (
      <Col style={{ minHeight: '30rem' }}>
        <Row
          style={{ minHeight: '30rem' }}
          className='justify-content-center align-items-center'
        >
          <Spinner type='grow' className='mr-2' color='primary' />
        </Row>
      </Col>
    )
  }

  if (dataError) {
    return (
      <TransactionError
        errorText={dataError}
        minHeight='max(55vh, 380px)'
        genericErrorText='An error occurred while creating your payment.'
      />
    )
  }

  return (
    <>
      <Col className='border-bottom'>
        <Row className='border-bottom px-4 py-3 mb-3'>
          <h6 className='rp-font-bold pb-0 mb-0'>{t('Recap')}</h6>
        </Row>
        {data?.items?.map((item, i) => {
          const baseFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: item?.base_currency || 'USD',
          })
          return (
            <React.Fragment key={i}>
              <div className='d-flex justify-content-between'>
                <p key={i} className='tw-flex tw-items-center tw-gap-2'>
                  {item.contractor_name}
                  {item?.amount && (
                    <>
                      <Info
                        className='tw-size-4 tw-text-text-80'
                        id={`withdrawFee-${i}`}
                      />

                      <UncontrolledTooltip
                        placement='right'
                        target={`withdrawFee-${i}`}
                      >
                        <div>
                          {item?.contractor_name !== 'Service Fee' && (
                            <div style={{ textAlign: 'left' }}>
                              <span style={{ minWidth: 20 }}>
                                {t('Amount')}:{' '}
                                {baseFormatter.format(
                                  item?.amount?.base_amount,
                                )}
                              </span>
                              <br />
                            </div>
                          )}
                          {item?.withdraw_fee && (
                            <div style={{ textAlign: 'left' }}>
                              <span style={{ minWidth: 20 }}>
                                {t('Withdraw fee')}:{' '}
                                {formatter.format(
                                  item?.withdraw_fee?.trans_amount,
                                )}{' '}
                              </span>
                              <br />
                            </div>
                          )}

                          {item?.contractors?.length > 0 &&
                            item?.contractors?.map((e, index) => (
                              <div style={{ textAlign: 'left' }} key={index}>
                                <span className='text-left'>{e}</span>
                                <br />
                              </div>
                            ))}
                        </div>
                      </UncontrolledTooltip>
                    </>
                  )}
                </p>
                <p>{formatter.format(item?.total_trans_amount)} </p>
              </div>
            </React.Fragment>
          )
        })}
        <Row className='justify-content-between font-weight-light text-muted px-4'>
          <p>{t('Fee')}</p>
          <p>{formatter.format(data?.fee ?? 0)}</p>
        </Row>

        <Row className='justify-content-between font-size-16 px-4 mb-3'>
          <h6>{t('Total')}</h6>
          <h6>
            {data?.company_currency?.code !== data?.currency?.code &&
              companyFormatter.format(data?.company_total)}{' '}
            {formatter.format(data?.total ?? 0)}
          </h6>
        </Row>
      </Col>
      {bankInfo ? (
        <Col className='py-3'>
          <Col className='p-0'>
            {!data?.warnings ? null : (
              <Alert
                customIcon={
                  <Warning size={24} className='flex-shrink-0 text-gold-110' />
                }
                color='danger'
                className='bg-gold-20 border-surface-30 p-4 mb-3'
                innerClassName='text-text-80 font-size-14'
                style={{ gap: 'var(--size-16)' }}
              >
                <p className='font-weight-bold font-size-16 mb-1'>
                  {t('Important')}
                </p>
                {data?.warnings?.map((message, index) => {
                  return (
                    <p
                      className='mb-0'
                      key={index}
                      dangerouslySetInnerHTML={{ __html: message }}
                    />
                  )
                })}
              </Alert>
            )}
          </Col>
          {!!data &&
            data?.account?.map((e, i) => (
              <Col key={`bankInfo-${i}`} className='p-0'>
                <Label
                  className={`col-form-label pt-0 font-weight-light ${
                    i === 0 ? 'text-primary rp-font-bold' : ''
                  }`}
                >
                  {e.name}
                </Label>
                <div
                  name='end-date'
                  className={`form-control mb-2 ${
                    i === 0 ? 'text-primary rp-font-bold' : ''
                  }`}
                  id='bankInfo_Beneficiary'
                  style={{ height: 'auto' }}
                >
                  {e.value}
                </div>
                <CopyToClipboard
                  text={e.value}
                  onCopy={() => toastr.success(`${e.name} copied`)}
                >
                  <span
                    style={{
                      position: 'absolute',
                      right: '10px',
                      bottom: '10px',
                    }}
                  >
                    <i className='fas fa-copy 16 text-primary' />
                  </span>
                </CopyToClipboard>
              </Col>
            ))}
        </Col>
      ) : (
        <Col className='p-0'>
          <Col>
            <Row className='justify-content-between py-2 border-bottom align-items-center px-3 py-3 mb-3'>
              <h6 className='pb-0 mb-0 rp-font-bold'>{t('Payment Method')}</h6>
              <div>
                {typeof method?.name === 'string' ? (
                  <span className='tw-text-sm'>{method?.name}</span>
                ) : (
                  ''
                )}

                <button
                  onClick={onBack}
                  className='tw-px-2 tw-py-1 tw-text-sm tw-font-bold tw-text-primary-100'
                >
                  {t('Edit')}
                </button>
              </div>
            </Row>
          </Col>

          <Col className='text-muted'>
            <Row className='justify-content-between font-weight-light align-items-center px-3'>
              <p>{t('Account holder')}</p>
              <p>{card?.billing_details?.name || 'Account Holder'}</p>
            </Row>
            <Row className='justify-content-between font-weight-light align-items-center px-3'>
              <p>{t('Account number')}</p>
              <p>
                ****{' '}
                {card?.last4 || card?.card?.last4 || card?.sepa_debit?.last4}
              </p>
            </Row>
          </Col>
        </Col>
      )}
    </>
  )
}

export default PaymentReview
