import React from 'react'
import { Cake, Confetti } from '@phosphor-icons/react'

const EventType = ({ type, index }) => {
  const Icon = ({ eventType }) => {
    switch (eventType) {
      case 'birthday':
        return <Cake size={16} className='tw-text-systemGreen-110' />

      case 'work_anniversary':
        return <Confetti size={16} className='tw-text-yellow-120' />

      default:
        return null
    }
  }
  return (
    <div
      className={`${type === 'birthday' ? 'tw-bg-systemGreen-20' : 'tw-bg-yellow-30'} tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-[32px] ${index > 0 && 'tw-ml-[-10px] tw-h-9 tw-w-9 tw-border-2 tw-border-white'}`}
    >
      <Icon eventType={type} />
    </div>
  )
}

export default EventType
