import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import {
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import toastr from 'toastr'
import { t } from 'i18next'

import { useFetch } from '../../helpers/hooks'
import {
  addPayoneerAccount,
  addPayPalAccount,
  connectPaypal,
  verifyPaypal,
} from '../../services/api'
import PaypalMethodCard from '../Withdrawal/components/PaypalMethodCard'
import useWithdrawAccounts from './use-contractor-withdraw-accounts'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'

const PaypalAccounts = ({
  onNext = () => {},
  noCards,
  onAdd = () => {},
  isPayoneer,
}) => {
  const [checkedCard, setCheckedCard] = useState(-1)
  const [editing, setEditing] = useState(!!noCards)
  const [showModal, setShowModal] = useState(false)

  const { type } = useParams()
  const history = useHistory()
  const location = history.location
  const code = new URLSearchParams(location.search).get('code')

  const {
    getAccountsList,
    accounts,
    loading: gettingAccounts,
  } = useWithdrawAccounts(isPayoneer ? 'payoneer' : 'paypal')

  const connect = useFetch({
    action: connectPaypal,
    onComplete: (data) => {
      if (data?.url && typeof data?.url === 'string') {
        window.location.replace(data?.url)
      } else {
        toastr.error(t('Something went wrong'))
      }
    },
    onError: toastr.error,
  })

  useFetch({
    action: verifyPaypal,
    autoFetch: type === 'paypal',
    body: { code },
    onComplete: () => {
      onAdd()
      getAccountsList()
      history.replace(
        location?.pathname.includes('settings')
          ? '/settings/paypal'
          : '/withdrawal-process/paypal',
      )
    },
    onError: (err) => {
      toastr.error(err)
      history.replace(
        location?.pathname.includes('settings')
          ? '/settings/paypal'
          : '/withdrawal-process/paypal',
      )
    },
  })

  function handleAddSuccess() {
    setShowModal(false)
    onAdd()
    getAccountsList()
    setEditing(!!noCards)
  }

  function toggle() {
    setShowModal((open) => !open)
  }

  return (
    <>
      <div className='h-100'>
        {!editing ? (
          <div>
            {gettingAccounts ? (
              <Loader minHeight='15rem' />
            ) : (
              <Col className='p-0 d-flex flex-column' style={{ gap: '1rem' }}>
                {accounts.map((e, i) => (
                  <PaypalMethodCard
                    isPayoneer={isPayoneer}
                    onClick={() => {
                      onNext(e)
                      setCheckedCard(i)
                    }}
                    key={`ppAcc-${i}`}
                    account={e}
                    index={i}
                    checked={checkedCard === i}
                  />
                ))}
                <button
                  onClick={() => {
                    if (isPayoneer) {
                      setShowModal(true)
                    } else {
                      connect.startFetch({ type: 'withdraw' })
                    }
                  }}
                  className='rp-btn-nostyle align-self-start text-primary rp-font-bold'
                  type='button'
                >
                  {connect.isLoading ? (
                    <i className='bx bx-loader bx-spin' />
                  ) : (
                    '+'
                  )}{' '}
                  {t('AddNewAccount', {
                    context: isPayoneer ? 'Payoneer' : 'Paypal',
                  })}
                </button>
              </Col>
            )}
          </div>
        ) : (
          <NewPayPalAccountForm
            isPayoneer={isPayoneer}
            onSuccess={handleAddSuccess}
            onCancel={toggle}
          />
        )}
      </div>
      <Modal
        isOpen={showModal}
        scrollable
        centered
        className='newAccountModal'
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>
          {t('AddAccount', {
            context: isPayoneer ? 'Payoneer' : 'Paypal',
          })}
        </ModalHeader>
        <ModalBody>
          <NewPayPalAccountForm
            isPayoneer={isPayoneer}
            onSuccess={handleAddSuccess}
            onCancel={toggle}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

function NewPayPalAccountForm({ isPayoneer, onSuccess, onCancel }) {
  const addPaypal = useFetch({
    action: addPayPalAccount,
    onComplete: () => {
      onSuccess?.()
    },
    onError: toastr.error,
  })

  const addPayoneer = useFetch({
    action: addPayoneerAccount,
    onComplete: () => {
      onSuccess?.()
    },
    onError: toastr.error,
  })

  const handleAddSubmit = (e, v) => {
    isPayoneer
      ? addPayoneer.startFetch({ email: v.email })
      : addPaypal.startFetch({ email: v.email })
  }

  const isLoading = addPayoneer.isLoading || addPaypal.isLoading

  return (
    <AvForm
      onValidSubmit={handleAddSubmit}
      className='d-flex flex-column h-100 pt-4 px-4'
    >
      <FormGroup className='mb-3'>
        <Label htmlFor='name'>
          {t('Email', { context: isPayoneer ? 'Payoneer' : 'PayPal' })}
        </Label>
        <AvField
          className='form-control'
          type='email'
          name='paypal-email'
          id='paypal-email'
          placeholder={t('Email')}
          required
        />
      </FormGroup>

      <ModalFooter className='px-0 mt-auto'>
        <Button disabled={isLoading} color='light' outline onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button type='submit' disabled={isLoading} loading={isLoading}>
          {t('Next')}
        </Button>
      </ModalFooter>
    </AvForm>
  )
}

export default PaypalAccounts
