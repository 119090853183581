import React from 'react'
import { useSelector } from 'react-redux'

import { getCurrencyFormatter } from '../../../utils/formatters/currency'

export function BaseAmount({ baseAmount, baseCurrency, contract }) {
  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )

  const defaultContract =
    useSelector((state) => state?.Contract?.details) ?? contract

  const contractCurrencyCode = defaultContract?.currency?.code || 'USD'
  const baseCurrencyCode = baseCurrency?.code || 'USD'

  const baseFormatter = getCurrencyFormatter(baseCurrencyCode)

  const sameCurrency = contractCurrencyCode === baseCurrencyCode

  return isEmployee ? (
    <span className='tw-ms-1'> {baseFormatter.format(baseAmount)} </span>
  ) : sameCurrency ? (
    ''
  ) : (
    <span className='tw-ms-1 tw-text-primary'>
      ({baseFormatter.format(baseAmount)})
    </span>
  )
}
