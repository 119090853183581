import { PlusCircle } from '@phosphor-icons/react'
import omit from 'lodash/omit'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from 'reactstrap'
import { cn } from 'ui'
import SearchBar from '../../../../../components/SearchBar'
import { CheckItem } from '../../../../../components/ui/check-item'

const Divider = () => <div className='tw-h-[1px] tw-w-full tw-bg-text-10' />

export function AddFilterButtons(props) {
  const { watch, getValues } = useFormContext()
  const triggerCategory = watch(`steps.0`)?.event?.category
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState(getValues('filters'))
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen((prevState) => !prevState)
  const filters = (props?.filters?.all ?? [])
    .filter((i) => i.categories?.includes(triggerCategory))
    .filter((i) => i.title.toLowerCase().includes(search.toLowerCase()))

  return (
    <Controller
      name='filters'
      render={({ field: { value, onChange } }) => (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle tag='div'>
            <button
              disabled={!filters?.length}
              id='add-filter-button'
              className={cn(
                'tw-flex tw-flex-row tw-gap-1 tw-rounded-full tw-border tw-border-surface-30 tw-bg-primary-10 tw-px-4 tw-py-1.5 tw-text-primary',
                dropdownOpen && 'tw-bg-primary tw-text-white',
                !filters?.length && 'tw-opacity-70',
              )}
            >
              <span>Add Filters</span>
              <PlusCircle size={20} weight='fill' />
            </button>

            {!filters?.length ? (
              <UncontrolledTooltip target='add-filter-button'>
                Select a trigger to apply filters.
              </UncontrolledTooltip>
            ) : null}
          </DropdownToggle>
          <DropdownMenu className='tw-w-[250px] tw-overflow-auto'>
            <SearchBar
              timeout={0}
              className='tw-m-4 tw-basis-full'
              query={search}
              placeholder='Find'
              onQueryChanged={setSearch}
              isClearable
              inputClassName='!tw-h-auto'
            />
            <Divider />
            <div className='tw-flex tw-max-h-[300px] tw-flex-col tw-gap-4 tw-overflow-auto tw-px-4 tw-py-4'>
              {filters.map((f, index) => {
                const checked = value?.[f?.filter]
                if (f.type === 'header')
                  return <span key={`${f.title}-${index}`}>{f.title}</span>

                return (
                  <CheckItem
                    key={`${f.title}-${index}`}
                    label={f.title}
                    defaultChecked={checked}
                    onChange={(e) => {
                      setSelected((prev) =>
                        !e.target.checked
                          ? omit(prev, [f.filter])
                          : {
                              ...prev,
                              [`${f.filter}`]: [],
                            },
                      )
                    }}
                  />
                )
              })}
            </div>
            <Divider />
            <div className='tw-m-4 tw-flex tw-flex-1 tw-flex-row tw-justify-between'>
              <button
                onClick={() => {
                  toggle()
                  setSelected([])
                  onChange([])
                }}
                className='tw-font-medium'
              >
                Clear All
              </button>
              <button
                onClick={() => {
                  toggle()
                  onChange(selected)
                }}
                className='tw-font-medium tw-text-primary'
              >
                Apply
              </button>
            </div>
          </DropdownMenu>
        </Dropdown>
      )}
    />
  )
}
