import { Clipboard, ClipboardText, LockSimpleOpen } from '@phosphor-icons/react'
import copy from 'copy-to-clipboard'
import React, { useState } from 'react'
import { Spinner } from 'reactstrap'

import { cn } from 'ui'
import { ReactComponent as RPSymbol } from '../../../assets/images/identity/rp-symbol-main.svg'
import masterCardLogo from '../../../assets/images/master-card.svg'
import Button from '../../../components/ui/button'
import { CARD_STATUSES, CARD_TYPE_ENUM } from '../utils/card-types-status'

function formatCCNumber(str) {
  if (typeof str !== 'string') return ''
  return str.replaceAll('-', '').match(/.{1,4}/g)
}
function formatExpiry(str) {
  if (typeof str !== 'string') return []
  return str.replaceAll('-', '').match(/.{1,4}/g)
}

const defaultInstructions = (
  <>
    <Clipboard weight='fill' className='tw-inline-block' /> Click on numbers to
    copy
  </>
)
let timeOutId = null

export function CreditCard({
  onMouseEnter,
  onMouseLeave,
  loading,
  cardType,
  cardStatus,
  unmaskedCardNumber,
  handleToggleFreezeCard,
  maskedCardNumber,
  validUntil,
  cvv,
  showDetails,
  onToggleCardDetails,
  line1,
}) {
  const [instructionMessage, setInstructionMessage] =
    useState(defaultInstructions)

  function copyToClipboard(value, name) {
    if (!cardActive || !cardIsVirtual) {
      return
    }

    copy(value)
    setInstructionMessage(
      <>
        <ClipboardText weight='fill' className='tw-inline-block' />{' '}
        {name ?? 'Detail'} copied!
      </>,
    )
    if (timeOutId) clearTimeout(timeOutId)
    timeOutId = setTimeout(
      () => setInstructionMessage(defaultInstructions),
      5000,
    )
  }

  function handleOnMouseLeave(e) {
    setInstructionMessage(defaultInstructions)
    if (typeof onMouseLeave === 'function') {
      onMouseLeave(e)
    }
  }
  const cardActive = cardStatus?.endsWith(CARD_STATUSES.ACTIVE)
  const cardBlocked = cardStatus === CARD_STATUSES.TEMP_BLOCK
  const cardIsVirtual = cardType === CARD_TYPE_ENUM.VIRTUAL

  return (
    <div
      className={cn(
        'tw-relative tw-flex tw-h-[195px] tw-w-[311px] tw-flex-col tw-justify-between tw-overflow-hidden tw-rounded-2xl tw-p-4 tw-font-mono tw-text-white tw-transition-transform tw-duration-100 md:tw-h-[211px] md:tw-w-[339px]',
        {
          'tw-bg-secondary-80': cardBlocked,
          'tw-bg-gradient-to-br tw-from-[#0f1035] tw-to-[#0f1035d8]':
            cardActive,
        },
      )}
      style={{
        perspective: '1000px',
        transformStyle: 'preserve-3d',
        boxShadow:
          '0px 6.5px 13px 0px rgba(0, 0, 0, 0.08), 0px 6.5px 55px 0px rgba(15, 16, 53, 0.16)',
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      {!loading ? null : (
        <div className='tw-absolute tw-inset-0 tw-flex tw-w-full tw-items-center tw-justify-center tw-bg-secondary-80/20'>
          <Spinner size='sm' style={{ right: '5px', top: '5px' }} />
        </div>
      )}

      <div className='tw-flex tw-justify-between'>
        <RPSymbol className='tw-h-7 tw-w-auto tw-text-white' />
      </div>

      <div className='tw-flex tw-flex-col tw-items-start tw-gap-1'>
        <div
          className={cn('-tw-mb-1.5 tw-ml-1 tw-opacity-60', {
            'tw-hidden': !cardActive,
          })}
        >
          {instructionMessage && showDetails ? instructionMessage : null}
          &nbsp;
        </div>

        <div className='tw-flex tw-min-h-6 tw-items-center tw-gap-4'>
          <CardDetail
            className='tw-flex tw-min-h-6 tw-items-center tw-gap-4'
            value={(unmaskedCardNumber && showDetails
              ? (formatCCNumber(unmaskedCardNumber) ?? [])
              : (formatCCNumber(maskedCardNumber) ?? [])
            )?.map((item, index) => (
              <span key={index}>{item}</span>
            ))}
            valueClassName='tw-flex tw-px-1 tw-text-lg md:tw-text-xl tw-gap-[1ch]'
            onClick={
              !cardIsVirtual
                ? undefined
                : () => {
                    copyToClipboard(
                      formatCCNumber(unmaskedCardNumber).join(' '),
                      'Number',
                    )
                  }
            }
          />

          {!cardIsVirtual ? null : (
            <Button
              outline={!showDetails}
              color='light'
              className={cn(
                '!tw-rounded-full !tw-px-2 !tw-py-0.5 !tw-text-xs lg:!tw-hidden',
                showDetails ? '' : '!tw-text-white hover:!tw-bg-surface-10/10',
              )}
              onClick={onToggleCardDetails}
            >
              {showDetails ? 'Hide' : 'Show'}
            </Button>
          )}
        </div>

        <div className='tw-flex tw-gap-4 tw-px-1'>
          <CardDetail
            label={
              <>
                VALID
                <br />
                THRU
              </>
            }
            value={formatExpiry(
              showDetails && validUntil ? validUntil : 'YYYYMM',
            ).join('/')}
            onClick={
              !cardIsVirtual
                ? undefined
                : () => {
                    copyToClipboard(
                      formatExpiry(validUntil)?.join('/'),
                      'Expiry',
                    )
                  }
            }
          />
          <CardDetail
            label={
              <>
                SECURITY
                <br />
                CODE
              </>
            }
            value={showDetails && cvv ? cvv : 'XXX'}
            onClick={
              !cardIsVirtual ? undefined : () => copyToClipboard(cvv, 'CVV')
            }
          />
        </div>
      </div>

      <div>
        {cardBlocked && (
          <Button
            color='light'
            outline
            onClick={handleToggleFreezeCard}
            icon={<LockSimpleOpen weight='fill' size={12} />}
            className='!tw-rounded-full !tw-px-2 !tw-py-0.5 !tw-text-xs !tw-text-white hover:!tw-bg-surface-10/10 lg:!tw-hidden'
          >
            UNFREEZE
          </Button>
        )}
      </div>

      <div className='tw-flex tw-items-end tw-justify-between'>
        <div className='tw-px-1 tw-text-sm'>{line1}</div>

        <img
          className='tw-relative tw-top-1 tw-h-9 tw-w-14 tw-object-contain'
          src={masterCardLogo}
        />
      </div>
    </div>
  )
}

function CardDetail({ className, label, value, valueClassName, onClick }) {
  const canBeHovered = typeof onClick === 'function'

  const Comp = canBeHovered ? 'button' : 'div'

  return (
    <div className={cn('tw-flex tw-items-end tw-gap-0.5', className)}>
      {!label ? null : (
        <div className='tw-text-[6px] tw-leading-[1.2]'>{label}</div>
      )}
      <Comp
        className={cn(
          'tw-px-1 tw-py-px tw-font-mono tw-text-sm tw-leading-none',
          valueClassName,
          canBeHovered &&
            'tw-cursor-pointer tw-rounded hover:tw-bg-primary-100/25',
        )}
        onClick={onClick}
      >
        {value}
      </Comp>
    </div>
  )
}
