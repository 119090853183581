import { t } from 'i18next'
import React from 'react'
import { cn } from 'ui'

export default function WorkingDays({
  activeClassName = 'tw-text-primary tw-bg-primary-10 tw-border-2',
  inActiveClassName = 'tw-text-black',
  days = [],
  onClick,
  className,
}) {
  const DAYS = [
    { index: 1, label: t('Mon') },
    { index: 2, label: t('Tue') },
    { index: 3, label: t('Wed') },
    { index: 4, label: t('Thu') },
    { index: 5, label: t('Fri') },
    { index: 6, label: t('Sat') },
    { index: 7, label: t('Sun') },
  ]
  return (
    <span className='tw-flex tw-flex-wrap tw-gap-3'>
      {DAYS.map((day) => (
        <button
          type='button'
          key={day.index}
          className={cn(
            'tw-h-[60px] tw-w-[60px] tw-rounded-full tw-border tw-text-sm tw-font-bold',
            className,
            days?.includes(day.index) ? activeClassName : inActiveClassName,
          )}
          onClick={() => onClick(day)}
          disabled={!onClick}
        >
          {day.label}
        </button>
      ))}
    </span>
  )
}
