import { Check } from '@phosphor-icons/react'
import cx from 'classnames'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Card,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import toastr from 'toastr'

import { MilestoneCard, MilestoneNameCell, PaymentStatusBadgeV2 } from '.'
import emptyMilestoneImage from '../../../assets/images/milestones-empty.png'
import noInvoicesImage from '../../../assets/images/no-invoices.svg'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import NoContent from '../../../components/NoContent'
import TableComp from '../../../components/Table/TableComp'
import TableHeader from '../../../components/Table/TableH'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import Button from '../../../components/ui/button'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../../components/ui/side-menu'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { storage } from '../../../helpers/config'
import { CONTRACT_STATUS, userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  approveMilestone,
  deleteWork,
  downloadWork,
  getApprovalFlowTimeline,
} from '../../../services/api'
import openFileV2 from '../../../utils/file/open-v2'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import DeclineModal from '../components/DeclineModal'
import SubmitMilestoneModal from '../components/SubmitMilestoneModal'
import { AddMilestoneModal } from '../components/add-milestone-modal'
import { ImageModal } from './Expenses'
import { Timeline } from './payment-approvals'
import { WorkAttributes } from './submitted-work'

export function Milestones({
  contract,
  updateContract,
  setNeedSign,
  setShowConfirmationModal,
  setEndedModal,
}) {
  const [addMilestone, setAddMilestone] = useState(false)
  const [submitMilestone, setSubmitMilestone] = useState(false)
  const [milestoneToSubmit, setMilestoneToSubmit] = useState(null)

  const userIsPartner = useSelector(
    (state) => state.userProfile?.userProfile?.is_partner,
  )
  const user = useSelector((state) => state.Account?.user)

  const { hasAccess } = usePermissions()

  function onMilestoneSubmit(milestone) {
    if (user?.type === 'client' || contract.can_submit) {
      setMilestoneToSubmit(milestone)
      setSubmitMilestone(true)
    } else if (contract.status.id === CONTRACT_STATUS.TERMINATED.value) {
      setShowConfirmationModal(true)
    } else if (contract.status.id === CONTRACT_STATUS.ENDED.value) {
      setEndedModal(true)
    } else {
      setNeedSign(true)
    }
  }

  return (
    <>
      {!addMilestone ? null : (
        <AddMilestoneModal
          contract={contract}
          isOpen={addMilestone}
          toggle={() => setAddMilestone((open) => !open)}
          onMilestoneAdded={updateContract}
        />
      )}

      <SubmitMilestoneModal
        isOpen={submitMilestone}
        toggle={() => setSubmitMilestone(false)}
        milestone={milestoneToSubmit}
        onMilestoneSubmitted={updateContract}
        data={contract}
      />

      {contract?.milestones?.length <= 0 ? (
        <NoContent
          customBottom={
            user?.type === userTypes.CONTRACTOR ||
            (hasAccess(permissions.CreateMilestones) &&
              contract?.can_approve &&
              contract?.type !== CONTRACT_TYPES.FULL_TIME) ||
            [5, 6, 7].includes(contract?.status?.id) ? (
              <AddMilestoneDropdown
                contract={contract}
                setShowConfirmationModal={setShowConfirmationModal}
                setEndedModal={setEndedModal}
                setAddMilestone={setAddMilestone}
              />
            ) : null
          }
          headline={
            contract.type === CONTRACT_TYPES.FULL_TIME
              ? 'No Invoice added'
              : 'No Milestone added'
          }
          subtitle='All work submissions will be shown here and will be subject to approval by the client.'
          image={
            contract.type === CONTRACT_TYPES.FULL_TIME
              ? noInvoicesImage
              : emptyMilestoneImage
          }
          actionTitle={
            contract.type === CONTRACT_TYPES.FULL_TIME
              ? 'Add Invoice'
              : 'Add Milestone'
          }
          onAction={() => {
            setAddMilestone(true)
          }}
          advanced
        />
      ) : (
        <Card className='mb-0 rp-shadow-2'>
          <TableHeader
            title={
              contract?.type === CONTRACT_TYPES.FULL_TIME || userIsPartner
                ? 'Invoices'
                : CONTRACT_TYPES.MILESTONES
            }
            leftSide={
              user?.type === userTypes.CONTRACTOR ||
              contract?.type !== CONTRACT_TYPES.FULL_TIME ||
              [5, 6, 7].includes(contract?.status?.id) ? (
                userIsPartner ? (
                  <Button onClick={() => setAddMilestone(true)}>
                    Add Invoice
                  </Button>
                ) : (
                  <AddMilestoneDropdown
                    contract={contract}
                    setShowConfirmationModal={setShowConfirmationModal}
                    setEndedModal={setEndedModal}
                    setAddMilestone={setAddMilestone}
                  />
                )
              ) : null
            }
          />

          <div className='d-md-none'>
            {contract?.milestones?.map((milestone) => {
              return (
                <MilestoneMobileCard
                  key={milestone?.id}
                  milestone={milestone}
                  onSubmit={() => onMilestoneSubmit(milestone)}
                  data={contract}
                  updateContractDetail={updateContract}
                />
              )
            })}
          </div>

          <div className='table-with-dropdown d-none d-md-block mb-1'>
            <TableComp className='table-centered'>
              <thead className='tw-text-black'>
                <tr>
                  <th className='!tw-border-t-0 !tw-px-6 tw-text-sm tw-font-semibold'>
                    Name
                  </th>
                  <th className='!tw-border-t-0 !tw-px-6 tw-text-sm tw-font-semibold'>
                    Amount
                  </th>
                  <th className='!tw-border-t-0 !tw-px-6 tw-text-sm tw-font-semibold'>
                    Status
                  </th>
                  <th className='!tw-border-t-0 !tw-px-6 tw-text-sm tw-font-semibold'>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {contract?.milestones?.map((milestone) => {
                  return (
                    <MilestoneCard
                      key={milestone?.id}
                      milestone={milestone}
                      onSubmit={() => onMilestoneSubmit(milestone)}
                      data={contract}
                      updateContractDetail={updateContract}
                    />
                  )
                })}
              </tbody>
            </TableComp>
          </div>
        </Card>
      )}
    </>
  )
}

function AddMilestoneDropdown({
  contract,
  setShowConfirmationModal,
  setEndedModal,
  setAddMilestone,
}) {
  const [menu, setMenu] = useState(false)

  const user = useSelector((state) => state.Account?.user)

  const { hasAccess } = usePermissions()

  const history = useHistory()

  return (
    <PermissionTooltip
      area={PERMISSION_GROUP.MILESTONES.name}
      showing={!hasAccess(permissions.CreateMilestones)}
      id='add-milestone-tooltip'
    >
      <Dropdown
        isOpen={menu}
        toggle={() => {
          if (contract.status.id === CONTRACT_STATUS.TERMINATED.value) {
            setShowConfirmationModal(true)
          } else if (contract.status.id === CONTRACT_STATUS.ENDED.value) {
            setEndedModal(true)
          } else {
            if (contract?.type === CONTRACT_TYPES.FULL_TIME) {
              history.push({
                pathname: '/scan-invoice',
                state: { contractId: contract?.id, ...contract },
              })
            } else if (user?.type === userTypes.CONTRACTOR) {
              setMenu(!menu)
            } else {
              setAddMilestone(true)
            }
          }
        }}
      >
        <DropdownToggle
          tag={Button}
          block
          disabled={
            !(
              user?.type === userTypes.CONTRACTOR ||
              hasAccess(permissions.CreateMilestones) ||
              contract?.can_approve
            ) || [5, 6, 7].includes(contract?.status?.id)
          }
        >
          {contract?.type === CONTRACT_TYPES.FULL_TIME
            ? 'Add Invoice'
            : 'Add milestone'}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => setAddMilestone(true)}>
            Add it Manually
          </DropdownItem>

          <DropdownItem
            onClick={() => {
              history.push({
                pathname: '/scan-invoice',
                state: { contractId: contract?.id, ...contract },
              })
            }}
          >
            Upload Invoice
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </PermissionTooltip>
  )
}

function MilestoneMobileCard({
  milestone,
  updateContractDetail,
  onSubmit,
  isAdmin,
  data,
}) {
  const [showImg, setShowImg] = useState(false)

  const formatter = getCurrencyFormatter(data?.currency?.code)

  return (
    <div className='tw-mx-4 tw-mb-4 tw-rounded tw-border tw-border-surface-30 tw-bg-white'>
      <div className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-surface-30 tw-p-4'>
        <PaymentStatusBadgeV2
          status={milestone.status?.name}
          paymentStatus={milestone.payment_status}
        />

        <MilestoneSideMenu
          contract={data}
          milestone={milestone}
          isAdmin={isAdmin}
          onSubmit={onSubmit}
          onUpdate={updateContractDetail}
        />
      </div>

      <div className='tw-flex tw-flex-col tw-gap-5 tw-py-4 tw-text-sm'>
        <div className='tw-flex tw-items-center tw-justify-between tw-px-4'>
          <span>Name</span>

          <MilestoneNameCell milestone={milestone} className='text-right' />
        </div>

        <div className='tw-flex tw-items-center tw-justify-between tw-px-4'>
          <span>Amount</span>

          <span className='tw-font-semibold tw-text-black'>
            {formatter.format(milestone.amount)}
          </span>
        </div>
      </div>

      <ImageModal
        title='Invoice preview'
        show={showImg}
        hide={() => setShowImg(false)}
        photo={`${storage}${milestone?.attachement?.thumbnail}`}
        downloadLink={`${storage}${milestone?.attachement?.pdf}`}
      />
    </div>
  )
}

export function MilestoneSideMenu({
  contract,
  milestone,
  isAdmin,
  onSubmit,
  onUpdate,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [actionOverridden, setActionOverridden] = useState(false)

  const user = useSelector((state) => state.Account?.user)
  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  function toggle() {
    setIsMenuOpen((isOpen) => !isOpen)
  }

  const { data: flowTimeline, isLoading: loadingFlowTimeline } = useFetch(
    {
      action: getApprovalFlowTimeline,
      body: { item_id: milestone.id, type: 'work' },
      autoFetch: !!isMenuOpen,
    },
    [isMenuOpen],
  )

  const firstPending = flowTimeline?.find((item) => item.status === 'Pending')
  const hasApprovalFlow = !!contract?.approval_flow?.steps?.length

  const actionable =
    !isAdmin &&
    [1, 2].includes(milestone.status?.id) &&
    (firstPending?.user_id === user.id ||
      !!userProfile?.is_company_creator ||
      actionOverridden ||
      !hasApprovalFlow)

  return (
    <>
      <Button
        color='link'
        onClick={toggle}
        type='button'
        className='!tw-px-0 !tw-py-1'
      >
        Details
      </Button>

      <SideMenu
        onClose={toggle}
        isOpen={isMenuOpen}
        className='!tw-z-[1050] !tw-w-full tw-max-w-[532px] tw-text-black'
        itemListClassName={cx(
          'tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain',
          actionable
            ? 'tw-grid-rows-[auto_1fr_91px]'
            : 'tw-grid-rows-[auto_1fr]',
        )}
      >
        <SideMenuHeader toggle={toggle} className='!tw-items-start'>
          <div className='tw-flex tw-items-baseline tw-gap-2'>
            <span className='tw-text-sm tw-text-text-60'>Milestone</span>

            <PaymentStatusBadgeV2
              status={milestone.status?.name}
              paymentStatus={milestone.payment_status}
            />
          </div>
        </SideMenuHeader>

        <SideMenuBody>
          <h4 className='tw-font-semibold'>Details</h4>
          <MilestoneNameCell milestone={milestone} />

          <WorkAttributes attributes={milestone?.attributes} />

          <DownloadAttachment item={milestone}>
            {({ onClick, loading }) => {
              return (
                <Button
                  type='button'
                  size='sm'
                  outline
                  onClick={onClick}
                  disabled={loading}
                  loading={loading}
                  className='tw-mt-6'
                >
                  Download attachment
                </Button>
              )
            }}
          </DownloadAttachment>

          <Timeline
            flowTimeline={flowTimeline}
            loading={loadingFlowTimeline}
            loadingLength={contract?.approval_flow?.steps?.length}
            isAdmin={isAdmin}
            actionOverridden={actionOverridden}
            setActionOverridden={setActionOverridden}
            contract={contract}
          />
        </SideMenuBody>

        {!actionable && !milestone.can_delete ? null : (
          <SideMenuFooter>
            <MilestoneActions
              isAdmin={isAdmin}
              milestone={milestone}
              contract={contract}
              onSubmit={onSubmit}
              onUpdate={() => {
                onUpdate?.()
                toggle()
              }}
              onlyDelete={!actionable && milestone.can_delete}
            />
          </SideMenuFooter>
        )}
      </SideMenu>
    </>
  )
}

export function DownloadAttachment({ item, children }) {
  const attachmentName = `attachment-${item?.id}.pdf`

  const downloadLine = useFetch({
    action: downloadWork,
    onComplete: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      openFileV2(url, {
        isDataUrl: true,
        download: true,
        name: attachmentName,
      })
    },
  })
  function fetchMilestoneFile(milestone) {
    downloadLine.startFetch({ work_id: milestone?.id })
  }

  function handleDownloadMilestone() {
    if (item?.file) {
      fetchMilestoneFile(item)
    } else if (item?.attachement?.pdf) {
      openFileV2(item.attachement.pdf, {
        isDataUrl: true,
        name: attachmentName,
      })
    }
  }

  if (!item?.file && !item?.attachement?.pdf) {
    return (
      <div className='tw-flex tw-h-[42px] tw-items-center'>
        No attachment available
      </div>
    )
  }

  if (typeof children === 'function') {
    return children({
      onClick: handleDownloadMilestone,
      loading: downloadLine.isLoading,
    })
  }

  return (
    <Button
      type='button'
      size='sm'
      outline
      onClick={handleDownloadMilestone}
      disabled={downloadLine.isLoading}
      loading={downloadLine.isLoading}
      className='tw-mt-6'
    >
      Download attachment
    </Button>
  )
}

function MilestoneActions({
  isAdmin,
  milestone,
  onSubmit,
  contract,
  onUpdate,
  onlyDelete,
}) {
  const [declineModal, setDeclineModal] = useState(false)
  const { hasAccess } = usePermissions()

  const user = useSelector((state) => state.Account?.user)

  function declineMilestone() {
    setDeclineModal(true)
  }

  const {
    startFetch: clientApproveMilestone,
    isLoading: approveMilestoneLoading,
  } = useFetch({
    action: approveMilestone,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message)
      } else {
        toastr.success('Milestone approved successfully')
        onUpdate?.()
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  function handleApproveMilestone() {
    clientApproveMilestone({ work_id: milestone.id })
  }

  const deleteLine = useFetch({
    action: deleteWork,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(
          data?.message || 'An error occurred while deleting milestone',
        )
      } else {
        toastr.success('Milestone deleted successfully')

        onUpdate?.()

        window.analytics.track('Deleted work', {
          contract_id: contract?.ref,
          contract_type: contract?.type,
          work_id: milestone?.id,
          work_name: milestone?.name,
          work_value: milestone?.value,
          currency: contract?.currency?.code,
        })
      }
    },
  })
  function handleRemoveWork(milestone) {
    deleteLine.startFetch({ work_id: milestone?.id })
  }

  const actionLoading = approveMilestoneLoading || deleteLine.isLoading

  const isClient = user?.type === userTypes.COMPANY

  if (isAdmin) {
    return null
  }

  if (onlyDelete && hasAccess(permissions.DeleteWorks)) {
    return (
      <DeleteMilestone
        milestone={milestone}
        disabled={actionLoading}
        loading={deleteLine.isLoading}
        handleRemoveWork={handleRemoveWork}
      />
    )
  }

  return (
    <>
      <DeclineModal
        isOpen={declineModal}
        toggle={() => setDeclineModal(false)}
        onDeclined={onUpdate}
        isMilestone
        e={milestone}
      />

      <PermissionTooltip
        area={PERMISSION_GROUP.MILESTONES.name}
        showing={!hasAccess(permissions.DeleteWorks)}
        id='delete-milestone-btn-tooltip'
        className='tw-mr-auto'
      >
        <DeleteMilestone
          milestone={milestone}
          disabled={actionLoading}
          loading={deleteLine.isLoading}
          handleRemoveWork={handleRemoveWork}
        />
      </PermissionTooltip>

      {isClient && milestone.status.id === 2 && (
        <>
          <PermissionTooltip
            area={PERMISSION_GROUP.MILESTONES.name}
            showing={!hasAccess(permissions.DeclineMilestones)}
            id='decline-milestone-btn-tooltip'
          >
            <Button
              color='danger'
              onClick={declineMilestone}
              disabled={
                !(
                  hasAccess(permissions.DeclineMilestones) ||
                  contract?.can_approve
                ) || actionLoading
              }
              type='button'
            >
              Decline
            </Button>
          </PermissionTooltip>
          <PermissionTooltip
            area={PERMISSION_GROUP.MILESTONES.name}
            showing={!hasAccess(permissions.ApproveMilestones)}
            id='approve-milestone-btn-tooltip'
          >
            <Button
              color='success'
              onClick={handleApproveMilestone}
              disabled={
                !(
                  hasAccess(permissions.ApproveMilestones) ||
                  contract?.can_approve
                ) || actionLoading
              }
              loading={approveMilestoneLoading}
              type='button'
            >
              Approve
            </Button>
          </PermissionTooltip>
        </>
      )}

      {isClient && milestone.status?.id === 1 && (
        <PermissionTooltip
          area={PERMISSION_GROUP.MILESTONES.name}
          showing={!hasAccess(permissions.ApproveMilestones)}
          id='mark-milestone-btn-tooltip'
        >
          <Button
            onClick={handleApproveMilestone}
            disabled={
              !(
                hasAccess(permissions.ApproveMilestones) ||
                contract?.can_approve
              ) || actionLoading
            }
            loading={approveMilestoneLoading}
            icon={<Check size={18} />}
            type='button'
          >
            Mark as completed
          </Button>
        </PermissionTooltip>
      )}

      {!isClient && milestone.status?.id === 1 && (
        <Button type='button' onClick={onSubmit}>
          Submit
        </Button>
      )}
    </>
  )
}

function DeleteMilestone({ milestone, disabled, loading, handleRemoveWork }) {
  const [showConfirmation, setShowConfirmation] = useState(false)

  const { hasAccess } = usePermissions()

  return (
    <>
      <ConfirmationModal
        title='Delete Milestone'
        message='Are you sure you want to delete this milestone'
        caption='Delete'
        buttonColor='danger'
        onConfirm={() => {
          handleRemoveWork?.(milestone)
          setShowConfirmation(false)
        }}
        isOpen={showConfirmation}
        toggle={() => setShowConfirmation(false)}
      />

      {milestone.can_delete ? (
        <Button
          type='button'
          color='link'
          onClick={() => setShowConfirmation(true)}
          className='tw-mr-auto !tw-px-0 !tw-text-black'
          disabled={disabled || !hasAccess(permissions.DeleteWorks)}
          loading={loading}
        >
          Delete
        </Button>
      ) : null}
    </>
  )
}
