import { t } from 'i18next'
import { WarningOctagon } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'

import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import Toggle from '../../../components/Forms/Toggle/Toggle'
import { Box } from '../../../components/ui/box'
import Button from '../../../components/ui/button'

export default function PayrollApprovalModal({
  isOpen,
  toggle,
  onConfirm,
  newValue,
  isLoading,
}) {
  const [sendEmail, setSendEmail] = useState(false)

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalBody className='!tw-p-6'>
        <div className='tw-mb-2 tw-flex tw-items-center tw-justify-between'>
          <WarningOctagon size={24} className='tw-fill-systemGold-100' />

          <ModalCloseButton toggle={toggle} />
        </div>

        <p className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
          {t('Payroll Approval Enable_disable message', {
            action: newValue ? 'enable' : 'disable',
          })}
        </p>

        {!newValue ? null : (
          <>
            <p
              className='tw-mb-6 tw-text-sm tw-text-text-80'
              style={{ maxWidth: '54ch' }}
            >
              {t(
                'By enabling Payroll Approval, all payments will need to be approved by one of the assigned approvers before you can process payroll.',
              )}
            </p>

            <Box className='tw-flex tw-items-center tw-justify-between'>
              <p className='tw-mb-0.5 tw-text-sm tw-font-bold'>
                {t('Notify users by emails')}
              </p>
              <Toggle
                check={sendEmail}
                change={(e) => setSendEmail(e.target.checked)}
                marginRight=''
              />
            </Box>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color='light' outline onClick={toggle} disabled={isLoading}>
          {t('Cancel')}
        </Button>
        <Button
          onClick={() => onConfirm({ sendEmail })}
          loading={isLoading}
          disabled={isLoading}
        >
          {newValue ? t('Enable') : t('Disable')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
