import { t } from 'i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import toastr from 'toastr'
import * as yup from 'yup'

import { PermissionTooltip } from '../../../../components/permission-tooltip'
import Button from '../../../../components/ui/button'
import Shimmer from '../../../../components/ui/shimmer'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../../../components/ui/side-menu'
import { CONTRACT_TYPES } from '../../../../core/config/contract-types'
import { EMPLOYEE_CONTRACT_TYPE, contractTypes } from '../../../../helpers/enum'
import { useFetch } from '../../../../helpers/hooks'
import {
  getContractCustomFields,
  updateContractCustomFields,
} from '../../../../services/api'
import { getErrorMessage } from '../../../../utils/get-errors'
import {
  SettingSectionHeading,
  SettingSectionSubHeading,
} from '../../../CompanySetting/components/settings-section-heading'
import { PERMISSION_GROUP } from '../../../CompanySetting/manage-role'
import { getCustomFieldSchema } from '../../components/add-milestone-modal'
import { AttributeFields } from '../../components/submit-work-modal'
import { LoadingItems } from '../payment-approvals'

export const CONTRACT_TYPE_MAP = {
  [CONTRACT_TYPES.FIXED]: 'fixed',
  [contractTypes.FIXED]: 'fixed',
  [CONTRACT_TYPES.PAY_AS_YOU_GO]: 'payg',
  [contractTypes.PAYG]: 'payg',
  [CONTRACT_TYPES.MILESTONES]: 'milestone',
  [contractTypes.MILESTONES]: 'milestone',
  [CONTRACT_TYPES.FULL_TIME]: 'eor',
  [contractTypes.FULL_TIME]: 'eor',
  [EMPLOYEE_CONTRACT_TYPE.EOR]: 'eor',
  [CONTRACT_TYPES.DIRECT_EMPLOYEE]: 'direct_employee',
  [contractTypes.DIRECT_EMPLOYEE]: 'direct_employee',
  [EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE]: 'direct_employee',
}

export function CustomFieldsSection({
  canManage,
  contract,
  contractLoading,
  onUpdate,
}) {
  const { data: fields, isLoading: isFieldsLoading } = useFetch(
    {
      action: getContractCustomFields,
      autoFetch: !!contract?.type,
      initResult: [],
      body: {
        area: 'contract',
        contract_type: CONTRACT_TYPE_MAP[contract?.type],
        active: 1,
        contractId: contract?.id,
      },
    },
    [contract?.type],
  )

  return (
    <div className='px-3 pb-3 px-md-4 pb-md-4'>
      <div className='tw-mb-4 tw-flex tw-items-center tw-justify-between tw-gap-4'>
        <div>
          <SettingSectionHeading
            learnMoreLink='https://help.remotepass.com/en/articles/9015015-custom-fields'
            learnMoreTitle='Learn more about custom fields'
          >
            {t('Custom fields')}
          </SettingSectionHeading>
          <SettingSectionSubHeading className='tw-mb-0'>
            {t(
              'Add custom fields to contract details, timesheets, and work submissions',
            )}
          </SettingSectionSubHeading>
        </div>

        {contractLoading ? null : (
          <ManageContractCustomFields
            canManage={canManage}
            fields={fields}
            onUpdate={onUpdate}
            contract={contract}
          />
        )}
      </div>

      <div className='tw-grid tw-gap-2 md:tw-grid-cols-2 md:tw-gap-4 xl:tw-grid-cols-4'>
        {isFieldsLoading || contractLoading ? (
          <LoadingItems
            length={2}
            noParent
            item={() => {
              return <Shimmer className='!tw-h-[59px] !tw-w-full tw-rounded' />
            }}
          />
        ) : (
          contract?.attributes?.map((attribute) => {
            return (
              <div
                key={attribute.id}
                className='tw-flex tw-flex-col tw-gap-px tw-rounded tw-border tw-border-secondary-30 tw-bg-surface-20 tw-px-4 tw-pb-2 tw-pt-3'
              >
                <div className='tw-text-[10px] tw-font-medium tw-text-text-80'>
                  {attribute.name}
                </div>
                <div className='tw-truncate tw-text-sm tw-text-text-80'>
                  {attribute.value}
                </div>
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}

const manageContractFormId = 'manage-contract-form'
function ManageContractCustomFields({ canManage, fields, contract, onUpdate }) {
  const [isOpen, setIsOpen] = useState(false)
  function toggle() {
    setIsOpen((open) => {
      // when closing, we should reset the form
      if (open) {
        reset()
      }

      return !open
    })
  }

  const { startFetch: updateAttributes, isLoading: updatingAttributes } =
    useFetch({
      action: updateContractCustomFields,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(t('Failed to update custom fields'))
        } else {
          toastr.success(t('Custom fields updated successfully'))
          toggle()
          onUpdate?.()
        }
      },
      onError: (error) => {
        toastr.error(getErrorMessage(error))
      },
    })

  const defaultCustomFields =
    contract?.attributes?.reduce((acc, attribute) => {
      acc[attribute.id] = attribute.value
      return acc
    }, {}) ?? {}

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { custom_field: defaultCustomFields },
    resolver: yupResolver(
      yup.object().shape({
        custom_field: getCustomFieldSchema(yup.object(), fields),
      }),
    ),
  })

  function onSubmit(values) {
    const body = { contract_id: contract.id }

    const customFields = Object.entries(values.custom_field)
    if (fields?.length > 0 && customFields.length > 0) {
      body.attributes = customFields.map(([id, value]) => ({
        id: Number(id),
        value: value ?? '',
      }))
      updateAttributes(body)
    } else {
      toastr.error(t('At least one custom field is required.'))
    }
  }

  return (
    <>
      <PermissionTooltip
        showing={!canManage}
        area={PERMISSION_GROUP.CONTRACT_SETTINGS.name}
        id='manage-custom-fields-btn'
      >
        <Button
          className='!tw-px-0 !tw-capitalize'
          color='link'
          onClick={toggle}
          disabled={!canManage}
        >
          {t('manage')}
        </Button>
      </PermissionTooltip>

      <SideMenu
        onClose={toggle}
        isOpen={isOpen}
        className='!tw-w-full tw-max-w-[532px]'
        itemListClassName='tw-grid tw-grid-rows-[77px_1fr_91px] [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain'
      >
        <SideMenuHeader toggle={toggle}>
          <div className='tw-flex tw-flex-col'>
            <div className='tw-font-semibold'>{t('Custom fields')}</div>
            <span className='tw-text-sm tw-text-text-60'>
              {t('Edit custom fields for this contract')}
            </span>
          </div>
        </SideMenuHeader>

        <SideMenuBody>
          <form
            id={manageContractFormId}
            onSubmit={handleSubmit(onSubmit)}
            className='tw-flex tw-flex-col tw-gap-4'
          >
            <AttributeFields
              fields={fields}
              control={control}
              errors={errors}
            />
          </form>
        </SideMenuBody>

        <SideMenuFooter>
          <Button
            color='light'
            outline
            onClick={toggle}
            disabled={updatingAttributes}
          >
            {t('Cancel')}
          </Button>
          <Button
            formId={manageContractFormId}
            disabled={updatingAttributes}
            loading={updatingAttributes}
          >
            {t('Save')}
          </Button>
        </SideMenuFooter>
      </SideMenu>
    </>
  )
}
