import showdown from 'showdown'
import TurndownService from 'turndown'

const turndownService = new TurndownService()

const placeholderExtension = () => [
  {
    type: 'lang',
    regex: /<!-- (.*?) -->\{\{(.*?)\}\}/g,
    replace: (match, label, id) => {
      return `<span data-placeholder="true" class="tw-text-primary tw-font-bold" id="${id}" label="${label}" contenteditable="false">${label}</span>`
    },
  },
]

turndownService.addRule('strikethrough', {
  filter: ['del', 's', 'strike'],
  replacement: function (content) {
    return '~~' + content + '~~'
  },
})

turndownService.addRule('placeholder', {
  filter: (node) =>
    node.nodeName === 'SPAN' && node.hasAttribute('data-placeholder'),
  replacement: (content, node) => {
    const id = node.getAttribute('id')
    const label = node.getAttribute('label')
    return `<!-- ${label} -->{{${id}}}`
  },
})

turndownService.addRule('underline', {
  filter: ['u'],
  replacement: function (content) {
    return '__' + content + '__'
  },
})

turndownService.addRule('empty-paragraph', {
  filter: 'br',
  replacement: () => {
    return '\n\n'
  },
})

const converter = new showdown.Converter({
  extensions: [placeholderExtension()],
})

export function makeMarkDown(body) {
  return turndownService.turndown(body).replaceAll(/\\_/g, '_')
}

export function makeHtml(body) {
  return converter
    .makeHtml(
      body
        .replaceAll(/\n\n /g, '<br/>')
        .replaceAll(/__([^_]+)__/g, '<u>$1</u>')
        .replaceAll(/~~([^~]+)~~/g, '<s>$1</s>'),
    )
    .replaceAll(/{{(.*?)}}/g, (match) => {
      return match.replace(/<\/?em>/g, '_')
    })
}
