import React from 'react'
import {
  Button,
  Dialog,
  DialogTrigger,
  Popover,
  TooltipTrigger,
} from 'react-aria-components'
import { CloseButton, cn } from 'ui'
import { EventContent, getCalendarEventIcon } from './event-item'
import { getEventColorsClasses, getEventTooltipText } from '../helpers'
import { TooltipV2 } from '../../../components/VerticalLayout/tooltip-v2'
import { format } from 'date-fns'
import { TimeOffRequestDetails } from '../timeoff-request'
import { track } from '../../../utils/analytics'
function MoreButton({
  slot,
  isCalendarView = false,
  itemsToShowPerSlot = 0,
  showText = true,
  className,
  fetchEvents,
}) {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
  const slotDate = format(
    slot.date ? new Date(slot.date) : new Date(slot.year, slot.month, slot.day),
    'dd MMMM yyyy',
  )

  const events = slot.events.sort((a, b) => a.placement - b.placement)
  return (
    <DialogTrigger isOpen={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <Button
        onPress={() => setIsPopoverOpen(true)}
        className={cn(
          'tw-mx-4 tw-flex tw-h-6 tw-w-[calc(100%-32px)] tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-surface-30 tw-px-4 tw-text-[10px] tw-uppercase tw-transition hover:tw-bg-surface-20',
          { 'tw-mx-auto !tw-h-8 !tw-w-8 !tw-p-0': !showText },
          { 'tw-mx-auto': isCalendarView },
          className,
        )}
      >
        {showText ? 'More' : `+ ${events.length - itemsToShowPerSlot}`}
      </Button>
      <Popover>
        <Dialog className='tw-w-[180px] focus-visible:tw-outline-none'>
          <div className='tw-rounded tw-bg-white tw-shadow-2xl'>
            <div className='tw-flex tw-h-10 tw-items-center tw-justify-between tw-gap-4 tw-border-b tw-border-surface-30 tw-px-4 tw-text-xs'>
              <div>{slotDate}</div>
              <CloseButton onClick={() => setIsPopoverOpen(false)} size={16} />
            </div>
            <div className='tw-flex tw-max-h-40 tw-flex-col tw-gap-2 tw-overflow-auto tw-p-2'>
              {events.map((event, index) => (
                <MoreEventItem
                  key={index}
                  event={event}
                  fetchEvents={fetchEvents}
                />
              ))}
            </div>
          </div>
        </Dialog>
      </Popover>
    </DialogTrigger>
  )
}

function MoreEventItem({ event, fetchEvents }) {
  const [showEvent, setShowEvent] = React.useState(false)

  const onCalendarEventClick = () => {
    track('Calendar event clicked', {
      Type: event.type,
    })
    if (['time_off_request', 'time_off'].includes(event.type)) {
      setShowEvent(true)
    }
  }
  const isStartOfEvent = event.isStartOfEvent
  const isEndOfEvent = event.isEndOfEvent
  return (
    <>
      {showEvent && (
        <TimeOffRequestDetails
          toggle={() => setShowEvent(false)}
          show={showEvent}
          event={event}
          fetchEvents={fetchEvents}
          icon={getCalendarEventIcon(event, 20)}
        />
      )}
      <TooltipTrigger delay={300} closeDelay={100}>
        <Button
          className={cn(
            'tw-inline-flex tw-h-6 tw-cursor-pointer tw-items-center tw-justify-start tw-gap-2 tw-px-3 tw-py-2 tw-transition-all tw-duration-300',
            getEventColorsClasses(event),
            {
              'tw-rounded-l-full': isStartOfEvent,
              'tw-rounded-r-full': isEndOfEvent,
            },
          )}
          onPress={onCalendarEventClick}
        >
          <EventContent event={event} className={cn('tw-truncate')} />
          <TooltipV2 placement='bottom'>
            <div className='tw-flex tw-flex-col tw-items-start tw-pt-1 tw-text-left'>
              <div>{getEventTooltipText(event).title}</div>
              <div className='tw-flex-shrink-0 tw-text-text-30'>
                {getEventTooltipText(event).text}
              </div>
            </div>
          </TooltipV2>
        </Button>
      </TooltipTrigger>
    </>
  )
}

export default MoreButton
