import React from 'react'
import {
  ListDashes,
  ListNumbers,
  TextB,
  TextItalic,
  TextStrikethrough,
  TextUnderline,
} from '@phosphor-icons/react'
import { EditorButton } from './editor-button'
import { ACTION_TYPE } from '../../tools/constants'

export function EditorActions({ editor, actionType }) {
  if (!editor) return null

  return (
    <>
      <div className='control-group'>
        <div className='tw-flex tw-flex-row tw-gap-1 tw-p-2'>
          <EditorButton
            editor={editor}
            icon={TextB}
            source='bold'
            action='toggleBold'
          />
          <EditorButton
            editor={editor}
            icon={TextItalic}
            source='italic'
            action='toggleItalic'
          />
          <EditorButton
            editor={editor}
            icon={TextStrikethrough}
            source='strike'
            action='toggleStrike'
          />

          {actionType === ACTION_TYPE.EMAIL ? (
            <EditorButton
              editor={editor}
              icon={TextUnderline}
              source='underline'
              action='toggleUnderline'
            />
          ) : null}

          <EditorButton
            editor={editor}
            icon={ListDashes}
            source='bulletList'
            action='toggleBulletList'
          />
          <EditorButton
            editor={editor}
            icon={ListNumbers}
            source='orderedList'
            action='toggleOrderedList'
          />
        </div>
      </div>
    </>
  )
}
