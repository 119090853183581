import { t } from 'i18next'
import { X } from '@phosphor-icons/react'
import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'

import UploadPreview from '../../../components/Common/upload-preview'
import Button from '../../../components/ui/button'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import {
  adminSignCoRContract,
  assignContractToSignatory,
  cancelInvitation,
  cancelSignatoryInvitation,
  clientCorSignSow,
  downloadCorSow,
  getAdminContractPDF,
  getContractPDF,
  getCorSowSubmit,
  getSignatoryList,
  rpSignCorSow,
  signContract,
} from '../../../services/api'
import { updateContractRef } from '../../../store/profile/actions'
import openFileV2 from '../../../utils/file/open-v2'
import { getFullName } from '../../../utils/get-full-name'
import KYCModal from '../components/KYCModal'
import { formatSignatoryName } from './SelectSignatoryDropdown'
import { parseJwt } from '../../../utils/parseJwt'
import permissions from '../../../helpers/permissions'

function DisabledButton({ children, ...props }) {
  return (
    <Button as='div' disabled block color='warning-disabled' {...props}>
      {children}
    </Button>
  )
}

export function CorClientAction({
  contract,
  updateContractDetail,
  inviteContractor,
}) {
  const corData = contract?.cor
  const isAdmin = location.pathname.startsWith('/admin')
  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  const history = useHistory()
  const { hasAccess } = usePermissions()

  const isPendingSowCreation = corData?.is_sow_created === 0
  const isPendingSowSignature = corData?.is_sow_client_signed === 0
  const isPendingMSAUploaded = corData?.is_cor_msa_uploaded === 0

  const isPendingDepositPayment =
    corData?.is_sow_client_signed === 1 && corData?.is_deposit_paid === 0

  const isDepositProcessing =
    corData?.is_deposit_processed === 1 ||
    corData?.is_deposit_processed === true

  const isPendingContractorInvite =
    !contract?.contractor && corData?.is_sow_client_signed === 1

  const { startFetch: clientSignSow, isLoading: signingSow } = useFetch({
    action: clientCorSignSow,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(t('Error occurred while signing the SOW'))
      } else {
        toastr.success(t('Successfully signed the SOW'))
        updateContractDetail?.()
      }
    },
    onError: (error) => {
      toastr.error(error, t('Error while signing the SOW'))
    },
  })
  function handleSignSow({ name }) {
    const body = { name, contract_id: contract.id }
    clientSignSow(body)
  }

  if (isPendingSowCreation) {
    return <DisabledButton>{t('Waiting SOW creation')}</DisabledButton>
  }

  if (isPendingMSAUploaded) {
    return (
      <>
        <DisabledButton id='pending-msa-signature-button'>
          {t('Pending MSA signature')}
        </DisabledButton>

        <UncontrolledTooltip target='pending-msa-signature-button'>
          {t('Contact your Customer Success Manager to complete this step')}
        </UncontrolledTooltip>
      </>
    )
  }

  if (isPendingSowSignature) {
    if (isAdmin) {
      return <DisabledButton>{t('Waiting Sow Signature')}</DisabledButton>
    } else {
      return (
        <SignSow
          loading={signingSow}
          onSignSow={handleSignSow}
          defaultName={getFullName(userProfile)}
          contract={contract}
          updateContractDetail={updateContractDetail}
        />
      )
    }
  }

  function goToPaymentsTab() {
    const pathname = history.location.pathname
    const search = history.location.search
    const searchWithoutTab = search.replace(/&tab=[^&]+/, '')
    const newLocation = `${pathname}${searchWithoutTab}&tab=payments`
    history.push(newLocation)
  }

  if (isPendingDepositPayment) {
    if (isAdmin) {
      return <DisabledButton>{t('Waiting Deposit Payment')}</DisabledButton>
    } else {
      if (isDepositProcessing) {
        return <DisabledButton>{t('Deposit processing')}</DisabledButton>
      } else {
        return (
          <Button block onClick={goToPaymentsTab}>
            {t('Pay Deposit')}
          </Button>
        )
      }
    }
  }

  if (isPendingContractorInvite) {
    if (isAdmin) {
      return (
        <DisabledButton>{t('Pending contractor invitation')}</DisabledButton>
      )
    } else {
      return (
        <Button
          disabled={!hasAccess(permissions.InviteToContracts)}
          block
          onClick={inviteContractor}
        >
          {t('Invite contractor')}
        </Button>
      )
    }
  }

  return (
    <>
      <DisabledButton color='success-disabled'>
        {t('SOW signed')}
      </DisabledButton>
      <DownloadCorSow contract={contract} />
    </>
  )
}

function DownloadCorSow({ contract }) {
  const contractRef = contract?.ref
  const fileToken = contract?.file

  const { startFetch: downloadSow, isLoading: downloadingSow } = useFetch({
    action: downloadCorSow,
    onComplete: (data, body) => {
      const name = `sow_${body?.contractRef}.pdf`

      openFileV2(data, { download: true, name })
    },
  })

  function handleDownloadSow() {
    downloadSow({ contractRef, token: fileToken })
  }

  return (
    <Button
      color='link'
      onClick={handleDownloadSow}
      size='sm'
      loading={downloadingSow}
      disabled={downloadingSow}
    >
      {t('Download SOW')}
    </Button>
  )
}

export function CorRpAction({ contract, updateContractDetail }) {
  const corData = contract?.cor
  const isAdmin = location.pathname.startsWith('/admin')
  const loginToken = useSelector((state) => state.Login?.loginToken)

  const isFixedContract = contract?.type === CONTRACT_TYPES.FIXED

  const isPendingSowCreation = corData?.is_sow_created === 0
  const rpWaitingClientSignature =
    corData?.is_sow_client_signed === 0 && corData?.is_sow_rp_signed === 0

  const isDepositProcessing =
    corData?.is_deposit_processed === 1 ||
    corData?.is_deposit_processed === true

  const shouldRpSignSow =
    (isFixedContract ? corData?.is_deposit_paid === 1 : true) &&
    corData?.is_sow_client_signed === 1 &&
    corData?.is_sow_rp_signed === 0
  const isRpWaitingInvite =
    corData?.is_sow_client_signed === 1 &&
    corData?.is_sow_rp_signed === 1 &&
    !contract.contractor
  const isPendingContractorSignature = contract?.contractor_signed === 0
  const isWaitingContractSignature =
    corData?.is_sow_client_signed === 1 &&
    corData?.is_sow_rp_signed === 1 &&
    contract?.client_signed === 0
  const isContractSigned =
    contract?.client_signed === 1 || contract?.client_signed === true

  const jwtData = isAdmin ? parseJwt(loginToken) : ''
  const adminFullName = getFullName(jwtData)

  const { startFetch: adminSignContract, isLoading: signingContract } =
    useFetch({
      action: adminSignCoRContract,
      withAdminAccess: true,
      onComplete: updateContractDetail,
    })

  const { startFetch: rpSignSow, isLoading: signingSow } = useFetch({
    action: rpSignCorSow,
    withAdminAccess: true,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(t('Error while creating the SOW'))
      } else {
        updateContractDetail?.()
      }
    },
    onError: (error) => {
      toastr.error(error?.message, t('Error while creating the SOW'))
    },
  })

  function handleSignSow({ name }) {
    const body = { name, contract_id: contract.id }
    rpSignSow(body)
  }

  function handleSignContract() {
    const body = { contract_id: contract?.id, name: adminFullName }
    adminSignContract(body)
  }

  if (isPendingSowCreation) {
    if (isAdmin) {
      return <RpSubmitSow contract={contract} onUpdate={updateContractDetail} />
    } else {
      return <DisabledButton>{t('Creating SOW')}</DisabledButton>
    }
  }

  if (rpWaitingClientSignature) {
    return <DisabledButton>{t('Waiting client signature')}</DisabledButton>
  }

  if (isDepositProcessing) {
    return <DisabledButton>{t('Waiting for Deposit')}</DisabledButton>
  }

  if (shouldRpSignSow) {
    if (isAdmin) {
      return (
        <SignSow
          loading={signingSow}
          onSignSow={handleSignSow}
          defaultName={adminFullName}
          contract={contract}
          updateContractDetail={updateContractDetail}
          isAdmin={isAdmin}
        />
      )
    } else {
      return <DisabledButton>{t('Pending SOW signature')}</DisabledButton>
    }
  }

  if (isRpWaitingInvite) {
    return <DisabledButton>{t('Pending contractor invitation')}</DisabledButton>
  }

  if (isPendingContractorSignature) {
    return <DisabledButton>{t('Pending contractor signature')}</DisabledButton>
  }

  if (isWaitingContractSignature) {
    if (isAdmin) {
      return (
        <ContractSignContract
          contract={contract}
          isAdmin={isAdmin}
          fullName={adminFullName}
          onSign={handleSignContract}
          loading={signingContract}
        />
      )
    } else {
      return <DisabledButton>{t('Pending signature')}</DisabledButton>
    }
  }

  if (isContractSigned) {
    return (
      <DisabledButton color='success-disabled'>{t('Signed')}</DisabledButton>
    )
  }

  return null
}

export function CorContractorAction({ contract, updateContractDetail }) {
  const [kycRequired, setKycRequired] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector((state) => state.Account?.user)
  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  const isAdmin = location.pathname.startsWith('/admin')
  const isClient = user?.type === userTypes.COMPANY

  const isNotContractor = isAdmin || isClient

  const isPendingSignature = contract?.contractor_signed === 0
  const isSigned = contract?.contractor_signed === 1

  const { startFetch: contractorSignContract, isLoading } = useFetch({
    action: signContract,
    onComplete: () => {
      toastr.success(t('Contract signed successfully'))
      updateContractDetail?.()
    },
    onError: (error) => {
      toastr.error(error?.message, t('Error while signing the contract'))
    },
  })

  const contractorFullName = getFullName(contract?.contractor)

  function handleContractorSign() {
    const body = { name: contractorFullName, contract_id: contract?.id }

    contractorSignContract(body)
  }

  if (isPendingSignature) {
    if (isNotContractor) {
      if (contract?.amended === 1 || isAdmin) {
        return <DisabledButton>{t('Pending signature')}</DisabledButton>
      } else {
        return (
          <CancelContractorInvitation
            updateContractDetail={updateContractDetail}
            contract={contract}
          />
        )
      }
    }

    if (userProfile?.kyc_verified === 0) {
      return (
        <>
          <Button block onClick={() => setKycRequired(true)}>
            {t('Sign contract')}
          </Button>

          <KYCModal
            toggle={() => setKycRequired(false)}
            isOpen={kycRequired}
            proceed={() => {
              setKycRequired(false)
              dispatch(updateContractRef({ ref: contract?.ref, type: 'kyc' }))
              history.push('/settings#kyc')
            }}
          />
        </>
      )
    }

    return (
      <ContractSignContract
        contract={contract}
        fullName={contractorFullName}
        onSign={handleContractorSign}
        loading={isLoading}
      />
    )
  }
  if (isSigned) {
    return (
      <DisabledButton color='success-disabled'>{t('Signed')}</DisabledButton>
    )
  }

  return null
}

function RpSubmitSow({ contract, onUpdate }) {
  const { startFetch: submitSow, isLoading } = useFetch({
    action: getCorSowSubmit,
    withAdminAccess: true,
    onComplete: () => {
      toastr.success(t('Sow submitted successfully'))
      onUpdate?.()
    },
    onError: (err) => {
      toastr.error(err, t('Error while submitting the SOW'))
    },
  })

  return (
    <Button
      onClick={() => {
        submitSow({ contract_id: contract?.id })
      }}
      loading={isLoading}
      disabled={isLoading}
    >
      {t('Submit Sow')}
    </Button>
  )
}

function SignSow({
  onSignSow,
  defaultName,
  contract,
  loading,
  updateContractDetail,
  isAdmin,
}) {
  const user = useSelector((state) => state.Account?.user)

  const [isOpen, setIsOpen] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const { hasAccess } = usePermissions()

  function toggle() {
    setIsOpen((open) => !open)
  }

  function toggleDropdown() {
    setIsDropdownOpen((open) => !open)
  }

  const { startFetch: assign } = useFetch({
    action: assignContractToSignatory,
    onComplete: () => {
      updateContractDetail?.()
    },
  })

  const { startFetch: cancel, isLoading: cancelingInvitation } = useFetch({
    action: cancelSignatoryInvitation,
    onComplete: () => {
      updateContractDetail?.()
    },
  })

  const { data: signatories } = useFetch({
    action: getSignatoryList,
    autoFetch: true,
  })

  const formattedSignatories = signatories?.map((signatory) =>
    formatSignatoryName(signatory, user),
  )

  function handleSignatorySelection(signatory) {
    if (signatory?.email === user?.email) {
      setIsOpen(true)
    } else {
      assign({ contract_id: contract?.id, signatory_id: signatory?.id })
    }
  }

  function handleCancelInvitation() {
    cancel({ contract_id: contract?.id })
  }

  if (contract?.signatory && !isAdmin && contract?.signatory?.id !== user?.id) {
    const signatoryFullName = getFullName(contract?.signatory)
    return (
      <>
        <Button
          block
          onClick={handleCancelInvitation}
          loading={cancelingInvitation}
          disabled={
            cancelingInvitation || !hasAccess(permissions.CancelInvitations)
          }
          color='danger-cancel'
        >
          {t('Cancel invitation')}
        </Button>
        <div className='text-muted font-size-12'>
          {t('Assigned to')} {signatoryFullName}
        </div>
      </>
    )
  }

  const condition1 =
    contract?.signatory && !isAdmin && contract?.signatory?.id === user?.id
  const condition2 = formattedSignatories?.length <= 0 || isAdmin

  return (
    <>
      {condition1 || condition2 ? (
        <Button block onClick={toggle} loading={loading} disabled={loading}>
          {t('Sign SOW')}
        </Button>
      ) : (
        <Dropdown
          isOpen={isDropdownOpen}
          toggle={toggleDropdown}
          className='w-100'
        >
          <DropdownToggle tag={Button} className='w-100'>
            {t('Select signatory')}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>{t('Select signatory')}</DropdownItem>

            {formattedSignatories?.map((signatory) => {
              return (
                <DropdownItem
                  key={signatory.id}
                  onClick={() => handleSignatorySelection(signatory)}
                >
                  {signatory.itemName}
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        </Dropdown>
      )}

      <SignSowModal
        isOpen={isOpen}
        toggle={toggle}
        onSignSow={onSignSow}
        defaultName={defaultName}
        contract={contract}
        loading={loading}
      />
    </>
  )
}

function SignSowModal({
  contract,
  defaultName,
  onSignSow,
  loading,
  toggle,
  isOpen,
}) {
  const contractRef = contract?.ref
  const fileToken = contract?.file

  const [name, setName] = useState(defaultName)

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader
        close={
          <button
            type='button'
            onClick={toggle}
            className='close text-secondary-100 p-1'
            aria-label='Close'
            style={{ width: 'auto', height: 'auto' }}
          >
            <X size={20} />
          </button>
        }
      >
        {t('Sign the SOW')}
      </ModalHeader>
      <ModalBody>
        <UploadPreview
          style={{ minHeight: 'max(65vh, 430px)' }}
          preview={{
            type: 'application/pdf',
            data: `${process.env.REACT_APP_API_HOST}/api/contract/cor/sow/view/${contractRef}/${fileToken}`,
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          className='flex-grow-1'
          style={{ width: 'unset' }}
        />

        <Button
          onClick={() => onSignSow({ name, onFinished: toggle })}
          loading={loading}
          disabled={loading}
        >
          {t('Sign SOW')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function ContractSignContract({
  contract,
  fullName,
  onSign,
  loading,
  isAdmin,
}) {
  const [isOpen, setIsOpen] = useState(false)

  function toggle() {
    setIsOpen((open) => !open)
  }

  const [pdfUrl, setPdfUrl] = useState()
  useFetch({
    action: isAdmin ? getAdminContractPDF : getContractPDF,
    autoFetch: true,
    body: contract,
    withAdminAccess: isAdmin,
    onComplete: (data) => {
      const objectUrl = URL.createObjectURL(data)
      setPdfUrl(objectUrl)
    },
  })

  return (
    <>
      <Button block onClick={toggle} loading={loading} disabled={loading}>
        {t('Sign contract')}
      </Button>

      <Modal isOpen={isOpen} toggle={toggle} size='lg'>
        <ModalHeader
          close={
            <button
              type='button'
              onClick={toggle}
              className='close text-secondary-100 p-1'
              aria-label='Close'
              style={{ width: 'auto', height: 'auto' }}
            >
              <X size={20} />
            </button>
          }
        >
          {t('Sign the contract')}
        </ModalHeader>

        <ModalBody>
          <UploadPreview
            style={{ minHeight: 'max(65vh, 430px)' }}
            preview={{ type: 'application/pdf', data: pdfUrl }}
          />
        </ModalBody>

        <ModalFooter>
          <Input
            defaultValue={fullName}
            disabled
            className='flex-grow-1'
            style={{ width: 'unset' }}
          />

          <Button onClick={toggle} color='light' outline disabled={loading}>
            {t('Close')}
          </Button>
          <Button onClick={onSign} disabled={loading} loading={loading}>
            {t('Sign contract')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

function CancelContractorInvitation({ updateContractDetail, contract }) {
  const { startFetch: cancelContractorInvitation, isLoading: canceling } =
    useFetch({
      action: cancelInvitation,
      onComplete: () => updateContractDetail(false),
    })

  const { hasAccess } = usePermissions()

  return (
    <>
      <Button
        color='danger-cancel'
        block
        onClick={() => {
          cancelContractorInvitation({ contract_id: contract?.id })
        }}
        loading={canceling}
        disabled={canceling || !hasAccess(permissions.CancelInvitations)}
      >
        {t('Cancel invitation')}
      </Button>

      {!contract.invitation_url ? null : (
        <CopyToClipboard
          text={contract.invitation_url}
          onCopy={() => toastr.info(t('Invitation link copied to clipboard'))}
        >
          <div className='text-primary cursor-pointer font-size-12 mt-1'>
            {t('Copy Invitation Link')}
          </div>
        </CopyToClipboard>
      )}
    </>
  )
}
