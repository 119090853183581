import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import { Avatar } from 'ui'

import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import { freshUpdateContractor } from '../../../store/fresh/actions'
import { updateNetSuiteContractor } from '../../../store/ns/actions'
import { qbUpdateContractor } from '../../../store/qb/actions'
import { updateContractor } from '../../../store/xero/actions'
import { updateZohoContractor } from '../../../store/zoho/actions'
import { IntegrationAccountMapping } from './XeroAccountMapping'

function MappingAccountModal({
  isOpen,
  toggle,
  contractor,
  platform = 'Xero',
}) {
  const dispatch = useDispatch()

  function onSubmitted(values) {
    const payload = {
      ...contractor,
      categories: values,
    }
    switch (platform) {
      case 'Xero':
        dispatch(updateContractor(payload))
        break
      case 'Freshbooks':
        dispatch(freshUpdateContractor(payload))
        break
      case 'Quickbooks':
        dispatch(qbUpdateContractor(payload))
        break
      case 'Zoho':
        dispatch(updateZohoContractor(payload))
        break
      case 'NetSuite':
        dispatch(updateNetSuiteContractor(payload))
        break
    }
  }

  return (
    <Modal
      size='lg'
      centered
      isOpen={isOpen}
      role='dialog'
      tabindex='-1'
      toggle={toggle}
    >
      <ModalHeader close={<ModalCloseButton toggle={toggle} />}>
        Update
      </ModalHeader>
      <ModalBody>
        <Row className='!tw-mx-0 tw-mb-6 tw-items-center'>
          <Avatar name={contractor?.name} />
          <Col className='!tw-pl-2'>
            <h6 className='tw-mb-1'>{contractor?.name}</h6>
            <p className='tw-mb-0 tw-text-text-80'>{contractor?.email}</p>
          </Col>
        </Row>
        <h5 className='tw-mb-1 tw-text-base'>{platform} Accounts</h5>
        <p className='tw-mb-9 tw-text-text-80'>
          RemotePass categories on the left will be posted to the {platform}{' '}
          accounts on the right
        </p>

        <IntegrationAccountMapping
          context='modal'
          onSubmitted={onSubmitted}
          contractor={contractor}
          platform={platform}
        />
      </ModalBody>

      <ModalFooter className='tw-bottom-0 tw-left-0 tw-right-0 tw-z-[1030] tw-bg-white max-md:tw-sticky'>
        <Button outline type='button' color='light' onClick={toggle}>
          Cancel
        </Button>
        <Button
          type='button'
          color='primary'
          onClick={() => {
            document.getElementById('submit-xero-account-mapping-modal').click()
            toggle()
          }}
        >
          Save changes
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default MappingAccountModal
