import { Storefront } from '@phosphor-icons/react'
import { t } from 'i18next'
import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Container } from 'reactstrap'
import toastr from 'toastr'

import Head from '../../../components/head'
import ModalHeader from '../../../components/ModalHeader'
import Alert from '../../../components/ui/alert'
import Button from '../../../components/ui/button'
import { useBillModuleActive } from '../bill-permission-hook'
import billSuccessIllustration from './bill-success-illustration.svg'

export function GoToVendorScreen() {
  const history = useHistory()
  const { vendorId } = useParams()

  function handleReturn() {
    history.push(history.location.state?.backRoute ?? '/bills')
  }
  useBillModuleActive()
  return (
    <Container fluid className='px-0'>
      <Head title={t('Go to vendor')} />

      <ModalHeader action={handleReturn}>
        <h2 className='tw-mb-0 tw-text-center tw-text-sm tw-font-bold'>
          {t('Bill creation')}
        </h2>
      </ModalHeader>

      <div className='tw-mx-auto tw-my-16 tw-max-w-3xl tw-rounded tw-bg-white tw-p-14'>
        <div className='tw-mx-auto tw-max-w-sm tw-space-y-8 tw-text-center'>
          <img
            src={billSuccessIllustration}
            alt=''
            aria-hidden
            width={384}
            height={268.5}
          />

          <div>
            <h3 className='tw-mb-2 tw-text-2xl tw-font-medium'>
              {t('Bill created')}
            </h3>
            <p className='tw-mb-0 tw-text-sm tw-font-semibold tw-text-text-60'>
              {t(
                'It looks like you created a bill for a new vendor. You can either add the vendor company and bank details now or do it later.',
              )}
            </p>
          </div>
          <Alert
            color='info'
            className='!tw-border-0 !tw-p-4 tw-text-start !tw-font-medium'
            innerClassName='tw-text-text-80 tw-text-xs'
          >
            {t(
              'This bill will be listed in the draft until the vendor details are added and verified.',
            )}
          </Alert>

          <div className='tw-flex tw-gap-3'>
            <Button
              outline
              color='light'
              block
              icon={<Storefront size={20} />}
              tag={Link}
              to={`/bills/vendors?selectedItem=${vendorId}`}
              onClick={() => {
                toastr.clear()
              }}
            >
              {t('Add vendor details')}
            </Button>
            <Button tag={Link} to='/bills'>
              {t('Do it later')}
            </Button>
          </div>
        </div>
      </div>
    </Container>
  )
}
