import { yupResolver } from '@hookform/resolvers/yup'
import { ArrowLeft } from '@phosphor-icons/react'
import React, { useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledInput from '../../../../components/ControlledInput'
import ControlledSelect from '../../../../components/ControlledSelect'
import ControlledCheckbox from '../../../../components/controlled-checkbox'
import Alert from '../../../../components/ui/alert'
import Button from '../../../../components/ui/button'
import Loader from '../../../../components/ui/loader'
import PageHeading from '../../../../components/ui/page-heading'
import { useFetch } from '../../../../helpers/hooks'
import {
  deDeleteJurisdictionBankCountry,
  deDeleteJurisdictionCurrency,
  deGetJurisdiction,
  deGetPayrollCutoffDates,
  deUpdateJurisdiction,
  getAdminJurisdictionContributionPlans,
} from '../../../../services/api'
import {
  mapCountryToOption,
  mapCurrencyToOption,
} from '../../../../utils/map-to-option'
import LabelContent from '../../../Contract/CreateContract/components/label-content'

export default function JurisdictionEdit() {
  const { id } = useParams()

  const {
    data: jurisdiction,
    isLoading,
    startFetch: getJurisdiction,
  } = useFetch({
    action: deGetJurisdiction,
    autoFetch: true,
    withAdminAccess: true,
    body: { id },
  })

  return (
    <div className='page-content'>
      <PageHeading>
        <PageHeading.Title
          action={
            <Link to='/admin/jurisdictions' className='text-text-80'>
              <ArrowLeft size={24} />
            </Link>
          }
        >
          Jurisdiction Edit
        </PageHeading.Title>
      </PageHeading>

      <Card style={{ minHeight: '30rem' }}>
        {isLoading ? (
          <Loader minHeight='30rem' />
        ) : (
          <CardBody className='py-4 px-0'>
            <EditJurisdictions
              jurisdiction={jurisdiction}
              onUpdate={() => getJurisdiction({ id })}
            />
          </CardBody>
        )}
      </Card>
    </div>
  )
}

export const jurisdictionDateFormat = 'yyyy-MM-dd'

export function getDefaultJurisdiction(jurisdiction) {
  const currencies = jurisdiction?.currencies.map((currency) => currency.id)
  const countryId = jurisdiction?.country?.id
  const bankCountries = jurisdiction?.bank_countries.map(
    (country) => country.id,
  )
  const contributionPlanIds = jurisdiction?.contribution_plans.map(
    (plan) => plan?.id,
  )

  const isInternalPayrollEnabled =
    jurisdiction?.is_internal_payroll_enabled === 1
  const isExternalPayrollEnabled =
    jurisdiction?.is_external_payroll_enabled === 1

  return {
    ...jurisdiction,
    bank_countries: bankCountries,
    contribution_plan_ids: contributionPlanIds,
    currencies,
    country_id: countryId,
    is_internal_payroll_enabled: isInternalPayrollEnabled,
    is_external_payroll_enabled: isExternalPayrollEnabled,
  }
}

export function getBoolean(bool) {
  return bool ? 1 : 0
}

export const jurisdictionSchema = yup.object().shape({
  name: yup.string().required(),
  city: yup.string().required(),
  country_id: yup.number().required('Country is required'),
  is_internal_payroll_enabled: yup
    .boolean()
    .test(
      'payment-method-1',
      'At least one payment method is required',
      (value, { parent }) => value || parent.is_external_payroll_enabled,
    ),
  is_external_payroll_enabled: yup
    .boolean()
    .test(
      'payment-method-2',
      'At least one payment method is required',
      (value, { parent }) => value || parent.is_internal_payroll_enabled,
    ),
  is_wps_enabled: yup.boolean().notRequired(),
  is_employee_identifier_required: yup.boolean().notRequired(),
  currencies: yup
    .array()
    .of(yup.number())
    .min(1, 'At least one currency is required')
    .required('At least one currency is required'),
  bank_countries: yup
    .array()
    .of(yup.number())
    .min(1, 'At least one bank country is required')
    .required('At least one bank country is required'),
  payroll_cutoff_date: yup.string().required(),
})

function EditJurisdictions({ jurisdiction, onUpdate }) {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    resetField,
  } = useForm({
    defaultValues: getDefaultJurisdiction(jurisdiction),
    resolver: yupResolver(jurisdictionSchema),
  })

  const { startFetch: updateJurisdiction, isLoading } = useFetch({
    action: deUpdateJurisdiction,
    withAdminAccess: true,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(
          typeof data?.message === 'string'
            ? data.message
            : 'Failed to update jurisdiction',
        )
      } else {
        onUpdate?.()
      }
    },
    onError: (err) => {
      toastr.error(
        typeof err === 'string' ? err : 'Failed to update jurisdiction',
      )
    },
  })

  function onSubmit(data) {
    const body = {
      ...data,
      is_wps_enabled: getBoolean(data.is_wps_enabled),
      is_external_payroll_enabled: getBoolean(data.is_external_payroll_enabled),
      is_internal_payroll_enabled: getBoolean(data.is_internal_payroll_enabled),
      is_employee_identifier_required: getBoolean(
        data.is_employee_identifier_required,
      ),
      jurisdiction_id: jurisdiction.id,
    }

    updateJurisdiction(body)
  }

  function handleDeleteError(err) {
    const message = typeof err === 'string' ? err : null
    toastr.error(
      message ?? 'Failed to delete item',
      message ? 'Failed to delete item' : 'Error',
    )
    onUpdate?.()
  }

  function handleDeleteComplete(data) {
    if (data?.success === false) {
      toastr.error(
        typeof data?.message === 'string'
          ? data.message
          : 'Failed to delete item',
      )
      onUpdate?.()
    } else {
      onUpdate?.()
      toastr.success('Item deleted successfully')
    }
  }

  const { startFetch: deleteCurrency } = useFetch({
    action: deDeleteJurisdictionCurrency,
    withAdminAccess: true,
    onComplete: handleDeleteComplete,
    onError: handleDeleteError,
  })
  const { startFetch: deleteBankCountry } = useFetch({
    action: deDeleteJurisdictionBankCountry,
    withAdminAccess: true,
    onComplete: handleDeleteComplete,
    onError: handleDeleteError,
  })

  function handleRemoveItem({ removedValue, name }) {
    const body = {
      jurisdiction_id: jurisdiction.id,
    }

    if (removedValue?.__isNew__ === true) {
      return
    }

    let deleteFunction = () => {}

    if (name === 'currencies') {
      deleteFunction = deleteCurrency
      body.currency_id = removedValue.value
    } else if (name === 'bank_countries') {
      deleteFunction = deleteBankCountry
      body.country_id = removedValue.value
    }

    deleteFunction(body)
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <JurisdictionForm
          control={control}
          errors={errors}
          setValue={setValue}
          handleRemoveItem={handleRemoveItem}
          resetField={resetField}
        />

        <Container fluid className='mt-4'>
          <Row>
            <Col>
              <Button type='submit' loading={isLoading} disabled={isLoading}>
                Update jurisdiction
              </Button>
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  )
}

export function JurisdictionForm({
  control,
  errors,
  setValue,
  handleRemoveItem,
  resetField,
}) {
  const { countries, currencies } = useSelector(
    (state) => state.Layout?.staticData ?? {},
  )

  function detectRemoveItem(value, { action, removedValue, name }) {
    const removeAction = action === 'remove-value'

    if (removeAction) {
      handleRemoveItem?.({ removedValue, name })
    }

    return value
  }

  const {
    contribution_plan_ids: contributionPlanIds,
    is_wps_enabled: isWpsEnabled,
    country_id: countryId,
  } = useWatch({
    control,
  })

  useEffect(() => {
    // reset contribution_plan_ids on country change
    resetField('contribution_plan_ids')
  }, [countryId])

  const { data: plans } = useFetch(
    {
      action: getAdminJurisdictionContributionPlans,
      body: { countryId },
      initResult: [],
      withAdminAccess: true,
      autoFetch: Boolean(countryId),
    },
    [countryId],
  )

  const { data: payrollCutoffDates } = useFetch({
    action: deGetPayrollCutoffDates,
    initResult: [],
    withAdminAccess: true,
    autoFetch: true,
  })

  const contributionPlanPercentages = plans?.filter(
    (plan) =>
      contributionPlanIds?.includes(plan.id) &&
      plan?.configs?.contribution_percentages,
  )
  return (
    <Container fluid>
      <Row>
        <Col md={4} lg={3}>
          <ControlledInput
            control={control}
            label={<LabelContent required>Name</LabelContent>}
            name='name'
            containerClassName='tw-mb-4'
            error={errors.name}
          />
        </Col>
        <Col md={4} lg={3}>
          <ControlledInput
            control={control}
            label={<LabelContent required>City</LabelContent>}
            name='city'
            containerClassName='tw-mb-4'
            error={errors.city}
          />
        </Col>
        <Col md={4} lg={3}>
          <ControlledSelect
            control={control}
            label='Country'
            name='country_id'
            containerClassName='tw-mb-4'
            error={errors.country_id}
            options={countries?.map((country) => mapCountryToOption(country))}
            required
          />
        </Col>
        <Col md={4} lg={3}>
          <ControlledCheckbox
            control={control}
            label={
              <LabelContent required>Is internal payroll enabled</LabelContent>
            }
            name='is_internal_payroll_enabled'
            id='is_internal_payroll_enabled'
            containerClassName='tw-mb-4 tw-mt-6'
            error={errors.is_internal_payroll_enabled}
          />
        </Col>
        <Col md={4} lg={3}>
          <ControlledCheckbox
            control={control}
            label={
              <LabelContent required>Is external payroll enabled</LabelContent>
            }
            name='is_external_payroll_enabled'
            id='is_external_payroll_enabled'
            containerClassName='tw-mb-4 tw-mt-6'
            error={errors.is_external_payroll_enabled}
          />
        </Col>
        <Col md={4} lg={3}>
          <ControlledCheckbox
            control={control}
            label={<LabelContent>Is WPS enabled</LabelContent>}
            name='is_wps_enabled'
            id='is_wps_enabled'
            containerClassName='tw-mb-4 tw-mt-6'
            error={errors.is_wps_enabled}
            transform={{
              output: (e) => {
                // update is_employee_identifier_required based on isWpsEnabled
                setValue?.('is_employee_identifier_required', e.target.checked)
                return e
              },
            }}
          />
        </Col>
        <Col md={4} lg={3}>
          {isWpsEnabled && (
            <UncontrolledTooltip target='is_employee_identifier_required_div'>
              &quot;Is employee identifier required&quot; must be on if the
              &quot;Is WPS enabled&quot; toggle is on
            </UncontrolledTooltip>
          )}
          <div id='is_employee_identifier_required_div'>
            <ControlledCheckbox
              control={control}
              label={
                <LabelContent>Is employee identifier required</LabelContent>
              }
              name='is_employee_identifier_required'
              id='is_employee_identifier_required'
              containerClassName='tw-mb-4 tw-mt-6'
              error={errors.is_employee_identifier_required}
              disabled={isWpsEnabled}
            />
          </div>
        </Col>
      </Row>

      <Row className='tw-gap-y-4'>
        <Col md={8} lg={6}>
          <ControlledSelect
            control={control}
            name='currencies'
            label={<LabelContent required>Currencies</LabelContent>}
            options={currencies?.map((currency) =>
              mapCurrencyToOption(currency, 'id'),
            )}
            isMulti
            transform={{ output: detectRemoveItem }}
            containerClassName='tw-mb-4'
            isClearable={false}
            error={errors.currencies}
          />
        </Col>
        <Col md={8} lg={6}>
          <ControlledSelect
            control={control}
            name='bank_countries'
            label={<LabelContent required>Bank countries</LabelContent>}
            options={countries?.map((country) => mapCountryToOption(country))}
            isMulti
            transform={{ output: detectRemoveItem }}
            containerClassName='tw-mb-4'
            isClearable={false}
            error={errors.bank_countries}
          />
        </Col>
        <Col md={8} lg={6}>
          <ControlledSelect
            control={control}
            id='contribution_plan_ids'
            name='contribution_plan_ids'
            label={
              <LabelContent
                required
                tooltipText={
                  !countryId
                    ? 'Select a country to view available pension plans'
                    : null
                }
                tooltipId='ppttId'
              >
                Pension plan
              </LabelContent>
            }
            options={plans?.map((plan) => ({
              label: plan.name,
              value: plan.id,
            }))}
            containerClassName='tw-mb-4'
            isClearable
            isMulti
          />
          {contributionPlanPercentages?.map((_plan, i) => (
            <Alert
              key={i}
              color='info'
              className='tw-my-3 tw-items-center !tw-p-4'
              innerClassName='tw-text-black tw-text-xs'
            >
              {_plan?.name} {'>'} 5 year tenure contribution{' '}
              <span className='tw-px-1 tw-text-text-80'>
                Min. of{' '}
                {
                  _plan?.configs?.contribution_percentages
                    ?.five_years_and_more_tenure
                }
              </span>{' '}
              | {'<'} 5 year tenure contribution{' '}
              <span className='tw-px-1 tw-text-text-80'> Min. of </span>
              {
                _plan?.configs?.contribution_percentages
                  ?.less_than_five_years_tenure
              }
            </Alert>
          ))}
        </Col>
        <Col md={8} lg={6}>
          <ControlledSelect
            control={control}
            name='payroll_cutoff_date'
            label={<LabelContent>Payroll cutoff date</LabelContent>}
            options={payrollCutoffDates?.map((date) => ({
              label: date,
              value: date,
            }))}
            isClearable
            containerClassName='tw-mb-4'
            error={errors.payroll_cutoff_date}
          />
        </Col>
      </Row>
    </Container>
  )
}
