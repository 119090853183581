import { format } from 'date-fns'

export function searchParamsFromObject(
  data = {},
  addQuestionMark = true,
  transformDatesFormat = false,
  transformArrays = false,
) {
  const params = new URLSearchParams()
  Object.entries(data).forEach(([key, value]) => {
    if (!value && typeof value !== 'number') return
    if (
      ['fromDate', 'toDate', 'start_date', 'end_date', 'date'].includes(key) &&
      transformDatesFormat
    ) {
      params.append(key, format(value, 'yyyy-MM-dd'))
    } else {
      if (Array.isArray(value) && transformArrays) {
        value.forEach((v) => {
          params.append(key + '[]', v)
        })
      } else {
        params.append(key, value)
      }
    }
  })

  let sp = params.toString()

  if (sp && addQuestionMark) {
    sp = ['?', sp].join('')
  }

  if (sp && !addQuestionMark) {
    sp = ['&', sp].join('')
  }

  return sp ?? ''
}
