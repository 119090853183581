import { ArrowLeft } from '@phosphor-icons/react'
import React from 'react'
import { Link } from 'react-router-dom'

export function IntegrationReviewHeader({ integrationName }) {
  return (
    <div className='tw-flex tw-items-center tw-gap-3'>
      <Link
        to='/settings/integrations'
        className='tw-text-current rtl:tw-rotate-180'
      >
        <ArrowLeft size={24} />
      </Link>

      <h2 className='tw-mb-0'>{integrationName}</h2>
    </div>
  )
}
