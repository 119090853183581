import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'reactstrap'

import { logoutUser } from '../services/api'
import { resetReduxState } from '../store/reducers'
import ModalHeader from './ModalHeader'
import { SimpleCard } from './simple-card'
import Button from './ui/button'

export function ErrorBoundaryFallback({
  error,
  resetError,
  componentStack,
  eventId,
}) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.Account?.user)

  const errorStack = [error?.message, componentStack].filter(Boolean).join(' ')

  function handleHardReset() {
    if (user?.token) {
      logoutUser(user?.token)
    }

    dispatch(resetReduxState())

    if (window.Intercom) {
      window.Intercom('shutdown')
    }
    window.analytics.reset()

    setTimeout(() => {
      window.location.href = '/login'
    }, 100)
  }

  return (
    <Container fluid className='!tw-px-0'>
      <ModalHeader noExit />

      <div className='tw-grid tw-min-h-[calc(100vh-var(--header-height))] tw-place-items-center tw-p-4'>
        <SimpleCard className='tw-w-full tw-max-w-lg tw-space-y-3 tw-p-3 *:tw-mb-0'>
          <p>Something went wrong:</p>
          <pre>
            <strong>ERROR EVENT ID:</strong> {eventId}
          </pre>
          {!errorStack ? null : (
            <pre className='tw-block tw-max-h-[250px] tw-overflow-auto tw-font-medium tw-text-systemRed-70 tw-shadow-inner'>
              {errorStack}
            </pre>
          )}

          {typeof resetError !== 'function' ? null : (
            <Button onClick={resetError} size='sm' className='!tw-flex'>
              Try again
            </Button>
          )}

          <Button
            onClick={handleHardReset}
            size='sm'
            outline
            color='light'
            className='!tw-flex'
          >
            Is the error persisting? Hard reset
          </Button>
          <small>This action will log you out</small>
        </SimpleCard>
      </div>
    </Container>
  )
}
