import { t } from 'i18next'
import React from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import Button from '../../../components/ui/button'
import { userTypes } from '../../../helpers/enum'

export function NeedSignModal({
  isOpen,
  toggle,
  clientSignature,
  contractorSignature,
}) {
  const user = useSelector((state) => state.Account?.user)
  const isClient = user?.type === userTypes.COMPANY

  function getMessage() {
    const clientSigned = clientSignature?.user_id === user?.id
    const contractorSigned = contractorSignature?.user_id === user?.id

    if (isClient) {
      if (!clientSigned) {
        return t(
          'This contract is pending your signature. You will be able to submit work after the contract is signed.',
        )
      } else if (!contractorSigned) {
        return t(
          'This contract is pending contractor signature. You will be able to submit work after the contract is signed by the contractor.',
        )
      }
    } else {
      if (!contractorSigned) {
        return t(
          'This contract is pending your signature. You will be able to submit work after the contract is signed.',
        )
      } else if (!clientSigned) {
        return t(
          'This contract is pending client signature. You will be able to submit work after the contract is signed by the client.',
        )
      }
    }

    return t(
      'This contract is pending signature. You will be able to submit work after the contract is signed.',
    )
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader close={<ModalCloseButton toggle={toggle} />}>
        {t('Contract has not signed yet')}
      </ModalHeader>
      <ModalBody className='tw-text-sm tw-text-text-80'>
        {getMessage()}
      </ModalBody>
      <ModalFooter>
        <Button type='button' onClick={toggle}>
          {t('Ok')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
