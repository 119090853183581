import { ArrowSquareIn, ArrowSquareOut, Eye } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import toastr from 'toastr'

import { getExpenseIcon } from '.'
import { ModalCloseButton } from '../../components/Common/modal-close-button'
import { DocumentPreview } from '../../components/Common/upload-preview'
import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import Button from '../../components/ui/button'
import { CONTRACT_TYPES } from '../../core/config/contract-types'
import { useFetch } from '../../helpers/hooks'
import {
  approveExpense,
  approveMilestone,
  approvePayments,
  approveWork,
  assignContractToSignatory,
  declinePayments,
  getApprovalFlowTimeline,
  getSignatoryList,
  rejectExpense,
} from '../../services/api'
import { approveBill, declineBill } from '../../services/api-bill-payments'
import { track } from '../../utils/analytics'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import { getErrorMessage } from '../../utils/get-errors'
import { getFullName } from '../../utils/get-full-name'
import isNill from '../../utils/is-nill'
import { searchParamsFromObject } from '../../utils/search-params-from-object'
import {
  TODO_TYPE,
  getReviewTypeIcon,
} from '../Activity/center-overview-client'
import { ConfirmDeleteExpenseModal } from '../AdminPanel/pages/Contracts/ExpenseDetails'
import { ContractSignatureModal } from '../Contract/ContractDetail/components/sign-modal-v2'
import { GenerateLink } from '../Contract/ContractPage/de-documents-list'
import { DocumentMessage } from '../Contract/ContractPage/de-documents-tab'
import { DownloadAttachment } from '../Contract/ContractPage/milestones-tab'
import { Timeline } from '../Contract/ContractPage/payment-approvals'
import { WorkDeclineModal } from '../Contract/ContractPage/submitted-work-decline-modal'
import DeclineModal from '../Contract/components/DeclineModal'
import { BillInfoText } from '../bills/list'
import { DueInText } from '../bills/list/bill-details-button'
import { PreviewExpense } from '../expenses/components/expense-details-modal'
import { TIMEOFF_EVENTS } from '../new-time-off-policy/events'
import { ReviewTimeOffItem, formatDate } from '../time-off/time-off-list'
import { useTimeOffActions } from '../time-off/use-time-off-actions'
import { useReviewContext } from './review-columns'
import {
  ContractorHeader,
  DetailsInfoList,
} from './review-layout-details-components'
import ConfirmActionButton from '../../components/confirm-action-button'
import { t } from 'i18next'

function RenderItem() {
  const { activeTab } = useReviewContext()

  switch (activeTab) {
    case TODO_TYPE.timeOff: {
      return <TimeOffDetails />
    }

    case TODO_TYPE.expenses: {
      return <ExpenseDetails />
    }

    case TODO_TYPE.contracts: {
      return <ContractDetails />
    }

    case TODO_TYPE.works: {
      return <WorkDetails />
    }

    case TODO_TYPE.documents: {
      return <DocumentDetails />
    }

    case TODO_TYPE.payments: {
      return <PaymentDetails />
    }

    case TODO_TYPE.invoices: {
      return <InvoiceDetails />
    }

    case TODO_TYPE.bills: {
      return <BillDetails />
    }

    default: {
      return null
    }
  }
}

function getNextItemId({ item, reviewItems }) {
  const currentIndex = reviewItems.findIndex((i) => i.id === item.id)

  let nextIndex
  if (reviewItems.length <= 1) {
    // one item remaining
    nextIndex = undefined
  } else if (currentIndex === reviewItems.length - 1) {
    // if the last item is selected, go to the first item
    nextIndex = 0
  } else {
    // otherwise, go to the next item
    nextIndex = currentIndex + 1
  }
  const nextItemId = !isNill(nextIndex) ? reviewItems[nextIndex]?.id : undefined

  return nextItemId
}

const manageTimeOffFormId = 'manage-time-off-form-id'
function TimeOffDetails() {
  const userProfile = useSelector((state) => state.userProfile?.userProfile)
  const {
    setUrlState,
    isSelecting,
    refreshReviewList,
    activeItem: item,
    reviewItems,
  } = useReviewContext()

  const contract = {
    id: item?.contract?.id,
    currency: item?.contract?.currency,
  }

  const { handleApproveTimeOff, handleDeclineTimeOff, actionIds } =
    useTimeOffActions({
      refreshTimeOff: () => {
        toastr.success(t('Time off updated successfully'))

        refreshReviewList()

        const nextItemId = getNextItemId({ item, reviewItems })
        setUrlState({ selectedItem: nextItemId })
      },
    })

  const isLoading = Object.values(actionIds).some(Boolean)
  const isApproving = actionIds.approvingId === item.id
  const isDeclining = actionIds.decliningId === item.id

  return (
    <>
      <ContractorHeader
        contractId={item?.contract?.ref}
        flag={item?.contractor?.flag}
        name={getFullName(item?.contractor)}
        photo={item?.contractor?.photo}
      />

      <div className='tw-p-6'>
        <ReviewTimeOffItem
          contract={contract}
          item={{
            from: item.from_date,
            to: item.to_date,
            is_half_start_date: item.is_half_start_date,
            is_half_end_date: item.is_half_end_date,
            days: item.days,
            created_at: item.created_at,
            policy_name: item.policy_name,
            balance: item.balance,
          }}
          onSubmit={(data) => {
            handleApproveTimeOff({ id: item.id, ...data })
            track(TIMEOFF_EVENTS.APPROVE, {
              deducted: !!item.cycles?.length,
              source: 'webapp',
              approver_country: userProfile?.country?.name,
              requester_contract_type: item?.contract?.type,
            })
          }}
          formId={manageTimeOffFormId}
          showDeduction={!isSelecting}
        />
      </div>

      {isSelecting ? null : (
        <div className='tw-sticky tw-bottom-0 tw-z-[1] tw-mt-auto tw-flex tw-items-center tw-justify-between tw-border-t tw-border-surface-30 tw-bg-white tw-p-6'>
          <Button
            color='danger'
            type='button'
            disabled={isLoading}
            loading={isDeclining}
            onClick={() => handleDeclineTimeOff({ id: item.id })}
          >
            {t('Decline')}
          </Button>
          <Button
            color='success'
            formId={manageTimeOffFormId}
            type='submit'
            disabled={isLoading}
            loading={isApproving}
          >
            {t('Approve')}
          </Button>
        </div>
      )}
    </>
  )
}

function ExpenseDetails() {
  const userProfile = useSelector((state) => state.userProfile?.userProfile)
  const [showConfirmDecline, setShowConfirmDecline] = useState(false)
  const [actionOverridden, setActionOverridden] = useState(false)

  const {
    setUrlState,
    isSelecting,
    refreshReviewList,
    activeItem: item,
    reviewItems,
  } = useReviewContext()

  const formatter = getCurrencyFormatter(item?.base_currency?.code)

  const { data: flowTimeline, isLoading: loadingFlowTimeline } = useFetch(
    {
      action: getApprovalFlowTimeline,
      body: { item_id: item?.id, type: 'expense' },
      autoFetch: !!item?.id,
    },
    [item?.id],
  )

  function onUpdate(data) {
    if (data?.success === false) {
      toastr.error(t('Something went wrong'))
    } else {
      toastr.success(t('Expense updated successfully'))

      refreshReviewList()

      const nextItemId = getNextItemId({ item, reviewItems })
      setUrlState({ selectedItem: nextItemId })
    }
  }

  function handleError(error) {
    toastr.error(getErrorMessage(error))
  }

  const { startFetch: approve, isLoading: approvingExpense } = useFetch({
    action: approveExpense,
    onComplete: () => {
      onUpdate?.()
      track('Approve expense', {
        requester_contract_type: item?.contract?.type,
        approver_country: userProfile?.country?.name,
        source: 'webapp',
      })
    },
    onError: handleError,
  })
  const { startFetch: decline, isLoading: decliningExpense } = useFetch({
    action: rejectExpense,
    onComplete: onUpdate,
    onError: handleError,
  })

  const isLoading = approvingExpense || decliningExpense
  const isApproving = approvingExpense
  const isDeclining = decliningExpense

  return (
    <>
      <DetailsHeader
        title={item.name}
        subtitle={item.category.name}
        icon={getExpenseIcon({ category: item.category })}
      />

      <ContractorHeader
        contractId={item?.contract?.ref}
        flag={item?.contractor?.flag}
        name={getFullName(item?.contractor)}
        photo={item?.contractor?.photo}
        withHeaderStyle={false}
      />

      <DetailsInfoList
        items={[
          { label: t('Submitted by'), value: item.submitted_by },
          {
            label: t('Submitted on'),
            value: formatDate(item.submitted_at * 1000),
          },
          { label: t('Date of the expense'), value: formatDate(item.date) },
          { label: t('Role'), value: item.contract.role },
          { label: t('Amount'), value: formatter.format(item.base_amount) },
          {
            label: t('Receipt'),
            value: (
              <ExpensePhotoPreview photo={item?.photo} file={item?.file} />
            ),

            className: '!tw-py-1',
          },
        ]}
      />

      <div className='tw-px-6 tw-pb-6'>
        <Timeline
          flowTimeline={flowTimeline}
          loading={loadingFlowTimeline}
          loadingLength={2}
          isAdmin={false}
          actionOverridden={actionOverridden}
          setActionOverridden={setActionOverridden}
          isDeclined={item?.status?.id === 4}
          contract={{
            ...item?.contract,
            approval_flow: { id: item.contract.approval_flow_id },
          }}
        />
      </div>

      {isSelecting ? null : (
        <div className='tw-sticky tw-bottom-0 tw-z-[1] tw-mt-auto tw-flex tw-items-center tw-justify-between tw-border-t tw-border-surface-30 tw-bg-white tw-p-6'>
          <Button
            color='danger'
            type='button'
            disabled={isLoading}
            loading={isDeclining}
            onClick={() => setShowConfirmDecline(true)}
          >
            {t('Decline')}
          </Button>

          <Button
            color='success'
            type='button'
            disabled={isLoading}
            loading={isApproving}
            onClick={() => approve({ expense_id: item.id })}
          >
            {t('Approve')}
          </Button>

          {showConfirmDecline && (
            <ConfirmDeleteExpenseModal
              show={showConfirmDecline}
              hide={() => setShowConfirmDecline(false)}
              reject={(reason) => {
                decline({ expense_id: item?.id, reason })
              }}
            />
          )}
        </div>
      )}
    </>
  )
}
function ExpensePhotoPreview({ photo, file }) {
  const [isOpen, setIsOpen] = useState(false)

  function toggle() {
    setIsOpen((open) => !open)
  }

  if (!photo) {
    return (
      <div className='tw-flex tw-h-[42px] tw-items-center'>
        {t('No receipt provided')}
      </div>
    )
  }

  return (
    <>
      <Button
        color='link'
        iconRight={<Eye size={20} />}
        onClick={toggle}
        className='!tw-px-0 !tw-py-2.5'
      >
        {t('Preview')}
      </Button>

      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader close={<ModalCloseButton toggle={toggle} />} />
        <ModalBody>
          <PreviewExpense
            photo={photo}
            file={file}
            imageClassName='tw-h-[34rem]'
          />
        </ModalBody>
      </Modal>
    </>
  )
}

function ContractDetails() {
  const [selectedSignatory, setSelectedSignatory] = useState(null)
  const [signModalOpen, setSignModalOpen] = useState(false)

  function toggleSignModal() {
    setSignModalOpen((open) => !open)
  }

  const user = useSelector((state) => state.Account?.user)

  const {
    setUrlState,
    isSelecting,
    refreshReviewList,
    activeItem: item,
    reviewItems,
  } = useReviewContext()

  const formatter = getCurrencyFormatter(item.currency?.code)

  const { data: signatories, isLoading: loadingSignatories } = useFetch(
    {
      action: getSignatoryList,
      autoFetch: !item.signatory,
      initResult: [],
    },
    [item.signatory],
  )

  function onUpdate(data) {
    if (data?.success === false) {
      toastr.error(t('Something went wrong with assigning signatory'))
    } else {
      toastr.success(t('Contract updated successfully'))

      refreshReviewList()

      const nextItemId = getNextItemId({ item, reviewItems })
      setUrlState({ selectedItem: nextItemId })
    }
  }

  function handleError(error) {
    toastr.error(getErrorMessage(error))
  }

  const { startFetch: assignSignatory, isLoading: isAssigningSignatory } =
    useFetch({
      action: assignContractToSignatory,
      onComplete: onUpdate,
      onError: handleError,
    })

  function handleSign() {
    const signatory =
      item.signatory ??
      signatories.find((signatory) => signatory?.id === selectedSignatory.value)

    if (signatory?.id === user?.id) {
      setSignModalOpen(true)
    } else {
      assignSignatory({ contract_id: item?.id, signatory_id: signatory?.id })
    }
  }

  return (
    <>
      <ContractorHeader
        contractId={item?.ref}
        name={getFullName(item?.contractor)}
        photo={item?.contractor?.photo}
        flag={item?.contractor?.flag}
      />

      <DetailsInfoList
        items={[
          { label: t('Contract type'), value: item.type },
          { label: t('Role'), value: item.name },
          { label: t('Start date'), value: formatDate(item.start_date) },
          { label: t('Payment'), value: formatter.format(item.amount) },
          item?.frequency?.name
            ? { label: t('Frequency'), value: item?.frequency?.name }
            : null,
        ]}
      />

      <div className='tw-px-6 tw-pb-6'>
        <Button
          tag={Link}
          to={`/contract/detail?id=${item.ref}`}
          target='_blank'
          outline
          iconRight={<ArrowSquareIn size={20} />}
          block
        >
          {t('Contract details')}
        </Button>
      </div>

      {isSelecting ? null : (
        <div className='tw-sticky tw-bottom-0 tw-z-[1] tw-mt-auto tw-flex tw-items-center tw-justify-between tw-gap-4 tw-border-t tw-border-surface-30 tw-bg-white tw-p-6'>
          {item.signatory ? null : (
            <CustomSelect
              options={signatories.map((signatory) => {
                const isMe = signatory?.id === user?.id
                const name = getFullName(signatory) || signatory?.email

                return {
                  label: name + (isMe ? ' (Me)' : ''),
                  value: signatory?.id,
                }
              })}
              value={selectedSignatory}
              onChange={(value) => setSelectedSignatory(value)}
              placeholder={t('Select signatory')}
              menuPlacement='top'
              wrapperClassName='tw-w-full'
              isDisabled={isAssigningSignatory}
              isLoading={loadingSignatories || loadingSignatories}
            />
          )}

          <Button
            type='button'
            block={!!item.signatory}
            disabled={
              isAssigningSignatory ||
              (item.signatory ? false : !selectedSignatory?.value)
            }
            loading={isAssigningSignatory}
            onClick={() => handleSign()}
          >
            {item.signatory ||
            !selectedSignatory ||
            selectedSignatory.value === user.id
              ? t('Sign')
              : t('Assign')}
          </Button>

          {!signModalOpen ? null : (
            <ContractSignatureModal
              isOpen={signModalOpen}
              toggle={toggleSignModal}
              contract={{ ref: item.ref, file: item.file, id: item.id }}
              onSignSuccess={() => {
                refreshReviewList()

                const nextItemId = getNextItemId({ item, reviewItems })
                setUrlState({ selectedItem: nextItemId })

                toggleSignModal()
              }}
            />
          )}
        </div>
      )}
    </>
  )
}

function WorkDetails() {
  const [declineModalOpen, setDeclineModalOpen] = useState(false)
  function toggleDeclineModal() {
    setDeclineModalOpen((open) => !open)
  }

  const {
    setUrlState,
    isSelecting,
    refreshReviewList,
    activeItem: item,
    reviewItems,
  } = useReviewContext()

  const formatter = getCurrencyFormatter(item.contract.currency?.code)

  function onUpdate(data) {
    if (data?.success === false) {
      toastr.error(t('Something went wrong while updating the work item'))
    } else {
      toastr.success(t('Work item updated successfully'))

      refreshReviewList()

      const nextItemId = getNextItemId({ item, reviewItems })
      setUrlState({ selectedItem: nextItemId })
    }
  }

  function handleError(error) {
    toastr.error(getErrorMessage(error))
  }

  function getApproveAction(contractType) {
    switch (contractType) {
      case CONTRACT_TYPES.PAY_AS_YOU_GO: {
        return approveWork
      }
      case CONTRACT_TYPES.MILESTONES: {
        return approveMilestone
      }
      default: {
        return () => {}
      }
    }
  }

  const { startFetch: approve, isLoading: approvingWork } = useFetch({
    action: getApproveAction(item?.contract?.type),
    onComplete: onUpdate,
    onError: handleError,
  })

  return (
    <>
      <DetailsHeader
        title={item.name}
        subtitle={`${t('Submitted by')} ${item.submitted_by}`}
        icon={getReviewTypeIcon(TODO_TYPE.works, { size: 24 })}
      />

      <ContractorHeader
        contractId={item.contract.ref}
        name={getFullName(item?.contractor)}
        photo={item?.contractor?.photo}
        flag={item?.contractor?.flag}
        withHeaderStyle={false}
      />

      <DetailsInfoList
        items={[
          {
            label: t('Submitted on'),
            value: formatDate(item.submitted_at),
          },
          { label: t('Role'), value: item.contract.role },
          { label: t('Rate'), value: item.rate },
          { label: t('Amount'), value: formatter.format(item.amount) },
          {
            label: t('Attachment'),
            value: item.file ? (
              <Button
                iconRight={<Eye />}
                color='link'
                className='!tw-p-0'
                href={item.file}
                target='_blank'
                rel='noreferrer'
                tag='a'
              >
                {t('Preview')}
              </Button>
            ) : (
              t('No attachment')
            ),
          },
        ]}
      />

      {isSelecting ? null : (
        <div className='tw-sticky tw-bottom-0 tw-z-[1] tw-mt-auto tw-flex tw-items-center tw-justify-between tw-border-t tw-border-surface-30 tw-bg-white tw-p-6'>
          <Button
            color='danger'
            type='button'
            disabled={approvingWork}
            onClick={() => setDeclineModalOpen(true)}
          >
            {t('Decline')}
          </Button>

          <Button
            color='success'
            type='button'
            disabled={approvingWork}
            loading={approvingWork}
            onClick={() => approve({ work_id: item.id })}
          >
            {t('Approve')}
          </Button>

          {!declineModalOpen ? null : (
            <WorkDeclineModal
              isOpen={declineModalOpen}
              toggle={toggleDeclineModal}
              item={{ ...item, contract_type: item?.contract?.type }}
              onDeclineSuccess={() => {
                refreshReviewList()

                const nextItemId = getNextItemId({ item, reviewItems })
                setUrlState({ selectedItem: nextItemId })

                toggleDeclineModal?.()
              }}
            />
          )}
        </div>
      )}
    </>
  )
}

function DocumentDetails() {
  const { activeItem, isSelecting } = useReviewContext()

  const contractorFullName = getFullName(activeItem?.contractor)

  // remove selectedItem from search
  const currentSearch = window.location.search
  const searchParams = new URLSearchParams(currentSearch)
  searchParams.delete('selectedItem')

  const params = {
    compliance: activeItem?.is_compliance ? '1' : '0',
    message: activeItem?.description,
    from: contractorFullName,
    back: location.pathname + '?' + searchParams.toString(),
  }
  const sp = searchParamsFromObject(params)
  const url = `/document/generate/${activeItem.id}${sp}`

  return (
    <>
      <ContractorHeader
        contractId={activeItem?.reference}
        name={getFullName(activeItem?.contractor)}
        photo={activeItem?.contractor?.photo}
        flag={activeItem?.contractor?.flag}
      />

      <DetailsInfoList
        items={[
          { label: t('Document'), value: activeItem?.title },
          {
            label: t('Requested on'),
            value: formatDate(activeItem?.created_at),
          },
        ]}
      />

      <div className='tw-p-6'>
        {!activeItem.description ? null : (
          <DocumentMessage
            message={activeItem.description}
            from={contractorFullName}
          />
        )}
      </div>

      {isSelecting ? null : (
        <div className='tw-sticky tw-bottom-0 tw-z-[1] tw-mt-auto tw-flex tw-items-center tw-justify-between tw-border-t tw-border-surface-30 tw-bg-white tw-p-6'>
          <GenerateLink url={url} contractType={activeItem?.type} />
        </div>
      )}
    </>
  )
}

function PaymentDetails() {
  const [actionOverridden, setActionOverridden] = useState(false)

  const {
    activeItem,
    isSelecting,
    refreshReviewList,
    reviewItems,
    setUrlState,
  } = useReviewContext()

  const formatter = getCurrencyFormatter(activeItem?.currency?.code)

  const { data: flowTimeline, isLoading: loadingFlowTimeline } = useFetch(
    {
      action: getApprovalFlowTimeline,
      body: { item_id: activeItem?.id, type: 'payment' },
      autoFetch: !!activeItem?.id,
    },
    [activeItem?.id],
  )

  function onUpdate(data) {
    if (data?.success === false) {
      toastr.error(t('Something went wrong'))
    } else {
      toastr.success(t('Payment updated successfully'))

      refreshReviewList()

      const nextItemId = getNextItemId({ item: activeItem, reviewItems })
      setUrlState({ selectedItem: nextItemId })
    }
  }

  function handleError(error) {
    toastr.error(getErrorMessage(error))
  }

  const { startFetch: approvePayment, isLoading: approvingPayments } = useFetch(
    {
      action: approvePayments,
      onComplete: onUpdate,
      onError: handleError,
    },
  )
  const { startFetch: declinePayment, isLoading: decliningPayments } = useFetch(
    {
      action: declinePayments,
      onComplete: onUpdate,
      onError: handleError,
    },
  )
  const loading = approvingPayments || decliningPayments

  return (
    <>
      <ContractorHeader
        contractId={activeItem?.contract?.ref}
        name={getFullName(activeItem?.contractor)}
        photo={activeItem?.contractor?.photo}
        flag={activeItem?.contractor?.flag}
      />

      <DetailsInfoList
        items={[
          {
            label: t('Due date'),
            value: formatDate(activeItem?.due_date * 1000),
          },
          { label: t('Amount'), value: formatter.format(activeItem?.amount) },
        ]}
      />

      <h4 className='tw-mb-1 tw-mt-2 tw-px-6 tw-text-base tw-font-medium'>
        {t('Summary')}
      </h4>

      <DetailsInfoList
        className='tw-py-0'
        items={activeItem.items.map((item) => {
          return {
            label: (
              <>
                <div className='tw-font-semibold tw-text-black'>
                  {item.type}
                </div>
                <div>{item.details}</div>
              </>
            ),

            value: (
              <div className='tw-flex tw-flex-col tw-items-end'>
                <div className='tw-text-text-100'>
                  {formatter.format(item.amount)}
                </div>
                <div className='tw-text-right tw-text-text-80'>
                  {`${t('Due')} ${formatDate(activeItem.due_date * 1000)}`}
                </div>
              </div>
            ),
          }
        })}
      />

      <div className='tw-px-6 tw-pb-6'>
        <Timeline
          flowTimeline={flowTimeline}
          loading={loadingFlowTimeline}
          loadingLength={2}
          isAdmin={false}
          actionOverridden={actionOverridden}
          setActionOverridden={setActionOverridden}
          isDeclined={activeItem?.status?.id === 4}
          contract={{
            ...activeItem?.contract,
            approval_flow: { id: activeItem.contract.approval_flow_id },
          }}
        />
      </div>

      {isSelecting ? null : (
        <div className='tw-sticky tw-bottom-0 tw-z-[1] tw-mt-auto tw-flex tw-items-center tw-justify-between tw-gap-4 tw-border-t tw-border-surface-30 tw-bg-white tw-p-6'>
          <Button
            color='danger'
            onClick={() => declinePayment({ payment_ids: [activeItem?.id] })}
            loading={decliningPayments}
            disabled={loading}
          >
            {t('Decline')}
          </Button>

          <Button
            color='success'
            onClick={() => approvePayment({ payment_ids: [activeItem?.id] })}
            loading={approvingPayments}
            disabled={loading}
          >
            {t('Approve')}
          </Button>
        </div>
      )}
    </>
  )
}

function InvoiceDetails() {
  const {
    activeItem,
    isSelecting,
    refreshReviewList,
    reviewItems,
    setUrlState,
  } = useReviewContext()

  const classifications = useSelector(
    (state) => state?.Layout?.staticData?.payment_classifications,
  )

  const formatter = getCurrencyFormatter(activeItem.contract.currency?.code)

  const { data: flowTimeline, isLoading: loadingFlowTimeline } = useFetch(
    {
      action: getApprovalFlowTimeline,
      body: { item_id: activeItem?.id, type: 'work' },
      autoFetch: !!activeItem?.id,
    },
    [activeItem?.id],
  )

  function onUpdateSuccess() {
    refreshReviewList()

    const nextItemId = getNextItemId({ item: activeItem, reviewItems })
    setUrlState({ selectedItem: nextItemId })
  }

  return (
    <>
      <ContractorHeader
        contractId={activeItem?.contract?.ref}
        name={getFullName(activeItem?.contractor)}
        photo={activeItem?.contractor?.photo}
        flag={activeItem?.contractor?.flag}
      />

      <DetailsInfoList
        items={[
          {
            label: t('Invoice date'),
            value: formatDate(activeItem?.submitted_at),
          },
          { label: t('Amount'), value: formatter.format(activeItem?.amount) },
          {
            label: t('Attachment'),
            className: '!tw-py-1',
            value: (
              <DownloadAttachment item={activeItem}>
                {({ onClick, loading }) => {
                  return (
                    <Button
                      color='link'
                      iconRight={<Eye size={20} />}
                      onClick={onClick}
                      className='!tw-px-0 !tw-py-2.5'
                      disabled={loading}
                      loading={loading}
                    >
                      {t('Download attachment')}
                    </Button>
                  )
                }}
              </DownloadAttachment>
            ),
          },
        ]}
      />

      {activeItem?.items?.length <= 0 || !activeItem?.items ? null : (
        <>
          <h4 className='tw-mb-1 tw-mt-2 tw-px-6 tw-text-base tw-font-medium'>
            {t('Summary')}
          </h4>

          <DetailsInfoList
            className='tw-py-0'
            items={activeItem?.items?.map((item) => {
              const classificationName = classifications?.find(
                (classification) => classification.id === item.classification,
              )?.name

              return {
                label: (
                  <>
                    <div className='tw-font-semibold tw-text-black'>
                      {item.desc}
                    </div>
                    <div>{classificationName}</div>
                  </>
                ),

                value: (
                  <div className='tw-flex tw-flex-col tw-items-end'>
                    <div className='tw-text-text-100'>
                      {formatter.format(item.amount)}
                    </div>
                  </div>
                ),
              }
            })}
          />
        </>
      )}
      <div className='tw-px-6 tw-pb-6'>
        <Timeline
          flowTimeline={flowTimeline}
          loading={loadingFlowTimeline}
          loadingLength={2}
          isAdmin={false}
          actionOverridden={false}
          setActionOverridden={() => {}}
          contract={{
            ...activeItem?.contract,
            approval_flow: { id: activeItem.contract.approval_flow_id },
          }}
        />
      </div>
      {isSelecting ? null : (
        <div className='tw-sticky tw-bottom-0 tw-z-[1] tw-mt-auto tw-flex tw-items-center tw-justify-between tw-gap-4 tw-border-t tw-border-surface-30 tw-bg-white tw-p-6'>
          <InvoiceActions
            onUpdateSuccess={onUpdateSuccess}
            onDeclineSuccess={onUpdateSuccess}
            item={activeItem}
          />
        </div>
      )}
    </>
  )
}

export function InvoiceActions({ item, onUpdateSuccess, onDeclineSuccess }) {
  const [declineModal, setDeclineModal] = useState(false)
  function toggleDeclineModal() {
    setDeclineModal((open) => !open)
  }

  const { startFetch: approveInvoice, isLoading: approvingInvoice } = useFetch({
    // We are using milestone approval for invoices
    action: approveMilestone,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(t('Something went wrong'))
      } else {
        toastr.success(t('Invoice updated successfully'))

        onUpdateSuccess?.()
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  return (
    <>
      <DeclineModal
        isOpen={declineModal}
        toggle={() => setDeclineModal(false)}
        onDeclined={() => {
          onDeclineSuccess?.()
        }}
        isMilestone
        e={{ id: item.id, details: item.details }}
      />

      <Button
        color='danger'
        onClick={toggleDeclineModal}
        disabled={approvingInvoice}
      >
        {t('Decline')}
      </Button>

      <Button
        color='success'
        onClick={() => {
          approveInvoice({ work_id: item?.id })
        }}
        loading={approvingInvoice}
        disabled={approvingInvoice}
      >
        {t('Approve')}
      </Button>
    </>
  )
}

function ReviewDetailsStickyFooter({ children }) {
  return (
    <div className='tw-sticky tw-bottom-0 tw-z-[1] tw-mt-auto tw-flex tw-items-center tw-justify-between tw-gap-4 tw-border-t tw-border-surface-30 tw-bg-white tw-p-6'>
      {children}
    </div>
  )
}

function BillDetails() {
  const userId = useSelector((state) => state.Account?.user?.id)

  const {
    activeItem,
    isSelecting,
    refreshReviewList,
    reviewItems,
    setUrlState,
  } = useReviewContext()

  const formatter = getCurrencyFormatter(activeItem.currency.code)

  function onUpdateSuccess() {
    refreshReviewList()

    const nextItemId = getNextItemId({ item: activeItem, reviewItems })
    setUrlState({ selectedItem: nextItemId })
  }

  return (
    <>
      <DetailsHeader
        title={activeItem.name}
        subtitle={activeItem.vendor.name}
        icon={getReviewTypeIcon(TODO_TYPE.bills, { size: 24 })}
      />

      <DetailsInfoList
        title={<h4 className='tw-text-base tw-font-medium'>{t('Details')}</h4>}
        items={[
          {
            label: t('Created on'),
            value: (
              <BillInfoText
                title={formatDate(activeItem.created_at)}
                subTitle={`${activeItem.creator_name}${activeItem.creator_id === userId ? ' (you)' : ''}`}
              />
            ),
          },
          { label: t('Category'), value: activeItem.category?.name },
          {
            label: t('Due date'),
            value: (
              <BillInfoText
                title={formatDate(activeItem.due_date)}
                subTitle={<DueInText date={activeItem.due_date} />}
              />
            ),
          },
          { label: t('Amount'), value: formatter.format(activeItem.amount) },
          {
            label: t('Invoice', { count: 1 }),
            value: activeItem.file ? (
              <DocumentPreview
                preview={{ data: activeItem.file.pdf, type: 'application/pdf' }}
                className='tw-size-full tw-min-h-[42rem]'
                withDownload={true}
              >
                <Button
                  className='!tw-p-0'
                  color='link'
                  iconRight={<Eye size={20} />}
                >
                  {t('Preview')}
                </Button>
              </DocumentPreview>
            ) : (
              t('No attachment')
            ),

            valueClassName: 'tw-leading-[25px]',
          },
        ]}
      />

      <Button
        outline
        iconRight={<ArrowSquareOut size={20} />}
        className='tw-mx-6 tw-mb-6'
        tag={Link}
        to={`/bills?tab=pending_approval&selectedItem=${activeItem.name}`}
      >
        {t('Bill details')}
      </Button>

      {isSelecting ? null : (
        <ReviewDetailsStickyFooter>
          <BillActions bill={activeItem} onUpdateSuccess={onUpdateSuccess} />
        </ReviewDetailsStickyFooter>
      )}
    </>
  )
}

function BillActions({ bill, onUpdateSuccess }) {
  const [reason, setReason] = useState('')
  const [showDeclineConfirm, setShowDeclineConfirm] = useState(false)

  const { isLoading: decliningBill, startFetch: _declineBill } = useFetch({
    action: declineBill,
    onComplete: () => {
      toastr.success(t('Bill declined successfully.'))
      onUpdateSuccess?.()
    },
    onError: (error) => toastr.error(error),
  })

  const { isLoading: approvingBill, startFetch: _approveBill } = useFetch({
    action: approveBill,
    onComplete: () => {
      toastr.success(t(`Bill approved successfully.`))
      onUpdateSuccess?.()
    },
    onError: (error) => toastr.error(error),
  })

  const actionLoading = approvingBill || decliningBill

  return (
    <>
      <ConfirmActionButton
        buttonLabel={t('Decline')}
        className='!tw-bg-systemRed !tw-text-white'
        title={t('Decline Reason')}
        content={
          <textarea
            className='form-control'
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        }
        onConfirm={() => _declineBill({ id: bill.id, reason })}
        isLoading={decliningBill}
        toggle={() =>
          setShowDeclineConfirm((showDeclineConfirm) => !showDeclineConfirm)
        }
        isOpen={showDeclineConfirm}
        caption={t('Decline')}
        buttonColor='danger'
        confirmDisabled={!reason}
      />

      <Button
        type='button'
        onClick={() => {
          _approveBill({ id: bill.id })
        }}
        color='success'
        disabled={actionLoading}
        loading={approvingBill}
      >
        {t('Approve')}
      </Button>
    </>
  )
}

function DetailsHeader({ title, subtitle, icon }) {
  return (
    <div className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-surface-30 tw-p-6 tw-text-start'>
      <div>
        <div className='tw-text-xl tw-font-semibold'>{title}</div>
        <div className='tw-text-sm tw-text-text-80'>{subtitle}</div>
      </div>

      <div className='tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-20 tw-p-2.5 tw-text-primary-100'>
        {icon}
      </div>
    </div>
  )
}

export function ReviewDetails() {
  const { activeItem } = useReviewContext()

  return (
    <>
      {activeItem ? (
        <RenderItem />
      ) : (
        <div className='tw-flex tw-h-full tw-items-center tw-justify-center tw-text-text-80'>
          {t('Select an item to review')}
        </div>
      )}
    </>
  )
}
