import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useRef, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import {
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledCurrencyInput from '../../components/ControlledCurrencyInput'
import ControlledInput from '../../components/ControlledInput'
import CustomDatePicker from '../../components/Forms/CustomDatePicker/CustomDatePicker'
import ModalHeader from '../../components/ModalHeader'
import StepContainer from '../../components/Steps/StepContainer'
import {
  useCustomResize,
  useFetch,
  useHideIntercomForPage,
} from '../../helpers/hooks'
import { addMilestone } from '../../services/api'
import InvoiceParser from './components/InvoiceParser'

class Entity {
  constructor(node) {
    this.node = node
  }

  get text() {
    return this.node?.normalizedValue?.text || this.node?.mentionText
  }
}

const parseAmount = (str) => {
  function setCharAt(str, index, chr) {
    if (index > str.length - 1) return str
    return str.substring(0, index) + chr + str.substring(index + 1)
  }

  let result = str?.replace(/[^\d.,]/g, '')
  try {
    if (result?.length > 2) {
      if (result[result?.length - 3] === ',') {
        result = setCharAt(result, result?.length - 3, '.')
      }
      if (result[result?.length - 2] === ',') {
        result = setCharAt(result, result?.length - 2, '.')
      }
      return parseFloat(result?.replace(/[^\d.]/g, ''))
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    return parseFloat(result)
  }
}

const schema = yup.object().shape({
  'amount-input-total': yup.number().required(),
  name: yup.string().required(),
})

const ScanInvoice = () => {
  const [items, setItems] = useState([{ desc: null, amount: '' }])
  const [subtotal, setSubtotal] = useState('')
  const [invoiceDate, setInvoiceDate] = useState(null)
  const [invoiceRef, setInvoiceRef] = useState('')
  const [dueDate, setDueDate] = useState(null)
  const [totalTaxes, setTotalTaxes] = useState('')
  const [image, setImage] = useState(null)
  const location = useLocation()
  const history = useHistory()
  const ref = useRef(null)
  const amountPrefix = 'amount-input'

  const {
    handleSubmit,
    control,
    setValue,

    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { name: '', total: '' },
  })

  const handleParsingResult = (data) => {
    setItems([{ desc: null, amount: '' }])
    setTotalTaxes('')
    setSubtotal('')
    setValue('amount-input-total', '')
    setDueDate(null)
    setInvoiceDate(null)
    setInvoiceRef('')

    const tot = new Entity(
      data?.entities?.find((f) => f.type === 'total_amount'),
    )
    const stotal = new Entity(
      data?.entities?.find((f) => f.type === 'net_amount'),
    )
    const tTotal = new Entity(
      data?.entities?.find((f) => f.type === 'total_tax_amount'),
    )
    const invId = new Entity(
      data?.entities?.find((f) => f.type === 'invoice_id'),
    )
    const invDate = new Entity(
      data?.entities?.find((f) => f.type === 'invoice_date'),
    )
    const dDate = new Entity(data?.entities?.find((f) => f.type === 'due_date'))
    setSubtotal(parseAmount(stotal.text) || stotal.text)
    setValue('amount-input-total', parseAmount(tot.text) || tot.text)
    setTotalTaxes(
      parseFloat(tTotal.text?.replace(/[^\d.,]/g, '')) || tTotal.text,
    )
    setInvoiceRef(invId.text)
    setItems(
      data?.entities
        ?.filter((e) => e?.type === 'line_item')
        ?.map((item) => {
          const desc = new Entity(
            item?.properties?.find((f) => f.type === 'line_item/description'),
          )
          const amount = new Entity(
            item?.properties?.find((f) => f.type === 'line_item/amount'),
          )
          return {
            desc: desc.text || null,
            amount:
              parseFloat(amount.text?.replace(/[^\d.,]/g, '')) || amount.text,
          }
        }),
    )
    try {
      const date = Date.parse(invDate.text)
      setInvoiceDate(date)
      setDueDate(Date.parse(dDate.text))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  const submitMileStone = useFetch({
    action: addMilestone,
    onComplete: () => {
      history.goBack()
    },
    onError: () => {
      toastr.error('Error occurred while submitting the invoice')
    },
  })
  const handleFocusInput = (event) => {
    ref.current = event?.target
  }
  const handleRemoveItem = (key) => {
    setItems(items.filter((_, i) => i !== key))
  }
  const handleAddItem = () => {
    setItems([...items, { desc: null, amount: '' }])
  }
  const handleDescChange = (e, key) => {
    setItems(
      items?.map((t, i) => (i === key ? { ...t, desc: e.target.value } : t)),
    )
  }
  const handleAmountChange = (value, key) => {
    setItems(items?.map((t, i) => (i === key ? { ...t, amount: value } : t)))
  }

  const handleSelectEntity = (v) => {
    if (ref.current) {
      try {
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          'value',
        ).set

        if (ref.current?.name?.includes(amountPrefix)) {
          nativeInputValueSetter.call(ref.current, parseAmount(v) || v)
        } else {
          nativeInputValueSetter.call(ref.current, v)
        }

        const ev2 = new Event('input', { bubbles: true })
        ref.current.dispatchEvent(ev2)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }
  }

  const onSubmit = (values) => {
    saveInvoice(values)
  }

  const saveInvoice = (values) => {
    const body = {
      contract_id: location.state?.contractId,
      amount: values['amount-input-total'],
      data: JSON.stringify({
        invoiceDate,
        invoiceRef,
        dueDate,
        totalTaxes,
        total: values['amount-input-total'],
        subtotal,
        items,
      }),
      expenses: values.expenses,
      attachement: image,
      name: values.name,
    }

    submitMileStone.startFetch(body)
  }

  useHideIntercomForPage()
  const isMobile = useCustomResize({ minWidth: 768 })

  return (
    <div>
      <Container fluid className='!tw-px-0'>
        <ModalHeader action={() => history.goBack()} />

        <StepContainer
          index={0}
          total={1}
          onNext={handleSubmit(onSubmit)}
          nextText='Submit invoice'
          loading={submitMileStone.isLoading}
          isFlat
          cardStyles={{
            maxWidth: 1050,
            paddingBottom: isMobile ? '5rem' : null,
          }}
        >
          <Row
            className='justify-content-center p-0 m-0'
            style={{ overflow: 'auto', minHeight: '75vh' }}
          >
            <Col md={6} sm={12} className='px-3 py-4'>
              <InvoiceParser
                onParsed={handleParsingResult}
                onSelect={handleSelectEntity}
                onPdfUploaded={setImage}
              />
            </Col>
            <Col className='py-4 border-left border-light' md={6} sm={12}>
              <form>
                <Row>
                  <Col md={6} className='mb-3'>
                    <label htmlFor='name'>Milestone Name:</label>
                    <ControlledInput
                      control={control}
                      placeholder='Name'
                      name='name'
                      error={errors.name}
                      onFocus={handleFocusInput}
                    />
                  </Col>
                  <Col md={6} className='mb-3'>
                    <label>Invoice #</label>
                    <Input
                      placeholder='Invoice ref'
                      name='invoice-ref'
                      onFocus={handleFocusInput}
                      value={invoiceRef}
                      onChange={(e) => setInvoiceRef(e.target.value)}
                    />
                  </Col>
                  <Col md={6} className='mb-3'>
                    <label>Due Date</label>
                    <CustomDatePicker
                      value={dueDate}
                      handleOnChange={setDueDate}
                      placeholder='Due Date'
                    />
                  </Col>
                  <Col md={6} className='mb-3'>
                    <label>Invoice Date</label>
                    <CustomDatePicker
                      value={invoiceDate}
                      handleOnChange={setInvoiceDate}
                      placeholder='Invoice Date'
                    />
                  </Col>

                  <Col md={6} className=''>
                    <label>Description</label>
                  </Col>
                  <Col md={6}>
                    <label>Amount</label>
                  </Col>

                  {React.Children.toArray(
                    items.map((item, key) => (
                      <>
                        <Col md={6} className=''>
                          <Input
                            placeholder='Description'
                            onFocus={handleFocusInput}
                            value={item.desc}
                            onChange={(e) => handleDescChange(e, key)}
                          />
                        </Col>
                        <Col md={6} className='mb-3'>
                          <Row className='p-0 m-0 align-items-center'>
                            <Col className='p-0 m-0'>
                              <CurrencyInput
                                name={`${amountPrefix}-amount-${key}`}
                                id={`${amountPrefix}-amount-${key}`}
                                className='form-control'
                                placeholder='Amount'
                                value={item.amount}
                                autoComplete='off'
                                onValueChange={(v) =>
                                  handleAmountChange(v, key)
                                }
                                onFocus={handleFocusInput}
                                // currency input props
                                allowDecimals={true}
                                decimalsLimit={2}
                                decimalSeparator='.'
                                groupSeparator=','
                              />
                            </Col>
                            {items?.length > 1 && (
                              <button
                                type='button'
                                aria-label='Delete Item'
                                title='Delete Item'
                                onClick={() => handleRemoveItem(key)}
                                className='rp-btn-nostyle text-danger d-flex p-0 ml-2'
                              >
                                <i className='bx bx-trash font-size-22' />
                              </button>
                            )}
                          </Row>
                        </Col>
                      </>
                    )),
                  )}

                  <Col md={{ size: 6, offset: 6 }} className='mb-2'>
                    <Row className='justify-content-end p-0 m-0'>
                      <button
                        className='rp-btn-nostyle text-primary'
                        onClick={handleAddItem}
                        type='button'
                      >
                        Add Item
                        <i className='bx bx-plus ml-2' />
                      </button>
                    </Row>
                  </Col>

                  <Col md={{ size: 6, offset: 6 }} className='mb-3'>
                    <label>Subtotal</label>
                    <CurrencyInput
                      name={`${amountPrefix}-subtotal`}
                      className='form-control'
                      placeholder='Subtotal'
                      value={subtotal}
                      autoComplete='off'
                      onValueChange={setSubtotal}
                      onFocus={handleFocusInput}
                      // currency input props
                      allowDecimals={true}
                      decimalsLimit={2}
                      decimalSeparator='.'
                      groupSeparator=','
                    />
                  </Col>

                  <Col md={{ size: 6, offset: 6 }} className='mb-3'>
                    <label>Taxes</label>
                    <CurrencyInput
                      name={`${amountPrefix}-total-taxes`}
                      className='form-control'
                      placeholder='Taxes'
                      value={totalTaxes}
                      autoComplete='off'
                      onValueChange={setTotalTaxes}
                      onFocus={handleFocusInput}
                      // currency input props
                      allowDecimals={true}
                      decimalsLimit={2}
                      decimalSeparator='.'
                      groupSeparator=','
                    />
                  </Col>

                  <Col md={{ size: 6, offset: 6 }} className=''>
                    <label>Total</label>
                    <InputGroup className='flex-nowrap'>
                      <ControlledCurrencyInput
                        control={control}
                        name={`${amountPrefix}-total`}
                        error={errors[`${amountPrefix}-total`]}
                        placeholder='Amount'
                        autoComplete='off'
                        onFocus={handleFocusInput}
                        className='rounded-right-0'
                      />
                      <InputGroupAddon addonType='append'>
                        <InputGroupText>
                          {location?.state?.currency?.code}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </StepContainer>
      </Container>
    </div>
  )
}

export default ScanInvoice
