import { t } from 'i18next'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { NavItem } from 'reactstrap'

import { NewBadge } from '../../components/ui/badge-new'
import PageHeading from '../../components/ui/page-heading'
import FEATURE_FLAGS from '../../config/feature-flags'
import { userTypes } from '../../helpers/enum'
import { PageNav } from '../../components/page-nav'
import { ExternalInvoices } from './external-invoices'
import { RemotePassInvoices } from './remote-pass-invoices'

const TAB_KEYS = {
  REMOTE_PASS_INVOICES: 'remote-pass',
  EXTERNAL_INVOICES: 'external-invoices',
}
const invoicePageTabs = [
  { label: t('RemotePass invoices'), key: TAB_KEYS.REMOTE_PASS_INVOICES },
  {
    label: t('External invoices'),
    key: TAB_KEYS.EXTERNAL_INVOICES,
    isNew: true,
  },
]

export function UserInvoices() {
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const tab = searchParams.get('tab')

  const userType = useSelector((state) => state?.Account?.user?.type)

  return (
    <div className='page-content'>
      <PageHeading>
        <PageHeading.Title>{t('Invoices')}</PageHeading.Title>
      </PageHeading>

      {FEATURE_FLAGS.EXTERNAL_INVOICES && userType === userTypes.CONTRACTOR && (
        <PageNav className='!tw-mb-4 tw-rounded tw-bg-white'>
          {invoicePageTabs.map(({ key, label, isNew }) => {
            const isActive = !tab
              ? key === TAB_KEYS.REMOTE_PASS_INVOICES
              : tab === key

            return (
              <NavItem key={key}>
                <PageNav.Link
                  tag={Link}
                  type='button'
                  className='tw-relative'
                  to={{ search: `?tab=${key}` }}
                  isActive={isActive}
                >
                  {label}{' '}
                  {isNew && (
                    <span className='tw-inline-block tw-w-9'>
                      <NewBadge className='tw-absolute tw-end-2.5 tw-top-1/2 -tw-translate-y-1/2' />
                    </span>
                  )}
                </PageNav.Link>
              </NavItem>
            )
          })}
        </PageNav>
      )}

      {tab === TAB_KEYS.EXTERNAL_INVOICES && FEATURE_FLAGS.EXTERNAL_INVOICES ? (
        <ExternalInvoices />
      ) : (
        <RemotePassInvoices />
      )}
    </div>
  )
}
