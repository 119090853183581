import React from 'react'
import toastr from 'toastr'
import StripeForm from './CheckoutForm'
import { t } from 'i18next'

const StripeFormReactNative = () => {
  const handleCCSubmitted = (stripData) => {
    if (stripData.error) {
      toastr.error(
        typeof stripData.error === 'string'
          ? stripData.error
          : t('An error occurred'),
      )
    }

    window.ReactNativeWebView.postMessage(JSON.stringify(stripData))
  }

  return <StripeForm onSubmitted={handleCCSubmitted} />
}

export default StripeFormReactNative
