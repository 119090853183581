import {
  CalendarCheck,
  Flag,
  FunnelSimple,
  IdentificationCard,
  Info,
} from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { userTypes } from '../../../helpers/enum'
import { track } from '../../../utils/analytics'
import CountriesMultiSelect from './CountriesMultiSelect/CountriesMultiSelect'
import FilterBox from './FilterBox'
import FiltersBoxContainer from './FiltersBoxContainer'
import {
  contractTypeFilters,
  FiltersTypes,
  showOnCalendarTypeFilters,
} from './filtersTypes'

const Filters = ({
  setFilters,
  filters,
  open,
  persistedFilters,
  isDefaultFilters,
  setIsDefaultFilters,
  allStatuses,
}) => {
  const [reset, setReset] = useState(false)

  const user = useSelector((state) => state.Account?.user)
  const isClient = user?.type === userTypes.COMPANY

  const defaultShowOnCalendarFilters = showOnCalendarTypeFilters
    .filter(
      (f) =>
        f.label !== 'Company National holidays' &&
        f.label !== 'Team National holidays',
    )
    .map((f) => f.value)

  const handleReset = (state) => {
    setReset(state)
  }

  const checkIfDefaultFilters = (filters) => {
    const defaultFilters = []
    const oneExistsInTwo = (one, two) => {
      return one.every((o) => two.includes(o)) && one.length === two.length
    }
    Object.keys(filters).forEach((f) => {
      if (f === 'contract_types') {
        defaultFilters.push(
          oneExistsInTwo(
            contractTypeFilters.filters.map((f) => f.value),
            filters[f],
          ),
        )
      }
      if (f === 'contract_statuses') {
        defaultFilters.push(
          oneExistsInTwo(
            [4, 8],
            filters[f].sort((a, b) => a - b),
          ),
        )
      }
      if (f === 'contract_tags') {
        defaultFilters.push(oneExistsInTwo([], filters[f]))
      }
      if (f === 'contract_countries') {
        defaultFilters.push(
          filters[f] === 'undefined' || filters[f].length === 0,
        )
      }
      if (f === 'event_types') {
        defaultFilters.push(
          oneExistsInTwo(defaultShowOnCalendarFilters, filters[f]),
        )
      }
    })

    return defaultFilters.every((state) => state)
  }

  const trackEvents = (filters) => {
    const { event_types: eventTypes } = filters
    const filteredEvents = {}
    showOnCalendarTypeFilters.forEach((df) => {
      filteredEvents[df.label] = Boolean(eventTypes?.[df.value])
    })

    track('Calendar event filters', filteredEvents)
  }

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      setIsDefaultFilters(checkIfDefaultFilters(filters))
      trackEvents(filters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    if (Object.keys(persistedFilters).length > 0) {
      setIsDefaultFilters(checkIfDefaultFilters(persistedFilters))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistedFilters])

  return (
    <div className={`!tw-w-[256px] ${open ? 'tw-visible' : 'tw-hidden'}`}>
      <div className='tw-flex tw-flex-col tw-gap-4'>
        <FiltersBoxContainer
          setFilters={setFilters}
          defaultFilters={defaultShowOnCalendarFilters}
          initialFilters={persistedFilters?.event_types}
          data={showOnCalendarTypeFilters}
          icon={<CalendarCheck size={20} />}
          title={FiltersTypes.DEFAULT.title}
          id={FiltersTypes.DEFAULT.key}
        />
        {isClient && (
          <FiltersBoxContainer
            isExpandable
            icon={<FunnelSimple size={20} />}
            title='More filters'
            showReset={!isDefaultFilters}
            handleReset={handleReset}
          >
            <FilterBox
              reset={reset}
              defaultFilters={contractTypeFilters.filters.map((f) => f.value)}
              initialFilters={persistedFilters?.contract_types}
              setFilters={setFilters}
              data={contractTypeFilters.filters}
              title={contractTypeFilters.title}
              id={contractTypeFilters.key}
              icon={
                <IdentificationCard size={20} className='tw-text-primary-100' />
              }
            />
            <hr className='!tw-m-0' />
            {allStatuses.length > 0 && (
              <FilterBox
                reset={reset}
                defaultFilters={[4, 8]}
                initialFilters={persistedFilters?.contract_statuses}
                setFilters={setFilters}
                data={allStatuses ?? []}
                title={FiltersTypes.CONTRACT_STATUS.title}
                id={FiltersTypes.CONTRACT_STATUS.key}
                icon={<Info size={20} className='tw-text-primary-100' />}
              />
            )}
            <hr className='!tw-m-0' />
            <div className='tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-2 tw-px-6 tw-py-4'>
              <div className='tw-flex tw-items-center tw-gap-2 tw-py-1'>
                <Flag size={20} className='tw-text-primary-100' />
                <span className='tw-text-xs tw-font-semibold'>
                  Contract country
                </span>
              </div>

              <CountriesMultiSelect
                reset={reset}
                setFilters={setFilters}
                initialFilters={persistedFilters?.contract_countries ?? []}
              />
            </div>
          </FiltersBoxContainer>
        )}
      </div>
    </div>
  )
}

export default Filters
