import * as general from './translation.json'
import * as profile from './ProfileSettings.translations.json'
import * as components from './components.translations.json'
import * as contractorSettings from './ContractorSettings.translations.json'
import * as withdrawalSettings from './Withdrawal.translations.json'
import * as withdrawProcess from './withdrawProcess.translations.json'
import * as activity from './Activity.translations.json'
import * as reviewCenter from './review-center.translations.json'
import * as duePayments from './due-payments-page.translations.json'
import * as companySettings from './CompanySetting.translations.json'
import * as userInvoices from './user-invoices.translation.json'
import * as invoicesTab from './invoices-tab.translation.json'
import * as itemTimeline from './item-timeline.translation.json'
import * as contract from './Contract.translation.json'
import * as transactions from './transactions.translation.json'
import * as timeoff from './time-off.translations.json'
import * as payInsurance from './pay-insurance.translation.json'
import * as payInvoices from './pay-invoices.translation.json'
import * as auth from './auth.translation.json'
import * as timeOffPolicies from './new-time-off-policy.translation.json'
import * as bills from './bills.translation.json'
import * as integrationSetup from './integration-setup.translation.json'
import * as bulkCreation from './bulk-creation.translation.json'
import * as expenses from './expenses.translation.json'
import * as documents from './documents.translation.json'

export const translationEn = {
  ...general,
  ...profile,
  ...components,
  ...contractorSettings,
  ...withdrawalSettings,
  ...withdrawProcess,
  ...activity,
  ...reviewCenter,
  ...duePayments,
  ...companySettings,
  ...userInvoices,
  ...invoicesTab,
  ...itemTimeline,
  ...contract,
  ...transactions,
  ...timeoff,
  ...payInsurance,
  ...payInvoices,
  ...auth,
  ...timeOffPolicies,
  ...bills,
  ...integrationSetup,
  ...expenses,
  ...documents,
  ...bulkCreation,
}
