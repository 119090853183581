import React from 'react'
import { Controller } from 'react-hook-form'
import { cn } from 'ui'
import { getInputErrorMessage } from '../Forms/get-input-error-message'
import InputFeedback from '../ui/input-feedback'

export function ControlledNativeSelect({
  control,
  name,
  options,
  error: externalError,
  showError = true,
  label,
  transform,
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        const theError =
          getInputErrorMessage(externalError) || getInputErrorMessage(error)
        const newValue = field.value

        function handleChange(event) {
          field.onChange(event?.target?.value)
        }

        return (
          <div>
            <label htmlFor={props?.id ?? name} className='tw-mb-2'>
              {label}
            </label>

            <NativeSelect
              error={theError}
              showError={showError}
              options={options}
              {...props}
              {...field}
              value={
                typeof transform?.input === 'function'
                  ? transform.input(newValue)
                  : newValue
              }
              onChange={(...args) =>
                typeof transform?.output === 'function'
                  ? handleChange(transform.output(...args))
                  : handleChange(...args)
              }
            />
          </div>
        )
      }}
    />
  )
}

export function NativeSelect({ options, showError, error, ...selectProps }) {
  const showingError = error && showError
  return (
    <>
      <select
        className={cn('form-select form-control !tw-mr-0', {
          'border-danger': error,
        })}
        style={{ height: 42 }}
        {...selectProps}
      >
        {options?.map((option) => {
          return (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              selected={option.selected}
            >
              {option.label}
            </option>
          )
        })}
      </select>
      {showingError ? (
        <InputFeedback className='tw-mt-1'>{error}</InputFeedback>
      ) : null}
    </>
  )
}
