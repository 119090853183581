import axios from 'axios'
import { t } from 'i18next'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TabPane } from 'reactstrap'
import toastr from 'toastr'

import Head from '../../../../components/head'
import Loader from '../../../../components/ui/loader'
import NavTabsV2 from '../../../../components/ui/nav-tabs/nav-tabs-v2'
import TabContent from '../../../../components/ui/tabs'
import { useFetch } from '../../../../helpers/hooks'
import { getXeroIntegrationData, setupXero } from '../../../../services/api'
import {
  submitAccountMapping,
  updatePreData,
  updateRequest,
} from '../../../../store/xero/actions'
import { IntegrationReviewHeader } from '../integration-review-header'
import { XeroAccountMappingTab } from './accountMappingTab'
import XeroLogs from './Logs'
import VendorsSettingTab from './vendorsSettingsTab'

const UpdateXeroIntegration = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [submitted, setSubmitted] = useState(false)

  const dispatch = useDispatch()

  const onComplete = (data) => {
    const accountMapping = {}
    data.categories.forEach((c) => {
      accountMapping[c.category_id] = c.xero_id
    })
    dispatch(submitAccountMapping(accountMapping))
  }

  const xero = useFetch({
    action: getXeroIntegrationData,
    successAction: updatePreData,
    autoFetch: true,
    onError: toastr.error,
    onComplete,
  })

  const refresh = useCallback(
    (cancel) => {
      if (cancel) cancel()
      if (xero.data?.contractors_queued > 0) {
        // eslint-disable-next-line no-return-assign,no-unused-vars
        xero.startFetch(null, false, new axios.CancelToken((c) => (cancel = c)))
      }
    },
    [xero.data],
  )

  useEffect(() => {
    let cancel
    const interval = setInterval(() => refresh(cancel), 800)
    return () => {
      clearInterval(interval)
    }
  }, [xero.data])

  const request = useSelector((state) => state.xero.request)
  const update = useFetch({
    action: setupXero,
    onError: toastr.error,
    onComplete: () => {
      setTimeout(() => {
        xero.startFetch(null, false)
      }, 800)
      dispatch(updateRequest(null))
    },
  })

  useEffect(() => {
    if (request && submitted) {
      update.startFetch(request)
      setSubmitted(false)
    }
  }, [request, submitted])

  useEffect(() => {
    return () => {
      dispatch(updateRequest(null))
    }
  }, [])

  const onUpdate = () => {
    setSubmitted(true)
  }

  const tabs = [
    {
      key: 0,
      label: t('Accounts'),
      component: (
        <XeroAccountMappingTab
          data={xero.data}
          loading={update.isLoading}
          onSubmitted={onUpdate}
        />
      ),
    },
    {
      key: 1,
      label: t('Team'),
      component: (
        <VendorsSettingTab
          data={xero.data}
          loading={update.isLoading}
          onSubmitted={onUpdate}
        />
      ),
    },
    {
      key: 2,
      label: t('Invoices'),
      component: (
        <XeroLogs
          onSubmitted={onUpdate}
          onUpdate={() => xero.startFetch(null, true)}
        />
      ),
    },
  ]

  const integrationName = 'Xero'

  return (
    <div className='page-content'>
      <Head
        title={[`${integrationName} |`, 'Integration Review']
          .filter(Boolean)
          .join(' ')}
      />

      {xero.isLoading ? (
        <Loader minHeight='100vh' />
      ) : (
        <>
          <IntegrationReviewHeader integrationName={integrationName} />

          <NavTabsV2
            options={tabs.map((tab) => ({ ...tab, value: tab.key }))}
            activeOption={activeTab}
            onClickOption={(tab) => {
              setActiveTab(tab.value)
            }}
            className='tw-mt-4 !tw-border-b-transparent'
            itemLinkClassName='!tw-py-3 md:!tw-py-3'
          />

          <TabContent activeTab={activeTab} className='mt-3'>
            {tabs.map((tab) => {
              return (
                <TabPane key={`tab:${tab.key}`} tabId={tab.key}>
                  {tab.component}
                </TabPane>
              )
            })}
          </TabContent>
        </>
      )}
    </div>
  )
}

export default UpdateXeroIntegration
