import React, { useState } from 'react'
import toastr from 'toastr'
import { useFetch } from '../../../../helpers/hooks'
import { getContractDetail } from '../../../../services/api'
import { RequestBgCheckModal } from '../../components/request-bg-check-modal'
import { getFullName } from '../../../../utils/get-full-name'
import { ContractBadge } from '../../../Contract/ContractDetail/contract-timeline'
import { Spinner } from 'reactstrap'
import { cn } from 'ui'

export function BackgroundCheckRow({ reference, background, reftech }) {
  const [backgroundCheckModal, setBackgroundCheckModal] = useState(null)
  const {
    isLoading: isLoadingContract,
    data: contract,
    startFetch,
  } = useFetch({
    action: getContractDetail,
    autoFetch: false,
    onComplete: () => setBackgroundCheckModal(true),
    onError: (err) => toastr.error(err),
  })

  const statusCode = background?.bg_status
  const status = background?.bg_info?.applicant?.status

  if (statusCode === 404)
    return (
      <>
        {isLoadingContract ? (
          <Spinner size='sm' />
        ) : (
          <button
            className='tw-font-bold tw-text-primary'
            onClick={() => startFetch({ id: reference })}
          >
            Request
          </button>
        )}

        {backgroundCheckModal && contract ? (
          <RequestBgCheckModal
            isOpen
            toggle={() => setBackgroundCheckModal(false)}
            itemsToCheck={[
              {
                name: getFullName(contract?.employee ?? contract?.contractor),
                raw: {
                  id: contract?.id,
                  contractor: contract?.employee ?? contract?.contractor,
                  ref: contract?.ref,
                },
                status: (
                  <ContractBadge
                    amended={contract?.amended}
                    status={contract?.status}
                    lastTimeline={
                      contract?.timeline?.[contract?.timeline?.length - 1]
                    }
                  />
                ),
              },
            ]}
            onSuccess={reftech}
          />
        ) : null}
      </>
    )

  return (
    <span
      className={cn(
        'tw-rounded-full tw-bg-systemGold-20 tw-px-2.5 tw-py-1 tw-font-bold tw-text-systemGold',
        status === 'RETURNED' && 'tw-bg-systemGreen-20 tw-text-systemGreen',
      )}
    >
      {status === 'RETURNED' ? 'COMPLETED' : status}
    </span>
  )
}
