import React from 'react'
import { Spinner } from 'reactstrap'
import { cn } from 'ui'

export default function Loader({
  type = 'grow',
  color = 'primary',
  size = 'md',
  minHeight = '20vh',
  className = '',
  style = {},
}) {
  return (
    <div
      className={cn('tw-flex tw-items-center tw-justify-center', className)}
      style={{ minHeight, ...style }}
    >
      <Spinner type={type} color={color} size={size} />
    </div>
  )
}
