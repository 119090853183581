import { t } from 'i18next'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { TreePalm, TreeStructure, Warning } from '@phosphor-icons/react'

import FEATURE_FLAGS from '../../../../config/feature-flags'
import permissions from '../../../../helpers/permissions'
import Button from '../../../../components/ui/button'
import { usePermissions } from '../../../../helpers/hooks'
import ConfirmationModal from '../../../../components/Common/ConfirmationModal'
import { ModalCloseButton } from '../../../../components/Common/modal-close-button'
import { workerTabs } from './manage-policy-workers'

export default function ManagePolicyFooters({
  selectedWorkers,
  selectedContract,
  handleClose,
  unsavedChanges,
  handleSave,
  isCloseConfirmOpen,
  loading,
  toggleCloseConfirmModal,
  toggleMenu,
  onSelectAll,
  workers,
  onReAssignClick,
  isReAssigning,
  onReAssignConfirm,
  activeTab,
  disableReAssignConfirm,
  onReAssignCancelClick,
  reassigningWorkers,
}) {
  if (isReAssigning) {
    return (
      <FooterWhenReAssigning
        onConfirm={onReAssignConfirm}
        disableConfirm={disableReAssignConfirm}
        onCancel={onReAssignCancelClick}
        reassigningWorkers={reassigningWorkers}
      />
    )
  }

  if (selectedWorkers && activeTab === workerTabs.assignedWorkers) {
    return (
      <FooterWhenBulkSelecting
        onSelectAll={onSelectAll}
        selectedWorkers={selectedWorkers}
        workers={workers}
        onReAssignClick={onReAssignClick}
      />
    )
  }

  if (!selectedContract) {
    return (
      <FooterWithoutSelectedContract
        unsavedChanges={unsavedChanges}
        handleClose={handleClose}
        handleSave={handleSave}
        isCloseConfirmOpen={isCloseConfirmOpen}
        loading={loading}
        toggleCloseConfirmModal={toggleCloseConfirmModal}
        toggleMenu={toggleMenu}
      />
    )
  }
  return <FooterWithSelectedContract selectedContract={selectedContract} />
}

const FooterWithoutSelectedContract = ({
  unsavedChanges,
  toggleMenu,
  loading,
  handleClose,
  isCloseConfirmOpen,
  toggleCloseConfirmModal,
  handleSave,
}) => {
  return (
    <>
      <div className='tw-text-xs tw-text-text-60'>
        {unsavedChanges ? t('You have unsaved changes') : null}
      </div>

      <CancelAction
        closeMenu={toggleMenu}
        loading={loading}
        handleClose={handleClose}
        isCloseConfirmOpen={isCloseConfirmOpen}
        toggleCloseConfirmModal={toggleCloseConfirmModal}
      />

      <Button
        type='button'
        disabled={loading}
        loading={loading}
        onClick={handleSave}
      >
        {t('Save')}
      </Button>
    </>
  )
}

const FooterWithSelectedContract = ({ selectedContract }) => {
  const { hasAccess } = usePermissions()

  return (
    <>
      <Button
        type='button'
        color='light'
        outline
        icon={<TreePalm size={20} />}
        className='tw-flex-1 !tw-px-4'
        tag={Link}
        to={`/contract/detail?id=${selectedContract}&tab=timeOff`}
      >
        {t('View Time off')}
      </Button>

      {FEATURE_FLAGS.ORGANIZATION_CHART &&
        hasAccess([permissions.ViewOrgChart, permissions.ManageOrgChart]) && (
          <Button
            type='button'
            color='light'
            outline
            icon={<TreeStructure size={20} />}
            className='tw-flex-1 !tw-px-4'
            tag={Link}
            to='/org-chart'
          >
            {t('View OrgChart')}
          </Button>
        )}
    </>
  )
}

const FooterWhenBulkSelecting = ({
  onSelectAll,
  selectedWorkers,
  workers,
  onReAssignClick,
}) => (
  <>
    <Button color='link' className='!tw-px-0' onClick={onSelectAll}>
      {selectedWorkers?.length === workers.length
        ? t('Unselect All')
        : t('Select All')}
    </Button>
    <span className='tw-flex-1' />

    {/* //@TODO: Uncomment when BE is ready
    <Button
      outline
      color='danger'
      className='tw-me-2 !tw-px-6'
      disabled={selectedWorkers?.length < 1}
    >
      {selectedWorkers?.length > 0
        ? `${t('Unassign')} (${selectedWorkers?.length})`
        : t('Unassign')}
    </Button> */}
    <Button
      disabled={selectedWorkers?.length < 1}
      className='tw-flex-1 !tw-px-6'
      onClick={onReAssignClick}
    >
      {selectedWorkers?.length > 0
        ? `${t('Reassign')} (${selectedWorkers?.length})`
        : t('Reassign')}
    </Button>
  </>
)

function CancelAction({
  closeMenu,
  handleClose,
  loading,
  isCloseConfirmOpen,
  toggleCloseConfirmModal,
}) {
  return (
    <>
      <Button
        type='button'
        onClick={handleClose}
        color='light'
        outline
        disabled={loading}
      >
        {t('Cancel')}
      </Button>

      <ConfirmationModal
        isOpen={isCloseConfirmOpen}
        toggle={toggleCloseConfirmModal}
        negativeCaption={t('No, keep editing')}
        caption={t('Yes, discard changes')}
        buttonColor='danger'
        onConfirm={() => {
          closeMenu?.()
          toggleCloseConfirmModal()
        }}
        content={
          <>
            <div className='tw-relative tw-flex tw-items-center tw-justify-between tw-pb-2'>
              <Warning size={24} className='tw-text-systemRed-100' />
              <ModalCloseButton toggle={toggleCloseConfirmModal} />
            </div>
            <h4 className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
              {t('Are you sure you want to close and discard the changes?')}
            </h4>
            <p className='tw-mb-0 tw-text-sm tw-text-text-80'>
              {t('All the changes you made now will be lost')}
            </p>
          </>
        }
      />
    </>
  )
}

const FooterWhenReAssigning = ({
  onConfirm,
  disableConfirm,
  onCancel,
  reassigningWorkers,
}) => {
  const [showConfirm, setShowConfirm] = useState(false)
  return (
    <>
      <Button
        outline
        color='light'
        className='tw-me-2 !tw-px-6'
        onClick={() => setShowConfirm(true)}
        disabled={reassigningWorkers}
      >
        {t('Cancel')}
      </Button>
      <Button
        color='primary'
        className='!tw-px-6'
        onClick={onConfirm}
        disabled={disableConfirm}
        loading={reassigningWorkers}
      >
        {t('Confirm')}
      </Button>

      <ConfirmationModal
        isOpen={showConfirm}
        title={
          <>
            <Warning size={24} className='tw-mb-2 tw-fill-red' />
            {t('Are you sure you want to cancel?')}
            <span className='tw-mt-2 tw-block tw-text-sm tw-font-medium tw-text-text-80'>
              {t(
                'You will lose all unsaved progress. This action cannot be undone.',
              )}
            </span>
          </>
        }
        toggle={() => setShowConfirm(false)}
        caption={t('Yes, Cancel')}
        negativeCaption={t('No, Close')}
        buttonColor='danger'
        onConfirm={onCancel}
      />
    </>
  )
}
