import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { Input } from 'reactstrap'

import { cn } from 'ui'
import LabelContent from '../../pages/Contract/CreateContract/components/label-content'
import { getInputErrorMessage } from '../Forms/get-input-error-message'
import { PasswordInputTypeToggle } from '../Forms/TextInput'
import InputFeedback from '../ui/input-feedback'

export function ControlledPasswordInput({
  control,
  name,
  error: externalError,
  showError = true,
  label,
  labelClassName,
  htmlFor,
  transform,
  containerClassName,
  wrapperClassName,
  wrapperStyles,
  type,
  ...otherProps
}) {
  const [inputType, setInputType] = useState(type)

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value, onBlur, ref, name },
        fieldState: { error },
      }) => {
        const theError = getInputErrorMessage(externalError || error)
        const showingError = theError && showError

        return (
          <div className={wrapperClassName} style={wrapperStyles}>
            {!label ? null : (
              <label
                className={labelClassName}
                htmlFor={otherProps?.id ?? htmlFor}
              >
                <LabelContent required>{label}</LabelContent>
              </label>
            )}

            <div className={cn('tw-relative tw-w-full', containerClassName)}>
              <Input
                {...otherProps}
                onChange={(e) =>
                  transform?.output
                    ? onChange(transform.output(e))
                    : onChange(e)
                }
                value={transform?.input ? transform.input(value) : value}
                onBlur={onBlur}
                innerRef={ref}
                name={name}
                type={inputType || 'text'}
                className={cn(
                  'form-control',
                  { 'tw-border-systemRed-100': showingError },
                  otherProps?.className,
                )}
              />

              {showingError ? (
                <InputFeedback className='tw-mt-1'>{theError}</InputFeedback>
              ) : null}

              {type === 'password' && (
                <PasswordInputTypeToggle
                  className='tw-absolute tw-top-[21px] tw-flex -tw-translate-y-1/2 tw-rounded-sm tw-bg-white tw-p-1 focus-visible:tw-ring focus-visible:tw-ring-surface-30 ltr:tw-right-3 rtl:tw-left-3'
                  inputType={inputType}
                  toggle={setInputType}
                />
              )}
            </div>
          </div>
        )
      }}
    />
  )
}
