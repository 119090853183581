import React, { useState } from 'react'
import cloud from '../../../../src/assets/images/cloud.svg'
import calendarTimeline from '../../../../src/assets/images/calendar-timeline.png'
import ReactPlayer from 'react-player'

import gradient from '../../../../src/assets/images/gradient-rectangle.svg'
import {
  CalendarDots,
  Play,
  PlugsConnected,
  TreePalm,
} from '@phosphor-icons/react'
import InfoBox from './info-box'
import { Button as DefaultBtn } from 'ui'
import Button from '../../../components/ui/button'
import { Modal, ModalBody } from 'reactstrap'

const Onboarding = ({ setOnboardingDone }) => {
  const [playVideo, setPlayVideo] = useState(false)
  const toggle = () => setPlayVideo((prev) => !prev)
  return (
    <div className='tw-relative tw-flex tw-w-full tw-items-center tw-justify-between tw-overflow-scroll tw-p-6'>
      <Modal
        isOpen={playVideo}
        centered
        onClose={() => setPlayVideo(false)}
        toggle={toggle}
        size='lg'
        className='[&_.modal-content]:!tw-flex [&_.modal-content]:!tw-items-center [&_.modal-content]:!tw-justify-center [&_.modal-content]:!tw-border-0 [&_.modal-content]:!tw-bg-transparent'
        backdrop
      >
        <ModalBody className='!tw-h-[360px] !tw-w-[640px] !tw-border-none !tw-bg-transparent !tw-p-0'>
          <ReactPlayer
            controls
            url='https://www.youtube.com/watch?v=G3z2eZxF4-c'
            playing
          />
        </ModalBody>
      </Modal>
      <div className='tw-relative tw-flex tw-h-full tw-w-full tw-flex-wrap tw-items-center tw-justify-center tw-bg-white'>
        <img
          src={cloud}
          className='tw-absolute tw-left-0 tw-top-0 tw-h-full tw-max-w-[40%] tw-rotate-180'
        />
        <div className='tw-flex tw-w-full tw-max-w-[480px] tw-flex-col tw-items-center tw-gap-4 tw-py-6'>
          <img src={calendarTimeline} className='tw-max-w-full' />
          <div className='tw-flex tw-max-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-10'>
            <div className='tw-flex tw-max-w-full tw-flex-col tw-items-center tw-gap-2 tw-text-center'>
              <span className='tw-text-[32px] tw-font-bold tw-text-black'>
                Calendar & Timeline
              </span>
              <div className='tw-relative tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-gap-4'>
                <InfoBox
                  title='All events, one location'
                  icon={
                    <CalendarDots
                      weight='duotone'
                      size={40}
                      className='!tw-text-systemBlue-100'
                    />
                  }
                />
                <img
                  src={gradient}
                  className='tw-invisible tw-absolute tw-left-[25%] tw-top-[50px] tw-h-[1px] tw-w-24 sm:tw-visible'
                />
                <InfoBox
                  title='Track and approve time off'
                  icon={
                    <TreePalm
                      weight='duotone'
                      size={40}
                      className='!tw-text-systemBlue-100'
                    />
                  }
                />
                <img
                  src={gradient}
                  className='tw-invisible tw-absolute tw-right-[25%] tw-top-[50px] tw-h-[1px] tw-w-24 sm:tw-visible'
                />
                <InfoBox
                  title='Connect with other calendars'
                  icon={
                    <PlugsConnected
                      size={40}
                      weight='duotone'
                      className='!tw-text-systemBlue-100'
                    />
                  }
                />
              </div>
              <span className='tw-text-xs tw-font-normal tw-text-text-80'>
                Simplify team planning! Handle time off overlaps, approve
                requests, and stay updated on upcoming birthdays and work
                anniversaries, all in one place.
              </span>
            </div>

            <div className='tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4'>
              <DefaultBtn
                color='secondary'
                size='lg'
                onClick={() => {
                  setOnboardingDone(true)
                  localStorage.setItem('calendar-onboarding', 'done')
                }}
              >
                Get Started
              </DefaultBtn>

              <Button
                icon={<Play size={20} />}
                text='Watch Video'
                onClick={() => setPlayVideo(true)}
                ghost
                className='!tw-border-none !tw-bg-transparent !tw-text-secondary-100'
              />
            </div>
          </div>
        </div>
        <img
          src={cloud}
          className='tw-absolute tw-right-0 tw-top-0 tw-h-full tw-max-w-[40%]'
        />
      </div>
    </div>
  )
}

export default Onboarding
