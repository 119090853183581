import React from 'react'

export const Fulltime = ({ isActive }) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 1C12.0231 1.0045 8.21044 2.5863 5.39837 5.39837C2.5863 8.21044 1.0045 12.0231 1 16C1 16.2652 1.10536 16.5196 1.29289 16.7071C1.48043 16.8946 1.73478 17 2 17C2.26522 17 2.51957 16.8946 2.70711 16.7071C2.89464 16.5196 3 16.2652 3 16C2.99042 13.0023 4.01377 10.0929 5.89784 7.76129C7.7819 5.42969 10.4117 3.8183 13.3445 3.19831C16.2774 2.57831 19.3343 2.98756 22.0008 4.35719C24.6673 5.72681 26.7805 7.9732 27.985 10.7183C29.1894 13.4633 29.4114 16.5395 28.6136 19.4291C27.8159 22.3187 26.0471 24.8452 23.6049 26.5836C21.1627 28.3219 18.1962 29.1658 15.2047 28.9734C12.2132 28.781 9.37934 27.5639 7.18 25.527L9.854 22.854C9.91666 22.7914 9.96157 22.7133 9.98412 22.6276C10.0067 22.542 10.0061 22.4518 9.98237 22.3665C9.95867 22.2812 9.91272 22.2036 9.84923 22.1419C9.78573 22.0801 9.70697 22.0363 9.621 22.015L1.621 20.015C1.54279 19.9955 1.46102 19.9952 1.38267 20.0141C1.30431 20.033 1.23169 20.0706 1.17099 20.1236C1.11029 20.1767 1.06331 20.2436 1.03406 20.3187C1.00481 20.3938 0.994155 20.4749 1.003 20.555L2.003 29.555C2.01338 29.6487 2.05005 29.7376 2.1088 29.8114C2.16755 29.8852 2.24597 29.9409 2.335 29.972C2.388 29.9907 2.44381 30.0001 2.5 30C2.56571 30.0001 2.63079 29.9873 2.69153 29.9622C2.75227 29.9372 2.80748 29.9004 2.854 29.854L5.768 26.939C7.55323 28.6089 9.71973 29.8169 12.0787 30.4577C14.4377 31.0985 16.9177 31.1528 19.3024 30.6157C21.6872 30.0786 23.9044 28.9665 25.7609 27.3762C27.6175 25.786 29.0569 23.7659 29.9539 21.4919C30.8509 19.2179 31.1782 16.7591 30.9073 14.3297C30.6364 11.9002 29.7755 9.5739 28.3997 7.55338C27.0238 5.53285 25.1747 3.87944 23.0135 2.73721C20.8522 1.59498 18.4445 0.998589 16 1Z'
        fill={isActive ? '#98ACF3' : '#CAC9CA'}
      />
      <path
        d='M9.854 22.8539L7.18 25.5269L5.768 26.9389L2.854 29.8539C2.80748 29.9003 2.75227 29.937 2.69153 29.9621C2.63079 29.9872 2.56571 30 2.5 29.9999C2.44381 30 2.388 29.9905 2.335 29.9719C2.24597 29.9407 2.16755 29.8851 2.1088 29.8113C2.05005 29.7375 2.01338 29.6486 2.003 29.5549L1.003 20.5549C0.994155 20.4747 1.00481 20.3937 1.03406 20.3186C1.06331 20.2435 1.11029 20.1765 1.17099 20.1235C1.23169 20.0704 1.30431 20.0329 1.38267 20.014C1.46102 19.995 1.54279 19.9954 1.621 20.0149L9.621 22.0149C9.70697 22.0362 9.78573 22.08 9.84923 22.1417C9.91272 22.2035 9.95867 22.281 9.98237 22.3664C10.0061 22.4517 10.0067 22.5418 9.98412 22.6275C9.96157 22.7131 9.91666 22.7913 9.854 22.8539Z'
        fill={isActive ? 'var(--primary)' : '#6C6C72'}
      />
      <path
        d='M16 6C15.7348 6 15.4804 6.10536 15.2929 6.29289C15.1054 6.48043 15 6.73478 15 7V16C15 16.2652 15.1054 16.5196 15.2929 16.7071C15.4804 16.8946 15.7348 17 16 17H25C25.2652 17 25.5196 16.8946 25.7071 16.7071C25.8946 16.5196 26 16.2652 26 16C26 15.7348 25.8946 15.4804 25.7071 15.2929C25.5196 15.1054 25.2652 15 25 15H17V7C17 6.73478 16.8946 6.48043 16.7071 6.29289C16.5196 6.10536 16.2652 6 16 6Z'
        fill={isActive ? 'var(--primary)' : '#6C6C72'}
      />
    </svg>
  )
}

export const RpIcon = ({ isActive }) => {
  return (
    <svg
      width='28'
      height='30'
      viewBox='0 0 28 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_299_47'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='28'
        height='30'
      >
        <path d='M0 0H27.8105V29.2458H0V0Z' fill='white' />
      </mask>
      <g mask='url(#mask0_299_47)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.8922 10.5708C13.0025 10.5708 12.166 10.9189 11.537 11.5506C10.9076 12.1826 10.5611 13.0228 10.5611 13.9163C10.5611 14.8098 10.9076 15.65 11.5366 16.2817L13.9198 18.6751L16.2754 16.3097C17.5744 15.0051 17.5744 12.8829 16.2754 11.5779L16.2482 11.5506C15.6188 10.9189 14.7823 10.5708 13.8922 10.5708ZM13.9131 4.22839C11.4347 4.22839 8.95661 5.17651 7.06981 7.07241C5.22795 8.92384 4.22136 11.3712 4.22451 13.9743C4.22838 16.5766 5.24165 19.0208 7.07754 20.8563L9.47799 23.2558L10.934 21.7928L8.50791 19.3542C7.07122 17.9111 6.28019 15.9915 6.28019 13.9499C6.28019 11.9083 7.07122 9.98911 8.50791 8.54558C9.94355 7.10205 11.8535 6.30706 13.885 6.30706C15.9164 6.30706 17.8264 7.10205 19.2624 8.54593L19.2905 8.5738C22.2558 11.5537 22.2558 16.4023 19.2905 19.3825L16.8921 21.7928L18.3439 23.2515L20.7563 20.8274C24.5296 17.0349 24.5296 10.8649 20.7563 7.07241C18.8695 5.17651 16.3915 4.22839 13.9131 4.22839ZM18.3488 29.2459L13.914 24.7923L9.48347 29.2416L4.09885 23.8639C1.46118 21.2291 0.00529608 17.7207 1.24486e-05 13.9842C-0.00489475 10.2474 1.44115 6.73467 4.07249 4.09254C9.50385 -1.36251 18.3245 -1.36216 23.745 4.08196C29.1658 9.52537 29.1658 18.383 23.745 23.8268L18.3488 29.2459Z'
          fill={isActive ? 'var(--primary)' : '#BDBDBD'}
        />
      </g>
    </svg>
  )
}

export const Fixed = ({ isActive, style }) => {
  return (
    <svg
      width='32'
      height='27'
      viewBox='0 0 32 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.8913 11.3128L19.6611 8.96297C19.916 8.89467 20.1808 9.05628 20.2525 9.32395L21.5511 14.1704C21.6229 14.4381 21.4744 14.7105 21.2194 14.7788L12.4496 17.1286C12.1947 17.1969 11.9299 17.0353 11.8582 16.7677L10.5595 11.9212C10.4878 11.6535 10.6363 11.3812 10.8913 11.3128Z'
        fill={isActive ? '#98ACF3' : '#CAC9CA'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5159 10.0352H20.5951C20.8591 10.0352 21.073 10.2598 21.073 10.5369V15.5544C21.073 15.8315 20.8591 16.0561 20.5951 16.0561H11.5159C11.252 16.0561 11.0381 15.8315 11.0381 15.5544V10.5369C11.0381 10.2598 11.252 10.0352 11.5159 10.0352Z'
        fill={isActive ? 'var(--primary)' : '#6C6C72'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.0561 2.00698C11.9716 2.00698 8.40396 4.22491 6.49385 7.52691C6.21634 8.00664 5.60248 8.17057 5.12275 7.89307C4.64302 7.61556 4.47909 7.00169 4.7566 6.52196C7.01069 2.62531 11.2262 0 16.0561 0C23.2609 0 29.1015 5.84062 29.1015 13.0454C29.1015 13.5996 28.6522 14.0489 28.098 14.0489C27.5438 14.0489 27.0945 13.5996 27.0945 13.0454C27.0945 6.94904 22.1525 2.00698 16.0561 2.00698ZM4.01423 12.0419C4.56845 12.0419 5.01772 12.4912 5.01772 13.0454C5.01772 19.1417 9.95979 24.0838 16.0561 24.0838C20.1406 24.0838 23.7083 21.8659 25.6184 18.5639C25.8959 18.0841 26.5098 17.9202 26.9895 18.1977C27.4692 18.4752 27.6332 19.0891 27.3557 19.5688C25.1016 23.4655 20.886 26.0908 16.0561 26.0908C8.85136 26.0908 3.01074 20.2502 3.01074 13.0454C3.01074 12.4912 3.46002 12.0419 4.01423 12.0419Z'
        fill={isActive ? '#98ACF3' : '#CAC9CA'}
      />
      <path
        d='M5.24898 10.3831L8.70353 14.7799C9.22086 15.4383 8.75181 16.4033 7.91447 16.4033L1.00536 16.4033C0.168014 16.4033 -0.301034 15.4383 0.216295 14.7799L3.67085 10.3831C4.07263 9.87179 4.8472 9.87179 5.24898 10.3831Z'
        fill={isActive ? 'var(--primary)' : '#6C6C72'}
      />
      <path
        d='M26.751 16.0202L23.2965 11.6235C22.7791 10.965 23.2482 10 24.0855 10L30.9946 10C31.832 10 32.301 10.9651 31.7837 11.6235L28.3291 16.0202C27.9274 16.5315 27.1528 16.5315 26.751 16.0202Z'
        fill={isActive ? 'var(--primary)' : '#6C6C72'}
      />
      <path
        d='M18.0628 13.0451C18.0628 14.1535 17.1642 15.0521 16.0558 15.0521C14.9474 15.0521 14.0488 14.1535 14.0488 13.0451C14.0488 11.9366 14.9474 11.0381 16.0558 11.0381C17.1642 11.0381 18.0628 11.9366 18.0628 13.0451Z'
        fill='white'
      />
    </svg>
  )
}

export const PayAsYouGo = ({ isActive }) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.3'
        d='M2.22857 0H10.4837C10.9093 0 11.3212 0.157105 11.6461 0.443424L17.3624 5.47993C17.7668 5.83619 18 6.35985 18 6.9115V22.6042C18 24.8423 17.9755 25 15.7714 25H2.22857C0.0245376 25 0 24.8423 0 22.6042V2.39583C0 0.157656 0.0245376 0 2.22857 0ZM6 13.75C5.33726 13.75 4.8 14.3096 4.8 15C4.8 15.6904 5.33726 16.25 6 16.25H14.4C15.0627 16.25 15.6 15.6904 15.6 15C15.6 14.3096 15.0627 13.75 14.4 13.75H6ZM6 18.75C5.33726 18.75 4.8 19.3096 4.8 20C4.8 20.6904 5.33726 21.25 6 21.25H9.6C10.2627 21.25 10.8 20.6904 10.8 20C10.8 19.3096 10.2627 18.75 9.6 18.75H6Z'
        fill={isActive ? 'var(--primary)' : '#6C6C72'}
      />
      <path
        d='M4.32143 2H14.1705C14.6139 2 15.0429 2.15711 15.3814 2.44342L21.3359 7.47993C21.7571 7.83619 22 8.35985 22 8.9115V24.6042C22 26.8423 21.9744 27 19.6786 27H4.32143C2.02556 27 2 26.8423 2 24.6042V4.39583C2 2.15766 2.02556 2 4.32143 2Z'
        fill={isActive ? 'var(--primary)' : '#6C6C72'}
      />
      <circle cx='25' cy='25' r='7' fill={isActive ? '#98ACF3' : '#CAC9CA'} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25 21C25.5523 21 26 21.4477 26 22V25H28C28.5523 25 29 25.4477 29 26C29 26.5523 28.5523 27 28 27H25C24.4477 27 24 26.5523 24 26V22C24 21.4477 24.4477 21 25 21Z'
        fill='white'
      />
      <rect x='5' y='18' width='6' height='2' rx='1' fill='white' />
      <rect x='5' y='14' width='10' height='2' rx='1' fill='white' />
    </svg>
  )
}

export const Milestone = ({ isActive }) => {
  return (
    <svg
      width='32'
      height='30'
      viewBox='0 0 32 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='17'
        y='4'
        width='2'
        height='8'
        fill={isActive ? '#98ACF3' : '#CAC9CA'}
      />
      <path
        d='M22.9632 3.13069L18.4648 0.769038C17.799 0.419456 17 0.902378 17 1.65444V5.59052C17 6.27937 17.6802 6.76194 18.3303 6.53438L22.8287 4.95994C23.6539 4.67113 23.7373 3.53707 22.9632 3.13069Z'
        fill={isActive ? 'var(--primary)' : '#6C6C72'}
      />
      <path
        d='M4.59375 22.4531L0 30H32L21.7699 12.7308C20.2315 10.1339 16.481 10.1115 14.9117 12.6897L8.96875 22.4531C8.46988 23.2727 7.28012 23.2727 6.78125 22.4531C6.28238 21.6335 5.09262 21.6335 4.59375 22.4531Z'
        fill={isActive ? 'var(--primary)' : '#6C6C72'}
      />
    </svg>
  )
}

export const RpTemplate = ({ isActive }) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 0L21.8 0C24.1196 0 26 1.81325 26 4.05V22.95C26 25.1868 24.1196 27 21.8 27H18V0Z'
        fill={isActive ? '#98ACF3' : '#C1C1C3'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.375 0H17.875C20.1532 0 22 1.81325 22 4.05V22.95C22 25.1868 20.1532 27 17.875 27H1.375C0.615608 27 0 26.3956 0 25.65V1.35C0 0.604416 0.615608 0 1.375 0Z'
        fill={isActive ? 'var(--primary)' : '#6C6C72'}
      />
      <circle cx='25' cy='25' r='7' fill={isActive ? '#98ACF3' : '#C1C1C3'} />
      <path
        d='M13.75 9.45C13.75 10.9412 12.5188 12.15 11 12.15C9.48122 12.15 8.25 10.9412 8.25 9.45C8.25 7.95883 9.48122 6.75 11 6.75C12.5188 6.75 13.75 7.95883 13.75 9.45Z'
        fill='white'
      />
      <path
        d='M4.68073 20.25C4.48916 20.25 4.10937 19.7937 4.1255 19.5743C4.42159 15.5474 7.38063 13.5 10.9873 13.5C14.6447 13.5 17.6496 15.4349 17.8734 19.575C17.8823 19.7399 17.8734 20.25 17.2996 20.25H4.68073Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.2318 25.1402C20.8782 24.7159 20.9355 24.0853 21.3598 23.7318L24.3598 21.2318C24.7306 20.9227 25.2693 20.9227 25.6402 21.2318L28.6402 23.7318C29.0644 24.0853 29.1218 24.7159 28.7682 25.1402C28.4146 25.5644 27.7841 25.6218 27.3598 25.2682L25 23.3017L22.6402 25.2682C22.2159 25.6218 21.5853 25.5644 21.2318 25.1402Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25 21C25.5523 21 26 21.4214 26 21.9412V28.0588C26 28.5786 25.5523 29 25 29C24.4477 29 24 28.5786 24 28.0588V21.9412C24 21.4214 24.4477 21 25 21Z'
        fill='white'
      />
    </svg>
  )
}

export const YourTemplate = ({ isActive }) => {
  return (
    <svg
      width='31'
      height='32'
      viewBox='0 0 31 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.375 0H21.875C24.1532 0 26 1.81325 26 4.05V22.95C26 25.1868 24.1532 27 21.875 27H5.375C4.61561 27 4 26.3956 4 25.65V1.35C4 0.604416 4.61561 0 5.375 0Z'
        fill={isActive ? '#98ACF3' : '#C1C1C3'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.375 0H17.875C20.1532 0 22 1.81325 22 4.05V22.95C22 25.1868 20.1532 27 17.875 27H1.375C0.615608 27 0 26.3956 0 25.65V1.35C0 0.604416 0.615608 0 1.375 0Z'
        fill={isActive ? 'var(--primary)' : '#6C6C72'}
      />
      <path
        d='M13.75 9.45C13.75 10.9412 12.5188 12.15 11 12.15C9.48122 12.15 8.25 10.9412 8.25 9.45C8.25 7.95883 9.48122 6.75 11 6.75C12.5188 6.75 13.75 7.95883 13.75 9.45Z'
        fill='white'
      />
      <path
        d='M4.68073 20.25C4.48916 20.25 4.10937 19.7937 4.1255 19.5743C4.42159 15.5474 7.38063 13.5 10.9873 13.5C14.6447 13.5 17.6496 15.4349 17.8734 19.575C17.8823 19.7399 17.8734 20.25 17.2996 20.25H4.68073Z'
        fill='white'
      />
      <path
        d='M26.0982 12.3628C25.446 11.8791 24.554 11.8791 23.9018 12.3628L23.3266 12.7895C23.0831 12.9701 22.7988 13.0879 22.4988 13.1323L21.7904 13.2374C20.9872 13.3565 20.3565 13.9872 20.2374 14.7904L20.1323 15.4988C20.0879 15.7988 19.9701 16.0831 19.7895 16.3266L19.3628 16.9018C18.8791 17.554 18.8791 18.446 19.3628 19.0982L19.7895 19.6734C19.9701 19.9169 20.0879 20.2012 20.1323 20.5012L20.2374 21.2096C20.3565 22.0128 20.9872 22.6435 21.7904 22.7626L22 22.7937V32L25 29.8571L28 32V22.7937L28.2096 22.7626C29.0128 22.6435 29.6435 22.0128 29.7626 21.2096L29.8677 20.5012C29.9121 20.2012 30.0299 19.9169 30.2105 19.6734L30.6372 19.0982C31.1209 18.446 31.1209 17.554 30.6372 16.9018L30.2105 16.3266C30.0299 16.0831 29.9121 15.7988 29.8677 15.4988L29.7626 14.7904C29.6435 13.9872 29.0128 13.3565 28.2096 13.2374L27.5012 13.1323C27.2012 13.0879 26.9169 12.9701 26.6734 12.7895L26.0982 12.3628Z'
        fill={isActive ? '#98ACF3' : '#C1C1C3'}
      />
      <circle cx='25' cy='18' r='3' fill='white' />
    </svg>
  )
}

export const HourGlass = ({ className, style, id }) => {
  return (
    <svg
      width='14'
      height='16'
      viewBox='0 0 14 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
      id={id}
    >
      <path
        d='M1 0.667969H13'
        stroke='currentColor'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M1 15.3359H13'
        stroke='currentColor'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M4.3335 13.336V12.81C4.33353 12.6788 4.37226 12.5506 4.44483 12.4413L6.44483 9.44135C6.50571 9.35004 6.58819 9.27518 6.68495 9.2234C6.78171 9.17162 6.88975 9.14453 6.9995 9.14453C7.10924 9.14453 7.21728 9.17162 7.31404 9.2234C7.4108 9.27518 7.49328 9.35004 7.55416 9.44135L9.55416 12.4413C9.62722 12.5509 9.66619 12.6797 9.66616 12.8113V13.336H4.3335Z'
        fill='currentColor'
      />
      <path
        d='M2.3335 15.3346V12.8086C2.33351 12.2821 2.48939 11.7674 2.7815 11.3293L5.00016 8.0013L2.7815 4.6733C2.48939 4.23523 2.33351 3.72049 2.3335 3.19397V0.667969'
        stroke='currentColor'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M11.6667 0.667969V3.19397C11.6667 3.72049 11.5108 4.23523 11.2187 4.6733L9 8.0013L11.2187 11.3293C11.5108 11.7674 11.6667 12.2821 11.6667 12.8086V15.3346'
        stroke='currentColor'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
    </svg>
  )
}

export const ErrorIcon = ({ className, style, id }) => {
  return (
    <svg
      width='4'
      height='20'
      viewBox='0 0 4 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
      id={id}
    >
      <path
        d='M3.25016 14.168L3.66683 0.417969H0.333496L0.750163 14.168H3.25016Z'
        fill='currentColor'
      />
      <path
        d='M2.00016 19.5833C2.92064 19.5833 3.66683 18.8371 3.66683 17.9167C3.66683 16.9962 2.92064 16.25 2.00016 16.25C1.07969 16.25 0.333496 16.9962 0.333496 17.9167C0.333496 18.8371 1.07969 19.5833 2.00016 19.5833Z'
        fill='currentColor'
      />
    </svg>
  )
}

export const Check = ({ className, style, id }) => {
  return (
    <svg
      width='16'
      height='11'
      viewBox='0 0 16 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
      id={id}
    >
      <path
        d='M5.99994 10.9999C5.73474 10.9999 5.48043 10.8945 5.29294 10.7069L0.585938 5.99994L1.99994 4.58594L5.99994 8.58594L13.9999 0.585938L15.4139 1.99994L6.70694 10.7069C6.51944 10.8945 6.26513 10.9999 5.99994 10.9999Z'
        fill='currentColor'
      />
    </svg>
  )
}

export const Contractor = ({ isActive, className, style, id }) => {
  return (
    <svg
      width='42'
      height='34'
      viewBox='0 0 42 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
      id={id}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.3607 14.7953L25.7583 11.7413C26.0896 11.6526 26.4338 11.8626 26.527 12.2105L28.2147 18.5092C28.3079 18.857 28.1149 19.211 27.7836 19.2998L16.386 22.3538C16.0547 22.4425 15.7105 22.2325 15.6173 21.8846L13.9296 15.5859C13.8364 15.2381 14.0294 14.8841 14.3607 14.7953Z'
        fill={isActive ? 'var(--primary-50)' : 'var(--text-10)'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.1727 13.1284H26.9724C27.3154 13.1284 27.5934 13.4204 27.5934 13.7805V20.3014C27.5934 20.6616 27.3154 20.9535 26.9724 20.9535H15.1727C14.8297 20.9535 14.5516 20.6616 14.5516 20.3014V13.7805C14.5516 13.4204 14.8297 13.1284 15.1727 13.1284Z'
        fill={isActive ? 'var(--primary-100)' : 'var(--text-30)'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.0725 2.69979C15.7641 2.69979 11.1274 5.58229 8.64496 9.87371C8.28429 10.4972 7.48649 10.7102 6.86302 10.3496C6.23954 9.98892 6.02649 9.19112 6.38715 8.56764C9.31666 3.50339 14.7953 0.0914307 21.0725 0.0914307C30.4361 0.0914307 38.0268 7.68213 38.0268 17.0457C38.0268 17.766 37.4429 18.3499 36.7226 18.3499C36.0023 18.3499 35.4184 17.766 35.4184 17.0457C35.4184 9.12269 28.9955 2.69979 21.0725 2.69979ZM5.42234 15.7416C6.14262 15.7416 6.72652 16.3255 6.72652 17.0457C6.72652 24.9688 13.1494 31.3917 21.0725 31.3917C26.3809 31.3917 31.0175 28.5092 33.5 24.2178C33.8607 23.5943 34.6585 23.3812 35.2819 23.7419C35.9054 24.1026 36.1185 24.9004 35.7578 25.5238C32.8283 30.5881 27.3496 34.0001 21.0725 34.0001C11.7089 34.0001 4.11816 26.4093 4.11816 17.0457C4.11816 16.3255 4.70206 15.7416 5.42234 15.7416Z'
        fill={isActive ? 'var(--primary-50)' : 'var(--text-10)'}
      />
      <path
        d='M7.02747 13.5896L11.5172 19.3037C12.1895 20.1594 11.5799 21.4137 10.4917 21.4137L1.51229 21.4137C0.424046 21.4137 -0.185548 20.1594 0.486794 19.3037L4.97647 13.5896C5.49864 12.925 6.50531 12.925 7.02747 13.5896Z'
        fill={isActive ? 'var(--primary-100)' : 'var(--text-30)'}
      />
      <path
        d='M34.9724 20.9119L30.4827 15.1978C29.8104 14.3421 30.42 13.0878 31.5082 13.0878L40.4876 13.0878C41.5758 13.0878 42.1854 14.3421 41.5131 15.1978L37.0234 20.9119C36.5012 21.5765 35.4946 21.5765 34.9724 20.9119Z'
        fill={isActive ? 'var(--primary-100)' : 'var(--text-30)'}
      />
      <path
        d='M23.6808 17.0466C23.6808 18.4872 22.513 19.655 21.0725 19.655C19.6319 19.655 18.4641 18.4872 18.4641 17.0466C18.4641 15.606 19.6319 14.4382 21.0725 14.4382C22.513 14.4382 23.6808 15.606 23.6808 17.0466Z'
        fill='white'
      />
    </svg>
  )
}
