import { WarningOctagon } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'

import { ModalCloseButton } from '../../components/Common/modal-close-button'
import Button from '../../components/ui/button'
import { useFetch } from '../../helpers/hooks'
import {
  approveBillBulk,
  approveExpenseBulk,
  approveInvoiceBulk,
  approvePayments,
  approveTimeOffBulk,
  approveWorkBulk,
  declineBillBulk,
  declineExpenseBulk,
  declineInvoiceBulk,
  declinePayments,
  declineTimeOffBulk,
  declineWorkBulk,
} from '../../services/api'
import { getErrorMessage } from '../../utils/get-errors'
import { TODO_TYPE } from '../Activity/center-overview-client'
import { useReviewContext } from './review-columns'
import { t } from 'i18next'

function getBulkAction({ type, items }) {
  function _getSelectedIds(selectedItems) {
    return (
      selectedItems?.filter((item) => item.selected)?.map((item) => item.id) ??
      []
    )
  }

  const selectedIds = _getSelectedIds(items)

  switch (type) {
    case TODO_TYPE.timeOff: {
      return {
        approve: approveTimeOffBulk,
        approveBody: () => {
          return { timeoff_ids: selectedIds }
        },
        decline: declineTimeOffBulk,
        declineBody: () => {
          return { timeoff_ids: selectedIds }
        },
      }
    }

    case TODO_TYPE.expenses: {
      return {
        approve: approveExpenseBulk,
        approveBody: () => {
          return { expense_ids: selectedIds }
        },
        decline: declineExpenseBulk,
        declineBody: () => {
          return { expense_ids: selectedIds }
        },
      }
    }

    case TODO_TYPE.works: {
      return {
        approve: approveWorkBulk,
        approveBody: () => {
          return { work_ids: selectedIds }
        },
        decline: declineWorkBulk,
        declineBody: () => {
          return { work_ids: selectedIds }
        },
      }
    }

    case TODO_TYPE.payments: {
      return {
        approve: approvePayments,
        approveBody: () => {
          return { payment_ids: selectedIds }
        },
        decline: declinePayments,
        declineBody: () => {
          return { payment_ids: selectedIds }
        },
      }
    }

    case TODO_TYPE.invoices: {
      return {
        approve: approveInvoiceBulk,
        approveBody: () => {
          return { invoice_ids: selectedIds }
        },
        decline: declineInvoiceBulk,
        declineBody: () => {
          return { invoice_ids: selectedIds }
        },
      }
    }

    case TODO_TYPE.bills: {
      return {
        approve: approveBillBulk,
        approveBody: () => {
          return { bill_ids: selectedIds }
        },
        decline: declineBillBulk,
        declineBody: () => {
          return { bill_ids: selectedIds }
        },
      }
    }

    default: {
      return {
        approve: () => {},
        approveBody: () => {},
        decline: () => {},
        declineBody: () => {},
      }
    }
  }
}

export function ReviewCenterBulkEdit({ selectedItems }) {
  const [isModalOpen, setIsModalOpen] = useState({
    approve: false,
    decline: false,
  })
  function closeModal() {
    setIsModalOpen({ approve: false, decline: false })
  }

  const {
    isSelecting,
    refreshReviewList,
    toggleSelecting,
    setUrlState,
    activeTab,
  } = useReviewContext()

  const selectedItemsCount = !selectedItems
    ? 0
    : selectedItems?.reduce((acc, item) => (item?.selected ? acc + 1 : acc), 0)

  function handleOnUpdate() {
    toastr.success(t('Items updated successfully'))
    refreshReviewList?.()
    toggleSelecting?.()
    setUrlState({ selectedItem: undefined })
  }

  const actions = getBulkAction({ type: activeTab, items: selectedItems })

  const { startFetch: bulkApproveItems, isLoading: bulkApprovingItems } =
    useFetch({
      action: actions.approve,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(t('Something went wrong while approving time offs'))
        } else {
          handleOnUpdate()
        }
      },
      onError: (error) => {
        toastr.error(getErrorMessage(error))
      },
    })

  const { startFetch: bulkDeclineItems, isLoading: bulkDecliningItems } =
    useFetch({
      action: actions.decline,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(t('Something went wrong while declining time offs'))
        } else {
          handleOnUpdate()
        }
      },
      onError: (error) => {
        toastr.error(getErrorMessage(error))
      },
    })

  const loading = bulkApprovingItems || bulkDecliningItems
  const noSelectedItems = selectedItemsCount <= 0
  const actionsDisabled = loading || noSelectedItems

  if (!isSelecting) {
    return null
  }

  return (
    <div className='tw-absolute tw-bottom-[calc(-1*var(--padding-content-bottom))] tw-left-[calc(-1*var(--padding-content-x))] tw-right-[calc(-1*var(--padding-content-x))] tw-z-20 tw-flex tw-items-center tw-justify-between tw-bg-white tw-px-[--padding-content-x] tw-py-3'>
      <Button
        color='danger'
        disabled={actionsDisabled}
        loading={bulkDecliningItems}
        onClick={() => setIsModalOpen({ approve: false, decline: true })}
      >
        {t('Decline')}
      </Button>

      {noSelectedItems ? (
        t('Select items')
      ) : (
        <span>
          {t('item_selection.counter', { count: selectedItemsCount })}
        </span>
      )}

      <Button
        color='success'
        disabled={actionsDisabled}
        loading={bulkApprovingItems}
        onClick={() => setIsModalOpen({ approve: true, decline: false })}
      >
        {t('Approve')}
      </Button>

      {!isModalOpen.approve && !isModalOpen.decline ? null : (
        <ConfirmModal
          isOpen={isModalOpen.approve || isModalOpen.decline}
          closeModal={closeModal}
          title={isModalOpen.approve ? t('Bulk Approval') : t('Bulk Decline')}
          message={
            isModalOpen.approve
              ? t('items_approve_confirmation', { count: selectedItemsCount })
              : t('items_decline_confirmation', { count: selectedItemsCount })
          }
          confirmText={isModalOpen.approve ? t('Approve') : t('Decline')}
          onConfirm={() => {
            if (isModalOpen.approve) {
              bulkApproveItems(actions.approveBody())
            } else {
              bulkDeclineItems(actions.declineBody())
            }
            closeModal()
          }}
        />
      )}
    </div>
  )
}

function ConfirmModal({
  isOpen,
  closeModal,
  title = t('Bulk Approval'),
  message = t('Are you sure you want to approve the selected items?'),
  confirmText,
  onConfirm,
  loading,
}) {
  return (
    <Modal isOpen={isOpen} toggle={closeModal} centered>
      <ModalHeader
        className='!tw-border-none !tw-pb-0'
        close={<ModalCloseButton toggle={closeModal} />}
      >
        <WarningOctagon size={24} className='tw-text-systemGold-100' />
      </ModalHeader>

      <ModalBody>
        <h3 className='tw-text-xl tw-font-semibold tw-text-secondary-120'>
          {title}
        </h3>
        <p className='tw-mb-0 tw-text-sm tw-font-medium tw-text-text-80'>
          {message}
        </p>
      </ModalBody>

      <ModalFooter>
        <Button color='light' outline disabled={loading} onClick={closeModal}>
          {t('No, go back')}
        </Button>
        <Button disabled={loading} loading={loading} onClick={onConfirm}>
          {confirmText}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
