import React, { useState } from 'react'
import { Col, Row, Spinner, Table } from 'reactstrap'
import toastr from 'toastr'

import Toggle from '../../../../components/Forms/Toggle/Toggle'
import { SimpleCard } from '../../../../components/simple-card'
import BadgeX from '../../../../components/Table/BadgeX'
import { StyledH5, StyledH6 } from '../../../../components/Typo'
import Button from '../../../../components/ui/button'
import Loader from '../../../../components/ui/loader'
import PageHeading from '../../../../components/ui/page-heading'
import Pagination from '../../../../components/ui/pagination'
import UserFlagging from '../../../../components/userFlag'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import { WITHDRAW_METHODS_BY_ID } from '../../../../core/config/payment-methods'
import { useFetch } from '../../../../helpers/hooks'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import {
  autoWithdraw,
  getWithdrawList,
  multipleAutoWithdraw,
} from '../../../../services/api'
import { getCurrencyFormatter } from '../../../../utils/formatters/currency'

const AutoWithdraw = () => {
  const [selectedIds, setSelectedIds] = useState([])
  const [forWithdraw, setForWithdraw] = useState(-1)
  const [page, setPage] = useState(1)

  const filters = { page, perPage: 40 }

  const withdraws = useFetch(
    {
      autoFetch: true,
      initResult: null,
      action: getWithdrawList,
      withAdminAccess: true,
      body: filters,
      onError: (err) => {
        toastr.error(err)
      },
    },
    [page],
  )
  const withdrawsData = withdraws?.data

  const showPaginator =
    withdraws.paginator?.first_page_url !== withdraws.paginator?.last_page_url

  const withdraw = useFetch({
    action: autoWithdraw,
    withAdminAccess: true,
    onComplete: () => {
      setForWithdraw(-1)
      setSelectedIds([])
      withdraws.startFetch(filters, false)
      toastr.success('Withdraw completed')
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const multipleWithdraw = useFetch({
    action: multipleAutoWithdraw,
    withAdminAccess: true,
    onComplete: () => {
      setSelectedIds([])
      withdraws.startFetch(filters, true)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  function formatWithdrawItem(item) {
    return {
      id: item?.id,
      user_id: item?.contractor?.id,
      amount: item?.balance,
      currency_id: item?.currency?.id,
      payment_method_id: item?.contractor?.default_account?.method_id,
      account_id: item?.contractor?.default_account?.id,
    }
  }

  function handleWithdraw(item, index) {
    setForWithdraw(index)
    withdraw.startFetch(formatWithdrawItem(item))
  }

  function isCheckable(item) {
    return item.failed !== 1 || item.queued !== 1
  }

  const selectableItems = withdrawsData?.filter(isCheckable)
  const isAllChecked = selectableItems?.length === selectedIds?.length

  function handleCheckAll() {
    if (isAllChecked) {
      setSelectedIds([])
    } else {
      setSelectedIds(selectableItems.map((item) => item.id))
    }
  }

  function handleWithdrawChecked(data) {
    setSelectedIds((prev) => {
      if (prev.includes(data.id)) {
        return prev.filter((id) => id !== data.id)
      } else {
        return [...prev, data.id]
      }
    })
  }

  const selectedWithdraws = (withdrawsData ?? [])
    ?.filter((item) => selectedIds.includes(item.id))
    ?.map(formatWithdrawItem)

  function handleMultipleWithdraws() {
    const body = { withdraws: selectedWithdraws }
    multipleWithdraw.startFetch(body)
  }
  const hasEditPermission = useHasPermission(
    ADMIN_PERMISSIONS.MANAGE_AUTO_WITHDRAWS,
  )

  return (
    <div className='page-content'>
      <PageHeading>
        <PageHeading.Title>Auto withdraw</PageHeading.Title>

        <PageHeading.ActionGroup>
          <Button
            onClick={() => {
              handleMultipleWithdraws()
            }}
            disabled={selectedWithdraws.length === 0}
            color={selectedWithdraws.length === 0 ? 'secondary' : 'primary'}
            className='px-2 py-1 px-md-4 py-md-2'
          >
            Process Withdraws
            {selectedWithdraws.length > 0
              ? ` (${selectedWithdraws.length})`
              : ''}
          </Button>
          <Button
            onClick={() => {
              withdraws.startFetch(filters, true)
              setSelectedIds([])
            }}
            className='px-2 py-1 px-md-4 py-md-2'
          >
            Refresh Withdraws
          </Button>
        </PageHeading.ActionGroup>
      </PageHeading>

      <SimpleCard>
        {withdraws.isLoading ? (
          <Loader minHeight='max(50vh, 550px)' />
        ) : (
          <>
            <div className='tw-p-4 md:tw-hidden'>
              {withdrawsData?.map((order, key) => (
                <WithdrawCardAdmin
                  order={order}
                  withdraw={withdraw}
                  forWithdraw={forWithdraw}
                  handleWithdraw={(order, index) =>
                    handleWithdraw(order, index)
                  }
                  index={key}
                  key={key}
                  hasEditPermission={hasEditPermission}
                />
              ))}
            </div>
            <div className='table-responsive tw-mb-0 !tw-hidden md:!tw-block'>
              <Table className='table-centered table-nowrap'>
                <thead>
                  <tr>
                    <th className='!tw-border-t-0'>Contractor name</th>
                    <th className='!tw-border-t-0 tw-text-right'>
                      Withdraw Method
                    </th>
                    <th className='!tw-border-t-0 tw-text-right'>Balance</th>
                    <th className='!tw-border-t-0'>Actions</th>
                    <th className='!tw-border-t-0 tw-text-center'>
                      <Toggle check={isAllChecked} change={handleCheckAll} />
                    </th>
                  </tr>
                </thead>
                <tbody className='tw-text-disabled'>
                  {withdrawsData?.map((order, key) => {
                    const formatter = getCurrencyFormatter(
                      order?.currency?.code,
                    )

                    const withdrawLoading =
                      (withdraw.isLoading && forWithdraw === key) ||
                      (selectedWithdraws.filter((e) => e.id === order.id)
                        .length > 0 &&
                        multipleWithdraw.isLoading)

                    return (
                      <tr key={'_order_' + key}>
                        <td>
                          <div className='tw-flex'>
                            {order.contractor.is_flagged ? (
                              <UserFlagging
                                user={order?.contractor}
                                onCompleteAction={() => {
                                  withdraws.startFetch(filters, false)
                                }}
                              />
                            ) : null}
                            {order.contractor?.first_name}{' '}
                            {order.contractor?.last_name}
                          </div>
                        </td>
                        <td className='tw-text-right'>
                          {WITHDRAW_METHODS_BY_ID[
                            order.contractor?.default_account?.method_id
                          ]?.name ??
                            order.contractor?.default_account?.method_id}
                        </td>
                        <td className='tw-text-right'>
                          {formatter.format(order.balance)}
                        </td>
                        <td className='tw-text-right'>
                          {!hasEditPermission || order.queued === 1 ? null : (
                            <Button
                              size='sm'
                              onClick={() => {
                                handleWithdraw(order, key)
                              }}
                              loading={withdrawLoading}
                              disabled={withdrawLoading}
                            >
                              Withdraw
                            </Button>
                          )}
                        </td>
                        <td className='tw-text-center'>
                          {order.failed === 1 ? (
                            <BadgeX status='danger' pill>
                              Failed
                            </BadgeX>
                          ) : order.queued === 1 ? (
                            <Spinner size='sm' />
                          ) : (
                            <Toggle
                              id={`wdr${key}`}
                              check={selectedIds.includes(order.id)}
                              change={() => {
                                handleWithdrawChecked(order)
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              {!showPaginator || withdraws.isLoading ? null : (
                <div className='tw-flex tw-w-full tw-justify-end tw-pr-6'>
                  <Pagination
                    activePage={page}
                    itemsCountPerPage={withdraws.paginator?.per_page ?? 10}
                    totalItemsCount={withdraws.paginator?.total ?? 0}
                    onChange={(newPage) => setPage(newPage)}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </SimpleCard>
    </div>
  )
}
const WithdrawCardAdmin = ({
  index,
  order,
  handleWithdraw,
  withdraw,
  forWithdraw,
  hasEditPermission,
}) => {
  return (
    <div
      className='py-0 mb-3 position-relative rounded bg-white'
      style={{
        boxShadow: '0px 1px 0px #DFE1E6',
        border: '1px solid #E7E8F2',
      }}
    >
      <Row className='p-3 m-0 border-bottom'>
        <Col xs={12} className='p-0 m-0 d-flex justify-content-between'>
          <StyledH5 className='text-dark font-weight-normal mb-0 font-size-14'>
            Contractor name
          </StyledH5>

          <StyledH6 className='text-primary mb-0 border-secondary font-size-12'>
            {order.contractor?.first_name} {order.contractor?.last_name}
          </StyledH6>
        </Col>
      </Row>
      <div className='p-3'>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Balance
          </h6>
          <h6 className='text-dark font-size-14 font-weight-normal mb-0'>
            {order.balance}
          </h6>
        </div>
        <div className='d-flex mb-0 align-items-center justify-content-between align-items-center'>
          {!hasEditPermission ? null : (
            <Button
              onClick={() => handleWithdraw(order, index)}
              block
              loading={withdraw.isLoading && forWithdraw === index}
            >
              Withdraw
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default AutoWithdraw
