import React, { useMemo } from 'react'
import PhoneInput from 'react-phone-input-2'

import { cn } from 'ui'
import LabelContent from '../../../pages/Contract/CreateContract/components/label-content'
import InputFeedback from '../../ui/input-feedback'
import { getInputErrorMessage } from '../get-input-error-message'
import '../phoneInput/phoneInput.css'

const CustomPhoneInput = ({
  value,
  onChange,
  name,
  label,
  id,
  enableAreaCodes,
  error,
  required,
  ...props
}) => {
  const inputId = useMemo(() => id ?? name ?? 'phone', [id, name])

  return (
    <div className='position-relative tw-text-start'>
      {!label ? null : (
        <label htmlFor={inputId}>
          <LabelContent required={required}>{label}</LabelContent>
        </label>
      )}
      <PhoneInput
        enableAreaCodes={enableAreaCodes}
        inputClass={cn(
          'rtl:!tw-pr-12 rtl:!tw-pl-4 rtl:tw-text-right ltr:!tw-pl-12 ltr:!tw-pr-4 ltr:tw-text-left',
          { 'tw-border-systemRed-100': !!error },
        )}
        value={value}
        country='ae'
        onChange={onChange}
        {...props}
        inputProps={{ name: name ?? 'phone', id: inputId }}
      />
      {!error ? null : (
        <InputFeedback className='tw-mt-1'>
          {getInputErrorMessage(error)}
        </InputFeedback>
      )}
    </div>
  )
}

export default CustomPhoneInput
