import { Info } from '@phosphor-icons/react'
import React from 'react'
import { Alert as BsAlert, UncontrolledAlert } from 'reactstrap'

import { cn } from 'ui'

export default function Alert({
  color,
  children,
  innerTag: InnerTag = 'p',
  innerClassName,
  customIcon,
  className,
  iconClassName,
  style,
  id,
  uncontrolled,
}) {
  const icon = customIcon === false ? null : (customIcon ?? <Info />)

  const AlertComponent = uncontrolled ? UncontrolledAlert : BsAlert

  return (
    <AlertComponent
      color={color}
      className={cn('tw-flex tw-gap-2 !tw-p-4 tw-text-sm', className)}
      style={style}
      id={id}
    >
      {!icon ? null : (
        <div
          className={cn(
            'tw-flex tw-h-[--icon-size] tw-w-[--icon-size] tw-flex-shrink-0 tw-items-center tw-justify-center [--icon-size:1.5rem] [&>*]:tw-size-full',
            iconClassName,
          )}
        >
          {icon}
        </div>
      )}
      <InnerTag className={cn('!tw-mb-0 tw-mt-0.5 tw-text-sm', innerClassName)}>
        {children}
      </InnerTag>
    </AlertComponent>
  )
}

export function PrimaryAlert({ children, className, ...props }) {
  return (
    <Alert
      color='primary'
      className={cn(
        '!tw-mb-0 tw-gap-4 !tw-border-surface-30 !tw-bg-systemBlue-20 !tw-p-6 !tw-text-black',
        className,
      )}
      customIcon={
        <Info size='22' className='tw-flex-shrink-0 tw-text-systemBlue-110' />
      }
      {...props}
    >
      {children}
    </Alert>
  )
}
