import cx from 'classnames'
import React from 'react'
import { Col, Row } from 'reactstrap'

import { StyledH4, StyledP } from '../Typo'

const NoContent = ({
  title,
  actionTitle,
  onAction,
  withAction,
  subtitle,
  customBottom,
  actionIcon = 'fas fa-plus',
  advanced = true,
  image,
  headline,
  style,
}) => {
  return (
    <>
      {!title ? null : <h4 className='mb-3 font-size-18'>{title}</h4>}

      <Col
        xl={12}
        className='d-flex flex-column justify-content-center py-4'
        style={style}
      >
        {advanced && (
          <>
            {!image ? null : (
              <Row className='justify-content-center'>
                <img
                  src={image}
                  style={{ width: '400px', height: '350px' }}
                  alt=''
                />
              </Row>
            )}
            <Row className='justify-content-center'>
              <StyledH4
                min='22px'
                max='32px'
                className='text-gray-h text-center mb-0'
              >
                {headline === '' ? '' : headline || 'Welcome to RemotePass'}
              </StyledH4>
            </Row>
          </>
        )}
        <Row className={cx('justify-content-center', { 'mt-3': advanced })}>
          <StyledP
            min='14px'
            max='16px'
            style={{ width: '70%' }}
            className='text-secondary text-center'
          >
            {subtitle || 'Manage contracts, compliance and make payments'}
          </StyledP>
        </Row>

        <Row className='d-flex justify-content-center'>
          {customBottom ??
            (withAction ? (
              <button onClick={onAction} className='btn btn-primary'>
                {actionIcon !== null && (
                  <i
                    style={{ marginInlineEnd: '10px' }}
                    className={actionIcon}
                  />
                )}
                {actionTitle}
              </button>
            ) : null)}
        </Row>
      </Col>
    </>
  )
}

export default NoContent
