import React from 'react'
import { Box, BoxIcon } from './ui/box'
import { cn } from 'ui'

export default function ApprovalFlowSummary({
  icon,
  iconClassName,
  title,
  description,
  footer,
  footerClassName,
  className,
  isActive,
  rightItem,
}) {
  return (
    <Box noPadding className={className}>
      <div
        className={cn(
          'tw-flex tw-items-center tw-gap-4 tw-p-4 tw-text-start',
          isActive ? 'tw-bg-primary-10' : 'tw-bg-white',
        )}
      >
        <BoxIcon className={iconClassName}>{icon}</BoxIcon>
        <div className='tw-flex-1'>
          {!title ? null : (
            <p
              className={cn(
                'tw-mb-0 tw-text-wrap tw-break-all tw-font-bold tw-text-black',
                { 'tw-text-primary': isActive },
              )}
            >
              {title}
            </p>
          )}
          {!description ? null : (
            <p
              className={cn(
                'tw-mb-0 tw-mt-0.5 tw-text-wrap tw-text-secondary-100',
                { 'tw-text-primary': isActive },
              )}
            >
              {description}
            </p>
          )}
        </div>

        {rightItem}
      </div>

      {!footer ? null : (
        <div
          className={cn(
            'tw-flex tw-flex-wrap tw-items-center tw-gap-2 tw-border-t tw-border-surface-30 tw-bg-surface-10 tw-p-4',
            footerClassName,
          )}
        >
          {footer}
        </div>
      )}
    </Box>
  )
}
