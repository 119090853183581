import React, { useEffect, useRef, useState } from 'react'
import {
  CaretUp,
  CaretDown,
  ClockCountdown,
  TrashSimple,
} from '@phosphor-icons/react'
import { Card } from 'reactstrap'
import { cn } from 'ui'
import { useFormContext } from 'react-hook-form'
import { RemoveStepModal } from './modals/remove-step-modal'
import ControlledInput from '../../../../components/ControlledInput'

export function Delay(props) {
  const {
    id,
    index,
    stepsField: { remove },
  } = props
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()
  const focused = watch('focusedNode') === id
  const inputRef = useRef()
  useEffect(() => setValue('focusedNode', id), [setValue, id])
  const hasError = errors?.steps?.[index]
  const [removeModal, setRemoveModal] = useState(false)

  return (
    <div
      className={cn(
        'tw-rounded-md',
        hasError ? 'tw-border-2 tw-border-red' : undefined,
        focused ? 'tw-border-2 tw-border-primary-60' : undefined,
      )}
    >
      <Card
        onClick={() => setValue('focusedNode', focused ? null : id)}
        className='!tw-mb-0 tw-flex tw-cursor-pointer !tw-flex-row tw-items-center tw-justify-center tw-gap-2 tw-p-4'
      >
        <div
          className={cn(
            'tw-rounded tw-bg-secondary-20 tw-p-2',
            focused && 'tw-bg-primary-20',
            hasError && 'tw-bg-red-20',
          )}
        >
          <ClockCountdown
            size={25}
            className={cn(
              'tw-text-secondary-100',
              focused && 'tw-text-primary',
              hasError && 'tw-text-red',
            )}
          />
        </div>
        <div className='tw-flex tw-flex-1 tw-flex-col'>
          <span className='tw-font-medium'>Wait For</span>
          <span>Select delay before executing the next action</span>
        </div>

        <button
          className='tw-rounded tw-bg-secondary-20 tw-p-2'
          onClick={() => setRemoveModal(true)}
        >
          <TrashSimple size={25} className='tw-text-secondary' />
        </button>
        <button className='tw-rounded tw-bg-primary-20 tw-p-2'>
          {focused ? (
            <CaretUp size={25} className='tw-text-primary' />
          ) : (
            <CaretDown size={25} className='tw-text-primary' />
          )}
        </button>
      </Card>

      {focused ? (
        <ControlledInput
          id='input'
          innerRef={inputRef}
          type='number'
          postFix='Calendar days'
          onKeyDown={(e) => {
            if (e.key === 'Enter') inputRef.current?.blur()
          }}
          name={`steps.${index}.numberOfDays`}
          wrapperClassName='!tw-h-auto tw-flex-1 tw-p-3.5'
          placeholder='Number of days'
        />
      ) : null}
      <RemoveStepModal
        onRemove={() => remove(index)}
        isOpen={removeModal}
        toggle={() => setRemoveModal((prevState) => !prevState)}
      />
    </div>
  )
}
