import React, { createContext, useContext, useMemo } from 'react'
import { useFetch } from '../../../../../helpers/hooks'
import {
  getFilters,
  getFiltersFromCore,
} from '../../../../../services/api-automations'
import { transformFilters } from '../tools'

export const FiltersContext = createContext({
  isLoading: true,
  filters: [],
})

export function FiltersProvider(props) {
  const { children } = props
  const { data = [], isLoading } = useFetch({
    autoFetch: true,
    isOpenApi: true,
    action: getFilters,
  })

  const { data: coreFilters = [], isLoading: loadingCoreFilters } = useFetch({
    autoFetch: true,
    action: getFiltersFromCore,
  })

  const filters = useMemo(
    () => transformFilters([...data, ...coreFilters]),
    [data, coreFilters],
  )

  return (
    <FiltersContext.Provider
      value={{ isLoading: isLoading || loadingCoreFilters, filters }}
    >
      {children}
    </FiltersContext.Provider>
  )
}

export function useFilters() {
  return useContext(FiltersContext)
}
