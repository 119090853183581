import { t } from 'i18next'
import { Coins } from '@phosphor-icons/react'
import cx from 'classnames'
import { format, lastDayOfMonth } from 'date-fns'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card } from 'reactstrap'
import toastr from 'toastr'
import { cn } from 'ui'

import CustomDatePicker from '../../components/Forms/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import StyledTh from '../../components/Table/StyledTh'
import TableComp from '../../components/Table/TableComp'
import Head from '../../components/head'
import Button from '../../components/ui/button'
import Pagination from '../../components/ui/pagination'
import Shimmer from '../../components/ui/shimmer'
import { BE_CONTRACT_CATEGORY } from '../../helpers/enum'
import { useFetch, usePermissions, useResize } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { getAllExpenses, getContractList } from '../../services/api'
import ExpenseModal from '../Contract/components/ExpensesModal'
import TabEmpty from '../Contract/components/tab/tab-empty'
import FteExpensesPage from '../Fulltime/expenses'
import ExpenseCard from './components/expense-card'
import ExpensesHeader from './components/expense-header'
import ExpenseLine from './components/expense-line'
import {
  defaultContract,
  expensesFiltersAreEmpty,
  getExpenseContract,
} from './helpers'
import { PermissionTooltip } from '../../components/permission-tooltip'
import { PERMISSION_GROUP } from '../CompanySetting/manage-role'

function ExpensesPage() {
  const [contracts, setContracts] = useState([
    { ...defaultContract, label: t('All contracts') },
  ])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [page, setPage] = useState(1)
  const [selectedContract, setSelectedContract] = useState(null)
  const [showAddExpense, setShowAddExpense] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const { hasAccess } = usePermissions()

  const contractorType = useSelector(
    (state) => state?.userProfile?.userProfile?.contractor_type,
  )
  const isEmployee = contractorType === BE_CONTRACT_CATEGORY.EMPLOYEE

  const isMobile = useResize()

  const { isLoading: contractsIsLoading } = useFetch({
    action: getContractList,
    body: { status: [4] },
    autoFetch: !isEmployee,
    onError: (error) => {
      toastr.error(error)
    },
    onComplete: (res) => {
      const newContracts = [
        { ...defaultContract, label: t('All contracts') },
        ...res.map((contract) => {
          return { ...contract, value: contract.id, label: contract.ref }
        }),
      ]

      setContracts(newContracts)
      setSelectedContract(contracts[0])
    },
  })

  const filters = {
    page,
    start_date: startDate ? format(new Date(startDate), 'yyyy-MM-dd') : null,
    end_date: endDate ? format(new Date(endDate), 'yyyy-MM-dd') : null,
    contract_id: selectedContract?.value,
  }

  const {
    data: expenses,
    isLoading: expensesIsLoading,
    startFetch: refreshExpenses,
    paginator,
  } = useFetch(
    {
      action: getAllExpenses,
      autoFetch: !isEmployee,
      body: isEmployee ? undefined : filters,
      onComplete: (data) => {
        setIsEmpty(data?.length <= 0 && expensesFiltersAreEmpty(filters))
      },
    },
    [startDate, endDate, selectedContract, page],
  )

  const isLoading = contractsIsLoading || expensesIsLoading
  const showFilters =
    (!isEmpty && !isLoading) || !expensesFiltersAreEmpty(filters)

  const hasAtLeastOneOnGoingContract = contracts.length > 0
  const canAddExpense =
    hasAccess(permissions.addExpense) && hasAtLeastOneOnGoingContract

  const showAddButton = hasAtLeastOneOnGoingContract

  const isAdmin = location.pathname?.startsWith('/admin/')

  if (isEmployee) {
    return <FteExpensesPage />
  }

  return (
    <div className='page-content'>
      <Head title={t('Expenses')} />

      <ExpensesHeader
        canAddExpense={canAddExpense}
        addExpense={() => setShowAddExpense(true)}
        showAddButton={showAddButton}
      />

      <Card>
        {showFilters && (
          <div
            className={cx(
              'd-flex justify-content-between',
              {
                'flex-column': isMobile,
                'align-items-center': !isMobile,
              },
              isMobile ? 'pt-3 pr-3 pb-3 pl-3' : 'pt-4.5 pr-4.5 pb-4 pl-4.5',
            )}
          >
            <h4 className={cn('tw-text-xl', { 'tw-pb-2': isMobile })}>
              {t('Filters')}
            </h4>

            <div className='tw-flex'>
              <CustomSelect
                wrapperStyles={{ minWidth: isMobile ? 100 : 176 }}
                value={selectedContract}
                onChange={(val) => setSelectedContract(val)}
                wrapperClassName={cn('tw-me-2', { 'tw-flex-1': isMobile })}
                options={contracts}
              />

              <CustomDatePicker
                selected={startDate}
                placeholder={t('Select Month')}
                showMonthYearPicker
                dateFormat='MM/yyyy'
                wrapperStyles={{ minWidth: isMobile ? 100 : 176 }}
                wrapperClassName={cn({ 'tw-flex-1': isMobile })}
                showTwoColumnMonthYearPicker
                clearable
                handleClear={() => {
                  setStartDate(null)
                  setEndDate(null)
                }}
                handleOnChange={(date) => {
                  setStartDate(date)
                  setEndDate(lastDayOfMonth(date))
                }}
              />
            </div>
          </div>
        )}
        {isLoading ? (
          <div style={{ minHeight: '30rem' }}>
            <div className='ml-4 mr-4 mt-4 mb-5'>
              <Shimmer className='w-100' />
            </div>
            <div className='mx-4 my-2'>
              <Shimmer className='w-100' />
            </div>
            <div className='mx-4 my-2'>
              <Shimmer className='w-100' />
            </div>
            <div className='mx-4 my-2'>
              <Shimmer className='w-100' />
            </div>
          </div>
        ) : expenses?.length <= 0 ? (
          <TabEmpty
            subtitle={t('Submitted expenses will be shown here')}
            title={t('No Expenses')}
            icon={<Coins size={240} color='var(--primary)' weight='duotone' />}
          >
            {!showAddButton ? null : (
              <PermissionTooltip
                showing={!hasAccess(permissions.addExpense)}
                area={PERMISSION_GROUP.EXPENSES.name}
                id='add-expense-btn-tooltip'
              >
                <Button
                  onClick={() => setShowAddExpense(true)}
                  className='mb-4'
                  title={
                    canAddExpense
                      ? t('Add Expense')
                      : t('Active contract is required')
                  }
                  disabled={!canAddExpense}
                >
                  {t('Add Expense')}
                </Button>
              </PermissionTooltip>
            )}
          </TabEmpty>
        ) : (
          <>
            {isMobile ? (
              <div className='p-3'>
                {expenses?.map((expense) => (
                  <ExpenseCard
                    contract={getExpenseContract(expense, contracts)}
                    key={expense?.id}
                    expense={expense}
                  />
                ))}
              </div>
            ) : (
              <TableComp responsive>
                <thead className='thead-light'>
                  <tr
                    style={{
                      borderTop: 0,
                      borderBottom: '1px solid #E7E8F2',
                    }}
                  >
                    <StyledTh className='pl-4.5'>{t('Contract')}</StyledTh>
                    <StyledTh>{t('Expense')}</StyledTh>
                    <StyledTh>{t('Category')}</StyledTh>
                    <StyledTh>{t('Date')}</StyledTh>
                    <StyledTh>{t('Amount')}</StyledTh>
                    <StyledTh>{t('Status')}</StyledTh>
                    <StyledTh>{t('Actions')}</StyledTh>
                  </tr>
                </thead>
                <tbody>
                  {expenses?.map((expense) => (
                    <ExpenseLine
                      showContractId
                      key={expense.id}
                      item={expense}
                      refreshData={() => refreshExpenses({ page: 1 })}
                      isAdmin={isAdmin}
                      contract={getExpenseContract(expense, contracts)}
                    />
                  ))}
                </tbody>
              </TableComp>
            )}
            <div className='mt-2 mt-md-0 d-flex justify-content-md-end p-4.5'>
              <Pagination
                innerClass='pagination mb-0'
                activePage={page}
                onChange={setPage}
                itemsCountPerPage={paginator?.per_page ?? 50}
                totalItemsCount={paginator?.total ?? 0}
              />
            </div>
          </>
        )}
      </Card>

      {showAddExpense && (
        <ExpenseModal
          contracts={contracts.filter((contract) => contract.value !== null)}
          show={showAddExpense}
          hide={(e) => {
            setShowAddExpense(false)
            if (e === true) {
              refreshExpenses({ page: 1 })
            }
          }}
        />
      )}
    </div>
  )
}

export default ExpensesPage
