import { t } from 'i18next'
import { CaretDown, CaretUp } from '@phosphor-icons/react'
import React from 'react'
import {
  Col,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
} from 'reactstrap'
import { CheckItem } from '../../../components/ui/check-item'

const FiltersDropdown = ({
  isOpen,
  toggle,
  tagsFilters,
  statusFilters,
  filterByStatus,
  filterByTag,
  clearFilters,
  tagQueries,
  statusQueries,
  ...props
}) => {
  return (
    <Dropdown isOpen={isOpen} toggle={toggle} {...props}>
      <DropdownToggle tag='div'>
        <div
          className='tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-rounded tw-border tw-border-surface-40 tw-bg-white tw-px-2'
          style={{ height: 'calc(1.5em + 1.3rem + 2px)' }}
        >
          <span className='tw-text-text-40'>{t('Filters')}</span>

          {isOpen ? (
            <CaretUp className='tw-text-text-40' size={20} />
          ) : (
            <CaretDown className='tw-text-text-40' size={20} />
          )}
        </div>
      </DropdownToggle>
      <DropdownMenu
        className='tw-w-full tw-rounded tw-border tw-border-surface-40 tw-bg-white tw-py-0 md:tw-w-[538px]'
        flip={false}
      >
        <div
          className='d-flex align-items-center justify-content-between w-100 px-3 border-bottom'
          style={{ height: 'calc(1.5em + 1.3rem + 2px)' }}
        >
          <span className='font-size-14 text-gray-h'>{t('Filter by')}</span>
          <button
            className='rp-btn-nostyle text-muted font-size-12'
            onClick={() => clearFilters()}
          >
            {t('Reset All')}
          </button>
        </div>

        <Container fluid>
          <Row className='!tw-text-start'>
            <Col className='py-3 border-right h-100' md={6}>
              <div className='mb-2 text-gray-h font-size-12'>{t('Status')}</div>
              {statusFilters?.map((e, i) => {
                return (
                  <CheckItem
                    key={`drop-${i}`}
                    label={e.label}
                    labelClassName='!tw-text-sm !tw-text-secondary-100'
                    inputClassName='!tw-w-[13px] !tw-h-[13px] !tw-border-secondary-100'
                    className='tw-mb-2 tw-flex tw-items-center'
                    checked={statusQueries.includes(e.value)}
                    onChange={(t) => {
                      toggle()
                      filterByStatus(t.target.checked, e.value)
                    }}
                  />
                )
              })}
            </Col>
            <Col md={6} className='py-3'>
              <div className='mb-2 text-gray-h font-size-12'>{t('Tags')}</div>
              {tagsFilters?.map((e, i) => {
                return (
                  <CheckItem
                    key={`drop-${i}`}
                    label={e.name}
                    labelClassName='!tw-text-sm !tw-text-secondary-100'
                    inputClassName='!tw-w-[13px] !tw-h-[13px] !tw-border-secondary-100'
                    className='tw-mb-2 tw-flex tw-items-center'
                    checked={tagQueries.includes(e.id)}
                    onChange={(t) => {
                      toggle()
                      filterByTag(t.target.checked, e.id)
                    }}
                  />
                )
              })}
            </Col>
          </Row>
        </Container>
      </DropdownMenu>
    </Dropdown>
  )
}

export default FiltersDropdown
