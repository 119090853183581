import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Detector from 'i18next-browser-languagedetector'
import { translationEn } from './locales/en/index.js'
import { translationAr } from './locales/ar/index.js'

import FEATURE_FLAGS from './config/feature-flags'

const resources = {
  ar: { translation: translationAr },
  en: { translation: translationEn },
}

let _i18n = i18n.use(initReactI18next)

if (FEATURE_FLAGS.LOCALIZATION) {
  _i18n = _i18n.use(Detector)
}

_i18n
  // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en', // use en if detected lng is not available
    // keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default _i18n
