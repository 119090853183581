export const REMOVABLE_STORAGE_KEY = 'REMOVABLE'

const BACKWARD_CAPABILITIES_KEYS = ['SHOW_AUTOMATION_ONBOARDING']

export function getRemovableStorageKey(key) {
  return `${REMOVABLE_STORAGE_KEY}_${key}`
}

export function deleteRemovableStorage() {
  try {
    Object.keys(localStorage)
      .filter(
        (key) =>
          key.split('_')?.[0] === REMOVABLE_STORAGE_KEY ||
          BACKWARD_CAPABILITIES_KEYS.includes(key),
      )
      .forEach((x) => localStorage.removeItem(x))
  } catch (e) {}
}
