import { t } from 'i18next'
import React from 'react'

import Button from '../../../components/ui/button'
import PageHeading from '../../../components/ui/page-heading'

export default function ExpensesHeader({
  addExpense,
  showAddButton,
  canAddExpense,
}) {
  return (
    <PageHeading className='align-items-center'>
      <PageHeading.Title>{t('Expenses')}</PageHeading.Title>

      {showAddButton && (
        <Button
          onClick={addExpense}
          title={
            canAddExpense ? t('Add Expense') : t('Active contract is required')
          }
          disabled={!canAddExpense}
        >
          {t('Add Expense')}
        </Button>
      )}
    </PageHeading>
  )
}
