import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Alert as BsAlert, Card } from 'reactstrap'
import toastr from 'toastr'

import ControlledInput from '../../components/ControlledInput'
import Alert from '../../components/ui/alert'
import Button from '../../components/ui/button'
import { HeaderAnonym } from '../../components/ui/header-anonym'
import Footer from '../../components/VerticalLayout/Footer'
import { useFetch } from '../../helpers/hooks'
import { resetPassword } from '../../services/api'
import LabelContent from '../Contract/CreateContract/components/label-content'

function ForgetPasswordPage(props) {
  const [sent, setSent] = useState(false)
  const { handleSubmit, control } = useForm()

  const { startFetch: resetPass, isLoading: isResettingPassword } = useFetch({
    action: resetPassword,
    onComplete: (_, body) => {
      setSent(true)
      window.analytics.track('Forgot password', { email_id: body?.email })
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  function onSubmit(values) {
    resetPass(values)
  }

  return (
    <>
      <HeaderAnonym />

      <div className='tw-mb-4 tw-mt-6 tw-max-w-full tw-px-4 tw-text-center md:tw-mb-6 md:tw-mt-12 md:tw-px-6'>
        <h1 className='tw-mb-3 tw-text-center tw-text-xl tw-text-secondary-120 sm:tw-text-3xl md:tw-mb-4'>
          Can’t remember your password?
        </h1>
        <h6 className='tw-mb-0 tw-font-light tw-text-text-60'>
          Not a problem! Just enter your email address and we’ll send you a link
          to reset it.
        </h6>
      </div>

      <div className='tw-px-6'>
        <Card className='rp-shadow-2 tw-mx-auto !tw-mb-0 tw-max-w-md !tw-p-4 md:!tw-p-6'>
          {props.forgetError && props.forgetError ? (
            <BsAlert color='danger' className='tw-mt-3'>
              {props.forgetError}
            </BsAlert>
          ) : null}
          {props.forgetSuccessMsg ? (
            <BsAlert color='success' className='tw-mt-3'>
              {props.forgetSuccessMsg}
            </BsAlert>
          ) : null}

          <form onSubmit={handleSubmit(onSubmit)}>
            <ControlledInput
              control={control}
              name='email'
              label={<LabelContent required>Email</LabelContent>}
              placeholder='Enter email'
              type='email'
              required
            />

            {!sent ? null : (
              <Alert color='info' className='!tw-mb-0 tw-mt-4'>
                If your email address exists in our database, you’ll receive an
                email with instructions to reset your password.
              </Alert>
            )}

            <Button
              block
              type='submit'
              loading={isResettingPassword}
              disabled={isResettingPassword}
              className='tw-mt-4'
            >
              {sent ? 'Resend' : 'Send reset link'}
            </Button>
          </form>

          <Link to='/login' className='tw-mt-4 tw-text-sm tw-font-medium'>
            Go back to the login page
          </Link>
        </Card>
      </div>

      <Footer className='tw-mb-4 tw-mt-12 tw-text-center' />
    </>
  )
}

export default ForgetPasswordPage
