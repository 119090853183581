import React, { useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { cn } from 'ui'
import { fieldTypes } from '.'
import { uploadTempFile, uploadTempFileAdmin } from '../../../services/api'
import { useFetch } from '../../../helpers/hooks'
import toastr from 'toastr'
import Button from '../../ui/button'
import {
  CheckCircle,
  DownloadSimple,
  FilePlus,
  Spinner,
  X,
  XCircle,
} from '@phosphor-icons/react'
import openFileV2 from '../../../utils/file/open-v2'
import IconButton from '../../ui/icon-button'
import {
  MAX_SIZE,
  MAX_SIZE_READABLE,
} from '../../../pages/Contract/utils/constants'

function InputPlaceholder({ title }) {
  return (
    <div className='tw-flex tw-flex-col tw-items-center tw-gap-2'>
      <div className='tw-flex tw-items-center tw-gap-2 tw-font-bold tw-text-primary'>
        {title} <FilePlus size={20} />
      </div>

      <div className='tw-text-secondary-80'>
        .png, .jpg, .jpeg, .gif or .pdf only. Max file size {MAX_SIZE_READABLE}
      </div>
    </div>
  )
}

function DynamicFileUpload({
  field,
  setValue,
  error,
  readOnly,
  filesDownloadable,
  canDeleteFiles,
  clearErrors,
  isPreviewOnly,
  watch,
}) {
  const isAdmin = location.pathname.startsWith('/admin')
  const inputs =
    (
      field.form_input?.value?.split(',') ||
      watch(`form_field_${field.id}`)?.split(',')
    )?.filter(Boolean) ?? []
  const downloadLinks = field.form_input?.download_link?.split(',')
  const [filesToUpload, setFilesToUpload] = useState([])
  const [files, setFiles] = useState(
    inputs?.map((input, index) => {
      const id = Math.random() * 1000000
      return {
        path: input,
        name: `${field.title} Uploaded File no.${index + 1}`,
        download_link: downloadLinks?.[index],
        id,
      }
    }) ?? [],
  )

  const isMulti = field.type === fieldTypes.multi_file_upload
  const { getInputProps, getRootProps } = useDropzone({
    multiple: isMulti,
    disabled: readOnly,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif'],
      'application/pdf': ['.pdf'],
    },
    onDropAccepted: handleFileDropped,
    maxSize: MAX_SIZE,
    onDropRejected: handleFileRejected,
  })

  const { startFetch: uploadFile } = useFetch(
    {
      action: isAdmin ? uploadTempFileAdmin : uploadTempFile,
      withAdminAccess: isAdmin,
      onComplete: (data, body) => {
        setFilesToUpload((prevFiles) => prevFiles.slice(1))
        setFiles((prevFiles) => [
          ...prevFiles.map((file) =>
            file.id === body.id ? { ...file, path: data.path } : file,
          ),
        ])
      },
      onError: (error, _, body) => {
        setFilesToUpload((prevFiles) => prevFiles.slice(1))
        setFiles((prevFiles) => [
          ...prevFiles.map((file) =>
            file.id === body.id ? { ...file, error } : file,
          ),
        ])
        toastr.error(error)
      },
    },
    [],
  )

  const templates = useMemo(
    () =>
      field.placeholder_download_link
        ? field.placeholder_download_link.split(',')
        : [],
    [field],
  )

  const hasError = useMemo(() => files.some((file) => file.error), [files])

  const allUploadedSuccessfully = useMemo(
    () => files.length > 0 && files.every((file) => file.path),
    [files],
  )

  useEffect(() => {
    if (filesToUpload.length) {
      uploadFile({
        file: filesToUpload[0].file,
        type: 'form_uploads',
        id: filesToUpload[0].id,
      })
    }
  }, [filesToUpload])

  useEffect(() => {
    const uploadedFiles = files.map((file) => file.path).filter(Boolean)
    if (!hasError) {
      setValue(
        `form_field_${field.id}`,
        uploadedFiles.length ? uploadedFiles.join() : '',
      )
    } else {
      setValue(`form_field_${field.id}`, '')
    }
  }, [files])

  function handleFileDropped(acceptedFiles) {
    clearErrors?.()
    if (isPreviewOnly) {
      const newFiles = acceptedFiles.map((file) => ({
        file,
        name: file.name,
        path: file.name,
        id: Math.random() * 1000000,
      }))
      setFiles((prevFiles) => [...prevFiles, ...newFiles])
      return
    }
    const newFiles = acceptedFiles.map((file) => ({
      file,
      name: file.name,
      id: Math.random() * 1000000,
    }))
    if (isMulti) {
      setFilesToUpload((prevFilesToUpload) => [
        ...prevFilesToUpload,
        ...newFiles,
      ])
      setFiles((prevFiles) => [...prevFiles, ...newFiles])
    } else {
      setFilesToUpload(newFiles)
      setFiles(newFiles)
    }
  }

  function handleFileRejected(fileRejections) {
    const errMsgs = fileRejections
      .map(({ file, errors }) => {
        const errorMsg = errors
          .map((e) =>
            e.code === 'file-too-large'
              ? `File larger than ${MAX_SIZE_READABLE}`
              : e.message,
          )
          .join(', ')
        return `File "${file.name}" was rejected: ${errorMsg}`
      })
      .join('<br/><br/>')
    if (errMsgs) {
      toastr.error(errMsgs)
    }
  }

  function handleFileDelete(e, id) {
    e.stopPropagation()
    setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id))
  }

  function handleDownloadTemplate(template) {
    openFileV2(template, {
      name: field.title,
      isDataUrl: true,
    })
  }

  function handleFileDownload(e, downloadLink) {
    openFileV2(downloadLink, {
      isDataUrl: true,
    })
  }

  return (
    <div className=''>
      <div className='tw-my-2 tw-text-sm tw-font-bold'>
        {field.title}{' '}
        {field.is_required ? (
          <span className='tw-ml-1 tw-text-systemRed'>*</span>
        ) : null}
      </div>
      <div className='tw-flex tw-items-start tw-gap-4'>
        {templates.length ? (
          <div className='tw-flex-1 tw-rounded-lg tw-border tw-border-secondary-30 tw-p-4'>
            {templates.map((template, index) => (
              <div
                className='tw-mb-2 tw-flex tw-items-center tw-justify-between'
                key={template}
              >
                <div className='tw-text-sm tw-font-bold'>
                  {field.title} Template {index + 1}
                </div>
                <Button
                  textClassName='tw-text-primary tw-flex tw-items-center tw-gap-1 tw-text-sm tw-font-bold'
                  onClick={() => handleDownloadTemplate(template)}
                  size='sm'
                  color='transparent'
                >
                  <DownloadSimple size={20} /> Download Template
                </Button>
              </div>
            ))}
            <div className='tw-mt-4 tw-text-secondary-80'>
              Download the form here and make sure you fill all the necessary
              information before uploading it
            </div>
          </div>
        ) : null}
        <div className='tw-flex-1'>
          <div
            className={cn(
              'tw-flex tw-flex-col tw-gap-3 tw-rounded-lg tw-border tw-border-dashed tw-p-4',
              hasError
                ? 'tw-border-systemRed tw-bg-systemRed-10'
                : allUploadedSuccessfully && !readOnly
                  ? 'tw-border-systemGreen tw-bg-systemGreen-10'
                  : 'tw-border-primary tw-bg-primary-20',
              { 'tw-cursor-pointer': !readOnly },
            )}
            {...getRootProps()}
          >
            {!files.length ? (
              <InputPlaceholder title='Drop files here or click to upload' />
            ) : (
              <>
                {files.map((file) => (
                  <div
                    className='tw-flex tw-items-start tw-justify-between'
                    key={file.id}
                  >
                    <div>
                      <div className='tw-mt-1 tw-text-sm tw-font-bold'>
                        {file.name}
                      </div>
                      {!readOnly && (
                        <div>
                          {file.path ? (
                            <div className='tw-flex tw-items-center tw-gap-1 tw-text-systemGreen'>
                              <CheckCircle size={16} />
                              Uploaded successfully
                            </div>
                          ) : file.error ? (
                            <div className='tw-flex tw-items-center tw-gap-1 tw-text-systemRed'>
                              <XCircle size={16} />
                              Error Uploading
                            </div>
                          ) : (
                            <div className='tw-flex tw-items-center tw-gap-1 tw-text-primary'>
                              <Spinner
                                size={20}
                                className='tw-animate-spin tw-transition-transform tw-duration-300'
                              />
                              Uploading
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div>
                      <div className='tw-flex tw-items-center tw-gap-2'>
                        {(!readOnly || canDeleteFiles) && (
                          <IconButton
                            onClick={(e) => handleFileDelete(e, file.id)}
                            circle
                            color='transparent'
                            icon={<X size={20} />}
                          />
                        )}
                        {filesDownloadable && file.download_link && (
                          <IconButton
                            onClick={(e) =>
                              handleFileDownload(e, file.download_link)
                            }
                            circle
                            color='transparent'
                            icon={<DownloadSimple size={20} />}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {isMulti && !readOnly && (
                  <InputPlaceholder title='Click to upload more files' />
                )}
              </>
            )}
            <input {...getInputProps()} />
          </div>
          {error ? (
            <div className='tw-mt-1 tw-flex tw-items-center tw-gap-1 tw-text-xs tw-text-systemRed'>
              <XCircle size={16} /> {error}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default DynamicFileUpload
