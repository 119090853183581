import { yupResolver } from '@hookform/resolvers/yup'
import { Warning } from '@phosphor-icons/react'
import cx from 'classnames'
import { addMinutes, format, isEqual, isFuture } from 'date-fns'
import React, { useEffect, useState } from 'react'
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  Card,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  TabPane,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { ReactComponent as CardPinSuccess } from '../../../assets/images/card-pin-success.svg'
import physicalCardBackImage from '../../../assets/images/physical-card--back.png'
import ControlledInput from '../../../components/ControlledInput'
import ModalHeader from '../../../components/ModalHeader'
import Steps from '../../../components/Steps'
import StepContainer from '../../../components/Steps/StepContainer'
import { ControlledVerificationCode } from '../../../components/controlled-verification-code'
import Alert from '../../../components/ui/alert'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import { useFetch } from '../../../helpers/hooks'
import {
  activatePhysicalCard,
  cardActivity,
  resendCardActivationCode,
  userIssuePhysicalCard,
} from '../../../services/api'
import useTriggerField from '../../Contract/CreateContract/utils/use-trigger-field'
import { isPhysicalCardPending } from '../organisms/physical-card-states'
import { CARD_STATUSES, CARD_TYPE_ENUM } from '../utils/card-types-status'
import { showInputErrors } from './cards-kyc'
import './pin_code.css'
import './activate-physical-card.css'
import TabContent from '../../../components/ui/tabs'

export function formatCreditCardNumber(value) {
  if (!value) return value

  return value
    .toString()
    .replace(/\s+/g, '')
    .replace(/[^0-9]/gi, '')
    .replace(/(.{4})/g, '$1 ')
    .trim()
}

export function creditCardNumberToString(value) {
  if (!value) return value

  return value
    .toString()
    .replace(/\s+/g, '')
    .replace(/[^0-9]/gi, '')
}

function CreditCardImage({ cardWidth = 224, boxStyles, className, style }) {
  return (
    <div
      className={cx('align-items-center d-flex position-relative', className)}
      style={{ alignSelf: 'center', ...style }}
    >
      <img
        src={physicalCardBackImage}
        alt='card back'
        style={{ width: cardWidth }}
      />

      {!boxStyles ? null : (
        <div
          className='position-absolute border-danger rounded'
          style={{
            backgroundColor: 'rgb(246,79,89,0.3)',
            border: '2px solid',
            ...boxStyles,
          }}
        />
      )}
    </div>
  )
}

function AssocNumber({ control, errors }) {
  return (
    <>
      <div className='d-flex gap-16 mb-3'>
        <div className='flex-grow-1'>
          <ControlledInput
            control={control}
            label='ASSOC number'
            name='assoc_number'
            id='assoc_number'
            placeholder='XXXXXXXXXXXX'
            type='number'
            error={errors?.assoc_number}
            wrapperClassName='mb-3'
          />

          <ControlledInput
            control={control}
            label='Confirm ASSOC number'
            name='confirm_assoc_number'
            id='confirm_assoc_number'
            placeholder='XXXXXXXXXXXX'
            type='number'
            error={errors?.confirm_assoc_number}
          />
        </div>

        <div role='none' className='border-left' />

        <CreditCardImage
          boxStyles={{
            bottom: 22,
            right: 4,
            width: '3.5rem',
            height: '1.5rem',
          }}
        />
      </div>

      <Alert
        color='warning'
        customIcon={<Warning />}
        className='p-3 bg-gold-20 border border-surface-30'
      >
        Please make sure the ASSOC code is correct as this action is
        irreversible.
      </Alert>
    </>
  )
}

function ActivationCode({ control, errors, onResendCode }) {
  const { setError } = useFormContext()

  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  return (
    <>
      <small
        className='font-size-14 d-block mb-4 text-text-40 mx-auto text-balance'
        style={{ width: '100%', maxWidth: '430px' }}
      >
        We’ve sent a validation code to{' '}
        <strong className='text-text-80'>{userProfile?.email}</strong> Enter the
        code soon, as it will expire shortly.
      </small>
      <FormGroup
        className={cx('pin-code mb-3', {
          'is-invalid': errors?.activation_code,
        })}
      >
        <Label className='font-size-16'>Your validation code</Label>
        <div className='align-items-center d-flex flex-column mb-3'>
          <ControlledVerificationCode
            control={control}
            name='activation_code'
            id='activation_code'
            length={6}
            error={errors?.activation_code}
          />
        </div>
        <small className='d-block font-size-14 text-text-60'>
          Haven’t received our email? Try spam folder or{' '}
          <ResendCode resend={onResendCode} />
        </small>
      </FormGroup>

      <hr className='w-100 my-4' style={{ maxWidth: 460 }} />

      <div
        className='d-flex flex-wrap gap-8 mx-auto w-100 justify-content-between cc-input'
        style={{ maxWidth: 460 }}
      >
        <ControlledInput
          control={control}
          labelClassName='font-size-14'
          wrapperClassName='text-left flex-grow-1'
          className='rp-font-medium tabular-nums'
          label='Card number'
          name='card_number'
          id='card_number'
          placeholder='XXXX XXXX XXXX XXXX'
          error={errors?.card_number}
          transform={{
            input: (value) => {
              const formattedValue = formatCreditCardNumber(value.slice(0, 16))

              return formattedValue
            },
            output: (e) => {
              const output = e.target.value
              const stringValue = creditCardNumberToString(output)

              if (stringValue.length > 16) {
                const error = 'Card number should be 16 digits'
                setError('card_number', { message: error })
              } else {
                setError('card_number', { message: '' })
              }

              return stringValue.slice(0, 16)
            },
          }}
        />

        <CreditCardImage
          style={{ alignSelf: 'flex-end' }}
          cardWidth={94}
          boxStyles={{
            bottom: 8,
            left: 3,
            width: '3.65rem',
            height: '0.75rem',
          }}
        />
      </div>
    </>
  )
}

const confirmPinName = 'confirm_pin_code'
function Pin({ control, errors }) {
  const pinConfirmation = useWatch({ name: confirmPinName, control })
  const { setError } = useFormContext()

  return (
    <>
      <div className='font-size-24 mb-4'>Create your 4-digit PIN</div>

      <FormGroup
        className={cx('pin-code mb-4', { 'is-invalid': errors?.pin_code })}
      >
        <div className='align-items-center d-flex flex-column'>
          <ControlledVerificationCode
            control={control}
            labelClassName='font-size-16 mb-3 mt-4 rp-font-medium text-muted'
            label='The PIN is often required for offline purchases'
            transform={{
              output: (newValue) => {
                if (pinConfirmation && newValue === pinConfirmation) {
                  setError(confirmPinName, { message: '' })
                }
                return newValue
              },
            }}
            name='pin_code'
            id='pin_code'
            length={4}
            error={errors?.pin_code}
          />
        </div>
      </FormGroup>

      <FormGroup className='pin-code'>
        <div className='align-items-center d-flex flex-column'>
          <ControlledVerificationCode
            control={control}
            labelClassName='font-size-16 mb-3 mt-4 rp-font-medium text-muted'
            label='Confirm your 4-digit PIN'
            name={confirmPinName}
            id={confirmPinName}
            length={4}
            error={errors?.[confirmPinName]}
          />
        </div>
      </FormGroup>
    </>
  )
}

const stepsDetails = {
  assocNumber: {
    stepIndex: 0,
    label: 'Validate',
    title: 'Enter your ASSOC number',
    Step: AssocNumber,
  },
  activationCode: {
    stepIndex: 1,
    label: 'Active',
    title: 'Let’s activate your physical card',
    Step: ActivationCode,
  },
  pin: {
    stepIndex: 2,
    label: 'Set a PIN',
    title: 'Let’s secure your card',
    Step: Pin,
  },
}

const assocNumberStep = {
  id: stepsDetails.assocNumber.stepIndex,
  label: stepsDetails.assocNumber.label,
  title: stepsDetails.assocNumber.title,
  Step: stepsDetails.assocNumber.Step,
  alignDefault: true,
  minHeight: '15rem',
  schema: yup.object().shape({
    assoc_number: yup
      .string()
      .matches(/^\d+$/, 'ASSOC number must be numeric')
      .required('ASSOC number is required'),
    confirm_assoc_number: yup
      .string()
      .oneOf(
        [yup.ref('assoc_number'), null],
        'Confirmation ASSOC number must match',
      )
      .required('Confirm ASSOC number is required'),
  }),
}

const steps = [
  {
    id: stepsDetails.activationCode.stepIndex,
    label: stepsDetails.activationCode.label,
    title: stepsDetails.activationCode.title,
    Step: stepsDetails.activationCode.Step,
    schema: yup.object().shape({
      activation_code: getSchema(6, 'Activation Code'),
      card_number: getSchema(16, 'Card Number'),
    }),
  },
  {
    id: stepsDetails.pin.stepIndex,
    label: stepsDetails.pin.label,
    title: stepsDetails.pin.title,
    Step: stepsDetails.pin.Step,
    schema: yup.object().shape({
      pin_code: getSchema(4, 'PIN Code'),
      [confirmPinName]: yup
        .string()
        .oneOf([yup.ref('pin_code'), null], 'Confirmation PIN Code must match')
        .required('Confirm PIN Code is required'),
    }),
  },
]

function getPhysicalCard(cards) {
  if (!cards || !Array.isArray(cards)) return {}

  return cards.find(
    (card) =>
      card.card_type === CARD_TYPE_ENUM.PHYSICAL &&
      card.status.text === CARD_STATUSES.PENDING_ACTIVATION,
  )
}

function getStepIndex(steps, activeStep) {
  return steps?.indexOf(steps.find((s) => s.id === activeStep))
}

export default function ActivatePhysicalCard() {
  const history = useHistory()

  const [theSteps, setTheSteps] = useState(steps)
  const [activeStep, setActiveStep] = useState(theSteps[0].id)

  const { startFetch: resendCode, isLoading: sendingCode } = useFetch({
    action: resendCardActivationCode,
    onError: (resp) => {
      toastr.error(resp?.error?.messages?.join(', ') || 'Something went wrong')
    },
  })

  function resendCodeHandler(physicalCard) {
    resendCode({ card_id: physicalCard.id })
  }

  const {
    data: cards,
    isLoading: cardsLoading,
    startFetch: refreshCardOrder,
  } = useFetch({
    action: cardActivity,
    autoFetch: true,
    onComplete: (data) => {
      const physicalCardPending = isPhysicalCardPending({
        status: data?.card_order?.status,
        type: data?.card_order?.card_type,
      })

      const shouldAddAssocNumberStep =
        data?.card_order?.shipping_details?.shipping_status === 'shipped' &&
        data?.card_order?.status === 'pending'

      if (shouldAddAssocNumberStep) {
        const newSteps = [assocNumberStep, ...steps]
        setTheSteps(newSteps)
        setActiveStep(assocNumberStep.id)
      }

      if (!physicalCardPending) {
        history.push('/cards')
      }

      const physicalCard = getPhysicalCard(data.cards)
      resendCodeHandler(physicalCard)
    },
  })

  const stepLoading = cardsLoading || sendingCode

  return (
    <Container fluid className='!tw-px-0'>
      <ModalHeader action={() => history.push('/cards')}>
        {stepLoading || theSteps?.length <= 1 ? null : (
          <Steps
            className='d-none d-md-flex'
            activeTab={getStepIndex(theSteps, activeStep)}
            data={theSteps.map((step) => step.label)}
            noLastAction
          />
        )}
      </ModalHeader>

      <div className='d-flex flex-column' style={{ marginBottom: '9rem' }}>
        <TabContent activeTab={activeStep}>
          {stepLoading ? (
            <Card style={{ maxWidth: 820, margin: '80px auto 0px auto' }}>
              <Loader minHeight='30rem' />
            </Card>
          ) : (
            <ActivationForm
              card={getPhysicalCard(cards?.cards)}
              cardOrder={cards?.card_order}
              resendCode={resendCodeHandler}
              theSteps={theSteps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              refreshCardOrder={refreshCardOrder}
            />
          )}
        </TabContent>
      </div>
    </Container>
  )
}

export function getSchema(length, label) {
  return yup
    .string()
    .length(length, `${label} must be ${length} characters`)
    .matches(/^\d+$/, `${label} must be numeric`)
    .required(`${label} is required`)
}

function ActivationForm({
  card,
  cardOrder,
  resendCode,
  theSteps,
  activeStep,
  setActiveStep,
  refreshCardOrder,
}) {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

  const schema = steps[0].schema.concat(steps[1].schema)

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: { card_number: '' },
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    watch,
    trigger,
  } = formMethods

  useTriggerField({ watch, trigger })

  const { startFetch: issuePhysicalCard, isLoading: issuingPhysicalCard } =
    useFetch({
      action: userIssuePhysicalCard,
      onComplete: () => {
        toastr.clear()
        setActiveStep((step) => step + 1)
        refreshCardOrder?.()
      },
      onError: (error) => {
        toastr.error(error || 'Something went wrong')
      },
    })

  const { startFetch: activateCard, isLoading } = useFetch({
    action: activatePhysicalCard,
    onComplete: () => {
      toastr.clear()
      setIsSuccessModalOpen(true)
    },
    onError: (resp) => {
      toastr.error(resp?.error?.messages.join(', ') || 'Something went wrong')
    },
  })

  const nextIsLoadingAndDisabled = isLoading || issuingPhysicalCard

  function onSubmit(data) {
    const body = { ...data, card_id: card?.id }
    delete body?.[confirmPinName]

    activateCard(body)
  }

  function onError(error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }

  const submitStep = handleSubmit(onSubmit, onError)

  function handleNext() {
    if (activeStep === stepsDetails.pin.stepIndex) {
      submitStep()
    } else {
      toastr.clear()

      const values = getValues()

      const index = getStepIndex(theSteps, activeStep)
      theSteps[index].schema
        .validate(values, { abortEarly: false })
        .then(() => {
          setError('card_number', { message: '' })
          if (activeStep === assocNumberStep.id) {
            const assocNumber = values?.assoc_number
            const cardOrderId = cardOrder?.id

            const body = {
              assoc_number: assocNumber,
              card_order_id: cardOrderId,
            }

            issuePhysicalCard(body)
          } else {
            setActiveStep((step) => step + 1)
          }
        })
        .catch(({ inner }) => showInputErrors(inner, setError))
    }
  }

  function handleBack() {
    setActiveStep((step) => step - 1)
  }

  function onResendCode() {
    resendCode(card)
  }

  return (
    <>
      {theSteps.map((step) => {
        return (
          <TabPane tabId={step.id} key={step.id}>
            <StepContainer
              total={theSteps.length}
              index={step.id}
              title={step.title}
              loading={nextIsLoadingAndDisabled}
              disableNext={nextIsLoadingAndDisabled}
              nextText={
                theSteps[theSteps.length - 1].id === step.id
                  ? 'Continue'
                  : 'Next'
              }
              onNext={handleNext}
              onBack={handleBack}
            >
              <div
                className={step.alignDefault ? null : 'text-center'}
                style={{ minHeight: step?.minHeight ?? '25rem' }}
              >
                <FormProvider {...formMethods}>
                  <step.Step
                    onResendCode={onResendCode}
                    control={control}
                    errors={errors}
                    cardOrder={cardOrder}
                  />
                </FormProvider>
              </div>
            </StepContainer>
          </TabPane>
        )
      })}

      <PinSetSuccessModal isOpen={isSuccessModalOpen} isActivation />
    </>
  )
}

function formatTime(date) {
  return format(new Date(date), 'm:ss')
}

function diffFromNow(date) {
  return new Date(date) - new Date()
}

function ResendCode({ resend }) {
  const [isResend, setIsResend] = useState(true)
  const [future, setFuture] = useState(() => {
    const defaultFuture = addMinutes(new Date(), 1)
    const formatted = formatTime(diffFromNow(defaultFuture))

    return { date: defaultFuture, formatted }
  })

  useEffect(() => {
    const second = setInterval(() => {
      const now = new Date().getTime()
      if (!isFuture(future.date) || isEqual(future.date, now)) {
        clearInterval(second)
        setIsResend(false)
      } else {
        const formatted = formatTime(diffFromNow(future.date))

        setFuture((prev) => ({ ...prev, formatted }))
      }
    }, 900)

    return () => {
      clearInterval(second)
    }
  }, [future])

  if (isResend) {
    return <span>Resend in {future.formatted}</span>
  }

  return (
    <button className='rp-btn-nostyle text-primary p-0' onClick={resend}>
      Resend it
    </button>
  )
}

export function PinSetSuccessModal({ isOpen, isActivation }) {
  const history = useHistory()

  return (
    <Modal isOpen={isOpen ?? true} centered>
      <ModalBody className='text-center py-5'>
        <CardPinSuccess className='mb-4' />
        {isActivation ? (
          <>
            <h2>Your card is ready!</h2>
            <p className='mb-0 text-muted'>
              Your card is secure and ready to spend.
            </p>
          </>
        ) : (
          <h2>You have successfully changed your PIN.</h2>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            history.push('/cards')
            window.scroll(0, 0)
          }}
        >
          Done
        </Button>
      </ModalFooter>
    </Modal>
  )
}
