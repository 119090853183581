import { yupResolver } from '@hookform/resolvers/yup'
import { FileCloud, TrashSimple } from '@phosphor-icons/react'
import React, { forwardRef, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledDropzoneInput from '../../../../components/controlled-dropzone-input'
import ControlledInput from '../../../../components/ControlledInput'
import Button from '../../../../components/ui/button'
import { useFetch } from '../../../../helpers/hooks'
import { uploadWork } from '../../../../services/api'
import { MAX_SIZE_READABLE } from '../../utils/constants'

const AnnexForm = forwardRef(function AnnexForm(
  { onSubmit, onUploadStatusChanged },
  ref,
) {
  const [path, setPath] = useState(null)

  const upload = useFetch({
    action: uploadWork,
    onComplete: (data) => {
      setPath(data?.path)
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  useEffect(() => {
    onUploadStatusChanged(upload.isLoading)
  }, [onUploadStatusChanged, upload.isLoading])

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: { name: '', file: '' },
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required().label('Name'),
        file: yup.mixed().required().label('File'),
      }),
    ),
  })

  const watchedFiles = watch('file')
  const [uploadedFile] = watchedFiles ?? []

  return (
    <form
      ref={ref}
      onSubmit={handleSubmit((data) => {
        onSubmit(data.name, path)
      })}
    >
      <ControlledInput control={control} name='name' label='Name' />

      <ControlledDropzoneInput
        control={control}
        name='file'
        accept={{ 'application/pdf': ['.pdf'] }}
        wrapperClassName='tw-mt-4'
        onDropRejectedFired={() => {
          setValue('file', null)
        }}
        onDropAccepted={(acceptedFiles) => {
          if (acceptedFiles?.length <= 0) {
            return
          }

          const [firstFile] = acceptedFiles

          upload.startFetch({ file: firstFile, type: 'annex' })
        }}
      >
        <div className='tw-flex tw-min-h-[173px] tw-flex-col tw-justify-center tw-px-6 tw-py-10'>
          {!watchedFiles || watchedFiles?.length <= 0 ? (
            <div className='tw-text-center'>
              <FileCloud
                size={40}
                weight='duotone'
                className='tw-inline-block tw-text-primary-100'
              />
              <div>
                <h4 className='md:tw-hidden'>Click to upload files.</h4>
                <h3 className='tw-hidden md:tw-block'>
                  Drop files here or click to upload.
                </h3>

                <p className='tw-mb-0 tw-text-xs tw-text-disabled'>
                  *PDF only. Max file size {MAX_SIZE_READABLE}
                </p>
              </div>
            </div>
          ) : (
            <div className='tw-flex tw-items-center tw-justify-between tw-gap-2'>
              <div>
                File uploaded
                {uploadedFile.name ? (
                  <>
                    : <strong>{uploadedFile.name}</strong>
                  </>
                ) : (
                  ''
                )}
              </div>

              <Button
                loading={upload.isLoading}
                disabled={upload.isLoading}
                onClick={() => {
                  setValue('file', null)
                }}
                size='sm'
                icon={<TrashSimple size={16} />}
                outline
                color='danger'
                className='!tw-border-transparent !tw-p-1.5'
              />
            </div>
          )}
        </div>
      </ControlledDropzoneInput>
    </form>
  )
})

export default AnnexForm
