import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col } from 'reactstrap'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import { updateOrganization } from '../../../../store/xero/actions'
import { StyledH5 } from '../../../../components/Typo'
import { updateZohoOrganization } from '../../../../store/zoho/actions'

const OrganizationSelect = ({ platform = 'Xero' }) => {
  const dispatch = useDispatch()
  const organizations = useSelector((state) => {
    switch (platform) {
      case 'Xero':
        return state.xero?.organizations || []
      case 'Zoho':
        return state.zoho?.organizations || []
    }
  })
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )

  const onOrganizationChange = (item) => {
    switch (platform) {
      case 'Xero':
        dispatch(updateOrganization(item.value))
        break
      case 'Zoho':
        dispatch(updateZohoOrganization(item.value))
        break
    }
  }
  return (
    <Col className='py-3'>
      <div className='mb-3'>
        <StyledH5 className='font-size-16 rp-font-bold mb-3'>
          Select the {platform} organization to connect with {company?.name}
        </StyledH5>
      </div>
      {/* <Row className="align-items-center w-100 p-0 m-0"> */}
      {/*  <p className="text-muted">RemotePass categories on the left will be posted to the Xero accounts on the right</p> */}
      {/* </Row> */}
      <CustomSelect
        options={organizations.map((e) => ({ label: e.name, value: e.id }))}
        onChange={onOrganizationChange}
      />
    </Col>
  )
}

OrganizationSelect.propTypes = {}

export default OrganizationSelect
