import _i18n from '../../i18n'
import isNill from '../is-nill'

export function getFloat(value) {
  if (typeof value === 'string' || typeof value === 'number') {
    value = parseFloat(Number(value)?.toFixed(2))
  }

  return value
}

export function formatDays(days, t = null) {
  if (isNill(days)) {
    return null
  }
  const _days = getFloat(days)

  const isAr = _i18n.language === 'ar'

  if (t) {
    return t('days', { count: _days })
  }

  if (isAr) {
    switch (Number(_days)) {
      case 0: {
        return 'صفر يوم'
      }
      case 1: {
        return 'يوم واحد'
      }
      case 2: {
        return 'يومان'
      }
    }
    if (Number(_days) <= 10) {
      return `${_days} أيام`
    }
    return `${_days} يوم`
  }

  return `${_days} day${Number(_days) === 1 ? '' : 's'}`
}

export function formatHours(hours) {
  if (isNill(hours)) {
    return null
  }

  const _hours = getFloat(hours)

  return `${_hours} hour${Number(_hours) === 1 ? '' : 's'}`
}
