import React from 'react'
import { Card } from 'reactstrap'
import { Funnel, Info } from '@phosphor-icons/react'
import { AddFilterButtons } from './add-filter-buttons'
import { Controller } from 'react-hook-form'
import entries from 'lodash/entries'
import { FilterButtons } from './filter-buttons'
import Loader from '../../../../../components/ui/loader'
import { useFilters } from '../contexts/filters-context'

export function AutomationFilters() {
  const { filters = [], isLoading } = useFilters()

  return (
    <Card className='!tw-mb-0 tw-gap-3 tw-p-6'>
      <Funnel size={25} />
      <span className='tw-text-xl tw-font-medium'>Filters</span>
      <span>Customise the trigger criteria according to your needs</span>

      {isLoading ? (
        <Loader minHeight='15px' className='tw-my-2 tw-flex-1' />
      ) : (
        <div className='tw-flex tw-flex-row tw-flex-wrap tw-gap-2'>
          <AddFilterButtons filters={filters} />
          <Controller
            name='filters'
            render={({ field: { value } }) => (
              <>
                {entries(value)?.map(([name]) => (
                  <FilterButtons
                    filters={filters}
                    name={name}
                    key={`filters-${name}`}
                  />
                ))}
              </>
            )}
          />
        </div>
      )}

      <div className='tw-flex tw-flex-row tw-items-center tw-gap-2'>
        <Info size={20} className='tw-text-text-80' />
        <span className='tw-text-text-80'>
          Only events meeting this criteria will be automated.
        </span>
      </div>
    </Card>
  )
}
