import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { Badge, Col, Container, Row } from 'reactstrap'

import { HeaderLogo } from '../../components/Common/header-logo'
import FormWizard from '../../components/Forms/FormWizard'
import { useContractorType } from '../../components/Forms/UserTypeSelect'
import Steps from '../../components/Steps'
import Loader from '../../components/ui/loader'
import { checkRegisterToken } from '../../services/api'
import { apiError } from '../../store/actions'

const Header = ({ title }) => (
  <Row className='tw-m-0 tw-mb-4 tw-mt-6 tw-justify-center md:!tw-mt-12'>
    <h5 className='tw-mb-4 tw-text-center tw-text-[22px] tw-text-black md:tw-text-[32px]'>
      {title}
    </h5>
  </Row>
)

const Register = (props) => {
  const searchParams = new URLSearchParams(props.location.search)
  const { isDeEmployee } = useContractorType()
  const typeP = searchParams.get('type')
  const [activeTab, setActiveTab] = useState(1)
  const [steps, setSteps] = useState([])
  const [userType, setUserType] = useState(
    isDeEmployee
      ? 1
      : typeP
        ? typeP === 'employee'
          ? 2
          : typeP === 'client'
            ? 0
            : 1
        : 0,
  )
  const [loading, setLoading] = useState(false)
  const tokenP = new URLSearchParams(props.location.search).get('token')
  const history = useHistory()

  useEffect(() => {
    if (tokenP) {
      setLoading(true)
      checkRegisterToken(tokenP)
        .then((r) => {
          if (r.data?.success) {
            if (!r?.data?.data.is_valid) {
              history.push('/activity')
            }
            setLoading(false)
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e)
        })
    }
  }, [])

  useEffect(() => {
    if (tokenP) {
      setSteps([
        isDeEmployee ? 'Email' : 'Account type',
        '',
        'Your Information',
      ])
    } else if (userType === 0) {
      setSteps(['Account type', 'Your Information', 'Company Info'])
    } else {
      setSteps(['Account type', 'Email', 'Info'])
    }
  }, [userType, isDeEmployee, tokenP])

  if (loading) {
    return <Loader minHeight='80vh' />
  }

  return (
    <div
      className={`${tokenP && !isDeEmployee ? 'blur-image' : 'blur-container'}`}
      style={{ minHeight: '100vh' }}
    >
      <div className='tw-fixed tw-left-0 tw-right-0 tw-top-0 tw-z-50 tw-flex tw-h-14 tw-items-center tw-justify-between tw-bg-white tw-shadow md:tw-h-20 md:tw-justify-start'>
        <div className='tw-shrink-0 tw-p-4'>
          <HeaderLogo />
        </div>
        <Col
          md={10}
          lg={8}
          className='tw-hidden tw-shrink md:tw-ml-6 md:tw-block'
        >
          <Steps activeTab={activeTab - 1} data={steps} userType={userType} />
        </Col>
        <Col
          xs={2}
          className='tw-m-0 tw-flex tw-justify-end tw-p-0 tw-px-4 md:tw-hidden'
        >
          <Badge
            className='tw-rounded tw-bg-primary/10 tw-text-xs lg:tw-hidden'
            color='white'
            pill
          >
            <p className='tw-mb-0 tw-px-0.5 tw-py-[3px] tw-text-xs tw-text-primary'>
              {activeTab} / {steps?.length}
            </p>
          </Badge>
        </Col>
      </div>

      <Container fluid className='!tw-pt-20 lg:!tw-pt-[100px]'>
        {activeTab === 0 && (
          <Row className='justify-content-center mt-3 mt-md-4 mb-4 p-0 m-0'>
            <h5 className='tw-mb-0 tw-text-[22px] md:tw-text-[32px]'>
              Account type
            </h5>
          </Row>
        )}
        {activeTab === 1 && (
          <Header
            title={
              userType === 1
                ? 'Tell us what describes you best'
                : 'Verify your email'
            }
          />
        )}
        {activeTab === 2 && (
          <Header
            title={userType === 1 ? 'Verify your email' : 'General info'}
          />
        )}
        {activeTab === 3 && (
          <Header
            title={
              tokenP
                ? steps[activeTab - 1]
                : userType === 1
                  ? 'Enter your info'
                  : 'Company info'
            }
          />
        )}
        <Row className='tw-m-0 tw-justify-center tw-p-0'>
          <Col className='tw-m-0 tw-p-0'>
            <FormWizard
              returnTabValue={(value) => {
                if (userType === 0) {
                  if (value > 2) {
                    setActiveTab(!tokenP ? value - 1 : value)
                  } else {
                    setActiveTab(1)
                  }
                } else {
                  setActiveTab(value)
                }
              }}
              userType={userType}
              setUserType={setUserType}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, { apiError })(Register)
