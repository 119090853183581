import { t } from 'i18next'
import cx from 'classnames'
import React, { useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Col, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap'
import toastr from 'toastr'

import BadgeX from '../../components/Table/BadgeX'
import FullscreenLoader from '../../components/ui/fullscreen-loader'
import Loader from '../../components/ui/loader'
import {
  ACH,
  BREX,
  CREDIT_CARD,
  SEPA_DEBIT,
} from '../../core/config/payment-methods'
import { useFetch, useResize } from '../../helpers/hooks'
import {
  addContractorPaymentMethod,
  addPayInBrexAccount,
  addPaymentMethod,
  getPayInAuthCode,
  getPayInAuthUrl,
  getPayInBrexAccounts,
  getSavedBrexAccounts,
  setupAch,
} from '../../services/api'
import StripeForm from '../due-payments-page/CheckoutForm'
import SepaForm from '../due-payments-page/SEPAForm'
import { CCItem } from './creditCards'
import PaymentMethods from './paymentMethods'
import FEATURE_FLAGS from '../../config/feature-flags'

const getDesiredMessage = (id) => {
  switch (id) {
    case CREDIT_CARD.id: {
      return 'Hi, I would like to request access to pay by card.'
    }
    case SEPA_DEBIT.id: {
      return 'Hi, I have a bank account in EU and would like to request access to SEPA Direct Debit.'
    }
    case ACH.id: {
      return 'Hi, I have a US bank account and would like to request access to ACH.'
    }
    case BREX.id: {
      return 'Hi, I would like to request access to pay with Brex.'
    }
    default: {
      return 'Hi, I would like to request access for this payment method.'
    }
  }
}

function formatBrexAccount(brexAccount) {
  if (!brexAccount) return null

  return {
    ...brexAccount,
    bank_name: brexAccount.name,
    last4: brexAccount.accountNumber.slice(-4),
    type: BREX.type,
  }
}

const CreditCardsForSteps = ({
  onNext = () => {},
  onAccountAdded = () => {},
  plaidToken,
  onBrexAuthInitiated,
  cards = [],
  noCoinbase,
  cardsLoading,
  onMethodSelected,
  hasMethodSelected,
  methods = [],
  loading,
  isEditing,
  onUpdate,
  isInsurance,
}) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const code = searchParams.get('code')
  const state = searchParams.get('state')

  const [brexAccountsModal, setBrexAccountsModal] = useState(!!code && !!state)
  const [checkedCard, setCheckedCard] = useState(-1)
  const [selectedMethod, setSelectedMethod] = useState(null)
  const [actionLoading, setActionLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const achMethods = cards.filter((e) => !e.type) || []
  const sepaMethods = cards.filter((e) => e.type === 'sepa_debit') || []
  const ccMethods = cards.filter((e) => e.type === 'card') || []

  const isMobile = useResize()

  const user = useSelector((state) => state.Account?.user)
  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  const addCard = isInsurance ? addContractorPaymentMethod : addPaymentMethod

  function toggleBrexAccountsModal() {
    setBrexAccountsModal((open) => !open)
  }

  const handleCCSubmitted = (stripData) => {
    if (stripData.error) {
      toastr.error(
        typeof stripData.error === 'string'
          ? stripData.error
          : typeof stripData.error?.message === 'string'
            ? stripData.error.message
            : 'An error occurred',
      )
      return
    }

    setActionLoading(true)
    addCard(user?.token, {
      payment_method_id:
        stripData?.paymentMethod?.id || stripData?.setupIntent?.payment_method,
    })
      .then((r) => {
        if (r.data.success) {
          const cardMethod = r.data.data

          setShowModal(false)
          onAccountAdded(cardMethod)

          handleSelectSavedMethod(cardMethod, 1)

          toastr.success(r.data.message)
        } else {
          toastr.error(
            r.data?.data?.error ||
              r.data.message ||
              'Something went wrong while adding card',
          )
        }
      })
      .catch(() => {
        toastr.error(t('Something went wrong while adding card'))
      })
      .finally(() => setActionLoading(false))
  }

  function handleSelectSavedMethod(method, id) {
    const foundMethod = methods?.methods.find((e) => e?.id === id)
    onMethodSelected(foundMethod)

    setCheckedCard(method)
    onNext(method)
  }

  const setupACH = useFetch({
    action: setupAch,
    onComplete: (data) => {
      onAccountAdded(data)
      setCheckedCard(data)
    },
  })

  const onPlaidSuccess = (publicToken, data) => {
    setupACH.startFetch({
      public_token: publicToken,
      account_id: data?.account_id,
    })
  }

  const { open: openPlaid } = usePlaidLink({
    token: plaidToken,
    onSuccess: onPlaidSuccess,
  })

  const { data: brexAccounts, startFetch: updateBrexAccounts } = useFetch({
    action: getSavedBrexAccounts,
    autoFetch: FEATURE_FLAGS.PAYMENT_METHOD_BREX,
    body: {
      userId: user?.id,
      companyId: userProfile?.company?.id,
    },
  })

  function handleUpdateBrexAccounts() {
    updateBrexAccounts({
      userId: user?.id,
      companyId: userProfile?.company?.id,
    })
  }

  const hasSavedMethods = () => {
    return (
      (!!achMethods?.length &&
        (methods?.methods?.find((e) => e?.id === ACH.id)?.enabled ||
          isEditing)) ||
      (!!sepaMethods?.length &&
        (methods?.methods?.find((e) => e?.id === SEPA_DEBIT.id)?.enabled ||
          isEditing)) ||
      (!!ccMethods?.length &&
        (methods?.methods?.find((e) => e?.id === CREDIT_CARD.id)?.enabled ||
          isEditing)) ||
      !!brexAccounts
    )
  }

  const canSaveMethods = () => {
    const PMethods = Array.isArray(methods?.methods)
      ? methods.methods
      : Array.isArray(methods)
        ? methods
        : []
    return (
      PMethods?.find((e) => e?.id === ACH.id)?.enabled ||
      PMethods?.find((e) => e?.id === SEPA_DEBIT.id)?.enabled ||
      PMethods?.find((e) => e?.id === CREDIT_CARD.id)?.enabled
    )
  }

  function closeModal() {
    setShowModal(false)
  }

  const { startFetch: getAuthUrl, isLoading: gettingBrexAuthUrl } = useFetch({
    action: getPayInAuthUrl,
    onComplete: (data) => {
      if (data?.url) {
        if (typeof onBrexAuthInitiated === 'function' && data?.url) {
          onBrexAuthInitiated(data)
        }
        window.open(data.url)
      }
    },
    onError: () => {
      toastr.error(t('Something went wrong while getting auth url'))
    },
  })

  function handleBrexAuthorization() {
    getAuthUrl({
      companyId: userProfile?.company?.id,
      userId: userProfile?.id,
      // 'registration' and 'payments' are mapped to callback URL in the Backend
      // 'registration' => '/pay-invoices'
      // 'payments' => '/settings/payment'
      // 'isEditing' is used in the company settings page, 'isInsurance' is used in the insurance page
      // otherwise it's the payments page
      type: isEditing ? 'registration' : isInsurance ? '' : 'payments',
    })
  }

  function isMethodEnabled(methodId) {
    return methods?.methods?.find((e) => e?.id === methodId)?.enabled
  }

  function isItemChecked(method) {
    return hasMethodSelected && checkedCard?.id === method?.id && !isEditing
  }

  return loading || setupACH.isLoading || !plaidToken ? (
    <Loader minHeight='max(40vh, 340px)' />
  ) : (
    <Col className='px-0'>
      <div className='mb-4 empty:tw-hidden'>
        {!hasSavedMethods() ? null : (
          <div className='d-flex align-items-center gap-8 mb-3'>
            <SectionTitle>{t('Saved Methods')}</SectionTitle>
            {!cardsLoading ? null : <Spinner size='sm' color='secondary' />}
          </div>
        )}

        {!isMethodEnabled(CREDIT_CARD.id) && !isEditing ? null : (
          <SavedAccountsList
            methods={ccMethods}
            title={t('Credit/Debit Cards')}
            onClick={(method) =>
              handleSelectSavedMethod(method, CREDIT_CARD.id)
            }
            onUpdate={() => onUpdate()}
            isItemChecked={(method) => isItemChecked(method)}
            isEditing={isEditing}
          />
        )}

        {!isMethodEnabled(SEPA_DEBIT.id) && !isEditing ? null : (
          <SavedAccountsList
            methods={sepaMethods}
            title={t('SEPA Direct Debit')}
            onClick={(method) => handleSelectSavedMethod(method, SEPA_DEBIT.id)}
            onUpdate={() => onUpdate()}
            isItemChecked={(method) => isItemChecked(method)}
            isEditing={isEditing}
          />
        )}

        {!isMethodEnabled(ACH.id) && !isEditing ? null : (
          <SavedAccountsList
            methods={achMethods}
            title={t('ACH - Direct Debit')}
            onClick={(method) => handleSelectSavedMethod(method, ACH.id)}
            onUpdate={() => onUpdate()}
            isItemChecked={(method) => isItemChecked(method)}
            isEditing={isEditing}
          />
        )}

        {!isMethodEnabled(BREX.id) && !isEditing ? null : (
          <SavedAccountsList
            methods={[formatBrexAccount(brexAccounts)].filter(Boolean)}
            title='Brex'
            onClick={(method) => handleSelectSavedMethod(method, BREX.id)}
            onUpdate={() => onUpdate()}
            isItemChecked={(method) => isItemChecked(method)}
            isEditing={isEditing}
            updateBrexAccounts={handleUpdateBrexAccounts}
          />
        )}
      </div>

      <>
        {canSaveMethods() && (
          <SectionTitle className='tw-mb-4'>{t('Add New Method')}</SectionTitle>
        )}

        <PaymentMethods
          data={methods?.methods || methods || []}
          bankMethods={
            methods?.methods
              ?.filter((m) => !!m?.payment_account_id)
              ?.map((m) => {
                return { ...m, id: m?.payment_account_id, label: m?.name }
              }) ?? []
          }
          currency={methods?.currency}
          loading={loading}
          isItemSelected={(item) => {
            return item?.id === selectedMethod?.id && !checkedCard && !isEditing
          }}
          onSelected={(item) => {
            onMethodSelected(null)
            onAccountAdded(null)

            setCheckedCard(null)
            setSelectedMethod(item)

            if (item?.id === ACH.id) {
              openPlaid()
            } else if (
              item?.id === SEPA_DEBIT.id ||
              item?.id === CREDIT_CARD.id
            ) {
              setShowModal(true)
            } else if (item?.id === BREX.id) {
              handleBrexAuthorization(item)
            } else {
              onMethodSelected(item)
              onAccountAdded(item)
            }
          }}
          noACH={achMethods?.length > 0}
          noCoinbase={noCoinbase}
          isEditing={isEditing}
          isInsurance={isInsurance}
          requestAccess={(e) => {
            if (e?.type !== 'bc') {
              window.Intercom('showNewMessage', `${getDesiredMessage(e.id)}`)
            }
          }}
        />
      </>

      {!gettingBrexAuthUrl ? null : <FullscreenLoader />}

      <Modal
        isOpen={showModal}
        scrollable
        centered
        style={{
          maxWidth: isMobile ? '100vw' : '50vw',
          minHeight: isMobile ? '100vh' : undefined,
        }}
        toggle={closeModal}
      >
        <ModalHeader toggle={closeModal}>{t('Add New Method')}</ModalHeader>
        <ModalBody className='p-0'>
          {selectedMethod?.type === 'card' && (
            <StripeForm
              onSubmitted={handleCCSubmitted}
              setLoading={setActionLoading}
              loading={actionLoading}
              onCancel={closeModal}
            />
          )}
          {selectedMethod?.type === 'sepa_debit' && (
            <SepaForm
              onSubmitted={handleCCSubmitted}
              setLoading={setActionLoading}
              loading={actionLoading}
              onCancel={closeModal}
            />
          )}
        </ModalBody>
      </Modal>

      <BrexAccountsModal
        isOpen={brexAccountsModal}
        toggle={toggleBrexAccountsModal}
        params={{ code, state }}
        onAccountAdded={handleUpdateBrexAccounts}
      />
    </Col>
  )
}

function BrexAccountsModal({ isOpen, toggle, params, onAccountAdded }) {
  const userProfile = useSelector((state) => state.userProfile?.userProfile)
  const { code, state } = params

  const { isLoading: gettingAuthCodeConfirmation } = useFetch({
    action: getPayInAuthCode,
    autoFetch: !!code && !!state,
    body: { code, state },
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(t('Error while connecting to Brex'))
      } else {
        getBrexAccounts({
          companyId: userProfile?.company?.id,
          userId: userProfile?.id,
        })
      }
    },
    onError: () => {
      toastr.error(t('Error while connecting to Brex'))
    },
  })

  const {
    data: brexAccounts,
    isLoading: gettingBrexAccounts,
    startFetch: getBrexAccounts,
  } = useFetch({
    action: getPayInBrexAccounts,
  })

  const loading = gettingAuthCodeConfirmation || gettingBrexAccounts

  const { startFetch: addBrexAccount, isLoading: addingBrexAccount } = useFetch(
    {
      action: addPayInBrexAccount,
      onComplete: () => {
        toastr.success(t('Brex account added successfully'))
        onAccountAdded?.()
        toggle()
      },
    },
  )

  function handleAddAccount(account) {
    addBrexAccount({
      ...account,
      companyId: userProfile?.company?.id,
      userId: userProfile?.id,
    })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered unmountOnClose>
      <ModalBody>
        <h4 className='text-gray-h mb-4'>{t('Select a Brex account')}</h4>

        {loading ? (
          <Loader />
        ) : (
          <div className='d-flex flex-column gap-6'>
            {brexAccounts?.map((account) => {
              return (
                <button
                  className='rp-btn-nostyle text-left d-flex align-items-center justify-content-between p-3 border rounded hover:bg-gray-100'
                  style={{ minHeight: 75 }}
                  key={account.id}
                  onClick={
                    addingBrexAccount
                      ? undefined
                      : () => handleAddAccount(account)
                  }
                  disabled={addingBrexAccount}
                >
                  <div>
                    <div className='font-size-16 text-gray-h'>
                      {account.name}
                    </div>
                    <div className='text-gray-600'>
                      {account.accountNumber.split('').slice(0, -4).fill('*')}{' '}
                      {account.accountNumber.slice(-4)}
                    </div>
                  </div>
                  {account?.primary ? (
                    <BadgeX className='uppercase' size='sm' status='secondary'>
                      {t('Primary')}
                    </BadgeX>
                  ) : null}
                </button>
              )
            })}
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

function SavedAccountsList({
  methods,
  title,
  onClick,
  onUpdate,
  updateBrexAccounts,
  isItemChecked,
  isEditing,
}) {
  if (!methods || !Array.isArray(methods) || methods.length <= 0) {
    return null
  }

  return (
    <div>
      <div className='text-secondary mt-3 mb-2'>{title}</div>

      <Row className='mx-n2' style={{ gap: '1rem 0' }}>
        {methods.map((method, index) => {
          return (
            <Col key={index} md={6} className='px-2'>
              <CCItem
                card={method}
                onCheck={() => onClick(method)}
                onUpdate={() => onUpdate(method)}
                checked={isItemChecked(method)}
                isEditing={isEditing}
                updateBrexAccounts={updateBrexAccounts}
              />
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

function SectionTitle({ children, className }) {
  return (
    <h5 className={cx('tw-mb-0 tw-font-bold tw-text-black', className)}>
      {children}
    </h5>
  )
}

export default CreditCardsForSteps
