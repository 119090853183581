import { yupResolver } from '@hookform/resolvers/yup'
import { ArrowLeft, Warning } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Panel } from '@xyflow/react'
import toastr from 'toastr'
import * as yup from 'yup'

import { cn } from 'ui'
import ControlledSelect from '../../components/ControlledSelect'
import SearchBar from '../../components/SearchBar'
import Alert from '../../components/ui/alert'
import Button from '../../components/ui/button'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../components/ui/side-menu'
import { useFetch, usePermissions } from '../../helpers/hooks'
import { updateOrgChartContract } from '../../services/api'
import { getDepartmentsList } from '../../services/api-org-charts'
import { ConfirmFormField } from '../Contract/CreateContract/components/confirm-field'
import { DepartmentColor } from './department-colors'
import { ControlledContractSelections } from './missing-managers'
import { UserProfile } from './organization-chart'
import permissions from '../../helpers/permissions'

export function MissingDepartments({ depData, refreshData }) {
  const [isOpen, setIsOpen] = useState(false)
  function toggleSidebar() {
    setIsOpen((open) => !open)
  }

  const missingDepartmentCount = depData?.contracts_missing_department?.length
  const showMissingDepartments = missingDepartmentCount > 0

  if (!showMissingDepartments) {
    return null
  }

  return (
    <>
      <Panel position='top-right' className='!tw-top-[calc(27px+25px)]'>
        <Alert
          customIcon={<Warning size={24} className='tw-text-systemGold-100' />}
          className='!tw-mb-0 tw-flex-col !tw-border-surface-30 !tw-bg-systemGold-20 !tw-p-6'
          iconClassName='tw-block'
          innerTag='div'
          color='warning'
        >
          <h5 className='tw-mb-0 tw-text-sm tw-font-bold tw-text-black'>
            Unassigned members
          </h5>
          <p className='tw-mb-0 tw-max-w-48 tw-text-sm tw-font-medium tw-text-black'>
            {missingDepartmentCount} member
            {missingDepartmentCount === 1 ? '' : 's'} don’t have a department
            assigned.
          </p>

          <Button
            type='button'
            color='link'
            className='tw-mt-4 !tw-p-0 !tw-text-secondary-100'
            onClick={toggleSidebar}
          >
            View
          </Button>
        </Alert>
      </Panel>

      {!isOpen ? null : (
        <ManageMissingDepartment
          isOpen={isOpen}
          toggleSidebar={toggleSidebar}
          depData={depData}
          onSuccess={refreshData}
        />
      )}
    </>
  )
}

const missingDepartmentFormId = 'missingManagersForm'
export function ManageMissingDepartment({
  isOpen,
  toggleSidebar,
  depData,
  onSuccess,
  defaultValues,
}) {
  const [search, setSearch] = useState('')
  const { hasAccess } = usePermissions()
  const hasManagePermission = hasAccess(permissions.ManageOrgChart)
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        contractId: yup.string().label('Contract').required(),
        departmentId: yup.string().label('Department').required(),
        isHead: yup.string().label('Head of department').notRequired(),
      }),
    ),
  })
  const selectedContractId = useWatch({ control, name: 'contractId' })

  const { data: departmentsList, isLoading: departmentsLoading } = useFetch({
    action: getDepartmentsList,
    autoFetch: true,
  })

  // When passing default values, we assume that the sidebar is opened for editing
  const isEditing = !!defaultValues?.contractId

  const filteredContracts = depData.contracts_missing_department.filter(
    (contract) => {
      return contract.name.toLowerCase().includes(search.toLowerCase())
    },
  )

  const selectedContract = isEditing
    ? depData.all_contracts.find(
        (contract) => String(contract.id) === String(defaultValues.contractId),
      )
    : !selectedContractId
      ? null
      : filteredContracts.find((contract) => contract.id === selectedContractId)

  const { startFetch: updateManager, isLoading: isUpdatingManager } = useFetch({
    action: updateOrgChartContract,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Failed to assign manager')
      } else {
        toastr.success('Manager assigned to department successfully')
        toggleSidebar()
        onSuccess?.()
      }
    },
  })

  function onSubmit(data) {
    const body = {
      contract_id: data.contractId,
      department_id: data.departmentId,
      is_head_of_department: data.isHead === 'yes',
    }
    updateManager(body)
  }

  return (
    <SideMenu
      className='!tw-w-full tw-max-w-[480px]'
      itemListClassName={cn(
        'tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain',
        selectedContract
          ? 'tw-grid-rows-[minmax(auto,max-content)_1fr_91px]'
          : 'tw-grid-rows-[minmax(auto,max-content)_1fr]',
      )}
      isOpen={isOpen}
      onClose={toggleSidebar}
    >
      <SideMenuHeader toggle={toggleSidebar} className='tw-items-start'>
        <div className='tw-flex-grow'>
          <div className='tw-flex tw-items-start tw-gap-2'>
            {!selectedContract || isEditing ? null : (
              <Button
                color='link'
                size='sm'
                className='!tw-px-0 !tw-text-secondary-100'
                type='button'
                onClick={() => {
                  setValue('contractId', null)
                  setValue('managerId', null)
                  setValue('departmentId', null)
                }}
                icon={
                  <ArrowLeft
                    size={16}
                    className='tw-flex-shrink-0'
                    weight='bold'
                  />
                }
              />
            )}
            {isEditing ? null : (
              <div className='tw-flex tw-flex-col'>
                <div className='tw-font-semibold'>
                  {isEditing ? 'Assign departments' : 'Assign departments'}
                </div>
                <span className='tw-text-sm tw-text-text-60'>
                  {isEditing
                    ? 'Change the department of the member'
                    : 'Choose a department for this member'}
                </span>
              </div>
            )}
          </div>

          {!selectedContract ? null : (
            <div
              className={cn(
                '[&>*>*]:tw-p-0 [&>*]:tw-border-none',
                !isEditing && 'tw-mt-6',
              )}
            >
              <UserProfile
                data={{
                  name: selectedContract.name,
                  role: isEditing
                    ? `#${selectedContract?.contract_ref}`
                    : selectedContract.title,
                  flag: selectedContract.flag,
                  photo: selectedContract.photo,
                  handless: true,
                  className: 'tw-w-auto',
                }}
              />
            </div>
          )}
        </div>
      </SideMenuHeader>

      <SideMenuBody className='tw-flex-grow !tw-px-0'>
        <form onSubmit={handleSubmit(onSubmit)} id={missingDepartmentFormId}>
          {selectedContract ? (
            <div className='tw-px-6'>
              <ControlledSelect
                control={control}
                name='departmentId'
                placeholder='Select department'
                label='Department'
                options={departmentsList?.map(({ id, name, color }) => {
                  return {
                    label: name,
                    value: id,
                    icon: <DepartmentColor color={color} />,
                  }
                })}
                isLoading={departmentsLoading}
                isDisabled={departmentsLoading}
              />

              <ConfirmFormField
                control={control}
                error={errors.isHead}
                name='isHead'
                defaultValue='no'
                title='Are they head of the department?'
                fieldOptions={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
                className='tw-mt-4'
              />
            </div>
          ) : (
            <>
              <div className='tw-px-6'>
                <SearchBar query={search} onQueryChanged={setSearch} />
              </div>

              <ControlledContractSelections
                control={control}
                name='contractId'
                disabled={!hasManagePermission}
                options={filteredContracts}
              />
            </>
          )}
        </form>
      </SideMenuBody>

      {!selectedContract ? null : (
        <SideMenuFooter>
          <Button
            type='button'
            color='light'
            outline
            onClick={toggleSidebar}
            disabled={isUpdatingManager}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            formId={missingDepartmentFormId}
            loading={isUpdatingManager}
            disabled={isUpdatingManager}
          >
            Assign
          </Button>
        </SideMenuFooter>
      )}
    </SideMenu>
  )
}
