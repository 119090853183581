import { validatePhoneNumber } from '../../../services/api'

export async function getFullPhoneNumber({ token, mobile, setLoading }) {
  setLoading?.(true)

  let fullMobile = null
  let mobileNumber = null
  let mobileCode = null

  const { data: resp } = await validatePhoneNumber(token, { phone: mobile })

  const respData = resp.data
  if (resp?.success && respData?.valid) {
    const phonePrefix = respData?.country?.prefix
    const code = phonePrefix.slice(1)
    const phoneWithoutPrefix = respData?.phone?.slice(phonePrefix.length)

    fullMobile = respData?.phone
    mobileNumber = phoneWithoutPrefix
    mobileCode = code
  }

  setLoading?.(false)

  const data = {
    fullPhoneNumber: fullMobile,
    mobile: mobileNumber,
    mobileCountryCode: mobileCode,
    valid: !!mobileCode,
  }

  return data
}
