import { Plus } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card } from 'reactstrap'

import { cn } from 'ui'
import { PaymentStatusBadgeV2, SubmittedByOn } from '.'
import noInvoicesImage from '../../../assets/images/no-invoices.svg'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import NoContent from '../../../components/NoContent'
import StyledTh from '../../../components/Table/StyledTh'
import TableComp from '../../../components/Table/TableComp'
import TableH from '../../../components/Table/TableH'
import { StyledH6 } from '../../../components/Typo'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import Pagination from '../../../components/ui/pagination'
import { CONTRACT_STATUS, userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions, useResize } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import { deleteWork, getContractSubmittedWork } from '../../../services/api'
import { getFullName } from '../../../utils/get-full-name'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import DeclineModal from '../components/DeclineModal'
import { SubmitWorkModal } from '../components/submit-work-modal'
import { SubmittedWorkActions } from './submitted-work-actions'

const getRate = (id, number) => {
  switch (id) {
    case 1:
      return `Hour${number === 1 ? '' : 's'}`
    case 2:
      return `Day${number === 1 ? '' : 's'}`
    case 3:
      return `Month${number === 1 ? '' : 's'}`
    case 4:
      return `Minute${number === 1 ? '' : 's'}`
  }
}

export default function SubmittedWork({
  contractDetails,
  setShowConfirmationModal,
  setEndedModal,
  setNeedSign,
}) {
  const user = useSelector((state) => state.Account?.user)
  const [submitWork, setSubmitWork] = useState(false)
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(null)
  const [page, setPage] = useState(1)
  const { hasAccess } = usePermissions()
  const isMobile = useResize()
  const {
    data: works,
    isLoading: isLoadingWorks,
    completed: doneFetchingWorks,
    paginator,
    startFetch: fetchWorks,
  } = useFetch(
    {
      action: getContractSubmittedWork,
      autoFetch: true,
      body: { id: contractDetails.id, page },
    },
    [page],
  )
  const isLoading = !doneFetchingWorks || isLoadingWorks
  const updateSubmittedWork = () => {
    fetchWorks({ id: contractDetails.id, page })
  }
  const deleteLine = useFetch({
    action: deleteWork,
    onComplete: () => {
      setDeleteConfirmModal(null)
      updateSubmittedWork()
      window.analytics.track('Deleted work', {
        contract_id: contractDetails?.ref,
        contract_type: contractDetails?.type,
        // work_id: e?.id,
        // work_name: e?.name,
        // work_value: e?.value,
        currency: contractDetails?.currency?.code,
      })
    },
  })
  const handleDeleteWork = () => {
    deleteLine.startFetch({
      work_id: deleteConfirmModal?.id,
    })
  }
  const showPaginator = paginator?.first_page_url !== paginator?.last_page_url

  const canAddWork =
    user?.type === userTypes.CONTRACTOR ||
    hasAccess(permissions.SubmitWorks) ||
    contractDetails?.can_approve ||
    ([
      CONTRACT_STATUS.ENDED.value,
      CONTRACT_STATUS.CANCELLED.value,
      CONTRACT_STATUS.TERMINATED.value,
    ].includes(contractDetails?.status?.id) &&
      !contractDetails?.amended)

  return (
    <>
      {deleteConfirmModal && (
        <ConfirmationModal
          confirmLoading={deleteLine.isLoading}
          content='Are you sure you want to delete this work?'
          title='Work Deletion'
          isOpen={deleteConfirmModal !== null}
          onConfirm={handleDeleteWork}
          withCancel
          toggle={() => setDeleteConfirmModal(null)}
        />
      )}
      {submitWork && (
        <SubmitWorkModal
          isOpen={submitWork}
          toggle={() => setSubmitWork((open) => !open)}
          contract={contractDetails}
          onWorkSubmitted={updateSubmittedWork}
        />
      )}
      <Card
        className='mb-0 rp-shadow-2'
        style={{ minHeight: 'max(40vh,400px)' }}
      >
        {isLoading ? (
          <Loader minHeight='max(40vh,400px)' />
        ) : works?.length > 0 ? (
          <>
            <div className='overflow-visible'>
              <TableH
                title='Work'
                leftSide={
                  <PermissionTooltip
                    showing={!hasAccess(permissions.SubmitWorks)}
                    id='add-work-btn-tooltip'
                    area={PERMISSION_GROUP.WORKS.name}
                  >
                    <Button
                      onClick={() => {
                        if (
                          contractDetails.status.id ===
                          CONTRACT_STATUS.TERMINATED.value
                        ) {
                          setShowConfirmationModal(true)
                        } else if (
                          contractDetails.status.id ===
                          CONTRACT_STATUS.ENDED.value
                        ) {
                          setEndedModal(true)
                        } else if (
                          user?.type === 'client' ||
                          contractDetails?.can_submit
                        ) {
                          setSubmitWork(true)
                        } else {
                          setNeedSign(true)
                        }
                      }}
                      disabled={
                        !(
                          user?.type === userTypes.CONTRACTOR ||
                          hasAccess(permissions.SubmitWorks) ||
                          contractDetails?.can_approve
                        ) ||
                        [5, 6, 7].includes(contractDetails?.status?.id) ||
                        contractDetails?.amended
                      }
                    >
                      Add New Work
                    </Button>
                  </PermissionTooltip>
                }
              />
              {isMobile ? (
                <div className='px-3'>
                  {works?.map((work, i) => (
                    <WorkCardMobile
                      key={`work:${i}`}
                      work={work}
                      updateContractDetail={updateSubmittedWork}
                      data={contractDetails}
                    />
                  ))}
                </div>
              ) : (
                <div className='table-with-dropdown'>
                  <TableComp className='table-centered'>
                    <thead className='thead-light'>
                      <tr>
                        <StyledTh>Name</StyledTh>
                        <StyledTh>Date</StyledTh>
                        <StyledTh>Rate</StyledTh>
                        <StyledTh>Status</StyledTh>
                        <StyledTh />
                      </tr>
                    </thead>
                    <tbody>
                      {works?.map((work, i) => (
                        <WorkCard
                          key={`work:${i}`}
                          work={work}
                          updateContractDetail={updateSubmittedWork}
                          data={contractDetails}
                        />
                      ))}
                    </tbody>
                  </TableComp>
                </div>
              )}
            </div>
            {!showPaginator || isLoading ? null : (
              <div className='px-3 w-100 d-flex justify-content-end align-items-center'>
                <Pagination
                  activePage={page}
                  itemsCountPerPage={paginator?.per_page ?? 10}
                  totalItemsCount={paginator?.total ?? 0}
                  onChange={(newPage) => setPage(newPage)}
                />
              </div>
            )}
          </>
        ) : (
          <div
            className='d-flex justify-content-center align-items-center pb-3'
            style={{ minHeight: 'max(40vh,400px)' }}
          >
            <NoContent
              headline='No work submitted'
              subtitle='All work submissions will be shown here and will be subject to approval by the client.'
              image={noInvoicesImage}
              customBottom={
                !canAddWork ? null : (
                  <Button
                    type='button'
                    onClick={() => {
                      if (
                        contractDetails.status.id ===
                        CONTRACT_STATUS.TERMINATED.value
                      ) {
                        setShowConfirmationModal(true)
                      } else if (
                        contractDetails.status.id ===
                        CONTRACT_STATUS.ENDED.value
                      ) {
                        setEndedModal(true)
                      } else if (
                        user?.type === 'client' ||
                        contractDetails?.can_submit
                      ) {
                        setSubmitWork(true)
                      } else {
                        setNeedSign(true)
                      }
                    }}
                    icon={<Plus size={16} weight='bold' />}
                  >
                    Submit Work
                  </Button>
                )
              }
            />
          </div>
        )}
      </Card>
    </>
  )
}

function WorkCardMobile({ work, updateContractDetail, data, isAdmin }) {
  const [declineModal, setDeclineModal] = useState(false)

  return (
    <div className='mb-3 bg-white border border-surface-30 rounded'>
      <DeclineModal
        data={data}
        isOpen={declineModal}
        e={work}
        onDeclined={() => {
          updateContractDetail()
        }}
        toggle={() => setDeclineModal(false)}
      />
      <div className='d-flex justify-content-between align-items-center border-bottom p-3 m-0 p-0'>
        <WorkStatus work={work} />

        <SubmittedWorkActions
          work={work}
          isAdmin={isAdmin}
          updateContract={updateContractDetail}
          contract={data}
        />
      </div>

      <div className='d-flex justify-content-between align-items-center py-3 px-3'>
        <p className='text-dark font-size-14 mb-0'>Name</p>
        <p className='text-dark font-size-14 mb-0'>{work.name}</p>
      </div>

      <div className='d-flex justify-content-between align-items-center pt-3 px-3 font-size-14 tw-gap-4'>
        <p className='text-dark mb-0'> Date</p>

        <div className='p-0 m-0'>
          <SubmittedByOn
            by={getFullName(work.created_by)}
            on={work.submitted_at}
            className='tw-flex tw-flex-wrap tw-gap-[2pt]'
          />

          <WorkAttributes attributes={work?.attributes} />
        </div>
      </div>

      <div className='d-flex justify-content-between align-items-center py-3 px-3'>
        <p className='text-dark font-size-14 mb-0'>Rate</p>
        <p className='text-dark font-size-14 mb-0'>
          {work.qty} {getRate(data?.rate_id?.id, work.qty)}
        </p>
      </div>
    </div>
  )
}

export function WorkCard({ work, data, isAdmin, updateContractDetail }) {
  return (
    <tr>
      <td className='py-3 px-4'>{work.name}</td>
      <td className='py-3 px-4' style={{ whiteSpace: 'normal' }}>
        <SubmittedByOn
          by={getFullName(work.created_by)}
          on={work.submitted_at}
        />

        <WorkAttributes attributes={work?.attributes} />

        {work.status.id === 4 && work?.reason && (
          <StyledH6 className='mb-0 mt-1'>
            Reason for decline: {work?.reason}
          </StyledH6>
        )}
      </td>
      <td className='py-3 px-4'>
        {work.qty} {getRate(data?.rate_id?.id, work.qty)}
      </td>
      <td className='py-3 px-3'>
        <WorkStatus work={work} />
      </td>
      <td className='py-3 px-4'>
        <SubmittedWorkActions
          work={work}
          isAdmin={isAdmin}
          updateContract={updateContractDetail}
          contract={data}
        />
      </td>
    </tr>
  )
}

function WorkStatus({ work }) {
  return (
    <PaymentStatusBadgeV2
      status={work.status?.name}
      paymentStatus={work.payment_status}
    />
  )
}

export function WorkAttributes({ attributes, className }) {
  return (
    <div
      className={cn(
        'tw-mt-2 tw-flex tw-flex-col tw-gap-1 tw-text-xs tw-text-text-60 empty:tw-hidden',
        className,
      )}
    >
      {attributes?.map((attribute) => (
        <div key={attribute?.id} className=''>
          <span>{attribute?.name}: </span>
          <span className='tw-font-medium tw-text-text-80'>
            {attribute?.value}
          </span>
        </div>
      ))}
    </div>
  )
}
