import React from 'react'

const InfoBox = ({ title, icon }) => {
  return (
    <div className='tw-flex tw-px-4 tw-py-6'>
      <div className='tw-flex tw-h-[110px] tw-w-[117px] tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-text-center'>
        {icon}
        <span className='tw-text-sm tw-font-medium tw-text-black'>{title}</span>
      </div>
    </div>
  )
}

export default InfoBox
