import { t } from 'i18next'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import toastr from 'toastr'

import BillApprovalFlowSelector from '../../../../components/bill-approval-flow-selector'
import Button from '../../../../components/ui/button'
import Shimmer from '../../../../components/ui/shimmer'
import {
  SideMenu,
  sideMenuGridClass,
  SideMenuHeading,
} from '../../../../components/ui/side-menu'
import { useFetch } from '../../../../helpers/hooks'
import { getApprovalFlows } from '../../../../services/api'
import {
  assignVendorBillApprovalFlow,
  getDefaultBillApprovalFlow,
} from '../../../../services/api-bill-payments'
import { track } from '../../../../utils/analytics'
import { mapListToOption } from '../../../../utils/map-to-option'
import { APPROVAL_FLOW_EVENTS } from '../../../CompanySetting/approvals/approval-flow-events'

export default function EditVendorApprovalFlow({
  toggle,
  vendor,
  activeFlow,
  refetchVendors,
}) {
  const [selectedFlow, setSelectedFlow] = useState(mapListToOption(activeFlow))
  const user = useSelector((state) => state.Account?.user)

  const { data: defaultBillApprovalFlow } = useFetch({
    action: getDefaultBillApprovalFlow,
    autoFetch: true,
  })

  const { startFetch: assignFlow, isLoading: assigningFlow } = useFetch({
    action: assignVendorBillApprovalFlow,
    onError: toastr.error,
    onComplete: () => {
      toastr.success(
        t('AssignFlowNameToVendor', {
          selectedFlowLabel: selectedFlow.label,
          vendorName: vendor.name,
        }),
      )

      const isDefault = defaultBillApprovalFlow?.id === selectedFlow.value
      track(APPROVAL_FLOW_EVENTS.ASSIGNED_FLOW, {
        user_id: user.id,
        type: 'Vendor',
        approval_flow_id: selectedFlow.value,
        approval_flow_name: selectedFlow.label,
        vendor_id: vendor.id,
        vendor_name: vendor.name,
        is_default: isDefault ? 1 : 0,
      })
      toggle()
      refetchVendors()
    },
  })

  const { data: approvalFlows, isLoading: fetchingFlows } = useFetch({
    action: getApprovalFlows,
    autoFetch: true,
  })

  return (
    <SideMenu
      isOpen
      onClose={toggle}
      preventScrollBehavior={false}
      itemListClassName={sideMenuGridClass()}
    >
      <SideMenu.Header toggle={toggle}>
        <SideMenuHeading
          title={t('Approval flow')}
          subTitle={t('Edit this vendor’s approval flow')}
          onClickBack={toggle}
        />
      </SideMenu.Header>
      <SideMenu.Body>
        {fetchingFlows ? (
          <span className='tw-flex tw-flex-col tw-gap-8'>
            {Array.from({ length: 3 }).map((_, index) => (
              <Shimmer key={index} width='100%' height={147} />
            ))}
          </span>
        ) : (
          <BillApprovalFlowSelector
            approvalFlows={approvalFlows.map((flow) => {
              const isDefault = defaultBillApprovalFlow?.id === flow.id
              return {
                ...flow,
                name: isDefault ? `${flow.name} (Default)` : flow.name,
              }
            })}
            value={selectedFlow?.value}
            onChange={(val) => setSelectedFlow(mapListToOption(val))}
          />
        )}
      </SideMenu.Body>
      <SideMenu.Footer>
        <Button
          type='button'
          onClick={toggle}
          outline
          color='light'
          disabled={assigningFlow}
        >
          {t('Cancel')}
        </Button>
        <Button
          type='button'
          disabled={assigningFlow}
          loading={assigningFlow}
          onClick={() =>
            assignFlow({ vendorId: vendor.id, flowId: selectedFlow.value })
          }
        >
          {t('Save')}
        </Button>
      </SideMenu.Footer>
    </SideMenu>
  )
}
