import { Eye, Spinner, Trash } from '@phosphor-icons/react'
import cx from 'classnames'
import React, { useState } from 'react'
import { t } from 'i18next'

import bankAccount from '../../../assets/images/bankAccount.svg'
import bankAccountChecked from '../../../assets/images/bankAccount_selected.svg'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import { useFetch, useResize } from '../../../helpers/hooks'
import { deleteBankAccount } from '../../../services/api'
import { track } from '../../../utils/analytics'
import EVENTS from '../../../utils/events'
import BankDetailsModal from './BankDetailsModal'
import IconButton from '../../../components/ui/icon-button'

export function getAccountNumber(jsonDetails) {
  let accountNumber = ''

  try {
    const details = JSON.parse(jsonDetails ?? '{}')
    accountNumber = details?.iban || details?.accountNumber || details?.IBAN
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }

  return accountNumber
}

const WithdrawMethodCard = ({
  deactivated,
  withTag,
  withRemove,
  card,
  onUpdate,
  selected,
  onSelect,
  onClick = () => {},
  checked,
  isLoading,
}) => {
  const [showDetails, setShowDetails] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const isMobile = useResize()

  let address = ''
  try {
    address = JSON.parse(card?.details ?? '{}')?.address
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
  const deleteCard = useFetch({
    action: deleteBankAccount,
    onComplete: () => {
      onUpdate()
      track(EVENTS.REMOVED_WITHDRAW_METHODS, {
        withdraw_account_name: card.holder_name,
        withdraw_method: 'Bank Transfer',
        currency: card?.currency,
        country: address?.country,
        is_default: false,
      })
    },
  })

  return (
    <div
      className={cx(`position-relative p-4 text-left rounded cursor-default`, {
        'border-primary': checked,
        'bg-soft-secondary': deactivated,
        'bg-white': !deactivated,
      })}
      style={{ border: '1px solid #e7e8f2' }}
    >
      <button
        className='bg-transparent position-absolute top-0 bottom-0 left-0 right-0 rounded border-0 w-100'
        style={{ zIndex: 0 }}
        disabled={deactivated}
        onClick={() => onClick?.(card)}
        type='button'
      />

      {withRemove && !deactivated && (
        <div
          className='position-absolute'
          style={{ top: 12, right: 12, zIndex: 9 }}
        >
          {deleteCard.isLoading ? (
            <Spinner
              className='tw-size-3.5 tw-animate-[spin_2s_linear_infinite] tw-text-systemRed-100'
              weight='bold'
            />
          ) : (
            <IconButton
              icon={
                <Trash
                  size={18}
                  weight='fill'
                  className='tw-m-1 tw-fill-disabled'
                />
              }
              className='!tw-h-fit !tw-w-fit !tw-border-0 !tw-bg-white'
              onClick={(e) => {
                e.stopPropagation()
                setShowConfirm(true)
              }}
              type='button'
            />
          )}
        </div>
      )}

      <div
        className='position-relative pointer-events-none d-flex flex-column flex-md-row align-items-start align-items-md-center'
        style={{ gap: '1.5rem' }}
      >
        <div
          className='position-relative'
          style={{ top: isMobile ? undefined : -4 }}
        >
          <img
            className={`${isMobile ? 'mb-md-2' : 'mb-md-0'}`}
            style={{ width: '1.8rem' }}
            src={checked ? bankAccountChecked : bankAccount}
            alt='Wallet icon'
          />
          <div
            className={`currency-flag currency-flag-${card?.currency.toLowerCase()}`}
            style={{ position: 'absolute', bottom: '-8px', right: '-8px' }}
          />
        </div>

        <div
          className='d-flex flex-column align-items-start'
          style={{ gap: '0.25rem' }}
        >
          <h5
            className={cx('mb-0 font-size-16 rp-font-gilroy-B rp-font-bold', {
              'text-primary': checked,
            })}
          >
            {'' + card.holder_name}
          </h5>
          <div className='d-flex' style={{ gap: '0.25rem' }}>
            <div
              className={`${
                checked ? 'text-primary' : 'text-secondary'
              } font-size-14`}
            >
              {card?.currency +
                ' ' +
                t('account ending in') +
                ' ' +
                card?.ends_with}
            </div>

            <button
              className={`pointer-events-auto rp-btn-nostyle d-flex hover:bg-slate-50 p-0.5 justify-content-center align-items-center ${
                checked ? 'text-primary' : 'text-secondary'
              }`}
              type='button'
              onClick={(e) => {
                e.stopPropagation()
                setShowDetails(true)
              }}
              title={t('Show details')}
            >
              <Eye weight='bold' size={18} />
            </button>
          </div>

          {withTag &&
            (isLoading ? (
              <>
                <i className='bx bx-loader bx-spin font-size-14' />
              </>
            ) : (
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  if (!selected) {
                    onSelect?.(e)
                  }
                }}
                className={`btn rounded-sm font-size-10 ${
                  deactivated
                    ? 'btn-dark'
                    : selected
                      ? 'btn-primary'
                      : 'btn-outline-light'
                } py-0 px-1 font-weight-normal pointer-events-auto`}
                disabled={deactivated}
              >
                {deactivated
                  ? t('Deactivated')
                  : selected
                    ? t('Default')
                    : t('Make default')}
              </button>
            ))}
        </div>
      </div>

      {showDetails && (
        <BankDetailsModal
          isOpen={showDetails}
          toggle={() => setShowDetails(false)}
          accountId={card?.id}
        />
      )}

      {showConfirm && (
        <ConfirmationModal
          toggle={() => setShowConfirm(false)}
          isOpen={showConfirm}
          title={t('Delete Bank Account')}
          message={t('Are you sure you want to delete this bank account?')}
          onConfirm={() => {
            setShowConfirm(false)
            deleteCard.startFetch({ id: card?.id })
          }}
        />
      )}
    </div>
  )
}

export default WithdrawMethodCard
