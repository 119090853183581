import { combineReducers } from 'redux'

// Front
import Layout from './layout/reducer'

// Authentication
import Login from './auth/login/reducer'
import Account from './auth/register/reducer'
import Profile from './auth/profile/reducer'
import Payment from './payment/reducer'
import userProfile from './profile/reducer'
import Withdraw from './withdraw/reducer'
import Contract from './contract/reducer'
import xero from './xero/reducer'
import qb from './qb/reducer'
import ns from './ns/reducer'
import hiBob from './hiBob/reducer'
import sapling from './sapling/reducer'
import fresh from './fresh/reducer'
import zoho from './zoho/reducer'
import bamboo from './bamboo/reducer'
import page from './page/reducer'

const appReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  Profile,
  userProfile,
  Payment,
  Withdraw,
  Contract,
  xero,
  qb,
  ns,
  fresh,
  zoho,
  hiBob,
  sapling,
  bamboo,
  page,
})

const RESET_REDUX_STATE = 'RESET_REDUX_STATE'
function rootReducer(state, action) {
  if (action.type === RESET_REDUX_STATE) {
    // Reset the state to the persisted state
    localStorage?.removeItem('persist:root')

    // Reset redux state to the initial state
    return appReducer({}, action)
  }

  return appReducer(state, action)
}
export function resetReduxState() {
  return { type: RESET_REDUX_STATE }
}

export default rootReducer
