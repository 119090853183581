import React, { useState } from 'react'
import toastr from 'toastr'
import { Modal, ModalBody, ModalHeader, UncontrolledAlert } from 'reactstrap'
import {
  getCalendarLinks,
  createCalendarLink,
  deleteCalendarLink,
} from '../../services/api-events'
import { useFetch } from '../../helpers/hooks'
import { ModalCloseButton } from '../../components/Common/modal-close-button'
import {
  ArrowSquareOut,
  Briefcase,
  Cake,
  CalendarBlank,
  Check,
  Confetti,
  Copy,
  Flag,
  LightbulbFilament,
  Link,
  LinkBreak,
  PlugsConnected,
  Repeat,
  SpinnerGap,
  TreePalm,
} from '@phosphor-icons/react'
import { LinkOut } from '../../components/ui/link-out'
import { Button, TooltipTrigger } from 'react-aria-components'
import { TooltipV2 } from '../../components/VerticalLayout/tooltip-v2'

const CALENDAR_OPTIONS = {
  ALL_EVENTS: {
    name: 'All Events',
    description: 'Includes all events listed below in the iCalendar link',
    id: 'all',
    icon: (props) => <CalendarBlank size={24} {...props} />,
  },
  BIRTHDAY: {
    name: 'Birthdays',
    description: 'Includes birthdays from your team',
    id: 'birthday',
    icon: (props) => <Cake size={24} {...props} />,
  },
  WORK_ANNIVERSARY: {
    name: 'Work anniversaries',
    description: 'Includes work anniversaries from your team',
    id: 'work_anniversary',
    icon: (props) => <Confetti size={24} {...props} />,
  },
  TIME_OFF: {
    name: 'Time off',
    description: 'Includes approved time off from your team',
    id: 'time_off',
    icon: (props) => <TreePalm size={24} {...props} />,
  },
  WORKER_NATIONAL_HOLIDAY: {
    name: 'Team national holidays',
    description: "Adds national holidays from your team's location",
    id: 'worker_national_holiday',
    icon: (props) => <Flag size={24} {...props} />,
  },
  COMPANY_NATIONAL_HOLIDAY: {
    name: 'Company national holidays',
    description: 'Includes national holidays from the company location',
    id: 'company_national_holiday',
    icon: (props) => <Briefcase size={24} {...props} />,
  },
}

export default function CreateICalLinksModal({ isOpen, toggle }) {
  const [calendarData, setCalendarData] = useState(
    Object.values(CALENDAR_OPTIONS),
  )
  const { isLoading: isGettingLinks } = useFetch({
    action: getCalendarLinks,
    autoFetch: true,
    onComplete: (data) => {
      setCalendarData((prevCalendarData) =>
        prevCalendarData?.map((prevInfo) => {
          const _calendarData = data?.find(
            (d) =>
              d.event_type === prevInfo.id ||
              (d.event_type === null && prevInfo.id === 'all'),
          )
          if (!_calendarData) return prevInfo
          return { ...prevInfo, data: _calendarData }
        }),
      )
    },
    onError: (err) => toastr.error(err),
  })

  const CALENDAR_LEARN_MORE_LINK =
    'https://help.remotepass.com/en/articles/10494252-how-to-sync-events-with-external-calendars'

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader
        className='!tw-px-7 !tw-pt-7'
        close={<ModalCloseButton toggle={toggle} />}
      >
        <PlugsConnected size={24} />
        <div className='tw-flex tw-items-center tw-gap-1 tw-pt-2 tw-text-xl'>
          Create iCalendar feeds
          <LinkOut href={CALENDAR_LEARN_MORE_LINK} />
        </div>
        <div className='tw-text-sm tw-text-text-80'>
          Generate an iCalendar feed for a specific event type or all events.
          Regenerate anytime if needed.
        </div>
      </ModalHeader>
      <ModalBody className='!tw-p-7'>
        <div className='tw-flex tw-max-h-[530px] tw-flex-col tw-gap-4 tw-overflow-auto'>
          <UncontrolledAlert
            className='!tw-mb-0 !tw-border-surface-30 !tw-bg-systemBlue-20 !tw-p-4'
            fade
          >
            <div className='tw-flex tw-items-start tw-gap-2'>
              <LightbulbFilament
                size={20}
                className='tw-flex-shrink-0 tw-text-systemBlue-110'
              />
              <div>
                <p className='tw-mb-0 tw-text-xs tw-font-semibold tw-text-black'>
                  How it works
                </p>
                <span className='tw-text-xs tw-font-medium tw-text-text-80'>
                  Create a URL, copy it, and add it to your external calendar to
                  keep events synced from the past month up to six months ahead.{' '}
                  <a
                    className='tw-inline-flex tw-items-center tw-gap-0.5'
                    href={CALENDAR_LEARN_MORE_LINK}
                    target='_blank'
                    rel='noreferrer'
                    title='Learn more about iCalendar feeds'
                  >
                    Learn more <ArrowSquareOut />
                  </a>
                </span>
              </div>
            </div>
          </UncontrolledAlert>
          {calendarData?.map((option, i) => (
            <CalendarLinkItem
              name={option.name}
              description={option.description}
              key={option.id || i}
              id={option.id}
              isGettingLinks={isGettingLinks}
              calendarInfo={option}
              setCalendarData={setCalendarData}
            />
          ))}
        </div>
      </ModalBody>
    </Modal>
  )
}

const COPY_LINK_TEXT = 'Copy Link'
const COPIED_TEXT = 'Copied!'

function CalendarLinkItem({ setCalendarData, isGettingLinks, calendarInfo }) {
  const [copyButtonText, setCopyButtonText] = useState(COPY_LINK_TEXT)
  const copyCalendarLink = () => {
    navigator.clipboard.writeText(calendarInfo?.data?.feed_url).then(
      () => {
        setCopyButtonText(COPIED_TEXT)
        setTimeout(() => {
          setCopyButtonText(COPY_LINK_TEXT)
        }, 7000)
      },
      () => {
        toastr.error('Failed to copy link')
      },
    )
  }

  const { startFetch: deleteLink, isLoading: isDeletingLink } = useFetch({
    action: deleteCalendarLink,
    onComplete: (_, body) => {
      setCalendarData((prevCalendarData) =>
        prevCalendarData?.map((prevInfo) => {
          const _calendarData = body?.id === prevInfo.data?.id
          if (_calendarData) {
            const { data, ...restInfo } = prevInfo
            return restInfo
          }
          return prevInfo
        }),
      )
    },
    onError: (err) => toastr.error(err),
  })

  const { startFetch: createLink, isLoading: isCreatingLink } = useFetch({
    action: createCalendarLink,
    onComplete: (data) => {
      setCalendarData((prevCalendarData) =>
        prevCalendarData?.map((calendarData) => {
          if (
            data?.event_type === calendarData.id ||
            (data?.event_type === null && calendarData.id === 'all')
          ) {
            return { ...calendarData, data }
          }
          return calendarData
        }),
      )
    },
    onError: (err) => toastr.error(err),
  })

  const isLoading = isGettingLinks || isCreatingLink || isDeletingLink

  return (
    <>
      {calendarInfo?.data?.feed_url ? (
        <>
          <div className='tw-flex tw-flex-col tw-rounded-md tw-border tw-border-surface-30'>
            <div className='tw-flex tw-items-center tw-gap-4 tw-p-4'>
              <div className='tw-rounded-sm tw-bg-systemGreen-20 tw-p-3'>
                {calendarInfo.icon?.({
                  className: 'tw-text-systemGreen-100',
                })}
              </div>
              <div className='tw-flex tw-flex-1 tw-flex-col'>
                <div className='tw-font-semibold tw-text-black'>
                  {calendarInfo.name}
                </div>
                <span className='tw-text-xs tw-font-normal tw-text-text-80'>
                  {calendarInfo.description}
                </span>
              </div>
              <TooltipTrigger delay={0} closeDelay={0}>
                <Button
                  className='tw-rounded tw-border tw-border-surface-30 tw-p-3'
                  onPress={() => deleteLink({ id: calendarInfo?.data?.id })}
                  aria-label='Disconnect link'
                >
                  <LinkBreak className='tw-text-systemRed-100' size={18} />
                </Button>
                <TooltipV2 className='tw-text-xs' placement='bottom'>
                  Disconnect
                </TooltipV2>
              </TooltipTrigger>
            </div>

            <div className='tw-border-t tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
              <div className='tw-flex tw-items-center tw-gap-1 tw-rounded-md tw-border tw-border-surface-30 tw-p-4 tw-text-xs tw-font-semibold tw-text-black'>
                <span className='tw-w-auto tw-truncate'>
                  {calendarInfo?.data?.feed_url}
                </span>

                {isLoading ? (
                  <>
                    <TooltipTrigger delay={0} closeDelay={0}>
                      <Button
                        className='tw-cursor-progress tw-p-1'
                        aria-label='Loading'
                      >
                        <SpinnerGap
                          className='tw-animate-spin tw-text-primary-100'
                          size={18}
                        />
                      </Button>
                      <TooltipV2 className='tw-text-xs' placement='bottom'>
                        Please wait...
                      </TooltipV2>
                    </TooltipTrigger>
                  </>
                ) : (
                  <>
                    <TooltipTrigger delay={0} closeDelay={0}>
                      <Button
                        className='tw-p-1'
                        onPress={() =>
                          createLink(
                            // eslint-disable-next-line prettier/prettier
                { ...(calendarInfo.id !== 'all') && { event_type: calendarInfo.id } }
                          )
                        }
                        aria-label='Regenerate link'
                      >
                        <Repeat size={18} />
                      </Button>
                      <TooltipV2 className='tw-text-xs' placement='bottom'>
                        Regenerate link
                      </TooltipV2>
                    </TooltipTrigger>
                    <TooltipTrigger delay={0} closeDelay={0}>
                      <Button
                        className='tw-p-1'
                        onPress={copyCalendarLink}
                        aria-label={copyButtonText}
                      >
                        {copyButtonText === COPIED_TEXT ? (
                          <Check className='tw-text-primary-100' size={18} />
                        ) : (
                          <Copy className='tw-text-primary-100' size={18} />
                        )}
                      </Button>
                      <TooltipV2 className='tw-text-xs' placement='bottom'>
                        {copyButtonText}
                      </TooltipV2>
                    </TooltipTrigger>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='tw-flex tw-items-center tw-gap-4 tw-rounded-md tw-border tw-border-surface-30 tw-p-4'>
          <div className='tw-rounded-sm tw-bg-secondary-20 tw-p-3'>
            {calendarInfo.icon?.()}
          </div>
          <div className='tw-flex tw-flex-1 tw-flex-col'>
            <div className='tw-font-semibold tw-text-black'>
              {calendarInfo.name}
            </div>
            <span className='tw-text-xs tw-font-normal tw-text-text-80'>
              {calendarInfo.description}
            </span>
          </div>
          {isLoading ? (
            <>
              <TooltipTrigger delay={0} closeDelay={0}>
                <Button
                  className='tw-cursor-progress tw-rounded tw-border tw-border-surface-30 tw-p-3'
                  aria-label='Loading'
                >
                  <SpinnerGap
                    className='tw-animate-spin tw-text-primary-100'
                    size={18}
                  />
                </Button>
                <TooltipV2 className='tw-text-xs' placement='bottom'>
                  Please wait...
                </TooltipV2>
              </TooltipTrigger>
            </>
          ) : (
            <>
              <TooltipTrigger delay={0} closeDelay={0}>
                <Button
                  className='tw-rounded tw-border tw-border-surface-30 tw-p-3'
                  onPress={() =>
                    createLink(
                      // eslint-disable-next-line prettier/prettier
                { ...(calendarInfo.id !== 'all') && { event_type: calendarInfo.id } }
                    )
                  }
                  aria-label='Create iCalendar link'
                >
                  <Link className='tw-text-primary-100' size={18} />
                </Button>
                <TooltipV2 className='tw-text-xs' placement='bottom'>
                  Create iCalendar link
                </TooltipV2>
              </TooltipTrigger>
            </>
          )}
        </div>
      )}
    </>
  )
}
