import React from 'react'
import { Controller } from 'react-hook-form'

import { cn } from 'ui'
import Toggle from './Toggle'

export function ControlledToggle({
  control,
  name,
  label,
  disabled,
  defaultValue,
  inputClassName,
  labelClassName,
  wrapperClassName,
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { name, onChange, value } }) => {
        return (
          <label
            className={cn(
              'tw-block tw-cursor-pointer tw-transition-colors hover:tw-bg-surface-10',
              wrapperClassName,
            )}
          >
            <Toggle
              name={name}
              check={Boolean(value)}
              change={onChange}
              disabled={disabled}
              className={inputClassName}
              {...props}
            />

            {!label ? null : <div className={labelClassName}>{label}</div>}
          </label>
        )
      }}
    />
  )
}
