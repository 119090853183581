import React from 'react'
import { ArrowRight, CreditCard } from '@phosphor-icons/react'

import { CARD_TYPE_ENUM } from '../utils/card-types-status'
import { PendingPhysicalCard } from '../components/pending-card'
import Button from '../../../components/ui/button'

export function isPhysicalCardPending({ status, type }) {
  return (
    type === CARD_TYPE_ENUM.PHYSICAL && ['pending', 'issued'].includes(status)
  )
}

export default function PhysicalCardStates({ handleRequestCard, cardOrder }) {
  const cardOrderStatus = cardOrder?.status
  const cardOrderType = cardOrder?.card_type

  const physicalCardPending = isPhysicalCardPending({
    status: cardOrderStatus,
    type: cardOrderType,
  })

  return (
    <>
      {physicalCardPending ? (
        <PendingPhysicalCard cardOrder={cardOrder} />
      ) : (
        <RequestPhysicalCard handleRequestCard={handleRequestCard} />
      )}
    </>
  )
}

function RequestPhysicalCard({ handleRequestCard }) {
  return (
    <div className='py-5 px-4 rp-max-w-100 credit-card-content'>
      <CreditCard size={32} weight='duotone' color='white' />

      <p className='font-size-16 mt-2 mb-0 text-white'>
        Request your physical card
      </p>

      <p className='mb-0 text-text-30 font-size-12'>
        Physical cards are accepted globally on the Mastercard Network.
      </p>
      <Button
        style={{ height: 40 }}
        className='p-0'
        onClick={handleRequestCard}
        color='transparent'
        textClassName='text-secondary-50 font-weight-bold'
        iconRight={<ArrowRight size={16} color='var(--secondary-50)' />}
      >
        Request card
      </Button>
    </div>
  )
}
