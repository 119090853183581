import {
  CaretDown,
  Eye,
  Info,
  MagnifyingGlass,
  Sparkle,
} from '@phosphor-icons/react'
import React, { useState } from 'react'
import { cn, ProfileCard } from 'ui'
import Button from '../../../../components/ui/button'
import { differenceInDays, format } from 'date-fns'
import { getCalendarEventIcon } from '../event-item'
import { getEventColorsClasses } from '../../helpers'
import { EmptyState } from '../../../review-center/empty-state'
import { useFetch } from '../../../../helpers/hooks'
import { ignoreInsights } from '../../../../services/api-events'
import { UncontrolledTooltip } from 'reactstrap'

function InsightItem({
  item,
  onShowInsight,
  onHideInsight,
  isShowingOnCalendar,
  ignoreInsight,
  isIgnoringInsights,
  isInsightsLoading,
}) {
  const overlappingLength =
    differenceInDays(
      new Date(item.outerOverlapEndDate),
      new Date(item.outerOverlapStartDate),
    ) + 1

  return (
    <div
      className={cn('tw-mb-2 tw-rounded-md tw-border tw-border-surface-30', {
        'tw-border-2 tw-border-primary tw-bg-primary-10': isShowingOnCalendar,
      })}
    >
      <div className='tw-flex tw-items-center tw-gap-2 tw-border-b tw-border-b-surface-30 tw-p-4 !tw-text-xs'>
        <div
          className={cn(
            'tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-full tw-bg-systemRed-100 tw-text-white',
            {
              'tw-bg-[#FF4539]': overlappingLength >= 10, // we don't have orange :/
              'tw-bg-systemRed-100':
                overlappingLength < 10 && overlappingLength >= 6,
              'tw-bg-systemGold-100': overlappingLength < 6,
            },
          )}
        >
          {overlappingLength}
        </div>
        <div>Overlapping days</div>
      </div>
      <div className='tw-flex tw-flex-col'>
        {item.events?.map((event, index) => {
          const dateString = ` ${format(new Date(event.startDate), 'MM/dd/yy')} - ${format(new Date(event.endDate), 'MM/dd/yy')}`
          return (
            <ProfileCard
              key={index}
              className='tw-border-b tw-border-b-surface-30 tw-p-4'
              titleClassName='!tw-text-xs'
              descriptionClassName='!tw-text-[10px]'
              name={event.worker?.name}
              // photo={event.worker?.avatar}
              title={event.worker?.name}
              description={dateString}
              customFlag={
                <div
                  className={cn(
                    getEventColorsClasses(event),
                    'tw-absolute tw-bottom-[-4px] tw-right-[-4px] tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-white',
                  )}
                >
                  {getCalendarEventIcon(event, 10)}
                </div>
              }
            />
          )
        })}
      </div>
      <div className='tw-flex tw-items-center tw-justify-between tw-gap-2 tw-p-4'>
        <Button
          color='transparent'
          disabled={isIgnoringInsights || isInsightsLoading}
          onClick={() => ignoreInsight(item.id)}
          className='!tw-p-0 !tw-text-xs !tw-font-normal'
        >
          Ignore
        </Button>
        <Button
          color='transparent'
          className='!tw-p-0 !tw-text-xs !tw-font-normal !tw-text-primary'
          onClick={() => {
            if (isShowingOnCalendar) {
              onHideInsight()
            } else {
              onShowInsight({
                workerIds: item.events.map((ev) => ev.entityId),
                startDate: item.outerOverlapStartDate,
                insightLength: overlappingLength,
                id: item.id,
              })
            }
          }}
          iconRight={<Eye size={16} />}
        >
          {isShowingOnCalendar ? 'Hide' : 'Show in calendar'}
        </Button>
      </div>
    </div>
  )
}

function InsightsPanel({
  insights,
  onShowInsight,
  onHideInsight,
  insightShowing,
  moreInsights,
  refreshInsights,
  isInsightsLoading,
}) {
  const [showMoreInsights, setShowMoreInsights] = useState(false)

  const { startFetch: ignoreInsight, isLoading: isIgnoringInsights } = useFetch(
    {
      action: ignoreInsights,
      onComplete: () => {
        refreshInsights?.()
      },
    },
  )

  return (
    <div
      className={cn(
        'tw-h-full tw-max-h-[calc(100vh)] tw-w-[256px] tw-overflow-auto tw-bg-white',
      )}
    >
      <div className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-b-surface-30 tw-p-4'>
        <div className='tw-flex tw-items-center tw-gap-1'>
          <Sparkle size={20} />
          <span>Insights</span>
          <div id='insights-info-icon'>
            <Info size={16} />
          </div>
          <UncontrolledTooltip target='insights-info-icon'>
            Any overlapping events for at least 2 days
          </UncontrolledTooltip>
        </div>
        <Button
          onClick={() => {
            ignoreInsight({
              insight_ids: insights.map((insight) => insight.id),
            })
          }}
          disabled={isIgnoringInsights || isInsightsLoading}
          color='transparent'
          className='!tw-p-0 !tw-font-normal'
        >
          Ignore All ({insights.length})
        </Button>
      </div>
      <div className='tw-p-4'>
        {insights?.length ? (
          insights.map((item) => (
            <InsightItem
              onShowInsight={onShowInsight}
              onHideInsight={onHideInsight}
              isShowingOnCalendar={insightShowing?.id === item.id}
              isInsightsLoading={isInsightsLoading}
              ignoreInsight={(id) => ignoreInsight({ insight_ids: [id] })}
              isIgnoringInsights={isIgnoringInsights}
              key={item.id}
              item={item}
            />
          ))
        ) : (
          <EmptyState
            className='!tw-p-0 tw-shadow-none'
            title={<div className='tw-text-sm'>No insights found</div>}
            textElement={
              <div className='tw-text-xs tw-text-text-60'>
                We couldn’t find any insight in the current period.
              </div>
            }
            pictureElement={
              <MagnifyingGlass className='tw-text-primary' size={40} />
            }
          />
        )}
        {moreInsights?.length ? (
          <button
            onClick={() => setShowMoreInsights((prev) => !prev)}
            className='tw-my-4 tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-border-t tw-border-t-surface-30 tw-py-4 tw-text-left'
          >
            <div>
              <div className=''>More insights</div>
              <div className='tw-text-xs tw-text-text-60'>
                Found in the next three months
              </div>
            </div>
            <div>
              <CaretDown
                size={24}
                className={cn('tw-text-primary tw-transition-transform', {
                  'tw-rotate-180': showMoreInsights,
                })}
              />
            </div>
          </button>
        ) : null}
        {showMoreInsights &&
          moreInsights?.map((item) => (
            <InsightItem
              onShowInsight={onShowInsight}
              onHideInsight={onHideInsight}
              isShowingOnCalendar={insightShowing?.id === item.id}
              isInsightsLoading={isInsightsLoading}
              ignoreInsight={(id) => ignoreInsight({ insight_ids: [id] })}
              refreshInsights={refreshInsights}
              key={item.id}
              item={item}
            />
          ))}
      </div>
    </div>
  )
}

export default InsightsPanel
