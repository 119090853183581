import React from 'react'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Placeholder from '@tiptap/extension-placeholder'
import { useEditor, EditorContent, BubbleMenu } from '@tiptap/react'
import { cn } from 'ui'
import { EditorActions } from './editor-actions'
import { PlaceholderExtension } from './placeholder-extension'
import InputFeedback from '../../../../../../components/ui/input-feedback'
import { getInputErrorMessage } from '../../../../../../components/Forms/get-input-error-message'
import { ActionPlaceholders } from '../action-placeholders'

export function ActionEditor(props) {
  const {
    error,
    value = '',
    items,
    isLoading,
    onChange,
    actionType,
    placeholder,
  } = props

  const editor = useEditor({
    onUpdate: ({ editor }) => {
      onChange(editor.isEmpty ? '' : editor.getHTML())
    },
    extensions: [
      Underline,
      PlaceholderExtension,
      Placeholder.configure({ placeholder }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
    ],
    editorProps: {
      attributes: {
        class:
          '[&_.is-editor-empty]:before:!tw-content-[attr(data-placeholder)] [&_.is-editor-empty]:!tw-text-text-40 !tw-min-h-[200px] !tw-m-5 focus:!tw-outline-none [&>ul]:!tw-m-5 [&>ul]:!tw-list-disc [&>ol]:!tw-m-5 [&>ol]:!tw-list-decimal',
      },
    },
    content: value,
  })
  return (
    <>
      <div
        className={cn(
          'tw-rounded tw-border tw-border-surface-130 tw-transition tw-duration-150',
          error && '!tw-border-systemRed',
          'focus-within:tw-border-[#bbbdd6] focus-within:tw-ring-2 focus-within:tw-ring-[#c6c8e069]',
        )}
      >
        {editor ? (
          <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
            <div className='tw-rounded-xl tw-bg-white tw-p-1 tw-shadow'>
              <EditorActions editor={editor} actionType={actionType} />
            </div>
          </BubbleMenu>
        ) : null}
        <EditorContent editor={editor} />

        <EditorActions editor={editor} actionType={actionType} />
      </div>
      {error ? (
        <InputFeedback>{getInputErrorMessage(error)}</InputFeedback>
      ) : null}

      <ActionPlaceholders
        items={items}
        isLoading={isLoading}
        onInsert={({ key, title }) =>
          editor.chain().focus().insertPlaceholder(key, title).run()
        }
      />
    </>
  )
}
