import { yupResolver } from '@hookform/resolvers/yup'
import {
  BellSimpleSlash,
  DownloadSimple,
  Info,
  ShieldCheck,
  ShieldWarning,
} from '@phosphor-icons/react'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import axios from 'axios'
import cx from 'classnames'
import format from 'date-fns/format'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import {
  Badge,
  ButtonDropdown,
  Card,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Popover,
  Row,
  Spinner,
  Table,
  UncontrolledTooltip,
} from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import ModalFooter from 'reactstrap/lib/ModalFooter'
import toastr from 'toastr'
import { ActionsDropdown, cn } from 'ui'
import * as yup from 'yup'

import archiveIconHover from '../../../../assets/images/archive.svg'
import archiveIcon from '../../../../assets/images/archiveBlack.svg'
import unArchiveIcon from '../../../../assets/images/unarchive.svg'
import unArchiveIconHover from '../../../../assets/images/unarchiveHover.svg'
import { ModalCloseButton } from '../../../../components/Common/modal-close-button'
import customStyles from '../../../../components/Common/react-select-custom-styles'
import ControlledCurrencyInput from '../../../../components/ControlledCurrencyInput'
import ControlledDatePicker from '../../../../components/ControlledDatePicker'
import ControlledInput from '../../../../components/ControlledInput'
import ControlledSelect from '../../../../components/ControlledSelect'
import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import Toggle from '../../../../components/Forms/Toggle/Toggle'
import SearchBar from '../../../../components/SearchBar'
import BadgeX from '../../../../components/Table/BadgeX'
import { StyledH5, StyledH6, StyledP } from '../../../../components/Typo'
import ControlledCheckbox from '../../../../components/controlled-checkbox'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import Pagination from '../../../../components/ui/pagination'
import { UserComments } from '../../../../components/user-comments/user-comments'
import UserFlagging from '../../../../components/userFlag'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import { COINBASE } from '../../../../core/config/payment-methods'
import { storage } from '../../../../helpers/config'
import { useFetch } from '../../../../helpers/hooks'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import { useUrlState } from '../../../../helpers/hooks/use-url-state'
import {
  adminDownloadMultiPaymentFile,
  adminDownloadProcessingTransactionReceipt,
  adminDownloadTransactionWPSFile,
  archiveTransaction,
  confirmTransaction,
  createZendeskTicket,
  downloadProviderReceipt,
  downloadWithdrawalReceipt,
  exportWithdraw,
  getAdminCompanies,
  getPaymentReminderHistory,
  getTransactionList,
  makeWorkTransfer,
  manualProcessTransaction,
  multipleProcessWithdraw,
  processWithdraw,
  revertPaymentTransaction,
  revertTransaction,
  reviewTransaction,
  sendPaymentReminder,
  setPreferredProvider,
  unProcessWithdraw as unProcessWithdrawAction,
  updateReceivedStatus,
} from '../../../../services/api'
import capitalizeFirstLetter from '../../../../utils/capitalize-first-letter'
import openFile from '../../../../utils/file/open'
import openFileV2 from '../../../../utils/file/open-v2'
import { getCurrencyFormatter } from '../../../../utils/formatters/currency'
import {
  weirdCurrencies,
  weirdCurrenciesFormatter,
} from '../../../../utils/formatters/currency-coinbase'
import { ImageIcon } from '../../../Contract/ContractList/ContractList'
import { downloadFile } from '../../../Contract/utils/helpers'
import { TransactionStatusBadge } from '../../../Transactions'
import { DownloadStatementModal } from '../../../Transactions/download-statement-modal'
import ContractRef from '../../components/ContractRef'
import { BurgerDetails } from '../Users/users'
import { getFullName } from '../balances/balances-list'
import { ContractIDsCell } from '../cards/contract-ids-cell'
import ExportInsurance from './ExportInsurance'
import ExportReportModal from './ExportReportModal'
import ExportTrxModal from './ExportTrxModal'
import { ManualProcessModal } from './ManualProcessModal'
import { RevertTrxModal } from './RevertTrxModal'
import PreferredProviderModal from './preferred-provider-modal'
import { ScreeningDetails, getStatus } from './screening-details'
import TransactionDetailsSider from './transaction-details-sider'
import UnProcessModal from './un-process-modal'
import { labelize } from './util.labelize'
import './withdrawal-payments-button.css'

const TAB_KEY = {
  PAYMENTS: 'payments',
  WITHDRAWS: 'withdraws',
  REFUNDS: 'refunds',
  PENDING_COMPLIANCE_REVIEW: 'pending-compliance-review',
  INSURANCES: 'insurances',
  ARCHIVED: 'archived',
  REVERTED_TRANSACTIONS: 'reverted-transactions',
  CONTRIBUTIONS: 'contributions',
}

const tabsOptions = [
  { key: TAB_KEY.PAYMENTS, label: 'Payments', value: '1' },
  { key: TAB_KEY.WITHDRAWS, label: 'Withdraws', value: '2' },
  { key: TAB_KEY.REFUNDS, label: 'Refunds', value: '6', tabKey: 'refunds' },
  {
    key: TAB_KEY.PENDING_COMPLIANCE_REVIEW,
    label: 'Pending compliance review',
    value: '7',
    tabKey: 'withdraws',
  },
  { key: TAB_KEY.INSURANCES, label: 'Insurances', value: '3' },
  { key: TAB_KEY.ARCHIVED, label: 'Archived', value: '4' },
  {
    key: TAB_KEY.REVERTED_TRANSACTIONS,
    label: 'Reverted transactions',
    value: '5',
    tabKey: 'reverted_withdraws',
  },
  {
    key: TAB_KEY.CONTRIBUTIONS,
    label: 'UAE payments',
    value: '5',
    tabKey: 'uae_payrolls',
  },
]

function statuses(tabValue) {
  return [
    { label: 'Processing', value: 'processing' },
    // Withdraws tab only
    [TAB_KEY.WITHDRAWS].includes(tabValue) && {
      label: 'Processed',
      value: 'processed',
    },
    { label: 'Confirmed', value: 'confirmed' },
  ].filter(Boolean)
}
const receivedStatuses = [
  { label: 'Not Received', value: 'not_received' },
  { label: 'Received', value: 'received' },
]

const paymentMethodOptions = [
  { value: 2, label: 'Paypal' },
  { value: 3, label: 'Bank Transfer' },
  { value: 6, label: 'CashPoint' },
  { value: 7, label: 'Payoneer' },
  { value: 9, label: 'Coinbase' },
  { value: 17, label: 'Paysend' },
]

const searchBarPlaceholder = 'Search by user name, transaction ID, or email'
const defaultCompany = { label: 'Select Company', value: null }

const PROVIDER = {
  WISE: 'wise',
  EBURY: 'ebury',
  TRANSFERMATE: 'transfermate',
  MATCHMOVE: 'matchmove',
  PAYSEND: 'paysend',
  VERTO: 'verto',
  PAYPAL: 'paypal',
  COINBASE: 'coinbase',
  PAYONEER: 'payoneer',
  PAYONEER_BT: 'payoneer_bt',
}

const BULK_PROCESS_PROVIDERS = [
  PROVIDER.EBURY,
  PROVIDER.WISE,
  PROVIDER.TRANSFERMATE,
  PROVIDER.PAYONEER_BT,
]

function getTrxFormatter(order) {
  const code = order?.currency?.code

  const options = {
    maximumSignificantDigits: order?.provider === COINBASE.key ? 6 : undefined,
  }

  const formatter = weirdCurrencies.includes(code)
    ? weirdCurrenciesFormatter(code, order?.provider)
    : getCurrencyFormatter(code, undefined, options)

  return formatter
}

function getTrxAmountFormatter({ code, provider }) {
  const formatter = weirdCurrencies.includes(code)
    ? weirdCurrenciesFormatter(
        code,
        provider === 'coinbase' ? COINBASE.key : undefined,
      )
    : getCurrencyFormatter(code)

  return formatter
}

const Transactions = () => {
  const [activePage, setActivePage] = useState(1)
  const [tab, setTab] = useUrlState({
    name: 'tab',
    defaultValue: tabsOptions[0].key,
  })
  const [query, setQuery] = useState(null)

  const [selectedUser, setSelectedUser] = useState(null)
  const [show, setShow] = useState(false)
  const [trxDetail, setTrxDetail] = useState(null)
  const [showExportModal, setShowExportModal] = useState(false)
  const [showExportPayment, setShowExportPayment] = useState(false)
  const [expanded, setExpanded] = useState(-1)
  const [date, setDate] = useState(null)
  const [archiving, setArchiving] = useState(-1)
  const [exporting, setExporting] = useState(null)
  const tabStatuses = statuses(tab)
  const [status, setStatus] = useState(tabStatuses[0])
  const [provider, setProvider] = useState(null)
  const [receivedStatus, setReceivedStatus] = useState(receivedStatuses[0])
  const [screeningDetails, setScreeningDetails] = useState(null)

  const [confirmTransactionModal, setConfirmTransactionModal] = useState({})

  const isWithdrawTab = tab === TAB_KEY.WITHDRAWS

  function openConfirmTransactionModal(
    transaction,
    { isWithdraws, isMarkAsReceived },
  ) {
    setConfirmTransactionModal({
      isOpen: true,
      transaction,
      isWithdraws,
      isMarkAsReceived,
    })
  }
  function closeConfirmTransactionModal() {
    setConfirmTransactionModal((data) => ({ ...data, isOpen: false }))
  }

  const [toRevert, setToRevert] = useState(null)
  const [toManualProcess, setToManualProcess] = useState(null)
  const [isRevertOpen, setIsRevertOpen] = useState(false)
  const [isManualProcessOpen, setIsManualProcessOpen] = useState(false)
  const [isRevertPaymentOpen, setIsRevertPaymentOpen] = useState(false)
  const [isPreferredProvider, setIsPreferredProvider] = useState(null)
  const [unProcessModal, setUnProcessModal] = useState(null)
  const [showMultipleConfirm, setShowMultipleConfirm] = useState(false)

  const [company, setCompany] = useState(defaultCompany)
  const [paymentMethodFilter, setPaymentMethodFilter] = useState(null)
  const [trxData, setTrxData] = useState(null)
  const [selectedWithdraws, setSelectedWithdraws] = useState([])

  const hasEditPermission = useHasPermission(
    ADMIN_PERMISSIONS.MANAGE_TRANSACTIONS,
  )

  const {
    isLoading: isTrxLoading,
    paginator: trxPaginator,
    startFetch: startTrxFetch,
  } = useFetch({
    autoFetch: false,
    initResult: null,
    action: getTransactionList,
    withAdminAccess: true,
    onComplete: (data) =>
      setTrxData(data.map((dt) => ({ ...dt, selected: false }))),
    onError: (err) => {
      toastr.error(err)
    },
  })

  const { data: companiesList } = useFetch({
    autoFetch: true,
    action: getAdminCompanies,
    withAdminAccess: true,
  })

  const confirm = useFetch({
    autoFetch: false,
    initResult: null,
    action: confirmTransaction,
    withAdminAccess: true,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Error confirming transaction', 'Error')
      } else {
        closeConfirmTransactionModal()

        applyFilter(null, true)
      }
    },
    onError: (err) => {
      toastr.error(err || 'Error confirming transaction', 'Error')
    },
  })
  const revert = useFetch({
    autoFetch: false,
    initResult: null,
    action: revertTransaction,
    withAdminAccess: true,
    onComplete: () => {
      setIsRevertOpen(false)
      setToRevert(null)
      applyFilter(null, true)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const manualProcess = useFetch({
    action: manualProcessTransaction,
    withAdminAccess: true,
    onComplete: () => {
      setToManualProcess(null)
      setIsManualProcessOpen(false)
      applyFilter(null, true)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const revertPayment = useFetch({
    autoFetch: false,
    initResult: null,
    action: revertPaymentTransaction,
    withAdminAccess: true,
    onComplete: () => {
      setIsRevertPaymentOpen(false)
      setToRevert(null)
      applyFilter(null, true)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const review = useFetch({
    autoFetch: false,
    initResult: null,
    action: reviewTransaction,
    withAdminAccess: true,
    onComplete: () => {
      applyFilter(null, true)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const preferredProvider = useFetch({
    action: setPreferredProvider,
    withAdminAccess: true,
    onComplete: () => {
      setIsPreferredProvider(null)
      applyFilter(null, true)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const { startFetch: downloadMultiPaymentFile } = useFetch({
    action: adminDownloadMultiPaymentFile,
    withAdminAccess: true,
    onComplete: (data, body) => {
      openFileV2(data, {
        download: true,
        isDataUrl: false,
        name: `${body?.transaction_id}_${body?.payroll_type}.csv`,
      })
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const { startFetch: downloadWPSFile } = useFetch({
    action: adminDownloadTransactionWPSFile,
    withAdminAccess: true,
    onComplete: (data, body) => {
      openFileV2(data, {
        download: true,
        isDataUrl: false,
        name: `${body?.transaction_id}_wps.csv`,
      })
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const handleConfirmRevert = (reason, reasonCustom, archiveBank) => {
    const body = {
      transaction_id: toRevert?.transaction_id,
      reason,
      archive_bank_account: archiveBank,
    }
    if (reason === 'custom') {
      body.reason_custom = reasonCustom
    }
    revert.startFetch(body)
  }
  const handleConfirmManualProcess = (data) => {
    manualProcess.startFetch({
      ...data,
      transaction_id: toManualProcess?.transaction_id,
    })
  }
  const handleConfirmRevertPayment = () => {
    const body = {
      transaction_id: toRevert?.transaction_id,
    }
    revertPayment.startFetch(body)
  }
  const handleReview = (trx) => {
    const body = {
      transaction_id: trx?.transaction_id,
      under_review: !trx?.under_review,
    }
    review.startFetch(body)
  }
  function handleSetPreferredProvider(newProvider) {
    preferredProvider.startFetch({
      transaction_id: isPreferredProvider?.transaction_id,
      preferred_processor: newProvider,
    })
  }

  const { startFetch: unProcessWithdraw, isLoading: unProcessing } = useFetch({
    action: unProcessWithdrawAction,
    withAdminAccess: true,
    onComplete: () => {
      setUnProcessModal(null)
      applyFilter(null, true)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  function handleUnProcessWithdraw(id, reason) {
    unProcessWithdraw({ transaction_id: id, reason })
  }

  const selectedTab = tabsOptions.find(({ key }) => key === tab)

  const applyFilter = useCallback(
    (cancelToken) => {
      const body = {
        page: activePage,
      }
      if (tab === TAB_KEY.PAYMENTS && company?.value) {
        body.company_id = company?.value
      }
      if (tab === TAB_KEY.ARCHIVED) {
        body.archived = 1
      } else {
        if (tab !== TAB_KEY.REVERTED_TRANSACTIONS) {
          body.archived = 0
        }
        body.tab = selectedTab?.tabKey ?? selectedTab?.label.toLowerCase()
      }
      if (date) {
        body.month = new Date(date).getMonth() + 1
        body.year = new Date(date).getFullYear()
      }
      // limit how many requests we send to the server
      if (query && query?.length > 2) {
        body.search = query
      }
      if (status?.value !== -1 && tab !== TAB_KEY.REVERTED_TRANSACTIONS) {
        body.status = status?.value
      }
      if (provider?.value) {
        body.provider = provider?.value
      }
      if (
        receivedStatuses.map((o) => o.value).includes(receivedStatus?.value) &&
        tab === TAB_KEY.PAYMENTS
      ) {
        body.is_received = receivedStatus?.value === 'received' ? 1 : 0
      }
      if (tab === TAB_KEY.WITHDRAWS) {
        setSelectedWithdraws([])
      }
      if (tab === TAB_KEY.WITHDRAWS && paymentMethodFilter?.value) {
        body.payment_method_id = paymentMethodFilter?.value
      }
      if (tab === TAB_KEY.PENDING_COMPLIANCE_REVIEW) {
        body.screening_status = 'review'
        body.status = 'pending'
        delete body.provider
        delete body.month
        delete body.year
      }
      // const loading = !date && !query && state?.value === -1 && !noLoading
      startTrxFetch(body, true, cancelToken)
    },
    // disabled exhaustive-deps because startTrxFetch is not memoized
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activePage,
      tab,
      date,
      query,
      status?.value,
      provider?.value,
      receivedStatus?.value,
      company?.value,
      paymentMethodFilter?.value,
    ],
  )

  useEffect(() => {
    let cancel
    // eslint-disable-next-line no-return-assign
    applyFilter(new axios.CancelToken((c) => (cancel = c)))
    return () => {
      if (cancel) cancel()
    }
  }, [date, query, tab, status, activePage, applyFilter])

  const archive = useFetch({
    action: archiveTransaction,
    withAdminAccess: true,
    onComplete: () => {
      setArchiving(-1)
      applyFilter(null, true)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const exportTransaction = useFetch({
    action: exportWithdraw,
    withAdminAccess: true,
    onComplete: (data, body) => {
      openFile(data, body?.transaction_id, '_transactions.xlsx', () => {
        setExporting(null)
      })
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const multipleProcess = useFetch({
    action: multipleProcessWithdraw,
    withAdminAccess: true,
    onComplete: () => applyFilter(null, true),
    onError: (err) => {
      toastr.error(err)
    },
  })
  const handleSearch = (query) => {
    setActivePage(1)
    setQuery(query)
  }

  const handleTrxChecked = (data) => {
    const newData = [...trxData]
    const index = newData.findIndex(
      (e) => e.transaction_id === data.transaction_id,
    )
    newData[index].selected = !newData[index].selected
    setTrxData(newData)
    if (tab === TAB_KEY.WITHDRAWS) {
      const newWithdraws = [...selectedWithdraws]
      if (
        newWithdraws.filter((e) => e.transaction_id === data.transaction_id)
          .length > 0
      ) {
        const withdrawIndex = newWithdraws.findIndex(
          (e) => e.transaction_id === data.transaction_id,
        )
        newWithdraws.splice(withdrawIndex, 1)
        setSelectedWithdraws(newWithdraws)
      } else {
        newWithdraws.push({
          transaction_id: data.transaction_id,
          amount:
            data?.provider === 'coinbase' ? data?.base_amount : data?.total,
          source: data?.base_currency?.code,
          target: data?.currency?.code,
          provider: data?.provider,
        })
        setSelectedWithdraws(newWithdraws)
      }
    }
  }
  function handleClearFilter() {
    setActivePage(1)
    setQuery('')
    setDate(null)
    setStatus(tabStatuses[0])
    setCompany(defaultCompany)
    setPaymentMethodFilter(null)
    setReceivedStatus(receivedStatuses[0])
    setProvider(null)
  }
  const handleMultipleProcessing = () => {
    setShowMultipleConfirm(false)
    const body = {
      transactions: selectedWithdraws,
    }
    multipleProcess.startFetch(body)
  }

  const handleUserClick = useCallback((data) => {
    setSelectedUser({
      id: data?.client?.id,
      selectedBankId: data?.bank_account_details?.account_id,
    })
    setShow(true)
  }, [])

  const revertedColumns = useMemo(
    () => [
      {
        Header: '',
        headerClassName: 'sticky-col first-col',
        className: 'sticky-col first-col',
        Cell: () => '',
      },
      {
        Header: 'TRX ID',
        headerClassName: 'sticky-col second-col',
        className: 'sticky-col second-col',
        accessor: 'transaction_ref',
      },
      {
        Header: 'Contracts ID',
        Cell: ({ rowData }) => {
          return (
            <div
              className='d-flex flex-column overflow-auto'
              style={{ maxHeight: '7ch' }}
            >
              {rowData?.contracts.map((id) => {
                return (
                  <Link
                    key={id}
                    target='_blank'
                    to={`contract-detail?id=${id}`}
                  >
                    {id}
                  </Link>
                )
              })}
            </div>
          )
        },
      },
      {
        Header: 'Name',
        headerClassName: 'sticky-col third-col',
        className: 'sticky-col third-col text-truncate',
        Cell: ({ rowData }) => {
          return (
            <UserFullName
              prefillComment={prefillComment(rowData, tab)}
              data={rowData}
              onCompleteAction={() => {
                applyFilter(null, true)
              }}
              onUserClick={() => handleUserClick(rowData)}
            />
          )
        },
      },
      {
        Header: 'Account holder',
        headerClassName: 'sticky-col forth-col',
        className: 'sticky-col forth-col',
        Cell: ({ rowData }) => {
          return (
            <>
              <div
                className='text-truncate d-inline-block'
                style={{ maxWidth: 'calc(100% - 20px)' }}
                title={rowData.bank_account_details?.accountHolderName}
              >
                {rowData.bank_account_details?.accountHolderName}
              </div>

              <VerifiedIcon
                className='tw-absolute tw-top-1/2 tw-inline-block -tw-translate-y-[62%] tw-translate-x-1'
                status={
                  rowData?.account_status === 'verified'
                    ? VERIFIED_BADGE_STATUS.verified
                    : rowData?.account_status === 'unverified'
                      ? VERIFIED_BADGE_STATUS.unverified
                      : rowData?.account_status === 'deactivated'
                        ? VERIFIED_BADGE_STATUS.deactivated
                        : ''
                }
              />
            </>
          )
        },
      },
      { Header: 'Type', accessor: 'type.name' },
      { Header: 'Method', accessor: 'method.name' },
      { Header: 'Email', accessor: 'client.email' },
      { Header: 'Company name', accessor: 'company_name' },
      {
        Header: 'Amount',
        Cell: ({ rowData }) => {
          const formatter = getTrxAmountFormatter({
            code: rowData?.currency?.code,
            provider:
              rowData?.method?.code === COINBASE.key ? COINBASE.key : undefined,
          })

          return formatter.format(rowData.amount)
        },
      },
      { Header: 'TRX date', accessor: 'created_at' },
      // { Header: 'Confirmed at', accessor: 'confirmed_at' },
      {
        Header: 'Source',
        Cell: ({ rowData }) => {
          const sourceFormatter = getCurrencyFormatter(
            rowData?.base_currency?.code,
          )
          return sourceFormatter.format(rowData.base_amount)
        },
      },
      {
        Header: 'Target',
        Cell: ({ rowData }) => {
          const formatter = getTrxAmountFormatter({
            code: rowData?.currency?.code,
            provider:
              rowData?.method?.code === COINBASE.key ? COINBASE.key : undefined,
          })
          const usdFormatter = getCurrencyFormatter()

          return (
            <>
              {rowData.total_usd && (
                <span className='font-weight-semibold'>{`(${usdFormatter.format(
                  rowData.total_usd,
                )})`}</span>
              )}{' '}
              {formatter.format(Number(rowData.total))}
            </>
          )
        },
      },
      {
        Header: 'Rate',
        accessor: 'rate',
      },
      {
        Header: 'ETA',
        accessor: 'eta',
      },
      {
        Header: 'Fee',
        Cell: ({ rowData }) => {
          const formatter = getTrxAmountFormatter({
            code: rowData?.currency?.code,
            provider:
              rowData?.method?.code === COINBASE.key ? COINBASE.key : undefined,
          })

          return formatter.format(Number(rowData?.fee))
        },
      },
      {
        Header: 'Provider',
        accessor: 'provider',
      },
      {
        Header: 'Reason',
        accessor: 'reason',
      },
      {
        Header: 'Reverted by',
        accessor: 'reverted_by',
      },
    ],
    [applyFilter, handleUserClick],
  )

  function getPayrollDownloadType(payrollType) {
    switch (payrollType) {
      case 'GCC':
        return 'gcc_multi_payment_file'
      case 'WPS':
        return 'wps_multi_payment_file'
      case 'DEWS':
        return 'dews_multi_payment_file'
      default:
        return ''
    }
  }

  const contributionColumns = useMemo(
    () => [
      {
        Header: 'TRX#',
        accessor: 'transaction_ref',
      },
      {
        Header: 'Company',
        accessor: 'company_name',
      },
      {
        Header: 'Name',
        headerClassName: 'tw-min-w-[170px]',
        Cell: ({ rowData }) => {
          return (
            <UserFullName
              prefillComment={prefillComment(rowData, tab)}
              data={rowData}
              onCompleteAction={() => {
                applyFilter(null, true)
              }}
              onUserClick={() => handleUserClick(rowData)}
            />
          )
        },
      },
      {
        Header: 'Type',
        accessor: 'payroll_type',
      },
      {
        Header: '# of employees',
        accessor: 'works',
        Cell: ({ rowData }) => {
          return rowData.works?.length ?? 0
        },
      },
      {
        Header: 'Total amount',
        accessor: 'total',
        Cell: ({ rowData, cellData }) => {
          // Hassan requested to use works currency
          const formatter = getCurrencyFormatter(
            rowData.works?.[0]?.currency?.code,
          )
          return formatter.format(cellData)
        },
      },
      {
        Header: 'Created on',
        accessor: 'created_at',
        Cell: ({ rowData }) => {
          return format(new Date(rowData.created_at), 'dd/MM/yyyy')
        },
      },
      {
        Header: 'Transferred on',
        accessor: 'confirmed_at',
        Cell: ({ rowData }) => {
          return format(new Date(rowData.created_at), 'dd/MM/yyyy')
        },
      },
      {
        Header: 'TRX Status',
        accessor: 'status',
        Cell: ({ rowData }) => {
          return <TransactionStatusBadge order={rowData} />
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ rowData, colData }) => {
          const worksCount = rowData.works?.length ?? 0
          const confirmedWorks = rowData.works?.filter(
            (work) => work.transferred,
          ).length
          return worksCount ? (
            <div
              className={cn(
                'tw-font-bold',
                confirmedWorks === worksCount
                  ? 'tw-text-systemGreen'
                  : 'tw-text-systemGold',
              )}
            >
              {confirmedWorks}/{worksCount} Transferred
            </div>
          ) : (
            colData
          )
        },
      },
      {
        Header: 'Actions',
        accessor: '.',
        Cell: ({ rowData }) => (
          <div className='tw-flex tw-gap-2'>
            {rowData.payroll_type === 'WPS' ? (
              <>
                <Button
                  color='primary'
                  size='sm'
                  onClick={() =>
                    downloadWPSFile({
                      transaction_id: rowData.transaction_id,
                    })
                  }
                >
                  WPS File
                </Button>
              </>
            ) : null}
            <Button
              color='primary'
              size='sm'
              onClick={() =>
                downloadMultiPaymentFile({
                  transaction_id: rowData.transaction_id,
                  payroll_type: getPayrollDownloadType(rowData.payroll_type),
                })
              }
            >
              Multi-payment File
            </Button>
          </div>
        ),
      },
    ],
    [applyFilter],
  )

  const prefillComment = useCallback((order, tab) => {
    return [TAB_KEY.PAYMENTS, TAB_KEY.WITHDRAWS].includes(tab)
      ? `${order?.transaction_ref} `
      : null
  }, [])
  const { startFetch: makeTransactionReceived, isLoading: markingAsReceived } =
    useFetch({
      action: updateReceivedStatus,
      withAdminAccess: true,
      onComplete: () => {
        toastr.success('Transaction marked as received')

        closeConfirmTransactionModal()
        applyFilter(null, true)
      },
      onError: (err) => {
        toastr.error(err)
      },
    })
  const [transferringWorkID, setTransferringWorkID] = useState(null)
  const { startFetch: makeTransfer } = useFetch({
    action: makeWorkTransfer,
    withAdminAccess: true,
    onComplete: () => {
      applyFilter(null, true)
      setTransferringWorkID(null)
    },
    onError: (err) => {
      toastr.error(err)
      setTransferringWorkID(null)
    },
  })
  function handleConfirmTransaction(body) {
    if (confirmTransactionModal.isMarkAsReceived) {
      makeTransactionReceived(body)
    } else {
      confirm.startFetch(body)
    }
  }

  return (
    <div className='page-content'>
      <BurgerDetails
        id={selectedUser?.id}
        selectedBankId={selectedUser?.selectedBankId}
        hide={() => {
          setShow(false)
          setSelectedUser({})
        }}
        show={show}
      />

      <TransactionDetailsSider
        transaction={trxDetail}
        hide={() => setTrxDetail(null)}
        isOpen={!!trxDetail}
      />

      <ScreeningDetails
        isOpen={!!screeningDetails}
        hide={() => {
          setScreeningDetails(false)
          setSelectedUser({})
        }}
        id={screeningDetails?.client?.id}
        onAction={applyFilter}
      />

      <Container fluid className='p-0 m-0'>
        <Row className='justify-content-end mb-4 mx-0'>
          <Col xs={12} md={4} className='px-0 mb-2 mb-md-0'>
            <StyledH5 min='22px' max='32px' className='mb-0'>
              Transactions
            </StyledH5>
          </Col>
          <Col xs={12} md={8} className='px-0'>
            <div
              className='d-flex justify-content-md-end flex-wrap'
              style={{ gap: '0.5rem' }}
            >
              <Link
                to='/admin/fee-simulator'
                className='btn btn-primary px-2 py-1 px-md-4 py-md-2'
              >
                Fee simulator
              </Link>
              {!hasEditPermission ? null : (
                <>
                  <button
                    className='btn btn-primary px-2 py-1 px-md-4 py-md-2'
                    type='button'
                    onClick={() => setShowExportModal(true)}
                  >
                    Export Withdraw
                  </button>
                  <button
                    className='btn btn-primary px-2 py-1 px-md-4 py-md-2'
                    type='button'
                    onClick={() => setShowExportPayment(true)}
                  >
                    Export Report
                  </button>
                  {tab === TAB_KEY.INSURANCES && (
                    <ExportInsurance date={date} status={status.value} />
                  )}
                </>
              )}
            </div>
            {!hasEditPermission ? null : (
              <>
                {!showExportModal ? null : (
                  <ExportTrxModal
                    isOpen={showExportModal}
                    toggle={() => setShowExportModal(false)}
                    title='Export Withdraw Transactions'
                  />
                )}
                <ExportReportModal
                  isOpen={showExportPayment}
                  toggle={() => setShowExportPayment(false)}
                />
              </>
            )}
          </Col>
        </Row>
        <Card>
          <div className='p-3'>
            <Nav
              tabs
              className='nav-tabs-custom nav-tabs-custom--bg-transparent pb-3 pb-md-1'
            >
              {tabsOptions.map(({ label, key }) => (
                <NavItem key={`tab-${key}`}>
                  <NavLink
                    className={cx({ active: tab === key })}
                    onClick={() => {
                      setActivePage(1)
                      setTab(key)
                    }}
                    tag='button'
                  >
                    {label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </div>

          <Col>
            {tab !== TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
              <Row className='p-3' style={{ gap: '0.75rem 0' }}>
                {tab === TAB_KEY.REVERTED_TRANSACTIONS ? null : (
                  <Col xs={12} md={4} lg={3} className='px-1'>
                    <CustomSelect
                      options={tabStatuses}
                      value={status}
                      onChange={(e) => {
                        setActivePage(1)
                        setStatus(e)
                      }}
                    />
                  </Col>
                )}

                {tab === TAB_KEY.WITHDRAWS && (
                  <Col xs={12} md={4} lg={3} className='px-1'>
                    <CustomSelect
                      options={providerOptions}
                      value={provider}
                      onChange={(e) => {
                        setActivePage(1)
                        setProvider(e)
                      }}
                      placeholder='Select provider'
                      isClearable
                    />
                  </Col>
                )}

                {tab !== TAB_KEY.PAYMENTS ? null : (
                  <Col xs={12} md={4} lg={3} className='px-1'>
                    <CustomSelect
                      options={receivedStatuses}
                      value={receivedStatus}
                      onChange={(value) => {
                        setActivePage(1)
                        setReceivedStatus(value)
                      }}
                    />
                  </Col>
                )}

                <Col xs={12} md={4} lg={3} className='px-1'>
                  <CustomDatePicker
                    showMonthYearPicker
                    placeholder='Filter by month'
                    dateFormat='MMM - yyyy'
                    value={date}
                    handleOnChange={(v) => {
                      setActivePage(1)
                      setDate(v)
                    }}
                  />
                </Col>

                {tab === TAB_KEY.WITHDRAWS && (
                  <Col xs={12} md={4} lg={3} className='px-1'>
                    <CustomSelect
                      placeholder='Select payment method'
                      options={paymentMethodOptions}
                      value={paymentMethodFilter}
                      onChange={setPaymentMethodFilter}
                      styles={customStyles}
                      isClearable
                    />
                  </Col>
                )}

                {tab === TAB_KEY.PAYMENTS && (
                  <Col xs={12} md={4} lg={3} className='px-1'>
                    <CustomSelect
                      options={
                        Array.isArray(companiesList)
                          ? [
                              defaultCompany,
                              ...companiesList.map((e) => ({
                                label: e.name,
                                value: e.id,
                              })),
                            ]
                          : [defaultCompany]
                      }
                      value={company}
                      onChange={setCompany}
                    />
                  </Col>
                )}

                <Col md={2} xs={12} className='px-1'>
                  <Button block color='primary' onClick={handleClearFilter}>
                    Clear Filter
                  </Button>
                </Col>
              </Row>
            )}

            <Row className='p-3'>
              <Col md={12} xs={12} className='p-0'>
                <SearchBar
                  query={query}
                  onQueryChanged={handleSearch}
                  placeholder={searchBarPlaceholder}
                  className='mr-md-2'
                />
              </Col>
            </Row>
            {tab === TAB_KEY.WITHDRAWS && (
              <Row className='align-items-end justify-content-end p-3'>
                <Button
                  onClick={() => setShowMultipleConfirm(true)}
                  disabled={selectedWithdraws.length === 0}
                  color={
                    selectedWithdraws.length === 0 ? 'secondary' : 'primary'
                  }
                  type='button'
                  className='font-size-14 withdraw-payments-button'
                >
                  Process Payments
                </Button>

                <Button
                  className='font-size-14 withdraw-payments-button ml-2'
                  onClick={() => applyFilter(null, true)}
                  type='button'
                >
                  Refresh Payments
                </Button>
              </Row>
            )}
          </Col>

          <div className='position-relative' style={{ minHeight: '10rem' }}>
            {!trxData || trxData.length <= 0 ? (
              <div
                style={{ minHeight: '10rem', fontWeight: 300, opacity: 0.7 }}
                className='d-flex justify-content-center align-items-center flex-column text-center'
              >
                {isTrxLoading ? null : (
                  <>
                    <p
                      className='text-secondary h4'
                      style={{ fontWeight: 300 }}
                    >
                      No data found.
                    </p>
                    <p
                      className='text-secondary mb-0 h4'
                      style={{ fontWeight: 300 }}
                    >
                      {searchBarPlaceholder} to get some data.
                    </p>
                  </>
                )}
              </div>
            ) : (
              <>
                <div className='p-3 m-0 d-block d-md-none'>
                  {trxData &&
                    trxData.length > 0 &&
                    trxData?.map((order, key) => (
                      <TransactionCardAdmin
                        archive={archive}
                        tab={tab}
                        setToConfirm={(order) => {
                          openConfirmTransactionModal(order, {
                            isWithdraws: isWithdrawTab,
                          })
                        }}
                        setIsOpen={() =>
                          setConfirmTransactionModal((prevData) => ({
                            ...prevData,
                            isOpen: true,
                          }))
                        }
                        order={order}
                        key={key}
                        onUpdate={() => applyFilter(null, true)}
                        withProcess={tab === TAB_KEY.WITHDRAWS}
                        index={key}
                        multipleLoading={multipleProcess.isLoading}
                        selectedWithdraws={selectedWithdraws}
                        setScreeningDetails={setScreeningDetails}
                      />
                    ))}
                </div>
                {tab === TAB_KEY.REVERTED_TRANSACTIONS ? (
                  /* Reverted transactions */
                  <DataTable
                    columns={revertedColumns}
                    data={trxData}
                    responsive
                    className='d-none d-md-table'
                  />
                ) : tab === TAB_KEY.CONTRIBUTIONS ? (
                  /* Reverted transactions */
                  <DataTable
                    columns={contributionColumns}
                    data={trxData}
                    expandable={{
                      expandedRowRender: (record) => {
                        return record.works?.map((work) => {
                          const formatter = getCurrencyFormatter(
                            work.currency?.code,
                          )
                          return (
                            <tr key={work.id}>
                              <td></td>
                              <td></td>
                              <td>{work.entity_name}</td>
                              <td colSpan={3}>
                                {getFullName(work.contractor)}
                              </td>
                              <td>{formatter.format(work.sub_total)}</td>
                              <td>
                                {format(
                                  new Date(work.created_at),
                                  'dd/MM/yyyy',
                                )}
                              </td>
                              <td>
                                {work.transferred_at
                                  ? format(
                                      new Date(work.transferred_at),
                                      'dd/MM/yyyy',
                                    )
                                  : 'N/A'}
                              </td>
                              <td></td>
                              <td>
                                {work.transferred ? (
                                  <div className='tw-font-bold tw-text-systemGreen'>
                                    Transferred
                                  </div>
                                ) : (
                                  <div className='tw-font-bold tw-text-systemGold'>
                                    Pending
                                  </div>
                                )}
                              </td>
                              <td>
                                {work.transferred ? null : (
                                  <Button
                                    size='sm'
                                    color='primary'
                                    loading={
                                      transferringWorkID === work.work_id
                                    }
                                    onClick={() => {
                                      setTransferringWorkID(work.work_id)

                                      makeTransfer({
                                        transaction_item_ids:
                                          work.transaction_items_ids,
                                      })
                                    }}
                                  >
                                    Transfer
                                  </Button>
                                )}
                              </td>
                            </tr>
                          )
                        })
                      },
                      rowExpandable: () => true,
                    }}
                    responsive
                    className='tw-hidden md:tw-table'
                  />
                ) : (
                  <div
                    className='table-responsive d-none d-md-block'
                    style={{ paddingBottom: trxData.length < 8 ? 300 : null }}
                  >
                    <Table className='table table-centered table-nowrap text-muted'>
                      <thead>
                        <tr>
                          <th
                            className='border-top-0 sticky-col first-col'
                            style={{ '--col-width': '70px' }}
                          />
                          <th
                            className='border-top-0 sticky-col second-col'
                            style={{ '--col-left': '70px' }}
                          >
                            TRX ID
                          </th>
                          {tab === TAB_KEY.WITHDRAWS && (
                            <th className='border-top-0'>Contract ID</th>
                          )}
                          <th
                            className='border-top-0 border-col sticky-col third-col'
                            style={{ '--col-left': '206px' }}
                          >
                            Name
                          </th>
                          {tab === TAB_KEY.INSURANCES && (
                            <th
                              className='border-top-0 border-col sticky-col third-col'
                              style={{ '--col-left': '206px' }}
                            >
                              Contractor ID
                            </th>
                          )}
                          {(tab === TAB_KEY.WITHDRAWS ||
                            tab === TAB_KEY.PENDING_COMPLIANCE_REVIEW) && (
                            <th
                              className='border-top-0 border-col sticky-col forth-col'
                              style={{ '--col-left': '446px' }}
                            >
                              Account holder
                            </th>
                          )}
                          {tab === TAB_KEY.WITHDRAWS && (
                            <th
                              className='border-top-0 border-col'
                              style={{ '--col-left': '446px' }}
                            >
                              Account number
                            </th>
                          )}
                          {tab === TAB_KEY.WITHDRAWS && (
                            <th className='border-top-0 border-col'>
                              Compliance review
                            </th>
                          )}
                          {tab === TAB_KEY.WITHDRAWS && (
                            <th className='border-top-0 border-col'>
                              User Type
                            </th>
                          )}
                          {tab !== TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                            <th className='border-top-0'>Type</th>
                          )}
                          {tab === TAB_KEY.PAYMENTS && (
                            <th className='border-top-0'>Bank Name</th>
                          )}
                          {tab !== TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                            <th className='border-top-0'>Method</th>
                          )}
                          {tab !== TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                            <th className='border-top-0'>Email</th>
                          )}
                          {tab !== TAB_KEY.WITHDRAWS &&
                            tab !== TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                              <th className='border-top-0'>Company name</th>
                            )}
                          {tab !== TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                            <th className='border-top-0 text-right'>
                              {tab === TAB_KEY.PAYMENTS ? 'Total' : 'Amount'}
                            </th>
                          )}
                          {tab !== TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                            <>
                              <th className='border-top-0'>TRX date</th>
                              <th className='border-top-0'>Confirmed at</th>
                            </>
                          )}
                          {tab === TAB_KEY.WITHDRAWS && (
                            <th className='border-top-0'>Fee</th>
                          )}
                          {tab !== TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                            <th className='border-top-0'>Rate</th>
                          )}
                          {tab !== TAB_KEY.PAYMENTS &&
                            tab !== TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                              <th className='border-top-0'>Source</th>
                            )}

                          {tab === TAB_KEY.WITHDRAWS && (
                            <th className='border-top-0'>Source in USD</th>
                          )}

                          {tab !== TAB_KEY.PAYMENTS &&
                            tab !== TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                              <th className='border-top-0'>Target</th>
                            )}

                          {tab === TAB_KEY.WITHDRAWS && (
                            <th className='border-top-0'>Target in USD</th>
                          )}

                          {tab === TAB_KEY.WITHDRAWS && (
                            <th className='border-top-0'>Bank country</th>
                          )}

                          {tab === TAB_KEY.PAYMENTS && (
                            <th className='border-top-0'>Markup</th>
                          )}

                          {![
                            TAB_KEY.PAYMENTS,
                            TAB_KEY.WITHDRAWS,
                            TAB_KEY.PENDING_COMPLIANCE_REVIEW,
                          ].includes(tab) && (
                            <th className='border-top-0'>ETA</th>
                          )}
                          {tab === TAB_KEY.WITHDRAWS && (
                            <th className='border-top-0'>Provider</th>
                          )}
                          {tab !== TAB_KEY.WITHDRAWS &&
                          tab !== TAB_KEY.PENDING_COMPLIANCE_REVIEW ? (
                            <th className='border-top-0'>Fee</th>
                          ) : null}

                          {(tab === TAB_KEY.PENDING_COMPLIANCE_REVIEW ||
                            tab === TAB_KEY.WITHDRAWS) && (
                            <th className='border-top-0'>Screening</th>
                          )}

                          <th className='border-top-0'>TRX Status</th>
                          {tab !== TAB_KEY.PAYMENTS ? null : (
                            <th className='border-top-0'>Received</th>
                          )}
                          {![
                            TAB_KEY.PAYMENTS,
                            TAB_KEY.WITHDRAWS,
                            TAB_KEY.REFUNDS,
                          ].includes(tab) && (
                            <th className='border-top-0'>Provider</th>
                          )}
                          {[TAB_KEY.WITHDRAWS, TAB_KEY.REFUNDS].includes(
                            tab,
                          ) && <th className='border-top-0'>Process</th>}
                          {!hasEditPermission ? null : (
                            <>
                              {![
                                TAB_KEY.PAYMENTS,
                                TAB_KEY.WITHDRAWS,
                                TAB_KEY.REFUNDS,
                                TAB_KEY.PENDING_COMPLIANCE_REVIEW,
                              ].includes(tab) && (
                                <th className='border-top-0'>Export</th>
                              )}
                              {![
                                TAB_KEY.PAYMENTS,
                                TAB_KEY.WITHDRAWS,
                                TAB_KEY.PENDING_COMPLIANCE_REVIEW,
                              ].includes(tab) && (
                                <th className='border-top-0'>Archive</th>
                              )}

                              {[TAB_KEY.PAYMENTS, TAB_KEY.WITHDRAWS].includes(
                                tab,
                              ) ? (
                                <th className='border-top-0'>Actions</th>
                              ) : null}
                              {tab === TAB_KEY.WITHDRAWS && (
                                <th className='border-top-0'></th>
                              )}
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {!trxData || trxData.length <= 0
                          ? null
                          : trxData.map((order, key) => {
                              const formatter = getTrxFormatter(order)

                              const sourceFormatter = getCurrencyFormatter(
                                order?.base_currency?.code,
                              )
                              const usdFormatter = getCurrencyFormatter()

                              const holderName =
                                order.bank_account_details?.accountHolderName

                              return (
                                <React.Fragment key={'_order_' + key}>
                                  <tr>
                                    <td
                                      className='first-col sticky-col px-4'
                                      style={{ '--col-width': '70px' }}
                                    >
                                      {order?.works?.length !== 0 ? (
                                        <button
                                          className='rp-btn-nostyle text-current d-flex p-1'
                                          onClick={() => {
                                            setExpanded(
                                              expanded === key ? -1 : key,
                                            )
                                          }}
                                        >
                                          <i className='bx bx-plus' />
                                        </button>
                                      ) : null}
                                    </td>

                                    <td
                                      className='sticky-col second-col'
                                      style={{ '--col-left': '70px' }}
                                    >
                                      {[
                                        TAB_KEY.WITHDRAWS,
                                        TAB_KEY.REFUNDS,
                                      ].includes(tab) ? (
                                        <button
                                          type='button'
                                          onClick={() => setTrxDetail(order)}
                                          className='bg-transparent border-0 text-primary'
                                        >
                                          {order.transaction_ref}
                                        </button>
                                      ) : (
                                        order.transaction_ref
                                      )}
                                    </td>

                                    {tab === TAB_KEY.WITHDRAWS && (
                                      <td>
                                        <ContractIDsCell
                                          cellData={order?.contracts?.map(
                                            (ref) => ({ ref }),
                                          )}
                                          rowData={order}
                                        />
                                      </td>
                                    )}

                                    <td
                                      className='sticky-col third-col border-light border-secondary'
                                      style={{ '--col-left': '206px' }}
                                    >
                                      <UserFullName
                                        data={order}
                                        prefillComment={prefillComment(
                                          order,
                                          tab,
                                        )}
                                        onCompleteAction={() => {
                                          applyFilter(null, true)
                                        }}
                                        onUserClick={() =>
                                          handleUserClick(order)
                                        }
                                      />
                                    </td>

                                    {tab === TAB_KEY.INSURANCES && (
                                      <td>{order.client.id}</td>
                                    )}

                                    {(tab === TAB_KEY.WITHDRAWS ||
                                      tab ===
                                        TAB_KEY.PENDING_COMPLIANCE_REVIEW) && (
                                      <td
                                        className='border-light border-secondary sticky-col forth-col'
                                        style={{ '--col-left': '446px' }}
                                      >
                                        <div className='tw-flex tw-items-center tw-gap-1'>
                                          {!holderName ? null : (
                                            <div
                                              className='tw-inline-block tw-max-w-[calc(100%-20px)] tw-truncate'
                                              title={holderName}
                                            >
                                              {holderName}
                                            </div>
                                          )}

                                          <VerifiedIcon
                                            status={
                                              order.account_status ===
                                              'verified'
                                                ? VERIFIED_BADGE_STATUS.verified
                                                : order.account_status ===
                                                    'unverified'
                                                  ? VERIFIED_BADGE_STATUS.unverified
                                                  : order.account_status ===
                                                      'deactivated'
                                                    ? VERIFIED_BADGE_STATUS.deactivated
                                                    : ''
                                            }
                                          />
                                        </div>
                                      </td>
                                    )}
                                    {tab === TAB_KEY.WITHDRAWS && (
                                      <td
                                        className='border-light border-secondary'
                                        style={{ '--col-left': '446px' }}
                                      >
                                        <div
                                          className='text-truncate d-inline-block'
                                          style={{
                                            maxWidth: 'calc(100% - 20px)',
                                          }}
                                          title={
                                            order.bank_account_details
                                              ?.accountNumber
                                          }
                                        >
                                          {
                                            order.bank_account_details
                                              ?.accountNumber
                                          }
                                        </div>
                                      </td>
                                    )}
                                    {tab === TAB_KEY.WITHDRAWS && (
                                      <td className='border-light border-secondary'>
                                        {order.under_review ? (
                                          <BadgeX status='success'>
                                            In compliance review
                                          </BadgeX>
                                        ) : (
                                          'N/A'
                                        )}
                                      </td>
                                    )}
                                    {tab === TAB_KEY.WITHDRAWS && (
                                      <td className='tw-capitalize'>
                                        {labelize(order.user_type)}
                                      </td>
                                    )}
                                    {tab !==
                                      TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                                      <td>{order?.type?.name}</td>
                                    )}

                                    {tab === TAB_KEY.PAYMENTS && (
                                      <td>{order?.bank_name}</td>
                                    )}

                                    {tab !==
                                      TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                                      <td>{order?.method?.name}</td>
                                    )}

                                    {tab !==
                                      TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                                      <td>{order?.client?.email}</td>
                                    )}

                                    {tab !== TAB_KEY.WITHDRAWS &&
                                      tab !==
                                        TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                                        <td>{order?.company_name}</td>
                                      )}

                                    {tab !==
                                      TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                                      <td className='text-right'>
                                        {formatter.format(
                                          tab === TAB_KEY.PAYMENTS
                                            ? order.total
                                            : order.amount,
                                        )}
                                      </td>
                                    )}

                                    {tab !==
                                      TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                                      <td title={order.created_at}>
                                        {format(
                                          new Date(order.created_at),
                                          'MM/dd/yy',
                                        )}
                                      </td>
                                    )}

                                    {tab !==
                                      TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                                      <td title={order.confirmed_at}>
                                        {format(
                                          new Date(order.confirmed_at),
                                          'MM/dd/yy',
                                        )}
                                      </td>
                                    )}

                                    {tab === TAB_KEY.WITHDRAWS && (
                                      <td className='text-capitalize'>
                                        {formatter.format(Number(order?.fee))}
                                      </td>
                                    )}

                                    {tab !==
                                      TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                                      <td>{order.rate}</td>
                                    )}

                                    {tab !== TAB_KEY.PAYMENTS &&
                                      tab !==
                                        TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                                        <td>
                                          {sourceFormatter.format(
                                            order.base_amount,
                                          )}
                                        </td>
                                      )}

                                    {tab === TAB_KEY.WITHDRAWS && (
                                      <td>
                                        {getCurrencyFormatter().format(
                                          order?.source_usd,
                                        )}
                                      </td>
                                    )}

                                    {tab !== TAB_KEY.PAYMENTS &&
                                      tab !==
                                        TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                                        <td>
                                          {order.total_usd && (
                                            <span className='font-weight-semibold'>{`(${usdFormatter.format(
                                              order.total_usd,
                                            )})`}</span>
                                          )}
                                          {formatter.format(
                                            Number(order.total),
                                          )}
                                        </td>
                                      )}

                                    {tab === TAB_KEY.WITHDRAWS && (
                                      <td>
                                        {getCurrencyFormatter().format(
                                          order?.target_usd,
                                        )}
                                      </td>
                                    )}

                                    {tab === TAB_KEY.PAYMENTS &&
                                      tab !==
                                        TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                                        <td>
                                          {!!order.markup &&
                                            formatter.format(order.markup)}
                                        </td>
                                      )}

                                    {tab === TAB_KEY.WITHDRAWS && (
                                      <td>{order?.country}</td>
                                    )}

                                    {![
                                      TAB_KEY.PAYMENTS,
                                      TAB_KEY.WITHDRAWS,
                                      TAB_KEY.PENDING_COMPLIANCE_REVIEW,
                                    ].includes(tab) && <td>{order.eta}</td>}

                                    {tab === TAB_KEY.WITHDRAWS && (
                                      <td className='text-capitalize'>
                                        {order.provider}
                                      </td>
                                    )}

                                    {(tab ===
                                      TAB_KEY.PENDING_COMPLIANCE_REVIEW ||
                                      tab === TAB_KEY.WITHDRAWS) && (
                                      <td>
                                        {order.screening_status && (
                                          <button
                                            onClick={() =>
                                              setScreeningDetails(order)
                                            }
                                            type='button'
                                          >
                                            <BadgeX
                                              size='md'
                                              status={
                                                getStatus(
                                                  order.screening_status,
                                                ).color
                                              }
                                              pill
                                            >
                                              {
                                                getStatus(
                                                  order.screening_status,
                                                ).label
                                              }
                                            </BadgeX>
                                          </button>
                                        )}
                                      </td>
                                    )}

                                    {tab !== TAB_KEY.WITHDRAWS &&
                                    tab !==
                                      TAB_KEY.PENDING_COMPLIANCE_REVIEW ? (
                                      <td>
                                        {formatter.format(Number(order?.fee))}
                                      </td>
                                    ) : null}

                                    <td>
                                      <div className='d-flex gap-4 align-items-center'>
                                        {order.status === 'Confirmed' ||
                                        !hasEditPermission ? (
                                          <BadgeX
                                            status={
                                              order.status === 'Confirmed'
                                                ? 'success'
                                                : 'warning'
                                            }
                                          >
                                            {order.status}
                                          </BadgeX>
                                        ) : (
                                          <>
                                            <BadgeX
                                              status='secondary'
                                              size='md'
                                            >
                                              {order.status}
                                            </BadgeX>

                                            {tab !==
                                              TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
                                              <Button
                                                onClick={() => {
                                                  openConfirmTransactionModal(
                                                    order,
                                                    {
                                                      isWithdraws:
                                                        isWithdrawTab,
                                                    },
                                                  )
                                                }}
                                                size='sm'
                                                outline
                                                color='success'
                                              >
                                                Mark as confirmed
                                              </Button>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </td>

                                    {tab !== TAB_KEY.PAYMENTS ? null : (
                                      <td>
                                        <div className='d-flex gap-10 align-items-center'>
                                          {order.is_cor === 1 ? (
                                            <BadgeX
                                              size='sm'
                                              status='secondary'
                                            >
                                              COR
                                            </BadgeX>
                                          ) : null}

                                          {order.is_received === 1 ? (
                                            <BadgeX status='primary'>
                                              Received
                                            </BadgeX>
                                          ) : (
                                            <Button
                                              onClick={() => {
                                                openConfirmTransactionModal(
                                                  order,
                                                  {
                                                    isWithdraws: isWithdrawTab,
                                                    isMarkAsReceived: true,
                                                  },
                                                )
                                              }}
                                              outline
                                              size='sm'
                                              disabled={
                                                (markingAsReceived ||
                                                  !hasEditPermission) &&
                                                (order.status !== 'Confirmed' ||
                                                  order.status !== 'Processed')
                                              }
                                            >
                                              Mark as received
                                            </Button>
                                          )}
                                        </div>
                                      </td>
                                    )}
                                    {![
                                      TAB_KEY.PAYMENTS,
                                      TAB_KEY.WITHDRAWS,
                                      TAB_KEY.REFUNDS,
                                    ].includes(tab) && (
                                      <td className='text-capitalize'>
                                        {order.provider}
                                      </td>
                                    )}
                                    {[
                                      TAB_KEY.WITHDRAWS,
                                      TAB_KEY.REFUNDS,
                                    ].includes(tab) && (
                                      <td>
                                        <ProcessColumn
                                          item={order}
                                          onUpdate={() =>
                                            applyFilter(null, true)
                                          }
                                          selectedWithdraws={selectedWithdraws}
                                          hasEditPermission={hasEditPermission}
                                          multipleLoading={
                                            multipleProcess.isLoading
                                          }
                                        />
                                      </td>
                                    )}

                                    {!hasEditPermission ? null : (
                                      <>
                                        {![
                                          TAB_KEY.PAYMENTS,
                                          TAB_KEY.WITHDRAWS,
                                          TAB_KEY.REFUNDS,
                                          TAB_KEY.PENDING_COMPLIANCE_REVIEW,
                                        ].includes(tab) && (
                                          <td className='text-center'>
                                            {exportTransaction.isLoading &&
                                            exporting?.transaction_id ===
                                              order?.transaction_id ? (
                                              <i className='bx bx-loader bx-spin font-size-16 text-primary' />
                                            ) : (
                                              <a
                                                className='text-primary'
                                                onClick={() => {
                                                  setExporting(order)
                                                  exportTransaction.startFetch({
                                                    transaction_id:
                                                      order.transaction_id,
                                                  })
                                                }}
                                              >
                                                <i className='bx bx-export font-size-20' />
                                              </a>
                                            )}
                                          </td>
                                        )}

                                        {![
                                          TAB_KEY.PAYMENTS,
                                          TAB_KEY.WITHDRAWS,
                                          TAB_KEY.PENDING_COMPLIANCE_REVIEW,
                                        ].includes(tab) ? (
                                          <td>
                                            <Col>
                                              {archiving === key ? (
                                                <i className='bx bx-loader bx-spin font-size-16 text-primary' />
                                              ) : (
                                                <a
                                                  className='text-primary'
                                                  onClick={() => {
                                                    setArchiving(key)
                                                    archive.startFetch({
                                                      transaction_id:
                                                        order.transaction_id,
                                                      status: order?.archived
                                                        ? 0
                                                        : 1,
                                                    })
                                                  }}
                                                >
                                                  {order?.archived ? (
                                                    <ImageIcon
                                                      icon={unArchiveIcon}
                                                      hoverIcon={
                                                        unArchiveIconHover
                                                      }
                                                      alt='unarchive icon'
                                                    />
                                                  ) : (
                                                    <ImageIcon
                                                      icon={archiveIcon}
                                                      hoverIcon={
                                                        archiveIconHover
                                                      }
                                                      alt='archive icon'
                                                    />
                                                  )}
                                                </a>
                                              )}
                                            </Col>
                                          </td>
                                        ) : null}

                                        {tab === TAB_KEY.PAYMENTS ? (
                                          <td>
                                            <PaymentActions
                                              transaction={order}
                                              onActionComplete={() => {
                                                applyFilter(null, true)
                                              }}
                                              onStartRevert={() => {
                                                setToRevert(order)
                                                setIsRevertPaymentOpen(true)
                                              }}
                                            />
                                          </td>
                                        ) : null}

                                        {tab === TAB_KEY.WITHDRAWS ? (
                                          <td>
                                            <WithdrawActions
                                              transaction={order}
                                              onStartRevert={() => {
                                                setToRevert(order)
                                                setIsRevertOpen(true)
                                              }}
                                              onStartPreferredProvider={() => {
                                                setIsPreferredProvider(order)
                                              }}
                                              onActionComplete={() => {
                                                applyFilter(null, true)
                                              }}
                                              onStartUnProcess={() => {
                                                setUnProcessModal(order)
                                              }}
                                              onStartReview={() => {
                                                handleReview(order)
                                              }}
                                              onStartManualProcess={() => {
                                                setToManualProcess(order)
                                                setIsManualProcessOpen(true)
                                              }}
                                              hasEditPermission={
                                                hasEditPermission
                                              }
                                            />
                                          </td>
                                        ) : null}
                                      </>
                                    )}

                                    {tab === TAB_KEY.WITHDRAWS && (
                                      <td>
                                        {order?.processed !== 1 &&
                                          order.status !== 'Confirmed' &&
                                          order.provider !== 'paypal' &&
                                          !order?.provider_response?.kyc &&
                                          hasEditPermission && (
                                            <>
                                              {order.failed === 1 ? (
                                                <Badge
                                                  className='d-inline-flex align-items-center font-size-14 rounded bg-soft-danger'
                                                  color='white'
                                                  pill
                                                >
                                                  <StyledP
                                                    style={{ padding: 4 }}
                                                    min='12px'
                                                    max='14px'
                                                    className='mb-0 text-danger'
                                                  >
                                                    failed
                                                  </StyledP>
                                                  {order?.failed &&
                                                  order?.last_processing_error ? (
                                                    <>
                                                      <Info
                                                        size={18}
                                                        id={`status-${order?.transaction_ref}`}
                                                        color='#f64f59'
                                                      />

                                                      <UncontrolledTooltip
                                                        target={`status-${order?.transaction_ref}`}
                                                        placement='bottom'
                                                      >
                                                        {
                                                          order?.last_processing_error
                                                        }
                                                      </UncontrolledTooltip>
                                                    </>
                                                  ) : null}
                                                </Badge>
                                              ) : order.queued === 1 ? (
                                                <i className='bx bx-loader bx-spin' />
                                              ) : (
                                                BULK_PROCESS_PROVIDERS.includes(
                                                  order.provider,
                                                ) && (
                                                  <Toggle
                                                    id={`trx${key}`}
                                                    check={order.selected}
                                                    change={() => {
                                                      handleTrxChecked(order)
                                                    }}
                                                  />
                                                )
                                              )}
                                            </>
                                          )}
                                      </td>
                                    )}
                                  </tr>

                                  {tab !== TAB_KEY.WITHDRAWS &&
                                    expanded === key && (
                                      <>
                                        {order?.works?.map((work) => {
                                          const formatter =
                                            getCurrencyFormatter(work?.currency)

                                          return (
                                            <tr
                                              className='font-weight-light font-size-12 bg-soft-primary'
                                              key={'_trans' + work?.id}
                                            >
                                              <th></th>
                                              <th className='rp-font-bold font-size-12'>
                                                <ContractRef
                                                  isAdmin
                                                  contractId={
                                                    work?.contract_ref
                                                  }
                                                />
                                              </th>
                                              <td>
                                                <Col>
                                                  <Row className='align-items-center flex-nowrap'>
                                                    {!work.contractor?.photo ? (
                                                      <div className='avatar-xs'>
                                                        <span className='avatar-title rounded-circle'>
                                                          {work.contractor?.first_name?.charAt(
                                                            0,
                                                          )}
                                                        </span>
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        <img
                                                          className='rounded-circle avatar-xs'
                                                          src={`${storage}${work.contractor?.photo}`}
                                                          alt=''
                                                        />
                                                      </div>
                                                    )}
                                                    <div
                                                      style={{ marginLeft: 10 }}
                                                    >
                                                      {
                                                        work.contractor
                                                          ?.first_name
                                                      }{' '}
                                                      {
                                                        work.contractor
                                                          ?.last_name
                                                      }
                                                    </div>
                                                  </Row>
                                                </Col>
                                              </td>

                                              <td className='font-size-14'>
                                                {work?.payment_ref}
                                              </td>

                                              <th className='font-weight-normal font-size-12'>
                                                {work?.details}
                                              </th>
                                              <th className='font-weight-normal font-size-12'>
                                                {work?.name}
                                              </th>
                                              <th className='font-weight-normal font-size-12 text-right'>
                                                {formatter.format(work?.amount)}
                                              </th>
                                              <th className='border-top-0 text-muted'></th>
                                              <th className='border-top-0 text-muted'></th>
                                              <th className='border-top-0 text-muted'></th>
                                              <th className='border-top-0 text-muted'></th>
                                              <th className='border-top-0 text-muted'></th>
                                              <th className='border-top-0 text-muted'></th>
                                              <th className='border-top-0 text-muted'></th>
                                              <th className='border-top-0 text-muted'></th>
                                              <th className='border-top-0 text-muted'></th>
                                              <th className='border-top-0 text-muted'></th>
                                              <th className='border-top-0 text-muted'></th>
                                            </tr>
                                          )
                                        })}
                                      </>
                                    )}
                                </React.Fragment>
                              )
                            })}
                      </tbody>
                    </Table>
                    <RevertTrxModal
                      isOpen={isRevertPaymentOpen}
                      toggle={() => setIsRevertPaymentOpen(false)}
                      onConfirm={handleConfirmRevertPayment}
                      trx={toRevert}
                      isLoading={revertPayment.isLoading}
                    />

                    {!confirmTransactionModal?.isOpen ? null : (
                      <ConfirmationModal
                        toggle={closeConfirmTransactionModal}
                        onConfirm={handleConfirmTransaction}
                        loading={confirm.isLoading || markingAsReceived}
                        {...confirmTransactionModal}
                      />
                    )}
                    <MultipleTransactionsModal
                      isOpen={showMultipleConfirm}
                      toggle={() => setShowMultipleConfirm(false)}
                      onConfirm={() => handleMultipleProcessing()}
                      loading={multipleProcess.isLoading}
                    />
                    <ManualProcessModal
                      isOpen={isManualProcessOpen}
                      toggle={() => setIsManualProcessOpen(false)}
                      onConfirm={handleConfirmManualProcess}
                      trx={toManualProcess}
                      isLoading={manualProcess.isLoading}
                    />
                    <RevertTrxModal
                      isWithdraw
                      isOpen={isRevertOpen}
                      toggle={() => setIsRevertOpen(false)}
                      onConfirm={handleConfirmRevert}
                      trx={toRevert}
                      isLoading={revert.isLoading}
                    />
                    {!isPreferredProvider ? null : (
                      <PreferredProviderModal
                        isOpen={!!isPreferredProvider}
                        transaction={isPreferredProvider}
                        isLoading={preferredProvider.isLoading}
                        toggle={() => setIsPreferredProvider((open) => !open)}
                        onConfirm={handleSetPreferredProvider}
                      />
                    )}
                    {!unProcessModal ? null : (
                      <UnProcessModal
                        isOpen={!!unProcessModal}
                        transaction={unProcessModal}
                        toggle={() => setUnProcessModal((open) => !open)}
                        isLoading={unProcessing}
                        onConfirm={handleUnProcessWithdraw}
                      />
                    )}
                  </div>
                )}
                {!trxPaginator ? null : (
                  <div className='d-flex justify-content-end pt-3 px-3'>
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={trxPaginator?.per_page ?? 50}
                      totalItemsCount={trxPaginator?.total}
                      pageRangeDisplayed={5}
                      onChange={setActivePage}
                    />
                  </div>
                )}
              </>
            )}

            {isTrxLoading ? (
              <div
                style={{
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  backgroundColor: '#5c656d30',
                  zIndex: 2,
                }}
                className='position-absolute'
              >
                <div
                  className='d-flex justify-content-center align-items-center'
                  style={{ height: '100%', maxHeight: '20rem' }}
                >
                  <Spinner type='grow' color='primary' />
                </div>
              </div>
            ) : null}
          </div>
        </Card>
      </Container>
    </div>
  )
}

const PROVIDER_TRX_MAPPING = {
  [PROVIDER.WISE]: (item) => item?.provider_response?.id,
  [PROVIDER.TRANSFERMATE]: (item) => item?.provider_response?.transaction_id,
  [PROVIDER.VERTO]: (item) => item?.provider_response?.reference,
  [PROVIDER.PAYPAL]: (item) => item?.provider_response?.transaction_id,
  [PROVIDER.PAYONEER_BT]: (item) => item?.provider_response?.payout_id,
  [PROVIDER.PAYONEER]: (item) => item?.provider_response?.payout_id,
  [PROVIDER.PAYSEND]: (item) =>
    item?.provider_response?.additional_details?.['payment.id'],
  [PROVIDER.EBURY]: (item) => item?.provider_response?.payment_id,
}

function ProcessColumn({
  item,
  hasEditPermission,
  onUpdate,
  selectedWithdraws,
  multipleLoading,
  disabled,
}) {
  if (
    item?.processed !== 1 &&
    item.queued !== 1 &&
    item.status !== 'Confirmed'
  ) {
    if (!hasEditPermission) {
      return 'Not processed'
    } else if (item.failed && item?.provider_response?.kyc) {
      return <ReasonPopover item={item} />
    } else {
      return (
        <ProcessDropdown
          item={item}
          onUpdate={onUpdate}
          selectedWithdraws={selectedWithdraws}
          multipleLoading={multipleLoading}
          disabled={disabled}
        />
      )
    }
  }

  const trxValue = Array.isArray(item?.provider_response)
    ? item?.provider_response[0]?.payment_id
    : (PROVIDER_TRX_MAPPING[item.provider]?.(item) ?? item?.transaction_ref)

  return trxValue ?? item?.transaction_ref ?? null
}

const ReasonPopover = ({ item }) => {
  const [showReason, setShowReason] = useState(false)

  const targetId = `Payoneer_bt_${item.transaction_id}`

  return (
    <div>
      <Popover
        className='unbo-popover'
        placement='top'
        isOpen={showReason && item?.provider_response?.kyc}
        trigger='legacy'
        target={targetId}
        toggle={() => setShowReason(false)}
      >
        <IssuesDisplay issues={item?.provider_response?.kyc[0]?.issues} />
      </Popover>

      <Badge
        className='font-size-12 p-1 bg-soft-danger text-danger'
        id={targetId}
        onMouseEnter={() => {
          setShowReason(true)
        }}
        onMouseLeave={() => setShowReason(false)}
      >
        Failed
      </Badge>
    </div>
  )
}

export function IssuesDisplay({ issues }) {
  return (
    <div className='p-3'>
      {issues?.map((issue, index) => {
        return (
          <div key={index}>
            <h6 className='font-size-14 mb-3 text-gray-h'>{issue?.title}</h6>
            <h6 className='font-size-12 rp-font-light text-gray-h'>
              Requirements:
            </h6>
            <div
              className='d-flex flex-column text-muted'
              style={{ gap: '0.5rem' }}
            >
              {issue?.requirements.map((req, i, arr) => {
                return (
                  <div key={i}>
                    <h6 className='text-current'>
                      {req.requirement_description}
                    </h6>
                    <ul className='mb-0'>
                      {req?.sub_requirements.map((subreq) => {
                        return (
                          <li key={subreq?.requirement_id}>
                            {subreq?.requirement_description}
                          </li>
                        )
                      })}
                    </ul>
                    {i < arr.length - 1 && (
                      <span className='mt-2 mb-1 h6'>{issue.operator}</span>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

const CustomOption = (props) => {
  const { innerProps, isDisabled } = props
  return isDisabled ? null : (
    <div className='p-2 cursor-pointer'>
      <Badge
        pill
        {...innerProps}
        className='font-size-12 rounded bg-soft-success text-success py-1'
      >
        {props.value}
      </Badge>
    </div>
  )
}
const myCustomStyles = {
  dropdownIndicator: () => ({
    color: '#556ee6',
    paddingLeft: 8,
  }),
  indicatorSeparator: () => ({ width: 0 }),
  control: () => ({
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 4,
    border: '1px solid transparent',
    padding: '6px 4px',
    transition: '150ms ease-in-out',
    transitionProperty: 'background-color, border-color',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#f8f8f8',
      borderColor: '#dedede',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    minWidth: 66,
  }),
  singleValue: (provided) => ({ ...provided, minWidth: 65 }),
}

function getTransactionInfoItems(trx) {
  const formatter = getTrxFormatter(trx)

  return [
    { label: 'Transaction ref:', value: trx?.transaction_ref },
    { label: 'Amount:', value: formatter.format(trx?.total) },
    {
      label: 'Client:',
      value: `${trx?.client?.first_name} ${trx?.client?.last_name}`,
    },
    trx?.provider && {
      label: 'Preferred provider:',
      value: capitalizeFirstLetter(trx?.provider ?? ''),
    },
  ].filter(Boolean)
}

const ProcessModal = ({
  isOpen,
  toggle,
  onConfirm,
  trx,
  loading,
  processorType,
}) => {
  useEffect(() => {
    document.body.classList.toggle('modal-open', isOpen)

    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [isOpen])
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Confirm Transaction</ModalHeader>
      <ModalBody>
        {[
          ...getTransactionInfoItems(trx),
          { label: 'Selected Provider:', value: processorType },
        ].map(({ label, value }, index) => {
          return (
            <div
              key={index}
              className='border-bottom d-flex py-1'
              style={{ gap: '0.5rem' }}
            >
              <span style={{ minWidth: 110 }}>{label}</span>
              <span className='rp-font-bold'>{value}</span>
            </div>
          )
        })}
        <p className='font-size-16 mb-0 mt-4 text-center text-muted'>
          Are you sure you want to process this transaction?
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          color='light'
          outline
          type='button'
          disabled={loading}
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          loading={loading}
          disabled={loading}
          type='button'
          onClick={() => {
            onConfirm()
          }}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const confirmationFormId = 'confirmationForm'
function ConfirmationModal({
  isOpen,
  toggle,
  onConfirm,
  transaction,
  isWithdraws,
  isMarkAsReceived,
  loading,
}) {
  const { control, handleSubmit, register, watch, reset } = useForm({
    defaultValues: {
      is_received: isMarkAsReceived,
      received_amount_email: false,
    },
    resolver: yupResolver(
      yup.object().shape({
        is_received: yup.boolean(),
        received_amount: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .when('is_received', {
            is: true,
            then: (schema) =>
              schema
                // 0 is the minimum, this is required for the negative balance case
                .min(0, 'Received amount must be greater than or equal to 0')
                .typeError('Received amount must be a number')
                .required('Received amount is required'),
          }),
        received_date: yup
          .date()
          .typeError('Received date is required')
          .when('is_received', {
            is: true,
            then: (schema) => schema.required('Received date is required'),
          }),
        received_reference: yup.string().when('is_received', {
          is: true,
          then: (schema) => schema.required('Received reference is required'),
        }),
        received_method: yup.string().when('is_received', {
          is: true,
          then: (schema) => schema.required('Received method is required'),
        }),
        received_amount_email: yup.boolean(),
      }),
    ),
  })

  function onSubmit(values) {
    let body = {}

    if (isWithdraws) {
      body = {
        transaction_id: values.transaction_id,
        is_received: 0,
        received_amount: 0,
        received_amount_email: false,
      }
    } else if (
      values.is_received === 1 ||
      values.is_received === '1' ||
      values.is_received === true ||
      isMarkAsReceived
    ) {
      body = {
        transaction_id: values.transaction_id,
        received_amount: values.received_amount,
        received_date: values.received_date,
        received_reference: values.received_reference,
        received_method: values.received_method,
        received_amount_email: values.received_amount_email ? 1 : 0,
      }

      if (!isMarkAsReceived) {
        body.is_received = 1
      }
    } else {
      body = {
        transaction_id: values.transaction_id,
        is_received: 0,
      }
    }

    onConfirm?.(body)
  }

  function closeConfirmTransactionModal() {
    reset()
    toggle()
  }

  const isReceived = watch('is_received')

  return (
    <Modal isOpen={isOpen} toggle={closeConfirmTransactionModal}>
      <ModalHeader
        close={<ModalCloseButton toggle={closeConfirmTransactionModal} />}
      >
        Confirm Transaction
      </ModalHeader>
      <ModalBody>
        <div className='tw-mb-3 tw-flex tw-flex-col tw-gap-3 tw-text-sm'>
          {getTransactionInfoItems(transaction).map(
            ({ label, value }, index) => {
              return (
                <div key={index}>
                  <span>{label}</span>{' '}
                  <span className='tw-font-bold'>{value}</span>
                </div>
              )
            },
          )}
        </div>

        <p className='tw-text-sm'>
          Are you sure you want to confirm this transaction?
        </p>

        <form id={confirmationFormId} onSubmit={handleSubmit(onSubmit)}>
          <input
            {...register('transaction_id', { valueAsNumber: true })}
            value={transaction?.transaction_id}
            type='hidden'
          />
          {isWithdraws || isMarkAsReceived ? null : (
            <ControlledCheckbox
              control={control}
              name='is_received'
              id='is_received'
              label='Mark as received'
              className='mb-3'
            />
          )}
          {(!isReceived || isWithdraws) && !isMarkAsReceived ? null : (
            <ReceivedFormFields control={control} trx={transaction} />
          )}
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          color='light'
          outline
          onClick={closeConfirmTransactionModal}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          formId={confirmationFormId}
          type='submit'
          disabled={loading}
          loading={loading}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function ReceivedFormFields({ control, trx }) {
  const formatter = getTrxFormatter(trx)

  const receivedAmount = useWatch({ control, name: 'received_amount' })

  const amountDifference = trx?.total - receivedAmount
  const showAmountDifference = amountDifference !== 0

  return (
    <div className='d-flex flex-column gap-12'>
      <ControlledCurrencyInput
        control={control}
        name='received_amount'
        id='received_amount'
        label='Received Amount:'
        defaultValue={0}
      />

      <ControlledDatePicker
        control={control}
        name='received_date'
        id='received_date'
        label='Received Date:'
        placeholder='Select date'
      />
      <ControlledInput
        control={control}
        name='received_reference'
        id='received_reference'
        label='Received Reference:'
      />
      <ControlledSelect
        control={control}
        name='received_method'
        inputId='received_method'
        label='Received Method:'
        options={transactionsMethods.map((type) => ({
          label: type,
          value: type,
        }))}
      />
      {!showAmountDifference ? null : (
        <>
          <div className='rp-font-bold text-dark'>
            Rest to be paid:{' '}
            {formatter.format(
              Number.isNaN(amountDifference) ? 0 : amountDifference,
            )}
          </div>

          <ControlledCheckbox
            control={control}
            name='received_amount_email'
            id='received_amount_email'
            label='Send email notification'
          />
        </>
      )}
    </div>
  )
}

const MultipleTransactionsModal = ({ isOpen, toggle, onConfirm, loading }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggle()
      }}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Confirm Transactions</h5>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={() => {
              toggle()
            }}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-body'>
          <p>Are you sure you want to process these transactions?</p>
        </div>
        <div className='modal-footer'>
          <Button
            text='Confirm'
            type='submit'
            className='font-size-14'
            style={{ height: 40 }}
            onClick={onConfirm}
            loading={loading}
            disabled={loading}
          />

          <Button
            text='Cancel'
            type='button'
            className='font-size-14'
            style={{ height: 40 }}
            onClick={toggle}
            color='secondary'
          />
        </div>
      </div>
    </Modal>
  )
}

const ReferenceModal = ({ isOpen, toggle, onConfirm }) => {
  const schema = yup.object().shape({
    reference_id: yup.string().required(),
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {},
  })

  const onSubmit = (data) => {
    onConfirm(data)
    toggle()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Reference ID</ModalHeader>
      <Form
        autoComplete='off'
        className='form-horizontal m-0 p-3'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-3'>
          <FormGroup className='d-inline p-0 m-0'>
            <Label
              style={{ whiteSpace: 'nowrap' }}
              htmlFor='billing-name'
              md='3'
              className='col-form-label p-0 m-0 mb-2 font-size-14'
            >
              Reference ID
              <span className='text-danger font-size-16 mx-1'>*</span>
            </Label>
            <ControlledInput
              control={control}
              name='reference_id'
              error={errors.reference_id}
            />
          </FormGroup>
        </div>
        <ModalFooter>
          <button type='submit' className='btn btn-primary'>
            Confirm
          </button>
          <button
            type='button'
            className='btn btn-secondary'
            data-dismiss='modal'
            onClick={toggle}
          >
            Cancel
          </button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

const processingProviders = [
  'Ebury',
  'Alfardan',
  'ENBD',
  'Wise',
  'Transfermate',
  'Verto',
  'Mercury',
  'Revolut',
  'SVB',
  'AWB',
  'Payoneer',
  'Payoneer BT',
  'Fuse',
]

const providerOptions = [...processingProviders, 'Cash', 'PayPal'].map(
  (processor) => ({
    label: processor,
    value:
      processor === 'Payoneer BT' ? 'payoneer_bt' : processor.toLowerCase(),
  }),
)

const transactionsMethods = [
  'SVB',
  'Revolut GBP',
  'Revolut USD',
  'Revolut EUR',
  'Citi USD',
  'Mercury USD',
  'Wise USD',
  'Wise EUR',
  'Wise GBP',
  'Wise AUD',
  'Stripe Card',
  'Stripe SEPA',
  'Stripe ACH',
  'JPM',
  'ENBD USD',
  'ENBD AED',
  'Balance',
  'Ebury AED',
  'Ebury USD',
]

function getProcessOptions(methodName) {
  return methodName === 'Cash Point'
    ? ['Cash']
    : methodName === 'PayPal'
      ? ['PayPal']
      : processingProviders
}

const ProcessDropdown = ({
  item,
  onUpdate,
  selectedWithdraws,
  multipleLoading,
  disabled,
}) => {
  const [fields, setFields] = useState([])
  const [processorType, setProcessorType] = useState(null)
  const [referenceId, setReferenceId] = useState('')
  const [showConfirm, setShowConfirm] = useState(false)
  const [showReference, setShowReference] = useState(false)

  const [isOpen, setIsOpen] = useState(false)
  const [show, setShow] = useState(false)

  const process = useFetch({
    action: processWithdraw,
    withAdminAccess: true,
    onComplete: (data) => {
      if (data?.missing_info?.length > 0) {
        setFields(data?.missing_info)
        setShow(true)
      } else {
        toastr.success('Transaction created successfully')
        onUpdate()
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const handleProcessing = (type, values) => {
    setShowConfirm(false)
    const body = {
      transaction_id: item?.transaction_id,
      amount: item?.provider === 'coinbase' ? item?.base_amount : item?.total,
      source: item?.base_currency?.code,
      target: item?.currency?.code,
      provider: type?.toLowerCase(),
      reference_id: referenceId,
    }

    if (values) {
      body.fields = Object.entries(values).map(([key, value]) => ({
        key,
        value,
      }))
    }
    setShow(false)
    process.startFetch(body)
  }
  return process.isLoading ||
    (selectedWithdraws.filter((e) => e.transaction_id === item.transaction_id)
      .length > 0 &&
      multipleLoading) ? (
    <i className='bx bx-loader bx-spin font-size-16 text-primary' />
  ) : (
    <>
      {item?.provider === 'coinbase' ? (
        <Button
          onClick={() => {
            setProcessorType('coinbase')
            setShowConfirm(true)
          }}
          size='sm'
          disabled={disabled}
          className={`${disabled ? 'tw-pointer-events-none' : ''}`}
        >
          Process
        </Button>
      ) : (
        <ButtonDropdown
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          style={{ position: 'relative' }}
          disabled={disabled}
        >
          <DropdownToggle
            caret
            color={!disabled ? 'primary' : 'secondary-40'}
            className={`btn-sm rounded !tw-text-white ${
              disabled ? 'tw-pointer-events-none' : ''
            }`}
          >
            Process
          </DropdownToggle>
          <DropdownMenu>
            {getProcessOptions(item?.method?.name).map((type, i) => (
              <DropdownItem
                onClick={() => {
                  setProcessorType(
                    type === 'Payoneer BT' ? 'payoneer_bt' : type,
                  )
                  if (type.toLowerCase() === 'cash') {
                    setShowReference(true)
                  } else {
                    setShowConfirm(true)
                  }
                }}
                key={`provider:${i}`}
              >
                {type}
              </DropdownItem>
            ))}
          </DropdownMenu>
          <MissingFieldsModal
            fields={fields}
            isOpen={show}
            toggle={() => setShow(false)}
            isLoading={process.isLoading}
            onSubmit={(values) => handleProcessing(processorType, values)}
          />
        </ButtonDropdown>
      )}
      <ProcessModal
        trx={item}
        isOpen={showConfirm}
        toggle={() => setShowConfirm(false)}
        onConfirm={() => handleProcessing(processorType)}
        loading={process.isLoading}
        processorType={processorType}
      />

      <ReferenceModal
        isOpen={showReference}
        toggle={() => setShowReference(false)}
        onConfirm={(data) => {
          setReferenceId(data.reference_id)
          setShowConfirm(true)
        }}
      />
    </>
  )
}

const missingFieldsForm = 'missingFieldsForm'
export const MissingFieldsModal = ({
  fields,
  isOpen,
  toggle,
  onSubmit,
  isLoading,
}) => {
  return (
    <Modal scrollable isOpen={isOpen} toggle={toggle} centered unmountOnClose>
      <ModalHeader toggle={toggle}>Missing Fields</ModalHeader>
      <ModalBody>
        <AvForm
          id={missingFieldsForm}
          onValidSubmit={(_, values) => {
            onSubmit(values)
          }}
        >
          {fields.map((e) => {
            return (
              <AvField
                key={e.key}
                name={e?.key}
                label={
                  <span>
                    {e?.label}{' '}
                    {!e?.comment ? null : (
                      <span className='text-muted'>({e?.comment})</span>
                    )}
                  </span>
                }
                defaultValue={e?.value}
                required
              />
            )
          })}
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <Button outline color='light' onClick={toggle} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          type='submit'
          formId={missingFieldsForm}
          disabled={isLoading}
          loading={isLoading}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const TransactionCardAdmin = (props) => {
  const {
    index,
    order,
    setIsOpen,
    setToConfirm,
    tab,
    archive,
    onUpdate,
    withProcess,
    multipleLoading,
    selectedWithdraws,
    setScreeningDetails,
  } = props
  const [archiving, setArchiving] = useState(-1)

  const formatter = getTrxFormatter(order)

  return (
    <div
      key={index}
      className='py-0 mb-3 bg-white rounded border position-relative'
      style={{ boxShadow: '0px 1px 0px #DFE1E6' }}
    >
      <Row className='p-3 m-0 border-bottom'>
        <Col xs={12} className='p-0 m-0 d-flex justify-content-between'>
          <StyledH5 className='text-dark font-weight-normal font-size-14'>
            TRX ID
          </StyledH5>
          <StyledH6 className='text-primary border-secondary font-size-12'>
            {order.transaction_ref}
          </StyledH6>
        </Col>
      </Row>
      <div className='p-3'>
        {tab !== TAB_KEY.PENDING_COMPLIANCE_REVIEW && (
          <>
            <div className='d-flex align-items-center justify-content-between align-items-center'>
              <StyledH6 className='text-dark font-weight-light font-size-14'>
                Type
              </StyledH6>
              <StyledH6 className='text-dark font-weight-light font-size-14'>
                {order.type?.name}
              </StyledH6>
            </div>

            <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                Type Method
              </h6>
              <h6 className='font-weight-normal mb-0 text-dark font-size-14'>
                {order.method?.name}
              </h6>
            </div>
          </>
        )}

        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Client name
          </h6>
          <h6 className='text-dark font-size-14 font-weight-normal mb-0'>
            {order.client?.first_name} {order.client?.last_name}
          </h6>
        </div>

        {tab !== TAB_KEY.PENDING_COMPLIANCE_REVIEW ? (
          <>
            <div className='d-flex mb-3 justify-content-between align-items-center'>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                Amount
              </h6>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                {formatter.format(order.amount)}
              </h6>
            </div>

            <div className='d-flex mb-3 justify-content-between align-items-center'>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                Source
              </h6>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                {formatter.format(order.base_amount)}
              </h6>
            </div>

            <div className='d-flex mb-3 justify-content-between align-items-center'>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                Source in USD
              </h6>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                {getCurrencyFormatter().format(order?.source_usd)}
              </h6>
            </div>

            <div className='d-flex mb-3 justify-content-between align-items-center'>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                Target in USD
              </h6>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                {getCurrencyFormatter().format(order?.target_usd)}
              </h6>
            </div>

            <div className='d-flex mb-3 justify-content-between align-items-center'>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                Rate
              </h6>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                {order.rate}
              </h6>
            </div>
            {![TAB_KEY.PAYMENTS, TAB_KEY.WITHDRAWS].includes(tab) && (
              <div className='d-flex mb-3 justify-content-between align-items-center'>
                <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                  ETA
                </h6>
                <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                  {order.eta}
                </h6>
              </div>
            )}

            <div className='d-flex mb-3 justify-content-between align-items-center'>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                Fee
              </h6>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                {formatter.format(order?.fee)}
              </h6>
            </div>

            <div className='d-flex mb-3 justify-content-between align-items-center'>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                TRX date
              </h6>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                {order.created_at}
              </h6>
            </div>

            <div className='d-flex mb-3 justify-content-between align-items-center'>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                Confirmed at
              </h6>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                {order.confirmed_at}
              </h6>
            </div>

            <div className='d-flex mb-3 justify-content-between align-items-center'>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                archive
              </h6>
              {archiving === index ? (
                <i className='bx bx-loader bx-spin font-size-16 text-primary'></i>
              ) : (
                <button
                  onClick={() => {
                    setArchiving(index)
                    archive.startFetch({
                      transaction_id: order.transaction_id,
                      status: order?.archived ? 0 : 1,
                    })
                  }}
                >
                  {order?.archived ? (
                    <ImageIcon
                      icon={unArchiveIcon}
                      hoverIcon={unArchiveIconHover}
                      alt='unarchive icon'
                    />
                  ) : (
                    <ImageIcon
                      icon={archiveIcon}
                      hoverIcon={archiveIconHover}
                      alt='archive icon'
                    />
                  )}
                </button>
              )}
            </div>
          </>
        ) : (
          <>
            <div className='d-flex mb-3 justify-content-between align-items-center'>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                Account holder
              </h6>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                {order.bank_account_details?.accountHolderName}
              </h6>
            </div>

            <div className='d-flex mb-3 justify-content-between align-items-center'>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                Screening
              </h6>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                {order.screening_status && (
                  <button
                    onClick={() => setScreeningDetails(order)}
                    type='button'
                  >
                    <BadgeX
                      size='md'
                      status={getStatus(order.screening_status).color}
                      pill
                    >
                      {getStatus(order.screening_status).label}
                    </BadgeX>
                  </button>
                )}
              </h6>
            </div>

            <div className='d-flex mb-3 justify-content-between align-items-center'>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                Provider
              </h6>
              <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
                {order.bank_account_details?.accountHolderName}
              </h6>
            </div>
          </>
        )}

        <div className='d-flex mb-0 justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            TRX Status
          </h6>
          <div className='w-50 d-block text-right'>
            {tab === TAB_KEY.PENDING_COMPLIANCE_REVIEW ? (
              <BadgeX status='secondary' size='md'>
                {order.status}
              </BadgeX>
            ) : order.status === 'Confirmed' ? (
              <Badge
                pill
                className='font-size-12 rounded bg-soft-success text-success py-1 my-2'
              >
                {order.status}
              </Badge>
            ) : (
              <Select
                name='currency_id'
                onChange={() => {
                  setToConfirm(order)
                  setIsOpen()
                }}
                defaultValue={{
                  value: order.status,
                  label: order.status,
                }}
                options={[{ value: 'Confirmed', label: 'Confirmed' }]}
                styles={myCustomStyles}
                components={{ Option: CustomOption }}
              />
            )}
          </div>
        </div>
        {withProcess && (
          <td className='text-center'>
            {order?.processed !== 1 &&
            order.status !== 'Confirmed' &&
            order.provider !== 'paypal' ? (
              <ProcessDropdown
                item={order}
                onUpdate={onUpdate}
                multipleLoading={multipleLoading}
                selectedWithdraws={selectedWithdraws}
              />
            ) : Array.isArray(order?.provider_response) ? (
              order?.provider_response[0]?.payment_id
            ) : order.provider === 'wise' ? (
              order?.provider_response?.id
            ) : (
              order?.transaction_ref
            )}
          </td>
        )}
      </div>
    </div>
  )
}

function PaymentActions({ transaction, onActionComplete, onStartRevert }) {
  const [showReminderHistory, setShowReminderHistory] = useState(false)
  const [isActionLoading, setIsActionLoading] = useState('')
  const [openDownloadModal, setOpenDownloadModal] = useState(false)

  const { startFetch: archiveTrx } = useFetch({
    action: archiveTransaction,
    withAdminAccess: true,
    onComplete: () => {
      setIsActionLoading('')
      if (typeof onActionComplete === 'function') {
        onActionComplete()
      }
    },
    onError: (err) => {
      toastr.error(err)
      setIsActionLoading('')
    },
  })

  const { startFetch: adminDownloadTransactionReceipt } = useFetch({
    action: adminDownloadProcessingTransactionReceipt,
    withAdminAccess: true,
    onComplete: (data) => {
      downloadFile(
        data,
        `Transaction-Receipt-${transaction?.transaction_ref}.pdf`,
      )
    },
    onError: (err) => toastr.error(err),
  })

  const actionsOptions = [
    {
      onClick: () => {
        setIsActionLoading('achiving')
        archiveTrx({
          transaction_id: transaction.transaction_id,
          status: transaction?.archived ? 0 : 1,
        })
      },
      icon: (
        <BxIcon
          name='bx bx-archive-in'
          className='font-size-14 text-muted'
          loading={isActionLoading === 'achiving'}
        />
      ),
      label: 'Archive',
    },
    {
      onClick: () => {
        setOpenDownloadModal(true)
      },
      icon: (
        <BxIcon
          name='bx bx-download'
          className='font-size-14 text-muted'
          loading={isActionLoading === 'downloading'}
        />
      ),
      label: 'Download',
    },
    transaction.status === 'Processed' && transaction.receipt_file
      ? {
          onClick: () => {
            adminDownloadTransactionReceipt({
              transaction_id: transaction.transaction_id,
            })
          },
          icon: <DownloadSimple size={16} />,
          label: 'Download client receipt',
        }
      : null,
    transaction.status !== 'Processed'
      ? null
      : {
          onClick: () => setShowReminderHistory(true),
          icon: (
            <BxIcon name='bx bx-send' className='font-size-14 text-muted' />
          ),
          label: 'Email reminders',
        },
    transaction.processed !== 0 || transaction.status !== 'Processed'
      ? null
      : {
          onClick: onStartRevert,
          icon: (
            <BxIcon name='bx bx-history' className='font-size-14 text-muted' />
          ),
          label: 'Revert',
        },
  ]

  return (
    <>
      <ActionsDropdown data={actionsOptions} />

      {!showReminderHistory ? null : (
        <RemindersHistoryModal
          isOpen={showReminderHistory}
          toggle={() => setShowReminderHistory((o) => !o)}
          transaction={transaction}
        />
      )}
      <DownloadStatementModal
        isOpen={openDownloadModal}
        toggle={() => setOpenDownloadModal((open) => !open)}
        item={{ ...transaction, ref: transaction?.transaction_ref }}
        isAdmin={true}
      />
    </>
  )
}

function RemindersHistoryModal({ isOpen, toggle, transaction }) {
  const { startFetch: sendReminder, isLoading: isSendingEmail } = useFetch({
    action: sendPaymentReminder,
    withAdminAccess: true,
    onComplete: toggle,
    onError: (err) => {
      toastr.error(err)
    },
  })

  const { data: historyData, isLoading: historyLoading } = useFetch({
    action: getPaymentReminderHistory,
    withAdminAccess: true,
    autoFetch: true,
    body: { transaction_id: transaction.transaction_id },
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Sent',
        Cell: ({ cellData }) => {
          return format(new Date(cellData), 'dd/MM/yyyy hh:mm')
        },
        accessor: 'last_event_time',
      },
      { Header: 'Status', accessor: 'status' },
      { Header: 'subject', accessor: 'subject' },
      { Header: 'Opened', accessor: 'opens_count' },
      { Header: 'Clicks', accessor: 'clicks_count' },
    ],
    [],
  )

  const handleSendReminder = useCallback(() => {
    sendReminder({ transaction_id: transaction.transaction_id })
  }, [transaction.transaction_id])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader>Email Reminder History</ModalHeader>
      <ModalBody>
        {historyLoading ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ minHeight: '10rem' }}
          >
            <Spinner color='primary' type='grow' />
          </div>
        ) : historyData?.length <= 0 ? (
          <div
            className='d-flex justify-content-center align-items-center flex-column text-muted'
            style={{ minHeight: '10rem', gap: '0.25rem' }}
          >
            <i className='bx bxs-inbox font-size-24' />
            <p className='font-size-16 mb-0'>No email reminders found.</p>
          </div>
        ) : (
          <DataTable data={historyData} columns={columns} />
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color='light'
          type='button'
          outline
          onClick={toggle}
          disabled={isSendingEmail}
        >
          Cancel
        </Button>
        <Button
          type='button'
          onClick={handleSendReminder}
          icon={<i className='bx bx-send font-size-14' />}
          disabled={isSendingEmail}
          loading={isSendingEmail}
        >
          Send reminder
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const WITHDRAW_ACTIONS_STATUS = {
  EXPORTING: 'EXPORTING',
  DOWNLOAD: 'DOWNLOAD',
  ARCHIVE: 'ARCHIVE',
  TICKET: 'SENDING',
}

function WithdrawActions({
  transaction,
  onStartRevert,
  onStartPreferredProvider,
  onStartUnProcess,
  onStartReview,
  onStartManualProcess,
  hasEditPermission,
  onActionComplete = () => {
    /* Default empty function. */
  },
}) {
  const [isActionLoading, setIsActionLoading] = useState(null)

  const exportTransaction = useFetch({
    action: exportWithdraw,
    withAdminAccess: true,
    onComplete: (data, body) => {
      openFile(data, body?.transaction_id, '_transactions.xlsx', () => {
        setIsActionLoading(null)
      })
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const archive = useFetch({
    action: archiveTransaction,
    withAdminAccess: true,
    onComplete: () => {
      setIsActionLoading(null)
      onActionComplete()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const ticket = useFetch({
    action: createZendeskTicket,
    withAdminAccess: true,
    onComplete: () => {
      setIsActionLoading(null)
      toastr.success('Email Sent Successfully')
    },
    onError: (error) => {
      toastr.error(error)
    },
  })
  const loginToken = useSelector((state) => state.Login?.loginToken)
  function handleDownloadWithdrawalReceipt(transaction) {
    const createdAtDate = transaction.created_at

    const ref = transaction.transaction_ref

    downloadWithdrawalReceipt(ref, loginToken, transaction.token)
      .then((res) => {
        if (
          Object.prototype.hasOwnProperty.call(res, 'success') &&
          res.success === false
        ) {
          return new Error('No data')
        }
        openFile(res?.data, `receipt-${ref}-${createdAtDate}.pdf`)
      })
      .catch(() => {
        toastr.error('Error downloading receipt', 'Error')
      })
      .finally(() => setIsActionLoading(null))
  }
  function handleDownloadProviderReceipt(transaction) {
    const createdAtDate = transaction.created_at

    const ref = transaction.transaction_ref

    downloadProviderReceipt(loginToken, {
      transaction_id: transaction.transaction_id,
    })
      .then((res) => {
        // if (!res.success) {
        //   toastr.error('Receipt can not be found.')
        //   return
        // }
        openFile(res?.data, `receipt-${ref}-${createdAtDate}.pdf`)
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
        toastr.error('Error downloading receipt', 'Error')
      })
  }
  const canManageTransactions = useHasPermission(
    ADMIN_PERMISSIONS.MANAGE_TRANSACTION_STATUS,
  )
  const withdrawsOptions = [
    {
      onClick: () => {
        setIsActionLoading(WITHDRAW_ACTIONS_STATUS.DOWNLOAD)
        handleDownloadWithdrawalReceipt(transaction)
      },
      icon: (
        <BxIcon
          name='bx bx-download'
          className='font-size-14 text-muted'
          loading={isActionLoading === WITHDRAW_ACTIONS_STATUS.DOWNLOAD}
        />
      ),
      label: 'Download Receipt',
    },
    {
      onClick: () => {
        setIsActionLoading(WITHDRAW_ACTIONS_STATUS.EXPORTING)
        exportTransaction.startFetch({
          transaction_id: transaction.transaction_id,
        })
      },
      icon: (
        <BxIcon
          name='bx bx-export'
          className='font-size-14 text-muted'
          loading={isActionLoading === WITHDRAW_ACTIONS_STATUS.EXPORTING}
        />
      ),
      label: 'Export',
    },
    transaction.processed !== 1 ||
    !canManageTransactions ||
    transaction?.provider === PROVIDER.MATCHMOVE
      ? null
      : {
          onClick: onStartUnProcess,
          icon: (
            <BxIcon name='bx bx-export' className='font-size-14 text-muted' />
          ),
          label: 'Un-process',
        },
    transaction.processed !== 0 || transaction.status !== 'Processed'
      ? null
      : {
          onClick: onStartRevert,
          icon: (
            <BxIcon name='bx bx-history' className='font-size-14 text-muted' />
          ),
          label: 'Revert',
        },
    {
      onClick: () => {
        setIsActionLoading(WITHDRAW_ACTIONS_STATUS.ARCHIVE)
        archive.startFetch({
          transaction_id: transaction.transaction_id,
          status: transaction?.archived ? 0 : 1,
        })
      },
      icon: (
        <BxIcon
          name='bx bx-archive-in'
          className='font-size-14 text-muted'
          loading={isActionLoading === WITHDRAW_ACTIONS_STATUS.ARCHIVE}
        />
      ),
      label: transaction?.archived ? 'Unarchive' : 'Archive',
    },
    {
      onClick: onStartPreferredProvider,
      icon: <BxIcon name='bx bx-star' className='font-size-14 text-muted' />,
      label: 'Preferred Provider',
    },
    {
      onClick: onStartReview,
      icon: (
        <BxIcon name='bx bx-revision' className='font-size-14 text-muted' />
      ),
      label: transaction.under_review
        ? 'Un-mark under review'
        : 'Mark under review',
    },
    [PROVIDER.PAYSEND].includes(transaction.provider) ||
    transaction.status !== 'Confirmed'
      ? null
      : {
          onClick: () => {
            handleDownloadProviderReceipt(transaction)
          },
          icon: (
            <BxIcon name='bx bx-download' className='font-size-14 text-muted' />
          ),
          label: 'Download Provider’s Receipt',
        },
    [PROVIDER.EBURY, PROVIDER.TRANSFERMATE].includes(transaction.provider) &&
    transaction.processed === 1
      ? {
          onClick: () => {
            setIsActionLoading(WITHDRAW_ACTIONS_STATUS.TICKET)
            ticket.startFetch({
              transaction_id: transaction.transaction_id,
            })
          },
          icon: (
            <BxIcon
              name='bx bx-mail-send'
              className='font-size-14 text-muted'
              loading={isActionLoading === WITHDRAW_ACTIONS_STATUS.TICKET}
            />
          ),
          label: 'Send email to provider',
        }
      : null,
    transaction?.processed !== 1 &&
    transaction.queued !== 1 &&
    transaction.status !== 'Confirmed' &&
    hasEditPermission
      ? {
          onClick: onStartManualProcess,
          icon: (
            <BxIcon name='bx bx-transfer' className='font-size-14 text-muted' />
          ),
          label: 'Manual process',
        }
      : null,
  ]

  return <ActionsDropdown data={withdrawsOptions} />
}

export function BxIcon({ loading, name, className }) {
  return (
    <i
      className={cx(
        `${loading ? 'bx bx-loader-circle bx-spin' : name}`,
        className,
      )}
    />
  )
}

function UserFullName({ data, onCompleteAction, onUserClick, prefillComment }) {
  const fullName = getFullName(data.client)

  return (
    <div className='position-relative w-100'>
      {data.client.is_flagged ? (
        <span
          className='position-absolute d-inline-flex align-items-center font-size-16'
          style={{
            top: '50%',
            transform: 'translate(-25px, -50%)',
            gap: '0.25rem',
          }}
        >
          <UserFlagging
            user={data?.client}
            onCompleteAction={onCompleteAction}
          />
        </span>
      ) : null}

      <button
        onClick={onUserClick}
        className='rp-btn-nostyle px-0 text-primary d-inline-block text-truncate'
        style={{
          maxWidth: 'calc(100% - 44px)',
        }}
        title={fullName}
      >
        {fullName}
      </button>

      <span
        className='position-absolute d-inline-flex align-items-center font-size-16'
        style={{
          top: '50%',
          transform: 'translate(5px, -50%)',
        }}
      >
        <VerifiedIcon
          status={
            data?.kyc_verified === 1
              ? VERIFIED_BADGE_STATUS.verified
              : VERIFIED_BADGE_STATUS.unverified
          }
          title={data.kyc_verified === 1 ? 'Kyc verified' : 'Kyc unverified'}
        />

        <UserComments
          comments={data.comments}
          userId={data.client.id}
          onCompleteAction={onCompleteAction}
          prefillComment={prefillComment}
        />
      </span>
    </div>
  )
}

export const VERIFIED_BADGE_STATUS = {
  verified: 'Verified',
  unverified: 'Unverified',
  deactivated: 'Deactivated',
}
export function VerifiedIcon({
  status,
  title,
  size = 16,
  weight = 'fill',
  className,
}) {
  switch (status) {
    case VERIFIED_BADGE_STATUS.verified: {
      return (
        <ShieldCheck
          size={size}
          weight={weight}
          className={cn('tw-text-systemGreen-100', className)}
          title={title || VERIFIED_BADGE_STATUS.verified}
        />
      )
    }
    case VERIFIED_BADGE_STATUS.unverified: {
      return (
        <ShieldWarning
          size={size}
          weight={weight}
          className={cn('tw-text-systemRed-100', className)}
          title={title || VERIFIED_BADGE_STATUS.unverified}
        />
      )
    }
    case VERIFIED_BADGE_STATUS.deactivated: {
      return (
        <BellSimpleSlash
          size={size}
          weight={weight}
          className={cn('tw-text-text-60', className)}
          title={title || VERIFIED_BADGE_STATUS.deactivated}
        />
      )
    }
    default: {
      return null
    }
  }
}

export default Transactions
