import { yupResolver } from '@hookform/resolvers/yup'
import { AvForm } from 'availity-reactstrap-validation'
import { t } from 'i18next'
import React, { forwardRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Col, FormGroup, Label, Row } from 'reactstrap'
import * as yup from 'yup'

import ControlledInput from '../../../../components/ControlledInput'
import { StyledH5 } from '../../../../components/Typo'
import { bambooRequest } from '../../../../store/bamboo/actions'
import { HiBobRequest } from '../../../../store/hiBob/actions'
import { SaplingRequest } from '../../../../store/sapling/actions'

export function getHrIntegrationName({ platform }) {
  switch (platform) {
    case 'sapling':
      return 'Sapling'
    case 'bamboo':
      return 'Bamboo'
    default:
      return 'HiBob'
  }
}

export const HrIntegrationSettings = forwardRef(function HrIntegrationSettings(
  { platform, onComplete, mode },
  ref,
) {
  const HiRequest = useSelector((state) => state.hiBob.request)
  const saplingRequest = useSelector((state) => state.sapling.saplingRequest)
  const bambooReq = useSelector((state) => state.bamboo.request)
  const dispatch = useDispatch()

  const integrationName = getHrIntegrationName({ platform })

  function getInputName() {
    switch (platform) {
      case 'sapling':
        return { url: 'sapling_url', key: 'sapling_apikey' }
      case 'bamboo':
        return { url: 'bamboohr_subdomain', key: 'bamboohr_apikey' }
      default:
        return { url: 'hibob_url', key: 'hibob_secret' }
    }
  }

  const schema = yup.object().shape({
    [getInputName().url]: yup.string().required(),
    [getInputName().key]: yup.string().required(),
  })

  const {
    handleSubmit,
    control,
    reset,
    watch,

    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {},
  })

  const url = watch(getInputName().url)
  const secret = watch(getInputName().key)

  useEffect(() => {
    if (mode === 'edit' && url?.length > 0 && secret?.length > 0) {
      if (platform === 'sapling') {
        dispatch(
          SaplingRequest({
            ...saplingRequest,
            sapling_url: url,
            sapling_apikey: secret,
          }),
        )
      } else if (platform === 'bamboo') {
        dispatch(
          bambooRequest({
            ...bambooReq,
            bamboohr_subdomain: url,
            bamboohr_apikey: secret,
          }),
        )
      } else {
        dispatch(
          HiBobRequest({ ...HiRequest, hibob_url: url, hibob_secret: secret }),
        )
      }
    }
  }, [url, secret])

  useEffect(() => {
    if (platform === 'sapling') {
      reset(saplingRequest)
    } else if (platform === 'bamboo') {
      reset(bambooReq)
    } else {
      reset(HiRequest)
    }
  }, [HiRequest, saplingRequest])

  function onSubmit(data) {
    onComplete(data)
  }

  return (
    <div className={`${mode === 'edit' ? 'border-bottom' : ''}`}>
      <div className={`${mode === 'edit' ? 'px-4 pt-4' : 'p-4 border-bottom'}`}>
        <StyledH5 className='font-size-16 mb-0 rp-font-bold'>
          {t('Automatically add employees and contractors to your account.', {
            integrationName,
          })}
        </StyledH5>
      </div>

      <div className='p-4'>
        <AvForm
          ref={ref}
          autoComplete='off'
          className='form-horizontal m-0'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col md={6} className='mb-2'>
              <FormGroup className='d-inline p-0 m-0'>
                <Label
                  style={{ whiteSpace: 'nowrap' }}
                  htmlFor='billing-name'
                  md='3'
                  className='col-form-label p-0 m-0 mb-2 d-block rp-font-bold font-size-14'
                >
                  {integrationName}{' '}
                  {platform === 'bamboo' ? t('Subdomain') : t('URL')}
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <div className='mb-3'>
                  {platform === 'bamboo' ? (
                    <span className='font-size-14 text-secondary'>
                      {t('Your subdomain using to connect your account.', {
                        integrationName,
                      })}{' '}
                      <a
                        href='http://help.remotepass.com/en/articles/5591011-how-to-integrate-bamboohr-with-remotepass'
                        rel='noreferrer'
                        target='_blank'
                        className='tw-border-b tw-border-current'
                      >
                        {t('Read More')}
                      </a>
                    </span>
                  ) : (
                    <span className='font-size-14 text-secondary'>
                      {t(
                        'Your URL is provided to you in your HireAPI configuration.',
                        { integrationName },
                      )}
                    </span>
                  )}
                </div>
                <Row className='p-0 m-0 align-items-center'>
                  <Col className='p-0 m-0'>
                    <ControlledInput
                      control={control}
                      placeholder={platform === 'bamboo' && 'subdomain'}
                      name={`${getInputName().url}`}
                      error={errors[getInputName().url]}
                    />
                  </Col>
                  {platform !== 'bamboo' ? null : (
                    <div className='tw-ms-1 tw-text-disabled'>
                      .bamboohr.com
                    </div>
                  )}
                </Row>
              </FormGroup>
            </Col>
            <Col md={6} className='mb-2'>
              <FormGroup className='d-inline p-0 m-0'>
                <Label
                  style={{ whiteSpace: 'nowrap' }}
                  htmlFor='billing-name'
                  md='3'
                  className='d-block col-form-label p-0 m-0 mb-2 font-size-14 rp-font-bold'
                >
                  {platform === 'bamboo'
                    ? t('integrationApiKey', { integrationName })
                    : t('integrationSecretKey', { integrationName })}

                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <div className='mb-3'>
                  {platform === 'bamboo' ? (
                    <span className='font-size-14 text-secondary'>
                      {t('Your API Key is provided to you in your account', {
                        integrationName,
                      })}{' '}
                      <a
                        href='http://help.remotepass.com/en/articles/5591011-how-to-integrate-bamboohr-with-remotepass'
                        rel='noreferrer'
                        target='_blank'
                        className='tw-border-b tw-border-current'
                      >
                        {t('Read More')}
                      </a>
                    </span>
                  ) : (
                    <span className='font-size-14 text-secondary'>
                      {t(
                        'Your Secret Key is provided to you in your HireAPI configuration',
                        { integrationName },
                      )}
                    </span>
                  )}
                </div>
                <ControlledInput
                  control={control}
                  name={`${getInputName().key}`}
                  error={errors[getInputName().key]}
                />
              </FormGroup>
            </Col>
          </Row>
        </AvForm>
      </div>
    </div>
  )
})
