import React, { useCallback, useMemo, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory, useLocation } from 'react-router-dom'
import { Play, PauseCircle } from '@phosphor-icons/react'
import toastr from 'toastr'
import isEmpty from 'lodash/isEmpty'
import set from 'lodash/set'
import Head from '../../../../../components/head'
import ModalHeader from '../../../../../components/ModalHeader'
import { Action } from '../action/action'
import { Delay } from '../delay'
import { Trigger } from '../trigger/trigger'
import { Divider } from '../divider'
import { TitleEditText } from '../automation-title-edit-text'
import { QuitConfirmModal } from './quit-confirm-modal'
import Button from '../../../../../components/ui/button'
import { useFetch } from '../../../../../helpers/hooks'
import { createAutomation } from '../../../../../services/api-automations'
import { transformAutomationResponse, updateAutomation } from '../tools'
import { useAutomationDetail } from '../hooks/use-automation-detail'
import { PlaceholdersProvider } from '../contexts/placeholders-context'
import { AutomationFilters } from '../filters/automation-filters'
import { schema } from '../tools/constants'
import { PublishConfirmModal } from './publish-confirm-modal'
import { UnpublishConfirmModal } from './unpublish-confirm-modal'
import { SaveConfirmModal } from './save-confirm-modal'
import { SlackConnectionProvider } from '../contexts/slack-connection-context'
import { useAutomationDefaultValues } from '../hooks/use-automation-default-values'
import { AutomationFormLoading } from '../automation-form-loading'
import { EmailRecipientsProvider } from '../contexts/email-recipient-context'
import isNill from '../../../../../utils/is-nill'
import { FiltersProvider } from '../contexts/filters-context'
import { AutomationMetrics } from '../tools/automation-metrics'

export function AutomationForm(props) {
  const { id, isTemplate, defaultValue } = props
  const history = useHistory()
  const [status, setStatus] = useState()
  const [quitModal, setQuitModal] = useState(false)
  const [publishModal, setPublishModal] = useState(false)
  const [unPublishModal, setUnPublishModal] = useState(false)
  const [saveModal, setSaveModal] = useState(false)
  const {
    isLoading,
    data,
    startFetch: refetchDetail,
  } = useAutomationDetail({ id })
  const goToList = () => history.push('/settings/automations')
  const currentAutomation = useMemo(
    () => (id ? transformAutomationResponse(data) : null),
    [data, id],
  )
  const context = { shouldValidate: false }
  const methods = useForm({
    resolver: yupResolver(schema),
    context,
    defaultValues: {
      focusedNode: 'trigger',
      ...(id ? {} : defaultValue),
    },
    values: id ? currentAutomation : undefined,
  })
  const stepsField = useFieldArray({
    control: methods.control,
    name: 'steps',
  })
  const { isLoading: isCreating, startFetch: _createAutomation } = useFetch({
    action: createAutomation,
    isOpenApi: true,
    onComplete: (res, data) => {
      AutomationMetrics.logAdded({ Source: 'scratch' })
      methods.setValue('id', res._id)
      _updateSteps({
        ...data,
        id: res._id,
      })
    },
    onError: (err) => toastr.error(err),
  })
  const { isLoading: iCreatingSteps, startFetch: _updateSteps } = useFetch({
    action: updateAutomation,
    isOpenApi: true,
    onComplete: (d, b) => {
      const metricPayload = {
        Source: 'Edition',
        Type: isTemplate ? 'template' : 'scratch',
        Trigger: b?.steps?.find((s) => s.type === 'trigger'),
        Steps: b?.steps?.filter((s) => s.type !== 'trigger'),
      }
      if (status === 'unPublish') {
        AutomationMetrics.logUnpublished(metricPayload)
        refetchDetail()
      } else {
        goToList()
        if (b.isPublished) {
          AutomationMetrics.logPublished(metricPayload)
        } else {
          AutomationMetrics.logSaved(metricPayload)
        }
        toastr.success(
          `${b?.name ?? 'Automation'} ${b.isPublished ? 'is now live' : 'is saved'}`,
          b.isPublished ? 'Published successfully' : 'Changes saved',
        )
      }
    },
    onError: (err) => toastr.error(err),
  })
  const isDirty = !isEmpty(methods.formState.dirtyFields)
  const handleSubmit = useCallback(
    (values) => {
      const payload = {
        id: id ?? values.id,
        ...(currentAutomation ? { ...currentAutomation } : {}),
        isTemplate: false,
        ...values,
      }
      if (!payload.id) {
        _createAutomation(payload)
      } else {
        _updateSteps(payload)
      }
    },
    [_createAutomation, _updateSteps, currentAutomation, id],
  )

  if (isLoading && isNill(data)) return <AutomationFormLoading />

  return (
    <PlaceholdersProvider>
      <EmailRecipientsProvider>
        <SlackConnectionProvider>
          <FiltersProvider>
            <FormProvider {...methods}>
              <div>
                <Head title='Automation set up' />
                <ModalHeader noExit>
                  <div className='tw-flex tw-flex-row tw-gap-2'>
                    <div className='tw-flex tw-flex-1 tw-items-center tw-justify-center'>
                      <TitleEditText current={currentAutomation} />
                    </div>
                    <Button
                      onClick={() =>
                        isDirty ? setQuitModal(true) : goToList()
                      }
                      outline
                      className='!tw-border-surface-30 !tw-bg-white'
                      textClassName='!tw-text-black'
                    >
                      Quit
                    </Button>

                    <Button
                      outline
                      disabled={!isDirty && id}
                      loading={
                        (status === 'save' || status === 'draft') &&
                        (isCreating || iCreatingSteps)
                      }
                      onClick={() => {
                        if (currentAutomation?.isPublished) {
                          set(context, 'shouldValidate', true)
                          methods.handleSubmit(
                            () => {
                              setSaveModal(true)
                              methods.setValue('isPublished', true)
                            },
                            () => {
                              setSaveModal(true)
                              methods.setValue('isPublished', false)
                            },
                          )()
                          setStatus('save')
                        } else {
                          methods.setValue('isPublished', false)
                          methods.handleSubmit(handleSubmit)()
                          setStatus('draft')
                        }
                      }}
                    >
                      Save
                    </Button>

                    {currentAutomation?.isPublished ? (
                      <Button
                        color='danger'
                        onClick={() => {
                          setUnPublishModal(true)
                          setStatus('unPublish')
                        }}
                        loading={
                          status === 'unPublish' &&
                          (isCreating || iCreatingSteps || isLoading)
                        }
                        outline
                        iconRight={<PauseCircle size={20} />}
                      >
                        Unplublish
                      </Button>
                    ) : (
                      <Button
                        loading={
                          status === 'publish' && (isCreating || iCreatingSteps)
                        }
                        onClick={() => {
                          setStatus('publish')
                          set(context, 'shouldValidate', true)
                          methods.setValue('isPublished', true)
                          methods.handleSubmit(
                            () => setPublishModal(true),
                            () =>
                              toastr.error(
                                'Make sure all the required fields are populated',
                                'Automation can’t be published',
                              ),
                          )()
                        }}
                        iconRight={<Play size={15} />}
                      >
                        Publish
                      </Button>
                    )}
                  </div>
                </ModalHeader>
                <div className='tw-m-4 tw-grid tw-grid-cols-1 tw-gap-2 md:tw-m-7 md:tw-grid-cols-3 md:tw-gap-7'>
                  <div />
                  <div>
                    {stepsField.fields.map((step, index, l) => (
                      <div key={step.id}>
                        <>
                          {step.type === 'trigger' ? (
                            <Trigger {...step} index={index} />
                          ) : step.type === 'action' ? (
                            <Action
                              {...step}
                              stepsField={stepsField}
                              index={index}
                            />
                          ) : (
                            <Delay
                              {...step}
                              stepsField={stepsField}
                              index={index}
                            />
                          )}

                          <Divider
                            index={index}
                            steps={stepsField.fields}
                            last={!l?.[index + 1]}
                            onClick={({ type }) => {
                              const payload = {
                                type,
                                ...(type === 'action'
                                  ? {
                                      actionType: null,
                                      recipients: [],
                                      subject: '',
                                      connection: null,
                                      body: '',
                                    }
                                  : {}),
                              }
                              if (!l?.[index + 1]) {
                                stepsField.append(payload)
                              } else {
                                stepsField.insert(index + 1, {
                                  ...payload,
                                  index: index === 0 ? index + 1 : index,
                                })
                              }
                            }}
                          />
                        </>
                      </div>
                    ))}
                  </div>

                  <div className='tw-order-first md:tw-order-last'>
                    <AutomationFilters />
                  </div>
                </div>
              </div>

              <QuitConfirmModal
                onSave={() => {
                  set(context, 'shouldValidate', true)
                  setStatus('save')
                  methods.handleSubmit(handleSubmit)()
                }}
                isPublished={currentAutomation?.isPublished}
                isOpen={quitModal}
                toggle={() => setQuitModal((prevState) => !prevState)}
              />
              <UnpublishConfirmModal
                onUnpulish={() => {
                  methods.setValue('isPublished', false)
                  methods.handleSubmit(handleSubmit)()
                }}
                isOpen={unPublishModal}
                toggle={() => setUnPublishModal((prevState) => !prevState)}
              />
              <PublishConfirmModal
                onPublish={() => methods.handleSubmit(handleSubmit)()}
                isOpen={publishModal}
                toggle={() => setPublishModal((prevState) => !prevState)}
              />
              <SaveConfirmModal
                onSave={() => methods.handleSubmit(handleSubmit)()}
                isOpen={saveModal}
                toggle={() => setSaveModal((prevState) => !prevState)}
              />
            </FormProvider>
          </FiltersProvider>
        </SlackConnectionProvider>
      </EmailRecipientsProvider>
    </PlaceholdersProvider>
  )
}

export function NewAutomationModal() {
  const location = useLocation()
  const id = new URLSearchParams(location.search).get('id')
  const isTemplate = new URLSearchParams(location.search).get('is_template')
  const { isLoading, values } = useAutomationDefaultValues({
    enabled: !id,
  })

  if (id) return <AutomationForm id={id} isTemplate={isTemplate} />

  if (isLoading) return <AutomationFormLoading />

  return <AutomationForm defaultValue={values} />
}
