import React, { useState, useMemo } from 'react'
import { Card, Container, FormGroup, Label } from 'reactstrap'
import { useFetch } from '../../../../helpers/hooks'
import {
  downloadContractPdf,
  getEORContractsAmendments,
  signAsProvider,
} from '../../../../services/api'
import Pagination from '../../../../components/ui/pagination'
import Loader from '../../../../components/ui/loader'
import NavTabs from '../../../../components/ui/nav-tabs'
import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import { useFilters } from '../cards/use-filters'
import { format } from 'date-fns'
import SearchBar from '../../../../components/SearchBar'
import { useLocation } from 'react-router-dom'
import AmendmentsTable from './components/amendments-table'
import { useUrlState } from '../../../../helpers/hooks/use-url-state'
import SignModal from '../../../Contract/ContractDetail/components/SignModal'
import { downloadFile } from '../../../Contract/utils/helpers'
import toastr from 'toastr'

function Amendments() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const action = searchParams.get('action')

  const amendmentsTabs = {
    pending: {
      label: 'Pending',
      value: 'pending',
      status: 'pending',
    },
    pending_signature: {
      label: 'Pending Signature',
      value: 'pending_signature',
      status: 'pending_signature',
    },
    signed: {
      label: 'Signed',
      value: 'signed',
      status: 'signed',
    },
    pending_client_signature: {
      label: 'Pending Client Signature',
      value: 'pending_client_signature',
      status: 'pending_signature',
      client_signed: 0,
    },
    pending_rp_signature: {
      label: 'Pending RP Signature',
      value: 'pending_rp_signature',
      status: 'pending_signature',
      contractor_signed: 0,
    },
  }
  const [activeTab, setActiveTab] = useUrlState({
    name: 'tab',
    defaultValue: amendmentsTabs.pending.value,
  })
  const [showSignModal, setShowSignModal] = useState(false)
  const [clickedAmendmentId, setClickedAmendmentId] = useState(null)

  const [filters, handleFiltersChange] = useFilters({
    page: 1,
    month: action === 'from-todo' ? null : new Date(),
    status:
      amendmentsTabs?.[activeTab]?.status ?? amendmentsTabs.pending.status,
    contractor_signed: amendmentsTabs?.[activeTab]?.contractor_signed,
    client_signed: amendmentsTabs?.[activeTab]?.client_signed,
  })

  const {
    data,
    paginator,
    isLoading,
    startFetch: refresh,
  } = useFetch(
    {
      action: getEORContractsAmendments,
      withAdminAccess: true,
      autoFetch: true,
      body: {
        ...filters,
        month: filters?.month ? format(filters?.month, 'yyyy-MM-dd') : null,
      },
    },
    [filters],
  )

  const { startFetch: downloadContract, isLoading: isDownloadingContract } =
    useFetch({
      action: downloadContractPdf,
      withAdminAccess: true,
      onComplete: (data) => {
        downloadFile(data, `SOW-${selectedContract?.ref}.pdf`)
      },
    })

  const { startFetch: signContract, isLoading: isSigningContract } = useFetch({
    action: signAsProvider,
    withAdminAccess: true,
    onComplete: () => {
      refresh?.()
      toastr.success('Provided signed successfully')
      setShowSignModal(false)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const navOptions = Object.values(amendmentsTabs).map((option) => ({
    ...option,
    disabled: isLoading,
  }))
  const showPaginator = paginator?.first_page_url !== paginator?.last_page_url

  const selectedContract = useMemo(() => {
    if (isLoading || !showSignModal || typeof clickedAmendmentId !== 'number') {
      return undefined
    }
    const i = data?.findIndex((d) => d?.amendment?.id === clickedAmendmentId)
    return data?.[i]?.contract
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, showSignModal, clickedAmendmentId])

  return (
    <div className='page-content'>
      <Container fluid className='p-0 m-0'>
        <h1 style={{ marginBottom: '2rem' }}>Amendments</h1>

        <Card>
          <NavTabs
            className='p-3'
            options={navOptions}
            activeOption={activeTab}
            onClickOption={(option) => {
              setActiveTab(option.value)
              handleFiltersChange('status', option.status)
              if (option.contractor_signed !== undefined) {
                handleFiltersChange('client_signed', undefined)
                handleFiltersChange(
                  'contractor_signed',
                  option.contractor_signed,
                )
              } else if (option.client_signed !== undefined) {
                handleFiltersChange('contractor_signed', undefined)
                handleFiltersChange('client_signed', option.client_signed)
              } else {
                handleFiltersChange('contractor_signed', undefined)
                handleFiltersChange('client_signed', undefined)
              }
            }}
          />
          <div className='d-flex align-items-center p-3 gap-8'>
            <CustomDatePicker
              value={filters.month}
              handleOnChange={(val) =>
                handleFiltersChange('month', val, { action: 'clear' })
              }
              maxDate={new Date()}
              placeholder='Select Month...'
              clearable
              label='Month'
              dateFormat='MM-yyyy'
              wrapperStyles={{ minWidth: 170 }}
              handleClear={() =>
                handleFiltersChange('month', null, { action: 'clear' })
              }
              showMonthYearPicker
              showFullMonthYearPicker
            />
            <FormGroup className='mb-0 flex-grow-1'>
              <Label>Search</Label>
              <SearchBar
                query={filters.search}
                onQueryChanged={(e) =>
                  handleFiltersChange('search', e, { action: 'clear' })
                }
                placeholder='Search by employee name or contract ID'
              />
            </FormGroup>
          </div>

          {isLoading ? (
            <>
              <Loader style={{ minHeight: 300 }} className='w-100' />
            </>
          ) : data?.length === 0 ? (
            <div className='d-flex w-100 p-5 justify-content-center align-items-center font-size-20'>
              No Amendments Pending
            </div>
          ) : (
            <AmendmentsTable
              canSign={activeTab === amendmentsTabs.pending_rp_signature.value}
              handleOnSign={() => {
                setShowSignModal(true)
              }}
              clickedAmendmentToSign={(id) => setClickedAmendmentId(id)}
              showContractInfo
              data={data}
              refresh={refresh}
            />
          )}
          {showPaginator && !isLoading && (
            <div className='ml-auto px-3 my-3 w-100 d-flex justify-content-end'>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={paginator?.per_page ?? 10}
                totalItemsCount={paginator?.total ?? 0}
                onChange={(newPage) => handleFiltersChange('page', newPage)}
              />
            </div>
          )}
        </Card>
      </Container>

      {Boolean(showSignModal) && Boolean(selectedContract) && (
        <SignModal
          data={selectedContract}
          downloading={isDownloadingContract}
          handleDownloadContract={() => {
            downloadContract({
              contractToken: selectedContract?.file,
              ref: selectedContract?.ref,
            })
          }}
          handleSignClick={() => {
            signContract({
              contract_id: selectedContract?.id,
            })
          }}
          loadingSignature={isSigningContract}
          setShowSignModal={setShowSignModal}
          showSignModal={showSignModal}
          isAdmin={true}
        />
      )}
    </div>
  )
}
export default Amendments
