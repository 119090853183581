import React from 'react'
import { t } from 'i18next'
import { ArrowLeft } from '@phosphor-icons/react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { cn } from 'ui'

import { ModalCloseButton } from '../../../../components/Common/modal-close-button'
import { workerTabs } from './manage-policy-workers'

const HeaderWithContract = ({
  handleClose,
  selectedContract,
  contractDetails,
  setSelectedContract,
}) => {
  return (
    <div className='tw-flex tw-flex-col tw-p-4'>
      <div className='tw-flex tw-items-center'>
        <button type='button' onClick={() => setSelectedContract(undefined)}>
          <ArrowLeft className='tw-me-2 rtl:tw-rotate-180' />
        </button>
        <div className='tw-flex-1 tw-font-semibold'>
          {contractDetails?.status?.name}
        </div>
        <ModalCloseButton
          toggle={handleClose}
          className='!tw-text-black'
          size={24}
        />
      </div>
      <span
        className={cn(
          'tw-text-sm tw-text-text-60',
          !!selectedContract && 'tw-ml-[28px]',
        )}
      >
        {`#${selectedContract}`}
      </span>
    </div>
  )
}

const HeaderWhenReassigning = ({ handleClose, setIsReAssigning }) => {
  return (
    <div className='tw-flex tw-flex-col tw-p-4'>
      <div className='tw-flex tw-items-center'>
        <button type='button' onClick={() => setIsReAssigning({})}>
          <ArrowLeft className='tw-me-2 rtl:tw-rotate-180' />
        </button>
        <div className='tw-flex-1 tw-font-semibold'>
          {t('Select new policy')}
        </div>
        <ModalCloseButton
          toggle={handleClose}
          className='!tw-text-black'
          size={24}
        />
      </div>
    </div>
  )
}

const HeaderWithoutContract = ({
  handleClose,
  policy,
  tabs,
  activeTab,
  setActiveTab,
  setUnAssignedWorkersSearch,
  totalUnassignedWorkers,
  selectedWorkers,
}) => {
  return (
    <>
      <div className='tw-flex tw-flex-col tw-p-4'>
        <div className='tw-flex tw-items-center'>
          <div className='tw-flex-1 tw-font-semibold'>
            {t('Manage workers')}
          </div>
          <ModalCloseButton
            toggle={handleClose}
            className='!tw-text-black'
            size={24}
          />
        </div>
        <span className='tw-text-sm tw-text-text-60'>
          {t('Assign policy to your workers', { policy: policy?.name })}
        </span>
      </div>
      <Nav
        tabs
        justified
        className='tw-items-stretch tw-overflow-hidden tw-rounded !tw-border-b-0 !tw-border-t !tw-border-t-surface-30'
      >
        {tabs.map(({ label, value, count }) => {
          const isActive = value === activeTab

          return (
            <NavItem key={value}>
              <NavLink
                tag='button'
                type='button'
                className={cn(
                  '!tw-mb-0 !tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-gap-2 !tw-border-x-0 !tw-border-b-2 !tw-border-t-0 !tw-px-4 !tw-py-6 tw-text-sm tw-font-medium',
                  isActive
                    ? '!tw-border-b-primary-100 !tw-bg-primary-10 tw-text-primary-100'
                    : 'tw-text-text-80 hover:!tw-border-transparent',
                )}
                onClick={() => {
                  setActiveTab(value)
                  setUnAssignedWorkersSearch('')
                }}
                disabled={
                  value === workerTabs.unassignedWorkers &&
                  activeTab === workerTabs.assignedWorkers &&
                  selectedWorkers
                }
              >
                <div>{label}</div>
                {!count &&
                count !== 0 &&
                value !== workerTabs.unassignedWorkers ? null : (
                  <div
                    className={cn(
                      'tw-min-h-5 tw-min-w-5 tw-rounded tw-border tw-p-0.5 tw-text-xs',
                      isActive
                        ? 'tw-border-primary-100 tw-bg-primary-100 tw-font-bold tw-text-white'
                        : 'tw-border-surface-30 tw-bg-transparent tw-font-semibold',
                    )}
                  >
                    {count}
                    {value === workerTabs.unassignedWorkers &&
                      totalUnassignedWorkers}
                  </div>
                )}
              </NavLink>
            </NavItem>
          )
        })}
      </Nav>
    </>
  )
}

export default function ManagePolicyHeader({
  selectedContract,
  isReAssigning,
  handleClose,
  contractDetails,
  setSelectedContract,
  setIsReAssigning,
  policy,
  tabs,
  activeTab,
  setActiveTab,
  setUnAssignedWorkersSearch,
  totalUnassignedWorkers,
  selectedWorkers,
}) {
  if (isReAssigning) {
    return (
      <HeaderWhenReassigning
        handleClose={handleClose}
        setSelectedContract={setSelectedContract}
        setIsReAssigning={setIsReAssigning}
      />
    )
  }

  if (selectedContract) {
    return (
      <HeaderWithContract
        handleClose={handleClose}
        selectedContract={selectedContract}
        contractDetails={contractDetails}
        setSelectedContract={setSelectedContract}
      />
    )
  }

  return (
    <HeaderWithoutContract
      handleClose={handleClose}
      policy={policy}
      tabs={tabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      setUnAssignedWorkersSearch={setUnAssignedWorkersSearch}
      totalUnassignedWorkers={totalUnassignedWorkers}
      selectedWorkers={selectedWorkers}
    />
  )
}
