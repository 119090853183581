import { yupResolver } from '@hookform/resolvers/yup'
import {
  ArrowSquareOut,
  Copy,
  Info,
  LinkSimple,
  Warning,
} from '@phosphor-icons/react'
import { format } from 'date-fns'
import { t } from 'i18next'
import React, { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import bambooLogo from '../../../assets/images/bamboo.png'
import bobLogo from '../../../assets/images/bob.png'
import netSuiteLogo from '../../../assets/images/brands/net-suite-1.svg'
import quickBooksLogo from '../../../assets/images/brands/quick-books.png'
import freshbooksLogo from '../../../assets/images/freshbooks.png'
import oktaLogo from '../../../assets/images/okta.png'
import saplingLogo from '../../../assets/images/sapling.png'
import xeroLogo from '../../../assets/images/xero.png'
import zohoInvoiceLogo from '../../../assets/images/zoho_invoice.png'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import ControlledInput from '../../../components/ControlledInputNew'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import Button from '../../../components/ui/button'
import InputFeedback from '../../../components/ui/input-feedback'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  connectFreshBooks,
  connectQuickbooks,
  connectXero,
  connectZoho,
  disableOktaIntegration,
  disconnectXero,
  enableOktaIntegration,
  updateDataToBamboo,
  updateDataToHibob,
  updateDataToSapling,
  updateOktaIntegration,
  validateOktaMetadataUrl,
} from '../../../services/api'
import { bambooRequest } from '../../../store/bamboo/actions'
import { HiBobRequest } from '../../../store/hiBob/actions'
import { SaplingRequest } from '../../../store/sapling/actions'
import { PERMISSION_GROUP } from '../manage-role'

const integrationDescription = {
  accounting: ({ integrationName, orgName }) =>
    t('integrationDescriptionAccounting', { integrationName, orgName }),
  hr: ({ integrationName }) =>
    t('integrationDescriptionHr', { integrationName }),
  authentication: () => t('integrationDescriptionAuthentication'),
}

function IntegrationCard({ item, onUpdate }) {
  const history = useHistory()
  const internalIntegration = item?.auth_type === 'internal'
  const saplingRequest = useSelector((state) => state.sapling.saplingRequest)
  const HiRequest = useSelector((state) => state.hiBob.request)
  const request = useSelector((state) => state.bamboo.request)

  const [isEnableOktaFlowOpen, setIsEnableOktaFlowOpen] = useState(false)
  const [isDisableOktaFlowOpen, setIsDisableOktaFlowOpen] = useState(false)

  const dispatch = useDispatch()

  function getDescription(e) {
    const orgName = e?.organisation_name ?? e?.name

    const accountingKeys = ['xero', 'quickbooks', 'zoho', 'netsuite', 'fresh']
    const hrKeys = ['hibob', 'bamboohr', 'sapling']
    const authenticationKeys = ['okta']

    if (accountingKeys.includes(e?.code)) {
      return integrationDescription.accounting({
        integrationName: e?.name ?? e?.code,
        orgName,
      })
    } else if (hrKeys.includes(e?.code)) {
      return integrationDescription.hr({
        integrationName: e?.name ?? e?.code,
        orgName,
      })
    } else if (authenticationKeys.includes(e?.code)) {
      return integrationDescription.authentication({
        integrationName: e?.name ?? e?.code,
        orgName,
      })
    }

    return null
  }

  function getConnectFunction(e) {
    switch (e?.code) {
      case 'xero':
        return connectXero
      case 'quickbooks':
        return connectQuickbooks
      case 'zoho':
        return connectZoho
      case 'hibob':
        return updateDataToHibob
      case 'fresh':
        return connectFreshBooks
      case 'sapling':
        return updateDataToSapling
      case 'bamboohr':
        return updateDataToBamboo
      case 'netsuite':
      default:
        return null
    }
  }

  function handleReview(e) {
    if (e?.code === 'sapling') {
      dispatch(
        SaplingRequest({ ...saplingRequest, session_id: item?.session_id }),
      )
      history.push('/integrations/update-sapling')
    } else if (e?.code === 'hibob') {
      dispatch(HiBobRequest({ ...HiRequest, session_id: item?.session_id }))
      history.push('/integrations/update-hibob')
    } else if (e?.code === 'bamboohr') {
      dispatch(bambooRequest({ ...request, session_id: item?.session_id }))
      history.push('/integrations/update-bamboo')
    } else if (e.code === 'okta') {
      setIsEnableOktaFlowOpen(true)
    }
  }

  const updateData = useFetch(
    {
      action: getConnectFunction(item),
      onComplete: onUpdate,
    },
    [],
  )

  function handleEnable() {
    if (item?.code === 'okta') {
      setIsEnableOktaFlowOpen(true)
    } else if (item.synched === 1 && item?.code !== 'netsuite') {
      updateData.startFetch({ session_id: item?.session_id, connected: 1 })
    } else {
      history.push(`/integrations/${item?.code}`)
    }
  }

  const connect = useFetch(
    {
      action: getConnectFunction(item),
      onComplete: (data) => {
        if (data?.redirect_url) {
          window.location.replace(data?.redirect_url)
        }
      },
    },
    [],
  )

  const disconnect = useFetch(
    {
      action: disconnectXero,
      onComplete: onUpdate,
    },
    [],
  )

  function handleSynced1(item) {
    if (item.synched === 1) {
      history.push(
        `/integrations/update-${item?.code === 'fresh' ? 'freshbooks' : item?.code}`,
      )
    } else {
      history.push(
        `/integrations/${item?.code === 'fresh' ? 'freshbooks' : item?.code}`,
      )
    }
  }

  const { hasAccess } = usePermissions()
  const canManageIntegrations = hasAccess(permissions.ManageCompanyIntegration)

  return (
    <>
      <div className='tw-relative tw-flex tw-h-full tw-flex-col tw-rounded tw-border tw-border-surface-30 tw-p-4'>
        <div className='tw-flex tw-justify-between'>
          <div className='tw-relative'>
            {!item.connected ? null : (
              <div className='tw-absolute tw-size-3 tw-rounded-full tw-bg-green-100 tw-ring-2 tw-ring-white'></div>
            )}
            {integrationIcon(item)}
          </div>

          <div>
            {item.synched & item.last_sync_date ? (
              <div className='tw-text-end tw-text-xs'>
                <div className='tw-text-text-80'>{t('Last synced')}</div>
                <div>
                  {format(item.last_sync_date * 1000, 'dd/MM/yyyy, HH:mm aaa')}
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <h4 className='tw-mt-4 tw-text-xl tw-font-bold'>{item?.name}</h4>
        <p className='tw-line-clamp-4 tw-text-xs tw-text-text-80'>
          {getDescription(item)}
        </p>

        <div className='tw-mt-auto'>
          {item.connected ? (
            <div className='tw-flex tw-flex-wrap tw-gap-2 *:tw-flex-1'>
              <PermissionTooltip
                showing={!canManageIntegrations}
                id={`add-user-btn-tooltip-${item?.id}`}
                area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
              >
                <Button
                  onClick={() => {
                    if (internalIntegration && item?.code !== 'netsuite') {
                      handleReview(item)
                    } else {
                      handleSynced1(item)
                    }
                  }}
                  disabled={!canManageIntegrations}
                  type='button'
                  color='light'
                  outline
                >
                  {item.synched === 1
                    ? t('Review')
                    : internalIntegration
                      ? t('Review')
                      : t('Complete')}
                </Button>
              </PermissionTooltip>
              <PermissionTooltip
                showing={!canManageIntegrations}
                id={`add-user-btn-tooltip-${item?.id}`}
                area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
              >
                <Button
                  type='button'
                  loading={updateData?.isLoading || disconnect.isLoading}
                  disabled={
                    updateData?.isLoading ||
                    disconnect.isLoading ||
                    !canManageIntegrations
                  }
                  color='danger'
                  outline
                  onClick={() => {
                    if (item?.code === 'okta') {
                      setIsDisableOktaFlowOpen((o) => !o)
                      return
                    }
                    if (internalIntegration && item?.code !== 'netsuite') {
                      if (!updateData.isLoading) {
                        updateData.startFetch({
                          session_id: item?.session_id,
                          connected: 0,
                        })
                      }
                    } else {
                      if (!disconnect.isLoading) {
                        disconnect.startFetch({
                          session_id: item?.session_id,
                        })
                      }
                    }
                  }}
                >
                  {internalIntegration ? t('Disable') : t('Disconnect')}
                </Button>
              </PermissionTooltip>
            </div>
          ) : (
            <PermissionTooltip
              showing={!canManageIntegrations}
              id={`add-user-btn-tooltip-${item?.id}`}
              area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
            >
              <Button
                type='button'
                block
                loading={
                  updateData?.isLoading ||
                  connect.isLoading ||
                  disconnect.isLoading
                }
                disabled={
                  updateData?.isLoading ||
                  connect.isLoading ||
                  disconnect.isLoading ||
                  !canManageIntegrations
                }
                onClick={() => {
                  if (internalIntegration) {
                    handleEnable()
                  } else {
                    if (!connect.isLoading) {
                      connect.startFetch()
                    }
                  }
                }}
              >
                {internalIntegration ? t('Enable') : t('Connect')}
              </Button>
            </PermissionTooltip>
          )}
        </div>
      </div>

      {isEnableOktaFlowOpen && (
        <EnableOktaModal
          isOpen={isEnableOktaFlowOpen}
          toggle={() => setIsEnableOktaFlowOpen(false)}
          onEnable={() => {
            setIsEnableOktaFlowOpen(false)
            onUpdate?.()
          }}
          oktaData={item.code === 'okta' ? item : null}
        />
      )}
      {isDisableOktaFlowOpen && (
        <DisableOktaModal
          isOpen={isDisableOktaFlowOpen}
          toggle={() => setIsDisableOktaFlowOpen(false)}
          onDisable={() => {
            setIsDisableOktaFlowOpen(false)
            onUpdate?.()
          }}
        />
      )}
    </>
  )
}

function EnableOktaModal({ isOpen, toggle, onEnable, oktaData = null }) {
  const isOktaConnected = oktaData?.connected === 1

  const [copyButtonText, setCopyButtonText] = useState(t('Copy Link'))
  const { startFetch: enableOktaInt, isLoading: isEnablingOkta } = useFetch({
    action: isOktaConnected ? updateOktaIntegration : enableOktaIntegration,
    onComplete: (data) => {
      if (data.connected === 1) {
        toastr.success(
          t('You can now use Okta SSO services'),
          t('Integration enabled'),
        )
        onEnable?.()
      }
    },
    onError: (err) => toastr.error(err),
  })

  const { startFetch: validateOktaUrl, isLoading: isValidatingUrl } = useFetch({
    action: validateOktaMetadataUrl,
    onComplete: (data) => {
      if (data.isValid) {
        enableOktaFlow()
      } else {
        toastr.error(data?.validationError)
      }
    },
    onError: (_, s) => {
      toastr.error(s.message?.[0], s.error)
    },
  })

  const schema = yup.object().shape({
    url: yup
      .string()
      .url(t('Please enter a valid url'))
      .required()
      .label(t('Metadata URL')),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      url: isOktaConnected ? oktaData?.metadata_url : '',
    },
  })

  const { url } = useWatch({ control })

  const callbackUrl = oktaData?.callback_url

  const enableOktaFlow = () => {
    enableOktaInt({
      metadata_url: url,
    })
  }

  const verifyMetadataUrl = () => {
    validateOktaUrl({ metadataUrl: url })
  }

  const copyOktaCallbackUrl = () => {
    navigator.clipboard.writeText(callbackUrl).then(
      () => {
        toastr.info(t('Okta Callback URL copied'))

        setCopyButtonText(t('Copied'))
        setTimeout(() => {
          setCopyButtonText(t('Copy Link'))
        }, 3000)
      },
      () => {
        toastr.error(t('Failed to copy Okta Callback URL'))
      },
    )
  }

  return (
    <Modal centered size='lg' isOpen={isOpen} toggle={toggle}>
      <ModalHeader
        className='!tw-px-7 !tw-pt-7'
        close={<ModalCloseButton toggle={toggle} />}
      >
        <img src={oktaLogo} height={40} width={40} alt='' />
        <div className='tw-text-xl'>{t('Okta integration')}</div>
        <div className='tw-text-sm tw-text-text-80'>
          {t(
            'Please fill in the following details to install single sign-on (SSO)',
          )}
        </div>
      </ModalHeader>
      <ModalBody className='!tw-p-7'>
        <div className='tw-flex tw-flex-col'>
          <div className='bg-primary-10 tw-p-6'>
            <Info size={24} className='tw-text-primary-100' />
            <a
              href='https://www.remotepass.com/integrations'
              target='_blank'
              className='tw-mt-2 tw-flex tw-w-fit tw-items-center tw-text-xl tw-text-secondary-120'
              rel='noreferrer'
            >
              {t('Add RemotePass to Okta')} <ArrowSquareOut size={20} />
            </a>
            <span className='tw-text-sm tw-text-text-80'>
              {t(
                'Start by integrating RemotePass into the Okta platform with this URL. This step is required to proceed with and finalize the integration details.',
              )}
            </span>

            <div className='tw-mt-6 tw-flex tw-flex-wrap tw-gap-4 sm:tw-flex-nowrap'>
              <div className='tw-flex tw-flex-1 tw-items-center tw-gap-2 tw-rounded tw-bg-surface-30 tw-p-2'>
                <LinkSimple size={24} />
                <div className='tw-flex-1 tw-text-text-80'>
                  <span className='tw-text-[10px]'>{t('Callback URL')}</span>
                  <div
                    className='tw-max-w-[200px] tw-truncate tw-text-sm sm:tw-max-w-[300px] md:tw-max-w-[475px]'
                    title={callbackUrl}
                  >
                    {callbackUrl}
                  </div>
                </div>
              </div>

              <Button
                onClick={copyOktaCallbackUrl}
                className='tw-flex-initial'
                icon={<Copy size={20} />}
              >
                {copyButtonText}
              </Button>
            </div>
          </div>

          <div className='tw-mb-3 tw-mt-6 tw-w-full tw-border-t tw-border-surface-30' />

          <div className='tw-py-4'>
            <InputGroup>
              <InputGroupText className='border-right-0 d-flex !tw-rounded-r-none'>
                <LinkSimple size={24} />
              </InputGroupText>

              <ControlledInput
                wrapperClassName='tw-flex-1'
                className='!tw-rounded-l-none'
                control={control}
                name='url'
                id='url'
                label={t('Metadata URL')}
                placeholder={t('Metadata URL')}
                showError={false}
              />
            </InputGroup>
            {errors?.url ? (
              <InputFeedback className='tw-mt-1'>
                {errors?.url?.message}
              </InputFeedback>
            ) : (
              <div className='tw-mt-2 tw-flex tw-items-start tw-gap-1 tw-text-xs tw-text-text-80'>
                <Info size={16} />
                <span>
                  {t(
                    'The Okta metadata URL with the configuration details required for Single Sign-On (SSO)',
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter className='!tw-px-7 !tw-pb-7'>
        <Button type='button' onClick={toggle}>
          {t('Cancel')}
        </Button>
        <Button
          disabled={!url || isEnablingOkta || isValidatingUrl}
          type='button'
          onClick={handleSubmit(verifyMetadataUrl)}
        >
          {isEnablingOkta || isValidatingUrl
            ? t('Verifying...')
            : isOktaConnected
              ? t('Save')
              : t('Enable')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function DisableOktaModal({ isOpen, toggle, onDisable }) {
  const { startFetch: disableOktaInt, isLoading: isDisablingOkta } = useFetch({
    action: disableOktaIntegration,
    onComplete: (data) => {
      if (data.connected === 0) {
        toastr.success(t('Okta Integration disabled'))
        onDisable?.()
      }
    },
    onError: (err) => toastr.error(err),
  })

  return (
    <ConfirmationModal
      isOpen={isOpen}
      toggle={toggle}
      content={
        <>
          <div className='tw-mb-2 tw-flex tw-items-center tw-justify-between'>
            <Warning size={24} className='tw-fill-systemGold-100' />
            <ModalCloseButton toggle={toggle} />
          </div>
          <p className='tw-mb-2 tw-text-xl tw-text-secondary-120'>
            {t('Are you sure you want to disable this Integration?')}
          </p>
          <p className='tw-text-sm tw-text-text-80'>
            {t(
              "You won't be able to use this integration. you will have to re-enable it to use it again",
            )}
          </p>
        </>
      }
      negativeCaption={t('No, Close')}
      caption={t('Yes, Disable')}
      buttonColor='danger'
      onConfirm={() => {
        disableOktaInt()
      }}
      confirmLoading={isDisablingOkta}
    />
  )
}

function createIntegrationImageIcon({ src, alt }) {
  return (
    <img
      src={src}
      alt={alt}
      className='tw-size-14 tw-overflow-clip tw-rounded-full'
      height={56}
      width={56}
    />
  )
}

function integrationIcon(item) {
  switch (item?.code) {
    case 'xero': {
      return createIntegrationImageIcon({ src: xeroLogo, alt: 'Xero logo' })
    }
    case 'quickbooks': {
      return createIntegrationImageIcon({
        src: quickBooksLogo,
        alt: 'Quickbooks logo',
      })
    }
    case 'sapling': {
      return createIntegrationImageIcon({
        src: saplingLogo,
        alt: 'Sapling logo',
      })
    }
    case 'hibob': {
      return createIntegrationImageIcon({ src: bobLogo, alt: 'Hibob logo' })
    }
    case 'bamboohr': {
      return createIntegrationImageIcon({
        src: bambooLogo,
        alt: 'BambooHR logo',
      })
    }
    case 'fresh': {
      return createIntegrationImageIcon({
        src: freshbooksLogo,
        alt: 'Fresh logo',
      })
    }
    case 'zoho': {
      return createIntegrationImageIcon({
        src: zohoInvoiceLogo,
        alt: 'Zoho logo',
      })
    }
    case 'netsuite': {
      return createIntegrationImageIcon({
        src: netSuiteLogo,
        alt: 'Netsuite logo',
      })
    }
    case 'okta': {
      return createIntegrationImageIcon({ src: oktaLogo, alt: 'Okta logo' })
    }

    default: {
      return null
    }
  }
}

export default IntegrationCard
