import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { CheckItem } from '../../../components/ui/check-item'

const FilterBox = ({
  data,
  title,
  icon,
  setFilters,
  id,
  defaultFilters,
  initialFilters,
  reset,
}) => {
  const [selectedFilters, setSelectedFilters] = useState(
    initialFilters ?? defaultFilters,
  )

  useEffect(() => {
    if (
      selectedFilters !== initialFilters &&
      selectedFilters !== defaultFilters
    ) {
      setFilters({ [id]: selectedFilters })
    }
  }, [selectedFilters])

  useEffect(() => {
    if (reset) {
      setFilters({ [id]: defaultFilters })
      setSelectedFilters(defaultFilters)
    }
  }, [reset])

  return (
    <div className='tw-flex tw-flex-col tw-px-6 tw-py-4'>
      <div className='tw-flex tw-items-center tw-gap-2 tw-py-1'>
        {icon} <span className='tw-text-xs tw-font-semibold'>{title}</span>
      </div>

      {data?.map((filter, i) => {
        const isSelected = selectedFilters?.includes(filter.value)

        return (
          <div key={filter + i} className='tw-flex tw-gap-2 tw-py-1'>
            <CheckItem
              inputClassName='!tw-w-5 !tw-h-5'
              className='tw-max-w-full'
              label={filter.label}
              labelId={filter.label}
              value={filter.value}
              checked={isSelected}
              labelClassName={cn(
                'tw-text-xs tw-truncate',
                isSelected
                  ? 'tw-text-primary-100 tw-font-bold'
                  : 'tw-font-semi-bold  tw-text-black',
              )}
              onChange={(event) => {
                const { value, checked } = event.target
                const _value =
                  // statuses and tags must be be parsed to numbers
                  id === 'contract_statuses' || id === 'contract_tags'
                    ? parseInt(value)
                    : value
                setSelectedFilters((p) =>
                  checked ? [...p, _value] : p.filter((i) => i !== _value),
                )
              }}
            />
          </div>
        )
      })}
    </div>
  )
}

export default FilterBox
