import React from 'react'
import set from 'lodash/set'
import toastr from 'toastr'
import compact from 'lodash/compact'
import { Avatar } from 'ui'
import { Modal, ModalFooter, ModalHeader } from 'reactstrap'
import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import { PaperPlaneTilt } from '@phosphor-icons/react'
import Button from '../../../components/ui/button'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import { useFetch } from '../../../helpers/hooks'
import { getContractList, requestDoc } from '../../../services/api'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import ControlledInput from '../../../components/ControlledInput'
import { CONTRACT_STATUS } from '../../../helpers/enum'

export function RequestDocumentModal({ toggle, isOpen, onSuccess }) {
  const { isLoading: isContractsLoading, data: contracts } = useFetch({
    action: (...args) =>
      getContractList(...args).then((res) => {
        set(
          res,
          'data.data',
          res.data?.data?.map(({ contractor, id, ref }) => {
            const fullName = compact([
              contractor?.first_name,
              contractor?.middle_name,
              contractor?.last_name,
            ]).join(' ')
            return {
              icon: (
                <Avatar
                  size='sm'
                  className='tw-mr-2'
                  photo={contractor?.photo}
                  name={fullName}
                />
              ),
              label: fullName,
              description: ref,
              value: id,
            }
          }),
        )
        return res
      }),
    autoFetch: true,
    body: { status: [CONTRACT_STATUS.ONGOING.value] },
    onError: (error) => toastr.error(error),
  })
  const { isLoading: isRequesting, startFetch: requestDocument } = useFetch({
    action: requestDoc,
    onComplete: () => {
      onSuccess()
      toggle()
    },
    onError: (error) => toastr.error(error),

    autoFetch: false,
  })
  const methods = useForm({
    shouldUnregister: true,
    defaultValues: {
      contract: null,
      title: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        contract: Yup.object().required('Contract is required'),
        title: Yup.string().required('Document title is required'),
      }),
    ),
  })

  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <ModalHeader
        className='tw-items-center'
        toggle={toggle}
        close={<ModalCloseButton toggle={toggle} />}
      >
        <PaperPlaneTilt size={24} />
      </ModalHeader>
      <FormProvider {...methods}>
        <div className='tw-flex tw-flex-1 tw-flex-col tw-gap-2 tw-p-5'>
          <span className='tw-text-xl tw-text-black'>Request document</span>
          <span className='tw-mb-2'>
            Request a document from the worker for submission
          </span>
          <Controller
            name='contract'
            render={({
              field: { value: actionType, onChange },
              fieldState: { error },
            }) => (
              <CustomSelect
                isClearable={true}
                hasError={!!error}
                error={error}
                isLoading={isContractsLoading}
                onChange={onChange}
                value={actionType}
                placeholder='Worker'
                options={contracts}
              />
            )}
          />

          <ControlledInput name='title' placeholder='Document title' />
        </div>
        <ModalFooter className='tw-flex tw-flex-row tw-items-center'>
          <Button
            className='!tw-border-surface-30 !tw-bg-white'
            textClassName='!tw-text-black'
            onClick={toggle}
            outline
          >
            Cancel
          </Button>
          <Button
            color='primary'
            disabled={false}
            loading={isRequesting}
            onClick={methods.handleSubmit((values) =>
              requestDocument({
                contract_id: values.contract.value,
                title: values.title,
              }),
            )}
          >
            Submit
          </Button>
        </ModalFooter>
      </FormProvider>
    </Modal>
  )
}
