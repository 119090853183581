import React from 'react'
import { OverlayArrow, Tooltip } from 'react-aria-components'
import { cn } from 'ui'
import _i18n from '../../i18n'

export function TooltipV2({
  children,
  className,
  offset = 4,
  placement = 'end',
  ...props
}) {
  const isRtl = _i18n.dir() === 'rtl'

  const _placement = !isRtl
    ? placement
    : placement === 'end'
      ? 'start'
      : placement === 'start'
        ? 'end'
        : placement

  return (
    <Tooltip
      {...props}
      offset={offset}
      placement={_placement}
      className={cn(
        'tw-rounded tw-bg-black tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-will-change-transform',
        // Animations
        'data-[entering]:tw-animate-slideTooltip data-[exiting]:tw-animate-[slideTooltip_200ms_reverse_ease-in]',
        // Placements
        'data-[placement=top]:[--origin:translateY(4px)]',
        'data-[placement=bottom]:[--origin:translateY(-4px)]',
        'data-[placement=left]:[--origin:translateX(4px)]',
        'data-[placement=right]:[--origin:translateX(-4px)]',
        className,
      )}
    >
      <OverlayArrow>
        <svg
          {...{
            end: { width: 4, height: 8, viewBox: '0 0 4 8' },
            start: { width: 4, height: 8, viewBox: '0 0 4 8' },
            top: { width: 8, height: 4, viewBox: '0 0 8 4' },
            bottom: { width: 8, height: 4, viewBox: '0 0 8 4' },
          }[_placement]}
        >
          <path
            d={
              {
                end: 'M0 4 L4 0 L4 8',
                start: 'M0 0 L4 4 L0 8',
                top: 'M0 0 L8 0 L4 4',
                bottom: 'M4 0 L8 4 L0 4',
              }[_placement]
            }
          />
        </svg>
      </OverlayArrow>
      {children}
    </Tooltip>
  )
}
