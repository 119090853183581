import React from 'react'
import { Label } from 'reactstrap'

const ControlledInputLabel = ({ required, onChange, value, ...prop }) => {
  return (
    <Label
      className={
        required
          ? 'after:tw-ml-0.5 after:tw-text-red-100 after:tw-content-["*"]'
          : null
      }
      contentEditable='true'
      onBlur={(e) => {
        if (value !== e.target.textContent) {
          onChange(e.target.textContent)
        }
      }}
      {...prop}
    >
      {value}
    </Label>
  )
}

export default ControlledInputLabel
