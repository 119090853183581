import { t } from 'i18next'
import { CalendarBlank, CheckCircle, XCircle } from '@phosphor-icons/react'
import { format } from 'date-fns'
import React from 'react'
import { Badge } from 'reactstrap'

import { CONTRACT_ACTIVITIES } from '../../../helpers/enum'
import { getContractStatusColor } from '../ContractList/ContractList'

export function ContractTimelineHeader({
  amended,
  status,
  lastTimeline,
  ignoreAmendment,
}) {
  return (
    <div className='tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-1 tw-border-b tw-border-surface-30 tw-p-4 md:tw-p-6'>
      <h5 className='tw-mb-0 tw-flex tw-items-start tw-gap-1 tw-whitespace-nowrap tw-text-base'>
        <CalendarBlank
          size={24}
          weight='duotone'
          className='tw-inline-block tw-text-secondary-100'
        />

        <span>{t('Timeline')}</span>
      </h5>

      <ContractBadge
        amended={amended}
        status={status}
        lastTimeline={lastTimeline}
        ignoreAmendment={ignoreAmendment}
      />
    </div>
  )
}

export function ContractTimeline({ timeline }) {
  return (
    <div className='p-3 p-md-4'>
      <ul className='vertical-timeline'>
        {timeline?.map((status, key) => {
          const Icon = status.completed === 1 ? CheckCircle : XCircle

          return (
            <li key={key} className='event-list'>
              <div
                className='event-timeline-dot tw-rounded-full tw-bg-white'
                style={{ marginLeft: -4 }}
              >
                <Icon
                  className={
                    [
                      CONTRACT_ACTIVITIES.CONTRACT_CANCELLED,
                      CONTRACT_ACTIVITIES.CONTRACT_TERMINATED,
                      CONTRACT_ACTIVITIES.TERMINATION_SCHEDULED,
                      CONTRACT_ACTIVITIES.TERMINATION_REQUESTED,
                      CONTRACT_ACTIVITIES.TERMINATION_REQUEST_REJECTED,
                    ].includes(status.name) || status.completed !== 1
                      ? 'tw-text-systemRed-100'
                      : 'tw-text-systemGreen-100'
                  }
                  size={24}
                />
              </div>

              <div className='tw-text-sm'>
                <p className='tw-mb-0 tw-font-semibold'>{status?.name}</p>

                {!status?.user ? null : (
                  <div className='tw-mb-0 tw-text-text-80'>
                    {t('By')} {status.user}
                  </div>
                )}

                {!status.date ? null : (
                  <p className='mb-0 tw-text-text-100'>
                    {format(status.date * 1000, 'MMM. dd, yyyy')}
                    <span className='tw-ml-1 tw-inline-block tw-text-text-60'>
                      {format(status.date * 1000, 'hh:mm a')}
                    </span>
                  </p>
                )}
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export function ContractBadge({
  amended,
  status,
  lastTimeline,
  ignoreAmendment,
}) {
  const lastTimelineName = lastTimeline?.name
  const statusColor =
    amended && !ignoreAmendment
      ? 'warning'
      : getContractStatusColor(status, lastTimelineName)

  const statusName =
    amended && !ignoreAmendment
      ? t('Pending amendment')
      : [
            CONTRACT_ACTIVITIES.TERMINATION_SCHEDULED,
            CONTRACT_ACTIVITIES.TERMINATION_REQUESTED,
            CONTRACT_ACTIVITIES.TERMINATION_REQUEST_APPROVED,
          ].includes(lastTimeline?.name)
        ? lastTimeline?.name
        : status?.name

  return (
    <Badge
      className={`!tw-text-balance !tw-rounded-full !tw-text-xs !tw-font-bold tw-uppercase bg-soft-${statusColor} text-${statusColor}`}
    >
      {t(statusName)}
    </Badge>
  )
}
