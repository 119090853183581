import { api, apiV2 } from './api'
import { openApi } from './openapi'
import { makeWeb, bareApi } from './webhooks'

export const addLocaleToInstancesRequestInterceptor = (locale) => {
  api.interceptors.request.use(
    (config) => {
      config.headers['x-locale'] = locale
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )
  apiV2.interceptors.request.use(
    (config) => {
      config.headers['x-locale'] = locale
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )
  openApi.interceptors.request.use(
    (config) => {
      config.headers['x-locale'] = locale
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )
  makeWeb.interceptors.request.use(
    (config) => {
      config.headers['x-locale'] = locale
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )
  bareApi.interceptors.request.use(
    (config) => {
      config.headers['x-locale'] = locale
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )
}
