import React from 'react'

import LogoDark from './logo-dark'

export function HeaderAnonym() {
  return (
    <div className='tw-flex tw-h-[--header-height-anonym] tw-items-center tw-justify-center tw-bg-white tw-shadow-sm'>
      <LogoDark height={24} />
    </div>
  )
}
