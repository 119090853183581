import React from 'react'
import Shimmer from '../../../components/ui/shimmer'
function WorkersLoading() {
  return (
    <div className='tw-flex tw-justify-start tw-gap-4 tw-overflow-hidden tw-bg-white tw-p-4'>
      <div className='tw-flex tw-w-56 tw-shrink-0 tw-flex-col tw-gap-4'>
        <Shimmer className='!tw-h-16 !tw-w-full tw-rounded-lg' />
        {[...Array(10)].map((_, index) => (
          <Shimmer key={index} className='!tw-h-16 !tw-w-full tw-rounded-lg' />
        ))}
      </div>
      <div className='tw-flex tw-flex-col tw-gap-4'>
        <div className='tw-flex tw-gap-2'>
          {[...Array(24)].map((_, index) => (
            <Shimmer key={index} className='!tw-h-16 !tw-w-32 tw-rounded' />
          ))}
        </div>
        {[...Array(10)].map((_, i) => (
          <div key={i} className='tw-flex tw-gap-2'>
            {[...Array(24)].map((_, index) => (
              <Shimmer key={index} className='!tw-h-16 !tw-w-32 tw-rounded' />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default WorkersLoading
