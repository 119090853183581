export const PRORATA_TYPE = {
  CALCULATED: 'calculated',
  CUSTOM: 'custom',
  FULL_AMOUNT: 'full-amount',
}

export const PRORATA_CALCULATION_TYPE = {
  WORKING_DAYS: 'working_days',
  CALENDAR_DAYS: 'calendar_days',
}

export const WORKING_DAYS = {
  MONDAY_FRIDAY: 'monday_friday',
  SUNDAY_THURSDAY: 'sunday_thursday',
  CALENDAR_DAYS: PRORATA_CALCULATION_TYPE.CALENDAR_DAYS,
}
