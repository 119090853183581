import { t } from 'i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  DownloadSimple,
  FileDoc,
  FilePlus,
  Paperclip,
  UploadSimple,
  Warning,
  XCircle,
} from '@phosphor-icons/react'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import BadgeX from '../../../components/Table/BadgeX'
import ControlledDropzoneInput from '../../../components/controlled-dropzone-input'
import Button from '../../../components/ui/button'
import DataTable, { getData } from '../../../components/ui/data-table'
import Loader from '../../../components/ui/loader'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { BE_CONTRACT_CATEGORY } from '../../../helpers/enum'
import { useFetch } from '../../../helpers/hooks'
import {
  downloadDocumentRequest,
  genericDownload,
  uploadContractorDoc,
} from '../../../services/api'
import openFileV2 from '../../../utils/file/open-v2'
import { getFullName } from '../../../utils/get-full-name'
import { searchParamsFromObject } from '../../../utils/search-params-from-object'
import { JobTitleModal } from '../ContractDetail/SelectSignatoryDropdown'
import CardsList from '../components/tab/card-list'
import TabEmpty from '../components/tab/tab-empty'
import { DocumentActions, DocumentMessage } from './de-documents-tab'
import { format, isBefore } from 'date-fns'
import { cn } from 'ui'

const DOC_STATUS = {
  SUBMITTED: 'Submitted',
  REQUESTED: 'Requested',
  COMPLETED: 'Completed',
}

const statusText = {
  client: {
    [DOC_STATUS.REQUESTED]: {
      byClient: t('Document request sent to the employee'),
      byEmployee: t('Document request sent by the employee'),
    },
    [DOC_STATUS.SUBMITTED]: t('Employee requested a document'),
    [DOC_STATUS.COMPLETED]: {
      byClient: t('Document uploaded by the employee'),
      byEmployee: t('Document generated, signed and shared with the employee'),
    },
  },
  contractor: {
    [DOC_STATUS.REQUESTED]: {
      byClient: t('Document request sent by the employer'),
      byEmployee: t('Document request sent to the employer'),
    },
    [DOC_STATUS.SUBMITTED]: t(
      'Document submitted and shared with the employer',
    ),
    [DOC_STATUS.COMPLETED]: {
      byClient: t('Document uploaded by the employee'),
      byEmployee: t('Document signed by the employer'),
    },
  },
}

const getComplianceDocType = (type) => {
  switch (type) {
    case 'application/pdf':
      return 'pdf'
    case 'image/jpeg':
    case 'image/jpg':
      return 'jpg'
    case 'image/png':
      return 'png'
    default:
      return ''
  }
}

export default function DeDocumentsList({
  loading,
  isEmpty,
  contract,
  documents,
  refreshDocs,
  docTypeOptions,
}) {
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const contractorType = userProfile?.contractor_type
  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE

  const columns = useMemo(
    () => [
      { Header: t('Name'), accessor: 'name' },
      { Header: t('Status'), accessor: 'status', Cell: BadgeCol },
      {
        Header: t('Expires on'),
        Cell: ({ rowData }) => {
          const expiryDate = rowData?.expiry_date
            ? new Date(rowData?.expiry_date)
            : null
          const isExpired = expiryDate && isBefore(expiryDate, new Date())
          return (
            <div
              className={cn(
                'tw-flex tw-items-center tw-gap-1',
                isExpired && 'tw-text-red',
              )}
            >
              {isExpired ? <Warning /> : null}
              {expiryDate ? format(expiryDate, 'yyyy/MM/dd') : '-'}
            </div>
          )
        },
      },
      {
        Header: t('Action'),
        accessor: 'id',
        Cell: ({ rowData }) => {
          const isGenerated = rowData?.status === DOC_STATUS.COMPLETED
          const clientNeedsToGenerate =
            rowData?.status === DOC_STATUS.SUBMITTED && !rowData?.is_compliance
          const employeeNeedsToUpload =
            rowData?.status === DOC_STATUS.REQUESTED &&
            isDeEmployee &&
            rowData?.is_compliance

          const {
            cropped_official_logo: croppedOfficialLogo,
            official_logo: officialLogo,
            logo,
          } = contract?.entity ?? {}

          const isContractLogoSet = Boolean(
            croppedOfficialLogo || officialLogo || logo,
          )

          if (isGenerated) {
            return <DownloadDocument row={rowData} />
          }

          if (
            clientNeedsToGenerate &&
            !isDeEmployee &&
            !rowData?.is_compliance
          ) {
            const params = {
              compliance: rowData?.is_compliance ? '1' : '0',
              back: location.pathname + location.search,
              message: rowData?.description,
              from: contract?.contractor?.full_name,
            }

            const sp = searchParamsFromObject(params)
            const url = `/document/generate/${rowData.id}${sp}`

            return (
              <GenerateLink
                url={url}
                contractType={contract?.type}
                isContractLogoSet={isContractLogoSet}
              />
            )
          }

          if (employeeNeedsToUpload) {
            return (
              <EmployeeUploadDocument
                row={rowData}
                contract={contract}
                refreshDocs={refreshDocs}
              />
            )
          }

          return null
        },
      },
    ],

    [contract, isDeEmployee, refreshDocs],
  )

  return loading ? (
    <Loader minHeight='max(20vh, 350px)' />
  ) : isEmpty ? (
    <TabEmpty
      icon={<Paperclip size={250} color='var(--primary)' weight='duotone' />}
      title={t('No document to show')}
      subtitle={t('Submitted compliance documents will be shown here')}
    >
      <DocumentActions
        isDirectEmployee={isDeEmployee}
        docTypeOptions={docTypeOptions}
        refreshDocs={refreshDocs}
        contract={contract}
      />
    </TabEmpty>
  ) : (
    <CardBody className='tw-p-6'>
      <DataTable
        columns={columns}
        data={documents}
        striped
        className='d-none d-md-table font-size-14'
      />

      <CardsList
        columns={columns.filter((col) => col.Header !== 'Status')}
        data={documents}
        className='d-md-none'
        itemHeader={(row, index) => {
          const statusColumn =
            columns.find((col) => col.Header === 'Status') ?? {}
          const statusData = getData(row, statusColumn?.accessor)

          const statusChild = statusColumn.Cell ? (
            <statusColumn.Cell
              cellData={statusData}
              index={index}
              rowData={row}
              accessor={statusColumn?.accessor}
            />
          ) : (
            statusData
          )

          return statusChild
        }}
      />
    </CardBody>
  )
}

function DownloadDocument({ row }) {
  const { startFetch: downloadDocument, isLoading: downloadingDeDoc } =
    useFetch({
      action: downloadDocumentRequest,
      onComplete: (data, body) => {
        if (data?.success === false) {
          toastr.error(data?.message || 'Something went wrong')
        } else {
          toastr.success(t('Document downloaded'))

          openFileV2(data, {
            download: true,
            name: `${body.name}_doc_${body.request_id}.pdf`,
          })
        }
      },
      onError: (err) => {
        const errorMessage =
          typeof err === 'string'
            ? err
            : typeof err?.message === 'string'
              ? err?.message
              : t('Something went wrong with downloading document')

        toastr.error(errorMessage)
      },
    })

  const {
    startFetch: downloadDocumentCompliance,
    isLoading: downloadingComplianceDoc,
  } = useFetch({
    action: genericDownload,
    onComplete: (data, body) => {
      openFileV2(data, {
        download: true,
        name: `${body.name}.${getComplianceDocType(data?.type)}`,
      })
    },
    onError: () => {
      toastr.error(t('Something went wrong with downloading document'))
    },
  })

  function handleDownloadDocument() {
    if (row?.is_compliance) {
      downloadDocumentCompliance({ link: row?.link, name: row.name })
    } else {
      downloadDocument({ request_id: row?.id, name: row.name })
    }
  }

  const loading = downloadingComplianceDoc || downloadingDeDoc

  if (loading) return <Spinner size='sm' />

  return (
    <button
      onClick={() => handleDownloadDocument()}
      className='tw-rounded tw-bg-secondary-30 tw-p-2 tw-text-secondary'
    >
      <DownloadSimple size={17} />
    </button>
  )
}

function BadgeCol({ cellData, rowData }) {
  const userType = useSelector((state) => state?.Account?.user?.type)

  let foundStatus = statusText[userType]?.[cellData]
  const isRequestedByClient = rowData?.is_compliance

  if (foundStatus && typeof foundStatus === 'object') {
    foundStatus = foundStatus[isRequestedByClient ? 'byClient' : 'byEmployee']
  }

  const id = `doc-badge-${rowData?.id}`

  const color =
    cellData === DOC_STATUS.SUBMITTED || cellData === DOC_STATUS.COMPLETED
      ? 'success'
      : cellData === DOC_STATUS.REQUESTED
        ? 'warning'
        : 'default'

  return (
    <>
      <BadgeX status={color} id={id}>
        {t(cellData)}
      </BadgeX>

      {!foundStatus ? null : (
        <UncontrolledTooltip placement='bottom' target={id}>
          {t(foundStatus)}
        </UncontrolledTooltip>
      )}
    </>
  )
}

const uploadDocumentFormId = 'upload-document-form'
function EmployeeUploadDocument({ row, contract, refreshDocs }) {
  const [isOpen, setIsOpen] = useState(false)
  function toggle() {
    setIsOpen((open) => {
      if (!open) {
        reset()
      }

      return !open
    })
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        file: yup.array().required(t('Document is required')),
      }),
    ),
  })

  const { startFetch: uploadDocument, isLoading } = useFetch({
    action: uploadContractorDoc,
    checkSuccess: true,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message || t('Something went wrong'))
      } else {
        toastr.success(t('Document uploaded'))
        document.body.classList.remove('modal-open')
        toggle()
      }
      refreshDocs?.()
    },
    onError: (err) => {
      toastr.error(err || t('Something went wrong with uploading document'))
    },
  })

  const uploadedFileName = watch('file')?.length > 0 && watch('file')[0]?.name

  function onSubmit(data) {
    const file = data?.file?.[0]
    const title = row?.name
    const type = row?.type_id

    uploadDocument({ file, title, type_id: type })
  }

  return (
    <>
      <button
        id='upload-document'
        onClick={toggle}
        className='tw-rounded tw-bg-primary-30 tw-p-2 tw-text-primary'
      >
        <UploadSimple size={17} />
      </button>

      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('Upload document')}</ModalHeader>
        <ModalBody>
          <form id={uploadDocumentFormId} onSubmit={handleSubmit(onSubmit)}>
            <div className='form-control bg-surface-20 mb-3'>{row?.name}</div>

            {!row?.description ? null : (
              <div className='mb-3'>
                <DocumentMessage
                  from={getFullName(contract?.client)}
                  message={row?.description}
                />
              </div>
            )}

            <ControlledDropzoneInput
              control={control}
              name='file'
              error={errors?.file}
              className='d-flex gap-12 align-items-center flex-wrap font-size-14 px-3'
              style={{ minHeight: 56 }}
            >
              {uploadedFileName ? (
                <>
                  {uploadedFileName}

                  <button
                    className='p-0.5 rp-btn-nostyle text-red-90 d-flex'
                    type='button'
                    onClick={() => {
                      setValue('file', null)
                    }}
                  >
                    <XCircle size={20} weight='fill' />
                  </button>
                </>
              ) : (
                <>
                  <FileDoc size={24} />

                  <div className='d-none d-md-block'>
                    {t('Drop files here or click to upload')}
                  </div>
                  <div className='d-md-none'>{t('Click to upload')}</div>
                </>
              )}
            </ControlledDropzoneInput>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button color='light' outline onClick={toggle} disabled={isLoading}>
            {t('Cancel')}
          </Button>
          <Button
            type='submit'
            formId={uploadDocumentFormId}
            disabled={isLoading}
            loading={isLoading}
          >
            {t('Upload')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export function GenerateLink({ url, contractType, isContractLogoSet }) {
  const [openJobTitleModal, setOpenJobTitleModal] = useState(false)
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const clientHasJobTitle = !!userProfile?.job_title
  const jobTitleRequired = contractType === CONTRACT_TYPES.DIRECT_EMPLOYEE

  return (
    <>
      <JobTitleModal
        open={openJobTitleModal}
        toggle={() => setOpenJobTitleModal((open) => !open)}
      />

      <div className='tw-w-fit' id='generate-div'>
        <Link
          to={url}
          onClick={(e) => {
            if (jobTitleRequired && !clientHasJobTitle) {
              e.preventDefault()
              setOpenJobTitleModal(true)
              return
            }
            if (!isContractLogoSet) {
              e.preventDefault()
              toastr.clear()
              toastr.error(
                t('Please upload contract logo first in the company settings'),
              )
            }
          }}
        >
          <div className='tw-rounded tw-bg-primary-30 tw-p-2 tw-text-primary'>
            <FilePlus size={17} />
          </div>
        </Link>
      </div>
      <UncontrolledTooltip target='generate-div'>
        {t('Generate')}
      </UncontrolledTooltip>
    </>
  )
}
