import {
  DownloadSimple,
  Paperclip,
  PaperPlaneTilt,
  Trash,
  UploadSimple,
  Warning,
  X,
} from '@phosphor-icons/react'
import { t } from 'i18next'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, Col, Row, Spinner, UncontrolledTooltip } from 'reactstrap'
import toastr from 'toastr'
import { cn } from 'ui'
import TableH from '../../../components/Table/TableH'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import Button from '../../../components/ui/button'
import { userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  deleteContractorDocument,
  downloadFile,
  removeRequestDoc,
} from '../../../services/api'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import TabEmpty from '../components/tab/tab-empty'
import { RequestFormModal } from './documents-request-form-modal'
import DataTable from '../../../components/ui/data-table'
import openFileV2 from '../../../utils/file/open-v2'
import UploadModal from '../../Documents/components/UploadModal'
import { DeleteDocumentModal } from '../../Documents/global/delete-document-modal'
import { format, isBefore } from 'date-fns'
import upperCase from 'lodash/upperCase'

const Documents = ({ docs, contractId, contractRef, loading, onUpdate }) => {
  const [open, setOpen] = useState(false)
  const [requestedDocToUpload, setRequestedDocToUpload] = useState(null)
  const [deletingDocument, setDeletingDocument] = useState(false)
  const user = useSelector((state) => state?.Account?.user)
  const { hasAccess } = usePermissions()

  const downloadDoc = useFetch({
    action: downloadFile,
    onComplete: (data, body) => {
      const name =
        body?.name === 'Contractor agreement' && contractRef
          ? contractRef
          : body?.name
      openFileV2(data, {
        name: `${name}.${data?.type?.split('/')[1]}`,
        download: true,
      })
    },
  })

  const deleteDocument = useFetch({
    action: deleteContractorDocument,
    onComplete: () => onUpdate(),
    onError: (err) => toastr.error(err),
  })

  const deleteDoc = useFetch({
    action: removeRequestDoc,
    onComplete: () => {
      onUpdate()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const handleDownload = (doc) => downloadDoc.startFetch(doc)

  const handleDelete = (doc) => {
    deleteDoc.startFetch({
      request_id: doc?.id,
    })
  }

  const columns = [
    { Header: t('Name'), accessor: 'name' },
    {
      Header: t('Status'),
      Cell: ({ rowData }) =>
        rowData?.link ? (
          <span className='tw-rounded-full tw-bg-systemGreen-10 tw-px-2.5 tw-py-1 tw-font-bold tw-text-systemGreen'>
            {upperCase(t('Uploaded'))}
          </span>
        ) : (
          <span className='tw-rounded-full tw-bg-systemGold-10 tw-px-2.5 tw-py-1 tw-font-bold tw-text-systemGold'>
            {upperCase(t('Requested'))}
          </span>
        ),
    },
    {
      Header: t('Expires on'),
      Cell: ({ rowData }) => {
        const expiryDate = rowData?.expiry_date
          ? new Date(rowData?.expiry_date)
          : null
        const isExpired = expiryDate && isBefore(expiryDate, new Date())
        return (
          <div
            className={cn(
              'tw-flex tw-items-center tw-gap-1',
              isExpired && 'tw-text-red',
            )}
          >
            {isExpired ? <Warning /> : null}
            {expiryDate ? format(expiryDate, 'yyyy/MM/dd') : '-'}
          </div>
        )
      },
    },
    {
      Cell: ({ rowData }) => {
        return (
          <div className='tw-flex tw-items-center tw-justify-end tw-gap-1'>
            {rowData?.can_delete &&
            rowData?.link &&
            hasAccess(permissions.DeleteContractsDocuments) ? (
              <>
                <button
                  onClick={() => setDeletingDocument(rowData)}
                  id='delete-button'
                  className='tw-rounded tw-bg-secondary-30 tw-p-2 tw-text-red'
                >
                  <Trash size={17} />
                </button>

                <UncontrolledTooltip target='delete-button'>
                  Delete document
                </UncontrolledTooltip>
              </>
            ) : null}

            {user?.type === userTypes.COMPANY && !rowData.link ? (
              <>
                <button
                  id='cancel-document-request'
                  onClick={() => handleDelete(rowData)}
                  className='tw-rounded tw-bg-secondary-30 tw-p-2 tw-text-secondary'
                >
                  <X size={17} />
                </button>
                <UncontrolledTooltip target='cancel-document-request'>
                  Cancel document request
                </UncontrolledTooltip>
              </>
            ) : null}

            {rowData?.link &&
            hasAccess(permissions.DownloadContractsDocuments) ? (
              <>
                <button
                  id='download-document'
                  onClick={() => handleDownload(rowData)}
                  className='tw-rounded tw-bg-secondary-30 tw-p-2 tw-text-secondary'
                >
                  <DownloadSimple size={17} />
                </button>
                <UncontrolledTooltip target='download-document'>
                  Download document
                </UncontrolledTooltip>
              </>
            ) : null}

            {rowData?.status?.toLowerCase() === 'requested' &&
            user?.type === userTypes.CONTRACTOR ? (
              <>
                <button
                  id='upload-document'
                  onClick={() => setRequestedDocToUpload(rowData)}
                  className='tw-rounded tw-bg-primary-30 tw-p-2 tw-text-primary'
                >
                  <UploadSimple size={17} />
                </button>
                <UncontrolledTooltip target='upload-document'>
                  Upload document
                </UncontrolledTooltip>
              </>
            ) : null}
          </div>
        )
      },
    },
  ]

  const actionButton =
    user?.type === userTypes.COMPANY ? (
      <PermissionTooltip
        showing={
          !hasAccess([
            permissions.RequestContractsDocuments,
            permissions.createDocument,
          ])
        }
        id='add-work-btn-tooltip'
        area={PERMISSION_GROUP.DOCUMENTS.name}
      >
        <Button
          type='button'
          icon={<PaperPlaneTilt />}
          disabled={
            !hasAccess(
              permissions.RequestContractsDocuments,
              permissions.createDocument,
            )
          }
          onClick={() => setOpen(true)}
          className='font-size-14'
        >
          {t('Request Document')}
        </Button>
      </PermissionTooltip>
    ) : null

  return (
    <>
      {loading ? (
        <Row
          style={{ minHeight: '80vh' }}
          className='justify-content-center align-items-center'
        >
          <Spinner type='grow' className='tw-me-2' color='primary' />
        </Row>
      ) : docs?.length > 0 ? (
        <Row className='p-0 m-0'>
          <Col xs='12' className='p-0 m-0'>
            <Card className='tw-m-0 tw-p-6'>
              <TableH
                title={t('Documents')}
                className='tw-mb-6'
                noPadding
                leftSide={actionButton}
              />
              <DataTable data={docs} columns={columns} responsive striped />
            </Card>
          </Col>
        </Row>
      ) : (
        <TabEmpty
          icon={
            <Paperclip size={250} color='var(--primary)' weight='duotone' />
          }
          title={t('No documents added')}
          subtitle={t('Submitted compliance documents will be shown here')}
        >
          {actionButton}
        </TabEmpty>
      )}

      {!open ? null : (
        <RequestFormModal
          show={open}
          hide={() => setOpen(false)}
          contractId={contractId}
          onUpdate={onUpdate}
        />
      )}

      {!deletingDocument ? null : (
        <DeleteDocumentModal
          onDelete={() =>
            deleteDocument.startFetch({ token: deletingDocument?.token })
          }
          toggle={() => setDeletingDocument(null)}
          isOpen={!!deletingDocument}
        />
      )}

      {!requestedDocToUpload ? null : (
        <UploadModal
          isOpen={!!requestedDocToUpload}
          toggle={() => setRequestedDocToUpload(null)}
          doc={requestedDocToUpload}
          update={onUpdate}
        />
      )}
    </>
  )
}

export default Documents
