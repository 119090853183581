import React from 'react'

import { cn } from 'ui'
import { FlagIcon } from '../../../components/ui/flag-icon'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'

export default function BalanceWithFlag({ balance }) {
  const { currency, amount } = balance ?? {}

  const formatter = getCurrencyFormatter(currency)

  const isUnavailable = Number.isNaN(amount) || !amount

  return (
    <div className='tw-flex tw-items-center tw-justify-between tw-rounded tw-border tw-border-surface-30 tw-px-4 tw-font-medium'>
      <div
        className={cn('tw-text-2xl tw-text-primary-100', {
          'tw-py-2': isUnavailable,
        })}
      >
        {isUnavailable ? 'Unavailable' : formatter.format(amount)}
      </div>
      {isUnavailable ? null : (
        <div className='tw-flex tw-items-center tw-gap-2 tw-border-l tw-border-surface-30 tw-py-2 tw-pl-4'>
          <FlagIcon
            code={currency}
            className='tw-shrink-0 tw-border-surface-30 [&.currency-flag]:!tw-size-5 [&.currency-flag]:!tw-rounded-full'
          />
          <div className='tw-text-2xl tw-text-secondary-100'>{currency}</div>
        </div>
      )}
    </div>
  )
}
