import axios from 'axios'
import { t } from 'i18next'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Card, Row, TabPane } from 'reactstrap'
import toastr from 'toastr'

import Head from '../../../../components/head'
import Button from '../../../../components/ui/button'
import NavTabsV2 from '../../../../components/ui/nav-tabs/nav-tabs-v2'
import TabContent from '../../../../components/ui/tabs'
import { useFetch } from '../../../../helpers/hooks'
import {
  getbambooEditData,
  saveBambooContractors,
  updateDataToBamboo,
} from '../../../../services/api'
import {
  bambooRequest,
  bambooUpdateContractors,
} from '../../../../store/bamboo/actions'
import { HrIntegrationSettings } from '../HibobIntegration/Tab1'
import Tab4 from '../HibobIntegration/Tab4'
import TabX from '../HibobIntegration/TabX'
import { IntegrationReviewHeader } from '../integration-review-header'
import { LoadingTab } from '../UpdateHibobIntegration'

const UpdateBambooIntegration = () => {
  const [activeTab, setActiveTab] = useState(0)
  const tab1Ref = useRef()

  const dispatch = useDispatch()
  const bambooReq = useSelector((state) => state.bamboo.request)
  const history = useHistory()

  useEffect(() => {
    if (!bambooReq?.session_id) {
      history.goBack()
    }
  }, [])

  const UpdateData = useFetch({
    action: updateDataToBamboo,
    onComplete: (data) => {
      if (data?.user_id) {
        toastr.success(t('Settings updated successfully.'))
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const callGetBambooContractors = useFetch({
    action: getbambooEditData,
    onComplete: (data) => {
      if (data?.settings) {
        const newContractType = bambooReq?.contract_types?.map((type) =>
          data?.settings?.settings?.contract_types.includes(type.name)
            ? type
            : {
                ...type,
                status: false,
              },
        )
        const newFields = bambooReq?.fields?.map((type) =>
          data?.settings?.settings?.fields.includes(type.name)
            ? {
                ...type,
                status: true,
              }
            : type,
        )
        dispatch(
          bambooRequest({
            ...bambooReq,
            ...data?.settings?.auth,
            contract_types: newContractType,
            fields: newFields,
          }),
        )
      }
      if (data?.contractors) {
        dispatch(bambooUpdateContractors(data?.contractors))
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const saveContractors = useFetch({
    action: saveBambooContractors,
    onComplete: () => {
      toastr.success(t('Settings updated successfully.'))
      callGetBambooContractors.startFetch()
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  useEffect(() => {
    callGetBambooContractors.startFetch()
  }, [])

  const refresh = useCallback(
    (cancel) => {
      if (cancel) cancel()
      if (callGetBambooContractors.data?.contractors_queued > 0) {
        callGetBambooContractors.startFetch(
          null,
          false,
          new axios.CancelToken((c) => (cancel = c)),
        )
      }
    },
    [callGetBambooContractors.data],
  )

  useEffect(() => {
    if (activeTab === 1) {
      let cancel
      const interval = setInterval(() => refresh(cancel), 800)
      return () => {
        clearInterval(interval)
      }
    }
  }, [callGetBambooContractors.data, activeTab])

  const tabs = [
    {
      label: t('Settings'),
      component: (
        <Card className='p-3'>
          {(saveContractors.isLoading ||
            callGetBambooContractors.isLoading) && <LoadingTab />}
          <HrIntegrationSettings
            platform='bamboo'
            mode='edit'
            ref={tab1Ref}
            onComplete={(data) => {
              dispatch(bambooRequest({ ...bambooReq, ...data }))
              const contractTypes = bambooReq?.contract_types
                ?.filter((type) => type.status === true)
                .map((type) => type.name)
              const fields = bambooReq?.fields
                ?.filter((type) => type.status === true)
                .map((type) => type.name)
              UpdateData.startFetch({
                ...bambooReq,
                ...data,
                contract_types: contractTypes,
                fields,
              })
            }}
          />
          <TabX
            platform='bamboo'
            mode='edit'
            context='tab3'
            onComplete={(data) => {
              dispatch(bambooRequest({ ...bambooReq, fields: data }))
            }}
          />
          <TabX
            platform='bamboo'
            mode='edit'
            context='tab2'
            onComplete={(data) => {
              dispatch(bambooRequest({ ...bambooReq, contract_types: data }))
            }}
          />
          <Row className='justify-content-end p-0 m-0 mt-4'>
            <Button
              onClick={() => {
                tab1Ref.current.submit()
              }}
              loading={UpdateData.isLoading}
              disabled={UpdateData.isLoading}
            >
              {t('Update')}
            </Button>
          </Row>
        </Card>
      ),
    },
    {
      label: t('Team'),
      component: (
        <Card className='p-3'>
          {(saveContractors.isLoading ||
            callGetBambooContractors.isLoading) && <LoadingTab />}
          <Tab4
            platform='bamboo'
            mode='edit'
            createContractor={(ContractorsToSubmit) => {
              saveContractors.startFetch({
                contractors: [ContractorsToSubmit[0]?.contractor_id],
              })
            }}
          />
        </Card>
      ),
    },
  ]

  return (
    <div className='page-content'>
      <Head title='Bamboo HR | Integration Review' />

      <IntegrationReviewHeader integrationName='Bamboo HR' />

      <NavTabsV2
        options={tabs.map((tab, index) => ({ ...tab, value: index }))}
        activeOption={activeTab}
        onClickOption={(tab) => {
          setActiveTab(tab.value)
        }}
        className='tw-mt-4 !tw-border-b-transparent'
        itemLinkClassName='!tw-py-3 md:!tw-py-3'
      />

      <TabContent activeTab={activeTab} className='mt-3'>
        {tabs.map((t, i) => (
          <TabPane key={`tab:${i}`} tabId={i}>
            {t.component}
          </TabPane>
        ))}
      </TabContent>
    </div>
  )
}

export default UpdateBambooIntegration
