import React from 'react'
import { Card } from 'reactstrap'

import Loader from '../../../../../components/ui/loader'
import PageHeading from '../../../../../components/ui/page-heading'
import Pagination from '../../../../../components/ui/pagination'
import { useFetch } from '../../../../../helpers/hooks'
import { getCorSupportedCountries } from '../../../../../services/api'
import { CardsSectionHeader } from '../../../../remotepass-cards/components/section-heading'
import { useFilters } from '../../cards/use-filters'
import CorCountriesList, { CorCountriesFilters } from './cor-countries-list'

export default function CorCountriesSupported() {
  const [filters, handleFiltersChange] = useFilters({ page: 1, pageSize: 10 })

  const {
    data: corSupportedCountries,
    isLoading: isCorSupportedCountriesLoading,
    startFetch: updateCountries,
  } = useFetch(
    {
      action: getCorSupportedCountries,
      autoFetch: true,
      withAdminAccess: true,
      body: filters,
    },
    [filters],
  )

  function handleUpdateCountries() {
    updateCountries(filters, null)
  }

  const showPaginator =
    corSupportedCountries?.first_page_url !==
    corSupportedCountries?.last_page_url

  return (
    <div className='page-content'>
      <PageHeading>
        <PageHeading.Title>
          Contractor of Record / Supported countries
        </PageHeading.Title>
      </PageHeading>

      <Card style={{ position: 'unset' }}>
        <CardsSectionHeader>
          <CorCountriesFilters
            filters={filters}
            onChange={handleFiltersChange}
          />
        </CardsSectionHeader>

        {isCorSupportedCountriesLoading ? (
          <Loader minHeight='30rem' />
        ) : (
          <>
            <CorCountriesList
              countries={corSupportedCountries?.data}
              updateCountries={handleUpdateCountries}
            />

            {!showPaginator ? null : (
              <div className='ml-auto pr-4 mt-3 w-100 d-flex justify-content-end'>
                <Pagination
                  activePage={filters?.page}
                  itemsCountPerPage={corSupportedCountries?.per_page ?? 10}
                  totalItemsCount={corSupportedCountries?.total ?? 0}
                  onChange={(newPage) => handleFiltersChange('page', newPage)}
                />
              </div>
            )}
          </>
        )}
      </Card>
    </div>
  )
}
