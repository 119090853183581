import {
  endOfMonth,
  format,
  getMonth,
  getYear,
  isToday,
  startOfMonth,
} from 'date-fns'
import React, { useRef, useState } from 'react'
import { cn } from 'ui'

import { useFetch } from '../../helpers/hooks'
import { getCalendarData } from '../../services/api-events'
import CalendarLoading from './components/calendar-loading'
import CalendarEventItem from './components/event-item'
import MoreButton from './components/more-button'
import {
  generateCalendarSlots,
  isWeekend,
  mergeSlotsWithEvents,
  weekDays,
} from './helpers'

function Calendar({ date, filters, initialFilters }) {
  const [calendarSlots, setCalendarSlots] = useState([])
  const startDate = format(startOfMonth(date), 'yyyy-MM-dd')
  const endDate = format(endOfMonth(date), 'yyyy-MM-dd')
  const calendarRef = useRef()

  const { isLoading, startFetch: fetchCalendarData } = useFetch(
    {
      action: getCalendarData,
      autoFetch: true,
      body: {
        ...initialFilters,
        ...filters,
        from: startDate,
        to: endDate,
      },
      onComplete: (data) => {
        // merge events to the calendar
        setCalendarSlots(
          mergeSlotsWithEvents(
            generateCalendarSlots(getYear(date), getMonth(date)),
            data,
          ),
        )
      },
    },
    [date, filters],
  )

  return isLoading ? (
    <CalendarLoading />
  ) : (
    <div ref={calendarRef}>
      <div className='tw-grid tw-grid-cols-7 tw-gap-0 tw-border-b tw-border-b-surface-30'>
        {weekDays.map((wDay, index) => (
          <div
            key={`${wDay.text}-head`}
            className={cn(
              'tw-flex tw-items-center tw-justify-center tw-border-r tw-border-surface-30 tw-p-4 tw-text-xs',
              isWeekend(index)
                ? 'tw-bg-surface-10 tw-text-text-60'
                : 'tw-bg-white tw-text-text-80',
            )}
          >
            {wDay.text}
          </div>
        ))}
      </div>
      {/* make the grid squares */}
      <div className='tw-grid tw-grid-cols-7 tw-gap-0 tw-overflow-hidden tw-bg-white'>
        {calendarSlots.map((slot, index) => {
          return (
            <div
              className={cn(
                'tw-relative tw-flex tw-h-[140px] tw-flex-col tw-justify-between tw-gap-2 tw-border-b tw-border-r tw-border-surface-30 tw-py-2 tw-font-semibold',
                {
                  'tw-bg-surface-10 tw-text-text-60':
                    slot.type === 'prev' ||
                    slot.type === 'next' ||
                    isWeekend(index % 7),
                  'tw-border-t-2 tw-border-t-primary tw-bg-primary-10 !tw-pt-1.5':
                    isToday(new Date(slot.year, slot.month, slot.day)),
                },
              )}
              key={index}
            >
              <div className='tw-relative'>
                <div className='tw-flex tw-w-full tw-items-center tw-justify-center tw-text-xs'>
                  {slot.label}
                </div>
                <div className='tw-relative tw-w-full'>
                  {isLoading ? (
                    <div className='tw-m-6 tw-flex tw-items-center tw-justify-center tw-text-text-50'>
                      Loading...
                    </div>
                  ) : (
                    slot.events
                      ?.filter((ev) => ev.placement <= 3)
                      .map((event, eventIndex) => {
                        if (!event.isStartOfEvent && index % 7 !== 0) {
                          return null
                        }
                        return (
                          <CalendarEventItem
                            key={`${event.entityId} + ${eventIndex} + ${slot.day}`}
                            event={event}
                            slot={slot}
                            fetchEvents={fetchCalendarData}
                          />
                        )
                      })
                  )}
                </div>
              </div>
              {slot.events?.length > 3 && (
                <MoreButton
                  isCalendarView
                  itemsToShowPerSlot={3}
                  slot={slot}
                  fetchEvents={fetchCalendarData}
                />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Calendar
