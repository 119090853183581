import { t } from 'i18next'
import React from 'react'
import { Link } from 'react-router-dom'

import { PermissionTooltip } from '../../../components/permission-tooltip'
import Button from '../../../components/ui/button'
import { track } from '../../../utils/analytics'
import { TIMEOFF_EVENTS } from '../../new-time-off-policy/events'
import { PERMISSION_GROUP } from '../manage-role'

const addTimeOffButtonId = 'add-time-off-policy-btn'

export function AddTimeOffPolicy({ hasPermission }) {
  return (
    <>
      <PermissionTooltip
        showing={!hasPermission}
        id={`${addTimeOffButtonId}-tooltip`}
        area={PERMISSION_GROUP.COMPANY_SETTINGS.name}
      >
        <Button
          disabled={!hasPermission}
          tag={Link}
          id={addTimeOffButtonId}
          color='link'
          className='!tw-px-0'
          to='/time-off-policies/add'
          onClick={() => track(TIMEOFF_EVENTS.ADD_POLICY)}
        >
          {t('Add New')}
        </Button>
      </PermissionTooltip>
    </>
  )
}
