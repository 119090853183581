import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import { useFetch } from '../../helpers/hooks'
import { RolesLoading } from '../../pages/CompanySetting/add-user-modal'
import { PERMISSION_GROUP } from '../../pages/CompanySetting/manage-role'
import { getRoles } from '../../services/api'
import { getFullName } from '../../utils/get-full-name'
import { ModalCloseButton } from '../Common/modal-close-button'
import RadioGroup from '../RadioGroup'
import { PrimaryAlert } from '../ui/alert'
import { t } from 'i18next'

export default function RolesModal({ loading, show, hide, onChange, user }) {
  const { data: rolesData, isLoading: loadingRolesData } = useFetch(
    {
      action: getRoles,
      autoFetch: show,
    },
    [show],
  )

  const roleOptions = rolesData?.map((role) => {
    const hasContractViewPermission = role?.groups?.some(
      (group) => group?.id === PERMISSION_GROUP.MANAGE_CONTRACTS.id,
    )

    const isDisabled = user?.is_approver ? !hasContractViewPermission : false

    return {
      value: role.id,
      label: role.name,
      description: role?.description,
      isDisabled,
    }
  })

  const someRoleIsDisabled = roleOptions?.some((role) => role.isDisabled)

  return (
    <Modal isOpen={show} centered toggle={hide}>
      <ModalHeader close={<ModalCloseButton toggle={hide} />}>
        {t('Select role for')} {getFullName(user) || t('this user')}
      </ModalHeader>

      <ModalBody style={{ minHeight: 242 }}>
        {loading || loadingRolesData ? (
          <RolesLoading />
        ) : (
          <>
            {!someRoleIsDisabled ? null : (
              <PrimaryAlert className='mb-2 p-3 !tw-text-start'>
                <strong>
                  {getFullName(user) || t('This user')}{' '}
                  {t('is an approver for one or more contracts.')}{' '}
                </strong>
                {t(
                  'Please select a role that has access to contracts (active options)',
                )}
                .
              </PrimaryAlert>
            )}
            <RadioGroup
              value={user?.role?.id}
              options={roleOptions ?? []}
              onChange={onChange}
              isDisabled={loading}
            />
          </>
        )}
      </ModalBody>
    </Modal>
  )
}
