import axios from 'axios'

const getConfig = (_, extraHeader, extra) => {
  return {
    headers: {
      // This one doesn't need to be set, it's just an example
      // Authorization: `Bearer ${token}`,
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Credentials': true,
      ...extraHeader,
    },
    ...extra,
  }
}

const makeWebHost = process.env.REACT_APP_MAKE_WEB_HOST
export const makeWeb = axios.create({
  baseURL: makeWebHost,
  headers: { 'Content-Type': 'application/json' },
})

export async function sendData(_, data) {
  return makeWeb.post('/', data, getConfig())
}

export const bareApi = axios.create({
  headers: { 'Content-Type': 'application/json' },
})

export async function getDefaultData() {
  return bareApi.get(
    'https://public-assets-rp.s3.eu-central-1.amazonaws.com/monday_data.txt',
  )
}
