import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { triggerFilterConditions } from '../tools/constants'
import ControlledInput from '../../../../../components/ControlledInput'
import CustomSelect from '../../../../../components/Forms/CustomSelect/CustomSelect'

export function TriggerFilter({ index }) {
  const { watch } = useFormContext()
  const filter = watch(`steps.${index}.event`)?.filter
  if (!filter) return null

  return (
    <div className='tw-flex tw-flex-row tw-gap-3.5'>
      <Controller
        defaultValue={triggerFilterConditions[1]}
        name={`steps.${index}.filterCondition`}
        render={({ field }) => (
          <CustomSelect
            value={field.value}
            options={triggerFilterConditions}
            placeholder='Condition'
            onChange={field.onChange}
            wrapperClassName='tw-flex-1'
          />
        )}
      />
      <ControlledInput
        name={`steps.${index}.filters.${filter.key}`}
        type='number'
        wrapperClassName='tw-flex-1'
        placeholder='Amount'
        postFix='USD'
      />
    </div>
  )
}
