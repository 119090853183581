import { t } from 'i18next'
import React from 'react'
import { Button, Col, Row, Spinner } from 'reactstrap'

import { useResize } from '../../helpers/hooks'
import paymentConfirmation from '../../assets/images/payment_confirmation.svg'

const ConfirmationPage = ({ onComplete, loading, confirmed }) => {
  const isMobile = useResize()
  return loading ? (
    <Col style={{ minHeight: '50vh' }}>
      <Row
        style={{ minHeight: '50vh' }}
        className='justify-content-center align-items-center'
      >
        <Spinner type='grow' className='mr-2' color='primary' />
      </Row>
    </Col>
  ) : (
    <Row className='justify-content-center'>
      <Col className='p-0'>
        <Row
          className='justify-content-center align-items-center'
          style={{ minHeight: '75vh' }}
        >
          <Col>
            <Row
              className='justify-content-center'
              style={{ fontSize: '10rem' }}
            >
              {confirmed ? (
                <img
                  height={isMobile ? 200 : 400}
                  src={paymentConfirmation}
                  alt=''
                />
              ) : (
                <i className='bx bx-x-circle text-danger' />
              )}
            </Row>
            {confirmed && (
              <Row className='justify-content-center text-center font-size-16'>
                <Col>
                  <p className='text-muted'>
                    {t(
                      'Your payment transaction status is processing. Once your payment is fully processed, you will be notified.',
                    )}
                  </p>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Button
            onClick={onComplete}
            className='my-2 rp-font-bold'
            color='primary'
          >
            {t('Back to Dashboard')}
          </Button>
        </Row>
      </Col>
    </Row>
  )
}

export default ConfirmationPage
