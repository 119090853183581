import { t } from 'i18next'
import { IdentificationCard, TreeStructure } from '@phosphor-icons/react'
import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Container, NavItem, Row, TabPane } from 'reactstrap'

import CreateContractBtn from '../../../components/Common/create-contract-btn'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import { PageNav } from '../../../components/page-nav'
import Button from '../../../components/ui/button'
import PageHeading from '../../../components/ui/page-heading'
import Shimmer from '../../../components/ui/shimmer'
import TabContent from '../../../components/ui/tabs'
import FEATURE_FLAGS from '../../../config/feature-flags'
import { userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  getArchivedContracts,
  getContractList,
  getContractsPendingInvite,
} from '../../../services/api'
import { useFilters } from '../../AdminPanel/pages/cards/use-filters'
import TabEmpty from '../components/tab/tab-empty'
import ClientContractList from './ClientContractList'
import ContractList from './ContractList'

function getArchivedListFilters(filters) {
  return { ...filters, query: filters.query.replace(/#/g, '') }
}

function getPendingInvitesFilters(filters) {
  return { page: filters.page, pending_invites: 1, type: filters.type }
}

export const CONTRACT_DETAILS_TABS = ({ t }) => {
  return {
    activeContracts: { value: '1', label: t('Active Contracts') },
    archivedContracts: { value: '2', label: t('Archived Contracts') },
    pendingInvites: { value: '3', label: t('Pending Invites') },
  }
}

export default function ContractListPage() {
  const user = useSelector((state) => state.Account.user)
  const isClient = user?.type === userTypes.COMPANY

  const [filtering, setFiltering] = useState(false)
  const [activeTab, setActiveTab] = useState(
    CONTRACT_DETAILS_TABS({ t }).activeContracts.value,
  )

  const { hasAccess } = usePermissions()

  const persistedCustomColumns = useMemo(
    () =>
      JSON.parse(localStorage.getItem('selectedContractsTableColumns'))?.[
        user?.company_id
      ],

    [user?.company_id],
  )

  const [filters, handleFiltersChange] = useFilters({
    country_ids: [],
    status: [],
    selected_custom_fields: persistedCustomColumns
      ? persistedCustomColumns.customColumns
      : [],
    tag: [],
    page: 1,
    query: '',
    type: '',
  })

  function setPage(page) {
    handleFiltersChange('page', page)
  }

  function setSelectedCustomFields(fields) {
    handleFiltersChange('selected_custom_fields', fields)
  }

  const {
    isLoading: archivedLoading,
    data: archivedList,
    startFetch: refetchArchivedContractList,
    paginator: archivedPaginator,
    completed: contractListCompleted,
  } = useFetch(
    {
      action: getArchivedContracts,
      autoFetch: true,
      body: getArchivedListFilters(filters),
    },
    [
      filters?.country_ids,
      filters?.status,
      filters?.tag,
      filters?.page,
      filters?.query,
      filters?.type,
      filters?.selected_custom_fields,
    ],
  )

  const {
    isLoading: pendingInviteLoading,
    data: pendingInviteList,
    startFetch: refetchPendingInviteContractList,
    paginator: pendingInvitePaginator,
  } = useFetch(
    {
      action: getContractsPendingInvite,
      autoFetch: FEATURE_FLAGS.BULK_INVITATIONS && isClient,
      body: getPendingInvitesFilters(filters),
    },
    [filters?.page, filters?.type],
  )

  const {
    data: contractList,
    isLoading: contractListLoading,
    startFetch: refetchContractList,
    paginator: activePaginator,
  } = useFetch(
    {
      action: getContractList,
      autoFetch: true,
      body: getArchivedListFilters(filters),
      onComplete: () => {
        window.analytics.track('Viewed contracts', { 'email-id': user?.email })
        setFiltering(false)
      },
    },
    [
      filters?.country_ids,
      filters?.status,
      filters?.tag,
      filters?.page,
      filters?.query,
      filters?.type,
      filters?.selected_custom_fields,
    ],
  )

  const noFilter =
    filters?.country_ids?.length === 0 &&
    filters?.status?.length === 0 &&
    filters?.tag?.length === 0 &&
    !filters?.query &&
    !filters?.type

  function onQuery(query) {
    handleFiltersChange('query', query ?? '', { action: 'clear' })
  }

  function onTabChange(value) {
    setActiveTab(value)
    onQuery('')
  }

  const pageLoading =
    contractListLoading || archivedLoading || !contractListCompleted

  function filterByStatus(checked, value) {
    const oldStatus = filters?.status ?? []
    if (checked) {
      handleFiltersChange('status', [...oldStatus, value], { action: 'clear' })
    } else {
      const newStatusQueries = oldStatus.filter((e) => e !== value)
      handleFiltersChange('status', newStatusQueries, { action: 'clear' })
    }
  }

  function filterByTag(checked, value) {
    const tagQueries = filters?.tag ?? []
    if (checked) {
      handleFiltersChange('tag', [...tagQueries, value])
    } else {
      const newTagQueries = tagQueries.filter((e) => e !== value)
      handleFiltersChange('tag', newTagQueries)
    }
  }

  function filterByCountry(countryIds) {
    handleFiltersChange('country_ids', countryIds)
  }

  function clearFilters() {
    handleFiltersChange('status', [])
    handleFiltersChange('tag', [])
  }

  function updateList(status, tag, del, { loading } = {}) {
    if (del && del === 'delete') {
      clearFilters()
    }
    if (del && del === 'clearF') {
      setFiltering(true)
      clearFilters()
    }
    if (status?.length > 0 || tag?.length > 0) {
      setFiltering(true)
    }
    refetchContractList(filters, loading)
    refetchArchivedContractList(filters, loading)
    refetchPendingInviteContractList(getPendingInvitesFilters(filters), loading)
  }

  useEffect(() => {
    if (activeTab === CONTRACT_DETAILS_TABS({ t }).archivedContracts.value) {
      window.analytics.track('Viewed archived contracts', {
        email_id: user?.email,
      })
    }
  }, [activeTab, user?.email])

  const tabOptions = [
    {
      label: CONTRACT_DETAILS_TABS({ t }).activeContracts.label,
      id: CONTRACT_DETAILS_TABS({ t }).activeContracts.value,
      count: activePaginator?.total,
    },
    archivedPaginator?.total <= 0 && !filters.query
      ? null
      : {
          label: CONTRACT_DETAILS_TABS({ t }).archivedContracts.label,
          id: CONTRACT_DETAILS_TABS({ t }).archivedContracts.value,
          count: archivedPaginator?.total,
        },
    (pendingInvitePaginator?.total <= 0 && !filters.query) ||
    !FEATURE_FLAGS.BULK_INVITATIONS ||
    !isClient
      ? null
      : {
          label: CONTRACT_DETAILS_TABS({ t }).pendingInvites.label,
          id: CONTRACT_DETAILS_TABS({ t }).pendingInvites.value,
          count: pendingInvitePaginator?.total,
        },
  ].filter(Boolean)

  const isCompany = user?.type === userTypes.COMPANY

  return (
    <div className='page-content' style={{ overflow: 'visible' }}>
      <PageHeading>
        <PageHeading.Title>{t('Contracts')}</PageHeading.Title>

        <PageHeading.ActionGroup className='tw-flex-wrap'>
          {FEATURE_FLAGS.ORGANIZATION_CHART &&
            isCompany &&
            hasAccess([
              permissions.ViewOrgChart,
              permissions.ManageOrgChart,
            ]) && (
              <Button
                color='light'
                outline
                icon={<TreeStructure size={16} />}
                tag={Link}
                to='/org-chart'
                className='tw-grow !tw-bg-white'
              >
                {t('View OrgChart')}
              </Button>
            )}

          <CreateContractBtn
            className='!tw-max-w-none'
            containerClassName='tw-grow'
            label={t('New Contract')}
            btnProps={{ textClassName: 'tw-flex tw-items-center tw-gap-2' }}
          />
        </PageHeading.ActionGroup>
      </PageHeading>

      <Container className='p-0 m-0' fluid>
        <>
          <Row
            style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
            className='p-3 m-0 d-block d-md-none justify-content-between align-items-center border-bottom bg-white'
          >
            {pageLoading ? (
              <Shimmer width='100%' height='40px' />
            ) : (
              <CustomSelect
                name='Active'
                isSearchable={false}
                value={activeTab.label}
                defaultValue={tabOptions[0]}
                onChange={(v) => onTabChange(v.value)}
                options={tabOptions}
              />
            )}
          </Row>

          <div className='tw-mb-6 tw-w-full tw-rounded tw-bg-white'>
            {pageLoading ? (
              <div className='tw-flex tw-h-[73px] tw-items-center tw-gap-4 tw-px-4'>
                <Shimmer width='152px' height='28px' />
                <Shimmer width='152px' height='28px' />
              </div>
            ) : (
              <>
                <PageNav>
                  {tabOptions.map((tab) => (
                    <NavItem key={tab.id}>
                      <PageNav.Link
                        isActive={activeTab === tab.id}
                        tag={activeTab === tab.value ? 'div' : 'button'}
                        to={tab.id}
                        onClick={() => onTabChange(tab.id)}
                      >
                        {tab.label} ({tab.count})
                      </PageNav.Link>
                    </NavItem>
                  ))}
                </PageNav>
              </>
            )}
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId='1'>
              {contractList?.length === 0 &&
              noFilter &&
              !contractListLoading ? (
                <div className='rounded bg-white rp-shadow-2'>
                  <TabEmpty
                    subtitle='All your active contracts will be shown here'
                    title={t('No active contracts')}
                    icon={
                      <IdentificationCard
                        size={240}
                        color='var(--primary)'
                        weight='duotone'
                      />
                    }
                  />
                </div>
              ) : user?.type === userTypes.COMPANY ? (
                <ClientContractList
                  Orders={contractList}
                  paginator={activePaginator}
                  page={filters.page}
                  setPage={setPage}
                  setSelectedCustomFields={setSelectedCustomFields}
                  withCustomFilter
                  withArchive={hasAccess(permissions.archiveContracts)}
                  onUpdate={updateList}
                  archiveValue={1}
                  filterByCountry={filterByCountry}
                  filterByStatus={filterByStatus}
                  filterByTag={filterByTag}
                  tagQueries={filters?.tag}
                  onQuery={onQuery}
                  statusQueries={filters?.status}
                  filtering={filtering}
                  clearFilters={clearFilters}
                  loading={contractListLoading}
                  onContractTypeChange={(val) =>
                    handleFiltersChange('type', val, { action: 'clear' })
                  }
                  showTags
                />
              ) : (
                <ContractList
                  Orders={contractList}
                  paginator={activePaginator}
                  page={filters.page}
                  setPage={setPage}
                  withCustomFilter
                  withArchive
                  onQuery={onQuery}
                  onUpdate={updateList}
                  archiveValue={1}
                  loading={contractListLoading}
                />
              )}
            </TabPane>
            <TabPane tabId='2'>
              {archivedList?.length === 0 && noFilter && !archivedLoading ? (
                <div className='rounded bg-white rp-shadow-2'>
                  <TabEmpty
                    subtitle={t(
                      'All your archived contracts will be shown here',
                    )}
                    title={t('No archived contracts')}
                    icon={
                      <IdentificationCard
                        size={240}
                        color='var(--primary)'
                        weight='duotone'
                      />
                    }
                  />
                </div>
              ) : user?.type === userTypes.COMPANY ? (
                <ClientContractList
                  Orders={archivedList}
                  paginator={archivedPaginator}
                  page={filters.page}
                  setPage={setPage}
                  setSelectedCustomFields={setSelectedCustomFields}
                  withCustomFilter
                  withArchive={hasAccess(permissions.archiveContracts)}
                  onUpdate={updateList}
                  archiveValue={0}
                  filterByCountry={filterByCountry}
                  filterByStatus={filterByStatus}
                  filterByTag={filterByTag}
                  tagQueries={filters?.tag}
                  statusQueries={filters?.status}
                  filtering={filtering}
                  onQuery={onQuery}
                  clearFilters={clearFilters}
                  loading={archivedLoading}
                  showTags={false}
                />
              ) : (
                <ContractList
                  Orders={archivedList}
                  paginator={archivedPaginator}
                  page={filters.page}
                  setPage={setPage}
                  withCustomFilter
                  withArchive
                  onQuery={onQuery}
                  onUpdate={updateList}
                  archiveValue={0}
                  loading={archivedLoading}
                />
              )}
            </TabPane>
            <TabPane tabId='3'>
              {archivedList?.length === 0 && noFilter && !archivedLoading ? (
                <div className='rounded bg-white rp-shadow-2'>
                  <TabEmpty
                    subtitle={t(
                      'All your pending invite contracts will be shown here',
                    )}
                    title={t('No pending invite contracts')}
                    icon={
                      <IdentificationCard
                        size={240}
                        color='var(--primary)'
                        weight='duotone'
                      />
                    }
                  />
                </div>
              ) : user?.type === userTypes.COMPANY ? (
                <ClientContractList
                  Orders={pendingInviteList}
                  paginator={pendingInvitePaginator}
                  page={filters.page}
                  setPage={setPage}
                  setSelectedCustomFields={setSelectedCustomFields}
                  // withCustomFilter
                  // withArchive={hasAccess(permissions.archiveContracts)}
                  onUpdate={updateList}
                  // archiveValue={0}
                  filterByCountry={filterByCountry}
                  filterByStatus={filterByStatus}
                  filterByTag={filterByTag}
                  tagQueries={filters?.tag}
                  statusQueries={filters?.status}
                  filtering={filtering}
                  onQuery={onQuery}
                  clearFilters={clearFilters}
                  loading={archivedLoading}
                  showTags
                  onContractTypeChange={(val) =>
                    handleFiltersChange('type', val, { action: 'clear' })
                  }
                  activeTab={activeTab}
                />
              ) : (
                <ContractList
                  Orders={pendingInviteList}
                  paginator={pendingInvitePaginator}
                  page={filters.page}
                  setPage={setPage}
                  // withCustomFilter
                  // withArchive
                  onQuery={onQuery}
                  onUpdate={updateList}
                  // archiveValue={0}
                  loading={pendingInviteLoading}
                />
              )}
            </TabPane>
          </TabContent>
        </>
      </Container>
    </div>
  )
}
