import { t } from 'i18next'
import React from 'react'
import { useSelector } from 'react-redux'

import { Avatar } from 'ui'
import BadgeX from '../../../components/Table/BadgeX'
import StyledTd from '../../../components/Table/StyledTd'
import BadgeV2 from '../../../components/ui/badge-v2'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { rpFormatDate } from '../../../utils/formatters/date-picker-date-format'
import ContractRef from '../../AdminPanel/components/ContractRef'
import { ExpenseActions } from '../../Contract/ContractPage/expenses-actions'
import { getStatusColor } from '../../Contract/utils/helpers'
import { getExpenseIcon } from '../../review-center'
import { formatDate } from '../../time-off/time-off-list'
import { BaseAmount } from './base-amount'

export default function ExpenseLine({
  showContractId = false,
  item,
  contract,
  isAdmin,
  refreshData,
}) {
  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )

  const formatter = getCurrencyFormatter(item?.contract_currency?.code)

  const isFullTime = contract?.type === 'Full Time'

  return (
    <tr style={{ borderBottom: '1px solid #E7E8F2' }}>
      {!showContractId ? null : (
        <StyledTd>
          <ContractRef
            target='_self'
            withHash
            isAdmin={isAdmin}
            contractId={contract?.ref}
          />
        </StyledTd>
      )}
      <StyledTd>{item?.name}</StyledTd>
      <StyledTd>
        <div className='tw-flex tw-items-center tw-gap-2'>
          {getExpenseIcon({ category: item?.category })}
          <span>{t(item?.category?.name)}</span>
        </div>
      </StyledTd>
      <StyledTd>
        {rpFormatDate(item?.date, 'yyyy-MM-dd', 'dd/MM/yyyy')}
      </StyledTd>
      {isFullTime ? (
        <StyledTd>
          {item?.due_date ? formatDate(item?.due_date) : t('N/A')}
        </StyledTd>
      ) : null}
      <StyledTd>
        {!isEmployee && formatter.format(item?.amount)}
        <BaseAmount
          baseAmount={item?.base_amount}
          baseCurrency={item?.base_currency}
        />
      </StyledTd>
      <StyledTd>
        <ExpenseStatusBadge item={item} />

        <NextApprover item={item} />
      </StyledTd>
      <StyledTd>
        <ExpenseActions
          isAdmin={isAdmin}
          item={item}
          contract={contract}
          refreshData={refreshData}
        />
      </StyledTd>
    </tr>
  )
}

export function ExpenseStatusBadge({ item, size }) {
  const _status =
    item?.payment_status === 'Unpaid'
      ? item?.status?.name
      : item?.payment_status

  return (
    <BadgeV2 status={getStatusColor(_status)} size={size}>
      {t(_status)}
    </BadgeV2>
  )
}

function NextApprover({ item }) {
  const nextApprover = item.status?.id === 2 && item?.next_approver

  if (!nextApprover) return null

  return (
    <BadgeX
      pill
      className='tw-ml-1 !tw-bg-secondary-20 !tw-text-black'
      leftIcon={<Avatar size='sm' name={nextApprover} className='-tw-ml-1' />}
      textClassName='tw-block tw-overflow-hidden tw-max-w-28 tw-text-ellipsis'
      title={nextApprover}
    >
      {nextApprover}
    </BadgeX>
  )
}
