import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Select from 'react-select'
import {
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { ModalCloseButton } from '../../../../components/Common/modal-close-button'
import { ControlledNativeSelect } from '../../../../components/controlled-native-select/controlled-native-select'
import ControlledInput from '../../../../components/ControlledInput'
import Toggle from '../../../../components/Forms/Toggle/Toggle'
import BadgeX from '../../../../components/Table/BadgeX'
import Button from '../../../../components/ui/button'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import { useFetch } from '../../../../helpers/hooks'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import {
  addNewAdmin as addNewAdminAction,
  changeAdminUserRole,
  changeAdminUserStatus,
  getAdminTeamList,
} from '../../../../services/api'
import capitalizeFirstLetter from '../../../../utils/capitalize-first-letter'

export default function AdminList() {
  const [showChangeRoleModal, setShowChangeRoleModal] = useState(null)
  const [showAddAdminModal, setShowAddAdminModal] = useState(false)

  const adminList = useFetch({
    action: getAdminTeamList,
    withAdminAccess: true,
    autoFetch: true,
  })

  const { startFetch: changeAdminRole, isLoading: changeAdminRoleIsLoading } =
    useFetch({
      action: changeAdminUserRole,
      withAdminAccess: true,
      onComplete: () => {
        adminList.startFetch(null, false)
        setShowChangeRoleModal(null)
      },
    })

  const { startFetch: changeAdminStatus } = useFetch({
    action: changeAdminUserStatus,
    withAdminAccess: true,
    onComplete: () => {
      adminList.startFetch(null, false)
    },
  })

  const { startFetch: addNewAdmin, isLoading: isNewAdminLoading } = useFetch({
    action: addNewAdminAction,
    withAdminAccess: true,
    onComplete: () => {
      adminList.startFetch(null, false)
      setShowAddAdminModal(false)
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  function handleChangeRole({ userId, role }) {
    changeAdminRole({ user_id: userId, role })
  }

  function handleStatusChange({ userId, newStatus }) {
    changeAdminStatus({ user_id: userId, status: newStatus })
  }

  function initAddAdmin() {
    setShowAddAdminModal(true)
  }

  function handleAddNewAdmin(data) {
    addNewAdmin(data)
  }

  const hasEditPermission = useHasPermission(ADMIN_PERMISSIONS.MANAGE_ADMINS)

  const roleOptions = adminList.data?.roles.map(({ id, name }) => ({
    label: name,
    value: id,
  }))

  return (
    <div className='page-content'>
      <div
        className='d-flex justify-content-between'
        style={{ marginBottom: '2rem' }}
      >
        <h1 className='mb-0'>All Admins</h1>
        {adminList.isLoading || !hasEditPermission ? null : (
          <Button onClick={initAddAdmin}>Add Admin User</Button>
        )}
      </div>

      <Card>
        {adminList.isLoading ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ minHeight: 145 }}
          >
            <Spinner type='grow' color='primary' />
          </div>
        ) : (
          <>
            <Table
              responsive
              className='table-centered table-nowrap text-muted mb-5'
              hover
            >
              <thead className='thead-light'>
                <tr>
                  <th className='border-top-0 text-muted'>Full Name</th>
                  <th className='border-top-0 text-muted'>Email</th>
                  <th className='border-top-0 text-muted'>Role</th>
                  <th className='border-top-0 text-muted'>Status</th>
                  {!hasEditPermission ? null : (
                    <th className='border-top-0 text-muted'>Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {adminList.data?.users?.map((item) => (
                  <AdminListItem
                    key={item.id}
                    item={item}
                    initRoleChange={setShowChangeRoleModal}
                    hasEditPermission={hasEditPermission}
                    onStatusChange={handleStatusChange}
                    isLoading={adminList.isLoading}
                  />
                ))}
              </tbody>
            </Table>

            {!showChangeRoleModal ? null : (
              <ChangeRoleModal
                open={!!showChangeRoleModal}
                toggle={() => setShowChangeRoleModal(null)}
                user={showChangeRoleModal}
                roles={roleOptions}
                onChangeRole={handleChangeRole}
                isLoading={changeAdminRoleIsLoading}
              />
            )}
          </>
        )}

        {!showAddAdminModal ? null : (
          <AddAdminModal
            open={showAddAdminModal}
            toggle={() => setShowAddAdminModal((o) => !o)}
            isLoading={isNewAdminLoading}
            onAddNewAdmin={handleAddNewAdmin}
            roles={roleOptions}
          />
        )}
      </Card>
    </div>
  )
}

function getFullName(names = []) {
  if (Array.isArray(names)) {
    return names.filter(Boolean).join(' ')
  } else if (typeof names === 'string') {
    return names
  }
  return ''
}

function AdminListItem({
  item,
  initRoleChange = () => {},
  hasEditPermission,
  onStatusChange,
  isLoading,
}) {
  return (
    <tr>
      <td>
        {getFullName([item?.first_name, item?.middleName, item?.last_name])}
      </td>
      <td>{item.email}</td>
      <td>
        <RoleBadge role={item?.role ?? ''} />
      </td>
      <td>
        <Toggle
          check={item.status === 'active'}
          change={(event) => {
            const newStatus = event.target.checked ? 'active' : 'inactive'
            onStatusChange({ userId: item?.id, newStatus })
          }}
          aria-label='Status'
          className='mr-0'
          disabled={isLoading || !hasEditPermission}
        />
      </td>
      {!hasEditPermission ? null : (
        <td>
          <Button
            type='button'
            onClick={() => initRoleChange(item)}
            color='warning'
            size='sm'
            disabled={isLoading}
          >
            Change Role
          </Button>
        </td>
      )}
    </tr>
  )
}

function ChangeRoleModal({
  open,
  toggle,
  isLoading = false,
  user = {},
  roles = [],
  onChangeRole = () => {},
}) {
  const [selectedRole, setSelectedRole] = useState(
    roles.find(({ label }) => label === user?.role) ?? roles[0],
  )

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Change role for{' '}
        {getFullName([user?.first_name, user?.middle_name, user?.last_name])}
      </ModalHeader>
      <ModalBody className='d-flex flex-column' style={{ gap: '1rem' }}>
        <div className='d-flex align-items-baseline' style={{ gap: '1rem' }}>
          <div style={{ width: 100 }}>Current Role:</div>
          <RoleBadge role={user?.role ?? ''} />
        </div>
        <div className='d-flex align-items-baseline' style={{ gap: '1rem' }}>
          <div style={{ width: 100 }}>New Role:</div>

          <div style={{ minWidth: 200 }}>
            <Select
              options={roles}
              value={selectedRole}
              name='role'
              onChange={(value) => setSelectedRole(value)}
              placeholder='Select new role'
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color='light'
          type='button'
          outline
          onClick={toggle}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          type='button'
          disabled={isLoading}
          loading={isLoading}
          onClick={() =>
            onChangeRole({ userId: user?.id, role: selectedRole?.label })
          }
        >
          {isLoading ? 'Changing role ...' : 'Change role'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function getRoleColor(role) {
  switch (role) {
    case 'Super Admin': {
      return 'success'
    }
    case 'Admin': {
      return 'primary'
    }
    case 'Accountant Admin': {
      return 'warning'
    }
    case 'Support Admin': {
      return 'info'
    }

    default: {
      return 'dark'
    }
  }
}

function RoleBadge({ role }) {
  return (
    <BadgeX
      status={getRoleColor(role)}
      textStatus={getRoleColor(role)}
      name={capitalizeFirstLetter(role)}
    />
  )
}

const addAdminModalId = 'add-admin-modal'

function AddAdminModal({
  open,
  toggle,
  isLoading = false,
  onAddNewAdmin = () => {},
  roles = [],
}) {
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        first_name: yup.string().required().label('First Name'),
        last_name: yup.string().required().label('Last Name'),
        email: yup.string().email().required().label('Email'),
        role: yup.string().required().label('Role'),
      }),
    ),
  })

  function onSubmit(values) {
    onAddNewAdmin(values)
  }

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader close={<ModalCloseButton toggle={toggle} />}>
        Add new admin
      </ModalHeader>
      <ModalBody>
        <form
          id={addAdminModalId}
          onSubmit={handleSubmit(onSubmit)}
          className='tw-flex tw-flex-col tw-gap-4'
        >
          <ControlledInput
            control={control}
            name='first_name'
            label='First Name'
            placeholder='Enter first name'
          />
          <ControlledInput
            control={control}
            name='last_name'
            label='Last Name'
            placeholder='Enter last name'
          />
          <ControlledInput
            control={control}
            name='email'
            label='Email'
            placeholder='Enter email'
          />
          <ControlledNativeSelect
            control={control}
            name='role'
            label='Role'
            options={[
              {
                label: 'Select a role',
                value: null,
                selected: true,
                disabled: true,
              },
              ...roles,
            ]}
          />
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          type='button'
          color='light'
          outline
          onClick={toggle}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          formId={addAdminModalId}
          color='primary'
          disabled={isLoading}
          loading={isLoading}
        >
          {isLoading ? 'Adding ...' : 'Add new admin'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
