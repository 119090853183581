import React from 'react'

export function EditorButton(props) {
  const { action, icon: Icon, source, editor } = props
  if (!editor) return null

  return (
    <button onClick={() => editor.chain().focus()[action]().run()}>
      <Icon
        size={20}
        className={editor.isActive(source) ? 'tw-text-primary' : 'tw-text-text'}
      />
    </button>
  )
}
