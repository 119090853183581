import { useFetch } from '../../../../../helpers/hooks'
import toastr from 'toastr'
import { getAutomations } from '../../../../../services/api-automations'
import { useCallback, useMemo, useState } from 'react'
import { transformParams } from '../tools'
import isEmpty from 'lodash/isEmpty'
import { AutomationMetrics } from '../tools/automation-metrics'

export function useAutomations({ onlyTemplates = false, limit = 10 }) {
  const [page, setPage] = useState(1)
  const [filters, _setFilters] = useState({})
  const {
    startFetch,
    paginator,
    data = [],
    isLoading,
  } = useFetch(
    {
      action: getAutomations,
      isOpenApi: true,
      body: {
        params: transformParams({
          page,
          limit,
          onlyTemplates,
          ...filters,
        }),
      },
      onError: (err) => toastr.error(err),
      autoFetch: true,
    },
    [page, onlyTemplates, limit, filters],
  )

  const isFirstLoading = useMemo(
    () => isLoading && isEmpty(filters),
    [filters, isLoading],
  )

  const isTotalEmpty = useMemo(
    () => !isFirstLoading && isEmpty(data),
    [data, isFirstLoading],
  )

  const setFilters = useCallback((setter) => {
    _setFilters((prev) => {
      const state = typeof setter === 'function' ? setter(prev) : setter
      AutomationMetrics.logListFiltered({ Filter_type: state })
      return state
    })
  }, [])

  return {
    refetch: startFetch,
    isLoading,
    isFirstLoading,
    isTotalEmpty,
    automations: data,
    filters,
    setFilters,
    pagination: {
      onPageChange: (page) => setPage(page),
      page,
      total: paginator?.totalItemsCount,
      perPage: limit,
    },
  }
}
