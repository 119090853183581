import { t } from 'i18next'
import React from 'react'
import { Badge, Col, Row } from 'reactstrap'

const WithdrawDetails = ({ bankInfo }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: bankInfo?.currency?.code || 'USD',
  })
  const getStatusColor = (status) => {
    switch (status) {
      case 'Processing':
        return 'warning'
      case 'Completed':
        return 'success'
      case 'Cancelled':
        return 'danger'
      default:
        return 'primary'
    }
  }
  return (
    <Row className='justify-content-center'>
      <Col>
        <Row className='justify-content-center'>
          <Col>
            {!!bankInfo && (
              <Row className='justify-content-center'>
                <Col>
                  <Col className='pt-2 border-bottom'>
                    <Col>
                      <Row className='justify-content-between pb-2'></Row>
                      <Row className='justify-content-between font-weight-light'>
                        <p>{t('Amount')}</p>
                        <p>{formatter.format(bankInfo?.subtotal)}</p>
                      </Row>

                      <Row className='justify-content-between font-weight-light'>
                        <p>{t('Fee')}</p>
                        <p>{formatter.format(bankInfo?.fee)}</p>
                      </Row>

                      <Row className='justify-content-between font-size-16 rp-font-bold'>
                        <p>{t('You will receive:')}</p>
                        <p>{formatter.format(bankInfo?.total)}</p>
                      </Row>

                      {bankInfo?.details['should_arrive"'] && (
                        <Row className='justify-content-between font-weight-light'>
                          <p>{t('Should arrive')}</p>
                          <p>{bankInfo?.details['should_arrive"']}</p>
                        </Row>
                      )}
                      {bankInfo?.status && (
                        <Row className='justify-content-between font-weight-light'>
                          <p>{t('Status')}</p>
                          <div>
                            <Badge
                              className={
                                'font-size-12 rounded badge-' +
                                getStatusColor(bankInfo?.status)
                              }
                              color='white'
                              pill
                            >
                              {bankInfo?.status}
                            </Badge>
                          </div>
                        </Row>
                      )}
                    </Col>
                  </Col>
                  <Col className='pt-2'>
                    <Col>
                      {bankInfo?.details?.details?.map((e, i) => (
                        <Row
                          key={`detail-${i}`}
                          className='justify-content-between font-weight-light'
                        >
                          <p>{e?.name}</p>
                          <p>{e?.value}</p>
                        </Row>
                      ))}
                    </Col>
                  </Col>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default WithdrawDetails
