import { useState } from 'react'
import {
  PRORATA_CALCULATION_TYPE,
  PRORATA_TYPE,
  WORKING_DAYS,
} from './constants'

export function useProrataEdit() {
  const [prorataTab, setProrataTab] = useState(PRORATA_TYPE.CALCULATED)
  const [prorataDays, setProrataDays] = useState(
    PRORATA_CALCULATION_TYPE.WORKING_DAYS,
  )
  const [workDays, setWorkDays] = useState(WORKING_DAYS.MONDAY_FRIDAY)
  const [showProrataEdit, setShowProrataEdit] = useState(false)

  return {
    prorataDays,
    setProrataDays,
    workDays,
    setWorkDays,
    showProrataEdit,
    setShowProrataEdit,
    prorataTab,
    setProrataTab,
  }
}
