import { X } from '@phosphor-icons/react'
import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { Row } from 'reactstrap'

import SidebarContent from '../pages/AdminPanel/components/VerticalLayout/SidebarContent'
import LogoDark from './ui/logo-dark'

const BurgerMenuPanel = ({ open, hide, routePermissions }) => {
  return (
    <Menu
      false
      isOpen={open}
      onClose={hide}
      className='bg-white'
      overlayClassName='rtl:!-tw-translate-x-full'
    >
      <Row
        style={{ height: 56 }}
        className='p-0 m-0 d-flex px-3 justify-content-between align-items-center border-bottom'
      >
        <Link to='/'>
          <LogoDark style={{ height: 22 }} />
        </Link>
        <button
          type='button'
          className='rp-btn-nostyle d-flex p-1'
          onClick={hide}
        >
          <X size={20} weight='bold' />
        </button>
      </Row>
      <div
        style={{ height: '80vh' }}
        className='d-flex flex-column justify-content-between'
      >
        <SidebarContent routePermissions={routePermissions} />
      </div>
    </Menu>
  )
}

export default withTranslation()(withRouter(BurgerMenuPanel))
