import {
  Check,
  CheckCircle,
  DotsThreeCircleVertical,
  DownloadSimple,
  Info,
  Path,
  X,
} from '@phosphor-icons/react'
import { format } from 'date-fns'
import { t } from 'i18next'
import React from 'react'
import toastr from 'toastr'

import { Avatar, cn } from 'ui'
import BadgeV2 from '../../components/ui/badge-v2'
import Button from '../../components/ui/button'
import { LinkOut } from '../../components/ui/link-out'
import Loader from '../../components/ui/loader'
import { SideMenu } from '../../components/ui/side-menu'
import { useFetch, usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { getContractDetail, getSingleTimeOff } from '../../services/api'
import openFileV2 from '../../utils/file/open-v2'
import { formatDays } from '../../utils/formatters/format-days'
import { DetailsInfoList } from '../review-center/review-layout-details-components'
import { getTimeOffStatusColor } from '../time-off/helpers'
import { formatTimeOffDate, getDeductionValue } from '../time-off/time-off-list'
import { useTimeOffActions } from '../time-off/use-time-off-actions'
import { getEventColorsClasses } from './helpers'

export function TimeOffRequestDetails({
  show,
  toggle,
  fetchEvents,
  icon,
  event = {},
}) {
  const { isLoading, data: timeOffData } = useFetch({
    action: getSingleTimeOff,
    autoFetch: Boolean(event.metadata?.timeOffId),
    initResult: {},
    body: {
      timeOffId: event.metadata?.timeOffId,
    },
  })

  const { data: timeOffContractData } = useFetch(
    {
      action: getContractDetail,
      autoFetch: Boolean(timeOffData.contract_ref),
      initResult: {},
      body: {
        id: timeOffData.contract_ref,
      },
    },
    [timeOffData.contract_ref],
  )

  const {
    approvingTimeOff,
    decliningTimeOff,
    deletingTimeOff,
    handleApproveTimeOff,
    handleDeclineTimeOff,
    handleDeleteTimeOff,
  } = useTimeOffActions({
    refreshTimeOff: () => {
      toastr.success('Time off updated successfully')
      fetchEvents()
    },
  })

  const loading =
    isLoading || approvingTimeOff || decliningTimeOff || deletingTimeOff

  const { hasAccess } = usePermissions()
  const canDeleteTimeOff = hasAccess(permissions.deleteTimeOff)

  const canManageTimeOff =
    hasAccess([
      permissions.addTimeOff,
      permissions.deleteTimeOff,
      permissions.rejectTimeOff,
      permissions.approveTimeOff,
    ]) ||
    timeOffContractData?.can_approve ||
    timeOffContractData?.can_approve_timeoff

  const canDeclineTimeOff =
    hasAccess(permissions.rejectTimeOff) || canManageTimeOff
  const canApproveTimeOff =
    hasAccess(permissions.approveTimeOff) || canManageTimeOff

  const getEventType = (event) => {
    switch (event.type) {
      case 'time_off':
        return 'Time-off'
      case 'time_off_request':
        return 'Time-off request'
      default:
        return ''
    }
  }

  function handleDownloadDocument(item) {
    openFileV2(item, {
      name: `Time-off-document${timeOffData.contract_ref}-${Date.now()}.pdf`,
      isDataUrl: true,
    })
  }

  const documents =
    timeOffData?.attachments?.map((attachment, index) => ({
      label: `Document ${index + 1}`,
      value: (
        <Button
          color='link'
          textClassName='tw-flex tw-items-center tw-gap-1'
          className='!tw-p-0'
          iconRight={<DownloadSimple size={20} />}
          onClick={() => handleDownloadDocument(attachment)}
        >
          Download
        </Button>
      ),
    })) ?? []

  // @todo: create and use common function in apps/user/src/pages/time-off/time-off-details-menu.jsx
  const items = [
    {
      label: 'Status',
      value: (
        <BadgeV2 status={getTimeOffStatusColor(timeOffData?.status?.name)}>
          {timeOffData?.status?.name}
        </BadgeV2>
      ),
    },
    { label: 'Contract ID', value: '#' + timeOffData?.contract_ref },
    { label: 'Policy', value: timeOffData?.policy?.name },
    {
      label: 'Start date',
      value: formatTimeOffDate({
        date: timeOffData?.from,
        isHalf: timeOffData?.is_half_start_date,
      }),
    },
    {
      label: 'End date',
      value: formatTimeOffDate({
        date: timeOffData?.to,
        isHalf: timeOffData?.is_half_end_date,
      }),
    },
    { label: 'Total days', value: formatDays(timeOffData?.days) },
    {
      label: 'Deduction',
      value: getDeductionValue({
        currencyCode: timeOffData?.currency?.code,
        total: timeOffData?.total,
        t,
      }),
    },
    {
      label: 'Reason',
      value: timeOffData?.reason,
    },
    ...documents,
  ].map((item) => ({
    ...item,
    valueClassName: 'tw-text-black',
    labelClassName: 'tw-font-normal',
  }))

  return (
    <>
      <SideMenu
        className='tw-z-[200] tw-max-w-[480px]'
        itemListClassName='tw-grid tw-grid-rows-[auto_1fr_auto] [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain'
        isOpen={show}
        onClose={toggle}
      >
        <SideMenu.Header toggle={toggle}>
          <div className='tw-flex tw-items-center tw-gap-4'>
            <Avatar
              size='xl'
              icon={icon}
              className={getEventColorsClasses(event)}
            />
            <div>
              <div className='tw-text-wrap tw-break-all tw-font-semibold tw-text-black'>
                <LinkOut
                  href={`contract/detail?id=${timeOffData?.contract_ref}`}
                  className='tw-flex tw-items-center tw-gap-1 tw-text-black'
                  title='View contract'
                >
                  {event.name || event.worker.name}
                </LinkOut>
              </div>
              <div className='tw-text-sm tw-font-medium tw-text-text-80'>
                {event.metadata.timeOffType || getEventType(event)}
              </div>
            </div>
          </div>
        </SideMenu.Header>
        <SideMenu.Body className='!tw-p-0'>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className='tw-border-t tw-border-surface-30 tw-px-4 tw-py-8 md:tw-px-6'>
                <div
                  className={cn('tw-flex tw-items-center', {
                    'tw-hidden': event.type === 'time_off',
                  })}
                >
                  <Info size={20} className='tw-me-2 tw-fill-primary-100' />
                  <h6 className='tw-mb-0 tw-text-base tw-font-bold tw-text-black'>
                    Details
                  </h6>
                </div>
                <DetailsInfoList items={items} className='tw-p-0' />
              </div>
              {/* TODO: Implement when data is ready from BE and decision to show is approved */}
              {/* <hr className='tw-my-0' /> */}
              {/* <ContractTimeline event={event} timeOffData={timeOffData} /> */}
            </>
          )}
        </SideMenu.Body>
        <SideMenu.Footer
          className={cn('tw-flex tw-justify-between', {
            'tw-hidden': event.type === 'time_off',
          })}
        >
          {canDeleteTimeOff && (
            <button
              onClick={() => {
                handleDeleteTimeOff({ id: event.metadata.timeOffId })
                toggle()
              }}
              className='tw-text-systemRed-100'
              disabled={loading}
            >
              Delete
            </button>
          )}
          <div className='tw-flex tw-w-full tw-justify-end tw-gap-2'>
            {canDeclineTimeOff && (
              <Button
                onClick={() => {
                  handleDeclineTimeOff({ id: event.metadata.timeOffId })
                  toggle()
                }}
                color='danger'
                icon={<X size={20} />}
                disabled={loading}
              >
                Decline
              </Button>
            )}
            {canApproveTimeOff && (
              <Button
                onClick={() => {
                  handleApproveTimeOff({ id: event.metadata.timeOffId })
                  toggle()
                }}
                color='success'
                icon={<Check size={20} />}
                disabled={loading}
              >
                Approve
              </Button>
            )}
          </div>
        </SideMenu.Footer>
      </SideMenu>
    </>
  )
}

export function ContractTimeline({ event }) {
  function getPrefixIcon(status) {
    switch (status) {
      case 'pending':
        return (
          <DotsThreeCircleVertical
            className='tw-text-systemGold-110'
            size={24}
          />
        )
      case 'approved':
      default:
        return <CheckCircle className='tw-text-systemGreen-100' size={24} />
    }
  }

  const timelines = [
    {
      action: 'Request submitted',
      date: event.date,
      name: event.name,
      time: null,
    },
    event.type === 'time_off_request'
      ? {
          action: 'Pending approval',
          name: 'Manager',
          status: 'pending',
        }
      : event.type === 'time_off'
        ? { action: 'Approved', name: 'Manager', status: 'approved' }
        : null,
  ]

  return (
    <div className='tw-px-4 tw-py-8 md:tw-px-6'>
      <div className='tw-flex tw-items-center'>
        <Path size={20} className='tw-me-2 tw-fill-primary-100' />
        <h6 className='tw-mb-0 tw-text-base tw-font-bold tw-text-black'>
          Timeline
        </h6>
      </div>

      <div className='tw-py-4'>
        <ul className='vertical-timeline'>
          {timelines?.map((timeline, key) => {
            const Icon = getPrefixIcon(event.type)

            return (
              <li key={key} className='event-list'>
                <div
                  className='event-timeline-dot -tw-ml-1 tw-rounded-full tw-bg-white'
                  style={{ marginLeft: -4 }}
                >
                  {Icon}
                </div>

                <div className='tw-flex tw-justify-between'>
                  <span className='tw-font-semibold tw-text-black'>
                    {timeline.action}
                  </span>
                  <span className='tw-text-text-100'>
                    {timeline.date &&
                      format(new Date(timeline.date), 'MMM dd, yyyy')}
                  </span>
                </div>
                <div className='tw-flex tw-justify-between tw-text-text-60'>
                  <span>{timeline.name}</span>
                  <span>{timeline.time}</span>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
