import { ArrowsClockwise, Info } from '@phosphor-icons/react'
import { t } from 'i18next'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'

import { Avatar } from 'ui'
import Toggle from '../../../../components/Forms/Toggle/Toggle'
import { StyledH5 } from '../../../../components/Typo'
import Button from '../../../../components/ui/button'
import { bambooUpdateContractors } from '../../../../store/bamboo/actions'
import { HiBobUpdateContractors } from '../../../../store/hiBob/actions'
import { SaplingUpdateContractors } from '../../../../store/sapling/actions'
import { getFullName } from '../../../../utils/get-full-name'
import XeroStatus from '../XeroStatus'

const Tab4 = ({ platform, mode, createContractor }) => {
  const hibobContractors = useSelector((state) => state.hiBob.contractors)
  const saplingContractors = useSelector((state) => state.sapling.contractors)
  const bambooContractors = useSelector((state) => state.bamboo.contractors)

  const getcontractorsState = () => {
    switch (platform) {
      case 'sapling':
        return saplingContractors
      case 'bamboo':
        return bambooContractors
      default:
        return hibobContractors
    }
  }

  const functionToDispatch = (contractors) => {
    if (platform === 'sapling') {
      dispatch(SaplingUpdateContractors(contractors))
    } else if (platform === 'bamboo') {
      dispatch(bambooUpdateContractors(contractors))
    } else {
      dispatch(HiBobUpdateContractors(contractors))
    }
  }

  const contractors = getcontractorsState()
  const dispatch = useDispatch()

  return (
    <div className='mx-3 mb-2'>
      <div className='mb-4 mt-3'>
        <StyledH5 className='font-size-16 rp-font-bold'>
          {t('Select which of the existing contracts you wish to create now')}
        </StyledH5>
      </div>
      <div>
        <Row>
          <Col md={12} className='mb-2'>
            {contractors?.length > 0 ? (
              contractors?.map((c, i) => {
                return (
                  <MiniCard
                    mode={mode}
                    key={i}
                    contractor={c}
                    handleCreate={() => {
                      const ContractorsToSubmit = contractors.filter(
                        (c, index) => i === index,
                      )
                      createContractor(ContractorsToSubmit)
                    }}
                    handelCheck={(value) => {
                      const newContractors = contractors?.map((c, index) => {
                        return i === index ? { ...c, checked: value } : c
                      })

                      functionToDispatch(newContractors)
                    }}
                  />
                )
              })
            ) : (
              <div
                style={{ minHeight: 200 }}
                className='justify-content-center align-items-center d-flex'
              >
                <p>{t('No contractors exist')}</p>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  )
}

function MiniCard({ mode, contractor, handelCheck, handleCreate }) {
  return (
    <div className='tw-mb-2 tw-flex tw-flex-1 tw-items-center tw-justify-between tw-rounded tw-p-2 hover:tw-bg-surface-20'>
      <div className='tw-flex tw-items-center tw-gap-4'>
        <Avatar
          name={getFullName(contractor)}
          photo={contractor?.photo}
          flag={contractor?.flag}
        />

        <div className='min-w-[16%] tw-flex-1'>
          <StyledH5
            className='tw-mb-0 tw-text-base tw-font-bold'
            translate='no'
          >
            {getFullName(contractor)}
          </StyledH5>
          <p className='tw-mb-0 tw-text-disabled' translate='no'>
            {contractor?.email}
          </p>
        </div>
      </div>

      {mode === 'edit' ? (
        <>
          {contractor?.queued ? (
            <ArrowsClockwise
              size={14}
              weight='bold'
              className='tw-animate-spinTwo'
            />
          ) : contractor?.status === 'Unsynched' ? (
            <Button onClick={handleCreate}>{t('Create now')}</Button>
          ) : (
            <div className='tw-flex tw-items-center tw-gap-1'>
              <XeroStatus status={contractor.status} />
              {!!contractor.error && (
                <div className='tw-relative tw-flex tw-gap-3'>
                  <Info
                    size={14}
                    id={`xero-sync-status-${contractor?.contractor_id}`}
                  />
                  <UncontrolledTooltip
                    placement='top'
                    target={`xero-sync-status-${contractor?.contractor_id}`}
                  >
                    {contractor.error}
                  </UncontrolledTooltip>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <div className='tw-flex tw-items-center'>
          <Toggle
            id={0}
            check={contractor?.checked}
            change={(event) => handelCheck(event.target.checked)}
          />
        </div>
      )}
    </div>
  )
}

export default Tab4
