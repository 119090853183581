import { addDays, format, isSameDay, startOfMonth, startOfWeek } from 'date-fns'
import React, { useMemo, useState } from 'react'
import { cn, ProfileCard } from 'ui'

import DataTable from '../../components/ui/data-table'
import { useFetch } from '../../helpers/hooks'
import { getTimelineEvents } from '../../services/api-events'
import { EmptyState } from '../review-center/empty-state'
import CalendarEventItem from './components/event-item'
import MoreButton from './components/more-button'
import WorkersLoading from './components/workers-loading'
import {
  generateTimeLineColumns,
  generateTimeLineData,
  getDurationInDays,
  getInsightEndDate,
} from './helpers'
import SelectWorkersFilter, { CALENDAR_WORKERS_VIEW } from './select-workers'

function Timeline({
  date,
  duration,
  filters,
  initialFilters,
  showInsight,
  selectedWorkers,
  setSelectedWorkers,
  showWorkersFilter,
  setShowWorkersFilter,
}) {
  const [data, setData] = useState([])
  const [workersView, setWorkersView] = useState(
    CALENDAR_WORKERS_VIEW.ALL_WORKERS,
  )

  const startDate = useMemo(
    () =>
      showInsight
        ? new Date(showInsight.startDate)
        : duration.value === 'm'
          ? startOfMonth(date)
          : startOfWeek(date, { weekStartsOn: 0 }),
    [date, duration, showInsight],
  )

  const endDate = useMemo(
    () =>
      showInsight
        ? getInsightEndDate(showInsight)
        : addDays(
            startDate,
            getDurationInDays({ date: startDate, duration }) - 1,
          ),
    [startDate, duration, showInsight],
  )

  const getWorkersInCalendarViewBody = {
    ...(workersView === CALENDAR_WORKERS_VIEW.MY_CIRCLE && {
      predefined_worker_filter: 'my_circle',
    }),
  }

  const timelineEventsBody = showInsight
    ? {
        contract_ids: showInsight.workerIds,
        from: showInsight.startDate,
        to: format(endDate, 'yyyy-MM-dd'),
      }
    : {
        from: format(startDate, 'yyyy-MM-dd'),
        to: format(endDate, 'yyyy-MM-dd'),
        ...initialFilters,
        ...filters,
        ...getWorkersInCalendarViewBody,
        contract_ids: Array.from(selectedWorkers),
      }

  const { isLoading, startFetch: fetchTimelineEvents } = useFetch(
    {
      action: getTimelineEvents,
      autoFetch: true,
      initResult: [],
      body: timelineEventsBody,
      onComplete: (data) => {
        setData(generateTimeLineData({ data }))
      },
    },
    [startDate, duration, filters, showInsight, selectedWorkers],
  )

  const columns = generateTimeLineColumns({ startDate, endDate }).columns.map(
    (col) => ({
      ...col,
      Header: (
        <div>
          <div>{col.day}</div>
          <div>{col.weekDay}</div>
        </div>
      ),
    }),
  )

  return (
    <>
      {showWorkersFilter && (
        <SelectWorkersFilter
          show={showWorkersFilter}
          toggle={() => setShowWorkersFilter(false)}
          selectedWorkers={selectedWorkers}
          setSelectedWorkers={setSelectedWorkers}
          setWorkersView={setWorkersView}
          workersView={workersView}
          getWorkersInCalendarViewBody={getWorkersInCalendarViewBody}
        />
      )}
      {isLoading ? (
        <WorkersLoading />
      ) : (
        <>
          <div className='[&_.table-responsive]:tw-overflow-y-hidden'>
            <DataTable
              className='tw-overflow-hidden tw-bg-white'
              headClassName='!tw-border-b !tw-border-surface-20'
              rowClassName='tw-h-[64px]'
              columns={[
                ...[
                  {
                    headerClassName:
                      'tw-w-[256px] tw-h-[64px] !tw-p-0 tw-min-w-[256px]',
                    className: 'tw-border-r tw-border-surface-20',
                    Header: (
                      <div className='tw-flex tw-h-full tw-items-center tw-justify-between tw-border-r tw-border-surface-20 tw-bg-surface-10 tw-px-4'>
                        <div>Workers ({data.length})</div>
                      </div>
                    ),
                    accessor: 'name',
                    Cell: ({ cellData, rowData }) => {
                      return (
                        <ProfileCard
                          title={
                            <a
                              target='_blank'
                              rel='noreferrer'
                              href={`contract/detail?id=${rowData?.contract_ref}`}
                              className='tw-text-black'
                            >
                              {cellData}
                            </a>
                          }
                          name={cellData}
                          description={rowData.title}
                          flag={rowData.country_flag}
                          photo={rowData.avatar}
                        />
                      )
                    },
                  },
                ],
                ...columns.map((col) => ({
                  ...col,
                  headerClassName: cn(
                    'tw-h-[64px] !tw-p-0 tw-min-w-[64px] tw-border-r tw-border-surface-20',
                  ),
                  Header: (
                    <div
                      className={cn(
                        'tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-gap-1 tw-text-xs tw-text-text-60',
                        {
                          'tw-bg-surface-10': col.isWeekend,
                          '!tw-border-t-2 tw-border-primary tw-bg-primary-10':
                            col.isToday,
                        },
                      )}
                    >
                      <div className='tw-font-bold'>{col.day}</div>
                      <div>{col.weekDay}</div>
                    </div>
                  ),
                  className: cn(
                    'tw-border-r tw-border-surface-20 tw-min-h-[64px] tw-relative tw-bg-white',
                    {
                      'tw-bg-surface-10': col.isWeekend,
                      'tw-bg-primary-10': col.isToday,
                    },
                  ),
                  Cell: ({ cellData }) => {
                    return (
                      <div>
                        {cellData?.length > 0 &&
                          cellData.slice(0, 1).map((event) => {
                            if (
                              !event.isStartOfEvent &&
                              !isSameDay(new Date(event.cellDate), startDate)
                            )
                              return null
                            return (
                              <CalendarEventItem
                                key={`${event.entityId}-${event.startDate}`}
                                startDate={startDate}
                                onlyIcon
                                isTimelineItem
                                event={event}
                                fetchEvents={fetchTimelineEvents}
                              />
                            )
                          })}
                        {cellData.length > 1 && (
                          <div className='tw-relative tw-mx-auto tw-h-20 tw-w-8'>
                            <MoreButton
                              itemsToShowPerSlot={0}
                              className='tw-absolute tw-bottom-0 tw-left-0'
                              showText={false}
                              slot={{
                                events: cellData.slice(1),
                                date: cellData[0].date,
                              }}
                              fetchEvents={fetchTimelineEvents}
                            />
                          </div>
                        )}
                      </div>
                    )
                  },
                })),
              ]}
              responsive
              data={data}
            />
          </div>
          {data?.length < 1 ? (
            <EmptyState
              className='!tw-p-0 tw-shadow-none'
              title={<div className='tw-text-2xl'>No events found</div>}
              textElement={
                <div className='tw-text-text-60'>
                  Try adjusting your filters or worker selection
                </div>
              }
            >
              {/* TODO: to be added later */}
              {/* <Button
                className='tw-my-4 tw-border-surface-30 tw-text-black'
                color='transparent'
                outline
              >
                Clear filters
              </Button> */}
            </EmptyState>
          ) : null}
        </>
      )}
    </>
  )
}

export default Timeline
