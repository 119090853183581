import React from 'react'
import { ListBullets, ArrowRight } from '@phosphor-icons/react'
import { ModalFooter } from 'reactstrap'
import { useSteps } from './step-context'
import Button from '../../../../../components/ui/button'

export function Intro() {
  const { nextStep } = useSteps()
  return (
    <div className='tw-flex tw-flex-1 tw-flex-col'>
      <div className='tw-flex tw-flex-1 tw-flex-col tw-items-center tw-justify-center'>
        <ListBullets size={150} weight='duotone' className='tw-text-primary' />
        <span className='tw-text-xl tw-text-black'>Check your eligiblity</span>
        <span className='tw-mt-5 tw-text-text-60'>
          Three steps to know if the contract is eligible for this program
        </span>
      </div>
      <ModalFooter>
        <Button onClick={nextStep} iconRight={<ArrowRight />}>
          Check
        </Button>
      </ModalFooter>
    </div>
  )
}
