import React from 'react'
import { cn } from 'ui'

export default function Footer({ className, style }) {
  return (
    <footer
      className={cn('tw-text-start tw-text-text-60', className)}
      style={style}
    >
      © {new Date().getFullYear()} RemotePass.
    </footer>
  )
}
