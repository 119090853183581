import React, { useState } from 'react'
import { Modal } from 'reactstrap'

import Loader from '../../components/ui/loader'
import { useResize } from '../../helpers/hooks'
import PayoneerMethodCard from '../Withdrawal/components/payoneer-method-card'
import PayoneerAccount from './payoneer-account'
import useWithdrawAccounts from './use-contractor-withdraw-accounts'
import { t } from 'i18next'

export default function PayoneerWithdrawAccounts({ onNext }) {
  const [addAccountModal, setAddAccountModal] = useState(false)
  const [checkedCard, setCheckedCard] = useState(null)

  const isMobile = useResize()

  const { accounts, loading: gettingAccounts } = useWithdrawAccounts('payoneer')

  if (gettingAccounts) {
    return <Loader minHeight='15rem' />
  }

  return (
    <div className='d-flex flex-column' style={{ gap: '1rem' }}>
      {accounts.map((account, index) => {
        return (
          <PayoneerMethodCard
            key={account?.id ?? index}
            account={account}
            selected={JSON.stringify(account) === JSON.stringify(checkedCard)}
            onClick={() => {
              setCheckedCard(account)
              onNext(account)
            }}
          />
        )
      })}

      {accounts.length > 0 ? null : (
        <>
          <button
            onClick={() => {
              setAddAccountModal(true)
            }}
            className='rp-btn-nostyle align-self-start text-primary rp-font-bold'
            type='button'
          >
            {t('+ Add New Payoneer Account')}
          </button>

          <Modal
            isOpen={addAccountModal}
            centered
            style={{
              maxWidth: isMobile ? '100vw' : '50vw',
              minHeight: isMobile ? '100vh' : undefined,
            }}
            toggle={() => {
              setAddAccountModal(false)
            }}
          >
            <PayoneerAccount />
          </Modal>
        </>
      )}
    </div>
  )
}
