import cx from 'classnames'
import React from 'react'
import toastr from 'toastr'

import payoneer from '../../../assets/images/payoneer-logo.png'
import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import {
  activatePayoneerAccount,
  deletePayoneerMethod,
} from '../../../services/api'
import { track } from '../../../utils/analytics'
import EVENTS from '../../../utils/events'
import { t } from 'i18next'

export default function PayoneerMethodCard({
  account,
  selected,
  onSelect,
  onUpdate,
  onClick,
  isLoading,
}) {
  const isButton = typeof onClick === 'function'
  const Component = isButton ? 'button' : 'div'

  const isActive = account?.active === 1
  const isDefault = account?.default === 1

  const identifier = account?.payee_id ?? account?.email ?? null

  const { startFetch: deleteMethodCard, isLoading: deletingAccount } = useFetch(
    {
      action: deletePayoneerMethod,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(
            data?.message ||
              data?.error ||
              t('Something went wrong while deleting the account'),
          )
        } else {
          onUpdate?.()
          track(EVENTS.REMOVED_WITHDRAW_METHODS, {
            withdraw_method: 'Payoneer',
            is_default: isDefault,
          })
        }
      },
      onError: (error) => {
        toastr.error(
          error?.message ||
            error?.error ||
            error ||
            t('Something went wrong while deleting the account'),
        )
      },
    },
  )

  const { startFetch: activatePayoneer, isLoading: activatingAccount } =
    useFetch({
      action: activatePayoneerAccount,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(
            data?.message ||
              data?.error ||
              t('Something went wrong while activating the account'),
          )
        } else {
          onUpdate?.()
          toastr.success(t('Account activated successfully'))
        }
      },
      onError: (error) => {
        toastr.error(
          error?.message ||
            error?.error ||
            error ||
            t('Something went wrong while activating the account'),
        )
      },
    })

  return (
    <Component
      className={cx(
        'd-flex align-items-center position-relative p-3 rounded border gap-12',
        {
          'bg-light': !isActive && !isButton,
          'bg-slate-50': !isActive,
          'w-100 text-left bg-transparent': isButton,
          'hover:bg-slate-50': !selected && isButton,
          'border-light': !selected,
          'border-primary': selected && isButton,
        },
      )}
      onClick={!isButton ? null : onClick}
      disabled={!isActive && !isButton}
    >
      <img
        src={payoneer}
        alt=''
        className='align-self-start'
        style={{
          '--size': '46px',
          width: 'var(--size)',
          height: 'auto',
          padding: '0.2rem',
          filter: selected ? undefined : 'grayscale(100%)',
        }}
      />

      <div className='flex-grow-1 align-items-start d-flex flex-column gap-8 justify-content-center'>
        <h5
          className={cx('font-size-16 mb-0', {
            'text-primary': selected,
            'text-dark': !selected,
          })}
          style={{ lineHeight: 1 }}
        >
          {t('Payoneer:')}
        </h5>
        {!identifier ? null : (
          <p
            className={cx('font-size-14 mb-0', {
              'text-primary': selected,
              'text-secondary': !selected,
            })}
            style={{ wordBreak: 'break-all', lineHeight: 1 }}
          >
            {identifier}
          </p>
        )}
        {isLoading ? (
          <>
            <i className='bx bx-loader bx-spin font-size-14' />
          </>
        ) : isButton ? null : (
          <>
            {!isActive ? null : selected ? (
              <div className='btn btn-primary rounded-sm font-size-10 py-0 px-1 font-weight-normal mt-1 cursor-default'>
                {t('Default')}
              </div>
            ) : !onSelect ? null : (
              <button
                onClick={onSelect}
                className='btn btn-outline-light rounded-sm font-size-10 py-0 px-1 font-weight-normal mt-1'
              >
                {t('Make default')}
              </button>
            )}
          </>
        )}
      </div>

      {isButton || !isActive ? (
        <Button
          className='px-1'
          size='sm'
          color='link'
          onClick={() => activatePayoneer({ account_id: account?.id })}
          style={{ width: 67 }}
        >
          {!activatingAccount ? (
            t('Reactivate')
          ) : (
            <i className='bx bx-loader bx-spin font-size-16' />
          )}
        </Button>
      ) : (
        <IconButton
          className='align-self-start text-muted'
          onClick={() => deleteMethodCard({ id: account?.id })}
          isLoading={deletingAccount}
        >
          <i className='bx bxs-trash-alt' />
        </IconButton>
      )}
    </Component>
  )
}

function IconButton({ className, onClick, isLoading, children, ...rest }) {
  return (
    <button
      type='button'
      onClick={onClick}
      className={cx('rp-btn-nostyle d-flex p-1 font-size-16', className)}
      disabled={isLoading}
      {...rest}
    >
      {isLoading ? <i className='bx bx-loader bx-spin' /> : children}
    </button>
  )
}
