import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Card, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

import addCardImage from '../../../assets/images/add-card.png'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import NoContent from '../../../components/NoContent'
import StyledTh from '../../../components/Table/StyledTh'
import TableComp from '../../../components/Table/TableComp'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import Pagination from '../../../components/ui/pagination'
import { CONTRACT_STATUS, userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions, useResize } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  getAdminExpenses,
  getAdminExpensesMonths,
  getExpenses,
  getExpensesMonths,
  getFullTimeExpenses,
  getFullTimeExpensesMoths,
} from '../../../services/api'
import { useFilters } from '../../AdminPanel/pages/cards/use-filters'
import ExpenseCard from '../../expenses/components/expense-card'
import ExpenseLine from '../../expenses/components/expense-line'
import ExpenseModal from '../components/ExpensesModal'
import TabCardHeader from '../components/tab/tab-card-header'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import { Plus } from '@phosphor-icons/react'
import { t } from 'i18next'

function formatFilters(filters) {
  const { month, ...rest } = filters
  return {
    ...rest,
    month: month?.value,
  }
}

export default function Expenses({ contractLoading }) {
  const [show, setShow] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const contract = useSelector((state) => state?.Contract?.details)
  const contractId = contract?.id

  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )
  const user = useSelector((state) => state?.Account?.user)
  const location = useLocation()
  const isAdmin = location.pathname?.startsWith('/admin/')

  const bankAccounts = useSelector((state) => state.userProfile.userProfile)

  const [filters, handleFiltersChange] = useFilters({ page: 1 })

  const { hasAccess } = usePermissions()
  const history = useHistory()
  const isMobile = useResize()

  const autoFetchMonths = !!contractId || isEmployee
  const months = useFetch(
    {
      autoFetch: autoFetchMonths,
      withAdminAccess: isAdmin,
      action: isAdmin
        ? getAdminExpensesMonths
        : isEmployee
          ? getFullTimeExpensesMoths
          : getExpensesMonths,
      body: { contract_id: contractId },
      onComplete: (data) => {
        handleFiltersChange('month', data?.months[0])
      },
    },
    [contractId],
  )

  const autoFetchList = contractId && filters?.month?.value
  const list = useFetch(
    {
      action: isAdmin
        ? getAdminExpenses
        : isEmployee
          ? getFullTimeExpenses
          : getExpenses,
      withAdminAccess: isAdmin,
      checkSuccess: true,
      body: formatFilters({ ...filters, contract_id: contractId }),
      autoFetch: autoFetchList,
    },
    [contractId, months?.data?.months?.length, filters],
  )

  const loadingList = autoFetchList ? !list.completed : list.isLoading

  const loadingMonths = autoFetchMonths ? !months.completed : months.isLoading

  const loading = contractLoading || loadingList || loadingMonths

  const hideTable = loading || months?.data?.months.length <= 1
  const showEmptyContent = !loading && months?.data?.months.length <= 1

  const handleButtonClick = () => {
    if (isEmployee) {
      if (!bankAccounts['bank-accounts']?.length) {
        setShowModal(true)
      } else {
        setShow(true)
      }
    } else {
      setShow(true)
    }
  }

  function refreshData() {
    months.startFetch({ contract_id: contractId }, false)
    list.startFetch(
      formatFilters({ ...filters, contract_id: contractId }),
      false,
    )
  }

  const paginator = list?.paginator
  const showPagination = paginator?.total > paginator?.per_page

  const contractIdsAllowed = [
    CONTRACT_STATUS.ONGOING.value,
    CONTRACT_STATUS.TERMINATED.value,
  ].includes(contract?.status?.id)

  const addIsDisabled =
    !(
      hasAccess(permissions.addExpense) || user?.type === userTypes.CONTRACTOR
    ) ||
    isAdmin ||
    !contractIdsAllowed ||
    (contract?.type === 'Full Time' &&
      user?.type === userTypes.CONTRACTOR &&
      !isEmployee)
  const isFullTime = contract?.type === 'Full Time'

  return (
    <Card className='mb-0 rp-shadow-2'>
      {hideTable ? null : (
        <TabCardHeader
          title={t('Expenses')}
          extra={
            <div className='tw-mt-2 tw-flex tw-w-full tw-flex-col tw-gap-2 md:tw-mt-0 md:tw-w-auto md:tw-flex-row'>
              <CustomSelect
                className='tw-mb-0'
                name='expenses'
                onChange={(e) => handleFiltersChange('month', e)}
                options={months?.data?.months}
                value={filters?.month}
                wrapperStyles={{ minWidth: 162 }}
              />

              <div className='tw-my-2 tw-border-t tw-border-surface-30 md:tw-hidden' />

              <PermissionTooltip
                showing={!hasAccess(permissions.addExpense)}
                area={PERMISSION_GROUP.EXPENSES.name}
                id='add-expense-btn-tooltip'
              >
                <Button
                  disabled={addIsDisabled}
                  onClick={handleButtonClick}
                  icon={<Plus size={15} />}
                >
                  {t('Add expense')}
                </Button>
              </PermissionTooltip>
            </div>
          }
        />
      )}

      {loading ? (
        <Loader minHeight='max(40vh,400px)' />
      ) : (
        <Row className='p-0 m-0'>
          {showEmptyContent ? (
            <NoContent
              headline={t('No Expenses')}
              subtitle={t('Submitted expenses will be shown here')}
              style={{ minHeight: 'max(40vh,400px)' }}
              withAction={!addIsDisabled}
              actionTitle={t('Add expense')}
              onAction={handleButtonClick}
              advanced
            />
          ) : isMobile ? (
            <div className='p-4 w-100'>
              {list.data?.map((expense) => (
                <ExpenseCard
                  key={expense?.id}
                  expense={expense}
                  refreshData={refreshData}
                />
              ))}
            </div>
          ) : (
            <Col className='p-0'>
              {loadingList ? (
                <Loader minHeight={170} />
              ) : list?.data?.length <= 0 ? (
                <NoContent
                  subtitle={t('No expenses found, please change your filters.')}
                  advanced={false}
                  style={{ minHeight: 170 }}
                />
              ) : (
                <>
                  <div className={!isAdmin && 'pb-3'}>
                    <div className='table-responsive'>
                      <TableComp className='table-centered'>
                        <thead className='thead-light'>
                          <tr
                            style={{
                              borderTop: 0,
                              borderBottom: '1px solid #E7E8F2',
                            }}
                          >
                            <StyledTh>{t('Expense')}</StyledTh>
                            <StyledTh>{t('Category')}</StyledTh>
                            <StyledTh>{t('Date')}</StyledTh>
                            {isFullTime ? (
                              <StyledTh>{t('Due Date')}</StyledTh>
                            ) : null}
                            <StyledTh>{t('Amount')}</StyledTh>
                            <StyledTh>{t('Status')}</StyledTh>
                            <StyledTh />
                          </tr>
                        </thead>
                        <tbody>
                          {list.data?.map((item, key) => (
                            <ExpenseLine
                              key={`exp-${key}`}
                              isAdmin={isAdmin}
                              contract={contract}
                              item={item}
                              refreshData={refreshData}
                            />
                          ))}
                        </tbody>
                      </TableComp>
                    </div>
                  </div>

                  {!showPagination ? null : (
                    <div className='d-flex justify-content-end mt-3 px-4'>
                      <Pagination
                        activePage={filters.page}
                        onChange={(page) => handleFiltersChange('page', page)}
                        itemsCountPerPage={paginator?.per_page ?? 20}
                        totalItemsCount={paginator?.total ?? 0}
                      />
                    </div>
                  )}
                </>
              )}
            </Col>
          )}
        </Row>
      )}

      {show && (
        <ExpenseModal
          show={show}
          hide={() => {
            setShow(false)
            refreshData()
          }}
        />
      )}

      <NoticeModal
        history={history}
        showModal={showModal}
        hide={() => setShowModal(false)}
      />
    </Card>
  )
}

const NoticeModal = ({ showModal, hide, history }) => {
  return (
    <Modal isOpen={showModal} toggle={hide}>
      <div className='modal-content'>
        <div className='modal-body'>
          <Row className='justify-content-center text-center'>
            <Col md={10}>
              <h2>{t('Add Bank account')}</h2>
              <h6 className='text-muted'>
                {t(
                  'Add your bank account details to receive expense reimbursements',
                )}
              </h6>
              <Row className='justify-content-center'>
                <Col sm='6' xs='8'>
                  <div>
                    <img src={addCardImage} alt='' className='img-fluid' />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className='modal-footer'>
          <button type='button' className='btn btn-link' onClick={hide}>
            {t('Cancel')}
          </button>
          <button
            type='button'
            className='btn btn-primary'
            data-dismiss='modal'
            onClick={() => history.push('/settings#accounts')}
          >
            {t('Add Bank Account')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export const ImageModal = ({
  show,
  hide,
  photo,
  title = 'Expense Photo',
  downloadLink,
}) => {
  return (
    <Modal isOpen={show} toggle={hide} centered size='lg' scrollable>
      <ModalHeader toggle={hide}>{title}</ModalHeader>
      <ModalBody>
        {!!downloadLink && (
          <Row className='justify-content-center mb-2'>
            <a className='mx-2 btn btn-primary' href={downloadLink} download>
              {t('Download')}
            </a>
          </Row>
        )}
        <img
          src={photo}
          alt=''
          className='border d-block mx-auto rounded'
          style={{ width: 'auto', minHeight: '50vh', maxHeight: '80vh' }}
        />

        <a
          href={photo}
          target='_blank'
          rel='noreferrer'
          className='text-center d-block mt-2'
        >
          {t('Open image in a new tab')}
        </a>
      </ModalBody>
    </Modal>
  )
}
