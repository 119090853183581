import { t } from 'i18next'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  Container,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  TabPane,
} from 'reactstrap'
import toastr from 'toastr'

import DropzoneInput from '../../components/Common/dropzone-input'
import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import ModalHeader from '../../components/ModalHeader'
import StepContainer from '../../components/Steps/StepContainer'
import Button from '../../components/ui/button'
import TabContent from '../../components/ui/tabs'
import { userTypes } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import { updateCompanyInfo as updateCompanyInfoAction } from '../../services/api'
import { tag } from '../../utils/analytics'
import { EVENTS } from '../../utils/analytics/events'
import { getErrorMessage } from '../../utils/get-errors'
import { mapCountryToOption } from '../../utils/map-to-option'
import LabelContent from '../Contract/CreateContract/components/label-content'
import { FILE_SIZE_LIMITS_IN_BYTES } from '../Contract/utils/constants'

const fields = {
  companyName: {
    name: 'companyName',
    label: 'Legal Company Name:',
    required: true,
    formText: 'This is the name of the company to be invoiced.',
  },
  taxNumber: {
    name: 'taxNumber',
    label: 'Add tax number (if applicable):',
    formText: 'Enter Tax ID. EIN or VAT number.',
  },
  linkedinProfileUrl: {
    name: 'linkedin_profile_url',
    label: 'Company Linkedin Profile URL',
    required: true,
  },
  websiteUrl: {
    name: 'website_url',
    label: 'Company Website URL',
    required: true,
  },
  personalLinkedinUrl: {
    name: 'personal_linkedin_profile_url',
    label: 'Personal Linkedin URL',
    required: true,
  },
  authorizedSignatory: {
    name: 'authorizedSignatory',
    label: 'Are you an authorized signatory?',
    required: true,
    options: {
      yes: {
        label: 'Yes',
        value: 'signatoryYes',
        required: true,
      },
      no: {
        label: 'No',
        value: 'signatoryNo',
        required: true,
      },
    },
  },
  signatoryName: {
    name: 'signatoryName',
    label: 'Full legal name of the authorized signatory:',
    required: true,
  },
  signatoryCountryId: {
    name: 'signatoryCountryId',
    label: 'Country of residence of the authorized signatory:',
    required: true,
  },
  signatoryEmail: {
    name: 'signatoryEmail',
    label: 'Email of the authorized signatory:',
    required: true,
  },

  registrationDocument: {
    name: 'registrationDocument',
    label: 'Company registration document:',
    required: true,
    formText:
      'Certificate of incorporation. For US companies, use IRS registration or Certificate of Good Standing.',
  },
}

export default function RegistrationDocument() {
  const dropzoneRef = useRef()
  const user = useSelector((state) => state.userProfile.userProfile)
  const staticData = useSelector((state) => state.Layout.staticData)
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const kycNotVerified =
    userProfile?.kyc_verified !== 1 &&
    userProfile?.type === userTypes.COMPANY &&
    userProfile?.is_company_creator
  const history = useHistory()

  const goBack = useCallback(() => {
    const search = history.location.search
    const redirect = new URLSearchParams(search).get('redirect')
    history.push(redirect ?? '/activity')
  }, [history])

  useEffect(() => {
    if (
      user?.type !== userTypes.COMPANY ||
      ['submitted', 'approved'].includes(user?.kyb_status)
    ) {
      goBack()
    }
  }, [goBack, history, user?.kyb_status, user?.type, kycNotVerified])

  const fullName = useMemo(
    () =>
      [user?.first_name, user?.middle_name, user?.last_name]
        .filter(Boolean)
        .join(' '),
    [user?.first_name, user?.last_name, user?.middle_name],
  )

  const [formData, setFormData] = useState({
    [fields.companyName.name]: user?.company.dba ?? user?.company.name,
    [fields.signatoryName.name]: fullName,
    [fields.signatoryEmail.name]: user?.email,
  })
  const [formErrors, setFormErrors] = useState({})

  const handleChange = useCallback(
    (key, value) => {
      const changes = { [key]: value }

      if (key === fields.authorizedSignatory.name) {
        if (value === fields.authorizedSignatory.options.yes.value) {
          changes[fields.signatoryName.name] = fullName
          changes[fields.signatoryEmail.name] = user?.email
        } else if (value === fields.authorizedSignatory.options.no.value) {
          changes[fields.signatoryName.name] = ''
          changes[fields.signatoryEmail.name] = ''
        }

        // reset related error
        setFormErrors((prevErrors) => {
          const _formErrors = { ...prevErrors }

          delete _formErrors[fields.signatoryEmail.name]
          delete _formErrors[fields.signatoryName.name]
          delete _formErrors[fields.signatoryCountryId.name]

          return _formErrors
        })
      }

      setFormData((formData) => ({ ...formData, ...changes }))
    },
    [fullName, user?.email],
  )

  const userIsAuthorizedSignatory = useMemo(
    () =>
      formData[fields.authorizedSignatory.name] ===
      fields.authorizedSignatory.options.yes.value,
    [formData],
  )

  const { startFetch: updateCompanyInfo, isLoading: updatingCompanyInfo } =
    useFetch({
      action: updateCompanyInfoAction,
      onComplete: (data) => {
        if (
          Object.prototype.hasOwnProperty.call(data, 'success') &&
          data.success === false
        ) {
          toastr.error(
            getErrorMessage(data),
            'Something went wrong please try again',
          )
        } else {
          const eventData = {
            date: new Date().toISOString(),
            email: user?.email,
            userID: user?.id,
            companyID: user?.company?.id,
          }
          tag(EVENTS.KYB_SUBMITTED, eventData)

          goBack()
        }
      },
      onError: (error) => {
        toastr.error(getErrorMessage(error))
      },
    })

  function handleSubmit(e) {
    e.preventDefault()
    const allErrors = {}

    if (!formData[fields.linkedinProfileUrl.name]) {
      allErrors[fields.linkedinProfileUrl.name] =
        'Linkedin Profile URL is required.'
    }
    if (!formData[fields.websiteUrl.name]) {
      allErrors[fields.websiteUrl.name] = 'Website URL is required.'
    }
    if (!formData[fields.personalLinkedinUrl.name]) {
      allErrors[fields.personalLinkedinUrl.name] =
        'Personal linkedin URL is required.'
    }

    if (!formData[fields.authorizedSignatory.name]) {
      allErrors[fields.authorizedSignatory.name] = 'This field is required.'
    }

    if (!formData[fields.signatoryName.name]) {
      allErrors[fields.signatoryName.name] = 'This field is required.'
    }

    if (!formData[fields.signatoryEmail.name]) {
      allErrors[fields.signatoryEmail.name] = 'This field is required.'
    }

    if (!formData[fields.signatoryCountryId.name]) {
      allErrors[fields.signatoryCountryId.name] = 'This field is required.'
    }

    if (!formData[fields.registrationDocument.name]) {
      allErrors[fields.registrationDocument.name] = 'Document file is required.'
      if (dropzoneRef?.current) {
        dropzoneRef.current.focus()
      }
    } else if (
      formData[fields.registrationDocument.name]?.size >
      FILE_SIZE_LIMITS_IN_BYTES.TWO_MB
    ) {
      allErrors[fields.registrationDocument.name] =
        'Document file should be less than 2MB.'
      if (dropzoneRef?.current) {
        dropzoneRef.current.focus()
      }
    }
    if (Object.keys(allErrors)?.length > 0) {
      setFormErrors(allErrors)
    } else {
      setFormErrors({})
      const body = {
        legal_name: formData[fields.companyName.name],
        tax_number: formData[fields.taxNumber.name],
        linkedin_profile_url: formData[fields.linkedinProfileUrl.name],
        personal_linkedin_profile_url:
          formData[fields.personalLinkedinUrl.name],
        website_url: formData[fields.websiteUrl.name],
        signatory_name: formData[fields.signatoryName.name],
        signatory_email: formData[fields.signatoryEmail.name],
        registration_document: formData[fields.registrationDocument.name],
        signatory_country_id: formData[fields.signatoryCountryId.name]?.id,
      }
      updateCompanyInfo(body)
    }
  }

  function handleDropRejected([firstFileRejection]) {
    const { errors } = firstFileRejection ?? {}
    const [firstError] = errors ?? []

    setFormErrors({
      [fields.registrationDocument.name]:
        firstError.code === 'file-invalid-type'
          ? 'File type must be a PDF.'
          : firstError.code === 'file-too-large'
            ? 'File size larger than 2MB'
            : 'Something went wrong please try again',
    })
    if (dropzoneRef?.current) {
      dropzoneRef.current.focus()
    }
  }

  return (
    <div className='page-content min-vh-100 px-0 pt-0 m-0'>
      <Container fluid className='px-0'>
        <ModalHeader action={goBack} />

        <TabContent activeTab={0} className='twitter-bs-wizard-tab-content'>
          <TabPane tabId={0}>
            <StepContainer
              index={0}
              total={1}
              title={t('Confirm your company details')}
              onNext={() => {}}
              disableNext={true}
              noFooter
              cardClassName='md:w-50'
            >
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label htmlFor={fields.companyName.name}>
                    {fields.companyName.label}
                  </Label>
                  <div className='d-flex align-items-center'>
                    <Input
                      name={fields.companyName.name}
                      id={fields.companyName.name}
                      value={formData[fields.companyName.name]}
                      onChange={(e) =>
                        handleChange(fields.companyName.name, e.target.value)
                      }
                      required={fields.companyName.required}
                      className='rounded-left'
                      style={{ borderRadius: 0 }}
                      readOnly
                      disabled
                    />

                    <div
                      className='align-self-stretch d-flex align-items-center rounded-right px-2 bg-light text-secondary'
                      style={{
                        border: '1px solid #E7E8F2',
                        borderLeft: 0,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {user?.company?.type?.name}
                    </div>
                  </div>
                  <FormText>{fields.companyName.formText}</FormText>
                </FormGroup>

                <FormGroup>
                  <Label htmlFor={fields.taxNumber.name}>
                    {fields.taxNumber.label}
                  </Label>
                  <Input
                    name={fields.taxNumber.name}
                    id={fields.taxNumber.name}
                    value={formData[fields.taxNumber.name]}
                    onChange={(e) =>
                      handleChange(fields.taxNumber.name, e.target.value)
                    }
                  />

                  <FormText>{fields.taxNumber.formText}</FormText>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor={fields.linkedinProfileUrl.name}>
                    <LabelContent
                      className='mb-2'
                      required={fields.linkedinProfileUrl.required}
                    >
                      {fields.linkedinProfileUrl.label}
                    </LabelContent>
                  </Label>
                  <Input
                    name={fields.linkedinProfileUrl.name}
                    id={fields.linkedinProfileUrl.name}
                    value={formData[fields.linkedinProfileUrl.name]}
                    invalid={!!formErrors[fields.linkedinProfileUrl.name]}
                    onChange={(e) =>
                      handleChange(
                        fields.linkedinProfileUrl.name,
                        e.target.value,
                      )
                    }
                  />

                  <FormText>{fields.linkedinProfileUrl.formText}</FormText>
                  <FormFeedback>
                    {formErrors[fields.linkedinProfileUrl.name]}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor={fields.websiteUrl.name}>
                    <LabelContent
                      className='mb-2'
                      required={fields.websiteUrl.required}
                    >
                      {fields.websiteUrl.label}
                    </LabelContent>
                  </Label>
                  <Input
                    name={fields.websiteUrl.name}
                    id={fields.websiteUrl.name}
                    value={formData[fields.websiteUrl.name]}
                    invalid={!!formErrors[fields.websiteUrl.name]}
                    onChange={(e) =>
                      handleChange(fields.websiteUrl.name, e.target.value)
                    }
                  />

                  <FormText>{fields.websiteUrl.formText}</FormText>
                  <FormFeedback>
                    {formErrors[fields.websiteUrl.name]}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor={fields.personalLinkedinUrl.name}>
                    <LabelContent
                      className='mb-2'
                      required={fields.personalLinkedinUrl.required}
                    >
                      {fields.personalLinkedinUrl.label}
                    </LabelContent>
                  </Label>
                  <Input
                    name={fields.personalLinkedinUrl.name}
                    id={fields.personalLinkedinUrl.name}
                    value={formData[fields.personalLinkedinUrl.name]}
                    invalid={!!formErrors[fields.personalLinkedinUrl.name]}
                    onChange={(e) =>
                      handleChange(
                        fields.personalLinkedinUrl.name,
                        e.target.value,
                      )
                    }
                  />

                  <FormText>{fields.personalLinkedinUrl.formText}</FormText>
                  <FormFeedback>
                    {formErrors[fields.personalLinkedinUrl.name]}
                  </FormFeedback>
                </FormGroup>
                <FormGroup tag='fieldset'>
                  <legend>
                    <LabelContent
                      required={fields.authorizedSignatory.required}
                    >
                      {fields.authorizedSignatory.label}
                    </LabelContent>
                  </legend>
                  <FormGroup check inline>
                    <Input
                      type='radio'
                      name={fields.authorizedSignatory.name}
                      id={fields.authorizedSignatory.options.yes.value}
                      value={fields.authorizedSignatory.options.yes.value}
                      checked={
                        formData[fields.authorizedSignatory.name] ===
                        fields.authorizedSignatory.options.yes.value
                      }
                      onChange={(e) =>
                        handleChange(
                          fields.authorizedSignatory.name,
                          e.target.value,
                        )
                      }
                      invalid={!!formErrors[fields.authorizedSignatory.name]}
                    />{' '}
                    <Label
                      className='font-size-14 cursor-pointer'
                      htmlFor={fields.authorizedSignatory.options.yes.value}
                      check
                    >
                      {fields.authorizedSignatory.options.yes.label}
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Input
                      type='radio'
                      name={fields.authorizedSignatory.name}
                      id={fields.authorizedSignatory.options.no.value}
                      value={fields.authorizedSignatory.options.no.value}
                      checked={
                        formData[fields.authorizedSignatory.name] ===
                        fields.authorizedSignatory.options.no.value
                      }
                      onChange={(e) =>
                        handleChange(
                          fields.authorizedSignatory.name,
                          e.target.value,
                        )
                      }
                      invalid={!!formErrors[fields.authorizedSignatory.name]}
                    />{' '}
                    <Label
                      className='font-size-14 cursor-pointer'
                      htmlFor={fields.authorizedSignatory.options.no.value}
                      check
                    >
                      {fields.authorizedSignatory.options.no.label}
                    </Label>
                  </FormGroup>

                  <FormFeedback
                    className={
                      !formErrors[fields.authorizedSignatory.name]
                        ? undefined
                        : 'd-block'
                    }
                  >
                    {formErrors[fields.authorizedSignatory.name]}
                  </FormFeedback>
                </FormGroup>

                {!formData[fields.authorizedSignatory.name] ? null : (
                  <>
                    <FormGroup>
                      <Label htmlFor={fields.signatoryName.name}>
                        <LabelContent
                          className='mb-2'
                          required={fields.signatoryName.required}
                        >
                          {userIsAuthorizedSignatory
                            ? 'Confirm your legal name:'
                            : fields.signatoryName.label}
                        </LabelContent>
                      </Label>
                      <Input
                        name={fields.signatoryName.name}
                        id={fields.signatoryName.name}
                        value={formData[fields.signatoryName.name]}
                        onChange={(e) =>
                          handleChange(
                            fields.signatoryName.name,
                            e.target.value,
                          )
                        }
                      />

                      <FormFeedback
                        className={
                          !formErrors[fields.signatoryName.name]
                            ? undefined
                            : 'd-block'
                        }
                      >
                        {formErrors[fields.signatoryName.name]}
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor={fields.signatoryCountryId.name}>
                        <LabelContent
                          className='mb-2'
                          required={fields.signatoryCountryId.required}
                        >
                          {fields.signatoryCountryId.label}
                        </LabelContent>
                      </Label>
                      <CustomSelect
                        name={fields.signatoryCountryId.name}
                        value={formData[fields.signatoryCountryId.name]}
                        inputId={fields.signatoryCountryId.name}
                        onChange={(value) =>
                          handleChange(fields.signatoryCountryId.name, value)
                        }
                        options={staticData?.countries.map((c) =>
                          mapCountryToOption(c),
                        )}
                      />

                      <FormFeedback
                        className={
                          !formErrors[fields.signatoryCountryId.name]
                            ? undefined
                            : 'd-block'
                        }
                      >
                        {formErrors[fields.signatoryCountryId.name]}
                      </FormFeedback>
                    </FormGroup>
                    {userIsAuthorizedSignatory ? null : (
                      <FormGroup>
                        <Label>
                          <LabelContent
                            required={fields.signatoryEmail.required}
                          >
                            {fields.signatoryEmail.label}
                          </LabelContent>
                        </Label>
                        <Input
                          name={fields.signatoryEmail.name}
                          id={fields.signatoryEmail.name}
                          type='email'
                          value={formData[fields.signatoryEmail.name]}
                          onChange={(e) =>
                            handleChange(
                              fields.signatoryEmail.name,
                              e.target.value,
                            )
                          }
                        />

                        <FormFeedback
                          className={
                            !formErrors[fields.signatoryEmail.name]
                              ? undefined
                              : 'd-block'
                          }
                        >
                          {formErrors[fields.signatoryEmail.name]}
                        </FormFeedback>
                      </FormGroup>
                    )}
                  </>
                )}

                <FormGroup>
                  <Label>
                    <LabelContent
                      required={fields.registrationDocument.required}
                    >
                      {fields.registrationDocument.label}
                    </LabelContent>
                  </Label>
                  <FormText className='mt-0 mb-2'>
                    {fields.registrationDocument.formText}
                  </FormText>
                  <DropzoneInput
                    rootRef={dropzoneRef}
                    invalid={formErrors[fields.registrationDocument.name]}
                    className={
                      formErrors[fields.registrationDocument.name]
                        ? 'is-invalid'
                        : ''
                    }
                    name={fields.registrationDocument.name}
                    onDropRejected={handleDropRejected}
                    onDropAccepted={(acceptedFiles) => {
                      handleChange(
                        fields.registrationDocument.name,
                        acceptedFiles[0],
                      )
                      setFormErrors((e) => ({
                        ...e,
                        [fields.registrationDocument.name]: null,
                      }))
                    }}
                    accept={{ 'application/pdf': ['.pdf'] }}
                  >
                    <div
                      className='d-flex align-items-center flex-column'
                      style={{ gap: '1rem', minHeight: 92 }}
                    >
                      <i
                        className='bx bxs-file-blank'
                        style={{ fontSize: 32 }}
                      />

                      {formData[fields.registrationDocument.name] ? (
                        <div className='align-items-center d-flex gap-16'>
                          <p className='mb-0 text-dark font-size-16'>
                            {formData[fields.registrationDocument.name].name}
                          </p>
                          <button
                            className='d-flex p-1 rp-btn-nostyle text-danger'
                            onClick={(e) => {
                              e.stopPropagation()
                              setFormData((d) => ({
                                ...d,
                                [fields.registrationDocument.name]: null,
                              }))
                            }}
                          >
                            <i
                              className='bx bx-trash-alt'
                              style={{ fontSize: 18 }}
                            />
                          </button>
                        </div>
                      ) : (
                        <>
                          <div className='text-center'>
                            <p className='mb-0 text-dark font-size-16'>
                              {t('Drop file here or click to upload')}
                            </p>
                            <p className='mb-0 text-secondary'>
                              {t('PDF only, max file size 2MB')}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </DropzoneInput>
                  <FormFeedback
                    className={
                      !formErrors[fields.registrationDocument.name]
                        ? undefined
                        : 'd-block'
                    }
                  >
                    {formErrors[fields.registrationDocument.name]}
                  </FormFeedback>
                </FormGroup>

                <hr
                  className='mt-0'
                  style={{
                    marginLeft: -24,
                    marginRight: -24,
                    borderTopColor: '#eff2f7',
                  }}
                />

                <div
                  className='d-flex justify-content-end'
                  style={{ marginBottom: -8 }}
                >
                  <Button
                    disabled={updatingCompanyInfo}
                    loading={updatingCompanyInfo}
                    type='submit'
                  >
                    {t('Submit')}
                  </Button>
                </div>
              </form>
            </StepContainer>
          </TabPane>
        </TabContent>
      </Container>
    </div>
  )
}
