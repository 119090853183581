import React from 'react'
import { cn } from 'ui'
import { t } from 'i18next'

export function EmptyState({
  pictureElement,
  title = t('You are all set!'),
  textElement,
  className,
  children,
}) {
  return (
    <div
      className={cn(
        'tw-flex tw-min-h-64 tw-flex-col tw-items-center tw-justify-center tw-rounded tw-bg-white tw-px-6 tw-pb-6 tw-pt-4 tw-text-center tw-shadow-sm',
        className,
      )}
    >
      <div className='tw-text-primary-100'>{pictureElement}</div>

      <h4 className='tw-mb-0 tw-text-2xl tw-font-medium'>{title}</h4>

      {!textElement ? null : (
        <p className='tw-mb-0 tw-mt-1 tw-text-sm tw-font-semibold tw-text-text-80'>
          {textElement}
        </p>
      )}
      {children}
    </div>
  )
}
