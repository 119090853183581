import { PlusCircle } from '@phosphor-icons/react'
import React, { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledTooltip,
} from 'reactstrap'
import { cn } from 'ui'
import Loader from '../../../../../components/ui/loader'
import { groupPlaceholders } from '../tools'

export function ActionPlaceholders({ onInsert, items, isLoading }) {
  const { watch } = useFormContext()
  const triggerCategory = watch(`steps.0`)?.event?.category
  const [search, setSearch] = useState('')
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const placeholders = useMemo(() => {
    return groupPlaceholders(
      items
        ?.filter((i) => i.available_categories.includes(triggerCategory))
        ?.filter((i) => i.title?.toLowerCase()?.includes(search)) ?? [],
    )
  }, [items, search, triggerCategory])

  return (
    <>
      {isLoading ? (
        <Loader minHeight='20px' className='tw-my-2 tw-flex-1' />
      ) : (
        <Dropdown
          isOpen={dropdownOpen}
          toggle={() => setDropdownOpen((prevState) => !prevState)}
        >
          <DropdownToggle tag='div'>
            <button
              disabled={!triggerCategory}
              id='add-variable-button'
              className={cn(
                'tw-flex tw-flex-row tw-gap-1 tw-rounded-full tw-border tw-border-surface-30 tw-bg-primary-10 tw-px-4 tw-py-1.5 tw-text-primary',
                dropdownOpen && 'tw-bg-primary tw-text-white',
                !triggerCategory && 'tw-opacity-70',
              )}
            >
              <span>Add Variable</span>
              <PlusCircle size={20} weight='fill' />
            </button>

            {!triggerCategory ? (
              <UncontrolledTooltip target='add-variable-button'>
                Select a trigger to add a variable.
              </UncontrolledTooltip>
            ) : null}
          </DropdownToggle>
          <DropdownMenu className='tw-max-h-[300px] tw-w-[250px] tw-overflow-auto'>
            <div className='tw-flex tw-flex-col tw-gap-2 tw-px-2 tw-py-2'>
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder='Search...'
                id='search-input'
              />
              {placeholders.map((f, index) => {
                return (
                  <DropdownItem
                    className={f.type === 'header' ? 'tw-ml-2' : ''}
                    onClick={() => onInsert(f)}
                    key={`${f.title}-${index}`}
                    header={f.type === 'header'}
                  >
                    {f.type === 'header' ? f.title.toUpperCase() : f.title}
                  </DropdownItem>
                )
              })}
            </div>
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  )
}
