import React from 'react'
import { useOwners } from '../contexts/owners-context'
import Shimmer from '../../../../../components/ui/shimmer'
import get from 'lodash/get'
import { Avatar } from 'ui'
import { assetsStorage } from '../../../../../helpers/config'
import { getFlagUrlFromIso2 } from '../../../../../components/ui/flag'
import { UncontrolledTooltip } from 'reactstrap'

export function AutomationOwnerColumn({ rowData }) {
  const { isLoading, owners } = useOwners()
  const owner = get(owners, rowData?.creator_id, null)
  const id = `avatar-container-${rowData._id}`
  if (isLoading)
    return <Shimmer width={40} height={40} className='tw-rounded-full' />

  if (owner)
    return (
      <>
        <Avatar
          id={id}
          photo={owner?.photo}
          flag={
            owner?.country?.iso2
              ? `${assetsStorage}/${getFlagUrlFromIso2(owner.country.iso2)}`
              : null
          }
          flagIsAbsolute
          className='!tw-h-[40px] !tw-w-[40px]'
          name={owner?.name}
        />

        <UncontrolledTooltip target={id}>{owner.name}</UncontrolledTooltip>
      </>
    )

  return 'N/A'
}
