import React from 'react'
import { useFormContext } from 'react-hook-form'
import usePrevious from '../../../../../utils/use-previous'
import CustomSelect from '../../../../../components/Forms/CustomSelect/CustomSelect'
import { triggers } from '../tools/constants'
import { IncompatibleSetupModal } from '../modals/incompatible-setup-modal'

export const TriggerSelect = (props) => {
  const { error, onChange, value } = props
  const { setValue } = useFormContext()
  const previous = usePrevious(value)

  return (
    <>
      <CustomSelect
        options={triggers}
        placeholder='Select trigger'
        onChange={onChange}
        hasError={!!error}
        error={error}
        value={value}
        isClearable
      />
      <IncompatibleSetupModal
        trigger={value}
        onCancelPress={() => onChange(previous)}
        onUpdatePress={({
          compatibleSteps,
          compatibleFilters,
          isStepsIncompatible,
          isFiltersIncompatible,
        }) => {
          if (compatibleFilters && isFiltersIncompatible)
            setValue('filters', compatibleFilters)
          if (compatibleSteps && isStepsIncompatible)
            setValue('steps', compatibleSteps)
        }}
      />
    </>
  )
}
