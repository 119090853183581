import {
  ArrowsLeftRight,
  Buildings,
  CaretDown,
  Gear,
  SignOut,
} from '@phosphor-icons/react'
import { t } from 'i18next'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

import { Avatar } from 'ui'
import { BE_CONTRACT_CATEGORY, userTypes } from '../../../helpers/enum'
import { usePermissions } from '../../../helpers/hooks'
import permissions, {
  allCompanyPermissions,
} from '../../../helpers/permissions'
import { sideIconStyles } from '../../BurgerMenu'

export default function ProfileMenu() {
  const [menu, setMenu] = useState(false)
  const user = useSelector((state) => state.Account?.user)
  const isClient = user?.type === userTypes.COMPANY
  const isContractor = user?.type === userTypes.CONTRACTOR

  const history = useHistory()

  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  const contractorType = useSelector(
    (state) => state?.userProfile?.userProfile?.contractor_type,
  )
  const isEmployee = contractorType === BE_CONTRACT_CATEGORY.EMPLOYEE
  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE

  const { hasAccess } = usePermissions()

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className='tw-h-full'
      >
        <DropdownToggle
          className='!tw-flex !tw-h-full tw-items-center tw-gap-4 tw-px-4 aria-[expanded=true]:tw-bg-surface-10'
          id='page-header-user-dropdown'
          tag='button'
        >
          <Avatar
            photo={userProfile?.photo}
            name={userProfile?.first_name || userProfile?.email}
            size='lg'
            label={isClient ? 'Client' : 'Contractor'}
          />

          <div className='tw-flex tw-min-w-[120px] tw-flex-col tw-items-start'>
            <span className='tw-font-semibold tw-text-black' translate='no'>
              {user?.first_name} {user?.last_name}
            </span>
            <span className='tw-font-medium tw-text-text-60'>
              {isClient
                ? t('Client')
                : isEmployee || isDeEmployee
                  ? t('Employee')
                  : t('Contractor')}
            </span>
          </div>
          <CaretDown size={24} />
        </DropdownToggle>
        <DropdownMenu right className='!tw-mt-2 tw-w-full'>
          {user?.type === userTypes.COMPANY && (
            <DropdownItem
              tag={Link}
              to='/profile-settings'
              className='!tw-flex tw-min-h-12 tw-items-center tw-gap-4 !tw-py-2 tw-text-base'
            >
              <Gear {...sideIconStyles} />
              {t('Profile Settings')}
            </DropdownItem>
          )}
          {(hasAccess(allCompanyPermissions) || isContractor) &&
            !isEmployee && (
              <DropdownItem
                tag={Link}
                to='/settings'
                className='!tw-flex tw-min-h-12 tw-items-center tw-gap-4 !tw-py-2 tw-text-base'
              >
                <Buildings {...sideIconStyles} />

                {isClient ? t('Company Settings') : t('Profile Settings')}
              </DropdownItem>
            )}
          {isEmployee && (
            <DropdownItem
              tag={Link}
              to='/settings'
              className='!tw-flex tw-min-h-12 tw-items-center tw-gap-4 !tw-py-2 tw-text-base'
            >
              <Buildings {...sideIconStyles} />

              {t('Profile Settings')}
            </DropdownItem>
          )}
          <div className='dropdown-divider' />
          {!isEmployee &&
            hasAccess(permissions.switchAccount) &&
            user[0] &&
            user[1] && (
              <DropdownItem
                tag='button'
                type='button'
                onClick={() => history.push('/switch')}
                className='!tw-flex tw-min-h-12 tw-items-center tw-gap-4 !tw-py-2 tw-text-base'
              >
                <ArrowsLeftRight {...sideIconStyles} />

                <div>
                  <div className='tw-capitalize tw-leading-none'>
                    {t('Switch Account')}
                  </div>
                  <div className='tw-text-xs tw-text-text-70'>
                    {t('To account', {
                      type: isContractor ? t('Client') : t('Contractor'),
                    })}
                  </div>
                </div>
              </DropdownItem>
            )}
          <DropdownItem
            tag={Link}
            to='/logout'
            className='!tw-flex tw-min-h-12 tw-items-center tw-gap-4 !tw-py-2 tw-text-base !tw-text-systemRed-100 tw-transition-colors hover:!tw-bg-systemRed-80/10'
          >
            <SignOut {...sideIconStyles} className='tw-text-current' />
            {t('Logout')}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}
