import React, { useState } from 'react'
import { getTimeOffTypeId } from '../../Activity'
import { cn } from 'ui'
import {
  getEventColorsClasses,
  getEventTooltipText,
  getRemainingLength,
  getTimelineRemainingLength,
} from '../helpers'
import Flag from '../../../components/ui/flag'
import {
  Cake,
  Coins,
  Confetti,
  PaperPlaneTilt,
  UserPlus,
} from '@phosphor-icons/react'
import { PolicyTypeIcon } from '../../new-time-off-policy'
import { TimeOffRequestDetails } from '../timeoff-request'
import { Button, TooltipTrigger } from 'react-aria-components'
import { TooltipV2 } from '../../../components/VerticalLayout/tooltip-v2'
import { track } from '../../../utils/analytics'

function CalendarEventItem({
  event,
  slot,
  onlyIcon,
  isTimelineItem,
  className,
  startDate,
  fetchEvents,
}) {
  const [showEvent, setShowEvent] = useState(false)
  const remainingLength = isTimelineItem
    ? getTimelineRemainingLength(event, startDate)
    : getRemainingLength(event, slot)

  const onCalendarEventClick = () => {
    track('Calendar event clicked', {
      Type: event.type,
    })
    if (['time_off_request', 'time_off'].includes(event.type)) {
      setShowEvent(true)
    }
  }

  return (
    <>
      <TooltipTrigger delay={300} closeDelay={300}>
        <Button
          className={cn(
            'tw-absolute tw-z-10 tw-my-2 tw-inline-flex tw-h-5 tw-cursor-pointer tw-items-center tw-justify-start tw-gap-2 tw-rounded-r-full tw-px-4 tw-transition-all tw-duration-300',
            getEventColorsClasses(event),
            {
              'tw-ml-4 tw-rounded-l-full':
                event.isStartOfEvent || isTimelineItem,
              'tw-border-l-0': !event.isStartOfEvent && !isTimelineItem,
              'tw-left-0 tw-top-5 !tw-my-0 !tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-p-0':
                isTimelineItem,
              'tw-justify-start tw-px-2':
                isTimelineItem && remainingLength > 100,
              'tw-left-[calc(50%-32px)]':
                isTimelineItem && remainingLength === 100,
            },
            className,
          )}
          style={{
            width:
              isTimelineItem && remainingLength === 100
                ? '32px'
                : `calc(${remainingLength}% - 2rem)`,
            top: `${(event.placement - 1) * 1.5}rem`,
          }}
          onPress={onCalendarEventClick}
        >
          <EventContent
            event={event}
            onlyIcon={(isTimelineItem || onlyIcon) && remainingLength <= 100}
          />
        </Button>
        <TooltipV2 placement='bottom'>
          <div className='tw-flex tw-flex-col tw-items-start tw-pt-1 tw-text-left'>
            <div>{getEventTooltipText(event).title}</div>
            <div className='tw-flex-shrink-0 tw-text-text-30'>
              {getEventTooltipText(event).text}
            </div>
            {getEventTooltipText(event).extra ? (
              <div className='tw-flex-shrink-0 tw-text-text-30'>
                {getEventTooltipText(event).extra}
              </div>
            ) : null}
          </div>
        </TooltipV2>
      </TooltipTrigger>
      {showEvent && (
        <TimeOffRequestDetails
          toggle={() => setShowEvent(false)}
          show={showEvent}
          event={event}
          fetchEvents={fetchEvents}
          icon={getCalendarEventIcon(event, 20)}
        />
      )}
    </>
  )
}

export function getCalendarEventIcon(event, size = 12) {
  switch (event.type) {
    case 'birthday':
      return <Cake size={size} className='tw-shrink-0' />
    case 'time_off':
    case 'time_off_request':
      return (
        <PolicyTypeIcon
          typeId={getTimeOffTypeId(event.metadata?.timeOffType)}
          size={size}
          className='tw-shrink-0'
        />
      )
    case 'work_anniversary':
      return <Confetti size={size} className='tw-shrink-0' />
    case 'new_joiner':
      return <UserPlus size={size} className='tw-shrink-0' />
    case 'company_national_holiday':
    case 'worker_national_holiday':
      return (
        <Flag size='12px' className='tw-shrink-0' url={event.metadata?.flag} />
      )
    case 'paydays':
      return <Coins size={size} className='tw-shrink-0' />
    default:
      return '' // add more event types
  }
}

const TrunkatedText = ({ children }) => (
  <span className='tw-truncate tw-text-[10px] tw-uppercase'>{children}</span>
)

const WorkerName = ({ event }) => (
  <TrunkatedText>{event.worker?.name || event.name}</TrunkatedText>
)
export function EventContent({ event, onlyIcon }) {
  const renderEventContent = () => (
    <>
      {getCalendarEventIcon(event)}
      {!onlyIcon && <WorkerName event={event} />}
    </>
  )

  switch (event.type) {
    case 'birthday':
    case 'time_off':
    case 'work_anniversary':
    case 'new_joiner':
      return renderEventContent()
    case 'company_national_holiday':
      return (
        <>
          {getCalendarEventIcon(event)}
          {!onlyIcon && <TrunkatedText>{event.metadata?.title}</TrunkatedText>}
        </>
      )
    case 'worker_national_holiday':
      return (
        <>
          {getCalendarEventIcon(event)}
          {!onlyIcon && (
            <TrunkatedText>
              {event.metadata?.title} - {event.worker?.name}
            </TrunkatedText>
          )}
        </>
      )
    case 'paydays':
      return (
        <>
          {getCalendarEventIcon(event)}
          {!onlyIcon && <WorkerName event={event} />}
        </>
      )
    case 'time_off_request':
      return (
        <>
          {getCalendarEventIcon(event)}
          {!onlyIcon && <WorkerName event={event} />}
        </>
      )
    default:
      return '' // add more event types
  }
}

export default CalendarEventItem
