import { t } from 'i18next'
import {
  ArrowSquareOut,
  Check,
  ClockClockwise,
  CurrencyCircleDollar,
  DownloadSimple,
  UserCircle,
} from '@phosphor-icons/react'
import cx from 'classnames'
import React, { useState } from 'react'
import {
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap'
import toastr from 'toastr'

import BadgeX from '../../../components/Table/BadgeX'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import { activateAllowanceInsuranceClient } from '../../../services/api'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import { SinglePlanFeatures } from '../../Insurance/components/Plan'
import { downloadFile } from '../utils/helpers'

const InsurancePlans = ({
  features,
  title,
  price,
  desc,
  currency,
  plan,
  ctaText,
  contractUser,
  contract,
  onAddAllowanceUpdate,
  hasAddPermission,
}) => {
  const [openConfirmAllowanceModal, setOpenConfirmAllowanceModal] =
    useState(false)

  const [openPdf, setOpenPdf] = useState(null)
  const onSelectPlan = () => {
    setOpenConfirmAllowanceModal(true)
  }

  return (
    <Card className='mt-3 border border-1 p-4'>
      <BadgeX
        className={cx(
          'p-2 mb-2 font-size-10 rounded-pill',
          title === 'Premium Plan' ? '' : 'invisible',
        )}
        color='primary'
        style={{ alignSelf: 'flex-start' }}
        size='sm'
      >
        {t('POPULAR')}
      </BadgeX>

      <SinglePlanFeatures
        features={features}
        plan={plan}
        title={title}
        price={price}
        desc={desc}
        currency={currency?.code}
      />

      <button
        className='btn p-0 d-flex align-items-center rp-font-medium font-size-16 text-dark'
        onClick={() => {
          setOpenPdf(plan?.handbook_url)
        }}
      >
        {t('See Details')}

        <ArrowSquareOut className='tw-ms-1' size={20} />
      </button>

      <PermissionTooltip
        id='add-allowance-plan'
        area={PERMISSION_GROUP.EXPENSES.name}
        showing={!hasAddPermission}
      >
        <Button
          onClick={onSelectPlan}
          className='mt-4'
          disabled={!hasAddPermission}
          block
        >
          {ctaText}
        </Button>
      </PermissionTooltip>

      <ConfirmAllowanceModal
        isOpen={openConfirmAllowanceModal}
        toggle={() => setOpenConfirmAllowanceModal(false)}
        plan={plan}
        contractUser={contractUser}
        contract={contract}
        onUpdate={onAddAllowanceUpdate}
      />

      {openPdf && (
        <ViewInsurancePlanPdf
          toggle={() => {
            setOpenPdf(null)
          }}
          pdfUrl={openPdf}
          planName={plan?.name}
        />
      )}
    </Card>
  )
}

const ConfirmAllowanceModal = ({
  isOpen,
  toggle,
  plan,
  contractUser,
  contract,
  onUpdate,
}) => {
  const buyAllowanceInsuranceReq = useFetch({
    action: activateAllowanceInsuranceClient,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message || 'Something went wrong')
      } else {
        onUpdate?.startFetch({ contract_id: contract?.id })
        toggle()
      }
    },
    onError: (error) => {
      toastr.error(
        typeof error === 'string'
          ? error
          : typeof error?.message === 'string'
            ? error.message
            : t('An error occurred while adding allowance') + '.',
      )
    },
  })

  const buyAllowanceInsurance = () => {
    let occurrenceId = 17 // 1st of every month
    if (new Date().getDate() < 15) {
      occurrenceId = 31 // 15th of every month
    }
    if (contract?.id && plan?.id) {
      buyAllowanceInsuranceReq.startFetch({
        contract_id: contract?.id,
        plan_id: plan?.id,
        occurrence_id: occurrenceId,
      })
    }
  }

  return (
    <Modal
      unmountOnClose
      centered
      isOpen={isOpen}
      toggle={toggle}
      keyboard
      backdrop
    >
      <ModalHeader toggle={toggle}>{t('Confirm allowance')}</ModalHeader>
      <ModalBody className='tw-text-center'>
        <Card className='p-4 mb-0 border border-1'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex flex-column align-items-start'>
              <UserCircle size={24} color='var(--secondary)' />
              <span className='text-gray-600 tw-text-sm'>
                {t('Contractor')}
              </span>
              <span className='rp-font-semibold tw-text-sm'>
                {contractUser?.full_name}
              </span>
            </div>
            <div className='d-flex flex-column align-items-start'>
              <CurrencyCircleDollar size={24} color='var(--secondary)' />
              <span className='text-gray-600 tw-text-sm'>{t('Amount')}</span>
              <span className='rp-font-semibold tw-text-sm'>
                {plan.currency.symbol} {plan.amount}
              </span>
            </div>
            <div className='d-flex flex-column align-items-start'>
              <ClockClockwise size={24} color='var(--secondary)' />
              <span className='text-gray-600 tw-text-sm'>
                {t('Occurrence')}
              </span>
              <span className='rp-font-semibold tw-text-sm'>
                {t('Every 15th')}
              </span>
            </div>
          </div>

          <hr />

          <div className='tw-text-start tw-text-sm'>
            {
              // prettier-ignore
              t(
              'Contractors have the right to use this allowance for whatever purpose they wish. This includes, but is not limited to health insurance.'
            )
            }
          </div>
        </Card>
      </ModalBody>

      <ModalFooter>
        <Button
          onClick={buyAllowanceInsurance}
          color='primary'
          type='button'
          className='w-100'
          disabled={buyAllowanceInsuranceReq.isLoading}
        >
          {buyAllowanceInsuranceReq.isLoading ? (
            <Spinner
              size='sm'
              className='tw-me-2 tw-animate-spin tw-align-middle tw-text-sm'
            />
          ) : (
            <span className='d-flex align-items-center'>
              <Check className='tw-size-6' />
              {t('Add Allowance')}
            </span>
          )}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const ViewInsurancePlanPdf = ({ pdfUrl, toggle, planName }) => {
  return (
    <Modal
      style={{
        minWidth: '80%',
        minHeight: '90%',
      }}
      isOpen={!!pdfUrl}
      toggle={toggle}
    >
      <div className='modal-header align-items-center'>
        <div className='d-flex justify-content-between align-items-center w-100'>
          <span className='rp-font-bold h5 m-0'>{planName}</span>
          <button
            className='tw-me-3 tw-flex tw-items-center tw-bg-primary-100 tw-text-secondary-10'
            onClick={() => {
              downloadFile(pdfUrl, `${planName}.pdf`)
            }}
          >
            <DownloadSimple size={20} weight='fill' className='tw-me-1' />
            <span>{t('Download PDF')}</span>
          </button>
        </div>
        <button type='button' className='close' onClick={toggle}>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <ModalBody style={{ height: '100%', minHeight: '80vh' }}>
        <iframe
          id='insurancePdfFrame'
          src={`${pdfUrl}`}
          title={planName + ' ' + t('PDF document')}
          width='100%'
          style={{
            border: 'none',
            height: 'calc(80vh - 1rem)',
          }}
          aria-labelledby={t('PDF document')}
        />
      </ModalBody>
    </Modal>
  )
}

export default InsurancePlans
