import {
  CalendarBlank,
  CaretLeft,
  CaretRight,
  Funnel,
  ListMagnifyingGlass,
  PlugsConnected,
  Sparkle,
} from '@phosphor-icons/react'
import { addDays, addMonths, isSameDay, subDays, subMonths } from 'date-fns'
import React from 'react'
import { Button as DefaultBtn, TooltipTrigger } from 'react-aria-components'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { UncontrolledTooltip } from 'reactstrap'
import { Button, cn } from 'ui'

import { CALENDAR_VIEWS, durationOptions } from '.'
import { TooltipV2 } from '../../components/VerticalLayout/tooltip-v2'
import { userTypes } from '../../helpers/enum'
import { track } from '../../utils/analytics'
import CreateICalLinksModal from './create-ical-links'
import { getDurationInDays } from './helpers'
import './styles.scss'

function getDurationText(duration) {
  switch (duration.value) {
    case 'w':
      return 'Weekly'
    case '2w':
      return 'Biweekly'
    default:
      return 'Monthly'
  }
}

const CustomDatePickerInput = React.forwardRef(function CustomDatePickerInput(
  { date, onClick, disabled },
  ref,
) {
  return (
    <Button
      ref={ref}
      isDisabled={disabled}
      lightOutline
      onPress={onClick}
      color='secondary'
      outline
      iconRight={<CalendarBlank size={20} />}
    >
      {/* December 2024 */}
      {date.toLocaleString('en-US', { month: 'long', year: 'numeric' })}
    </Button>
  )
})

function CalendarControls({
  startDate,
  setStartDate,
  view,
  setView,
  toggleFilters,
  isFiltersOpen,
  toggleInsightsPanel,
  duration,
  setDuration,
  createCalendarLink,
  setCreateCalendarLink,
  isDefaultFilters,
  hasInsights,
  setShowInsight,
  showInsight,
  showWorkersFilter,
  setShowWorkersFilter,
  hasSelectedWorkers,
}) {
  const user = useSelector((state) => state.Account?.user)
  const isClient = user?.type === userTypes.COMPANY

  const durationInDays = getDurationInDays({
    startDate,
    duration,
  })
  const today = new Date()

  const handleUpdatingViewEffect = (v) => {
    setView(v)
    if (v === CALENDAR_VIEWS.CALENDAR) {
      setDuration(durationOptions[2])
    } else {
      setDuration(durationOptions[0])
    }
  }

  const setDateToPrevPeriod = () => {
    setStartDate((prevDate) => {
      return duration.value === 'm'
        ? subMonths(prevDate, 1)
        : subDays(prevDate, durationInDays)
    })
  }

  const setDateToNextPeriod = () => {
    setStartDate((prevDate) => {
      return duration.value === 'm'
        ? addMonths(prevDate, 1)
        : addDays(prevDate, durationInDays)
    })
  }

  return (
    <div className='tw-flex tw-w-full tw-flex-wrap tw-items-center tw-justify-center tw-bg-white md:tw-justify-between'>
      <div className='tw-flex tw-items-center tw-justify-start tw-gap-2 tw-p-4'>
        <TooltipTrigger delay={0}>
          <DefaultBtn
            aria-label='Reset Filters'
            onClick={toggleFilters}
            className={cn(
              'tw-relative tw-flex tw-h-12 tw-w-12 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-[4px] tw-border tw-border-surface-30',
              {
                'tw-bg-primary-10 tw-text-primary': isFiltersOpen,
              },
            )}
          >
            <Funnel size={20} />
            {!isDefaultFilters && (
              <div className='tw-absolute tw-right-3 tw-top-3 tw-flex tw-h-[10px] tw-w-[10px] tw-items-center tw-justify-center tw-rounded-lg tw-border-2 tw-border-white tw-bg-primary'></div>
            )}
          </DefaultBtn>
          <TooltipV2 className='tw-text-xs' placement='bottom'>
            Filters
          </TooltipV2>
        </TooltipTrigger>
        {isClient && (
          <TooltipTrigger delay={0}>
            <DefaultBtn
              aria-label='Search Workers'
              onClick={() => setShowWorkersFilter((show) => !show)}
              className={cn(
                'tw-relative tw-flex tw-h-12 tw-w-12 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-[4px] tw-border tw-border-surface-30',
                {
                  'tw-bg-primary-10 tw-text-primary': showWorkersFilter,
                },
              )}
            >
              <ListMagnifyingGlass size={20} />
              {hasSelectedWorkers && (
                <div className='tw-absolute tw-right-3 tw-top-3 tw-flex tw-h-[10px] tw-w-[10px] tw-items-center tw-justify-center tw-rounded-lg tw-border-2 tw-border-white tw-bg-primary'></div>
              )}
            </DefaultBtn>
            <TooltipV2 className='tw-text-xs' placement='bottom'>
              Search Workers
            </TooltipV2>
          </TooltipTrigger>
        )}

        {/* {isClient && (
          <div className='tw-flex tw-items-center'>
            <Button
              id='calendar-switch'
              className={cn('!tw-rounded-r-none', {
                '!tw-bg-primary-10 !tw-text-primary':
                  view === CALENDAR_VIEWS.CALENDAR && !showInsight,
              })}
              onlyIcon
              onPress={() => {
                setShowInsight(null)
                if (view === CALENDAR_VIEWS.CALENDAR) return
                track(' Calendar select view', {
                  Type: capitalizeFirstLetter(CALENDAR_VIEWS.CALENDAR),
                })
                handleUpdatingViewEffect(CALENDAR_VIEWS.CALENDAR)
              }}
              icon={<CalendarBlank size={20} />}
              lightOutline
              color='secondary'
              outline
            />
            <Button
              id='timeline-switch'
              className={cn('!tw-rounded-l-none tw-border-l-0', {
                '!tw-bg-primary-10 !tw-text-primary':
                  view === CALENDAR_VIEWS.TIMELINE || showInsight,
              })}
              onPress={() => {
                setShowInsight(null)
                if (view === CALENDAR_VIEWS.TIMELINE) return
                track(' Calendar select view', {
                  Type: capitalizeFirstLetter(CALENDAR_VIEWS.TIMELINE),
                })
                handleUpdatingViewEffect(CALENDAR_VIEWS.TIMELINE)
              }}
              onlyIcon
              icon={<UsersThree size={20} />}
              lightOutline
              color='secondary'
              outline
            />
            <UncontrolledTooltip target='calendar-switch'>
              Switch to calendar view
            </UncontrolledTooltip>
            <UncontrolledTooltip target='timeline-switch'>
              Switch to timeline view
            </UncontrolledTooltip>
          </div>
        )} */}
      </div>
      <div className='tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-gap-4 tw-p-4'>
        <Button
          isDisabled={isSameDay(startDate, today) || showInsight}
          onPress={() => {
            if (isSameDay(startDate, today)) return
            track('Calendar navigate', {
              Type: 'Today',
            })
            setStartDate(today)
          }}
          lightOutline
          color='secondary'
          outline
        >
          Today
        </Button>
        <div>
          <DatePicker
            openToDate={startDate}
            onChange={(date) => {
              track('Calendar select period')
              setStartDate(date)
            }}
            disabled={showInsight}
            showMonthYearPicker
            customInput={<CustomDatePickerInput date={startDate} />}
            calendarClassName='calendar-date-picker !tw-border-surface-30 !tw-rounded !tw-shadow-lg '
            className='tw-z-50'
            renderMonthContent={(_, shortMonth) => (
              <div className='tw-p-2'>{shortMonth}</div>
            )}
          />
        </div>
        <div className='tw-flex tw-items-center tw-gap-2'>
          <Button
            isDisabled={showInsight}
            onlyIcon
            id='prev-control'
            icon={<CaretLeft size={20} />}
            lightOutline
            onPress={() => {
              track('Calendar navigate', {
                Type: 'Arrows',
              })
              setDateToPrevPeriod()
            }}
            color='secondary'
            outline
          />

          <UncontrolledTooltip target='prev-control'>
            Previous {duration.label}
          </UncontrolledTooltip>
          <Button
            onlyIcon
            isDisabled={showInsight}
            id='next-control'
            icon={<CaretRight size={20} />}
            lightOutline
            onPress={() => {
              track('Calendar navigate', {
                Type: 'Arrows',
              })
              setDateToNextPeriod()
            }}
            color='secondary'
            outline
          />
          <UncontrolledTooltip target='next-control'>
            Next {duration.label}
          </UncontrolledTooltip>
        </div>
      </div>
      <div className='tw-flex tw-items-center tw-justify-start tw-gap-2 tw-p-4'>
        {view === CALENDAR_VIEWS.TIMELINE ? (
          <Select
            options={durationOptions}
            value={duration}
            isDisabled={showInsight}
            onChange={(duration) => {
              track('Calendar select range', {
                Type: getDurationText(duration),
              })
              setDuration(duration)
            }}
            isSearchable={false}
            classNames={{
              control: () =>
                'tw-h-12 tw-w-[120px] tw-text-center !tw-cursor-pointer',
              indicatorSeparator: () => 'tw-hidden',
            }}
          />
        ) : null}

        {isClient && (
          <>
            <Button
              onlyIcon
              icon={<PlugsConnected size={20} />}
              lightOutline
              color='secondary'
              outline
              id='ical-calendar-control'
              onPress={() => setCreateCalendarLink(true)}
            />
            <UncontrolledTooltip target='ical-calendar-control'>
              Create iCalendar feeds
            </UncontrolledTooltip>

            <Button
              onlyIcon
              icon={<Sparkle size={20} />}
              lightOutline
              color='secondary'
              id='insights-controls'
              className='tw-relative [&_.tw-whitespace-nowrap]:tw-absolute [&_.tw-whitespace-nowrap]:tw-right-3 [&_.tw-whitespace-nowrap]:tw-top-3'
              outline
              onPress={() => toggleInsightsPanel()}
            >
              {hasInsights && (
                <div className='tw-h-[10px] tw-w-[10px] tw-rounded-full tw-border-2 tw-border-white tw-bg-red' />
              )}
            </Button>
            <UncontrolledTooltip target='insights-controls'>
              See what&apos;s happening
            </UncontrolledTooltip>
          </>
        )}
      </div>
      {createCalendarLink && (
        <CreateICalLinksModal
          isOpen={createCalendarLink}
          toggle={() => setCreateCalendarLink(false)}
        />
      )}
    </div>
  )
}

export default CalendarControls
