import { CaretRight } from '@phosphor-icons/react'
import { t } from 'i18next'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'

import { Avatar } from 'ui'
import Head from '../../components/head'
import { HeaderAnonym } from '../../components/ui/header-anonym'
import { BE_CONTRACT_CATEGORY, userTypes } from '../../helpers/enum'
import {
  loginUserSuccessful,
  saveRoute,
  updateDeepLink,
} from '../../store/auth/register/actions'
import { tag } from '../../utils/analytics'
import { EVENTS } from '../../utils/analytics/events'
import { getDefaultEventData } from '../../utils/analytics/get-default-event-data'
import { getFullName } from '../../utils/get-full-name'

function trackSignIn(data) {
  const email = data?.email
  const clientId = data?.id

  const defaultEventData = getDefaultEventData(email)
  const eventData = {
    ...defaultEventData,
    userID: clientId,
    email_id: email,
  }

  if (!data?.is_contractor) {
    eventData.companyID = data?.company_id
  }

  tag(EVENTS.SIGNED_IN, eventData)
}

export default function UserTypeChooser() {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.Account?.user)
  const link = useSelector((state) => state.Account?.link)
  const history = useHistory()
  const lastPath = useSelector((state) => state.Account?.lastPath)

  function handleLoginResponse(data) {
    trackSignIn(data)

    dispatch(loginUserSuccessful(data))
    if (lastPath) {
      dispatch(saveRoute(null))
      history.push(lastPath)
    } else if (link && typeof link === 'string') {
      history.push(link)
      dispatch(updateDeepLink(null))
    }
  }

  return (
    <Modal
      isOpen={true}
      className='!tw-m-0 !tw-h-full !tw-max-w-full'
      modalClassName='tw-bg-surface-20'
      contentClassName='!tw-bg-transparent !tw-border-0 tw-h-full'
    >
      <HeaderAnonym />

      <Head title={t('Select profile')} />

      <ModalBody className='tw-flex tw-h-[calc(100vh-80px)] tw-flex-col tw-items-center tw-bg-transparent !tw-p-0'>
        <div className='tw-w-full tw-max-w-[500px] tw-py-20'>
          <div className='tw-mb-6 tw-text-center md:tw-mb-10'>
            <h3 className='tw-text-2xl md:tw-text-3xl'>
              {t('Welcome back', { name: user?.[0]?.first_name })}
            </h3>
            <p className='tw-mb-0 tw-text-sm tw-text-disabled'>
              {t('Choose a profile below to pick up where you left off.')}
            </p>
          </div>
          <div className='tw-flex tw-flex-col tw-gap-4 tw-pb-4'>
            {user?.length <= 0
              ? null
              : user?.map((user, i) => {
                  const isClient = user?.type === userTypes.COMPANY
                  const isContractor =
                    user?.contractor_type === BE_CONTRACT_CATEGORY.INDIVIDUAL ||
                    user?.contractor_type === BE_CONTRACT_CATEGORY.ENTITY
                  const isDirectEmployee =
                    user?.contractor_type ===
                    BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE

                  return (
                    <button
                      key={i}
                      onClick={() => {
                        handleLoginResponse(user)
                      }}
                      className='tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-3 tw-rounded tw-border-none tw-bg-white tw-px-6 tw-py-6 tw-text-start tw-shadow-sm tw-transition-colors hover:tw-shadow-none hover:tw-ring-2 hover:tw-ring-primary-100 focus:tw-ring-2 focus:tw-ring-primary-80 md:tw-px-7'
                    >
                      <div className='tw-flex tw-flex-1 tw-items-center tw-justify-between tw-gap-4 md:tw-gap-5'>
                        <Avatar
                          photo={user?.photo}
                          name={user?.first_name || user?.email}
                          size='xl'
                          label={isClient ? 'Client' : undefined}
                        />

                        <div className='tw-flex-1'>
                          <h4 className='tw-mb-0 tw-text-base tw-font-semibold'>
                            {t('accountType', {
                              type: isClient
                                ? t('Client')
                                : isContractor
                                  ? t('Contractor')
                                  : isDirectEmployee
                                    ? t('Direct Employee')
                                    : t('Employee'),
                            })}
                          </h4>
                          <p className='tw-mb-0 tw-mt-1.5 tw-text-sm tw-text-text-60'>
                            {isClient ? user?.company_name : getFullName(user)}
                          </p>
                        </div>
                      </div>

                      <CaretRight
                        weight='bold'
                        size={18}
                        className='rtl:tw-rotate-180'
                      />
                    </button>
                  )
                })}

            <Link
              to='/logout'
              className='tw-block tw-w-full tw-text-center tw-text-sm'
            >
              {t('Logout')}
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
