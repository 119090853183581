import { yupResolver } from '@hookform/resolvers/yup'
import { Eye, EyeSlash } from '@phosphor-icons/react'
import { t } from 'i18next'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import toastr from 'toastr'
import { cn } from 'ui'

import UploadPreview from '../../../../components/Common/upload-preview'
import Button from '../../../../components/ui/button'
import {
  SideMenu,
  sideMenuGridClass,
  SideMenuHeading,
} from '../../../../components/ui/side-menu'
import { useFetch } from '../../../../helpers/hooks'
import { updateVendor } from '../../../../services/api-bill-payments'
import { track } from '../../../../utils/analytics'
import {
  VendorDetailForm,
  vendorDetailSchema,
} from '../create/create-vendor-step-1'

const editVendorFormId = 'editVendorFormId'
export function EditVendorDetailsSideMenu({
  isOpen,
  toggle,
  updateVendors,
  vendor,
  source = 'vendor_list',
}) {
  const def = {
    name: vendor?.name,
    category_id: vendor?.category?.id,
    country_id: vendor?.country?.id,
    state_id: vendor?.state?.id,
    city: vendor?.city === '' ? undefined : vendor?.city,
    address: vendor?.address === '' ? undefined : vendor?.address,
  }
  const { id: userID } = useSelector((state) => state.Account?.user)
  const [showBill, setShowBill] = useState(false)

  const { control, handleSubmit } = useForm({
    defaultValues: { ...def },
    resolver: yupResolver(vendorDetailSchema),
  })

  const { startFetch: _updateVendor, isLoading: isUpdatingVendor } = useFetch({
    action: updateVendor,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(t('Something went wrong while updating the vendor'))
      } else {
        const logCreateVendorData = {
          userID,
          vendorID: data?.id,
          'Vendor Name': data?.name,
          Category: data?.category?.name,
          Source: source,
          Country: data?.country ? data?.country?.name : undefined,
          State: data?.state ? data?.state?.name : undefined,
          City: data?.city ?? undefined,
          Address: data?.address ?? undefined,
        }
        track('Vendor Updated', logCreateVendorData)
        toastr.success(t('Vendor updated successfully'))
        toggle?.()
        updateVendors?.()
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  if (!isOpen) return null

  function onSubmit(values) {
    _updateVendor({
      ...values,
      id: vendor?.id,
      city: values?.city?.length > 0 ? values?.city : undefined,
      address: values?.address?.length > 0 ? values?.address : undefined,
    })
  }

  const billToPreview = vendor?.latest_bill?.pdf

  return (
    <SideMenu
      isOpen={isOpen}
      onClose={toggle}
      preventScrollBehavior={false}
      itemListClassName='tw-flex'
      className={showBill ? '!tw-max-w-[70vw]' : '!tw-max-w-[23.3vw]'}
    >
      {billToPreview && (
        <div className='tw-flex-grow'>
          {showBill ? (
            <UploadPreview
              preview={{
                type: 'application/pdf',
                data: billToPreview,
              }}
            />
          ) : null}
        </div>
      )}
      <div
        className={sideMenuGridClass({
          className: cn(`!tw-grid !tw-flex-shrink-0`, {
            'tw-w-full': !billToPreview,
          }),
        })}
      >
        <SideMenu.Header toggle={toggle}>
          <SideMenuHeading
            title={t('Vendor details')}
            subTitle={t('Edit vendor details')}
            onClickBack={toggle}
          />
        </SideMenu.Header>
        <SideMenu.Body>
          {billToPreview && (
            <p className='tw-mb-0 tw-flex tw-items-center tw-justify-between !tw-text-sm tw-text-black'>
              {t('There is an invoice uploaded for this vendor')}
              <Button
                className='tw-ml-2 tw-w-[72px] !tw-p-0 !tw-ps-0.5 !tw-text-xs'
                size='sm'
                color='link'
                iconRight={
                  showBill ? <EyeSlash size={16} /> : <Eye size={16} />
                }
                onClick={() => setShowBill((prev) => !prev)}
              >
                {showBill ? t('Hide') : t('Preview')}
              </Button>
            </p>
          )}
          <form onSubmit={handleSubmit(onSubmit)} id={editVendorFormId}>
            <VendorDetailForm control={control} className='tw-space-y-4' />
          </form>
        </SideMenu.Body>
        <SideMenu.Footer>
          <Button
            type='button'
            onClick={toggle}
            outline
            color='light'
            disabled={isUpdatingVendor}
          >
            {t('Cancel')}
          </Button>
          <Button
            formId={editVendorFormId}
            type='submit'
            disabled={isUpdatingVendor}
            loading={isUpdatingVendor}
          >
            {t('Save')}
          </Button>
        </SideMenu.Footer>
      </div>
    </SideMenu>
  )
}
