import React, { useEffect, useMemo, useState } from 'react'
import { CloseButton } from 'ui'
import CalendarControls from './controls'
import rpLogoWhite from '../../assets/images/identity/logo_main.png'
import Filters from './filters/filters'
import Calendar from './calendar'
import Timeline from './timeline'
import { useHistory } from 'react-router-dom'
import { useDebounceValue } from 'usehooks-ts'
import { useFetch } from '../../helpers/hooks'

import toastr from 'toastr'
import { getPreferences, savePreference } from '../../services/api-preference'
import Onboarding from './onboarding/onboarding'
import { track } from '../../utils/analytics'
import { useSelector } from 'react-redux'
import {
  getDurationInDays,
  getFromToDates,
  getInitialFilters,
  returnTodaysCelebrationEvents,
} from './helpers'
import InsightsPanel from './components/insights/insights'
import CalendarLoading from './components/calendar-loading'
import CelebrationPopup from './celebration-popup/CelebrationPopup'
import { getInsights, getTimelineEvents } from '../../services/api-events'
import { addDays, format } from 'date-fns'
import { userTypes } from '../../helpers/enum'
import { CALENDAR_SELECTED_WORKERS_FILTER_KEY } from './select-workers'
export const CALENDAR_VIEWS = {
  CALENDAR: 'calendar',
  TIMELINE: 'timeline',
}
export const durationOptions = [
  { label: 'Week', value: 'w' },
  { label: '2 Weeks', value: '2w' },
  { label: 'Month', value: 'm' },
]

const lastDismissedCelebrationPopupKey = 'last-dismissed-celebration-popup'
const today = format(new Date(), 'yyyy-MM-dd')

function EventsPage() {
  const lastDismissedCelebrationPopupDate = localStorage.getItem(
    lastDismissedCelebrationPopupKey,
  )
  const history = useHistory()

  const [startDate, setStartDate] = useState(new Date())
  const [view, setView] = useState(CALENDAR_VIEWS.CALENDAR)
  const [duration, setDuration] = useState(durationOptions[2])
  const [isFiltersOpen, setIsFiltersOpen] = useState(true)
  const [showInsightsPanel, setShowInsightPanel] = useState(false)
  const [showInsight, setShowInsight] = useState(null)
  const [filters, setFilters] = useState({})
  const [initialFilters, setInitialFilters] = useState({})
  const [isDefaultFilters, setIsDefaultFilters] = useState(true)
  const [createCalendarLink, setCreateCalendarLink] = useState(false)
  const [onboardingFinished, setOnboardingFinished] = useState(
    Boolean(
      localStorage.getItem('calendar-onboarding') &&
        localStorage.getItem('calendar-onboarding') === 'done',
    ) ?? false,
  )
  const { contract_statuses: statuses } = useSelector(
    (state) => state?.Layout?.staticData ?? {},
  )
  const user = useSelector((state) => state.Account?.user)
  const isClient = user?.type === userTypes.COMPANY
  const [showCelebrationPopup, setShowCelebrationPopup] = useState(false)
  const [todaysCelebrationEvents, setTodaysCelebrationEvents] = useState([])
  const [selectedWorkers, setSelectedWorkers] = useState(new Set())
  const [showWorkersFilter, setShowWorkersFilter] = useState(false)

  const formattedStatuses = useMemo(
    () =>
      statuses
        ?.filter((status) => ![1, 2, 6].includes(status.id)) // remove unsupported statuses
        ?.map((e) => ({
          label: e.name,
          value: e.id,
        })) ?? [],
    [statuses],
  )

  const { startFetch: createPreference } = useFetch({
    action: savePreference,
    onError: (error) => {
      toastr.error(error)
    },
  })

  useEffect(() => {
    if (isClient) {
      setView(CALENDAR_VIEWS.TIMELINE)
      setDuration(durationOptions[0])
    }
  }, [isClient])

  useFetch(
    {
      action: getPreferences,
      autoFetch: formattedStatuses.length > 0,
      onComplete: (data) => {
        const foundCalendarFilters = data?.find(
          (obj) => obj.key === 'calendar.filters',
        )?.value
        const initialFilters = getInitialFilters(
          foundCalendarFilters ? JSON.parse(foundCalendarFilters) : {},
          formattedStatuses,
        )
        // get today's celebration events
        if (isClient) {
          getTodaysCelebrationEvents({
            from: format(new Date(), 'yyyy-MM-dd'),
            to: format(new Date(), 'yyyy-MM-dd'),
            event_types: ['birthday', 'work_anniversary'],
            predefined_worker_filter: 'my_circle',
          })
        }

        setInitialFilters(initialFilters)

        // find and initialize saved selected workers
        const foundCalendarSelectedWorkersFilter = data?.find(
          (obj) => obj.key === CALENDAR_SELECTED_WORKERS_FILTER_KEY,
        )?.value

        // select pre-saved workers
        if (foundCalendarSelectedWorkersFilter) {
          setSelectedWorkers(
            new Set(JSON.parse(foundCalendarSelectedWorkersFilter)),
          )
        }
      },
      onError: (err) => {
        toastr.error(err)
      },
    },
    [formattedStatuses],
  )

  const { startFetch: getTodaysCelebrationEvents } = useFetch({
    action: getTimelineEvents,
    onComplete: (data) => {
      if (data?.length > 0 && lastDismissedCelebrationPopupDate !== today) {
        setTodaysCelebrationEvents(returnTodaysCelebrationEvents(data))
        setShowCelebrationPopup(true)
      }
    },
  })

  const {
    data: moreInsights,
    startFetch: getMoreInsights,
    isLoading: isMoreInsightsLoading,
  } = useFetch({
    action: getInsights,
  })

  const {
    isLoading: isInsightsLoading,
    data: insights,
    startFetch: refreshInsights,
  } = useFetch(
    {
      action: getInsights,
      autoFetch: isClient,
      body: {
        ...getFromToDates({ date: startDate, duration, view }),
      },
      onComplete: () => {
        const moreInsightsFrom = getFromToDates({
          date: startDate,
          duration,
          view,
        }).to
        const moreInsightsTo = format(
          addDays(new Date(moreInsightsFrom), 90),
          'yyyy-MM-dd',
        )
        getMoreInsights({
          from: moreInsightsFrom,
          to: moreInsightsTo,
        })
      },
    },
    [startDate, duration, view],
  )

  const handleToggleFilters = () => {
    setIsFiltersOpen((prev) => !prev)
  }

  const [debouncedFilters] = useDebounceValue(filters, 1000)

  const handleSetFilters = (filters) => {
    setFilters((prev) => ({ ...initialFilters, ...prev, ...filters }))
  }

  useEffect(() => {
    Object.keys(debouncedFilters).length > 0 &&
      createPreference({
        key: 'calendar.filters',
        value: JSON.stringify(debouncedFilters),
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilters])

  useEffect(() => {
    track('Calendar open')
  }, [])

  const handleCloseCelebrationPopup = () => {
    setShowCelebrationPopup(false)
    localStorage.setItem(lastDismissedCelebrationPopupKey, today)
  }

  const isLoadingPrefs = Object.keys(initialFilters).length === 0

  return (
    <>
      {showCelebrationPopup && todaysCelebrationEvents.length > 0 && (
        <CelebrationPopup
          open={showCelebrationPopup}
          onClose={handleCloseCelebrationPopup}
          data={todaysCelebrationEvents}
        />
      )}
      <div className='tw-flex tw-h-16 tw-items-center tw-justify-between tw-bg-white tw-px-8'>
        <img
          className='tw-absolute'
          src={rpLogoWhite}
          style={{ height: '24px' }}
        />
        <div className='tw-invisible tw-flex tw-grow tw-items-center tw-justify-center tw-font-bold sm:tw-visible'>
          Calendar
        </div>
        <CloseButton onClick={() => history.push('/activity')} />
      </div>
      {!onboardingFinished ? (
        <Onboarding setOnboardingDone={setOnboardingFinished} />
      ) : (
        <div className='tw-flex tw-flex-col tw-gap-4 tw-p-10'>
          <CalendarControls
            view={view}
            setView={setView}
            startDate={startDate}
            toggleFilters={handleToggleFilters}
            isFiltersOpen={isFiltersOpen}
            setStartDate={setStartDate}
            duration={duration}
            setDuration={setDuration}
            toggleInsightsPanel={() => setShowInsightPanel((prev) => !prev)}
            createCalendarLink={createCalendarLink}
            setCreateCalendarLink={setCreateCalendarLink}
            isDefaultFilters={isDefaultFilters}
            hasInsights={insights?.length > 0}
            setShowInsight={setShowInsight}
            showInsight={showInsight}
            showWorkersFilter={showWorkersFilter}
            setShowWorkersFilter={setShowWorkersFilter}
            hasSelectedWorkers={selectedWorkers.size > 0}
          />
          <div className='tw-flex tw-w-full tw-gap-3'>
            {!isLoadingPrefs && (
              <Filters
                setFilters={handleSetFilters}
                filters={debouncedFilters}
                persistedFilters={initialFilters}
                open={isFiltersOpen}
                isDefaultFilters={isDefaultFilters}
                setIsDefaultFilters={setIsDefaultFilters}
                allStatuses={formattedStatuses}
              />
            )}

            <div className='tw-flex-1 tw-overflow-x-scroll'>
              {view === CALENDAR_VIEWS.CALENDAR && !showInsight ? (
                isLoadingPrefs ? (
                  <CalendarLoading />
                ) : (
                  <Calendar
                    date={startDate}
                    initialFilters={initialFilters}
                    filters={debouncedFilters}
                    isFiltersOpen={isFiltersOpen}
                    isInsightsOpen={showInsightsPanel}
                  />
                )
              ) : (
                <Timeline
                  date={startDate}
                  showInsight={showInsight}
                  duration={duration}
                  initialFilters={initialFilters}
                  filters={debouncedFilters}
                  selectedWorkers={selectedWorkers}
                  setSelectedWorkers={setSelectedWorkers}
                  showWorkersFilter={showWorkersFilter}
                  setShowWorkersFilter={setShowWorkersFilter}
                />
              )}
            </div>

            {showInsightsPanel && (
              <InsightsPanel
                insightShowing={showInsight}
                refreshInsights={() => {
                  refreshInsights()
                }}
                moreInsights={moreInsights}
                isInsightsLoading={isInsightsLoading || isMoreInsightsLoading}
                endDate={addDays(
                  new Date(startDate),
                  getDurationInDays({ date: startDate, duration }) - 1,
                )}
                onShowInsight={({
                  workerIds,
                  startDate,
                  insightLength,
                  id,
                }) => {
                  setShowInsight({
                    id,
                    workerIds,
                    startDate,
                    insightLength,
                  })
                }}
                onHideInsight={() => setShowInsight(null)}
                insights={insights}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default EventsPage
