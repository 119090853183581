import cx from 'classnames'
import React, { useState } from 'react'

import topUpCardSelected from '../../../assets/images/top-up-card-selected.svg'
import topUpCard from '../../../assets/images/top-up-card.svg'
import Alert from '../../../components/ui/alert'
import Button from '../../../components/ui/button'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import CardDetailsModal from './CardDetailsModal'
import { useFetch } from '../../../helpers/hooks'
import { deletePaysendCardAccount } from '../../../services/api'
import { track } from '../../../utils/analytics'
import EVENTS from '../../../utils/events'
import { t } from 'i18next'

const CARD_METHOD_STATUS = (t) => {
  return {
    PENDING: {
      label: t('Pending'),
      key: 'PENDING',
    },
    REJECTED: {
      label: t('Rejected'),
      key: 'REJECTED',
    },
    APPROVED: {
      label: t('Approved'),
      key: 'APPROVED',
    },
  }
}

export default function CardMethod({
  account,
  selected,
  makeDefault,
  onUpdate,
  withRemove,
  onClick,
  isLoading,
}) {
  const isButton = typeof onClick === 'function'
  const Component = isButton ? 'button' : 'div'

  const isPending = account?.status === CARD_METHOD_STATUS(t).PENDING.key
  const isApproved = account?.status === CARD_METHOD_STATUS(t).APPROVED.key
  const isActive = isApproved
  const [showConfirm, setShowConfirm] = useState(false)
  const [showDetails, setShowDetails] = useState(false)

  const deleteCard = useFetch({
    action: deletePaysendCardAccount,
    onComplete: () => {
      onUpdate()
      track(EVENTS.REMOVED_WITHDRAW_METHODS, {
        withdraw_account_name: account.holder_name,
        withdraw_method: 'Paysend Card Account',
        currency: account?.currencyCode,
        is_default: false,
      })
    },
  })

  return (
    <Component
      className='p-0 border-0 bg-transparent position-relative'
      onClick={!isButton ? null : onClick}
      disabled={!isActive && isButton}
    >
      {withRemove && (
        <div
          style={{
            zIndex: 9,
            top: 12,
            right: 12,
            position: 'absolute',
          }}
        >
          {deleteCard.isLoading ? (
            <i className='bx bx-loader bx-spin text-danger font-size-14 align-middle ml-2' />
          ) : (
            <button
              type='button'
              className='rp-btn-nostyle d-flex p-1 text-muted font-size-16 hover:bg-slate-50'
              onClick={() => {
                setShowConfirm(true)
              }}
            >
              <i className='bx bxs-trash-alt' />
            </button>
          )}
        </div>
      )}
      <div
        className={cx(
          'd-flex align-items-center position-relative p-3 rounded border gap-12',
          {
            'bg-light': !isActive && !isButton,
            'bg-surface-30': !isActive,
            'w-100 text-left': isButton,
            'hover:bg-slate-50': isActive && !selected && isButton,
            'border-light': !selected,
            'border-transparent': selected && isButton,
            'rounded-bottom-0': isPending,
          },
        )}
        style={{
          boxShadow: selected ? '0 0 0px 2px var(--primary-100)' : undefined,
        }}
      >
        <img
          src={selected ? topUpCardSelected : topUpCard}
          alt=''
          className='align-self-start'
          style={{
            '--size': '46px',
            width: 'var(--size)',
            height: 'auto',
            padding: '0.2rem',
          }}
        />

        <div className='flex-grow-1 align-items-start d-flex flex-column gap-8 justify-content-center'>
          <h5
            className={cx('font-size-16 mb-0', {
              'text-primary': selected,
              'text-dark': !selected,
            })}
            style={{ lineHeight: 1 }}
          >
            {account?.holder_name}
          </h5>
          <p
            className={cx(
              'font-size-14 mb-0 d-flex align-items-center justify-content-end',
              {
                'text-primary': selected,
                'text-secondary': !selected,
              },
            )}
            style={{ wordBreak: 'break-all', lineHeight: 1 }}
          >
            **** **** **** {account?.last_4_digits}{' '}
            <button
              onClick={(e) => {
                e.stopPropagation()
                setShowDetails(true)
              }}
              className='rp-btn-nostyle ml-1 d-flex p-0 hover:bg-slate-50 text-secondary'
              type='button'
              title={t('Show details')}
            >
              <i className='bx bx-show font-size-20'></i>
            </button>
          </p>
          {isButton ? null : (
            <>
              {!isActive ? null : selected ? (
                <div className='btn btn-primary rounded-sm font-size-10 py-0 px-1 font-weight-normal cursor-default'>
                  {t('Default')}
                </div>
              ) : !makeDefault ? null : (
                <Button
                  onClick={makeDefault}
                  size='sm'
                  loading={isLoading}
                  className='font-size-10 py-0 px-1'
                  color='light'
                  outline
                >
                  {t('Make default')}
                </Button>
              )}
            </>
          )}
        </div>
      </div>

      {!isPending ? null : (
        <Alert
          customIcon={false}
          className='rounded-top-0 border-surface-30 bg-gold-20 text-dark font-size-14 text-left p-3 mb-0'
        >
          {t(
            'Your card is currently under review, you’ll receive a notification once approved.',
          )}
        </Alert>
      )}

      {showDetails && (
        <CardDetailsModal
          isOpen={showDetails}
          details={account}
          toggle={() => {
            setShowDetails(false)
          }}
        />
      )}

      {showConfirm && (
        <ConfirmationModal
          toggle={() => setShowConfirm(false)}
          isOpen={showConfirm}
          title={t('Delete Card Account')}
          message='Are you sure you want to delete this account?'
          onConfirm={() => {
            deleteCard.startFetch({ id: account?.id })
            setShowConfirm(false)
          }}
        />
      )}
    </Component>
  )
}
