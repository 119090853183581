import { t } from 'i18next'
import {
  CalendarPlus,
  CalendarX,
  CirclesThreePlus,
  ClockClockwise,
  HourglassLow,
} from '@phosphor-icons/react'
import React from 'react'

import { cn } from 'ui'
import { labelFromType } from '../pages/CompanySetting/components/time-off-policies-tab/manage-policy-workers'
import {
  getCarryOverExpiration,
  getCarryOverType,
  getPolicyEarningDate,
} from '../pages/Contract/ContractPage/time-off-stats'
import { TimeOffPolicyCarryOverTypes } from '../pages/Contract/utils/constants'
import { getCarryOverDateType } from '../pages/new-time-off-policy/utils/get-carry-over-type'
import { DetailsInfoList } from '../pages/review-center/review-layout-details-components'
import Accordion from './Accordion'

export default function PolicyAccrualInfo({
  policy,
  className,
  bodyClassName,
  isOpenByDefault,
}) {
  return (
    <Accordion
      className={cn(
        'tw-w-[stretch] tw-border-b tw-border-b-surface-30 tw-px-0 tw-py-4',
        className,
      )}
      isOpenByDefault={isOpenByDefault}
      iconClassName='tw-fill-primary'
      contentWrapperClassName='tw-px-0 tw-py-0'
      label={
        <div className='tw-text-start'>
          <p className='tw-mb-0 tw-text-sm tw-font-semibold tw-text-black'>
            {t('Accrual & carryover')}
          </p>
          <span className='tw-text-sm tw-text-text-80'>
            {t('View accrual & carryover rules for this policy')}
          </span>
        </div>
      }
      value={
        <DetailsInfoList
          className={cn('tw-py-0', bodyClassName)}
          items={[
            {
              icon: <CalendarPlus size={24} />,
              label: t('Earning date'),
              value: getPolicyEarningDate({
                contractStartDate: null,
                earningStartDays: policy?.earning_start_days,
                t,
              }),
            },
            {
              icon: <ClockClockwise size={24} />,
              label: t('Accrual frequency'),
              value: labelFromType(policy?.accrual_frequency, t),
              valueClassName: 'tw-capitalize',
            },
            {
              icon: <CirclesThreePlus size={24} />,
              label: t('Time off carryover'),
              value: getCarryOverType(
                policy?.carryover_type,
                policy?.carryover_days,
                t,
              ),
            },
            policy?.carryover_type === TimeOffPolicyCarryOverTypes.LIMITED && {
              icon: <HourglassLow size={24} />,
              label: t('Carryover review'),
              value: getCarryOverDateType(policy?.carryover_date_type, t),
            },
            policy?.carryover_type !== TimeOffPolicyCarryOverTypes.LIMITED
              ? null
              : {
                  icon: <CalendarX size={24} />,
                  label: t('Carryover expiration'),
                  value: getCarryOverExpiration(
                    policy?.carryover_expiration_days,
                    t,
                  ),
                },
          ].filter(Boolean)}
        />
      }
    />
  )
}
