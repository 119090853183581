export const MANAGER_ROOT_ID = 'manager-root'
export const DEPARTMENT_ROOT_ID = 'department-root'

export const DEFAULT_FIT_VIEW_OPTIONS = {
  duration: 1000,
}
export const ZOOM_DURATION = 200
export const ZOOM_STEP = 0.25

export const EDGE_TYPE = 'smoothstep'

export const DEFAULT_SPACING = [20, 38]

export const NODE_TYPES = {
  WORKER_COUNT: 'departmentEmployeesNumber',
  WORKER: 'userProfile',
  COMPANY: 'companyProfile',
  DEPARTMENT: 'departmentInfo',
}
