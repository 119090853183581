import React from 'react'

const ProrataStateContext = React.createContext()

export function ProrataStateProvider({ children, value }) {
  return (
    <ProrataStateContext.Provider value={value}>
      {children}
    </ProrataStateContext.Provider>
  )
}

export function useProrataState() {
  return React.useContext(ProrataStateContext)
}
