import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader } from 'reactstrap'

import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import Head from '../../components/head'
import Loader from '../../components/ui/loader'
import { BE_CONTRACT_CATEGORY, CONTRACT_STATUS } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import { getContractList } from '../../services/api'
import {
  getContractPaidAndUnPaidDays,
  getContractTimeOff,
} from '../../services/api-time-off-policies'
import { TimeOffStats } from '../Contract/ContractPage/time-off-stats'
import { useTimeOffFetch } from './helpers'
import TimeOffEmpty from './time-off-empty'
import TimeOffList from './time-off-list'
import TimeOffPageHeader from './time-off-page-header'
import { CONTRACT_TYPES } from '../../core/config/contract-types'

export default function TimeOffPage() {
  const [contractId, setContractId] = useState(null)

  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  const isEmployee =
    userProfile?.contractor_type === BE_CONTRACT_CATEGORY.EMPLOYEE

  const {
    timeOffs,
    gettingTimeOffList,
    updateTimeOff,
    paginator,
    filters,
    handleFiltersChange,
  } = useTimeOffFetch({
    contractId,
    isEmployee,
    isContractDetails: false,
    autoFetch: contractId,
  })

  const {
    data: policies,
    isLoading: isLoadingPolicies,
    startFetch: refreshContractPolicies,
  } = useFetch(
    {
      action: getContractTimeOff,
      body: { contract_id: contractId },
      autoFetch: !!contractId,
    },
    [contractId],
  )

  return (
    <div className='page-content'>
      <Head title='Time off' />

      <TimeOffPageHeader
        updateTimeOff={() => {
          updateTimeOff()
          refreshContractPolicies()
        }}
        timeOffs={timeOffs}
        isUserAssignedTimeOff={!!policies?.length}
      />

      <TimeOffContent
        timeOffs={timeOffs}
        gettingTimeOffList={gettingTimeOffList}
        updateTimeOff={updateTimeOff}
        paginator={paginator}
        filters={filters}
        handleFiltersChange={handleFiltersChange}
        contract={contractId}
        onContractChange={setContractId}
        policies={policies}
        isLoadingPolicies={isLoadingPolicies}
        refreshContractPolicies={refreshContractPolicies}
      />
    </div>
  )
}

function getContractOptions(contract) {
  const label = `${contract?.ref} - ${contract?.name} (${contract?.type})`
  return { label, value: contract?.id }
}

function TimeOffContent({
  timeOffs,
  gettingTimeOffList,
  updateTimeOff,
  paginator,
  filters,
  handleFiltersChange,
  contract,
  onContractChange,
  policies,
  isLoadingPolicies,
  refreshContractPolicies,
}) {
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const isEmployee =
    userProfile?.contractor_type === BE_CONTRACT_CATEGORY.EMPLOYEE

  const hasFilters =
    Object.keys(filters).length > 2 &&
    Object.keys(filters).some((key) => key !== 'page' && key !== 'contract_id')

  const isListEmpty =
    !timeOffs || timeOffs?.list?.length <= 0 || timeOffs?.length <= 0

  const isPageEmpty = !hasFilters && isListEmpty

  const timeOffsList = timeOffs?.list || []
  const isFiltering = !!(
    filters.time_off_type_id ||
    filters.status_id ||
    filters.month
  )

  const { data: contracts, isLoading: contractsLoading } = useFetch({
    action: getContractList,
    autoFetch: true,
    body: { statuses: CONTRACT_STATUS.ONGOING.value },
    onComplete: (contracts) => {
      if (isEmployee) {
        onContractChange(userProfile?.contract_id)
      } else {
        onContractChange(contracts?.[0]?.id)
      }
    },
  })

  const selectedContract = isEmployee
    ? { id: userProfile?.contract_id }
    : contracts?.find((c) => c?.id === contract)

  const {
    data: paidAndUnPaidDays,
    isLoading: gettingPaidDays,
    startFetch: getPaidAndUnpaidDays,
  } = useFetch(
    {
      action: getContractPaidAndUnPaidDays,
      autoFetch: selectedContract?.id,
      body: { contract_id: selectedContract?.id },
    },
    [selectedContract?.id],
  )

  return (
    <Card>
      <CardHeader className='!tw-bg-transparent !tw-px-0 !tw-pb-0 !tw-pt-6'>
        <>
          {contracts?.length > 1 && !contractsLoading && (
            <div className='tw-px-6'>
              <CustomSelect
                placeholder='Select contract'
                value={getContractOptions(selectedContract)}
                options={contracts
                  .filter(({ type }) => type !== CONTRACT_TYPES.MILESTONES)
                  .map(getContractOptions)}
                onChange={(val) => onContractChange(val?.value)}
              />
            </div>
          )}

          <TimeOffStats
            contract={selectedContract}
            policies={policies}
            isLoadingPolicies={isLoadingPolicies}
            refreshPolicies={() => {
              refreshContractPolicies()
              getPaidAndUnpaidDays()
            }}
            updateTimeOff={updateTimeOff}
          />
        </>
      </CardHeader>

      {(gettingTimeOffList && !isFiltering) || !contract ? (
        <Loader minHeight='30rem' />
      ) : isPageEmpty && !isFiltering ? (
        <TimeOffEmpty onSubmit={updateTimeOff} timeOffs={timeOffs} />
      ) : (
        <CardBody className='px-md-0'>
          <TimeOffList
            timeOffsList={timeOffsList}
            paginator={paginator}
            filters={filters}
            handleFiltersChange={handleFiltersChange}
            update={() => {
              updateTimeOff()
              refreshContractPolicies()
              getPaidAndUnpaidDays()
            }}
            gettingTimeOffList={gettingTimeOffList || gettingPaidDays}
            paidDays={paidAndUnPaidDays?.days_paid}
            unPaidDays={paidAndUnPaidDays?.days_unpaid}
            contract={selectedContract}
            isFiltering={isFiltering}
          />
        </CardBody>
      )}
    </Card>
  )
}
