import {
  ArrowCircleUp,
  PencilSimple,
  TrashSimple,
  Warning,
} from '@phosphor-icons/react'
import { t } from 'i18next'
import React, { useState } from 'react'

import { ActionsDropdown } from 'ui'
import { ConfirmationModal } from '../remotepass-cards/components/active-credit-card-section'
function TenureRuleLine({ tenureRule, index, onDelete, onEdit, addedDays }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  return (
    <>
      <div className='tw-flex tw-justify-between tw-border-b tw-border-b-surface-30 tw-py-4'>
        <div className='tw-flex tw-grow tw-flex-col tw-justify-center tw-gap-1'>
          <div className='tw-font-semibold'>
            {t('More than')}
            {tenureRule.tenure} {tenureRule.tenure > 1 ? 'years' : 'year'}
          </div>
          <div className='tw-text-text-80'>
            {t('Rule')}
            {index + 1}
          </div>
        </div>
        <div className='tw-flex tw-items-center tw-justify-end tw-gap-4'>
          <div className='tw-flex tw-flex-col tw-gap-1'>
            <div className='tw-flex tw-items-center tw-gap-2 tw-font-semibold'>
              <div className='tw-flex tw-h-[30px] tw-items-center tw-gap-0.5 tw-rounded-full tw-bg-systemGreen-10 tw-px-2'>
                <span>{addedDays}</span>
                <ArrowCircleUp
                  weight='fill'
                  className='tw-text-systemGreen'
                  size={16}
                />
              </div>
              <span>
                {tenureRule.amount}
                {t('days')}
              </span>
            </div>
            <div className='tw-text-end tw-text-text-80'>
              {t('Time off amount')}
            </div>
          </div>
          <div>
            <ActionsDropdown
              data={[
                {
                  label: 'Edit',
                  onClick: () => onEdit(tenureRule),
                  icon: <PencilSimple />,
                },
                {
                  label: 'Delete',
                  className: 'tw-text-red',
                  onClick: () => setShowDeleteModal(true),
                  icon: <TrashSimple className='tw-text-red' />,
                },
              ]}
            />
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <ConfirmationModal
          isOpen={showDeleteModal}
          titleClassName='!tw-items-start tw-text-xl !tw-pb-0'
          title={
            <p className='tw-m-0 tw-text-black'>
              {t('Are you sure you want to delete rule?')}
            </p>
          }
          content='Rule will be permanently deleted. This action cannot be undone.'
          contentClassName='!tw-pt-0'
          cancelText='No, Close'
          confirmText='Yes, Delete'
          confirmColor='danger'
          icon={<Warning size={24} className='tw-text-systemRed-100' />}
          toggle={() => setShowDeleteModal(false)}
          onConfirm={() => onDelete(tenureRule)}
        />
      )}
    </>
  )
}

export default TenureRuleLine
